import React, { Component } from "react";
import Navbar from "../Navbar/Navbar";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import session from "../../session";
import "./Dashboard.scss";
import { ReactComponent as Arrival } from "./svg/Arrival.svg";
import { ReactComponent as InPort } from "./svg/In Port.svg";
import { ReactComponent as Departure } from "./svg/Departure.svg";
import { ReactComponent as Recents } from "./svg/Recents.svg";
import { ReactComponent as BerthMonitoring } from "./svg/Berth Monitoring.svg";
import { SkellyBarTableRow } from "../Tools/SkeletonScreen/SkeletonScreen";
import { Icon } from "react-fa";
import Highcharts from "highcharts";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { DropdownList } from "react-widgets";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import api from "../../helpers/api.helper";
import Moment from "react-moment";
import constants from "../../constants";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import Media from "react-media";

const media_queries = {
  xs: "(max-width: 575.98px)",
  sm: "(min-width: 576px) and (max-width: 767.98px)",
  md: "(min-width: 768px) and (max-width: 991.98px)",
  lg: "(min-width: 992px) and (max-width: 1199.98px)",
  xl: "(min-width: 1200px)",
};

const sample_tickerArrivals = [
  {
    checklist: {
      vessel_entry_registration: {
        is_done: true,
        by: "Subic Grain Admin",
        date: new Date("2020-01-21T03:56:17.158Z"),
      },
      general_manifest_registration: {
        is_done: false,
      },
      berth_assignment: {
        is_done: false,
      },
      resource_allocation: {
        is_done: false,
      },
      vessel_exit_registration: {
        is_done: false,
      },
      cargo_documents_cleared: {
        is_done: false,
      },
      vessel_pre_departure_inspection: {
        is_done: false,
      },
    },
    calendar_schedule: [
      {
        title: "Submitted",
        date: "2020-01-21 11:57",
      },
      {
        title: "Announced",
        date: "2020-01-21 11:57",
      },
    ],
    timeline: [],
    vessel_movement: [],
    status_change_log: [
      {
        status: "Submitted",
        date: new Date("2020-01-21T03:57:45.860Z"),
        remarks: "",
      },
      {
        status: "Announced",
        date: new Date("2020-01-21T03:57:53.577Z"),
        remarks: "",
      },
    ],
    deleted_at: null,
    pending_amendments: [
      {
        etd: "2020-01-24T16:00:00.000Z",
      },
    ],
    is_berth_assigned: false,
    has_departure_details: false,
    is_manifest_complete: false,
    has_resource_management: false,
    do_later: false,
    is_local: true,
    has_coasting_manifest: false,
    _id: "5e267661a900bc2c4b171b5c",
    cargo_details: [
      {
        isOpen: true,
        _id: "5e267661a900bc2c4b171b5d",
        uom: null,
      },
    ],
    vessel_name: "MV Florentia 20",
    grt: 0,
    nrt: 0,
    dwt: 63340,
    loa: 200,
    draft: 0,
    beam: 0,
    country: "",
    vessel_owner: "",
    vessel_class: "Bulk Carrier",
    eta: new Date("2020-01-20T16:00:00.000Z"),
    etd: new Date("2020-01-23T16:00:00.000Z"),
    company_id: "5e1f7d9baaf9db3254551543",
    selected_port_call_purposes: [],
    status_id: 1,
    status: "Announced",
    user_id: "5ddb5c83e8073a1191959a43",
    anchorage_logs: [],
    berth_logs: [],
    created_at: new Date("2020-01-21T03:56:17.145Z"),
    updated_at: new Date("2020-01-21T03:56:17.145Z"),
    __v: 3,
    is_vessel_entry_approved: true,
    is_vessel_entry_submitted: true,
  },
  {
    checklist: {
      vessel_entry_registration: {
        is_done: true,
        by: "Subic Grain Admin",
        date: new Date("2020-01-21T03:56:17.158Z"),
      },
      general_manifest_registration: {
        is_done: false,
      },
      berth_assignment: {
        is_done: false,
      },
      resource_allocation: {
        is_done: false,
      },
      vessel_exit_registration: {
        is_done: false,
      },
      cargo_documents_cleared: {
        is_done: false,
      },
      vessel_pre_departure_inspection: {
        is_done: false,
      },
    },
    calendar_schedule: [
      {
        title: "Submitted",
        date: "2020-01-21 11:57",
      },
      {
        title: "Announced",
        date: "2020-01-21 11:57",
      },
    ],
    timeline: [],
    vessel_movement: [],
    status_change_log: [
      {
        status: "Submitted",
        date: new Date("2020-01-21T03:57:45.860Z"),
        remarks: "",
      },
      {
        status: "Announced",
        date: new Date("2020-01-21T03:57:53.577Z"),
        remarks: "",
      },
    ],
    deleted_at: null,
    pending_amendments: [
      {
        etd: "2020-01-24T16:00:00.000Z",
      },
    ],
    is_berth_assigned: false,
    has_departure_details: false,
    is_manifest_complete: false,
    has_resource_management: false,
    do_later: false,
    is_local: true,
    has_coasting_manifest: false,
    _id: "5e267661a900bc2c4b171b5c",
    cargo_details: [
      {
        isOpen: true,
        _id: "5e267661a900bc2c4b171b5d",
        uom: null,
      },
    ],
    vessel_name: "MV Florentia 20",
    grt: 0,
    nrt: 0,
    dwt: 63340,
    loa: 200,
    draft: 0,
    beam: 0,
    country: "",
    vessel_owner: "",
    vessel_class: "Bulk Carrier",
    eta: new Date("2020-01-20T16:00:00.000Z"),
    etd: new Date("2020-01-23T16:00:00.000Z"),
    company_id: "5e1f7d9baaf9db3254551543",
    selected_port_call_purposes: [],
    status_id: 1,
    status: "Announced",
    user_id: "5ddb5c83e8073a1191959a43",
    anchorage_logs: [],
    berth_logs: [],
    created_at: new Date("2020-01-21T03:56:17.145Z"),
    updated_at: new Date("2020-01-21T03:56:17.145Z"),
    __v: 3,
    is_vessel_entry_approved: true,
    is_vessel_entry_submitted: true,
  },
  {
    checklist: {
      vessel_entry_registration: {
        is_done: true,
        by: "Subic Grain Admin",
        date: new Date("2020-01-21T03:56:17.158Z"),
      },
      general_manifest_registration: {
        is_done: false,
      },
      berth_assignment: {
        is_done: false,
      },
      resource_allocation: {
        is_done: false,
      },
      vessel_exit_registration: {
        is_done: false,
      },
      cargo_documents_cleared: {
        is_done: false,
      },
      vessel_pre_departure_inspection: {
        is_done: false,
      },
    },
    calendar_schedule: [
      {
        title: "Submitted",
        date: "2020-01-21 11:57",
      },
      {
        title: "Announced",
        date: "2020-01-21 11:57",
      },
    ],
    timeline: [],
    vessel_movement: [],
    status_change_log: [
      {
        status: "Submitted",
        date: new Date("2020-01-21T03:57:45.860Z"),
        remarks: "",
      },
      {
        status: "Announced",
        date: new Date("2020-01-21T03:57:53.577Z"),
        remarks: "",
      },
    ],
    deleted_at: null,
    pending_amendments: [
      {
        etd: "2020-01-24T16:00:00.000Z",
      },
    ],
    is_berth_assigned: false,
    has_departure_details: false,
    is_manifest_complete: false,
    has_resource_management: false,
    do_later: false,
    is_local: true,
    has_coasting_manifest: false,
    _id: "5e267661a900bc2c4b171b5c",
    cargo_details: [
      {
        isOpen: true,
        _id: "5e267661a900bc2c4b171b5d",
        uom: null,
      },
    ],
    vessel_name: "MV Florentia 20",
    voyage_no: 51258,
    grt: 0,
    nrt: 0,
    dwt: 63340,
    loa: 200,
    draft: 0,
    beam: 0,
    country: "",
    vessel_owner: "",
    vessel_class: "Bulk Carrier",
    eta: new Date("2020-01-20T16:00:00.000Z"),
    etd: new Date("2020-01-23T16:00:00.000Z"),
    company_id: "5e1f7d9baaf9db3254551543",
    selected_port_call_purposes: [],
    status_id: 1,
    status: "Announced",
    user_id: "5ddb5c83e8073a1191959a43",
    anchorage_logs: [],
    berth_logs: [],
    created_at: new Date("2020-01-21T03:56:17.145Z"),
    updated_at: new Date("2020-01-21T03:56:17.145Z"),
    __v: 3,
    is_vessel_entry_approved: true,
    is_vessel_entry_submitted: true,
  },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      greetingDisplay: "Hello",
      activeTab: "1",
      prevTab: "1",
      openDataTable: "",
      currentTime: new Date(),
      currentDate: new Date(),
      SkellyWidths: [
        [180, 100, 100, 80, 120, 120, 80],
        [180, 100, 160, 100, 120, 80, 80],
        [80, 80, 80, 80, 80, 80, 80, 80],
        [80, 80, 80, 80, 80, 80, 80, 80],
        [160, 160, 160, 160, 160],
      ],
      isLoading: true,
      popoverOpen: false,
      dashboardData: {
        arrivals_and_departures_graph: [
          {
            arrival: [],
            departure: [],
          },
        ],
      },
      dashboardTicker: {},
      tickerArrivals: [],
      arrivalServices: [],
      tickerInPort: [],
      tickerDeparture: [],
      tickerRecents: [],
      tickerBerthMonitoring: [],
      tickerBerths: [],
      _PORT_CALLS: [],
      BERTHS: [],
      _BERTHS: [],
      BERTH_SELECTED: [],
      in_use: false,
      show_status: false,
    };
  }

  setDashboardGreeting = () => {
    let dbGreetings = [
      "Welcome back",
      "We're glad you're back",
      "Good day",
      "Well, hello there",
      "Hi there",
      "Good day",
    ];

    let greeting = dbGreetings[Math.floor(Math.random() * dbGreetings.length)];

    if (greeting === "Good day") {
      let thehours = new Date().getHours();
      if (thehours >= 0 && thehours < 12) {
        return "Good morning";
      } else if (thehours >= 12 && thehours < 17) {
        return "Good afternoon";
      } else if (thehours >= 17 && thehours < 24) {
        return "Good evening";
      }
    } else {
      return greeting;
    }
  };

  componentDidMount = () => {
    this.setState({
      greetingDisplay: this.setDashboardGreeting(),
      isLoading: false, // set to true when there is data to be  loaded
    });

    this.getDashboardData();
    this.loadPortCalls();
    this.loadTicker();
    //use when there is data to be loaded
    // this.loadData({}).then(() => {
    //   this.setState({
    //     isLoading: false
    //   });
    // });

    // Clock
    // setInterval(() => this.setState({ currentTime: new Date() }), 1000);
    // setInterval(() => this.setState({ currentDate: new Date() }), 86400000);
  };

  toggle = (tab) => {
    if (this.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  dataTableToggle = (i, open, suf) => {
    let row = i + suf;

    if (open !== row) {
      this.setState({
        openDataTable: row,
      });
    } else {
      this.setState({
        openDataTable: i,
      });
    }
  };

  togglePopover = () => {
    this.setState((prevState) => ({
      popoverOpen: !prevState.popoverOpen,
    }));
  };

  loadTicker = () => {
    api
      .get(this, {
        endpoint: "dashboard/ticker",
        state_key: "dashboardTicker",
      })
      .then((data) => {
        let tick_arrivals,
          tick_inport,
          tick_departure,
          tick_recents,
          tick_berth = [];

        tick_arrivals = data.arrival;
        tick_inport = data.vessels_in_port;
        tick_departure = data.departure;
        tick_recents = data.recents;
        tick_berth = data.berth_monitoring;

        let berth_logs1 = [];
        let berth_logs2 = [];
        let berths = [];

        for (let x in tick_berth) {
          berth_logs1[x] = tick_berth[x].berth_logs;
        }

        for (let y in berth_logs1) {
          for (let z in berth_logs1[y]) {
            berths[z] = berth_logs1[y][z].berth;
          }
        }

        this.setState({
          tickerArrivals: tick_arrivals
            ? tick_arrivals
            : this.state.tickerArrivals,
          tickerInPort: tick_inport ? tick_inport : this.state.tickerInPort,
          tickerDeparture: tick_departure
            ? tick_departure
            : this.state.tickerDeparture,
          tickerRecents: tick_recents ? tick_recents : this.state.tickerRecents,
          tickerBerthMonitoring: tick_berth
            ? tick_berth
            : this.state.tickerBerthMonitoring,
          tickerBerths: berth_logs1 ? berth_logs1 : this.state.tickerBerths,
        });
      });
  };

  getDashboardData = () => {
    api
      .get(this, {
        endpoint: "dashboard/dashboard_data",
        state_key: "dashboardData",
      })
      .then((data) => {});
  };

  loadPortCalls = () => {
    api
      .get(this, {
        endpoint: "portcall",
        state_key: "_PORT_CALLS",
      })
      .then((data) => {
        let arr_in_port = [];
        let arr_arrivals_m = [];
        let arr_arrivals_d = [];
        let today = new Date();

        // IN PORT
        for (let pc in data) {
          if (data[pc].is_in_port === true) {
            arr_in_port[pc] = data[pc];
          }
        }

        for (let ar in data) {
          let dt = new Date(data[ar].eta);

          if (dt.getMonth() === today.getMonth()) {
            arr_arrivals_m[ar] = data[ar];

            let arr_day = new Date(arr_arrivals_m[ar].eta);

            if (
              arr_day.getDay() === today.getDay() ||
              today.getDay() - 1 ||
              today.getDay() + 1
            ) {
              arr_arrivals_d[ar] = data[ar];
            }
          }
        }

        this.setState({
          _VESSELS_IN_PORT: arr_in_port,
        });

        // console.log("PCALSLSLSLS", arr_arrivals_m);
      });
  };

  loadBerths = () => {
    this.setState({
      show_status: false,
    });

    api
      .get(this, {
        endpoint: "berth",
        state_key: "_BERTHS",
      })
      .then((data) => {
        let _berths = [];

        for (let x in data) {
          _berths[x] = data[x].name;
        }

        _berths.sort((a, b) => {
          return ("" + a).localeCompare(b);
        });

        this.setState({
          BERTHS: _berths,
        });
      });
  };

  setSelectedBerth = (b) => {
    let { _BERTHS } = this.state;
    let selectedPCs = [];
    let selectedRow = [];
    let selected_ = [];
    // let today = moment(new Date()).format(constants.__dateFormat);
    let today = new Date();

    this.setState({
      show_status: true,
    });

    for (let x in _BERTHS) {
      if (_BERTHS[x].name === b) {
        selectedPCs[x] = _BERTHS[x].currently_berthed;

        for (let y in selectedPCs) {
          // selectedRow[y] = selectedPCs[y];
          for (let z in selectedPCs[y]) {
            selectedRow[z] = selectedPCs[y][z];
            for (let a in selectedRow) {
              let _berth_eta = new Date(selectedRow[a].berth_eta);
              let _berth_etd = new Date(selectedRow[a].berth_etd);

              if (_berth_eta >= today || _berth_etd >= today) {
                selected_[a] = selectedRow[a];
              }
            }
          }
        }
      }
    }

    if (selected_.length !== 0) {
      this.setState({
        in_use: true,
      });
    } else {
      this.setState({
        in_use: false,
      });
    }

    // console.log("ASDASDASD2", selectedRow);
    // console.log("ASDASDASD1", today);

    this.setState({
      BERTH_SELECTED: selected_,
    });
  };

  render() {
    const {
      greetingDisplay,
      activeTab,
      SkellyWidths,
      isLoading,
      popoverOpen,
      tickerArrivals,
      tickerInPort,
      tickerDeparture,
      tickerRecents,
      tickerBerths,
      BERTH_SELECTED,
      in_use,
      show_status,
      openDataTable,
    } = this.state;
    const recentArrivalsByType = {
      //   title: {
      //     text: "Recent Vessel Arrivals by Type"
      //   },
      //   credits: {
      //     enabled: false
      //   },
      //   chart: {
      //     alignTicks: false,
      //     events: {
      //       load: function(e) {
      //         //this.xAxis[0].setExtremes(oData.first_date, oData.last_date);
      //       }
      //     },
      //     ignoreHiddenSeries: true
      //   },
      //   colors: [
      //     "#f11e5b",
      //     "#68d9f7",
      //     "#9eb9ef",
      //     "#c49eef",
      //     "#7cb5ec",
      //     "#f7a35c",
      //     "#90ee7e",
      //     "#7798BF",
      //     "#aaeeee",
      //     "#ff0066",
      //     "#eeaaee",
      //     "#55BF3B",
      //     "#DF5353",
      //     "#7798BF",
      //     "#aaeeee",
      //     "#ff6666",
      //     "#ffd966",
      //     "#66ffd9",
      //     "#b366ff",
      //     "#ff66d9",
      //     "#e6ffe6"
      //   ],
      //   legend: {
      //     enabled: true,
      //     shadow: true,
      //     itemWidth: 180,

      //     itemStyle: {
      //       fontSize: "15px",
      //       width: 180
      //     }
      //   },
      //   rangeSelector: {
      //     selected: 0
      //   },
      //   navigator: {
      //     height: 40,
      //     xAxis: {
      //       gridLineWidth: 1
      //     },
      //     series: {
      //       type: "column"
      //     }
      //   },
      //   plotOptions: {
      //     series: {
      //       cursor: "pointer",
      //       point: {
      //         events: {
      //           click: function() {},

      //           mouseOver: function() {
      //             //console.log(this)
      //           }
      //         }
      //       },
      //       showInLegend: true,
      //       stacking: "normal",
      //       dataGrouping: {
      //         enabled: true,
      //         forced: true,
      //         units: [["day", [1]]]
      //       },
      //       legend: {
      //         enabled: true,
      //         shadow: true,
      //         itemWidth: 180,

      //         itemStyle: {
      //           fontSize: "15px",
      //           width: 180
      //         }
      //       }
      //     }
      //   },
      //   xAxis: {
      //     ordinal: false
      //   }
      // };

      title: {
        text: "Recent Vessel Arrivals by Type",
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yAxis: {
        title: {
          text: "Number of Vessel Arrivals",
        },
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },
      // plotOptions: {
      //   line: {
      //     dataLabels: {
      //       enabled: true
      //     },
      //     enableMouseTracking: true
      //   }
      // },
      // series: [{
      //   name: 'Barge',
      //   data: [dashboardData.vessel_type_line_graph]
      // }],

      series: [
        {
          name: "Barge",
          data: [
            43934, 52503, 574177, 69658, 97031, 119931, 137133, 154175, 137133,
            154175, 137133,
          ],
        },
        {
          name: "Bulk Carrier",
          data: [
            243916, 2413064, 297142, 29851, 32490, 30282, 38121, 4043415, 4175,
            137133, 154175, 137133,
          ],
        },
        {
          name: "Cargo",
          data: [
            111744, 1727122, 16005, 19771, 20185, 243377, 32147, 39387, 154175,
            137133, 154175, 137133,
          ],
        },
        {
          name: "Fishing",
          data: [
            null,
            null,
            7988,
            12169,
            15112,
            22452,
            34400,
            34227,
            154175,
            137133,
            154175,
            137133,
          ],
        },
        {
          name: "Passenger",
          data: [
            122908, 59428, 8105, 11248, 8989, 11816, 18274, 18111, 1541175,
            137133, 154175, 137133,
          ],
        },
        {
          name: "Pleasure Craft",
          data: [
            12908, 54948, 813105, 11248, 83989, 11816, 18274, 1811, 1454175,
            137133, 1548175, 1367133,
          ],
        },
        {
          name: "Sailing Vessel",
          data: [
            12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111, 154175, 137133,
            154175, 137133,
          ],
        },
        {
          name: "Special Craft",
          data: [
            12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111, 154175, 137133,
            154175, 137133,
          ],
        },
        {
          name: "Tanker",
          data: [
            12908, 5948, 8105, 11248, 89289, 11816, 18274, 18111, 154175,
            137133, 154175, 137133,
          ],
        },
        {
          name: "Tug",
          data: [
            12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111, 154175, 137133,
            154175, 137133,
          ],
        },
        {
          name: "Other",
          data: [
            12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111, 154175, 137133,
            154175, 137133,
          ],
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    };
    const vesselByType = {
      credits: {
        enabled: false,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "All Vessels By Type (30 Days)",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: "Vessel Type",
          colorByPoint: true,
          data: [
            {
              name: "Passenger",
              y: 50,
            },
            {
              name: "Cargo",
              y: 12.5,
            },
            {
              name: "Tug",
              y: 25,
            },
            {
              name: "Pleasure Craft",
              y: 12.5,
            },
          ],
        },
      ],
    };
    const arrivalsAndDepartures = {
      credits: {
        enabled: false,
      },
      colors: ["#f11e5b", "#3a73f7"],
      chart: {
        type: "line",
      },
      title: {
        text: "Arrivals and Departures",
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yAxis: {
        title: {
          text: "No. of Vessels",
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: false,
        },
      },
      series: [
        {
          name: "Arrivals",
          data: this.state.dashboardData.arrivals_and_departures_graph[0]
            .arrival,
        },
        {
          name: "DepartureS",
          data: this.state.dashboardData.arrivals_and_departures_graph[0]
            .departure,
        },
      ],
    };

    const Schedule = (props) => {
      let prev_day = null;
      const date_today = new Date();

      function compare(a, b) {
        const datetimeA = a.datetime;
        const datetimeB = b.datetime;

        let comparison = 0;
        if (datetimeA > datetimeB) {
          comparison = 1;
        } else if (datetimeA < datetimeB) {
          comparison = -1;
        }
        return comparison;
      }

      function prevDay(d) {
        let dtItem =
          d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
        let today =
          date_today.getFullYear() +
          "/" +
          (date_today.getMonth() + 1) +
          "/" +
          date_today.getDate();
        if (prev_day !== dtItem) {
          prev_day = dtItem;
          return (
            <li className="schedule-day d-flex">
              {dtItem === today ? (
                <div>Today</div>
              ) : (
                <Moment format={"MMMM DD, YYYY"}>{d || "-"}</Moment>
              )}
            </li>
          );
        } else {
          return <div className="hr-div"></div>;
        }
      }

      return (
        <React.Fragment>
          {/* { props.data != null ? (
             <React.Fragment>{"No Schedules"}</React.Fragment>
           ) : ( */}
          <ul className="scheduleList">
            {props.data.sort(compare).map((b, $index) => {
              return (
                <React.Fragment key={$index}>
                  {prevDay(b.datetime)}
                  <li className="schedule-item d-flex flex-column">
                    <div className="time-cont d-flex">
                      <Moment format={constants.__timeFormat}>
                        {b.datetime || "-"}
                      </Moment>
                    </div>
                    <div className="item-details flex-column">
                      <div>{b.title}</div>
                      <div>{b.desc}</div>
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
          {/* )} */}
        </React.Fragment>
      );
    };
    const sample_schedule = [
      {
        datetime: new Date("12-11-2019-12:00"),
        title: "Event 1",
        desc: "Desc 1",
      },
      {
        datetime: new Date("11-14-2019-12:00"),
        title: "Event 2",
        desc: "Desc 2",
      },
      {
        datetime: new Date("11-15-2019-13:00"),
        title: "Event 3",
        desc: "Desc 3",
      },
      {
        datetime: new Date("11-14-2019-15:00"),
        title: "Event 4",
        desc: "Desc 4",
      },
    ];

    const berths = this.state.BERTHS;

    return (
      <div>
        <div className="flex-column">
          <Navbar />

          <div className="portDashboard flex-column">
            <div className="d-flex flex-row flex-wrap p-0 mr-n2">
              <div className="col-md-3 col-xs-12 d-flex flex-column justify-content-center dashHeader">
                <div className="d-flex flex-row greeting">
                  {greetingDisplay},
                </div>
                <div className="d-flex flex-row username">
                  <span>{session.get().first_name}</span>!
                </div>
              </div>

              <Media queries={media_queries}>
                {(matches) =>
                  matches.xs ? (
                    <>
                      <SwipeableViews class="swipeable-dashWidgets">
                        <div
                          className="col m-0 d-flex flex-row align-items-center dashWidget green"
                          onClick={() => this.togglePopover}
                          id="Popover-Inport"
                        >
                          <div className="dashWidget-count">
                            {this.state.dashboardData.vessels_in_port}
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <div className="dashWidget-label">In Port</div>
                            <div className="dashWidget-desc d-flex">
                              Updated 3 mins ago
                            </div>
                          </div>
                        </div>
                        <div className="col m-0 d-flex flex-row align-items-center dashWidget orange">
                          <div className="dashWidget-count">
                            {this.state.dashboardData.expected_arrivals}
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <div className="dashWidget-label">
                              Expected Arrivals
                            </div>
                            <div className="dashWidget-desc d-flex">
                              Updated 3 mins ago
                            </div>
                          </div>
                        </div>
                        <div className="col m-0 d-flex flex-row align-items-center dashWidget red">
                          <div className="dashWidget-count">
                            {this.state.dashboardData.vessels_in_berth}
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <div className="dashWidget-label">
                              No. of Vessels Berthed
                            </div>
                            <div className="dashWidget-desc d-flex">
                              Updated 3 mins ago
                            </div>
                          </div>
                        </div>
                      </SwipeableViews>
                      <Popover
                        className="Popover-Inport"
                        placement="bottom"
                        isOpen={popoverOpen}
                        target="Popover-Inport"
                        toggle={this.togglePopover}
                        trigger="legacy"
                      >
                        <PopoverBody>
                          <div className="d-flex flex-column">
                            <div className="col-md-12 d-flex flex-row align-items-center dashWidget orange">
                              <div className="dashWidget-count">
                                {this.state.dashboardData.vessels_arrived}
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <div className="dashWidget-label">
                                  Vessel Arrived
                                </div>
                                <div className="dashWidget-desc d-flex">
                                  last 24 hours
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 d-flex flex-row align-items-center dashWidget blue">
                              <div className="dashWidget-count">
                                {this.state.dashboardData.vessels_departed}
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <div className="dashWidget-label">
                                  Vessel Departures
                                </div>
                                <div className="dashWidget-desc d-flex">
                                  last 24 hours
                                </div>
                              </div>
                            </div>
                          </div>
                        </PopoverBody>
                      </Popover>
                    </>
                  ) : (
                    <div className="col-md-9 col-xs-12 d-flex flex-row justify-content-center dashWidgetCont">
                      <div
                        className="col-md-4 d-flex flex-row align-items-center dashWidget green"
                        onClick={() => this.togglePopover}
                        id="Popover-Inport"
                      >
                        <div className="dashWidget-count">
                          {this.state.dashboardData.vessels_in_port}
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <div className="dashWidget-label">In Port</div>
                          <div className="dashWidget-desc d-flex">
                            Updated 3 mins ago
                          </div>
                        </div>
                      </div>
                      <Popover
                        className="Popover-Inport"
                        placement="bottom"
                        isOpen={popoverOpen}
                        target="Popover-Inport"
                        toggle={this.togglePopover}
                        trigger="legacy"
                      >
                        <PopoverBody>
                          <div className="d-flex flex-column">
                            <div className="col-md-12 d-flex flex-row align-items-center dashWidget orange">
                              <div className="dashWidget-count">
                                {this.state.dashboardData.vessels_arrived}
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <div className="dashWidget-label">
                                  Vessel Arrived
                                </div>
                                <div className="dashWidget-desc d-flex">
                                  last 24 hours
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 d-flex flex-row align-items-center dashWidget blue">
                              <div className="dashWidget-count">
                                {this.state.dashboardData.vessels_departed}
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <div className="dashWidget-label">
                                  Vessel Departures
                                </div>
                                <div className="dashWidget-desc d-flex">
                                  last 24 hours
                                </div>
                              </div>
                            </div>
                          </div>
                        </PopoverBody>
                      </Popover>

                      <div className="col-md-4 d-flex flex-row align-items-center dashWidget orange">
                        <div className="dashWidget-count">
                          {this.state.dashboardData.expected_arrivals}
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <div className="dashWidget-label">
                            Expected Arrivals
                          </div>
                          <div className="dashWidget-desc d-flex">
                            Updated 3 mins ago
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 d-flex flex-row align-items-center dashWidget red">
                        <div className="dashWidget-count">
                          {this.state.dashboardData.vessels_in_berth}
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <div className="dashWidget-label">
                            No. of Vessels Berthed
                          </div>
                          <div className="dashWidget-desc d-flex">
                            Updated 3 mins ago
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </Media>
            </div>

            <div className="d-flex flex-row flex-wrap mx-n2">
              <div className="form-container-box calendar-cont col-md-6 col-xs-12 m-2 brad-p3 d-flex flex-row flex-wrap">
                <div className="col-md-8">
                  <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[dayGridPlugin]}
                  />
                </div>
                <div className="col-md-4">
                  <Schedule data={sample_schedule} />
                </div>
              </div>
              <div className="form-container-box col-md-6 col-xs-12 m-2 py-2 brad-p3">
                {/* <HighchartsReact
                  highcharts={Highcharts}
                  options={recentArrivalsByType}
                /> */}
                <HighchartsReact
                  highcharts={HighStock}
                  //constructorType={"stockChart"}
                  options={recentArrivalsByType}
                />
              </div>
              <div className="form-container-box col-md-6 col-xs-12 m-2 py-2 brad-p3">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={vesselByType}
                />
              </div>
              <div className="form-container-box col-md-6 col-xs-12 m-2 py-2 brad-p3">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={arrivalsAndDepartures}
                />
              </div>
            </div>

            <div className="d-flex flex-row p-0 mt-2">
              <div className="portCallList form-container-box col-md-12">
                <Nav tabs>
                  <NavItem className="svg">
                    {activeTab === "1" ? (
                      <Arrival />
                    ) : activeTab === "2" ? (
                      <InPort />
                    ) : activeTab === "3" ? (
                      <BerthMonitoring />
                    ) : activeTab === "4" ? (
                      <Departure />
                    ) : (
                      <Recents />
                    )}
                  </NavItem>
                  <Media queries={media_queries}>
                    {(matches) =>
                      matches.xs || matches.sm ? (
                        <NavItem
                          className="active justify-contents-center"
                          onClick={() => {
                            this.toggle("0");
                          }}
                        >
                          <NavLink className="active">
                            {activeTab === "0" && "Choose Ticker"}
                            {activeTab === "1" && "Arrival"}
                            {activeTab === "2" && "Vessels In Port"}
                            {activeTab === "3" && "Berth Monitoring"}
                            {activeTab === "4" && "Departure"}
                            {activeTab === "5" && "Recents"}
                            <Icon
                              name={
                                activeTab === "0" ? "caret-up" : "caret-down"
                              }
                              className="nav-caret"
                            />
                          </NavLink>
                        </NavItem>
                      ) : (
                        <>
                          <NavItem
                            className={
                              activeTab === "1"
                                ? "active justify-contents-center"
                                : "justify-contents-center"
                            }
                          >
                            <NavLink
                              className={activeTab === "1" ? "active" : ""}
                              onClick={() => {
                                this.toggle("1");
                              }}
                            >
                              Arrival
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={
                              activeTab === "2"
                                ? "active justify-contents-center"
                                : "justify-contents-center"
                            }
                          >
                            <NavLink
                              className={activeTab === "2" ? "active" : ""}
                              onClick={() => {
                                this.toggle("2");
                              }}
                            >
                              Vessels In Port
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={
                              activeTab === "3"
                                ? "active justify-contents-center"
                                : "justify-contents-center"
                            }
                          >
                            <NavLink
                              className={activeTab === "3" ? "active" : ""}
                              onClick={() => {
                                this.toggle("3");
                                this.loadBerths();
                              }}
                            >
                              Berth Monitoring
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={
                              activeTab === "4"
                                ? "active justify-contents-center"
                                : "justify-contents-center"
                            }
                          >
                            <NavLink
                              className={activeTab === "4" ? "active" : ""}
                              onClick={() => {
                                this.toggle("4");
                              }}
                            >
                              Departure
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={
                              activeTab === "5"
                                ? "active justify-contents-center"
                                : "justify-contents-center"
                            }
                          >
                            <NavLink
                              className={activeTab === "5" ? "active" : ""}
                              onClick={() => {
                                this.toggle("5");
                              }}
                            >
                              Recents
                            </NavLink>
                          </NavItem>
                        </>
                      )
                    }
                  </Media>
                  <NavItem
                    onClick={() => {
                      if (activeTab === "1") {
                        window.open("/ticker/arrivals", "_blank");
                      }

                      if (activeTab === "2") {
                        window.open("/ticker/vessels_in_port", "_blank");
                      }

                      if (activeTab === "3") {
                        window.open("/ticker/berth_monitoring", "_blank");
                      }
                      if (activeTab === "4") {
                        window.open("/ticker/departure", "_blank");
                      }
                      if (activeTab === "5") {
                        window.open("/ticker/recents", "_blank");
                      }
                    }}
                    className="ml-auto externalLink d-flex flex-column justify-content-center align-items-center"
                  >
                    <Icon name="external-link"></Icon>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  {/* TICKER NAV SM */}
                  <TabPane tabId="0">
                    <Nav className="tab-navs">
                      <NavItem
                        className={
                          activeTab === "1"
                            ? "active justify-contents-center"
                            : "justify-contents-center"
                        }
                      >
                        <NavLink
                          className={activeTab === "1" ? "active" : ""}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Arrival
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeTab === "2"
                            ? "active justify-contents-center"
                            : "justify-contents-center"
                        }
                      >
                        <NavLink
                          className={activeTab === "2" ? "active" : ""}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Vessels In Port
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeTab === "3"
                            ? "active justify-contents-center"
                            : "justify-contents-center"
                        }
                      >
                        <NavLink
                          className={activeTab === "3" ? "active" : ""}
                          onClick={() => {
                            this.toggle("3");
                            this.loadBerths();
                          }}
                        >
                          Berth Monitoring
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeTab === "4"
                            ? "active justify-contents-center"
                            : "justify-contents-center"
                        }
                      >
                        <NavLink
                          className={activeTab === "4" ? "active" : ""}
                          onClick={() => {
                            this.toggle("4");
                          }}
                        >
                          Departure
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          activeTab === "5"
                            ? "active justify-contents-center"
                            : "justify-contents-center"
                        }
                      >
                        <NavLink
                          className={activeTab === "5" ? "active" : ""}
                          onClick={() => {
                            this.toggle("5");
                          }}
                        >
                          Recents
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </TabPane>

                  {/* ARRIVAL TICKER */}
                  <TabPane tabId="1">
                    <table className="arrivalTable">
                      <Media queries={media_queries}>
                        {(matches) =>
                          matches.xs || matches.sm ? (
                            <>
                              {tickerArrivals.map((dt, i) => (
                                <>
                                  <tbody
                                    key={i + "-title"}
                                    className={
                                      openDataTable === i + "-arrivalDetails"
                                        ? "row-title active"
                                        : "row-title"
                                    }
                                    onClick={() => {
                                      this.dataTableToggle(
                                        i,
                                        openDataTable,
                                        "-arrivalDetails"
                                      );
                                    }}
                                  >
                                    <tr>
                                      <td colspan="2">
                                        <span>{dt.vessel_name}</span>
                                        <br />
                                        <span
                                          className={!dt.voyage_no && "no-data"}
                                        >
                                          {dt.voyage_no ? dt.voyage_no : "0000"}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          className={
                                            dt.eta
                                              ? "ticker-eta"
                                              : "ticker-eta no-data"
                                          }
                                        >
                                          {dt.eta
                                            ? moment(dt.eta).format(
                                                constants.__dateTimeFormat
                                              )
                                            : "00-AAA-0000 00:00"}
                                        </span>
                                        <br />
                                        <span
                                          className={
                                            dt.ata
                                              ? "ticker-ata"
                                              : "ticker-ata no-data"
                                          }
                                        >
                                          {dt.ata
                                            ? moment(dt.ata).format(
                                                constants.__dateTimeFormat
                                              )
                                            : "00-AAA-0000 00:00"}
                                        </span>
                                      </td>
                                      <td>
                                        <Icon
                                          name={
                                            openDataTable ===
                                            i + "-arrivalDetails"
                                              ? "caret-up"
                                              : "caret-down"
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                  {openDataTable === i + "-arrivalDetails" && (
                                    <tbody
                                      className="row-details"
                                      key={i + "-arrivalDetails"}
                                    >
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Ship Agent
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !dt.shipping_agency && "no-data"
                                          }
                                        >
                                          {dt.shipping_agency &&
                                          dt.shipping_agency.name
                                            ? dt.shipping_agency.name
                                            : "---"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Port of Origin
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !dt.port_of_origin && "no-data"
                                          }
                                        >
                                          {dt.port_of_origin
                                            ? dt.port_of_origin
                                            : "---"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Cargo Handlers
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !(
                                              dt.selected_port_call_purposes
                                                .length > 0
                                            ) && "no-data"
                                          }
                                        >
                                          {dt.selected_port_call_purposes
                                            .length > 0
                                            ? dt.selected_port_call_purposes.map(
                                                (sp, i) => (
                                                  <div key={i}>
                                                    {sp.arrastre
                                                      ? sp.arrastre
                                                      : ""}
                                                    {sp.arrastre &&
                                                    sp.stevedoring
                                                      ? " / "
                                                      : ""}
                                                    {sp.stevedoring
                                                      ? sp.stevedoring
                                                      : ""}
                                                  </div>
                                                )
                                              )
                                            : "---"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">Cargo</td>
                                        <td
                                          colspan="2"
                                          className={
                                            !(
                                              dt.cargo_details &&
                                              dt.cargo_details.length > 0
                                            ) && "no-data"
                                          }
                                        >
                                          {dt.cargo_details &&
                                          dt.cargo_details.length > 0
                                            ? dt.cargo_details[0].item
                                            : "---"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ width: "18%" }}>Name of Ship</th>
                                  <th style={{ width: "12%" }}>Voyage No.</th>
                                  <th style={{ width: "14%" }}>Ship Agent</th>
                                  <th style={{ width: "10%" }}>
                                    Port of Origin
                                  </th>
                                  <th style={{ width: "15%" }}>
                                    Estimated Date & Time of Arrival
                                  </th>
                                  <th style={{ width: "17%" }}>
                                    Cargo Handlers
                                  </th>
                                  <th style={{ width: "12%" }}>Cargo</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {tickerArrivals.map((dt, i) => (
                                  <tr key={i}>
                                    <td>{dt.vessel_name}</td>
                                    <td>{dt.voyage_no}</td>
                                    <td>
                                      {dt.shipping_agency &&
                                      dt.shipping_agency.name
                                        ? dt.shipping_agency.name
                                        : ""}
                                    </td>
                                    <td>{dt.port_of_origin}</td>
                                    <td>
                                      {dt.eta ? (
                                        <p className="m0">
                                          <span className="fw600">ETA: </span>
                                          {moment(dt.eta).format(
                                            constants.__dateTimeFormat
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {dt.ata ? (
                                        <p
                                          className="m0"
                                          style={{ paddingTop: "0.5em" }}
                                        >
                                          <span className="fw600">ATA: </span>
                                          {moment(dt.ata).format(
                                            constants.__dateTimeFormat
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {dt.selected_port_call_purposes.map(
                                        (sp, i) => (
                                          <div key={i}>
                                            {sp.arrastre ? sp.arrastre : ""}
                                            {sp.arrastre && sp.stevedoring
                                              ? " / "
                                              : ""}
                                            {sp.stevedoring
                                              ? sp.stevedoring
                                              : ""}
                                          </div>
                                        )
                                      )}
                                    </td>
                                    <td>
                                      {dt.cargo_details &&
                                      dt.cargo_details.length > 0
                                        ? dt.cargo_details[0].item
                                        : "-"}
                                    </td>
                                    <td>{dt.is_arrived ? "ARRIVED" : ""}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <SkellyBarTableRow
                                loading={isLoading}
                                widths={SkellyWidths[0]}
                                dark
                              />
                            </>
                          )
                        }
                      </Media>
                    </table>
                  </TabPane>

                  {/* VESSELS IN PORT TICKER */}
                  <TabPane tabId="2">
                    <table className="arrivalTable">
                      <Media queries={media_queries}>
                        {(matches) =>
                          matches.xs || matches.sm ? (
                            <>
                              {tickerInPort.map((ip, i) => (
                                <>
                                  <tbody
                                    key={i + "-title"}
                                    className={
                                      openDataTable === i + "-inportDetails"
                                        ? "row-title active"
                                        : "row-title"
                                    }
                                    onClick={() => {
                                      this.dataTableToggle(
                                        i,
                                        openDataTable,
                                        "-inportDetails"
                                      );
                                    }}
                                  >
                                    <tr>
                                      <td colspan="2">
                                        <span>{ip.vessel_name}</span>
                                        <br />
                                        <span
                                          className={!ip.voyage_no && "no-data"}
                                        >
                                          {ip.voyage_no ? ip.voyage_no : "0000"}
                                        </span>
                                      </td>
                                      <td>
                                        {/* NOTE: Replace false with berth var. */}
                                        <span className={!false && "no-data"}>
                                          {false ? false : "---"}
                                        </span>
                                        <br />
                                        <span>{ip.status}</span>
                                      </td>
                                      <td>
                                        <Icon
                                          name={
                                            openDataTable ===
                                            i + "-inportDetails"
                                              ? "caret-up"
                                              : "caret-down"
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                  {openDataTable === i + "-inportDetails" && (
                                    <tbody
                                      className="row-details"
                                      key={i + "-inportDetails"}
                                    >
                                      <tr>
                                        <td></td>
                                        <td className="row-label">Cargo</td>
                                        <td
                                          colspan="2"
                                          className={
                                            !(
                                              ip.cargo_details &&
                                              ip.cargo_details.length > 0
                                            ) && "no-data"
                                          }
                                        >
                                          {ip.cargo_details &&
                                          ip.cargo_details.length > 0
                                            ? ip.cargo_details[0].item
                                            : "---"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Cargo Handlers
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !(
                                              ip.selected_port_call_purposes
                                                .length > 0
                                            ) && "no-data"
                                          }
                                        >
                                          {ip.selected_port_call_purposes
                                            .length > 0
                                            ? ip.selected_port_call_purposes.map(
                                                (sp, i) => (
                                                  <div key={i}>
                                                    {sp.arrastre
                                                      ? sp.arrastre
                                                      : ""}
                                                    {sp.arrastre &&
                                                    sp.stevedoring
                                                      ? " / "
                                                      : ""}
                                                    {sp.stevedoring
                                                      ? sp.stevedoring
                                                      : ""}
                                                  </div>
                                                )
                                              )
                                            : "---"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          B/L Quantity
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !(
                                              ip.cargo_details &&
                                              ip.cargo_details.length > 0
                                            ) && "no-data"
                                          }
                                        >
                                          {" "}
                                          {ip.cargo_details &&
                                          ip.cargo_details.length > 0
                                            ? ip.cargo_details[0].amount +
                                              " " +
                                              ip.cargo_details[0].uom
                                            : "---"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ width: "20%" }}>Name of Ship</th>
                                  <th style={{ width: "12%" }}>Voyage No.</th>
                                  <th style={{ width: "20%" }}>Cargo</th>
                                  <th style={{ width: "12%" }}>
                                    Cargo Handlers
                                  </th>
                                  <th style={{ width: "18%" }}>B/L Quantity</th>
                                  <th style={{ width: "9%" }}>Berth</th>
                                  <th style={{ width: "9%" }}>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tickerInPort.map((ip, i) => (
                                  <tr key={i}>
                                    <td>{ip.vessel_name}</td>
                                    <td>{ip.voyage_no}</td>
                                    <td>
                                      {ip.shipping_agency &&
                                      ip.shipping_agency.name
                                        ? ip.shipping_agency.name
                                        : ""}
                                    </td>
                                    <td>
                                      {ip.selected_port_call_purposes.map(
                                        (sp, i) => (
                                          <div key={i}>
                                            {sp.arrastre ? sp.arrastre : ""}
                                            {sp.arrastre && sp.stevedoring
                                              ? " / "
                                              : ""}
                                            {sp.stevedoring
                                              ? sp.stevedoring
                                              : ""}
                                          </div>
                                        )
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {ip.cargo_details &&
                                      ip.cargo_details.length > 0
                                        ? ip.cargo_details[0].amount +
                                          " " +
                                          ip.cargo_details[0].uom
                                        : "-"}
                                    </td>
                                    <td></td>
                                    <td>{ip.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <SkellyBarTableRow
                                loading={isLoading}
                                widths={SkellyWidths[1]}
                                dark
                              />
                            </>
                          )
                        }
                      </Media>
                    </table>
                  </TabPane>

                  {/* BERTH MONITORING TICKER */}
                  <TabPane tabId="3">
                    <div className="d-flex flex-row flex-wrap">
                      <div className="col p-0 d-flex flex-column berth-select justify-content-center align-items-center">
                        <BerthMonitoring />
                        <DropdownList
                          filter
                          textField="Berth"
                          defaultValue={berths[1]}
                          data={berths}
                          onChange={(berth) => this.setSelectedBerth(berth)}
                        />
                        <div
                          className={
                            in_use ? "status inuse" : "status available"
                          }
                        >
                          {show_status ? (in_use ? "In Use" : "Available") : ""}
                        </div>
                      </div>
                      <div className="col-md-10 p-0">
                        <table className="arrivalTable">
                          <Media queries={media_queries}>
                            {(matches) =>
                              matches.xs || matches.sm ? (
                                <>
                                  {BERTH_SELECTED.map((bs, i) => (
                                    <>
                                      <tbody
                                        key={i + "-title"}
                                        className={
                                          openDataTable === i + "-berthmDetails"
                                            ? "row-title active"
                                            : "row-title"
                                        }
                                        onClick={() => {
                                          this.dataTableToggle(
                                            i,
                                            openDataTable,
                                            "-berthmDetails"
                                          );
                                        }}
                                      >
                                        <tr>
                                          <td colspan="2">
                                            <span>{bs.vessel_name}</span>
                                            <br />
                                            <span
                                              className={
                                                !bs.voyage_number && "no-data"
                                              }
                                            >
                                              {bs.voyage_number
                                                ? bs.voyage_number
                                                : "0000"}
                                            </span>
                                          </td>
                                          <td>
                                            <span>{bs.status}</span>
                                          </td>
                                          <td>
                                            <Icon
                                              name={
                                                openDataTable ===
                                                i + "-berthmDetails"
                                                  ? "caret-up"
                                                  : "caret-down"
                                              }
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                      {openDataTable ===
                                        i + "-berthmDetails" && (
                                        <tbody
                                          className="row-details"
                                          key={i + "-berthmDetails"}
                                        >
                                          <tr>
                                            <td></td>
                                            <td className="row-label">
                                              Arrival
                                            </td>
                                            <td colspan="2">
                                              <span
                                                className={
                                                  bs.berth_eta
                                                    ? "ticker-eta"
                                                    : "ticker-eta no-data"
                                                }
                                              >
                                                {bs.berth_eta
                                                  ? moment(bs.berth_eta).format(
                                                      constants.__dateTimeFormat
                                                    )
                                                  : "00-AAA-0000 00:00"}
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td className="row-label">
                                              Departure
                                            </td>
                                            <td colspan="2">
                                              <span
                                                className={
                                                  bs.berth_etd
                                                    ? "ticker-etd"
                                                    : "ticker-etd no-data"
                                                }
                                              >
                                                {bs.berth_etd
                                                  ? moment(bs.berth_etd).format(
                                                      constants.__dateTimeFormat
                                                    )
                                                  : "00-AAA-0000 00:00"}
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td className="row-label">Cargo</td>
                                            <td
                                              colspan="2"
                                              className={
                                                !bs.cargo_details && "no-data"
                                              }
                                            >
                                              {bs.cargo_details
                                                ? bs.cargo_details
                                                : "---"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td className="row-label">
                                              Cargo Handlers
                                            </td>
                                            <td
                                              colspan="2"
                                              className={
                                                !bs.cargo_handlers && "no-data"
                                              }
                                            >
                                              {bs.cargo_handlers
                                                ? bs.cargo_handlers
                                                : "---"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <thead>
                                    <tr>
                                      <th style={{ width: "15%" }}>
                                        Name of Ship
                                      </th>
                                      <th style={{ width: "17%" }}>
                                        Estimated Date <br /> & Time of Arrival
                                      </th>
                                      <th style={{ width: "17%" }}>
                                        Estimated Date <br /> & Time of
                                        Departure.
                                      </th>
                                      <th style={{ width: "10%" }}>
                                        Voyage <br /> No.
                                      </th>
                                      <th style={{ width: "15%" }}>Cargo</th>
                                      <th style={{ width: "15%" }}>
                                        Cargo Handlers
                                      </th>
                                      <th style={{ width: "10%" }}>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {BERTH_SELECTED.map((bs, i) => (
                                      <tr key={i}>
                                        <td>{bs.vessel_name}</td>
                                        <td>
                                          {bs.berth_eta
                                            ? moment(bs.berth_eta).format(
                                                constants.__dateTimeFormat
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {bs.berth_etd
                                            ? moment(bs.berth_etd).format(
                                                constants.__dateTimeFormat
                                              )
                                            : ""}
                                        </td>
                                        <td>{bs.voyage_number}</td>
                                        <td>{bs.cargo_details}</td>
                                        <td>{bs.cargo_handlers}</td>
                                        <td>{bs.status}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  <SkellyBarTableRow
                                    loading={isLoading}
                                    widths={SkellyWidths[2]}
                                    dark
                                  />
                                </>
                              )
                            }
                          </Media>
                        </table>
                      </div>
                    </div>
                  </TabPane>

                  {/* DEPARTURE TICKER */}
                  <TabPane tabId="4">
                    <table className="arrivalTable">
                      <Media queries={media_queries}>
                        {(matches) =>
                          matches.xs || matches.sm ? (
                            <>
                              {tickerDeparture.map((td, i) => (
                                <>
                                  <tbody
                                    key={i + "-title"}
                                    className={
                                      openDataTable === i + "-tdDetails"
                                        ? "row-title active"
                                        : "row-title"
                                    }
                                    onClick={() => {
                                      this.dataTableToggle(
                                        i,
                                        openDataTable,
                                        "-tdDetails"
                                      );
                                    }}
                                  >
                                    <tr>
                                      <td colspan="2">
                                        <span>{td.vessel_name}</span>
                                        <br />
                                        <span
                                          className={!td.voyage_no && "no-data"}
                                        >
                                          {td.voyage_no ? td.voyage_no : "0000"}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          className={
                                            td.etd
                                              ? "ticker-etd"
                                              : "ticker-etd no-data"
                                          }
                                        >
                                          {td.etd
                                            ? moment(td.etd).format(
                                                constants.__dateTimeFormat
                                              )
                                            : "00-AAA-0000 00:00"}
                                        </span>
                                        <br />
                                        <span
                                          className={
                                            td.atd
                                              ? "ticker-atd"
                                              : "ticker-atd no-data"
                                          }
                                        >
                                          {td.atd
                                            ? moment(td.atd).format(
                                                constants.__dateTimeFormat
                                              )
                                            : "00-AAA-0000 00:00"}
                                        </span>
                                      </td>
                                      <td>
                                        <Icon
                                          name={
                                            openDataTable === i + "-tdDetails"
                                              ? "caret-up"
                                              : "caret-down"
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                  {openDataTable === i + "-tdDetails" && (
                                    <tbody
                                      className="row-details"
                                      key={i + "-arrivalDetails"}
                                    >
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Ship Agent
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !(
                                              td.selected_port_call_purposes
                                                .length > 0
                                            ) && "no-data"
                                          }
                                        >
                                          {td.selected_port_call_purposes
                                            .length > 0
                                            ? td.selected_port_call_purposes.map(
                                                (sp, i) => (
                                                  <div key={i}>
                                                    {sp.arrastre
                                                      ? sp.arrastre
                                                      : ""}
                                                    {sp.arrastre &&
                                                    sp.stevedoring
                                                      ? " / "
                                                      : ""}
                                                    {sp.stevedoring
                                                      ? sp.stevedoring
                                                      : ""}
                                                  </div>
                                                )
                                              )
                                            : "---"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Port of Origin
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !td.port_of_origin && "no-data"
                                          }
                                        >
                                          {td.port_of_origin
                                            ? td.port_of_origin
                                            : "---"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Destination
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !td.port_of_destination && "no-data"
                                          }
                                        >
                                          {td.port_of_destination
                                            ? td.port_of_destination
                                            : "---"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Total No. of Crew
                                        </td>
                                        <td
                                          colspan="2"
                                          className={
                                            !td.total_number_of_pd_crew &&
                                            "no-data"
                                          }
                                        >
                                          {td.total_number_of_pd_crew
                                            ? td.total_number_of_pd_crew
                                            : "---"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ width: "12.5%" }}>
                                    Name of Ship
                                  </th>
                                  <th style={{ width: "12.5%" }}>Voyage No.</th>
                                  <th style={{ width: "12.5%" }}>Ship Agent</th>
                                  <th style={{ width: "12.5%" }}>
                                    Port of Origin
                                  </th>
                                  <th style={{ width: "12.5%" }}>
                                    Destination
                                  </th>
                                  <th style={{ width: "12.5%" }}>
                                    Total No.
                                    <br />
                                    of Crew
                                  </th>
                                  <th style={{ width: "15%" }}>
                                    Estimated Date & Time of Departure
                                  </th>
                                  <th style={{ width: "12.5%" }}>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tickerDeparture.map((td, i) => (
                                  <tr key={i}>
                                    <td>{td.vessel_name}</td>
                                    <td>{td.voyage_no}</td>
                                    <td>
                                      {td.selected_port_call_purposes.map(
                                        (sp, i) => (
                                          <div key={i}>
                                            {sp.arrastre ? sp.arrastre : ""}
                                            {sp.arrastre && sp.stevedoring
                                              ? " / "
                                              : ""}
                                            {sp.stevedoring
                                              ? sp.stevedoring
                                              : ""}
                                          </div>
                                        )
                                      )}
                                    </td>
                                    <td>{td.port_of_origin}</td>
                                    <td>{td.port_of_destination}</td>
                                    <td>{td.total_number_of_pd_crew}</td>
                                    <td>
                                      {td.etd ? (
                                        <p className="m0">
                                          <span className="fw600">ETD: </span>
                                          {moment(td.etd).format(
                                            constants.__dateTimeFormat
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {td.atd ? (
                                        <p
                                          className="m0"
                                          style={{ paddingTop: "0.5em" }}
                                        >
                                          <span className="fw600">ATD: </span>
                                          {moment(td.atd).format(
                                            constants.__dateTimeFormat
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>{td.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                              <SkellyBarTableRow
                                loading={isLoading}
                                widths={SkellyWidths[3]}
                                dark
                              />
                            </>
                          )
                        }
                      </Media>
                    </table>
                  </TabPane>

                  {/* RECENTS TICKER */}
                  <TabPane tabId="5">
                    <table className="arrivalTable">
                      <Media queries={media_queries}>
                        {(matches) =>
                          matches.xs || matches.sm ? (
                            <>
                              {tickerRecents.map((tr, i) => (
                                <>
                                  <tbody
                                    key={i + "-title"}
                                    className={
                                      openDataTable === i + "-arrivalDetails"
                                        ? "row-title active"
                                        : "row-title"
                                    }
                                    onClick={() => {
                                      this.dataTableToggle(
                                        i,
                                        openDataTable,
                                        "-arrivalDetails"
                                      );
                                    }}
                                  >
                                    <tr>
                                      <td colspan="3">
                                        <span>{tr.vessel_name}</span>
                                        <br />
                                        <span
                                          className={!tr.voyage_no && "no-data"}
                                        >
                                          {tr.voyage_no ? tr.voyage_no : "0000"}
                                        </span>
                                      </td>
                                      <td>
                                        <Icon
                                          name={
                                            openDataTable ===
                                            i + "-arrivalDetails"
                                              ? "caret-up"
                                              : "caret-down"
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                  {openDataTable === i + "-arrivalDetails" && (
                                    <tbody
                                      className="row-details"
                                      key={i + "-arrivalDetails"}
                                    >
                                      <tr>
                                        <td></td>
                                        <td className="row-label">Arrival</td>
                                        <td colspan="2">
                                          <span
                                            className={
                                              tr.eta
                                                ? "ticker-eta"
                                                : "ticker-eta no-data"
                                            }
                                          >
                                            {tr.eta
                                              ? moment(tr.eta).format(
                                                  constants.__dateTimeFormat
                                                )
                                              : "00-AAA-0000 00:00"}
                                          </span>
                                          <br />
                                          <span
                                            className={
                                              tr.ata
                                                ? "ticker-ata"
                                                : "ticker-ata no-data"
                                            }
                                          >
                                            {tr.ata
                                              ? moment(tr.ata).format(
                                                  constants.__dateTimeFormat
                                                )
                                              : "00-AAA-0000 00:00"}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">Departure</td>
                                        <td colspan="2">
                                          <span
                                            className={
                                              tr.etd
                                                ? "ticker-etd"
                                                : "ticker-etd no-data"
                                            }
                                          >
                                            {tr.etd
                                              ? moment(tr.etd).format(
                                                  constants.__dateTimeFormat
                                                )
                                              : "00-AAA-0000 00:00"}
                                          </span>
                                          <br />
                                          <span
                                            className={
                                              tr.atd
                                                ? "ticker-atd"
                                                : "ticker-atd no-data"
                                            }
                                          >
                                            {tr.atd
                                              ? moment(tr.atd).format(
                                                  constants.__dateTimeFormat
                                                )
                                              : "00-AAA-0000 00:00"}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Berth Laytime
                                        </td>
                                        <td colspan="2"></td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td className="row-label">
                                          Port Laytime
                                        </td>
                                        <td colspan="2"></td>
                                      </tr>
                                    </tbody>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <thead>
                                <tr>
                                  <th style={{ width: "20%" }}>Vessel Name</th>
                                  <th style={{ width: "20%" }}>Arrival</th>
                                  <th style={{ width: "20%" }}>Departure</th>
                                  <th style={{ width: "20%" }}>
                                    Berth Laytime
                                  </th>
                                  <th style={{ width: "20%" }}>Port Laytime</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tickerRecents.map((tr, i) => (
                                  <tr key={i}>
                                    <td>{tr.vessel_name}</td>
                                    <td>
                                      {tr.eta ? (
                                        <p className="m0">
                                          <span className="fw600">ETD: </span>
                                          {moment(tr.eta).format(
                                            constants.__dateTimeFormat
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {tr.ata ? (
                                        <p
                                          className="m0"
                                          style={{ paddingTop: "0.5em" }}
                                        >
                                          <span className="fw600">ATD: </span>
                                          {moment(tr.ata).format(
                                            constants.__dateTimeFormat
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {tr.etd ? (
                                        <p className="m0">
                                          <span className="fw600">ETD: </span>
                                          {moment(tr.etd).format(
                                            constants.__dateTimeFormat
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      {tr.atd ? (
                                        <p
                                          className="m0"
                                          style={{ paddingTop: "0.5em" }}
                                        >
                                          <span className="fw600">ATD: </span>
                                          {moment(tr.atd).format(
                                            constants.__dateTimeFormat
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                ))}
                              </tbody>
                              <SkellyBarTableRow
                                loading={isLoading}
                                widths={SkellyWidths[4]}
                                dark
                              />
                            </>
                          )
                        }
                      </Media>
                    </table>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
