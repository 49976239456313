import React, { Component, useState } from "react";
import {
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Form
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Icon } from "react-fa";
import Moment from "react-moment";
import Navbar from "../../Navbar/Navbar";
import "./CargoHandlerMaintenance.scss";
import Swal from "sweetalert2";
import api from "../../../helpers/api.helper";
import AlertModal from "../../Tools/AlertModal/AlertModal";

class CargoHandlerMaintenance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      isEdit: false,
      isAlert: "",
      modalMsg: "",
      tglAler: false,
      CARGO_HANDLERS: [],
      CARGO_HANDLER: [],
      CONTACT_PERSON: {
        name: "",
        contact_number: "",
        email: ""
      },
      ERR: {
        name: false
      }
    };
  }

  componentDidMount = () => {
    this.loadData();
    // this.handleAddTugBoat();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "cargo_handler",
        state_key: "CARGO_HANDLERS"
      })
      .then(data => {
        const contact_person = {
          name: "",
          address: "",
          email: ""
        };

        let cargo_handlers = [];

        for (let x in data) {
          if (!data[x].deleted_at) {
            cargo_handlers[x] = data[x];
          }
        }

        this.setState({
          CARGO_HANDLERS: cargo_handlers,
          CONTACT_PERSON: data.contact_person
            ? data.contact_person
            : contact_person
        });
      });
  };

  toggleAddCargoHandler = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  };

  newCargoHandler = () => {
    let { CARGO_HANDLER } = this.state;

    let cargo_handler = [];

    CARGO_HANDLER = cargo_handler;

    this.setState({
      openModal: !this.state.openModal,
      isEdit: false,
      CARGO_HANDLER
    });
  };

  handleSave = () => {
    let { CARGO_HANDLER, CONTACT_PERSON } = this.state;

    if (CARGO_HANDLER.name) {
      api
        .save(this, {
          endpoint: "cargo_handler",
          _data: {
            name: CARGO_HANDLER.name,
            address: CARGO_HANDLER.address,
            contact_person: CONTACT_PERSON
          }
        })
        .then(data => {
          this.showAlert("success", "Cargo Handler successfully saved!", true);

          this.toggleAddCargoHandler();
          this.loadData();
        });
    } else {
      let { ERR } = this.state;
      ERR.name = true;
      this.setState({ ERR });
    }
  };

  handleDeleteCargoHandler = (i, name) => {
    Swal.fire({
      icon: "question",
      title: "Delete " + name + "?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Deleted " + name + "!",
          showConfirmButton: false,
          timer: 1500
        });

        api
          .update(this, {
            endpoint: "cargo_handler/" + i,
            _data: {
              deleted_at: new Date()
            }
          })
          .then(data => {
            this.loadData();
          });
      }
    });
  };

  handleCargoHandlerData = i => {
    api
      .get(this, {
        endpoint: "cargo_handler/" + i,
        state_key: "CARGO_HANDLER"
      })
      .then(data => {
        this.setState({
          isEdit: true,
          CONTACT_PERSON: data.contact_person
        });

        this.toggleAddCargoHandler();
      });
  };

  handleUpdateBerth = () => {
    let { CARGO_HANDLER, CONTACT_PERSON } = this.state;

    if (CARGO_HANDLER.name) {
      Swal.fire({
        icon: "question",
        title: "Save changes for " + CARGO_HANDLER.name + "?",
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true
      }).then(result => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Updated " + CARGO_HANDLER.name + "!",
            showConfirmButton: false,
            timer: 1500
          });

          api
            .update(this, {
              endpoint: "cargo_handler/" + CARGO_HANDLER._id,
              _data: {
                name: CARGO_HANDLER.name,
                address: CARGO_HANDLER.address,
                contact_person: CONTACT_PERSON
              }
            })
            .then(data => {
              this.setState({
                openModal: !this.state.openModal,
                isEdit: false
              });

              this.loadData();
            });
        }
      });
    } else {
      let { ERR } = this.state;
      ERR.name = true;
      this.setState({ ERR });
    }
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool
    });

    // console.log("ALERT", bool);
  };

  render() {
    return (
      <div>
        {this.state.tglAlert ? (
          <AlertModal
            sendTrigger={this.state.isAlert}
            sendMsg={this.state.alertMsg}
          />
        ) : (
          ""
        )}
        <div className="flex-column">
          <Navbar />

          <div className="col-md-12 p-20">
            <Col
              md={{ size: 12 }}
              className="form-container-box d-flex flex-column bt-green"
            >
              <div className="col-md-12 d-flex flex-column p-0">
                <div className="col-md-12 fcb-title">
                  Maintenance > Cargo Handlers
                </div>
                <div className="col-md-12 d-flex justify-content-end p-0 mt-3">
                  <Button
                    className="btn-sm"
                    color="primary"
                    onClick={() => this.newCargoHandler()}
                  >
                    NEW
                  </Button>
                </div>
                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "10px" }}
                >
                  <thead>
                    <tr>
                      <th rowSpan="2">
                        <div>Name</div>
                      </th>
                      <th rowSpan="2">Address</th>
                      <th colSpan="3" className="tac">
                        Contact Person
                      </th>
                      <th rowSpan="2" width="5%"></th>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <th>Contact Number</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.CARGO_HANDLERS.map((ch, $i) => {
                      return (
                        <tr key={$i}>
                          <td>{ch.name}</td>
                          <td>{ch.address}</td>
                          <td>{ch.contact_person.name}</td>
                          <td>{ch.contact_person.contact_number}</td>
                          <td>{ch.contact_person.email}</td>
                          <td>
                            <div className="d-flex flex-row tblActions w100per">
                              <Icon
                                name="pencil"
                                style={{
                                  backgroundColor: "#4CAF50",
                                  marginRight: "0.5em"
                                }}
                                onClick={() =>
                                  this.handleCargoHandlerData(ch._id)
                                }
                              />
                              <Icon
                                onClick={() =>
                                  this.handleDeleteCargoHandler(ch._id, ch.name)
                                }
                                name="trash"
                                style={{ backgroundColor: "#f44336" }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </div>

          <Modal
            style={{ width: "8em" }}
            isOpen={this.state.openModal}
            toggle={this.toggleAddCargoHandler}
          >
            <ModalHeader toggle={this.toggleAddCargoHandler}>
              {this.state.isEdit
                ? "Edit " + this.state.CARGO_HANDLER.name
                : "New Cargo Handler"}
            </ModalHeader>
            <ModalBody>
              <Form className="d-flex flex-column">
                <FormGroup
                  className={
                    this.state.ERR.name
                      ? "p-0 mb-5 w100per required-field"
                      : "p-0 mb-5 w100per"
                  }
                >
                  <Label for="">Cargo Handler Name</Label>
                  <Input
                    className="form-control-sm"
                    type="text"
                    name="mmsi_no"
                    value={this.state.CARGO_HANDLER.name}
                    onChange={e => {
                      let { CARGO_HANDLER, ERR } = this.state;
                      CARGO_HANDLER.name = e.target.value;
                      ERR.name = CARGO_HANDLER.name ? false : true;

                      this.setState({
                        CARGO_HANDLER
                      });
                    }}
                  />
                  {this.state.ERR.name ? (
                    <span className="error-msg">This is required.</span>
                  ) : (
                    ""
                  )}
                </FormGroup>

                <FormGroup className="p-0 mb-5 w100per">
                  <Label for="">Address</Label>
                  <Input
                    className="form-control-sm"
                    type="text"
                    name="mmsi_no"
                    value={this.state.CARGO_HANDLER.address}
                    onChange={e => {
                      let { CARGO_HANDLER } = this.state;
                      CARGO_HANDLER.address = e.target.value;
                      this.setState({
                        CARGO_HANDLER
                      });
                    }}
                  />
                </FormGroup>

                <p className="w100per contact-person">CONTACT PERSON</p>
                <FormGroup className="p-0 mb-5 w100per">
                  <Label for="">Name</Label>
                  <Input
                    className="form-control-sm"
                    type="text"
                    name="mmsi_no"
                    value={this.state.CONTACT_PERSON.name}
                    onChange={e => {
                      let { CONTACT_PERSON } = this.state;
                      CONTACT_PERSON.name = e.target.value;
                      this.setState({
                        CONTACT_PERSON
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup className="p-0 mb-5 w100per">
                  <Label for="">Contact Number</Label>
                  <Input
                    className="form-control-sm"
                    type="number"
                    name="mmsi_no"
                    value={this.state.CONTACT_PERSON.contact_number}
                    onChange={e => {
                      let { CONTACT_PERSON } = this.state;
                      CONTACT_PERSON.contact_number = e.target.value;
                      this.setState({
                        CONTACT_PERSON
                      });
                    }}
                  />
                </FormGroup>
                <FormGroup className="p-0 mb-5 w100per">
                  <Label for="">Email</Label>
                  <Input
                    className="form-control-sm"
                    type="email"
                    name="mmsi_no"
                    value={this.state.CONTACT_PERSON.email}
                    onChange={e => {
                      let { CONTACT_PERSON } = this.state;
                      CONTACT_PERSON.email = e.target.value;
                      this.setState({
                        CONTACT_PERSON
                      });
                    }}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                className="fs13"
                color="danger"
                onClick={this.toggleAddCargoHandler}
              >
                CANCEL
              </Button>
              <Button
                className="fs13"
                color="success"
                onClick={() =>
                  this.state.isEdit
                    ? this.handleUpdateBerth()
                    : this.handleSave()
                }
              >
                SAVE
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(CargoHandlerMaintenance);
