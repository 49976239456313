import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import "./CrewListViewPDF.scss";
import sbma_logo from "../../../img/Sbma_logo.png";
import constants from "../../../constants";
import Moment from "react-moment";
import { Button } from "reactstrap";
import { Icon } from "react-fa";

import api from "../../../helpers/api.helper";

export default class CrewListViewPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      portcall_id: this.props.portcall_id,
      crew_list: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "crew_list/" + this.state.portcall_id,
        state_key: "crew_list",
        request_params: {
          search: { type: this.props.search_type },
          sort: { last_name: 1 },
        },
        append_data: true,
      })
      .then((data) => {
        console.log("DATA>>>", data);
      });
  };

  render() {
    return (
      <div>
        <div className="col-md-12 p0 d-flex justify-content-end">
          <ReactToPrint
            trigger={() => (
              <Button
                className="btn-sm"
                color="primary"
                style={{ margin: "0.5em 0" }}
              >
                PRINT <Icon name="print" />
              </Button>
            )}
            content={() => this.componentRef}
          />
        </div>

        <div
          className="VEC-container crew-list-print"
          ref={(el) => (this.componentRef = el)}
        >
          <img className="sbma-logo" src={sbma_logo} alt={sbma_logo} />

          <div className="subic-name">
            <h4>SUBIC BAY METROPOLITAN AUTHORITY</h4>
            <h5>Subic Bay Freeport Zone, Philippines</h5>
          </div>

          <div className="dept-header"></div>

          <div className="control-no">
            <p style={{ fontWeight: "bold", fontSize: "8px" }}>CONTROL NO.</p>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>-</p>
          </div>

          <div className="col-md-12 d-flex flex-row justify-content-center">
            <div className="vec-title">
              <h5>{this.props.type} Crew List</h5>
            </div>
          </div>

          <table className="table-pdf mt-4">
            <thead>
              <tr>
                <th>No</th>
                <th>Last Name</th>
                <th>First Name</th>
                <th>Rank / Rating</th>
                <th>Nationality</th>
                <th>Date of Birth</th>
                <th>Place of Birth</th>
                <th>Age</th>
                <th>Sex</th>
                <th>Date of Embark</th>
                <th>Place of Embark</th>
                <th>Passport No.</th>
                <th>Expiry Date</th>
                <th>Seaman's Book</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {this.state.crew_list.map((crew, $i) => {
                return (
                  <tr index={$i}>
                    <td>{$i + 1}</td>
                    <td>{crew.last_name}</td>
                    <td>{crew.first_name}</td>
                    <td>{crew.rank}</td>
                    <td>{crew.nationality}</td>
                    <td>
                      <Moment format={constants.__dateFormat}>
                        {crew.date_of_birth || "-"}
                      </Moment>
                    </td>
                    <td>{crew.place_of_birth}</td>
                    <td>{crew.age}</td>
                    <td>{crew.sex}</td>
                    <td>
                      <Moment format={constants.__dateFormat}>
                        {crew.date_of_embark || "-"}
                      </Moment>
                    </td>
                    <td>{crew.place_of_embark}</td>
                    <td>{crew.passport_no}</td>
                    <td>
                      <Moment format={constants.__dateFormat}>
                        {crew.passport_expiry_date || "-"}
                      </Moment>
                    </td>
                    <td>{crew.sbn}</td>
                    <td>
                      <Moment format={constants.__dateFormat}>
                        {crew.sbn_expiry_date || "-"}
                      </Moment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
