import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./DocView.scss";
import vessel_entry from "../../img/vessel_entry.png";
import ships_particular from "../../img/ships_particular.png";
import crew_list from "../../img/crew_list.png";
import spam from "../../img/spam.png";
import vessel_exit from "../../img/vessel_exit.png";

export default class DocView extends Component {
  constructor(props) {
    super(props);

    console.log(this.props);
  }

  handleDownloadPdf = () => {
    window.open(window.location.href + "?pdf=vessel_entry", "_blank");

    //ReactDOM.render(<VesselEntryClearanceView visit={ this.props.visit }/>, document.getElementById('root'));
  };

  render() {
    return (
      <Row xs="1" md="2" className="docList">
        <Col
          className="per-doc d-flex flex-column"
          onClick={this.handleDownloadPdf}
        >
          <div>
            <img src={vessel_entry} alt="vessel_entry" />
            <p>Vessel Entry Clearance</p>
          </div>
        </Col>
        <Col className="per-doc d-flex flex-column">
          <div>
            <img src={ships_particular} alt="ships_particular" />
            <p>Ship's Particular</p>
          </div>
        </Col>
        <Col className="per-doc d-flex flex-column">
          <div>
            <img src={crew_list} alt="crew_list" />
            <p>Crew List</p>
          </div>
        </Col>
        <Col className="per-doc d-flex flex-column">
          <div>
            <img src={spam} alt="spam" />
            <p>SPAM</p>
          </div>
        </Col>
        <Col className="per-doc d-flex flex-column">
          <div>
            <img src={vessel_exit} alt="vessel_exit" />
            <p>Vessel Exit</p>
          </div>
        </Col>
      </Row>
    );
  }
}
