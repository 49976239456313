import React, { Component } from "react";
import "./BackToTop.scss";
import { Icon } from "react-fa";
import { Button } from "reactstrap";

export default class BackToTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToTop: false,
      showTooltip: false
    };
  }

  componentDidMount = () => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 350) {
        this.setState({
          showToTop: true
        });
      } else {
        this.setState({
          showToTop: false
        });
      }
    });
  };

  backToTop = () => {
    window.scrollTo(0, 0);
  };

  toggleToolTip = () => {
    this.setState(prevState => ({
      showToTop: !prevState.showToTop
    }));
  };

  render() {
    const { showToTop } = this.state;

    return (
      <div className="toTopCon">
        {showToTop ? (
          <div>
            <Button
              id="tooltipToTop"
              className="btt-con"
              onClick={() => this.backToTop()}
            >
              <Icon className="backToTop" name="angle-double-up" />
            </Button>
            {/* <Tooltip
              placement="left"
              isOpen={showToTop}
              target="tooltipToTop"
              toggle={this.toggleToolTip}
            >
              Back To Top
            </Tooltip> */}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
