import React, { Component } from "react";
import { Button, FormGroup, Input } from "reactstrap";
import "./Login.scss";
import sbma_logo from "../../img/sbma-logo.png";
import apollo_logo from "../../img/apollo-logo.png";
import ekonek_logo from "../../img/ekonek-logo.jpg";
import api from "../../helpers/api.helper";
import Cookies from "js-cookie";
import session from "../../session";
import { ToastHeader, ToastBody } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import portWebsocket from "../../portWebsocket";
import constants from "../../constants";
import Media from "react-media";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __login: {},
      __opened: false,
      random: 1,
    };

    if (Cookies.get("port_token") && session.get() !== null) {
      window.location.replace("/dashboard");
    }

    toast.configure({
      autoClose: 2500,
      draggable: true,
      draggablePercent: 60,
      hideProgressBar: true,
      //etc you get the idea
    });
  }

  componentDidMount = () => {
    const min = 1;
    const max = 2;
    const rand = Math.floor(Math.random() * (max - min + 1)) + min;
    this.setState({ random: rand });
  };

  handleLogin = () => {
    let component = this;

    api
      .post(component, {
        endpoint: "user/login",
        _data: component.state.__login,
      })
      .then((res) => {
        if (res.data.token) {
          this.handleSetSession(res.data).then((user) => {
            if (user) {
              toast.success(
                <>
                  <ToastHeader>Login Success</ToastHeader>
                  <ToastBody>
                    Hello {user.first_name} {user.last_name}!
                  </ToastBody>
                </>
              );
              component.props.history.push("dashboard");
            }
          });
        }
      })
      .catch((e, f) => {
        if (e.response && e.response.data && e.response.data.error) {
          toast.error(
            <>
              <ToastHeader>Login Failed</ToastHeader>
              <ToastBody>Invalid Username / Password.</ToastBody>
            </>
          );
          //toast.error(e.response.data.error)
        }
      });
  };

  handleSetSession = (data) => {
    return new Promise((resolve, reject) => {
      let d = new Date(),
        oJwt = this.parseJwt(data.token);

      Cookies.set("port_token", data.token, {
        path: "/",
        expires: new Date(d.setTime(d.getTime() + 480 * 60 * 1000)),
      });

      Cookies.set("uid", oJwt._id, {
        path: "/",
        expires: new Date(d.setTime(d.getTime() + 480 * 60 * 1000)),
      });

      Cookies.set("role", data.user.role, {
        path: "/",
        expires: new Date(d.setTime(d.getTime() + 480 * 60 * 1000)),
      });

      portWebsocket.initialize();

      session.set(data.user);

      //localStorage.setItem("port_session", JSON.stringify(data.user));

      resolve(data.user);
    }).catch((e) => {
      console.log(e);
    });
  };

  parseJwt = (token) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  handleRegister = () => {
    //let component = this;
    // api.post(component, {
    //   endpoint: "user",
    //   _data: {
    //     username: 'admin',
    //     password: 'Demo1234',
    //     email: 'email@aesiph.io',
    //     first_name: 'Super',
    //     last_name: 'Admin',
    //     middle_name: '',
    //     role: 'admin'
    //
    //   }
    // }).then(res => {
    //
    //
    // }).catch((e, f) => {
    //   alert(e)
    // });
    //
    // api.post(component, {
    //   endpoint: "user",
    //   _data: {
    //     username: 'port_admin',
    //     password: 'Demo1234',
    //     email: 'email@aesiph.io',
    //     first_name: 'Port',
    //     last_name: 'Manager',
    //     middle_name: '',
    //     role: 'port_admin'
    //
    //   }
    // }).then(res => {
    //
    //
    // }).catch((e, f) => {
    //   alert(e)
    // });
    //
    // api.post(component, {
    //   endpoint: "user",
    //   _data: {
    //     username: 'shipping_agent_1',
    //     password: 'Demo1234',
    //     email: 'email@aesiph.io',
    //     first_name: 'Agent',
    //     last_name: 'Orange',
    //     middle_name: '',
    //     role: 'shipping_agent'
    //
    //   }
    // }).then(res => {
    //
    //
    // }).catch((e, f) => {
    //   alert(e)
    // });
    //
    // api.post(component, {
    //   endpoint: "user",
    //   _data: {
    //     username: 'shipping_agent_2',
    //     password: 'Demo1234',
    //     email: 'email@aesiph.io',
    //     first_name: 'Agent',
    //     last_name: 'Blue',
    //     middle_name: '',
    //     role: 'shipping_agent'
    //
    //   }
    // }).then(res => {
    //
    //
    // }).catch((e, f) => {
    //   alert(e)
    // });
  };

  handleOpen = () => {
    this.setState((prevState) => ({
      __opened: !prevState.__opened,
    }));
  };

  render() {
    const { __opened } = this.state;
    return (
      <div className="login-page">
        <div className={__opened ? "login-cont opened" : "login-cont"}>
          <div className={"bg-img bg-" + this.state.random}></div>
          <div className={"logo-title bg-" + this.state.random}>
            <img src={apollo_logo} alt="APOLLO Port Management"></img>
            {/* <p>Visit us at aesiph.com!</p> */}
            <Media queries={constants.media_queries}>
              {(matches) => (matches.xs ? <h1>PORT MANAGEMENT</h1> : "")}
            </Media>
          </div>
          <div className="login-input">
            <img
              src={ekonek_logo}
              alt="ekonek-logo"
              className="ekonek-logo"
            ></img>
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs && !matches.sm ? (
                  <>
                    <img
                      src={sbma_logo}
                      alt="sbma-logo"
                      className="sbma-logo"
                    ></img>
                    <h1>PORT MANAGEMENT</h1>
                  </>
                ) : (
                  ""
                )
              }
            </Media>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleLogin();
              }}
            >
              <FormGroup>
                <Input
                  className="unameField fieldImage"
                  autoComplete="off"
                  type="text"
                  name="email"
                  id="exampleEmail"
                  placeholder="Username"
                  onChange={(e) => {
                    let __login = this.state.__login;

                    __login.username = e.target.value;
                  }}
                />
                <Input
                  className="passField fieldImage"
                  autoComplete="off"
                  type="password"
                  name="password"
                  id="examplePassword"
                  placeholder="Password"
                  onChange={(e) => {
                    let __login = this.state.__login;

                    __login.password = e.target.value;
                  }}
                />
              </FormGroup>

              <Button type={"submit"} color="" className="loginBtn">
                LOG IN
              </Button>
              <div onClick={this.handleOpen} className="loginBtn">
                LOG IN
              </div>

              <div className="forgotPass d-none">
                <p>Forgot Password?</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
