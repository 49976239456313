import moment from "moment";
import constants from "../constants";
const toTimeline = statusChangeLogs => {
  let formatted_status_change_logs = {};
  for (let x in statusChangeLogs) {
    let d = moment(statusChangeLogs[x].date).format(constants.__dateFormat);

    if (!formatted_status_change_logs[d]) {
      formatted_status_change_logs[d] = [];
    }

    formatted_status_change_logs[d] = [
      statusChangeLogs[x],
      ...formatted_status_change_logs[d]
    ];
  }

  let arr_formatted = [];
  for (let y in formatted_status_change_logs) {
    arr_formatted = [
      { dt: y, timeline: formatted_status_change_logs[y] },
      ...arr_formatted
    ];
  }

  return arr_formatted;
};

export default toTimeline;
