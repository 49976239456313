import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../../../helpers/api.helper";
import constants from "../../../constants";
import moment from "moment";
import "./ArrivalTicker.scss";
import { ReactComponent as Arrival } from "../svg/Arrival.svg";

class ArrivalTicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DASHBOARD_TICKER: [],
      ARRIVAL_TICKER: [],
    };
  }

  componentDidMount = () => {
    this.loadTicker();
  };

  loadTicker = () => {
    api
      .get(this, {
        endpoint: "dashboard/ticker",
        state_key: "dashboardTicker",
      })
      .then((data) => {
        // console.log("TICKER", data);
        this.setState({
          ARRIVAL_TICKER: data.arrival,
        });
      });
  };

  render() {
    return (
      <>
        <div className="col-md-12 p0 tickerCon">
          <div class="col-md-12 d-flex flex-row align-items-center">
            <div class="col-md-6 p0 d-flex flex-row">
              <Arrival />{" "}
              <p className="table-title d-flex align-items-center col-md-10 p0">
                ARRIVAL
              </p>
            </div>
            <div class="col-md-6 p0 d-flex flex-row justify-content-end">
              <div className="d-flex flex-column align-items-end date-con">
                <span className="date-no">
                  {moment(new Date()).format(constants.__timeFormat)}
                </span>
                <span className="date-label">
                  {moment(new Date()).format(constants.__dateFormat) +
                    " " +
                    moment(new Date()).format(constants.__dayFormat)}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center page-con">
                <span className="page-no">1</span>
                <span className="page-label">PAGE</span>
              </div>
            </div>
          </div>
          <table className="arrivalTable ticker-table">
            <thead>
              <tr>
                <th style={{ width: "18%" }}>Name of Ship</th>
                <th style={{ width: "12%" }} className="tar">
                  Voyage No.
                </th>
                <th style={{ width: "14%" }}>Ship Agent</th>
                <th style={{ width: "10%" }}>Port of Origin</th>
                <th style={{ width: "15%" }}>
                  Estimated Date & Time of Arrival
                </th>
                <th style={{ width: "17%" }}>Cargo Handlers</th>
                <th style={{ width: "12%" }}>Cargo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.ARRIVAL_TICKER.map((dt, i) => (
                <tr key={i}>
                  <td>{dt.vessel_name}</td>
                  <td className="tar yellowTD">{dt.voyage_no}</td>
                  <td>
                    {dt.shipping_agency && dt.shipping_agency.name
                      ? dt.shipping_agency.name
                      : ""}
                  </td>
                  <td>{dt.port_of_origin}</td>
                  <td>
                    {dt.eta ? (
                      <p className="m0">
                        <span className="fw600">ETA: </span>
                        {moment(dt.eta).format(constants.__dateTimeFormat)}
                      </p>
                    ) : (
                      ""
                    )}
                    {dt.ata ? (
                      <p className="m0" style={{ paddingTop: "0.5em" }}>
                        <span className="fw600">ATA: </span>
                        {moment(dt.ata).format(constants.__dateTimeFormat)}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {dt.selected_port_call_purposes.map((sp, i) => (
                      <div key={i}>
                        {sp.arrastre ? sp.arrastre : ""}
                        {sp.arrastre && sp.stevedoring ? " / " : ""}
                        {sp.stevedoring ? sp.stevedoring : ""}
                      </div>
                    ))}
                  </td>
                  <td>
                    {dt.cargo_details && dt.cargo_details.length > 0
                      ? dt.cargo_details[0].item
                      : "-"}
                  </td>
                  <td>{dt.is_arrived ? "ARRIVED" : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default withRouter(ArrivalTicker);
