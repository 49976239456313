import Cookies from "js-cookie";

const portWebsocket = {
    initialize: () => {

        if (Cookies.get("port_token") && Cookies.get("uid")) {
            portWebsocket.connection = new WebSocket('wss://7hnk84xvpi.execute-api.ap-northeast-1.amazonaws.com/Stage-1?port_token=' + Cookies.get("port_token") + '' +
                '&domain=' + window.location.hostname + '' +
                '&uid=' + Cookies.get("uid"));

            portWebsocket.connection.onopen = () => {
                portWebsocket.connection.send(JSON.stringify({
                    "action": "OnMessage", "message": JSON.stringify({
                        'event': 'connected',
                        'message': {
                            'port_token': Cookies.get("port_token"),
                            'domain': window.location.hostname,
                            'uid': Cookies.get("uid")
                        },
                        data: ''
                    })
                }));
            };

            portWebsocket.connection.onmessage = (msg) => {

                //console.log(msg);

                if (msg.data && msg.data) {

                    try {
                        let oParsedWebsocketMessage = JSON.parse(msg.data);
                        console.log(oParsedWebsocketMessage);
                        if (portWebsocket.events[oParsedWebsocketMessage.event] && typeof (portWebsocket.events[oParsedWebsocketMessage.event]) === 'function') {
                            portWebsocket.events[oParsedWebsocketMessage.event](oParsedWebsocketMessage.message);
                        } else {
                            console.log('websocket received event: ' + oParsedWebsocketMessage.event + ' , not found in events handler')
                        }
                    } catch (e) {
                        console.log(e)
                    }


                } else {
                    console.log('websocket message incomplete')
                }

            };
        }

    },
    events: {},
};

portWebsocket.initialize();

export default portWebsocket;
