import React, { Component, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Popover,
  PopoverHeader,
  PopoverBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Icon } from "react-fa";
import Datetime from "react-datetime";
import moment from "moment";
import { withRouter } from "react-router-dom";
import "./BerthPlanForm.scss";
import "../../../css/datetime.scss";
import constants from "../../../constants";
import Navbar from "../../Navbar/Navbar";
import Sidenav from "../../Sidenav/Sidenav";
import session from "../../../session";
import back_icon from "../../../img/svg/BackIcon.svg";
import Moment from "react-moment";
import api from "../../../helpers/api.helper";
import constant from "../../../constants";
import AlertModal from "../../Tools/AlertModal/AlertModal";
import Toggle from "react-toggle";

class BerthPlanForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      selected_status: "",
      status_change: {},
      berth_plan_logs: [],
      __berth_plan: {},
      __port_call: {
        selected_port_call_purposes: [],
      },
      __data: {},
      bars: [],
      dates: [],
      __berth: [],
      openModal: false,
      __dateF: constant.__dateFormat,
      __timeF_ss: constant.__timeFormat_ss,
      viewSched: false,
      schedToggle: "visit",
      calendar_header_display: "",
      dayIndex: 0,
      tglAlerrt: false,
      isAlert: "",
      alertMsg: "",
      berthAssigned: false,
    };
  }

  handleSaveNotification = () => {
    //submit entry
    let voyageNumber = this.state.__port_call.voyage_number
      ? "(" + this.state.__port_call.voyage_number + ")"
      : "";
    api
      .save(this, {
        endpoint: "notification/port_admin/" + this.state.__port_call._id,
        preserve_state: true,
        _data: {
          filler: true,
          vessel_name: this.state.__port_call.vessel_name,
          voyage_number: this.state.__port_call.voyage_number,
          first_name: session.get().first_name,
          last_name: session.get().last_name,
          subject:
            this.state.__port_call.vessel_name +
            voyageNumber +
            " has been enqueued to your pier. ",
          action: 0,
          description:
            " has enqueued a vessel to your pier and requires your attention.",
          message:
            session.get().first_name +
            " " +
            session.get().last_name +
            " has enqueued a vessel to your pier. Kindly fill out the resource allocation plan and submit to engineering.",
          role: "cargo_handler",
          portcall_id: this.state.__ID,
        },
      })
      .then(() => {});
  };

  handleStatusChange = () => {
    let { status_change } = this.state,
      portcall_id = this.props.match.params.id;
    if (this.props.match.params.berth_plan_id) {
      status_change.berth_plan_id = this.props.match.params.berth_plan_id;
    }

    api
      .save(this, {
        endpoint: "berth_plan_log/" + portcall_id,
        _data: status_change,
        state_key: "berth_plan_logs",
        append: true,
      })
      .then(() => {
        this.handleSave();
        /**
         * Check if the status is changed to berth complete. we have to change the status of the portcall.
         *
         */

        this.closeModal();
      });

    if (
      parseInt(this.state.__data.status_id) ===
      parseInt(constants.COMPLETE_BERTHPLAN_STATUS)
    ) {
      // update portcall status change
      api.update(this, {
        endpoint: "portcall/update_status_text/" + this.props.match.params.id,
        _data: { status: "Berth Complete" },
      });
    }
  };

  componentDidMount = () => {
    this.loadData();
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  toggleModal = (stat) => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
      pc_status: stat,
    }));
  };

  closeModal = () => {
    this.setState({ status_change: {} });
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));

    this.revertStatus();
  };

  revertStatus = () => {
    let { __data } = this.state;

    if (__data.orig_status_id) {
      __data.status_id = __data.orig_status_id;
      __data.status = __data.orig_status;
      this.setState({ __data });
    }
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "portcall/" + this.state.__ID,
        state_key: "__port_call",
      })
      .then((data) => {});

    api
      .get(this, {
        endpoint: "berth",
        state_key: "__berth",
      })
      .then((data) => {});
    if (this.props.match.params.berth_plan_id) {
      api
        .get(this, {
          endpoint: "berth_plan/" + this.props.match.params.berth_plan_id,
        })
        .then((data) => {});

      api.get(this, {
        endpoint: "berth_plan_log/" + this.props.match.params.berth_plan_id,
        state_key: "berth_plan_logs",
      });
    }
  };

  handleSave = () => {
    this.showAlert("loading", "", true);
    let data = this.state.__data;
    data.port_call_id = this.state.__port_call._id;
    data.vessel_name = this.state.__port_call.vessel_name;
    data.vessel_image = this.state.__port_call.vessel_image;
    data.voyage_number = this.state.__port_call.voyage_number;

    if (this.props.match.params.berth_plan_id) {
      // perform updte
      api
        .update(this, {
          _data: data,
          endpoint: "berth_plan/" + this.props.match.params.berth_plan_id,
        })
        .then((response) => {
          this.handleSaveNotification();
          this.setState(
            {
              tglAlert: false,
            },
            () => this.showAlert("success", "Berth Plan Updated!", true)
          );
        });
    } else {
      let { __berth } = this.state;
      let cargoHandlerIDs = "";

      for (let x in __berth) {
        if (__berth[x]._id === data.berth_id) {
          cargoHandlerIDs = __berth[x].cargo_handler_id;
        }
      }

      data.status = "Pending";
      data.status_id = constants.PENDING_BERTHPLAN_STATUS;
      api
        .save(this, {
          _data: {
            ...data,
            cargo_handler_id: cargoHandlerIDs,
          },
          endpoint: "berth_plan",
        })
        .then((response) => {
          this.handleSaveNotification();
          this.setState(
            {
              tglAlert: false,
              berthAssigned: true,
            },
            () =>
              this.showAlert("success", "Berth Plan successfully saved!", true)
          );
        });
    }

    data.orig_status = null;
    data.orig_status_id = null;
    this.setState({ __data: data });
  };

  pad = (num, size) => {
    size = 2;
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  /**
   *  THE METHOD THAT WILL PLOT THE GANTT CHART
   *
   * This method will connect to an API end point that will return the berth plans that is inside the date range
   */
  handleAssembleCalendar = () => {
    let { berth_id } = this.state.__data,
      { eta, etd } = this.state.__port_call;
    if (berth_id && eta && etd) {
      /**
       * send api request to get all berth plan for the selected berth between the given dates.
       */
      api
        .get(this, {
          endpoint:
            "berth_plan/schedule/" +
            berth_id +
            "/" +
            moment(eta).format("YYYY-MM-DD") +
            "/" +
            moment(etd).format("YYYY-MM-DD"),
          state_key: "berth_schedule",
          preserve_state: true, // NOTE, WE SHOULD PRESERVE THE STATE BECAUSE WE WILL BE DOING SOME PROCESSING IN THE DATA.
        })
        .then((berth_plan_schedule) => {
          if (berth_plan_schedule && berth_plan_schedule.length > 0) {
            /**
             *  dt_checker - will contain all the dates and the time of "TAKEN" slots
             *
             * @datastructure: {
             *                      <date> : ["HH", "HH"....]
             *                  }
             *      {
             *        "2019-11-04" : ["19", 20, 21, 22, 23], ------>>>>>>>>> This means on November 4, starting 19:00, the berth is already occupied.
             *        "2019-11-05" : [00, 01,02,03,04,05,06,07,08,09......],
             *        ....
             *      }
             */
            let dt_checker = {};
            let dates = []; // This will contain the flat array of the dates between the ETA and ETD. ['2019-11-04', '2019-11-05', '2019-11-06', ....]

            /**
             * We will populate the variable "dates" here, we will start from ETA to ETD.
             */
            let range_dt_start = moment(eta);
            let range_dt_end = moment(etd);

            while (range_dt_start <= range_dt_end) {
              dates.push(moment(range_dt_start).format("YYYY-MM-DD"));
              range_dt_start.add(1, "days");
            }
            /** ----------- END OF dates VARIABLE ASSEMBLY  */

            for (let x in berth_plan_schedule) {
              /**
               *    IN THIS LOOP, WE ARE GOING TO ASSEMBLE dt_cheker VARIABLE, AGAIN, WHICH WILL HOLD THE DATES AND HOURS THAT ARE ALREADY TAKEN.
               */
              let running_hours = null;
              let sched = berth_plan_schedule[x];
              let end_time = moment(sched.etd).add(1, "hours");
              if (running_hours === null) {
                running_hours = moment(sched.eta);
              }

              while (running_hours <= end_time) {
                let start_hour = moment(running_hours).format("HH");

                if (
                  typeof dt_checker[
                    moment(running_hours).format("YYYY-MM-DD")
                  ] === "undefined"
                ) {
                  dt_checker[moment(running_hours).format("YYYY-MM-DD")] = [];
                }
                dt_checker[moment(running_hours).format("YYYY-MM-DD")].push(
                  start_hour
                );

                running_hours = moment(running_hours).add(1, "hours");
              }
            } /** --------------- END OF dt_checker VARIABLE */

            // loop thru stated date range
            /**
             *  THIS IS WHERE WE ARE GOING TO ASSEMBLE THE REQUIRED VARIABLE 'bars'. THIS WILL CREATE THE 'AVAILABLE' AND TAKEN BARS'
             *  In this section, we are going to loop through dates variable and dt_checker to get which times are available per date.
             *
             *  first, we will loop through dates, and check if there is an existing schedule for that date.
             *
             */
            let bars = [];
            let bar = {};
            let prev_status = null; // Previous status
            for (let x in dates) {
              // looping through dates
              /**
               *  Check if there's an existing date inside dt_checker variable, if there's no existing date, it means that the entire day is available,
               * therefore, we will either add 24 to the counter, or assign 24 to counter depending on the previous state.
               */
              if (typeof dt_checker[dates[x]] !== "undefined") {
                /**
                 *   IF THE DATE EXISTS INSIDE THE dt_checker, therefore some time or the entire day is already taken,
                 * to check we should loop through 0-23 (00 = 12 AM , 23 = 11 PM)
                 *
                 */
                for (let z = 0; z <= 23; z++) {
                  /**
                   * curr_status - will check if the HH (hour) exists inside the dt_checker. if it is existing, the time is already taken.
                   * if false, time is available.
                   */
                  let curr_status =
                    dt_checker[dates[x]].indexOf(this.pad(z)) > -1; // NOTE we are padding the z variable sothat 0 will become 00, 1 will become 01 and so on.

                  if (curr_status) {
                    /**
                     *  as stated, if curr_status = true, the time is alrady taken
                     */
                    if (!prev_status || prev_status === null) {
                      // prev_status is the checking in prev loop pass. check if prev_status is also null (meaning it's the first pass)
                      /** if prev_status and curr_status are not equal, therefore, we have to push the prev count and create new one. */

                      if (curr_status !== prev_status || prev_status === null) {
                        if (bar.class) {
                          /**
                           * in here we will assemble the to and from for available, for click function of available
                           *
                           */
                          bar.btn_props = {};
                          bar.btn_props.from = new Date(
                            bar.base_date + " " + bar.base_time + ":00"
                          );
                          bar.btn_props.to = new Date(
                            dates[x] + " " + this.pad(z - 1) + ":00"
                          );

                          bars.push(bar);
                        }

                        bar = {};
                        bar.class = "taken";
                        bar.count = 0;
                      }
                    }
                  } else {
                    // we are just doing the same thing here, but for available slot.
                    if (prev_status || prev_status === null) {
                      if (curr_status !== prev_status || prev_status === null) {
                        if (bar.class) {
                          bars.push(bar);
                        }
                        bar = {};
                        bar.base_date = dates[x];
                        bar.base_time = this.pad(z);
                        bar.class = "available";
                        bar.count = 0;
                      }
                    }
                  }
                  bar.count++;
                  prev_status = curr_status;
                }
              } else {
                /**
                 * as stated, the entire day is available.
                 * let's check prev_status, if prev_status === FALSE, therefore, the prev loop pass is an available time(Hours) therefore we should add 24.
                 *
                 * IF prev_status === TRUE the prev loop pass is a taken time (hours) therefore we should push the existing bar in our bar collection called bars and create new one for available.
                 */
                if (!prev_status && prev_status !== null) {
                  bar.count += 24;
                } else {
                  if (bar.class) {
                    bars.push(bar); // the prev bar is taken, therefore, we should stop counting. push the bar inside bars and create new one for available.
                  }
                  prev_status = false;
                  bar.base_date = dates[x];
                  bar.base_time = this.pad(0);
                  bar.class = "available";
                  bar.count = 24;
                }
              }
            }
            bars.push(bar);

            // assemble display for the calendar header ;
            let calendar_header_display = "";
            if (dates.length > 0) {
              let is_year_equal = true;
              calendar_header_display = moment(dates[0]).format("MMMM DD");

              if (
                moment(dates[0]).format("YYYY") !==
                moment(dates[dates.length - 1]).format("YYYY")
              ) {
                is_year_equal = false;
                calendar_header_display +=
                  ", " + moment(dates[0]).format("YYYY");
              }

              calendar_header_display += " - ";

              if (
                moment(dates[0]).format("MMMM") !==
                moment(dates[dates.length - 1]).format("MMMM")
              ) {
                calendar_header_display += moment(
                  dates[dates.length - 1]
                ).format("MMMM");
              }

              calendar_header_display += moment(dates[dates.length - 1]).format(
                "DD"
              );

              if (!is_year_equal) {
                calendar_header_display += ", ";
              }

              calendar_header_display +=
                " " + moment(dates[dates.length - 1]).format("YYYY");
            }

            this.setState({ dt_checker, dates, bars, calendar_header_display });
          }
        });
    }
  };

  handleAssembleCalendarByDay = (dayIndex) => {
    let { dates, dt_checker } = this.state;
    if (dates[dayIndex]) {
      let date = dates[dayIndex];
      let bar = {},
        bars = [],
        prev_status = null;

      if (dt_checker[date]) {
        for (let z = 0; z <= 23; z++) {
          let curr_status = dt_checker[date].indexOf(this.pad(z)) > -1;
          if (curr_status) {
            /**
             *  as stated, if curr_status = true, the time is alrady taken
             */
            if (!prev_status || prev_status === null) {
              // prev_status is the checking in prev loop pass. check if prev_status is also null (meaning it's the first pass)
              /** if prev_status and curr_status are not equal, therefore, we have to push the prev count and create new one. */

              if (curr_status !== prev_status || prev_status === null) {
                if (bar.class) {
                  /**
                   * in here we will assemble the to and from for available, for click function of available
                   *
                   */
                  bar.btn_props = {};
                  bar.btn_props.from = new Date(
                    bar.base_date + " " + bar.base_time + ":00"
                  );
                  bar.btn_props.to = new Date(
                    date + " " + this.pad(z - 1) + ":00"
                  );

                  bars.push(bar);
                }

                bar = {};
                bar.class = "taken";
                bar.count = 0;
              }
            }
          } else {
            // we are just doing the same thing here, but for available slot.
            if (prev_status || prev_status === null) {
              if (curr_status !== prev_status || prev_status === null) {
                if (bar.class) {
                  bars.push(bar);
                }
                bar = {};
                bar.base_date = date;
                bar.base_time = this.pad(z);
                bar.class = "available";
                bar.count = 0;
              }
            }
          }
          bar.count++;
          prev_status = curr_status;
        }
        bars.push(bar);
      } else {
        bar = {
          btn_props: {
            to: new Date(date + " 23:59"),
            from: new Date(date + " 00:00"),
          },
          class: "available",
          count: 24,
        };
      }

      if (bar.class === "available") {
        bar.btn_props = {
          from: new Date(date + " " + bar.base_time),
          to: new Date(date + " 23:59"),
        };
      }
      bars.push(bar);

      let calendar_header_display = moment(date).format("MMMM DD, YYYY");

      this.setState({
        bars,
        calendar_header_display,
        dayIndex,
      });
    }
  };
  render() {
    const {
      __berth,
      __dateF,
      __timeF_ss,
      schedToggle,
      viewSched,
      isAlert,
      tglAlert,
      alertMsg,
    } = this.state;
    const vd = this.state.__port_call;

    const Available = (props) => {
      const [popoverOpen, setPopoverOpen] = useState(false);

      const toggle = () => setPopoverOpen(!popoverOpen);

      return (
        <span>
          <Button
            size="sm"
            block
            color="aesi-link-white"
            id={"Popover-" + props.data.id}
            type="button"
          >
            Available
          </Button>
          <Popover
            className="berth-assignment"
            placement="right"
            isOpen={popoverOpen}
            target={"Popover-" + props.data.id}
            toggle={toggle}
            trigger="legacy"
          >
            <PopoverHeader>
              <Moment format="MMM DD, HH:mm">{props.data.from || "-"}</Moment>
              {" - "}
              <Moment format="MMM DD, HH:mm">{props.data.to || "-"}</Moment>
            </PopoverHeader>
            <PopoverBody>
              <div className="d-flex flex-row">
                <Label
                  for="exampleFile"
                  className="m-0"
                  style={{ width: "40px" }}
                >
                  From
                </Label>
                <Datetime dateFormat={__dateF} timeFormat={__timeF_ss} />
              </div>
              <div className="d-flex flex-row my-2">
                <Label
                  for="exampleFile"
                  className="m-0"
                  style={{ width: "40px" }}
                >
                  To
                </Label>
                <Datetime dateFormat={__dateF} timeFormat={__timeF_ss} />
              </div>
              <Button block size="sm" color="aesi-green">
                Assign
              </Button>
            </PopoverBody>
          </Popover>
        </span>
      );
    };

    const StatusWithAction = (props) => {
      const [dropdownOpen, setOpen] = useState(false);
      const toggle = () => setOpen(!dropdownOpen);
      const color =
        props.name === "Completed"
          ? "aesi-success"
          : props.name === "Active"
          ? "aesi-active"
          : "aesi-pending";

      return (
        <ButtonGroup
          className={
            props.className
              ? "aesi-status-pill " + props.className
              : "aesi-status-pill"
          }
        >
          <Button size="sm" color={color}>
            {props.name}
          </Button>
          {props.name !== "Completed" ? (
            <ButtonDropdown size="sm" isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color={color}>
                <Icon name="ellipsis-h" />
              </DropdownToggle>
              <DropdownMenu right>
                {/* <DropdownItem header>Header</DropdownItem> */}
                {props.name === "Active" ? (
                  <DropdownItem>Mark as Completed</DropdownItem>
                ) : (
                  <DropdownItem>Mark as Active</DropdownItem>
                )}
              </DropdownMenu>
            </ButtonDropdown>
          ) : (
            ""
          )}
        </ButtonGroup>
      );
    };

    return (
      <div className="BerthPlanForm d-flex flex-column">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}
        <Navbar />

        <div md={{ size: 12 }} className="d-flex flex-column page-header">
          <Col md={{ size: 12 }} className="d-flex flex-row">
            <Col className="d-flex flex-row col-md-6">
              <img src={back_icon} alt="back_icon" className="" width="25" />
              <p className="vessel-name aligner justify-content-start">
                {vd.vessel_name}, Voyage {vd.voyage_number}
              </p>
            </Col>
          </Col>
        </div>

        <Col md={{ size: 12 }} className="main-content d-flex flex-row mt-3">
          <Col md={{ size: 2 }}>
            <Sidenav
              dataFromParent={this.props.match.params.id}
              visit={vd}
              berthAssigned={this.state.berthAssigned}
            />
          </Col>

          <Col md={{ size: 10 }} className="mc-right d-flex flex-column">
            <Form
              className="form-container-box bt-green"
              style={{ margin: "0 5px", minHeight: "30em" }}
            >
              <div className="fcb-title d-flex flex-row col-md-12">
                <span
                  className="cur-p mr-1"
                  onClick={() =>
                    this.props.history.push(
                      "/port_call/berth_plans/" + this.state.__ID
                    )
                  }
                >
                  Berth Plan >{" "}
                </span>
                New Berth Plan
                <StatusWithAction
                  className="col-md-2 ml-auto p-0 py-1"
                  name="Pending"
                />
              </div>

              <div className="d-flex flex-row flex-wrap col-md-12 p-0 mt-2">
                <div className="col-md-6 d-flex flex-column">
                  <FormGroup className="mb-5">
                    <Label for="">PORT ETA</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="22"
                      disabled={true}
                      value={moment(this.state.__port_call.eta).format(
                        "DD-MMM-YYYY HH:mm"
                      )}
                      onChange={(e) => {
                        let __port_call = this.state.__port_call;

                        __port_call.eta = e.target.value;
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <FormGroup className="mb-5">
                    <Label for="">PORT ETD</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="22"
                      disabled={true}
                      value={moment(this.state.__port_call.etd).format(
                        "DD-MMM-YYYY HH:mm"
                      )}
                      onChange={(e) => {
                        let __port_call = this.state.__port_call;

                        __port_call.eta = e.target.value;
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-12 hr-div my-3"></div>
                <div className="col-md-6 d-flex flex-column">
                  <FormGroup className="mb-5">
                    <Label for="exampleSelect">
                      Berth<span className="reqAsterisk">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      className="form-control-sm"
                      tabIndex="3"
                      value={this.state.__data.berth}
                      onChange={(e) => {
                        let __data = this.state.__data;
                        __data.berth = e.target.value;
                        let berth_id = e.target.options[
                          e.target.selectedIndex
                        ].getAttribute("berth-id");

                        let cargo_handler_id = e.target.options[
                          e.target.selectedIndex
                        ].getAttribute("cargo-handler-id");

                        __data.berth_id = berth_id;
                        __data.cargo_handler_id = cargo_handler_id;
                        this.setState({ __data });

                        this.handleAssembleCalendar();
                      }}
                    >
                      <option>-- Please select berth --</option>
                      {__berth.map((b, i) => (
                        <option
                          key={i}
                          value={b.name}
                          berth-id={b._id}
                          cargo-handler-id={b.cargo_handler_id}
                        >
                          {b.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <FormGroup className="mb-5">
                    <Label for="exampleSelect">Service</Label>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      className="form-control-sm"
                      tabIndex="3"
                      value={this.state.__data.service}
                      onChange={(e) => {
                        let __data = this.state.__data;
                        __data.service = e.target.value;
                        this.setState({ __data });
                      }}
                    >
                      <option>-- Please select service --</option>
                      {this.state.__port_call.selected_port_call_purposes.map(
                        (b, i) => (
                          <option key={i} value={b.service} berth-id={b._id}>
                            {b.service}
                          </option>
                        )
                      )}
                    </Input>
                  </FormGroup>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <FormGroup className="mb-5">
                    <Label for="exampleFile">ETA</Label>
                    <Datetime
                      dateFormat={__dateF}
                      timeFormat={__timeF_ss}
                      value={
                        this.state.__data.eta
                          ? new Date(this.state.__data.eta)
                          : ""
                      }
                      onChange={(e) => {
                        let __data = this.state.__data;
                        __data.eta = e;

                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <FormGroup className="mb-5">
                    <Label for="exampleFile">ETD</Label>
                    <Datetime
                      dateFormat={__dateF}
                      timeFormat={__timeF_ss}
                      value={
                        this.state.__data.etd
                          ? new Date(this.state.__data.etd)
                          : ""
                      }
                      onChange={(e) => {
                        let __data = this.state.__data;
                        __data.etd = e;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="col-md-12 d-flex flex-row p-0">
                  <div className="col-md-6">
                    <div className="d-flex flex-row mt-2">
                      <Toggle
                        className="col-md-1 custom-toggle-color"
                        icons={false}
                        checked={this.state.__data.is_public}
                        onChange={(e) => {
                          let { __data } = this.state;
                          __data.is_public = e.target.checked;

                          this.setState({ __data });

                          console.log("DATA", __data);
                        }}
                      />
                      <span className="fs13" style={{ marginLeft: "-0.75em" }}>
                        Public
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 d-flex flex-row justify-content-end">
                    <Button
                      size="sm"
                      color="aesi-link-blue"
                      onClick={() => {
                        this.setState({
                          viewSched: !viewSched,
                        });
                      }}
                    >
                      <Icon name="calendar" />
                      {viewSched ? " View Schedule" : " Hide Schedule"}
                    </Button>
                  </div>
                </div>

                {viewSched ? (
                  <div className="col-md-12 d-flex flex-column">
                    <table className="berths-schedule">
                      <thead>
                        <tr className="toolbar">
                          <th>&nbsp;</th>
                          <td colspan={schedToggle === "day" ? "12" : "72"}>
                            <ButtonGroup className="mr-auto">
                              <Button
                                className="border-none"
                                color="secondary"
                                size="sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (schedToggle === "day") {
                                    let { dayIndex } = this.state;
                                    dayIndex--;
                                    this.handleAssembleCalendarByDay(dayIndex);
                                  }
                                }}
                                outline
                              >
                                <Icon name="chevron-left"></Icon>
                              </Button>
                              {/* <Button
                                className="px-3"
                                color="aesi-green"
                                size="sm"
                                // onClick={}
                                outline
                              >

                              </Button> */}
                              <span>{this.state.calendar_header_display}</span>
                              <Button
                                className="border-none"
                                color="secondary"
                                size="sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (schedToggle === "day") {
                                    let { dayIndex } = this.state;
                                    dayIndex++;
                                    this.handleAssembleCalendarByDay(dayIndex);
                                  }
                                }}
                                outline
                              >
                                <Icon name="chevron-right"></Icon>
                              </Button>
                            </ButtonGroup>
                          </td>
                          <td
                            colspan={schedToggle === "day" ? "12" : "96"}
                            className="text-right"
                          >
                            <ButtonGroup className="ml-auto">
                              <Button
                                className="px-3"
                                color={
                                  schedToggle === "visit"
                                    ? "aesi-active"
                                    : "secondary"
                                }
                                size="xs"
                                onClick={() => {
                                  this.setState({
                                    schedToggle: "visit",
                                  });
                                }}
                                active={schedToggle === "visit"}
                                outline={schedToggle !== "visit"}
                              >
                                Visit Duration
                              </Button>
                              <Button
                                className="px-3"
                                color={
                                  schedToggle === "day"
                                    ? "aesi-active"
                                    : "secondary"
                                }
                                size="xs"
                                onClick={() => {
                                  this.setState({
                                    schedToggle: "day",
                                  });
                                  this.handleAssembleCalendarByDay(0);
                                }}
                                active={schedToggle === "day"}
                                outline={schedToggle !== "day"}
                              >
                                Day
                              </Button>
                              <Button
                                className="px-3"
                                color={
                                  schedToggle === "week"
                                    ? "aesi-active"
                                    : "secondary"
                                }
                                size="xs"
                                onClick={() => {
                                  this.setState({
                                    schedToggle: "week",
                                  });
                                }}
                                active={schedToggle === "week"}
                                outline={schedToggle !== "week"}
                              >
                                Week
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                        <tr className="dates">
                          <th>&nbsp;</th>
                          {schedToggle === "day" ? (
                            <React.Fragment>
                              <td>00:00</td>
                              <td>01:00</td>
                              <td>02:00</td>
                              <td>03:00</td>
                              <td>04:00</td>
                              <td>05:00</td>
                              <td>06:00</td>
                              <td>07:00</td>
                              <td>08:00</td>
                              <td>09:00</td>
                              <td>10:00</td>
                              <td>11:00</td>
                              <td>12:00</td>
                              <td>13:00</td>
                              <td>14:00</td>
                              <td>15:00</td>
                              <td>16:00</td>
                              <td>17:00</td>
                              <td>18:00</td>
                              <td>19:00</td>
                              <td>20:00</td>
                              <td>21:00</td>
                              <td>22:00</td>
                              <td>23:00</td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {this.state.dates
                                ? this.state.dates.map((dt, i) => {
                                    return (
                                      <td colspan="24">
                                        {moment(dt).format("MMM DD")}
                                      </td>
                                    );
                                  })
                                : ""}
                            </React.Fragment>
                          )}
                        </tr>
                      </thead>
                      {schedToggle === "day" ? (
                        <tbody className="berth-day">
                          <tr className="dayguide">
                            <th>&nbsp;</th>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="am"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                            <td className="pm"></td>
                          </tr>
                          <tr className="berth-sched">
                            <th>
                              <div>Leyte Wharf</div>
                            </th>
                            {this.state.bars.map((b, i) => {
                              return (
                                <td colspan={b.count} className={b.class}>
                                  {b.class === "available" ? (
                                    <Available data={b.btn_props} />
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      ) : (
                        <tbody className="berth-week">
                          <tr className="dayguide">
                            <th>&nbsp;</th>
                            {this.state.dates
                              ? this.state.dates.map((dt, i) => {
                                  return (
                                    <>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="am"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                      <td className="pm"></td>
                                    </>
                                  );
                                })
                              : ""}
                          </tr>
                          <tr className="berth-sched">
                            <th>
                              <div>Leyte Wharf</div>
                            </th>
                            {this.state.bars.map((b, i) => {
                              return (
                                <td colspan={b.count} className={b.class}>
                                  {b.class === "available" ? (
                                    <Available data={b.btn_props} />
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-12 hr-div my-3"></div>
                <div className="col-md-6 d-flex flex-column">
                  <FormGroup className="mb-5">
                    <Label for="exampleFile">ATA</Label>
                    {/* {this.state.__data.status_id !==
                    constants.PENDING_BERTHPLAN_STATUS ? ( */}
                    {false ? (
                      <Datetime
                        dateFormat={__dateF}
                        timeFormat={__timeF_ss}
                        value={
                          this.state.__data.ata
                            ? new Date(this.state.__data.ata)
                            : ""
                        }
                        disabled={true}
                        onChange={(e) => {
                          let __data = this.state.__data;
                          __data.ata = e;
                          this.setState({ __data });
                        }}
                      />
                    ) : (
                      // <div className="view-text">TBA</div>
                      <Datetime inputProps={{ disabled: true }} />
                    )}
                  </FormGroup>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <FormGroup className="mb-5">
                    <Label for="">ATD</Label>
                    {this.state.__data.status_id ===
                    constants.COMPLETE_BERTHPLAN_STATUS ? (
                      <Datetime
                        dateFormat={__dateF}
                        timeFormat={__timeF_ss}
                        value={
                          this.state.__data.atd
                            ? new Date(this.state.__data.atd)
                            : ""
                        }
                        disabled={true}
                        onChange={(e) => {
                          let __data = this.state.__data;
                          __data.atd = e;
                          this.setState({ __data });
                        }}
                      />
                    ) : (
                      // <div className="view-text">TBA</div>
                      <Datetime inputProps={{ disabled: true }} />
                    )}
                  </FormGroup>
                </div>
              </div>

              <div className="col-md-12 d-flex flex-column mt-4">
                {/* <h3>Event Log</h3> */}
                <div className="fcb-title">Event Log</div>
                <Table className="custom-table mt-3" bordered>
                  <thead>
                    <tr>
                      <th>Date and Time</th>
                      <th>Event</th>
                      <th>Remarks</th>
                      <th>Modified By</th>
                      <th>Date Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.berth_plan_logs.map((bpl, i) => {
                      return (
                        <tr>
                          <td>
                            <Moment format={constants.__dateTimeFormat}>
                              {bpl.date}
                            </Moment>
                          </td>
                          <td>{bpl.status_name}</td>
                          <td>{bpl.remarks}</td>
                          <td>{bpl.modified_by}</td>
                          <td>
                            <Moment format={constants.__dateTimeFormat}>
                              {bpl.date_modified}
                            </Moment>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 footer-actions d-flex flex-row">
                {parseInt(this.state.__data.status_id) !==
                parseInt(constant.COMPLETE_BERTHPLAN_STATUS) ? (
                  <div className="col-md-12 d-flex flex-row justify-content-end p-0">
                    <Button
                      onClick={() =>
                        this.props.history.push(
                          "/port_call/berth_plans/" + this.state.__ID
                        )
                      }
                      className="btn-sm"
                      color="danger"
                      style={{ margin: "0 0.5em" }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      className="btn-sm"
                      color="primary"
                      onClick={() => this.handleSave()}
                    >
                      SAVE
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Form>
          </Col>
        </Col>

        <Modal
          style={{ width: "25em" }}
          isOpen={this.state.openModal}
          toggle={this.toggleModal}
          className=""
          onClosed={this.revertStatus}
        >
          <ModalHeader toggle={this.closeModal}>
            Change Status to {this.state.selected_status}
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <Form className="d-flex">
              {this.state.pc_status !== "Closed" ? (
                <FormGroup className="mb-5 col-md-12">
                  <Label for="exampleFile">Enter Actual Date</Label>
                  <Datetime
                    dateFormat={__dateF}
                    timeFormat={__timeF_ss}
                    value={
                      this.state.status_change.date
                        ? new Date(this.state.status_change.date)
                        : ""
                    }
                    onChange={(e) => {
                      let status_change = this.state.status_change;
                      status_change.date = e;

                      let { __data } = this.state;
                      __data.atd = e;

                      this.setState({ status_change });
                    }}
                  />
                </FormGroup>
              ) : (
                <div></div>
              )}

              <FormGroup className="mb-5 col-md-12">
                <Label for="">Enter Remarks (Optional)</Label>
                <Input
                  type="textarea"
                  name="mmsi_no"
                  id=""
                  placeholder=""
                  value={this.state.status_change.remarks}
                  onChange={(e) => {
                    let status_change = this.state.status_change;
                    status_change.remarks = e.target.value;
                    this.setState({ status_change });
                  }}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="sm-btn-font"
              color="danger"
              onClick={this.closeModal}
            >
              Cancel
            </Button>
            <Button
              className="sm-btn-font"
              color="primary"
              onClick={this.handleStatusChange}
            >
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(BerthPlanForm);
