let session = (() => {
  let getName = () => {
    const user = JSON.parse(localStorage.getItem("port_session"));
    return user.first_name + " " + user.last_name; // Or pull this from cookie/localStorage
  };

  let get = () => {
    return JSON.parse(localStorage.getItem("port_session"));
  };

  let set = data => {
    localStorage.setItem("port_session", JSON.stringify(data));
  };

  return {
    getName: getName,
    get: get,
    set: set
  };
})();

export default session;
