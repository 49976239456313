import React, { Component, useState } from "react";
import {
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Form
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Icon } from "react-fa";
import Moment from "react-moment";
import Navbar from "../../Navbar/Navbar";
import "./TugboatCompanies.scss";
import Swal from "sweetalert2";
import api from "../../../helpers/api.helper";
import AlertModal from "../../Tools/AlertModal/AlertModal";

class TugboatCompanies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      TUGBOAT_COMPANIES: [
        {
          tugboats: [
            {
              name: ""
            }
          ]
        }
      ],
      TUGBOATS: [{ name: "" }],
      openModal: false,
      isEdit: false,
      TUGBOAT_COMPANY: [
        {
          name: "",
          tugboats: [
            {
              name: ""
            }
          ]
        }
      ],
      isAlert: "",
      modalMsg: "",
      tglAler: false,
      ERR: {
        tugCompName: false,
        tugboat: false
      }
    };
  }

  componentDidMount = () => {
    this.loadData();
    // this.handleAddTugBoat();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "tugboat_company",
        state_key: "TUGBOAT_COMPANIES"
      })
      .then(data => {
        let tugCompanies = [];

        for (let x in data) {
          if (!data[x].deleted_at) {
            tugCompanies[x] = data[x];
          }
        }

        this.setState({
          TUGBOAT_COMPANIES: tugCompanies
        });
      });
  };

  handleAddTugBoat = () => {
    let { TUGBOATS } = this.state;
    const addTug = {
      name: ""
    };
    TUGBOATS = [...TUGBOATS, addTug];
    this.setState({ TUGBOATS });
  };

  handleRemoveTugBoat(index) {
    let { TUGBOATS } = this.state;
    TUGBOATS.splice(index, 1);

    this.setState({ TUGBOATS });
  }

  handleRemoveTBC(tbc, index, ID) {
    console.log("ID", ID);

    let { TUGBOAT_COMPANIES } = this.state;

    tbc.splice(index, 1);
    TUGBOAT_COMPANIES = tbc;
    this.setState({ TUGBOAT_COMPANIES });

    this.handleDeleteTugboat(ID);
  }

  toggleAddTugboat = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  };

  newTugBoat = () => {
    let { TUGBOAT_COMPANY, TUGBOATS, ERR } = this.state;
    const addTug = {
      name: ""
    };

    let tugboatCompany = [];
    let tugBoats = [addTug];

    TUGBOAT_COMPANY = tugboatCompany;
    TUGBOATS = tugBoats;
    ERR.tugCompName = false;
    ERR.tugboat = false;

    this.setState({
      openModal: !this.state.openModal,
      isEdit: false,
      TUGBOAT_COMPANY,
      TUGBOATS,
      ERR
    });
  };

  handleSave = () => {
    let { TUGBOAT_COMPANY, TUGBOATS } = this.state;

    if (TUGBOAT_COMPANY.name && TUGBOATS.length != 0) {
      api
        .save(this, {
          endpoint: "tugboat_company",
          _data: {
            name: TUGBOAT_COMPANY.name,
            tugboats: TUGBOATS
          }
        })
        .then(data => {
          this.showAlert(
            "success",
            "Tugboat Company successfully saved!",
            true
          );

          this.toggleAddTugboat();
          this.loadData();
        });
    } else {
      let { ERR } = this.state;

      ERR.tugCompName = true;
      ERR.tugboat = true;

      this.setState({
        ERR
      });
    }
  };

  handleTugboatData = i => {
    api
      .get(this, {
        endpoint: "tugboat_company/" + i,
        state_key: "TUGBOAT_COMPANY"
      })
      .then(data => {
        this.setState({
          TUGBOAT_COMPANY: data,
          TUGBOATS: data.tugboats,
          isEdit: true
        });

        this.toggleAddTugboat();
      });
  };

  handleDeleteTugboat = (i, name) => {
    Swal.fire({
      icon: "question",
      title: "Delete " + name + "?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Deleted " + name + "!",
          showConfirmButton: false,
          timer: 1500
        });

        api
          .update(this, {
            endpoint: "tugboat_company/" + i,
            _data: {
              deleted_at: new Date()
            }
          })
          .then(data => {
            this.loadData();
          });
      }
    });
  };

  handleUpdateTugboat = () => {
    let { TUGBOAT_COMPANY, TUGBOATS } = this.state;

    if (TUGBOAT_COMPANY.name && TUGBOATS.length != 0) {
      Swal.fire({
        icon: "question",
        title: "Save changes for " + TUGBOAT_COMPANY.name + "?",
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true
      }).then(result => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Updated " + TUGBOAT_COMPANY.name + "!",
            showConfirmButton: false,
            timer: 1500
          });

          api
            .update(this, {
              endpoint: "tugboat_company/" + TUGBOAT_COMPANY._id,
              _data: {
                name: TUGBOAT_COMPANY.name,
                tugboats: TUGBOATS
              }
            })
            .then(data => {
              this.setState({
                openModal: !this.state.openModal,
                isEdit: false
              });

              this.loadData();
            });
        }
      });
    } else {
      let { ERR } = this.state;

      ERR.tugCompName = true;
      ERR.tugboat = true;

      this.setState({
        ERR
      });
    }
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool
    });

    // console.log("ALERT", bool);
  };

  render() {
    return (
      <div>
        {this.state.tglAlert ? (
          <AlertModal
            sendTrigger={this.state.isAlert}
            sendMsg={this.state.alertMsg}
          />
        ) : (
          ""
        )}
        <div className="flex-column">
          <Navbar />

          <div className="col-md-12 p-20">
            <Col
              md={{ size: 12 }}
              className="form-container-box d-flex flex-column bt-green"
            >
              <div className="col-md-12 d-flex flex-column p-0">
                <div className="col-md-12 fcb-title">
                  Maintenance > Tugboat Companies
                </div>
                <div className="col-md-12 d-flex justify-content-end p-0 mt-3">
                  <Button
                    className="btn-sm"
                    color="primary"
                    onClick={() => this.newTugBoat()}
                  >
                    NEW
                  </Button>
                </div>
                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "10px" }}
                >
                  <thead>
                    <tr>
                      <th width="20%">Tugboat Company</th>
                      <th>Tugboat(s)</th>
                      <th width="5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.TUGBOAT_COMPANIES.map((tc, $i) => {
                      return (
                        <tr key={$i}>
                          <td>{tc.name}</td>
                          <td>
                            {tc.tugboats.map((tb, $i2) => {
                              return (
                                <span key={$i2}>
                                  {tb.name}{" "}
                                  {tc.tugboats.length === $i2 + 1 ? "" : ", "}
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            <div className="d-flex flex-row tblActions w100per">
                              <Icon
                                name="pencil"
                                style={{
                                  backgroundColor: "#4CAF50",
                                  marginRight: "0.5em"
                                }}
                                onClick={() => this.handleTugboatData(tc._id)}
                              />
                              <Icon
                                // onClick={() =>
                                //   this.handleRemoveTBC(
                                //     this.state.TUGBOAT_COMPANIES,
                                //     $i,
                                //     tc._id
                                //   )
                                // }
                                onClick={() =>
                                  this.handleDeleteTugboat(tc._id, tc.name)
                                }
                                name="trash"
                                style={{ backgroundColor: "#f44336" }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </div>

          <Modal
            style={{ width: "25em" }}
            isOpen={this.state.openModal}
            toggle={this.toggleAddTugboat}
          >
            <ModalHeader toggle={this.toggleAddTugboat}>
              {this.state.isEdit
                ? "Edit " + this.state.TUGBOAT_COMPANY.name
                : "New Tugboat Company"}
            </ModalHeader>
            <ModalBody>
              <div className="d-flex flex-column">
                <Form className="col-md-12 p-0 d-flex flex-column">
                  <FormGroup
                    className={
                      this.state.ERR.tugCompName
                        ? "p-0 mb-5 w100per required-field"
                        : "p-0 mb-5 w100per"
                    }
                  >
                    <Label for="">Tugboat Company Name</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      value={this.state.TUGBOAT_COMPANY.name}
                      onChange={e => {
                        let { TUGBOAT_COMPANY, ERR } = this.state;
                        TUGBOAT_COMPANY.name = e.target.value;
                        ERR.tugCompName = TUGBOAT_COMPANY.name ? false : true;
                        this.setState({
                          TUGBOAT_COMPANY,
                          ERR
                        });
                      }}
                    />
                    {this.state.ERR.tugCompName ? (
                      <span className="error-msg">This is required.</span>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                  <FormGroup className="p-0 mb-5 w100per p-relative">
                    <Label for="">Tugboat(s)</Label>
                    {this.state.TUGBOATS.map((tb, i) => {
                      return (
                        <>
                          <div
                            className={
                              this.state.ERR.tugboat
                                ? this.state.TUGBOATS[i].name
                                  ? ""
                                  : "required-field"
                                : ""
                            }
                          >
                            <Input
                              key={i}
                              className="form-control-sm mb-5"
                              type="text"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              value={tb.name}
                              onChange={e => {
                                let { TUGBOATS, ERR } = this.state;

                                TUGBOATS[i].name = e.target.value;
                                // ERR.tugboat = false;

                                this.setState({
                                  TUGBOATS
                                });
                              }}
                            />
                          </div>

                          <Icon
                            name="times"
                            className="tug-erase"
                            onClick={() => this.handleRemoveTugBoat(i)}
                          />
                          {this.state.ERR.tugboat ? (
                            this.state.TUGBOATS[i].name ? (
                              ""
                            ) : (
                              <div
                                className="col-md-12 p0"
                                style={{ margin: "-0.5em 0 0.20em 0" }}
                              >
                                <span className="error-msg">
                                  This is required.
                                </span>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </FormGroup>

                  <div class="col-md-12 d-flex flex-row p-0">
                    <span
                      className="add-tug-btn"
                      onClick={this.handleAddTugBoat}
                    >
                      Add tugboat +
                    </span>
                  </div>
                </Form>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className="fs13"
                color="danger"
                onClick={this.toggleAddTugboat}
              >
                CANCEL
              </Button>
              <Button
                className="fs13"
                color="success"
                onClick={() =>
                  this.state.isEdit
                    ? this.handleUpdateTugboat()
                    : this.handleSave()
                }
              >
                SAVE
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(TugboatCompanies);
