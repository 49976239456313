import React, { Component } from "react";
import { Row, Col, Table, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Icon } from "react-fa";
import DocView from "../DocView/DocView";
import "./VesselMovementLog.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import back_icon from "../../img/svg/BackIcon.svg";
import Moment from "react-moment";
import moment from "moment";
import api from "../../helpers/api.helper";
import constants from "../../constants";
import Media from "react-media";
import PageHeader from "../Tools/PageHeader/PageHeader";
import write from "write";
import classnames from "classnames";
import { ReactComponent as CsvSvg } from "../../img/svg/csv-line.svg";
import { ReactComponent as BillSvg } from "../../img/svg/invoice.svg";

class VesselMovementLog extends Component {
  constructor(props) {
    super(props);

    this.VesselMovementLog = React.createRef();

    this.state = {
      dropdownOpen: false,
      ddValue: "",
      showAudit: false,
      showLogsDD: false,
      viewMode: "editMode",
      __data: {},
      __date_format: constants.__dateFormat,
      __date_time_format: constants.__dateTimeFormat,
      __time_format: constants.__timeFormat,
      __ID: this.props.match.params.id,
      selectedLog: "Vessel Movement Log",
      logsList: ["Vessel Movement Log", "Berth Logs", "Anchorage Logs"],
      portcall_timeline: [],
      vessel_movement_logs: [],
      openModal: false,
      _viewSummary: false,
      _portcall_status: constants.__portcall_status,
      pc_status: "",
      status_change: { tug_boats: [{ name: "" }] },
      selected_tug_boats: [],
      berth_plans: [],
      use_berth_plan: true,
      berths: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.props.match.params.id) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.props.match.params.id,
        })
        .then((data) => {
          /**
           *    Formatting of timeline. we will have to group the logs per date.
           *
           */
          let formatted_status_change_logs = {};
          if (data.status_change_log && data.status_change_log.length > 0) {
            for (let x in data.status_change_log) {
              let d = moment(data.status_change_log[x].date).format(
                constants.__dateFormat
              );

              if (!formatted_status_change_logs[d]) {
                formatted_status_change_logs[d] = [];
              }

              formatted_status_change_logs[d].push(data.status_change_log[x]);
            }

            let arr_formatted = [];
            for (let y in formatted_status_change_logs) {
              arr_formatted = [
                ...arr_formatted,
                { dt: y, timeline: formatted_status_change_logs[y] },
              ];
            }
            this.setState({
              portcall_timeline: arr_formatted,
              vessel_movement_logs: data.vessel_movement,
            });
          }

          this.checkIfPdf();
        })
        .catch((e) => {
          console.log("ERROR!!", e);
        });
    }
  };

  toggleDD = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleAudit = () => {
    this.setState((prevState) => ({
      showAudit: !prevState.showAudit,
    }));
  };

  convertToCSV = () => {
    window.open(
      "http://localhost:4000/api/portcall/download_vessel_movement/" +
        this.props.match.params.id
    );
  };

  downloadBreakdown = () => {
    window.open(
      "http://localhost:4000/api/portcall/download_services/" +
        this.props.match.params.id
    );
  };

  render() {
    const {
      showAudit,
      showLogsDD,
      logsList,
      selectedLog,
      viewMode,
    } = this.state;
    const vd = this.state.__data;
    const vesselMovementLogs = this.state.vessel_movement_logs
      ? this.state.vessel_movement_logs
      : [];
    if (!vd._id) {
      return "";
    }

    const JSONtoCSV = (props) => {
      const fields = [
        {
          label: "DATE AND TIME START",
          value: "date_start",
        },
        {
          label: "DATE AND TIME END",
          value: "date_end",
        },
        {
          label: "LOCATION",
          value: "anchorage",
        },
        {
          label: "DURATION",
          value: "duration",
        },
        {
          label: "SERVICE",
          value: "service",
        },
      ];

      // const json2csvParser = new Parser({ fields });
      // const csv = json2csvParser.parse(props.data);

      // console.log(csv);
      // console.log("PROPS", props.data);

      // return "LOL";
    };

    const TugboatFields = (props) => {
      let arrFields = [];
      let count = 0;

      Object.keys(props.data).forEach((field) => {
        let duration = "";
        duration =
          moment
            .duration(
              moment(props.data[field].pilot_time_out, "YYYY/MM/DD HH:mm").diff(
                moment(props.data[field].pilot_time_in, "YYYY/MM/DD HH:mm")
              )
            )
            .asHours() * 60;

        arrFields[count] = {
          tugboat: props.data[field].name.name,
          pilot: props.data[field].pilot,
          pilot_time_in: props.data[field].pilot_time_in,
          pilot_time_out: props.data[field].pilot_time_out,
          duration: duration,
        };

        count++;
      });

      return (
        <table className="w100per custom-table" style={{ marginTop: 0 }}>
          <tbody>
            {arrFields.map((f, $i) => (
              <tr key={$i}>
                <td
                  width="20%"
                  className={
                    "bg-lt-gr blt " +
                    classnames({
                      bbt: $i === arrFields.length - 1,
                      btt: $i === 0,
                    })
                  }
                >
                  {f.tugboat}
                </td>
                <td
                  width="20%"
                  className={
                    "bg-lt-gr " +
                    classnames({
                      bbt: $i === arrFields.length - 1,
                      btt: $i === 0,
                    })
                  }
                >
                  {f.pilot}
                </td>
                <td
                  width="20%"
                  className={
                    "bg-lt-gr " +
                    classnames({
                      bbt: $i === arrFields.length - 1,
                      btt: $i === 0,
                    })
                  }
                >
                  {moment(f.pilot_time_in).format(constants.__dateTimeFormat)}
                </td>
                <td
                  width="20%"
                  className={
                    "bg-lt-gr " +
                    classnames({
                      bbt: $i === arrFields.length - 1,
                      btt: $i === 0,
                    })
                  }
                >
                  {moment(f.pilot_time_out).format(constants.__dateTimeFormat)}
                </td>
                <td
                  width="20%"
                  className={
                    "bg-lt-gr brt " +
                    classnames({
                      bbt: $i === arrFields.length - 1,
                      btt: $i === 0,
                    })
                  }
                >
                  {f.duration}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

    return (
      <div className="VesselMovementLog">
        <div className="d-flex flex-column">
          <Navbar />
          {/* <PortCallToolbar /> */}
          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav
                  dataFromParent={this.props.match.params.id}
                  visit={vd}
                />
              ) : (
                <PageHeader
                  hideDocToggle
                  sendVName={vd.vessel_name}
                  sendVoyageNo={vd.voyage_number}
                />
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      visit={vd}
                    />
                  </Col>
                ) : (
                  <PageHeader
                    hideDocToggle
                    sendVName={vd.vessel_name}
                    sendVoyageNo={vd.voyage_number}
                    pagename={selectedLog}
                  />
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              <div className="d-flex flex-row justify-contend-end csv-tools-con">
                <span
                  title="Breakdown"
                  className="csv-tools"
                  style={{ backgroundColor: "#FF9800" }}
                  onClick={() => this.downloadBreakdown()}
                >
                  <BillSvg width="1em" />
                </span>
                <span
                  title="CSV"
                  className="csv-tools"
                  style={{ backgroundColor: "#009688" }}
                  onClick={() => this.convertToCSV()}
                >
                  <CsvSvg width="1em" />
                </span>
              </div>
              <Row className="form-container-box bt-green p-rel">
                <Media queries={constants.media_queries}>
                  {(matches) =>
                    matches.xs ? (
                      <div
                        className={
                          showLogsDD
                            ? "logsToggle logsToggle-open"
                            : "logsToggle"
                        }
                      >
                        <ul>
                          {logsList.map((log, index) => (
                            <li
                              className={
                                selectedLog === log ? "active" : undefined
                              }
                              key={index}
                              onClick={() => {
                                // let sLog = this.selectedLog;
                                let vessel_movement_logs = [];
                                switch (log) {
                                  case "Berth Logs":
                                    vessel_movement_logs = vd.berth_logs;
                                    break;
                                  case "Anchorage Logs":
                                    vessel_movement_logs = vd.anchorage_logs;
                                    break;
                                  default:
                                    vessel_movement_logs = vd.vessel_movement;
                                    break;
                                }
                                this.setState({
                                  selectedLog: log,
                                  showLogsDD: false,
                                  vessel_movement_logs,
                                });

                                console.log(selectedLog);
                              }}
                            >
                              {log}
                            </li>
                          ))}
                        </ul>
                        <div
                          onClick={() =>
                            this.setState((prevState) => ({
                              showLogsDD: !prevState.showLogsDD,
                            }))
                          }
                        >
                          <Icon name={showLogsDD ? "caret-left" : "bars"} />
                        </div>
                      </div>
                    ) : (
                      <div className="fcb-title">
                        <div className="d-flex flex-row col-md-12 p0">
                          <div className="col-md-6 d-flex flex-row p0">
                            <Icon
                              className="cur-p"
                              name="bars"
                              onClick={() =>
                                this.setState((prevState) => ({
                                  showLogsDD: !prevState.showLogsDD,
                                }))
                              }
                            />
                            <p style={{ margin: "-0.20em 0 0.25em 0.65em" }}>
                              {selectedLog}
                            </p>
                          </div>

                          {/* <div className="col-md-6 d-flex flex-row p0 justify-content-end">
                            <BillSvg width="20" />
                            <CsvSvg width="20" />
                          </div> */}
                        </div>

                        {showLogsDD && (
                          <div className="logsDD">
                            <ul>
                              {logsList.map((log, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    // let sLog = this.selectedLog;
                                    let vessel_movement_logs = [];
                                    switch (log) {
                                      case "Berth Logs":
                                        vessel_movement_logs = vd.berth_logs;
                                        break;
                                      case "Anchorage Logs":
                                        vessel_movement_logs =
                                          vd.anchorage_logs;
                                        break;
                                      default:
                                        vessel_movement_logs =
                                          vd.vessel_movement;
                                        break;
                                    }
                                    this.setState({
                                      selectedLog: log,
                                      showLogsDD: false,
                                      vessel_movement_logs,
                                    });

                                    console.log(selectedLog);
                                  }}
                                >
                                  {log}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )
                  }
                </Media>

                <Col>
                  <Media queries={constants.media_queries}>
                    {(matches) =>
                      matches.xs ? (
                        <>
                          <ul>
                            {vesselMovementLogs.map((l, $index) => {
                              return vesselMovementLogs.length < 1 ? (
                                <li>
                                  <Row>No Data</Row>
                                </li>
                              ) : (
                                <li>
                                  <Row>
                                    <Col>
                                      <Moment
                                        format={this.state.__date_time_format}
                                      >
                                        {l.date_start}
                                      </Moment>
                                      {" - "}
                                      <Moment
                                        format={this.state.__date_time_format}
                                      >
                                        {l.date_end ? l.date_end : ""}
                                      </Moment>
                                    </Col>
                                  </Row>
                                  <Row xs="2">
                                    <Col>
                                      <span>Location</span>
                                      <span>
                                        {l.anchorage ? l.anchorage : l.berth}
                                      </span>
                                    </Col>
                                    <Col>
                                      <span>Duration</span>
                                      <span>{l.duration}</span>
                                    </Col>
                                    <Col>
                                      <span>Tug Boat / Company</span>
                                      <span>
                                        {l.tug_boats.map((tb, $index) => {
                                          return (
                                            <span>
                                              {tb.name} / {tb.company}
                                            </span>
                                          );
                                        })}
                                      </span>
                                    </Col>
                                    <Col>
                                      <span>Service</span>
                                      <span>{l.service}</span>
                                    </Col>
                                  </Row>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      ) : (
                        <>
                          {/* <JSONtoCSV data={this.state.vessel_movement_logs} /> */}
                          <Table className="custom-table" bordered>
                            <thead>
                              <tr>
                                <th>
                                  Date and <br /> Time Start
                                </th>
                                <th>
                                  Date and <br /> Time End
                                </th>
                                <th>Location</th>
                                <th>Duration</th>
                                <th colSpan="5" className="p0">
                                  <table
                                    className="w100per custom-table"
                                    style={{ marginTop: 0 }}
                                  >
                                    <thead>
                                      <tr>
                                        <th width="20%" className="btt blt bbt">
                                          Tug Boat / Company
                                        </th>
                                        {/* <th width="20%">Service</th> */}
                                        <th width="20%" className="btt bbt">
                                          Pilot
                                        </th>
                                        <th width="20%" className="btt bbt">
                                          Pilot <br /> Time-in
                                        </th>
                                        <th width="20%" className="btt bbt">
                                          Pilot <br /> Time-out
                                        </th>
                                        <th width="20%" className="btt bbt brt">
                                          Duration (mins)
                                        </th>
                                      </tr>
                                    </thead>
                                  </table>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {vesselMovementLogs.map((l, $index) => {
                                return vesselMovementLogs.length < 1 ? (
                                  <tr>
                                    <td
                                      colSpan="5"
                                      style={{ textAlign: "center" }}
                                    >
                                      No Data
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td>
                                      <Moment
                                        format={this.state.__date_time_format}
                                      >
                                        {l.date_start}
                                      </Moment>
                                    </td>
                                    <td>
                                      <Moment
                                        format={this.state.__date_time_format}
                                      >
                                        {l.date_end ? l.date_end : "-"}
                                      </Moment>
                                    </td>
                                    <td>
                                      {l.anchorage ? l.anchorage : l.berth}
                                    </td>
                                    <td>{l.duration}</td>
                                    <td colSpan="5" className="p0">
                                      {/* {l.tug_boats.map((tb, $index) => {
                                        return (
                                          <span>
                                            {tb.name} / {tb.company}
                                          </span>
                                        );
                                      })} */}
                                      <TugboatFields data={l.tug_boats} />
                                    </td>
                                    {/* <td>{l.service}</td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      )
                    }
                  </Media>
                </Col>
              </Row>

              <Row className="form-container-box bt-green mt-3">
                <div className="col-md-12 d-flex flex-row auditHead">
                  <Icon
                    name={showAudit ? "angle-up" : "angle-down"}
                    onClick={this.handleAudit}
                  />

                  <p>Audit Trails</p>
                </div>

                {showAudit ? (
                  <Col md={{ size: 12 }} className="p-0">
                    <Table
                      className="custom-table"
                      bordered
                      style={{ marginTop: "20px" }}
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Details</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default withRouter(VesselMovementLog);
