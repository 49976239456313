import React, { Component } from "react";
import { Col, Table, Button } from "reactstrap";

import Moment from "react-moment";
import Navbar from "../../../Navbar/Navbar";
import "./VesselDetail.scss";

import api from "../../../../helpers/api.helper";

export default class VesselDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vesselList: [],
      __data: []
    };
  }

  componentDidMount = () => {
    api.get(this, { endpoint: "vessel" }).then(data => {});
  };

  render() {
    const { __data } = this.state;

    return (
      <div>
        <div className="flex-column">
          <Navbar />

          <div className="col-md-12 p-20">
            <Col
              md={{ size: 12 }}
              className="form-container-box d-flex flex-column bt-green"
            >
              <div className="col-md-12 d-flex flex-column p-0">
                <div className="fcb-title">Maintenance > Vessels</div>
                <div className="col-md-12 d-flex justify-content-end p-0 mt-3">
                  <Button
                    className="btn-sm"
                    color="warning"
                    style={{ margin: "0 0.5em" }}
                  >
                    SEARCH
                  </Button>
                  <Button
                    data-cy="new_vessel_btn"
                    className="btn-sm"
                    color="primary"
                    onClick={() =>
                      this.props.history.push("/maintenance/vessel/create")
                    }
                  >
                    NEW
                  </Button>
                </div>
              </div>

              <Table
                data-cy="vessels_table"
                className="custom-table"
                bordered
                style={{ marginTop: "10px" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Vessel Type</th>
                    <th>Vessel Class</th>
                    <th>Vessel Size</th>
                    <th>Owner</th>
                    <th>Cargo Type</th>
                    <th>Lenght Overall</th>
                    <th>Draft</th>
                    <th>Delivery Date</th>
                    <th>Port Call Sign</th>
                    <th>Gross Tonnage</th>
                  </tr>
                </thead>
                <tbody>
                  {__data.map(vessel => (
                    <tr
                      className="cur-p"
                      key={vessel._id}
                      onClick={() =>
                        this.props.history.push(
                          "/maintenance/vessel/view/" + vessel._id
                        )
                      }
                    >
                      <td>-</td>
                      <td>{vessel.vessel_name || "-"}</td>
                      <td>{vessel.type || "-"}</td>
                      <td>{vessel.classification || "-"}</td>
                      <td>{vessel.size || "-"}</td>
                      <td>{vessel.cargo_type || "-"}</td>
                      <td>{vessel.registered_owner || "-"}</td>
                      <td>{vessel.draft || "-"}</td>
                      <td>{vessel.loa || "-"}</td>
                      <td>
                        <Moment format="DD-MMM-YYYY">
                          {vessel.date_of_delivery || "-" || "-"}
                        </Moment>
                      </td>
                      <td>{vessel.call_sign || "-"}</td>
                      <td>{vessel.grt || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </div>
        </div>
      </div>
    );
  }
}
