import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Alert,
} from "reactstrap";
import "./VesselEntry.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import { DropdownList } from "react-widgets";
import seaports from "../../../src/sea-ports.json";
import api from "../../helpers/api.helper";
import constants from "../../constants";
import Datetime from "react-datetime";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import PageHeader from "../Tools/PageHeader/PageHeader";
import DocView from "../DocView/DocView";
import AlertModal from "../Tools/AlertModal/AlertModal";
import session from "../../session";
import Toggle from "react-toggle";
import Media from "react-media";
import Icon from "react-fa";
import ForeignVesselForm from "./ForeignVesselForm";
import LocalVesselForm from "./LocalVesselForm";
import localV from "../../img/svg/local.svg";
import foreignV from "../../img/svg/foreign.svg";

class VesselEntry extends Component {
  constructor(props) {
    super(props);

    this.vnRef = React.createRef();

    this.state = {
      __ID: this.props.match.params.id,
      openModal: false,

      __data: {
        mother_vessel: {},
        cargo_details: [
          {
            cargo_type: "",
            item: "",
            amount: 0,
            uom: null,
          },
        ],
        shipping_agency: {
          name: "",
          address: "",
          tel_no: "",
          fax_no: "",
        },
      },
      __vessels: [],
      __vessel_names: [],
      selected_vessel: {},
      selected_port_call_purposes: [],
      seaports: seaports,
      __dateF: constants.__dateFormat,
      __timeF_ss: constants.__timeFormat_ss,
      pending_amendment_eta: null,
      pending_amendment_etd: null,
      viewMode: "editMode",
      isAlert: "",
      modalMsg: "",
      tglAlert: false,
      is_save_btn: false,
      errList: {
        vesselName: {
          name: "Vessel Name",
          msg: "",
        },
        _eta: {
          name: "ETA",
          msg: "",
        },
        _etd: {
          name: "ETD",
          msg: "",
        },
      },
      hasError: false,
      disableSave: false,
      readOnly: false,
      TUGBOATS: [],
      _TUGBOATS: [{ name: "" }],
      PC_VESSELS: [],
      PCALLS: [],
      vesselType: "",
    };
  }

  toggleModal = (stat) => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
      pc_status: stat,
    }));
  };

  closeModal = () => {
    this.setState({ status_change: {} });
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));

    this.revertStatus();
  };

  // componentWillMount = () => {
  //   localStorage.getItem("save_btn") &&
  //     this.setState({
  //       is_save_btn: localStorage.getItem("save_btn")
  //     });
  // };

  componentDidMount = () => {
    this.getData();
    this.disableFields();
    this.loadTugboats();
    this.loadPCvessels();
  };

  // componentWillUpdate = () => {
  //   localStorage.setItem("save_btn", true);
  // };

  getData = () => {
    api
      .get(this, { endpoint: "vessel", state_key: "__vessels" })
      .then((data) => {
        let vessel_names = [];

        for (let x in data) {
          let vessel = data[x];

          vessel_names[x] = vessel.vessel_name;
        }

        this.setState({
          __vessel_names: vessel_names,
        });
      });

    if (!this.state.__ID) {
    } else {
      api
        .get(this, { endpoint: "portcall/" + this.state.__ID })
        .then((data) => {
          // console.log(data)
          // console.log("AL", data.mother_vessel.vessel_name);

          let original_eta = null,
            original_etd = null,
            selected_port_call_purposes = null,
            pending_amendment_eta = null,
            pending_amendment_etd = null;
          if (data.selected_port_call_purposes) {
            selected_port_call_purposes = data.selected_port_call_purposes;
          }

          if (
            parseInt(data.status_id) !== parseInt(constants.PENDING_STATUS) ||
            parseInt(data.status_id) !== parseInt(constants.REJECTED_STATUS)
          ) {
            original_eta = data.eta;
            original_etd = data.etd;
          }

          if (data.pending_amendments && data.pending_amendments.length > 0) {
            for (let x in data.pending_amendments) {
              if (data.pending_amendments[x].eta) {
                pending_amendment_eta = data.pending_amendments[x].eta;
              }
              if (data.pending_amendments[x].etd) {
                pending_amendment_etd = data.pending_amendments[x].etd;
              }
            }
          }

          if (!data.vessel_name && !data) {
            this.setState({
              is_save_btn: false,
            });
          } else {
            this.setState({
              is_save_btn: true,
            });
          }

          // console.log("PCV", data);

          this.setState({
            selected_port_call_purposes,
            original_eta,
            original_etd,
            pending_amendment_etd,
            pending_amendment_eta,
          });
        });
    }
  };

  loadPCvessels = () => {
    api
      .get(this, { endpoint: "portcall", state_key: "PCALLS" })
      .then((data) => {
        let pc_vessels = [];

        for (let x in data) {
          pc_vessels[x] = data[x].vessel_name + " - " + data[x].voyage_number;
        }

        // console.log("PCV", pc_vessels);

        this.setState({ PC_VESSELS: pc_vessels });
      });
  };

  loadTugboats = () => {
    let tugboats_ = [];

    api
      .get(this, { endpoint: "tugboat_company", state_key: "_TUGBOATS" })
      .then((data) => {
        for (let x in data) {
          tugboats_[x] = data[x].name;
        }

        this.setState({
          TUGBOATS: tugboats_,
        });

        // console.log("TUG", tugboats_);
      });
  };

  setSelectedVessel = (vesselInformation) => {
    // console.log("VINFO", vesselInformation);
    if (vesselInformation) {
      let { __data } = this.state;

      __data.grt = vesselInformation.grt ? vesselInformation.grt : 0;
      __data.nrt = vesselInformation.nrt ? vesselInformation.nrt : 0;
      __data.dwt = vesselInformation.dwt ? vesselInformation.dwt : 0;
      __data.loa = vesselInformation.loa ? vesselInformation.loa : 0;
      __data.draft = vesselInformation.draft ? vesselInformation.draft : 0;
      __data.beam = vesselInformation.beam ? vesselInformation.beam : 0;
      __data.beam = vesselInformation.beam ? vesselInformation.beam : 0;
      __data.country = vesselInformation.port_of_registry
        ? vesselInformation.port_of_registry
        : "";
      __data.vessel_owner = vesselInformation.registered_owner
        ? vesselInformation.registered_owner
        : "";
      // __data.master = vesselInformation.master ? vesselInformation.master : "";
      __data.vessel_class = vesselInformation.type
        ? vesselInformation.type
        : "";

      this.setState({ __data });
      // console.log("DATA", __data);
    }
  };

  handleRemoveSelectedPurpose = (index) => {
    let { selected_port_call_purposes } = this.state;
    selected_port_call_purposes.splice(index, 1);

    this.setState({ selected_port_call_purposes });
  };

  handleSelectPurpose = (purpose) => {
    if (purpose) {
      let { selected_port_call_purposes } = this.state;
      selected_port_call_purposes = [...selected_port_call_purposes, purpose];

      this.setState({ selected_port_call_purposes });
    }
  };

  handleAddCargoDetails = () => {
    let { __data } = this.state;
    let { cargo_details } = __data;
    cargo_details = [
      ...cargo_details,
      {
        cargo_type: "",
        item: "",
        amount: 0,
        uom: null,
      },
    ];
    __data.cargo_details = cargo_details;
    this.setState({ __data });
  };

  createAmendment = () => {
    let userSession = localStorage.getItem("port_session");
    if (userSession) {
      userSession = JSON.parse(userSession);
    }

    if (
      parseInt(this.state.__data.status_id) !==
        parseInt(constants.PENDING_STATUS) ||
      parseInt(this.state.__data.status_id) !==
        parseInt(constants.REJECTED_STATUS)
    ) {
      let amendment_data = [],
        has_eta_changed =
          this.state.original_eta !== null &&
          this.state.original_eta !== this.state.__data.eta,
        has_etd_changed =
          this.state.original_etd !== null &&
          this.state.original_etd !== this.state.__data.etd;
      let user_id = userSession._id,
        user_name = userSession.last_name + ", " + userSession.first_name;
      if (has_eta_changed) {
        amendment_data.push({
          field: "eta",
          from_value: this.state.original_eta,
          to_value: this.state.__data.eta,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_etd_changed) {
        amendment_data.push({
          field: "etd",
          from_value: this.state.original_etd,
          to_value: this.state.__data.etd,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      api.save(this, {
        endpoint: "amendment/" + this.state.__data._id,
        _data: amendment_data,
        state_key: "amendments",
      });
    }
  };

  handleCancel = () => {
    this.props.history.push("/port_calls");
  };

  handleSave = () => {
    this.setState({ showButton: true });
    let { __data, selected_port_call_purposes } = this.state;
    let userSession = localStorage.getItem("port_session");
    if (userSession) {
      userSession = JSON.parse(userSession);
    }
    __data.company_id = userSession.company._id;

    if (__data.vessel_name && __data.eta && __data.etd) {
      this.setState({
        hasError: false,
        disableSave: true,
      });
      if (!this.state.__ID) {
        this.showAlert("loading", "", true);
        __data = { ...__data, selected_port_call_purposes };
        __data.status_id = constants.PENDING_STATUS;
        __data.status = "Pending";

        if (!__data.is_along_side_vessel) {
          __data.mother_vessel.vessel_name = "";
        }

        api
          .save(this, {
            endpoint: "portcall",
            _data: __data,
          })
          .then((data) => {
            this.setState(
              {
                tglAlert: false,
                disableSave: false,
              },
              () =>
                this.showAlert(
                  "success",
                  "Vessel Detail successfully saved!",
                  true
                )
            );

            this.props.history.push("/port_call/in_port_services/" + data);
          });
      } else {
        this.showAlert("loading", "", true);
        if (
          parseInt(this.state.__data.status_id) ===
            parseInt(constants.PENDING_STATUS) ||
          parseInt(this.state.__data.status_id) ===
            parseInt(constants.REJECTED_STATUS)
        ) {
          if (!__data.is_along_side_vessel) {
            __data.mother_vessel.vessel_name = "";
          }

          api
            .update(this, {
              endpoint: "portcall/" + this.state.__ID,
              _data: __data,
            })
            .then(() => {
              this.setState(
                {
                  tglAlert: false,
                  disableSave: false,
                },
                () => this.showAlert("success", "Vessel Detail updated!", true)
              );
            });
        } else {
          this.createAmendment();
        }
      }
    } else {
      let { errList } = this.state;

      errList.vesselName.msg = !__data.vessel_name ? "This is required!" : "";
      errList._eta.msg = !__data.eta ? "This is required!" : "";
      errList._etd.msg = !__data.etd ? "This is required!" : "";

      this.setState({ ...errList, hasError: true });

      // console.log("err", errList);
    }
  };

  getViewMode = (view_mode) => {
    this.setState({
      viewMode: view_mode,
    });
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  disableFields = () => {
    if (session.get().role === "port_admin") {
      this.setState({
        readOnly: true,
      });
    }
  };

  handleVtype = () => {
    let { __data } = this.state;

    __data.local_or_foreign = "";

    this.setState({
      vesselType: "",
      __data,
    });
  };

  render() {
    const {
      viewMode,
      isAlert,
      alertMsg,
      is_save_btn,
      tglAlert,
      errList,
      TUGBOATS,
      readOnly,
      PC_VESSELS,
    } = this.state;
    const vd = this.state.__data;

    const vessel = this.state.__data.vessel_name;
    const port_of_origin = this.state.__data.port_of_origin;

    const VNAME = vd.vessel_name;
    const VOYAGE_NO = vd.voyage_number;
    const { referrer } = this.state;

    // console.log("GET VD", this.state.__data.vessel_name);

    if (referrer) return <redirect to={referrer} />;

    return (
      <div className="vesselEntry">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}
        <div className="d-flex flex-column">
          <Navbar />

          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav
                  getVType={this.state.vesselType}
                  dataFromParent={this.state.__ID}
                  visit={vd}
                />
              ) : this.state.__ID ? (
                <PageHeader
                  parentCallback={this.getViewMode}
                  sendVName={this.state.__data.vessel_name}
                  sendVoyageNo={this.state.__data.voyage_number}
                />
              ) : (
                <Col className="pageHeader">
                  <Col className="d-flex flex-row vessel-title">
                    <p className="vessel-name">Vessel Entry Registration</p>
                  </Col>
                </Col>
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav dataFromParent={this.state.__ID} visit={vd} />
                  </Col>
                ) : this.state.__ID ? (
                  <PageHeader
                    parentCallback={this.getViewMode}
                    sendVName={VNAME}
                    sendVoyageNo={VOYAGE_NO}
                    pagename="Vessel Details"
                  />
                ) : (
                  <Col className="pageHeader">
                    <Col className="d-flex flex-row vessel-title">
                      <p className="vessel-name">Vessel Entry Registration</p>
                    </Col>
                  </Col>
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              {/* {!vd.local_or_foreign ? (
                <div className="vesselEntry">
                  <Row className="form-container-box bt-green vessel-detail-form">
                    <Form>
                      <div className="fcb-title">Select Vessel Type</div>

                      <div
                        className="col-md-8 d-flex flex-row"
                        style={{ padding: "3em 0" }}
                      >
                        <div
                          className="col-md-6 local-con"
                          onClick={() => {
                            let { __data } = this.state;

                            __data.local_or_foreign = "Local";

                            this.setState({
                              vesselType: "Local",
                              __data,
                            });
                          }}
                        >
                          <div
                            class="form-container-box p0 cur-p"
                            style={{
                              backgroundColor: "#4CAF50",
                              borderRadius: "10px",
                            }}
                          >
                            <div
                              class="w100per d-flex flex-column align-items-center justify-content-center"
                              style={{ padding: "4em 0" }}
                            >
                              <img
                                src={localV}
                                alt=""
                                style={{ width: "4em" }}
                              />
                            </div>
                            <p className="d-flex justify-content-center typeLabel">
                              LOCAL
                            </p>
                          </div>
                        </div>

                        <div
                          className="col-md-6 foreign-con"
                          onClick={() => {
                            let { __data } = this.state;

                            __data.local_or_foreign = "Foreign";

                            this.setState({
                              vesselType: "Foreign",
                              __data,
                            });
                          }}
                        >
                          <div
                            class="form-container-box p0 cur-p"
                            style={{
                              backgroundColor: "#FF9800",
                              borderRadius: "10px",
                            }}
                          >
                            <div
                              class="w100per d-flex flex-column align-items-center justify-content-center"
                              style={{ padding: "4em 0" }}
                            >
                              <img
                                src={foreignV}
                                alt=""
                                style={{ width: "4em" }}
                              />
                            </div>
                            <p className="d-flex justify-content-center typeLabel">
                              FOREIGN
                            </p>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Row>
                </div>
              ) : vd.local_or_foreign === "Foreign" ? (
                <ForeignVesselForm
                  changeVtype={this.handleVtype}
                  sendID={this.state.__ID}
                />
              ) : vd.local_or_foreign === "Local" ? (
                <LocalVesselForm
                  changeVtype={this.handleVtype}
                  sendID={this.state.__ID}
                />
              ) : (
                ""
              )} */}

              <ForeignVesselForm
                changeVtype={this.handleVtype}
                sendID={this.state.__ID}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(VesselEntry);
