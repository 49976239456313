import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  ModalFooter,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Icon } from "react-fa";
import DocView from "../DocView/DocView";
import "./VisitDetail.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import vessel_placeholder from "../../img/VesselImagePlaceholder.jpg";
import berth_icon_white from "../../img/BerthIconWhite.png";
import Moment from "react-moment";
import moment from "moment";
import api from "../../helpers/api.helper";
import constants from "../../constants";
import Datetime from "react-datetime";
import { DropdownList } from "react-widgets";
import ReactDOM from "react-dom";
import qs from "query-string";
import session from "../../session";
import Can from "../Can/Can";
import PageHeader from "../Tools/PageHeader/PageHeader";
import VesselExitPDF from "../PDF/VesselExitPDF/VesselExitPDF";
import AlertModal from "../Tools/AlertModal/AlertModal";
import Swal from "sweetalert2";
import _ from "lodash";
import Media from "react-media";
import SwipeableViews from "react-swipeable-views";
import { ProgressSteps, Step } from "../Tools/ProgressSteps/ProgressSteps";
import pdf_spam from "../../img/svg/pdf_spam.svg";
import pdf_ships from "../../img/svg/pdf_ships.svg";
import pdf_entry from "../../img/svg/pdf_entry.svg";
import pdf_exit from "../../img/svg/pdf_exit.svg";
import pdf_crews from "../../img/svg/pdf_crews.svg";
import pdf_crews_pd from "../../img/svg/pdf_crews_pd.svg";
import toTimeline from "../../helpers/timeline.helper";
import SPAMpdf from "../PDF/SPAM/SPAMpdf";
import ShipsParticularPDF from "../PDF/ShipsParticularPDF/ShipsParticularPDF";
import VesselEntryClearanceView from "../VesselEntryClearanceView/VesselEntryClearanceView";
import CrewListViewPDF from "../PDF/CrewListViewPDF/CrewListViewPDF";
import classnames from "classnames";

class VisitDetail extends Component {
  constructor(props) {
    super(props);

    this.visitDetail = React.createRef();

    this.state = {
      dropdownOpen: false,
      ddValue: "",
      showAudit: false,
      showLogsDD: false,
      viewMode: "editMode",
      __data: {
        shipping_agency: {},
        selected_port_call_purposes: {},
      },
      __date_format: constants.__dateFormat,
      __date_time_format: constants.__dateTimeFormat,
      __time_format: constants.__timeFormat,
      __ID: this.props.match.params.id,
      selectedLog: "Vessel Movement Log",
      logsList: ["Vessel Movement Log", "Berth Logs", "Anchorage Logs"],
      portcall_timeline: [],
      vessel_movement_logs: [],
      openModal: false,
      _viewSummary: false,
      _markAs: false,
      _portcall_status: constants.__portcall_status,
      pc_status: "",
      markStat: "",
      status_change: { tug_boats: [{ name: "" }], berth_plan_collection: [] },
      selected_tug_boats: [],
      berth_plans: [],
      use_berth_plan: true,
      berths: [],
      isAlert: "",
      alertMsg: "",
      alertSuccess: "",
      tglAlert: false,
      _CALENDAR_EVENT: [],
      is_confirmed: false,
      no_ws: false,
      _previewDoc: "",
      AB_LOGS: [],
      BERTH_PLANS: [],
      PC_BERTH_PLANS: [],
      tab_index: 0,
      openTableInfo: false,
      isDocOpen: false,
      docName: "",
    };
  }

  handleDigest = (stateData) => {
    if (stateData) {
      this.setState({ ...stateData });
    }
  };

  componentDidMount = () => {
    this.loadData().then(() => {
      this.loadLogs();
      this.handleExtractApprovalLogs();
    });
  };

  handleExtractApprovalLogs = () => {
    const { __data } = this.state;
    let tmpEntryApprovals = {},
      tmpExitApprovals = {};
    const { entry_approvals, exit_approvals } = __data;
    if (entry_approvals && entry_approvals.logs.length > 0) {
      entry_approvals.logs.forEach((val) => {
        tmpEntryApprovals[val.level] = val;
      });
    }

    if (exit_approvals && exit_approvals.logs.length > 0) {
      exit_approvals.logs.forEach((val) => {
        tmpExitApprovals[val.level] = val;
      });
    }

    this.setState({
      entry_approvals: tmpEntryApprovals,
      exit_approvals: tmpExitApprovals,
    });
  };

  renderStatus() {
    const { __data } = this.state;
    if (__data.is_vessel_entry_submitted && !__data.is_vessel_entry_approved) {
      return "Pending";
    }

    if (
      __data.is_vessel_entry_approved &&
      !__data.is_in_port &&
      !__data.is_departed
    ) {
      return "Announced";
    }

    if (__data.is_in_port) {
      return "In Port";
    }

    if (__data.is_departed) {
      return "Departed";
    }

    return "-";
  }

  handleApproval = (data) => {
    return new Promise((resolve, reject) => {
      api
        .update(this, {
          endpoint: "portcall/approve/" + this.state.__ID,
          preserve_state: true,
          _data: {
            data,
          },
        })
        .then((response) => {
          // do something here

          return resolve(response);
        });
    });
  };

  loadData = () => {
    if (!this.props.match.params.id) {
    } else {
      return new Promise((resolve, reject) => {
        api
          .get(this, {
            endpoint: "portcall/" + this.props.match.params.id,
          })
          .then((data) => {
            this.setState({
              _CALENDAR_EVENT: data.calendar_schedule,
            });

            /**
             *    Formatting of timeline. we will have to group the logs per date.
             *
             */

            if (data.status_change_log && data.status_change_log.length > 0) {
              const arr_formatted = toTimeline(data.status_change_log);
              this.setState({
                portcall_timeline: arr_formatted,
                vessel_movement_logs: data.vessel_movement,
              });
            }

            this.checkIfPdf();

            return resolve();
          })
          .catch((e) => {
            console.log("ERROR!!", e);
          });
      });
    }
  };

  loadLogs = () => {
    if (!this.props.match.params.id) {
    } else {
      //     // let sortedLogs = _.sortBy(ab_logs).reverse(); // REVERSE SORT
      //     // let sortedLogs = _.reverse(ab_logs); // REVERSE SORT
      //     let sortedLogs = _.sortBy(ab_logs, "date_start"); // BY DATE START
      //     // let sortedLogs = ab_logs; // REVERSE SORT
      api
        .get(this, {
          endpoint: "berth_plan",
          state_key: "BERTH_PLANS", // GET ONLY PENDING
          request_params: {
            // search: { port_call_id: this.state.__ID, status_id: 0 }
            search: { port_call_id: this.state.__ID },
          },
        })
        .then((bp) => {
          let a_logs = [];
          let b_logs = [];

          a_logs = this.state.__data.anchorage_logs;
          b_logs = this.state.__data.berth_logs;

          let merge_logs = [...bp, ...a_logs, ...b_logs];
          let sortedLogs = _.sortBy(merge_logs).reverse();

          this.setState({ AB_LOGS: sortedLogs });
        });
    }
  };

  addEditPortcall = () => {
    if (!this.props.match.params.id) {
      api.save(this, { endpoint: "portcall" }).then((data) => {
        this.setState((prevState) => ({}));
      });
    } else {
      api
        .update(this, {
          endpoint: "portcall/" + this.props.match.params.id,
        })
        .then((data) => {});
    }
  };

  toggleDD = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  toggleTableInfo = () => {
    this.setState((prevState) => ({
      openTableInfo: !prevState.openTableInfo,
    }));
  };

  changeValue = (value) => {
    // this.setState({ ddValue: event.target.value });
    // console.log(this.state.value);
    if (this.state.ddValue !== value) {
      this.setState({
        ddValue: value,
      });
    }
  };

  handleAudit = () => {
    this.setState((prevState) => ({
      showAudit: !prevState.showAudit,
    }));
  };

  toggleModal = (stat) => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
      pc_status: stat,
    }));
  };

  togglePreview = () => {
    this.setState((prevState) => ({
      _viewSummary: !prevState._viewSummary,
    }));
  };

  toggleDocView = (doc) => {
    this.setState((prevState) => ({
      isDocOpen: !prevState.isDocOpen,
    }));

    this.setState({
      docName: doc,
    });
  };

  toggleMarkAs = (stat) => {
    this.setState((prevState) => ({
      _markAs: !prevState._markAs,
      markStat: stat,
    }));
  };

  closeModal = () => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));
  };

  handlePortCallUpdate = (updateData, preserve_state) => {
    if (!updateData) {
      updateData = this.state.__data;
    }

    api
      .update(this, {
        endpoint: "portcall/" + this.state.__data._id,
        _data: updateData,
        preserve_state,
      })
      .then(() => {});
  };

  handleChangeStatus = (status, meta) => {
    // submit

    return new Promise((resolve, reject) => {
      api
        .update(this, {
          endpoint: "portcall/status_change/" + this.state.__data._id,
          state_key: "portcall_status_change",
          preserve_state: true,
          _data: {
            status,
            date: this.state.status_change.date_start,
            remarks: this.state.status_change.remarks
              ? this.state.status_change.remarks
              : "",
          },
        })
        .then(() => {
          resolve(true);
        });
    });
  };

  checkIfPdf() {
    if (
      this.props.location &&
      qs.parse(this.props.location.search) &&
      qs.parse(this.props.location.search).pdf
    ) {
      switch (qs.parse(this.props.location.search).pdf) {
        case "vessel_entry":
          // ReactDOM.render(
          //   <VesselEntryClearanceView visit={this.state.__data} />,
          //   document.getElementById("root")
          // );

          break;

        case "vessel_exit":
          break;

        default:
      }

      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  getViewMode = (view_mode) => {
    this.setState({
      viewMode: view_mode,
    });
  };

  showAlert = (mode, msg, bool, success) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
      alertSuccess: success,
    });
  };

  handleSuccessAlert = (success) => {
    this.setState({
      tglAlert: false,
    });

    Swal.fire({
      icon: "success",
      title: success,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  changePcStatus = (stat) => {
    this.setState({
      pc_status: stat,
    });
  };

  handleChange = (value) => {
    const current_tab = this.tab_index;

    if (current_tab !== value) {
      this.setState({
        tab_index: value,
      });
    }
  };

  handleChangeIndex = (tab_index) => {
    this.setState({
      tab_index,
    });
  };

  render() {
    const {
      showAudit,
      showLogsDD,
      logsList,
      selectedLog,
      openModal,
      viewMode,
      _viewSummary,
      _markAs,
      isAlert,
      alertMsg,
      alertSuccess,
      tglAlert,
      _previewDoc,
      AB_LOGS,
      tab_index,
      openTableInfo,
    } = this.state;
    const vd = this.state.__data;
    const portcall_timeline = this.state.portcall_timeline
      ? this.state.portcall_timeline
      : [];
    const vesselMovementLogs = this.state.vessel_movement_logs
      ? this.state.vessel_movement_logs
      : [];
    if (!vd._id) {
      return "";
    }
    const StatusList = (props) => {
      const dateFormat = constants.__dateFormat + " " + constants.__timeFormat;

      if (props.berth) {
        return (
          <React.Fragment>
            <div class="col-md-1">
              <span>{props.index + 1}</span>
            </div>
            <div
              className="col-md-6 align-items-center"
              style={{ padding: "0" }}
            >
              <div>{props.berth_name}</div>
              <div>
                {/* {props.berth_plan ? (
                  <Moment format={dateFormat}>{props.eta || "-"}</Moment>
                ) : (
                  <Moment format={dateFormat}>{props.date || "-"}</Moment>
                )} */}
                <Moment format={dateFormat}>
                  {props.date ? props.date : props.eta}
                </Moment>
              </div>
              {/* <div>{b.purpose}</div> */}
            </div>
            <div
              className="col-md-5 align-items-center"
              style={{ padding: "0" }}
            ></div>
          </React.Fragment>
        );
      }

      if (props.anchorage) {
        return (
          <React.Fragment>
            <div class="col-md-1">
              <span>{props.index + 1}</span>
            </div>
            <div
              className="col-md-6 align-items-center"
              style={{ padding: "0" }}
            >
              <div>{props.anchorage_name}</div>
              <div>
                <Moment format={dateFormat}>{props.date || "-"}</Moment>
              </div>
              {/* <div>{b.purpose}</div> */}
            </div>
            <div
              className="col-md-5 align-items-center"
              style={{ padding: "0" }}
            ></div>
          </React.Fragment>
        );
      }
    };

    const SwipeableOnXS = (props) => {
      return (
        <Media queries={constants.media_queries}>
          {(matches) =>
            !matches.xs ? (
              <>{props.children}</>
            ) : (
              <>
                <SwipeableTabs
                  tabs={[
                    "Pier",
                    "Tracking",
                    "Documents",
                    "Logs",
                    "Audit Trails",
                  ]}
                />
                <SwipeableViews
                  fullwidth="true"
                  resistance
                  // animateHeight
                  index={tab_index}
                  onChangeIndex={this.handleChangeIndex}
                  className="swipeableOnMobile"
                >
                  {props.children}
                </SwipeableViews>
              </>
            )
          }
        </Media>
      );
    };

    const SwipeableTabs = (props) => {
      const items = props.tabs.length;
      const width = 100 / items;

      return (
        <Row className={"swipeable-tabs " + props.className}>
          <ul>
            {props.tabs.map((b, $index) => {
              return (
                <li
                  key={$index}
                  style={{ width: width + "%" }}
                  className={tab_index === $index ? "active" : undefined}
                  onClick={() => {
                    this.handleChange($index);
                  }}
                >
                  <span>{b}</span>
                </li>
              );
            })}
          </ul>
          <span
            style={{ width: width + "%", left: width * tab_index + "%" }}
          ></span>
        </Row>
      );
    };

    const IS_VESSEL_BERTHED =
      parseInt(this.state.__data.status_id) ===
      parseInt(constants.BERTHED_STATUS);

    let tmp = constants.tug_boats;
    let tug_boats = [];
    for (let x in tmp) {
      if (this.state.selected_tug_boats.indexOf(tmp[x].id) === -1) {
        tug_boats = [...tug_boats, tmp[x]];
      }
    }

    const VNAME = vd.vessel_name;
    const VOYAGE_NO = vd.voyage_number;

    const ProgressRow = (props) => {
      return (
        <Row
          className={
            props.className ? "ab-section " + props.className : "ab-section"
          }
        >
          <Col className="p-0">
            <ProgressSteps>
              <Step
                title="Entry Submitted"
                visited={vd.is_vessel_entry_submitted}
              />
              <Step
                title="Entry Cleared"
                visited={
                  vd.is_vessel_entry_submitted && vd.is_vessel_entry_approved
                }
              />
              <Step title="Berth Assigned" visited={vd.is_berth_assigned} />
              <Step
                title="Resource Allocated"
                visited={vd.has_resource_management}
              />
              <Step
                title="Exit Clearance Submitted"
                visited={vd.is_vessel_exit_submitted}
              />
              <Step
                title="Cleared for Exit"
                visited={vd.is_vessel_exit_submitted && vd.is_cleared_for_exit}
              />
              <Step title="Departed" visited={vd.is_departed} />
            </ProgressSteps>
          </Col>
        </Row>
      );
    };

    const TimelineCont = (props) => {
      return (
        <div className="form-container-box bt-purple vd-timeline">
          <div className="fcb-title fcb-title-v2">Timeline</div>
          <Row xs="1">
            {portcall_timeline.map((t, $index) => {
              return (
                <Col key={$index} className="vdt-content">
                  <p className="timeline-date">
                    <Moment format="MMM DD, YYYY">{t.dt}</Moment>
                  </p>
                  <ul className="timeline-vessel">
                    {t.timeline.map((tl, $i) => {
                      return (
                        <li key={$i}>
                          <p className="timeline-header">
                            <Moment format="HH:mm">{tl.date}</Moment>
                          </p>
                          <div className="timeline-container">
                            <p>
                              {tl.status} <br />
                              by <span>{tl.user}</span>
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              );
            })}
          </Row>
        </div>
      );
    };

    const DocumentCont = (props) => {
      return (
        <div className="form-container-box bt-blue vd-documents">
          <div className="fcb-title fcb-title-v2">Documents</div>
          {/* <DocView visit={vd} /> */}
          <Row xs="2" md="3">
            <Col
              className="documents-item"
              onClick={() => this.toggleDocView("SPAM")}
            >
              <div>
                <div>
                  <img src={pdf_spam} alt="pdf" />
                </div>
                <div>
                  <span>SPAM</span>
                  {/* <span>Last opened recently</span> */}
                </div>
              </div>
            </Col>
            <Col
              className="documents-item"
              onClick={() => this.toggleDocView("Ship's Particulars")}
            >
              <div>
                <div>
                  <img src={pdf_ships} alt="pdf" />
                </div>
                <div>
                  <span>Ship's Particulars</span>
                  {/* <span>Last opened recently</span> */}
                </div>
              </div>
            </Col>
            <Col
              className="documents-item"
              onClick={() => this.toggleDocView("Vessel Entry Clearance")}
            >
              <div>
                <div>
                  <img src={pdf_entry} alt="pdf" />
                </div>
                <div>
                  <span>Vessel Entry Clearance</span>
                  {/* <span>Last opened recently</span> */}
                </div>
              </div>
            </Col>
            <Col
              className="documents-item"
              onClick={() => this.toggleDocView("Vessel Exit Clearance")}
            >
              <div>
                <div>
                  <img src={pdf_exit} alt="pdf" />
                </div>
                <div>
                  <span>Vessel Exit Clearance</span>
                  {/* <span>Last opened recently</span> */}
                </div>
              </div>
            </Col>
            <Col
              className="documents-item"
              onClick={() => {
                this.setState({
                  crewlist_type: "Pre Arrival",
                  crewlist_search_type: "prearrival",
                });
                this.toggleDocView("Crew List");
              }}
            >
              <div>
                <div>
                  <img src={pdf_crews} alt="pdf" />
                </div>
                <div>
                  <span>Pre Arrival Crew List</span>
                  {/* <span>Last opened recently</span> */}
                </div>
              </div>
            </Col>

            <Col
              className="documents-item"
              onClick={() => {
                this.setState({
                  crewlist_type: "Pre Departure",
                  crewlist_search_type: "predeparture",
                });
                this.toggleDocView("Crew List");
              }}
            >
              <div>
                <div>
                  <img src={pdf_crews_pd} alt="pdf" />
                </div>
                <div>
                  <span>Pre Departure Crew List</span>
                  {/* <span>Last opened recently</span> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    const AnchorageCont = (props) => {
      return (
        <div className="form-container-box bt-orange with-berth">
          <div className="fcb-title fcb-title-v2">Berth</div>
          <Row xs="1" md="2">
            <Col>
              <div>Planned</div>
              <Row className="berth-list" xs="1">
                {this.state.berth_plans.length === 0
                  ? "No Data Found"
                  : this.state.berth_plans.map((ab, i) => (
                      <Col
                        key={i}
                        className="berth-row d-flex flex-row align-items-center"
                      >
                        <StatusList
                          berth
                          berth_name={ab.berth}
                          eta={ab.eta}
                          date={ab.date}
                          data={ab}
                          status={ab.status}
                          status_id={ab.status_id}
                          index={i}
                          // data={this.state.berth_plans}
                          berth_plan={true}
                        />
                      </Col>
                    ))}
              </Row>
            </Col>
            <Col>
              <div>Actual</div>
              <Row className="berth-list" xs="1">
                {this.state.__data.vessel_movement.length === 0
                  ? "No Data Found"
                  : this.state.__data.vessel_movement.map((ab, i) => (
                      <Col
                        key={i}
                        className="berth-row d-flex flex-row align-items-center"
                      >
                        {ab.anchorage ? (
                          <StatusList
                            anchorage
                            anchorage_name={ab.anchorage}
                            date={ab.date_start}
                            data={ab}
                            status={ab.status}
                            index={i}
                            // data={this.state.__data.anchorage_logs}
                          />
                        ) : (
                          <StatusList
                            berth
                            berth_name={ab.berth}
                            eta={ab.eta}
                            date={ab.date}
                            data={ab}
                            status={ab.status}
                            status_id={ab.status_id}
                            index={i}
                            // data={this.state.berth_plans}
                            berth_plan={true}
                          />
                        )}
                      </Col>
                    ))}
              </Row>
            </Col>
          </Row>
        </div>
      );
    };

    const ABSecRow = (props) => {
      if (props.tldoc) {
        return (
          <Row className="ab-section">
            <Col xs="6">
              <TimelineCont />
            </Col>
            <Col xs="6">
              <DocumentCont />
            </Col>
          </Row>
        );
      } else {
        return (
          <Row
            className={
              props.className ? "ab-section " + props.className : "ab-section"
            }
          >
            <Col xs="12">
              {props.timeline && <TimelineCont />}
              {props.document && <DocumentCont />}
              {props.anchorage && <AnchorageCont />}
            </Col>
          </Row>
        );
      }
    };

    return (
      <div className="visitDetail">
        {tglAlert ? (
          <AlertModal
            sendTrigger={isAlert}
            sendMsg={alertMsg}
            successMsg={alertSuccess}
          />
        ) : (
          ""
        )}

        <div className="d-flex flex-column">
          <Navbar />
          <PortCallToolbar
            portcall={this.state.__data}
            digestHandler={this.handleDigest}
            portcallStateKey="__data"
          />
          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav dataFromParent={this.state.__ID} visit={vd} />
              ) : (
                <PageHeader
                  parentCallback={this.getViewMode}
                  sendVName={VNAME}
                  sendVoyageNo={VOYAGE_NO}
                />
              )
            }
          </Media>

          <Modal
            keyboard={false}
            backdrop="static"
            style={{}}
            isOpen={_viewSummary}
            toggle={this.togglePreview}
            className="preview-modal"
            zIndex="2001"
            centered
          >
            <ModalHeader toggle={this.togglePreview}>
              Vessel {_previewDoc === "entry" ? "Entry" : "Exit"} Clearance
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
              {parseInt(vd.status_id) === parseInt(constants.PENDING_STATUS) ? (
                <Col
                  className="d-flex flex-row"
                  style={{ padding: "0 1.75em 0.25em 0" }}
                >
                  <Button
                    className="col-md-2 my-2 ml-auto"
                    color="aesi-active"
                    size="sm"
                    onClick={(e) => {
                      this.handleChangeStatus(constants.SUBMITTED_STATUS);
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              ) : (
                ""
              )}

              {/* <Col className="d-flex flex-row p-0" style={{ width: "8.5in" }}>
                <Button
                  className="col-md-2 my-2 ml-auto"
                  color="aesi-success"
                  size="sm"
                  onClick={e => {
                    this.handleChangeStatus(constants.ANNOUNCED_STATUS);
                  }}
                >
                  Approve
                </Button>
                <Button
                  className="col-md-2 my-2 ml-2"
                  color="aesi-danger"
                  size="sm"
                  onClick={e => {
                    this.handleChangeStatus(constants.REJECTED_STATUS);
                  }}
                >
                  Return
                </Button>
              </Col> */}

              {parseInt(vd.status_id) === parseInt(constants.PENDING_STATUS) ? (
                <Col className="d-flex flex-row p-0" style={{ width: "8.5in" }}>
                  <Button
                    className="col-md-2 my-2 ml-auto"
                    color="aesi-active"
                    size="sm"
                    onClick={(e) => {
                      this.handleChangeStatus(constants.SUBMITTED_STATUS);
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              ) : (
                ""
              )}

              {parseInt(vd.status_id) ===
              parseInt(constants.SUBMITTED_STATUS) ? (
                <Col className="d-flex flex-row p-0" style={{ width: "8.5in" }}>
                  <Button
                    className="col-md-2 my-2 ml-auto"
                    color="aesi-success"
                    size="sm"
                    onClick={(e) => {
                      this.handleChangeStatus(constants.ANNOUNCED_STATUS);
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    className="col-md-2 my-2 ml-2"
                    color="aesi-danger"
                    size="sm"
                    onClick={(e) => {
                      this.handleChangeStatus(constants.REJECTED_STATUS);
                    }}
                  >
                    Reject
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </ModalBody>
            {/* <ModalFooter></ModalFooter> */}
          </Modal>

          <Modal
            keyboard={false}
            backdrop="static"
            style={{}}
            isOpen={_markAs}
            toggle={this.toggleMarkAs}
            centered
          >
            <ModalHeader toggle={this.toggleMarkAs}>
              Mark As Complete
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
              <FormGroup className="mb-5 col-md-12">
                <Label for="exampleFile" className="fs13">
                  Enter Actual Date and Time of{" "}
                  {this.state.markStat === "berth"
                    ? "Berth Completion"
                    : "Completion at Anchorage"}
                </Label>
                <Datetime
                  dateFormat={this.state.__date_format}
                  timeFormat={this.state.__time_format}
                  // value={}
                  onChange={(e) => {
                    this.setState({ modal_actual_end: e });
                  }}
                />
              </FormGroup>
              <FormGroup
                className="mb-5 col-md-12 fs13"
                style={{ marginTop: "0.5em" }}
              >
                <Label for="">Enter Remarks (Optional)</Label>
                <Input
                  type="textarea"
                  name="mmsi_no"
                  id=""
                  placeholder=""
                  // value={}
                  // onChange={}
                />
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Button
                className="sm-btn-font"
                color="danger"
                onClick={this.toggleMarkAs}
              >
                Cancel
              </Button>
              <Button
                className="sm-btn-font"
                color="primary"
                onClick={() => {
                  if (
                    this.state.modal_props.index !== undefined &&
                    this.state.modal_props.collection[
                      this.state.modal_props.index
                    ]
                  ) {
                    if (!this.state.modal_props.berth_plan) {
                      let { modal_props } = this.state;

                      modal_props.collection[modal_props.index].status = 2;
                      modal_props.collection[
                        modal_props.index
                      ].date_end = new Date(this.state.modal_actual_end);

                      this.setState({ modal_props });
                    } else if (
                      this.state.modal_props.berth_plan &&
                      this.state.modal_props.type === "berth"
                    ) {
                      let oBerthPlan = this.state.modal_props.collection[
                        this.state.modal_props.index
                      ];

                      let { modal_props } = this.state;
                      let berth_logs = this.state.__data.berth_logs;

                      modal_props.collection[modal_props.index].status_id = 2;
                      modal_props.collection[modal_props.index].status =
                        "Completed";
                      modal_props.collection[
                        modal_props.index
                      ].date_end = new Date(this.state.modal_actual_end);

                      this.setState({ modal_props });
                      let berth_plan_logs = this.state.status_change
                        .berth_plan_collection;

                      berth_logs[berth_logs.length - 1].date_end =
                        modal_props.collection[modal_props.index].date_end;

                      let duration = moment.duration(
                        moment(berth_logs[berth_logs.length - 1].end_date).diff(
                          moment(berth_logs[berth_logs.length - 1].date_start)
                        )
                      );
                      berth_logs[
                        berth_logs.length - 1
                      ].duration = duration.asHours();

                      this.handleBerthPlanUpdate(oBerthPlan, {
                        status_id: 2,
                        status: "Completed",
                        berth_id:
                          modal_props.collection[modal_props.index].berth_id,
                        berth_plan_id:
                          modal_props.collection[modal_props.index]._id,
                        date_end:
                          modal_props.collection[modal_props.index].date_end,
                      }).then((res) => {
                        if (res) {
                          this.setState({
                            berth_plans: berth_plan_logs,
                            __data: {
                              ...this.state.__data,
                              berth_logs,
                            },
                          });

                          this.toggleMarkAs();
                        }
                      });
                      //update berth plan log
                    }

                    if (this.state.modal_props.type === "anchorage") {
                      let anchorage_logs = this.state.modal_props.collection;
                      this.setState(
                        {
                          __data: {
                            ...this.state.__data,
                            anchorage_logs,
                          },
                        },
                        () => {
                          // this.handlePortCallUpdate();
                          this.handleAnchorageUpdate({
                            anchorage: this.state.status_change.anchorage,
                            anchorage_id: this.state.status_change.anchorage_id,
                            status: 2,
                            date_end: new Date(this.state.modal_actual_end),
                            tug_boats: this.state.status_change.tug_boats,
                            remarks: this.state.status_change.remarks,
                          });

                          this.toggleMarkAs();
                        }
                      );

                      this.closeModal();
                    }

                    if (this.state.modal_props.type === "berth") {
                      if (this.state.modal_props.berth_plan) {
                        //if from berth plan
                        let berth_plan_logs = this.state.modal_props.collection;
                        this.setState({ berth_plans: berth_plan_logs });
                      } else {
                        let berth_logs = this.state.modal_props.collection;
                        this.setState(
                          {
                            __data: {
                              ...this.state.__data,
                              berth_logs,
                            },
                          },
                          () => {
                            // this.handlePortCallUpdate();
                            this.toggleMarkAs();
                          }
                        );
                      }

                      this.closeModal();
                    }
                  }

                  this.closeModal();
                }}
              >
                Save
              </Button>{" "}
            </ModalFooter>
          </Modal>

          <Modal
            keyboard={false}
            backdrop="static"
            style={{}}
            isOpen={this.state.isDocOpen}
            toggle={() => {
              let { isDocOpen } = this.state;

              this.setState({
                isDocOpen: !this.state.isDocOpen,
              });
            }}
            className={
              "preview-modal " +
              classnames({ maxWidth80em: this.state.docName === "Crew List" })
            }
            zIndex="2001"
            centered
          >
            <ModalHeader
              toggle={() => {
                let { isDocOpen } = this.state;

                this.setState({
                  isDocOpen: !this.state.isDocOpen,
                });
              }}
            >
              {this.state.docName}
            </ModalHeader>
            <ModalBody
              className="d-flex flex-column"
              style={{ minHeight: "50em" }}
            >
              {this.state.docName === "SPAM" ? (
                <SPAMpdf getID={this.state.__ID} />
              ) : (
                ""
              )}
              {this.state.docName === "Ship's Particulars" ? (
                <ShipsParticularPDF getID={this.state.__ID} />
              ) : (
                ""
              )}

              {this.state.docName === "Vessel Entry Clearance" ? (
                <VesselEntryClearanceView
                  visit={this.state.__data}
                  pcID={this.state.__ID}
                  approvals={this.state.entry_approvals}
                />
              ) : (
                ""
              )}
              {this.state.docName === "Crew List" ? (
                <CrewListViewPDF
                  type={this.state.crewlist_type}
                  portcall_id={this.state.__ID}
                  search_type={this.state.crewlist_search_type}
                />
              ) : (
                ""
              )}

              {this.state.docName === "Vessel Exit Clearance" ? (
                <VesselExitPDF
                  pcID={this.state.__ID}
                  visit={this.state.__data}
                  approvals={this.state.exit_approvals}
                />
              ) : (
                ""
              )}
            </ModalBody>
          </Modal>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav dataFromParent={this.state.__ID} visit={vd} />
                  </Col>
                ) : (
                  <PageHeader
                    parentCallback={this.getViewMode}
                    sendVName={VNAME}
                    sendVoyageNo={VOYAGE_NO}
                    pagename={
                      viewMode === "editMode" ? "hide-me" : "Visit Detail"
                    }
                  />
                )
              }
            </Media>

            <Col md="10" xs="12">
              <Media queries={constants.media_queries}>
                {(matches) =>
                  !matches.xs && <ProgressRow className="with-progress" />
                }
              </Media>

              <Row className="ab-section">
                <Col xs="12" md="3">
                  <Col xs="12" className="form-container-box vessel-status">
                    <div>
                      <div className="col-md-6">
                        {vd.eta ? (
                          <React.Fragment>
                            <div className="vd-value">
                              <Moment format={constants.__dateFormat}>
                                {vd.eta || "-"}
                              </Moment>
                              <br />
                              <Moment format={constants.__timeFormat}>
                                {vd.eta || "-"}
                              </Moment>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="vd-value">-</div>
                        )}

                        <div className="vd-label">ETA</div>
                      </div>
                      <div className="col-md-6">
                        {vd.etd ? (
                          <React.Fragment>
                            <div className="vd-value">
                              <Moment format={constants.__dateFormat}>
                                {vd.etd || "-"}
                              </Moment>
                              <br />
                              <Moment format={constants.__timeFormat}>
                                {vd.etd || "-"}
                              </Moment>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="vd-value">-</div>
                        )}

                        <div className="vd-label">ETD</div>
                      </div>
                    </div>
                    <div className="hr-div"></div>
                    <div>
                      <h1>{this.renderStatus()}</h1>
                    </div>
                    <div className="hr-div"></div>
                    <div>
                      <div>
                        <div className="vd-label">Time Arrived</div>
                        <div className="vd-value">
                          {vd.ata ? (
                            <React.Fragment>
                              <Moment format={constants.__dateFormat}>
                                {vd.ata || "-"}
                              </Moment>{" "}
                              <Moment format={constants.__timeFormat}>
                                {vd.ata || "-"}
                              </Moment>
                            </React.Fragment>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label">Time Departed</div>
                        <div className="vd-value">
                          {vd.atd ? (
                            <React.Fragment>
                              <Moment format={constants.__dateFormat}>
                                {vd.atd || "-"}
                              </Moment>{" "}
                              <Moment format={constants.__timeFormat}>
                                {vd.atd || "-"}
                              </Moment>
                            </React.Fragment>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs="12" className="form-container-box vessel-info">
                    <div className="image-container-200">
                      <img
                        src={
                          !vd.vessel_image
                            ? vessel_placeholder
                            : vd.vessel_image
                        }
                        alt="vessel-default"
                      />
                    </div>
                    <div className="mb-20">
                      <div className="vd-value col-md-12 text-center">
                        <h5 className="m-0">{vd.vessel_name || "-"}</h5>
                      </div>
                      <div className="vd-label col-md-12 text-center">
                        Vessel Name
                      </div>
                    </div>
                    <div
                      className={
                        openTableInfo
                          ? "vessel-info-table open-xs"
                          : "vessel-info-table"
                      }
                    >
                      <div>
                        <div className="vd-label col-5 col-md-6">
                          Flag/Country
                        </div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.port_of_registry || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label col-5 col-md-6">
                          Vessel Class/Type
                        </div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.type || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label col-5 col-md-6">GRT</div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.grt || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label col-5 col-md-6">NRT</div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.nrt || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label col-5 col-md-6">DWT</div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.dwt || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label col-5 col-md-6">
                          Shipping Agency
                        </div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.shipping_agency_name || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label col-5 col-md-6">Master</div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.master || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label col-5 col-md-6">Cargo</div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.cargo || "-"}
                        </div>
                      </div>
                      <div>
                        <div className="vd-label col-5 col-md-6">B/L Qty</div>
                        <div className="vd-value col-7 col-md-6">
                          {vd.bl_qty || "-"}
                        </div>
                      </div>
                    </div>
                    <div className="arrow-button">
                      <span onClick={this.toggleTableInfo}>
                        <Icon
                          name={openTableInfo ? "chevron-up" : "chevron-down"}
                        />
                      </span>
                    </div>
                  </Col>
                </Col>

                <Col xs="12" md="9">
                  <Media queries={constants.media_queries}>
                    {(matches) =>
                      !matches.xs ? (
                        <>
                          <ABSecRow anchorage />
                          <ABSecRow tldoc />
                        </>
                      ) : (
                        <ProgressRow />
                      )
                    }
                  </Media>
                </Col>
              </Row>

              <SwipeableOnXS>
                <Media queries={constants.media_queries}>
                  {(matches) => matches.xs && <ABSecRow anchorage />}
                </Media>
                <Media queries={constants.media_queries}>
                  {(matches) => matches.xs && <ABSecRow timeline />}
                </Media>
                <Media queries={constants.media_queries}>
                  {(matches) => matches.xs && <ABSecRow document />}
                </Media>
                <Row className="ab-section">
                  <Col xs="12">
                    <div className="form-container-box bt-green">
                      <Col md="12" className="fcb-title">
                        <div className="d-flex flex-row">
                          <Icon
                            className="cur-p"
                            name="bars"
                            onClick={() =>
                              this.setState((prevState) => ({
                                showLogsDD: !prevState.showLogsDD,
                              }))
                            }
                          />
                          <p style={{ margin: "-0.20em 0 0.25em 0.65em" }}>
                            {selectedLog}
                          </p>
                        </div>

                        {showLogsDD && (
                          <div className="logsDD">
                            <ul>
                              {logsList.map((log, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    // let sLog = this.selectedLog;
                                    let vessel_movement_logs = [];
                                    switch (log) {
                                      case "Berth Logs":
                                        vessel_movement_logs = vd.berth_logs;
                                        break;
                                      case "Anchorage Logs":
                                        vessel_movement_logs =
                                          vd.anchorage_logs;
                                        break;
                                      default:
                                        vessel_movement_logs =
                                          vd.vessel_movement;
                                        break;
                                    }
                                    this.setState({
                                      selectedLog: log,
                                      showLogsDD: false,
                                      vessel_movement_logs,
                                    });
                                  }}
                                >
                                  {log}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Col>

                      <Col md="12" className="portcall-logs">
                        <Media queries={constants.media_queries}>
                          {(matches) =>
                            !matches.xs ? (
                              <Table
                                className="custom-table"
                                bordered
                                style={{ marginTop: "20px" }}
                              >
                                <thead>
                                  <tr>
                                    <th>Date and Time Start</th>
                                    <th>Date and Time End</th>
                                    <th>Location</th>
                                    <th>Duration</th>
                                    <th>Tug Boat / Company</th>
                                    <th>Service</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vesselMovementLogs.map((l, $index) => {
                                    return vesselMovementLogs.length < 1 ? (
                                      <tr>
                                        <td
                                          colSpan="5"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Data
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr key={$index}>
                                        <td>
                                          <Moment
                                            format={
                                              this.state.__date_time_format
                                            }
                                          >
                                            {l.date_start}
                                          </Moment>
                                        </td>
                                        <td>
                                          {l.date_end ? (
                                            <Moment
                                              format={
                                                this.state.__date_time_format
                                              }
                                            >
                                              {l.date_end}
                                            </Moment>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {l.anchorage ? l.anchorage : l.berth}
                                        </td>
                                        <td>{l.duration}</td>
                                        <td>
                                          {l.tug_boats
                                            ? l.tug_boats.forEach((tb) => {
                                                return (
                                                  "<div>" + tb.name + "</div>"
                                                );
                                              })
                                            : ""}
                                        </td>
                                        <td>{l.service}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : (
                              <div className="logs-list">
                                {vesselMovementLogs.map((l, $index) => {
                                  return vesselMovementLogs.length < 1 ? (
                                    "no data"
                                  ) : (
                                    <div key={$index}>
                                      <div>
                                        {l.duration ? l.duration : "---"}
                                      </div>

                                      <div className="tug-name">
                                        {l.tug_boats.length > 0
                                          ? l.tug_boats.map((tb, $index) => {
                                              return (
                                                <span key={$index}>
                                                  {tb.name} / {tb.company}
                                                </span>
                                              );
                                            })
                                          : "---"}
                                      </div>

                                      <div className="row ">
                                        <div className="col-6">
                                          <div>
                                            {l.anchorage
                                              ? l.anchorage
                                              : l.berth}
                                          </div>

                                          <div>
                                            {l.service ? l.service : "---"}
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div>
                                            {l.date_start ? (
                                              <Moment
                                                format={
                                                  this.state.__date_time_format
                                                }
                                              >
                                                {l.date_start}
                                              </Moment>
                                            ) : (
                                              "---"
                                            )}
                                            <br />
                                            {l.date_end ? (
                                              <Moment
                                                format={
                                                  this.state.__date_time_format
                                                }
                                              >
                                                {l.date_end}
                                              </Moment>
                                            ) : (
                                              "---"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )
                          }
                        </Media>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Row className="ab-section">
                  <Col xs="12">
                    <div className="form-container-box bt-green">
                      <Col md="12" className="d-flex flex-row auditHead">
                        {showAudit ? (
                          <Icon
                            className=""
                            name="angle-up"
                            onClick={this.handleAudit}
                          />
                        ) : (
                          <Icon
                            className=""
                            name="angle-down"
                            onClick={this.handleAudit}
                          />
                        )}

                        <p>Audit Trails</p>
                      </Col>

                      <div>
                        {showAudit ? (
                          <Col md="12" className="p-0">
                            <Table
                              className="custom-table"
                              bordered
                              style={{ marginTop: "20px" }}
                            >
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Details</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </SwipeableOnXS>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(VisitDetail);
