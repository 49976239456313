import React, { Component, useState } from "react";
import {
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Form
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Icon } from "react-fa";
import Moment from "react-moment";
import Navbar from "../../Navbar/Navbar";
import "./AnchorageMaintenance.scss";
import Swal from "sweetalert2";
import api from "../../../helpers/api.helper";
import AlertModal from "../../Tools/AlertModal/AlertModal";

class AnchorageMaintenance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      isEdit: false,
      isAlert: "",
      modalMsg: "",
      tglAler: false,
      ANCHORAGES: [],
      ANCHORAGE: [],
      ERR_LIST: {
        anchorageName: false,
        latErr: false,
        longErr: false
      }
    };
  }

  componentDidMount = () => {
    this.loadData();
    // this.handleAddTugBoat();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "anchorage",
        state_key: "ANCHORAGES"
      })
      .then(data => {
        let anchorages = [];

        for (let x in data) {
          if (!data[x].deleted_at) {
            anchorages[x] = data[x];
          }
        }

        this.setState({
          ANCHORAGES: anchorages
        });
      });
  };

  toggleAddAnchorage = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  };

  newAnchorage = () => {
    let { ANCHORAGE } = this.state;
    const addAnchorage = {
      name: "",
      lat: 0,
      long: 0
    };

    let anchorage = [];

    ANCHORAGE = anchorage;

    this.setState({
      openModal: !this.state.openModal,
      isEdit: false,
      ANCHORAGE
    });
  };

  handleSave = () => {
    let { ANCHORAGE } = this.state;

    if (ANCHORAGE.name) {
      api
        .save(this, {
          endpoint: "anchorage",
          _data: {
            name: ANCHORAGE.name,
            lat: ANCHORAGE.lat,
            long: ANCHORAGE.long
          }
        })
        .then(data => {
          this.showAlert("success", "Anchorage successfully saved!", true);

          this.toggleAddAnchorage();
          this.loadData();
        });
    } else {
      let { ERR_LIST } = this.state;

      ERR_LIST.anchorageName = true;

      this.setState({
        ERR_LIST
      });
    }
  };

  handleDeleteAnchorage = (i, name) => {
    Swal.fire({
      icon: "question",
      title: "Delete " + name + "?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Deleted " + name + "!",
          showConfirmButton: false,
          timer: 1500
        });

        api
          .update(this, {
            endpoint: "anchorage/" + i,
            _data: {
              deleted_at: new Date()
            }
          })
          .then(data => {
            this.loadData();
          });
      }
    });
  };

  handleAnchorageData = i => {
    api
      .get(this, {
        endpoint: "anchorage/" + i,
        state_key: "ANCHORAGE"
      })
      .then(data => {
        this.setState({
          isEdit: true
        });

        this.toggleAddAnchorage();
      });
  };

  handleUpdateAnchorage = () => {
    let { ANCHORAGE } = this.state;

    if (ANCHORAGE.name) {
      Swal.fire({
        icon: "question",
        title: "Save changes for " + ANCHORAGE.name + "?",
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true
      }).then(result => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Updated " + ANCHORAGE.name + "!",
            showConfirmButton: false,
            timer: 1500
          });

          api
            .update(this, {
              endpoint: "anchorage/" + ANCHORAGE._id,
              _data: {
                name: ANCHORAGE.name,
                lat: ANCHORAGE.lat,
                long: ANCHORAGE.long
              }
            })
            .then(data => {
              this.setState({
                openModal: !this.state.openModal,
                isEdit: false
              });

              this.loadData();
            });
        } else {
          let { ERR_LIST } = this.state;

          ERR_LIST.anchorageName = true;

          this.setState({
            ERR_LIST
          });
        }
      });
    }
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool
    });

    // console.log("ALERT", bool);
  };

  render() {
    return (
      <div>
        {this.state.tglAlert ? (
          <AlertModal
            sendTrigger={this.state.isAlert}
            sendMsg={this.state.alertMsg}
          />
        ) : (
          ""
        )}
        <div className="flex-column">
          <Navbar />

          <div className="col-md-12 p-20">
            <Col
              md={{ size: 12 }}
              className="form-container-box d-flex flex-column bt-green"
            >
              <div className="col-md-12 d-flex flex-column p-0">
                <div className="col-md-12 fcb-title">
                  Maintenance > Anchorage
                </div>
                <div className="col-md-12 d-flex justify-content-end p-0 mt-3">
                  <Button
                    className="btn-sm"
                    color="primary"
                    onClick={() => this.newAnchorage()}
                  >
                    NEW
                  </Button>
                </div>
                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "10px" }}
                >
                  <thead>
                    <tr>
                      <th width="20%">Anchorage</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th width="5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.ANCHORAGES.map((ac, $i) => {
                      return (
                        <tr key={$i}>
                          <td>{ac.name}</td>
                          <td>{ac.lat}</td>
                          <td>{ac.long}</td>
                          <td>
                            <div className="d-flex flex-row tblActions w100per">
                              <Icon
                                name="pencil"
                                style={{
                                  backgroundColor: "#4CAF50",
                                  marginRight: "0.5em"
                                }}
                                onClick={() => this.handleAnchorageData(ac._id)}
                              />
                              <Icon
                                // onClick={() =>
                                //   this.handleRemoveTBC(
                                //     this.state.TUGBOAT_COMPANIES,
                                //     $i,
                                //     ac._id
                                //   )
                                // }
                                onClick={() =>
                                  this.handleDeleteAnchorage(ac._id, ac.name)
                                }
                                name="trash"
                                style={{ backgroundColor: "#f44336" }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </div>

          <Modal
            style={{ width: "25em" }}
            isOpen={this.state.openModal}
            toggle={this.toggleAddAnchorage}
          >
            <ModalHeader toggle={this.toggleAddAnchorage}>
              {this.state.isEdit
                ? "Edit " + this.state.ANCHORAGE.name
                : "New Anchorage"}
            </ModalHeader>
            <ModalBody>
              <div className="d-flex flex-column">
                <Form className="col-md-12 p-0 d-flex flex-column">
                  <FormGroup
                    className={
                      this.state.ERR_LIST.anchorageName
                        ? "p-0 mb-5 w100per required-field"
                        : "p-0 mb-5 w100per"
                    }
                  >
                    <Label for="">Anchorage Name</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      value={this.state.ANCHORAGE.name}
                      onChange={e => {
                        let { ANCHORAGE, ERR_LIST } = this.state;
                        ANCHORAGE.name = e.target.value;
                        ERR_LIST.anchorageName = ANCHORAGE.name ? false : true;

                        this.setState({
                          ANCHORAGE,
                          ERR_LIST
                        });
                      }}
                    />
                    {this.state.ERR_LIST.anchorageName ? (
                      <span className="error-msg">This is required.</span>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                  <FormGroup className="p-0 mb-5 w100per">
                    <Label for="">Latitude</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      value={this.state.ANCHORAGE.lat}
                      onChange={e => {
                        let { ANCHORAGE } = this.state;
                        ANCHORAGE.lat = e.target.value;
                        this.setState({
                          ANCHORAGE
                        });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="p-0 mb-5 w100per">
                    <Label for="">Longitude</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      value={this.state.ANCHORAGE.long}
                      onChange={e => {
                        let { ANCHORAGE } = this.state;
                        ANCHORAGE.long = e.target.value;
                        this.setState({
                          ANCHORAGE
                        });
                      }}
                    />
                  </FormGroup>
                </Form>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className="fs13"
                color="danger"
                onClick={this.toggleAddAnchorage}
              >
                CANCEL
              </Button>
              <Button
                className="fs13"
                color="success"
                onClick={() =>
                  this.state.isEdit
                    ? this.handleUpdateAnchorage()
                    : this.handleSave()
                }
              >
                SAVE
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(AnchorageMaintenance);
