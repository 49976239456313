import React, { Component } from "react";
import Swal from "sweetalert2";
import constants from "../../../constants";

export default class AlertModal extends Component {
  componentDidMount = () => {
    this.callModal();
  };

  // componentWillUpdate = () => {
  //   this.callModal();
  // };

  callModal = () => {
    if (this.props.sendTrigger === "success") {
      Swal.fire({
        icon: "success",
        title: this.props.sendMsg,
        showConfirmButton: false,
        timer: 1500
      });
    } else if (this.props.sendTrigger === "loading") {
      Swal.fire({
        background: "transparent",
        html: constants.__vesselLoading,
        showConfirmButton: false,
        backdrop: `
          rgba(0,0,0,0.6)
          left top
          no-repeat
        `
      });
    } else if (this.props.sendTrigger === "error") {
      Swal.fire({
        icon: "error",
        title: this.props.sendMsg,
        showConfirmButton: false,
        timer: 1500
      });
    } else if (this.props.sendTrigger === "confirm") {
      Swal.fire({
        icon: "warning",
        title: this.props.sendMsg,
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true
      }).then(result => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: this.props.successMsg,
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    }
  };

  render() {
    return <React.Fragment></React.Fragment>;
  }
}
