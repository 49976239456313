import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Form, FormGroup, Input, Button, Label } from "reactstrap";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import api from "../../helpers/api.helper";
import Datetime from "react-datetime";
import constants from "../../constants";
import Toggle from "react-toggle";
import PageHeader from "../Tools/PageHeader/PageHeader";
import DocView from "../DocView/DocView";
import AlertModal from "../Tools/AlertModal/AlertModal";
import "./PortTariffs.scss";
import Media from "react-media";
import Icon from "react-fa";
import { RadioGroup, Radio } from "react-radio-group";
import classnames from "classnames";

class PortTariffs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __port_call: {},
      exit_clearance: {},
      viewMode: "editMode",
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call",
        })
        .then((data) => {});
      api.get(this, {
        endpoint: "exit_clearance/" + this.state.__ID,
        state_key: "exit_clearance",
      });
    }
  };

  handleSave = () => {
    this.showAlert("loading", "", true);
    this.setState({
      handleSave: true,
    });
    let { exit_clearance } = this.state;

    api
      .update(this, {
        endpoint: "exit_clearance/port_tariffs/" + this.state.__ID,
        _data: exit_clearance,
      })
      .then((data) => {
        this.setState(
          {
            tglAlert: false,
            handleSave: false,
          },
          () =>
            this.showAlert("success", "Port Tariffs & Services updated!", true)
        );
      });
  };

  getViewMode = (view_mode) => {
    this.setState({
      viewMode: view_mode,
    });
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    console.log("ALERT", bool);
  };

  render() {
    const vd = this.state.__port_call;
    const VNAME = vd.vessel_name;
    const VOYAGE_NO = vd.voyage_number;
    const { viewMode, isAlert, alertMsg, tglAlert } = this.state;

    return (
      <div className="portTarrifs">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}

        <div className="d-flex flex-column">
          <Navbar />

          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav dataFromParent={this.state.__ID} visit={vd} />
              ) : this.state.__ID ? (
                <PageHeader
                  parentCallback={this.getViewMode}
                  sendVName={VNAME}
                  sendVoyageNo={VOYAGE_NO}
                />
              ) : (
                <Col className="pageHeader">
                  <Col className="d-flex flex-row vessel-title">
                    <p className="vessel-name">Vessel Entry Registration</p>
                  </Col>
                </Col>
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      visit={vd}
                    />
                  </Col>
                ) : this.state.__ID ? (
                  <PageHeader
                    parentCallback={this.getViewMode}
                    sendVName={VNAME}
                    sendVoyageNo={VOYAGE_NO}
                    pagename="Port Tarrifs & Services"
                  />
                ) : (
                  <Col className="pageHeader">
                    <Col className="d-flex flex-row vessel-title">
                      <p className="vessel-name">Vessel Entry Registration</p>
                    </Col>
                  </Col>
                )
              }
            </Media>

            <Col md="10" xs="12">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {viewMode === "editMode" ? (
                  <React.Fragment>
                    <Row className="form-container-box bt-green col-md-12">
                      <div className="fcb-title">Port Tariffs & Services</div>

                      <Row md="2" xs="1" className="w100per">
                        <Col className="emReq">
                          <p className="col-md-8 p0">Cargo Documents Cleared</p>

                          <RadioGroup
                            className="d-flex flex-row col-md-4 p0 radio-group"
                            name="is_cargo_documents_cleared"
                            selectedValue={
                              this.state.__port_call.is_cargo_documents_cleared
                            }
                            onChange={(e) => {
                              let { exit_clearance } = this.state;
                              exit_clearance.is_cargo_documents_cleared = e;

                              this.setState({ exit_clearance });
                              console.log("EX", exit_clearance);
                            }}
                          >
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={true} />
                              <span>Yes</span>
                            </label>
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={false} />
                              <span>No</span>
                            </label>
                          </RadioGroup>

                          <div
                            className={classnames({
                              data:
                                !this.state.__port_call
                                  .is_cargo_documents_cleared ||
                                !this.state.__port_call
                                  .is_cargo_documents_cleared,
                              "data opened col-md-12 p0": this.state.__port_call
                                .is_cargo_documents_cleared
                                ? this.state.__port_call
                                    .is_cargo_documents_cleared
                                : this.state.exit_clearance
                                    .is_cargo_documents_cleared,
                            })}
                          >
                            <FormGroup className="col-md-12 p0">
                              <Datetime
                                dateFormat={constants.__dateFormat}
                                timeFormat={false}
                                value={
                                  this.state.__port_call
                                    .cargo_documents_cleared_date
                                    ? new Date(
                                        this.state.__port_call.cargo_documents_cleared_date
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  let { exit_clearance } = this.state;
                                  exit_clearance.cargo_documents_cleared_date = e;
                                  this.setState({ exit_clearance });
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Col>

                        <Col className="emReq">
                          <p className="col-md-8 p0">Port Charges Paid</p>

                          <RadioGroup
                            className="d-flex flex-row col-md-4 p0 radio-group"
                            name="is_port_charges_paid"
                            selectedValue={
                              this.state.__port_call.is_port_charges_paid
                            }
                            onChange={(e) => {
                              let { exit_clearance } = this.state;
                              exit_clearance.is_port_charges_paid = e;

                              this.setState({ exit_clearance });
                              console.log("EX", exit_clearance);
                            }}
                          >
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={true} />
                              <span>Yes</span>
                            </label>
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={false} />
                              <span>No</span>
                            </label>
                          </RadioGroup>

                          <div
                            className={classnames({
                              data:
                                !this.state.__port_call.is_port_charges_paid ||
                                !this.state.__port_call.is_port_charges_paid,
                              "data opened col-md-12 p0": this.state.__port_call
                                .is_port_charges_paid
                                ? this.state.__port_call.is_port_charges_paid
                                : this.state.exit_clearance
                                    .is_port_charges_paid,
                            })}
                          >
                            <FormGroup className="col-md-12 p0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="date"
                                value={this.state.__port_call.port_charges_paid}
                                onChange={(e) => {
                                  let { exit_clearance } = this.state;
                                  exit_clearance.port_charges_paid =
                                    e.target.value;
                                  this.setState({ exit_clearance });
                                }}
                              />
                            </FormGroup>
                            {/* <input
                              type="file"
                              name="file"
                              onChange={e => {
                                let file = e.target.files[0];
                                let { exit_clearance } = this.state;
                                exit_clearance.port_charges_paid_file = file;
                                this.setState({ exit_clearance });
                              }}
                            /> */}
                          </div>
                        </Col>

                        <Col className="emReq">
                          <p>Pilotage Services Paid</p>
                          <RadioGroup
                            className="d-flex flex-row col-md-4 p0 radio-group"
                            name="is_pilotage_service_paid"
                            selectedValue={
                              this.state.__port_call.is_pilotage_service_paid
                            }
                            onChange={(e) => {
                              let { exit_clearance } = this.state;
                              exit_clearance.is_pilotage_service_paid = e;

                              this.setState({ exit_clearance });
                              console.log("EX", exit_clearance);
                            }}
                          >
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={true} />
                              <span>Yes</span>
                            </label>
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={false} />
                              <span>No</span>
                            </label>
                          </RadioGroup>

                          <div
                            className={classnames({
                              data:
                                !this.state.__port_call
                                  .is_pilotage_service_paid ||
                                !this.state.__port_call
                                  .is_pilotage_service_paid,
                              "data opened col-md-12 p0": this.state.__port_call
                                .is_pilotage_service_paid
                                ? this.state.__port_call
                                    .is_pilotage_service_paid
                                : this.state.exit_clearance
                                    .is_pilotage_service_paid,
                            })}
                          >
                            <FormGroup className="col-md-12 p0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="date"
                                value={
                                  this.state.__port_call.pilotage_service_paid
                                }
                                onChange={(e) => {
                                  let { exit_clearance } = this.state;
                                  exit_clearance.pilotage_service_paid =
                                    e.target.value;
                                  this.setState({ exit_clearance });
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Col>

                        <Col className="emReq">
                          <p>Vessel Pre-Departure Inspection</p>
                          <RadioGroup
                            className="d-flex flex-row col-md-4 p0 radio-group"
                            name="is_vessel_predeparture_inspection"
                            selectedValue={
                              this.state.__port_call
                                .is_vessel_predeparture_inspection
                            }
                            onChange={(e) => {
                              let { exit_clearance } = this.state;
                              exit_clearance.is_vessel_predeparture_inspection = e;

                              this.setState({ exit_clearance });
                              console.log("EX", exit_clearance);
                            }}
                          >
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={true} />
                              <span>Yes</span>
                            </label>
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={false} />
                              <span>No</span>
                            </label>
                          </RadioGroup>

                          <div
                            className={classnames({
                              data:
                                !this.state.__port_call
                                  .is_vessel_predeparture_inspection ||
                                !this.state.__port_call
                                  .is_vessel_predeparture_inspection,
                              "data opened col-md-12 p0": this.state.__port_call
                                .is_vessel_predeparture_inspection
                                ? this.state.__port_call
                                    .is_vessel_predeparture_inspection
                                : this.state.exit_clearance
                                    .is_vessel_predeparture_inspection,
                            })}
                          >
                            <FormGroup className="col-md-12 p0">
                              <Datetime
                                dateFormat={constants.__dateFormat}
                                timeFormat={false}
                                value={
                                  this.state.__port_call
                                    .vessel_departure_inspection_date
                                    ? new Date(
                                        this.state.__port_call.vessel_departure_inspection_date
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  let { exit_clearance } = this.state;
                                  exit_clearance.vessel_departure_inspection_date = e;
                                  this.setState({ exit_clearance });
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Col>

                        <Col className="emReq">
                          <p>Penalties Paid</p>
                          <RadioGroup
                            className="d-flex flex-row col-md-4 p0 radio-group"
                            name="is_penalties_paid"
                            selectedValue={
                              this.state.__port_call.is_penalties_paid
                            }
                            onChange={(e) => {
                              let { exit_clearance } = this.state;
                              exit_clearance.is_penalties_paid = e;

                              this.setState({ exit_clearance });
                              console.log("EX", exit_clearance);
                            }}
                          >
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={true} />
                              <span>Yes</span>
                            </label>
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={false} />
                              <span>No</span>
                            </label>
                          </RadioGroup>

                          <div
                            className={classnames({
                              data:
                                !this.state.__port_call.is_penalties_paid ||
                                !this.state.__port_call.is_penalties_paid,
                              "data opened col-md-12 p0": this.state.__port_call
                                .is_penalties_paid
                                ? this.state.__port_call.is_penalties_paid
                                : this.state.exit_clearance.is_penalties_paid,
                            })}
                          >
                            <FormGroup className="col-md-12 p0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="date"
                                value={this.state.__port_call.penalties_paid}
                                onChange={(e) => {
                                  let { exit_clearance } = this.state;
                                  exit_clearance.penalties_paid =
                                    e.target.value;
                                  this.setState({ exit_clearance });
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Col>

                        <Col className="emReq">
                          <p>Tugboat Services Paid</p>

                          <RadioGroup
                            className="d-flex flex-row col-md-4 p0 radio-group"
                            name="is_tugboat_services_paid"
                            selectedValue={
                              this.state.__port_call.is_tugboat_services_paid
                            }
                            onChange={(e) => {
                              let { exit_clearance } = this.state;
                              exit_clearance.is_tugboat_services_paid = e;

                              this.setState({ exit_clearance });
                              console.log("EX", exit_clearance);
                            }}
                          >
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={true} />
                              <span>Yes</span>
                            </label>
                            <label className="col-md-6 p0 p-rel">
                              <Radio value={false} />
                              <span>No</span>
                            </label>
                          </RadioGroup>

                          <div
                            className={classnames({
                              data:
                                !this.state.__port_call
                                  .is_tugboat_services_paid ||
                                !this.state.__port_call
                                  .is_tugboat_services_paid,
                              "data opened col-md-12 p0": this.state.__port_call
                                .is_tugboat_services_paid
                                ? this.state.__port_call
                                    .is_tugboat_services_paid
                                : this.state.exit_clearance
                                    .is_tugboat_services_paid,
                            })}
                          >
                            <FormGroup className="col-md-12 p0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="date"
                                value={
                                  this.state.__port_call.tugboat_services_paid
                                }
                                onChange={(e) => {
                                  let { exit_clearance } = this.state;
                                  exit_clearance.tugboat_services_paid =
                                    e.target.value;
                                  this.setState({ exit_clearance });
                                }}
                              />
                            </FormGroup>
                            {/* <input
                              type="file"
                              name="file"
                              onChange={e => {
                                let file = e.target.files[0];
                                let { exit_clearance } = this.state;
                                exit_clearance.tug_boat_service_paid_file = file;
                                this.setState({ exit_clearance });
                              }}
                            /> */}
                          </div>
                        </Col>
                      </Row>
                    </Row>

                    <div className="footer-actions">
                      <div className="col-md-12 p-0 d-flex flex-row justify-content-end">
                        <Button
                          className={
                            this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"
                          }
                          color="danger"
                          style={{ margin: "0 0.5em" }}
                        >
                          CANCEL
                        </Button>

                        <Button
                          className={
                            this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"
                          }
                          color="success"
                          onClick={() => this.handleSave()}
                        >
                          <Media queries={constants.media_queries}>
                            {(matches) =>
                              matches.xs ? <Icon name="save" /> : "SAVE"
                            }
                          </Media>
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <DocView visit={vd} />
                )}
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(PortTariffs);
