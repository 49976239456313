import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Input,
  Label,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  Alert,
  ModalFooter,
} from "reactstrap";
import { BrowserRouter as Router, withRouter, NavLink } from "react-router-dom";
import "./PortCallToolbar.scss";
import Icon from "react-fa";
import Swal from "sweetalert2";
import constants from "../../constants";
import Media from "react-media";
import Sticky from "react-sticky-el";
import Moment from "react-moment";
import moment from "moment";
import Can from "../Can/Can";
import { ReactComponent as ToolbarBerth } from "../../img/svg/toolbar-berth.svg";
import { ReactComponent as ToolbarArrive } from "../../img/svg/toolbar-arrive.svg";
import { ReactComponent as ToolbarDepart } from "../../img/svg/toolbar-depart.svg";
import statusChange from "../../helpers/statusChange.helper";
import toTimeline from "../../helpers/timeline.helper";
import { ReactComponent as PDFShips } from "../../img/svg/pdf_ships.svg";
import { ReactComponent as PDFEntry } from "../../img/svg/pdf_entry.svg";
import { ReactComponent as PDFCrews } from "../../img/svg/pdf_crews.svg";
import session from "../../session";
import handleApproval from "../../helpers/approvals.helper";
import Datetime from "react-datetime";
import { DropdownList } from "react-widgets";
import api from "../../helpers/api.helper";
import VesselExitPDF from "../PDF/VesselExitPDF/VesselExitPDF";
import VesselEntryClearanceView from "../VesselEntryClearanceView/VesselEntryClearanceView";
import CrewListViewPDF from "../PDF/CrewListViewPDF/CrewListViewPDF";
class PortCallToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToolbarOpen: false,
      openModal: false,
      _markAs: false,
      openActionsModal: false,
      selectedPreview: 0,
      from_arrived: false,
      openDocList: false,
      viewFullSize: false,
      berthPlans: [],
      berthPlan: "",
      selected_tug_boats: [],
      berths: [],
      movementLog: {},
      vesselMovementError: [],
      preDepartureChecklist: [],
      preArrivalChecklist: [],
      tugboats: [],
      status_change: {
        tug_boats: [{}],
      },
    };
  }

  handleSaveNotification = (type) => {
    //submit entry

    let voyageNumber = this.props.portcall.voyage_number
      ? "(" + this.props.portcall.voyage_number + ")"
      : "";
    switch (type) {
      case "submit_entry":
        api
          .save(this, {
            endpoint: "notification/port_admin/" + this.props.portcall._id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.props.portcall.vessel_name,
              voyage_number: this.props.portcall.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              subject:
                this.props.portcall.vessel_name +
                voyageNumber +
                ") Entry Application ",
              action: 0,
              description:
                " has submitted a vessel entry application and requires your approval.",
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has submitted a vessel entry application and requires your approval.",
              role: "processing",
              portcall_id: this.props.portcall._id,
            },
          })
          .then(() => {});

        break;

      case "endorse":
        api
          .save(this, {
            endpoint: "notification/company/" + this.props.portcall.company_id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.props.portcall.vessel_name,
              voyage_number: this.props.portcall.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              subject:
                "Endorsement of " +
                this.props.portcall.vessel_name +
                voyageNumber +
                ") Entry Application ",
              action: 1,
              description:
                " has  endorsed your vessel entry application for " +
                this.props.portcall.vessel_name +
                " for final approval.",
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has  endorsed your vessel entry application for " +
                this.props.portcall.vessel_name +
                " for final approval.",
              portcall_id: this.props.portcall._id,
            },
          })
          .then(() => {});

        api.save(this, {
          endpoint: "notification/port_admin/" + this.props.portcall._id,
          preserve_state: true,
          _data: {
            filler: true,
            vessel_name: this.props.portcall.vessel_name,
            voyage_number: this.props.portcall.voyage_number,
            first_name: session.get().first_name,
            last_name: session.get().last_name,
            action: 0,
            subject:
              "Endorsement of " +
              this.props.portcall.vessel_name +
              " (" +
              this.props.portcall.voyage_number +
              ") Entry Application ",
            description:
              " is endorsing a vessel entry application to you and requires your approval.",
            message:
              session.get().first_name +
              " is endorsing a vessel entry application to you and requires your approval.",
            role: "port_admin",
            portcall_id: this.props.portcall._id,
          },
        });

        break;
      case "approve_entry":
        api
          .save(this, {
            endpoint: "notification/company/" + this.props.portcall.company_id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.props.portcall.vessel_name,
              voyage_number: this.props.portcall.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              subject:
                this.props.portcall.vessel_name +
                voyageNumber +
                ") Entry Application Approved!",
              action: 1,
              description:
                " has approved your vessel entry application for " +
                this.props.portcall.vessel_name,
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has approved your vessel entry application for " +
                this.props.portcall.vessel_name,
              portcall_id: this.props.portcall._id,
            },
          })
          .then(() => {});

        api.save(this, {
          endpoint: "notification/port_admin/" + this.props.portcall._id,
          preserve_state: true,
          _data: {
            filler: true,
            vessel_name: this.props.portcall.vessel_name,
            voyage_number: this.props.portcall.voyage_number,
            first_name: session.get().first_name,
            last_name: session.get().last_name,
            action: 0,
            subject:
              this.props.portcall.vessel_name +
              " (" +
              this.props.portcall.voyage_number +
              ") Entry Application Approved",
            description:
              " has approved the vessel entry application for " +
              this.props.portcall.vessel_name +
              ". You can now include this vessel in your berth plan.",
            role: "harbor_master",
            message:
              session.get().first_name +
              " has approved the vessel entry application for " +
              this.props.portcall.vessel_name +
              ". You can now include this vessel in your berth plan.",
            portcall_id: this.props.portcall._id,
          },
        });

        break;

      case "return_entry":
        if (this.props.portcall.company_id) {
          api
            .save(this, {
              endpoint:
                "notification/company/" + this.props.portcall.company_id,
              preserve_state: true,
              _data: {
                filler: true,
                vessel_name: this.props.portcall.vessel_name,
                voyage_number: this.props.portcall.voyage_number,
                first_name: session.get().first_name,
                last_name: session.get().last_name,
                action: 2,
                subject:
                  this.props.portcall.vessel_name +
                  " (" +
                  this.props.portcall.voyage_number +
                  ") Entry Application Rejected",
                description:
                  " has rejected your vessel entry application for " +
                  this.props.portcall.vessel_name,
                message:
                  session.get().first_name +
                  " has rejected your vessel entry application for " +
                  this.props.portcall.vessel_name,
                portcall_id: this.props.portcall._id,
              },
            })
            .then(() => {});
        }

        break;

      case "submit_exit":
        api
          .save(this, {
            endpoint: "notification/port_admin/" + this.props.portcall._id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.props.portcall.vessel_name,
              voyage_number: this.props.portcall.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              action: 0,
              role: "billing",
              subject:
                this.props.portcall.vessel_name +
                voyageNumber +
                ") Exit Application",
              description:
                " has submitted a vessel exit application. Kindly fill out the billing clearance of the port call.",
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has submitted a vessel exit application. Kindly fill out the billing clearance of the port call.",
              portcall_id: this.props.portcall._id,
            },
          })
          .then(() => {});

        break;

      case "endorse_exit":
        api
          .save(this, {
            endpoint: "notification/company/" + this.props.portcall.company_id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.props.portcall.vessel_name,
              voyage_number: this.props.portcall.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              action: 1,
              subject:
                "Endorsement of " +
                this.props.portcall.vessel_name +
                voyageNumber +
                ") Exit Application",
              description:
                " has endorsed your vessel exit application for " +
                this.props.portcall.vessel_name +
                " for final approval",
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has endorsed your vessel exit application for " +
                this.props.portcall.vessel_name +
                " for final approval",
              portcall_id: this.props.portcall._id,
            },
          })
          .then(() => {});
        api
          .save(this, {
            endpoint: "notification/port_admin/" + this.props.portcall._id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.props.portcall.vessel_name,
              voyage_number: this.props.portcall.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              action: 0,
              role: "port_admin",
              subject:
                "Endorsement of " +
                this.props.portcall.vessel_name +
                voyageNumber +
                ") Exit Application",
              description:
                " is endorsing a vessel exit application for " +
                this.props.portcall.vessel_name +
                " to you and requires your approval.",
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " is endorsing a vessel exit application for " +
                this.props.portcall.vessel_name +
                " to you and requires your approval.",
              portcall_id: this.props.portcall._id,
            },
          })
          .then(() => {});

        break;

      case "approve_exit":
        api
          .save(this, {
            endpoint: "notification/company/" + this.props.portcall.company_id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.props.portcall.vessel_name,
              voyage_number: this.props.portcall.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              action: 1,
              subject:
                this.props.portcall.vessel_name +
                voyageNumber +
                ") Exit Application Rejected",
              description:
                " has approved your vessel exit application for " +
                this.props.portcall.vessel_name,
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has approved your vessel exit application for " +
                this.props.portcall.vessel_name,
              portcall_id: this.props.portcall._id,
            },
          })
          .then(() => {});

        break;

      case "return_exit":
        api
          .save(this, {
            endpoint: "notification/company/" + this.props.portcall.company_id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.props.portcall.vessel_name,
              voyage_number: this.props.portcall.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              action: 2,
              description:
                " has rejected your vessel exit application for " +
                this.props.portcall.vessel_name,
              portcall_id: this.props.portcall._id,
            },
          })
          .then(() => {});

        break;

      default:
        console.log("none");
        break;
    }
  };

  toggleConfirmDeparture = () => {
    Swal.fire({
      icon: "question",
      title: "Do you want to create Vessel Exit Application?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Confirmed!",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          enable_depart_link: true,
        });

        this.props.history.push(
          "/port_call/departure_details/" + this.props.portcall._id
        );
      }
    });
  };

  handleSubmitDepartureDetails = () => {
    Swal.fire({
      icon: "warning",
      title: "Submit exit clearance application to billing?",
      input: "textarea",
      inputPlaceholder: "Enter your remarks here",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then(async (remarks) => {
      api
        .update(this, {
          endpoint: "portcall/exit_clearance/" + this.props.portcall._id,
          state_key: "portcall",
          preserve_state: true,
          _data: { is_vessel_exit_submitted: true, submit: true, remarks },
        })
        .then((response) => {
          const { portcall, portcallStateKey } = this.props;
          portcall.is_vessel_exit_submitted = true;
          const obj = {};
          obj[portcallStateKey] = portcall;
          this.props.digestHandler({
            ...obj,
          });
          this.handleSaveNotification("submit_exit");
          this.handleSuccessAlert(
            "Exit clearance application submitted to billing!"
          );
        });
    });
  };

  handleValidateMovementCompletion = (start_date, end_date) => {
    return moment(start_date) < moment(end_date);
  };

  handleGetTugBoats = () => {
    const { tugboat_company_id } = this.props.portcall;
    const { tugboats } = this.state;

    if (tugboats.length === 0) {
      api
        .get(this, {
          endpoint: "tugboat_company/" + tugboat_company_id,
          state_key: "tug_boat_company",
        })
        .then((tb) => {
          if (tb.tugboats) {
            this.setState({ tugboats: tb.tugboats });
          }
        });
    }
  };

  handleExtractApprovalLogs = () => {
    const __data = this.props.portcall;
    let tmpEntryApprovals = {},
      tmpExitApprovals = {};
    const { entry_approvals, exit_approvals } = __data;
    if (entry_approvals && entry_approvals.logs.length > 0) {
      entry_approvals.logs.forEach((val) => {
        tmpEntryApprovals[val.level] = val;
      });
    }

    if (exit_approvals && exit_approvals.logs.length > 0) {
      exit_approvals.logs.forEach((val) => {
        tmpExitApprovals[val.level] = val;
      });
    }

    this.setState({
      entry_approvals: tmpEntryApprovals,
      exit_approvals: tmpExitApprovals,
    });
  };

  componentDidMount = () => {
    this.handleGetBerthPlans();
    this.handleDetermineIncompleteVesselMovement();
    this.handleAssembleChecklist();
    this.handleExtractApprovalLogs();
  };

  handleGetBerth = () => {
    let { berths } = this.state;
    if (berths.length === 0) {
      api.get(this, {
        endpoint: "berth",
        state_key: "berths",
        request_params: {
          sort: { name: 1 },
        }, // GET ONLY PENDING
      });
    }
  };
  handleGetBerthPlans = () => {
    let { berthPlans } = this.state;
    if (berthPlans.length === 0) {
      api
        .get(this, {
          endpoint: "berth_plan",
          state_key: "berth_plans",
          request_params: {
            search: { port_call_id: this.props.portcall._id },
          }, // GET ONLY PENDING
        })
        .then((bp) => {
          let berthPlans = Object.assign([], bp);
          let berthPlanDd = [];
          berthPlans.forEach((berthPlan, i) => {
            if (!berthPlan.status_id) {
              berthPlan.drop_down_display =
                berthPlan.berth +
                " ( " +
                moment(berthPlan.eta).format(constants.__dateTimeFormat) +
                " - " +
                moment(berthPlan.etd).format(constants.__dateTimeFormat) +
                ") - " +
                berthPlan.service;
              berthPlanDd.push(berthPlan);
            }
          });

          this.setState({ berthPlans: berthPlanDd });
          this.props.digestHandler({ berth_plans: bp });
        });
    }
  };

  showAlert = (mode, msg, bool, success) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
      alertSuccess: success,
    });
  };

  handleSuccessAlert = (success) => {
    this.setState({
      tglAlert: false,
    });

    Swal.fire({
      icon: "success",
      title: success,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  changePcStatus = (stat) => {
    this.setState({
      pc_status: stat,
    });
  };

  handleAnchorageUpdate = (oData) => {
    return new Promise((resolve, reject) => {
      api
        .update(this, {
          endpoint: "portcall/anchorage/" + this.props.portcall._id,
          _data: oData,
          preserve_state: true,
          state_key: "anchorage_logs",
          return_true_value: true,
        })
        .then((log) => {
          return resolve(log);
        });
    });
  };

  handleBerthPlanUpdate = (oData) => {
    return new Promise((resolve, reject) => {
      api
        .update(this, {
          endpoint: "portcall/berth/" + this.props.portcall._id,
          _data: oData,
          state_key: "berth_plans",
          preserve_state: true,
          return_true_value: true,
        })
        .then((log) => {
          return resolve(log);
        });
    });
  };

  handleRemoveTugBoat(index) {
    let { status_change, selected_tug_boats } = this.state;
    status_change.tug_boats.splice(index, 1);
    selected_tug_boats.splice(index, 1);

    this.setState({ status_change, selected_tug_boats });
  }

  handleAddTugBoat = () => {
    let { status_change } = this.state;
    status_change.tug_boats = [...status_change.tug_boats, { name: "" }];
    this.setState({ status_change });
  };

  handleConfirm = ({ msg, success, action, data, doApproval }) => {
    Swal.fire({
      icon: "warning",
      title: msg,
      input: "textarea",
      inputPlaceholder: "Enter your remarks here",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then(async (remarks) => {
      if (remarks.dismiss) {
        return;
      }
      let postData = data;
      if (remarks.value) {
        postData.remarks = remarks.value;
      }

      let { portcall, portcallStateKey } = this.props;
      let serverResponse = null;
      if (doApproval) {
        serverResponse = await handleApproval({
          portcall,
          approvaldData: data,
        });
      } else {
        serverResponse = await statusChange({
          portcall,
          statusData: data,
        });
      }

      portcall = serverResponse;
      if (serverResponse.data) {
        portcall = serverResponse.data;
      }
      this.handleSaveNotification(action);
      let obj = {};
      obj[portcallStateKey] = portcall;
      this.props.digestHandler({
        ...obj,
        portcall_timeline: toTimeline(portcall.status_change_log),
      });
      this.handleSuccessAlert(success);
    });
  };

  handleDeparture = (data) => {
    if (data) {
      let { portcall, portcallStateKey } = this.props;
      statusChange({
        portcall,
        statusData: data,
      }).then((response) => {
        let obj = {};
        portcall = response;
        obj[portcallStateKey] = portcall;
        this.props.digestHandler({
          ...obj,
          portcall_timeline: toTimeline(portcall.status_change_log),
        });
        this.handleSuccessAlert("Vessel Departed!");
        this.toggleActionsModal();
        this.setState({ no_ws: true });
      });
    }
  };

  handleOpen = () => {
    this.setState((prevState) => ({
      isToolbarOpen: !prevState.isToolbarOpen,
    }));
  };

  togglePreview = (ind) => {
    this.setState({
      selectedPreview: ind,
    });
  };

  togglePreviewList = () => {
    this.setState((prevState) => ({
      openDocList: !prevState.openDocList,
    }));
  };

  toggleFullSize = () => {
    this.setState((prevState) => ({
      viewFullSize: !prevState.viewFullSize,
    }));
  };

  toggleModal = () => {
    this.handleOpen();
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));
    this.setState({
      openDocList: false,
      viewFullSize: false,
    });
  };

  toggleExitClearanceModal = () => {
    this.handleOpen();
    this.setState((prevState) => ({
      isVesselExitOpen: !prevState.isVesselExitOpen,
    }));
  };

  toggleActionsModal = () => {
    this.handleOpen();
    this.setState((prevState) => ({
      openActionsModal: !prevState.openActionsModal,
      vesselMovementError: [],
      selected_tug_boats: [],
      status_change: {
        tug_boats: [{}],
      },
    }));
  };

  toggleMarkAs = (stat) => {
    this.handleOpen();
    this.setState((prevState) => ({
      _markAs: !prevState._markAs,
      status_change: {
        tug_boats: [{}],
      },
      selected_tug_boats: [],
      markStat: stat,
    }));
  };

  handleDetermineIncompleteVesselMovement = () => {
    const { portcall } = this.props;
    let vesselMovementStatus = {
      movementLog: {},
    };
    if (!portcall) {
      vesselMovementStatus = { allCompleted: false };
    }

    const { vessel_movement } = portcall;
    if (vessel_movement && vessel_movement.length === 0) {
      // there's no existing movement in vessel just yet. so we can return all completed true;
      vesselMovementStatus = { allCompleted: true };
    }

    const lastVesselMovement = vessel_movement[vessel_movement.length - 1];
    if (lastVesselMovement) {
      const type = lastVesselMovement.anchorage_id ? "anchorage" : "berth";
      if (!lastVesselMovement.date_end) {
        vesselMovementStatus = {
          allCompleted: false,
          type,
          movementLog: lastVesselMovement,
        };
      } else {
        vesselMovementStatus = {
          allCompleted: true,
          type,
          movementLog: lastVesselMovement,
        };
      }
    }

    this.setState({
      isAllMovementCompleted: vesselMovementStatus.allCompleted,
      incompleteVesselMovementType: vesselMovementStatus.type,
      movementLog: vesselMovementStatus.movementLog,
    });
  };

  handleValidateVesselMovement = () => {
    const { status_change, pc_status } = this.state;
    const { date_start, tug_boats } = status_change;
    let isValid = true;
    const errorMessage = [];

    if (typeof date_start === "undefined" || !moment(date_start).isValid()) {
      isValid = false;
      errorMessage.push("Please enter a valid start date.");
    }

    if (!tug_boats || tug_boats.length === 0 || tug_boats[0].length === 0) {
      isValid = false;
      errorMessage.push("Please select at least (1) one tug boat.");
    }

    if (pc_status === "Berth" && !status_change.berth_id) {
      isValid = false;
      errorMessage.push("Please select a berth.");
    }

    if (pc_status === "Anchorage" && !status_change.anchorage_id) {
      isValid = false;
      errorMessage.push("Please select an anchorage");
    }

    this.setState({ vesselMovementError: errorMessage });
    return isValid;
  };

  handleAssembleChecklist = () => {
    const { checklist } = this.props.portcall;

    const sample_preabclist = [
      {
        desc: "Vessel Entry Registration",
        actor: checklist.vessel_entry_registration
          ? checklist.vessel_entry_registration.by
          : "",
        timestamp: checklist.vessel_entry_registration.date
          ? checklist.vessel_entry_registration.date
          : "",
        active: checklist.vessel_entry_registration.is_done
          ? checklist.vessel_entry_registration.is_done
          : false,
      },
      {
        desc: "General Manifest Registration",
        actor: checklist.general_manifest_registration.by
          ? checklist.general_manifest_registration.by
          : "",
        timestamp: checklist.general_manifest_registration.date
          ? checklist.general_manifest_registration.date
          : "",
        active: checklist.general_manifest_registration.is_done
          ? checklist.general_manifest_registration.is_done
          : false,
      },
      {
        desc: "Berth Assignment",
        actor: checklist.berth_assignment.by
          ? checklist.berth_assignment.by
          : "",
        timestamp: checklist.berth_assignment.date
          ? checklist.berth_assignment.date
          : "",
        active: checklist.berth_assignment.is_done
          ? checklist.berth_assignment.is_done
          : false,
      },
      {
        desc: "Resource Allocation",
        actor: checklist.resource_allocation.by
          ? checklist.resource_allocation.by
          : "",
        timestamp: checklist.resource_allocation.date
          ? checklist.resource_allocation.date
          : "",
        active: checklist.resource_allocation.is_done
          ? checklist.resource_allocation.is_done
          : false,
      },
    ];

    const sample_predclist = [
      {
        desc: "Vessel Exit Registration",
        actor: checklist.vessel_exit_registration.by
          ? checklist.vessel_exit_registration.by
          : "",
        timestamp: checklist.vessel_exit_registration.date
          ? checklist.vessel_exit_registration.date
          : "",
        active: checklist.vessel_exit_registration.is_done,
      },
      {
        desc: "Cargo Documents Cleared",
        actor: checklist.cargo_documents_cleared.by
          ? checklist.cargo_documents_cleared.by
          : "",
        timestamp: checklist.cargo_documents_cleared.date
          ? checklist.cargo_documents_cleared.date
          : "",
        active: checklist.cargo_documents_cleared.is_done,
      },
      {
        desc: "Vessel Pre-Departure Inspection",
        actor: checklist.vessel_pre_departure_inspection.by
          ? checklist.vessel_pre_departure_inspection.by
          : "",
        timestamp: checklist.vessel_pre_departure_inspection.date
          ? checklist.vessel_pre_departure_inspection.date
          : "",
        active: checklist.vessel_pre_departure_inspection.is_done,
      },
    ];

    this.setState({
      preArrivalChecklist: sample_preabclist,
      preDepartureChecklist: sample_predclist,
    });
  };

  render() {
    let tug_boats = [];
    const {
      isToolbarOpen,
      openModal,
      openActionsModal,
      selectedPreview,
      openDocList,
      viewFullSize,
    } = this.state;

    let tmp = this.state.tugboats;
    for (let x in tmp) {
      if (this.state.selected_tug_boats.indexOf(tmp[x]._id) === -1) {
        tug_boats = [...tug_boats, tmp[x]];
      }
    }

    const Checklist = (props) => {
      const rowLen = props.data.length;
      const dateFormat = constants.__dateFormat + " " + constants.__timeFormat;

      return (
        <React.Fragment>
          <div className="checklist-label">{props.label}</div>
          <ul className={props.sm ? "checklist-sm" : undefined}>
            {props.data.map((b, $index) => {
              return (
                <React.Fragment key={$index}>
                  <li className="checklist-item d-flex flex-row">
                    <div className="step d-flex">
                      <span
                        className={
                          b.active ? " d-flex node active" : " d-flex node"
                        }
                      >
                        <Icon name="check" />
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <div className="item-desc">
                        {b.desc} {b.actor ? "by " + b.actor : ""}
                      </div>
                      <div className="timestamp">
                        {b.timestamp ? (
                          <React.Fragment>
                            Created at&nbsp;
                            <Moment format={dateFormat}>
                              {b.timestamp || "-"}
                            </Moment>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </li>
                  {rowLen === $index + 1 ? "" : <div className="divider"></div>}
                </React.Fragment>
              );
            })}
          </ul>
        </React.Fragment>
      );
    };

    const ToolbarCont = (props) => {
      return (
        <div
          className={
            isToolbarOpen
              ? "PC-SideToolbar PC-SideToolbar-opened"
              : "PC-SideToolbar"
          }
        >
          <div
            className="PC-SideToolbar-overlay"
            onClick={() => {
              this.handleOpen();
            }}
          ></div>
          <div className="PC-SideToolbar-button">
            <div
              onClick={() => {
                this.handleOpen();
              }}
            >
              {true ? <span></span> : ""}
              <Icon name={isToolbarOpen ? "times" : "ellipsis-h"} />
            </div>
          </div>
          <div className="PC-SideToolbar-content">
            <Row xs="1">
              <Col className="toolbar-buttons">
                {!props.portcall.is_vessel_entry_approved ? (
                  <Button
                    color="aesi-active"
                    block
                    onClick={() => {
                      this.toggleModal();
                    }}
                  >
                    <Icon name="eye" />
                    Preview Vessel Entry Clearance
                  </Button>
                ) : (
                  ""
                )}

                <Can
                  role={session.get().role}
                  perform="berth-management:create"
                  yes={() =>
                    !this.state.isAllMovementCompleted ? (
                      this.state.incompleteVesselMovementType ===
                      "anchorage" ? (
                        <>
                          <Button
                            color="aesi-primary"
                            block
                            onClick={() => {
                              this.handleGetTugBoats();
                              this.toggleMarkAs();
                            }}
                          >
                            <Icon name="check" />
                            Complete Anchorage
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            color="aesi-primary"
                            block
                            onClick={() => {
                              this.handleGetTugBoats();
                              this.toggleMarkAs();
                            }}
                          >
                            <Icon name="check" />
                            Complete Berth
                          </Button>
                        </>
                      )
                    ) : (
                      ""
                    )
                  }
                />

                <Can
                  role={session.get().role}
                  perform="resource-management:create"
                  yes={() =>
                    !this.props.portcall.has_resource_management ? (
                      <>
                        <Button
                          color="aesi-primary"
                          block
                          onClick={() => {
                            window.open(
                              window.location.protocol +
                                "//" +
                                window.location.host +
                                "/port_call/resource_plan/" +
                                props.portcall._id
                            );
                          }}
                        >
                          <Icon name="check" />
                          Create Resource Allocation Plan
                        </Button>
                      </>
                    ) : (
                      ""
                    )
                  }
                />

                <Can
                  role={session.get().role}
                  perform="resource-management:approve"
                  yes={() =>
                    !this.props.portcall.is_resource_allocation_approved &&
                    this.props.portcall.status_id ===
                      constants.RESOURCE_PLAN_SUBMITTED_STATUS ? (
                      <>
                        <Button
                          color="aesi-primary"
                          block
                          onClick={() => {
                            window.open(
                              window.location.protocol +
                                "//" +
                                window.location.host +
                                "/port_call/resource_plan/" +
                                props.portcall._id
                            );
                          }}
                        >
                          <Icon name="check" />
                          Review Allocation Plan
                        </Button>
                      </>
                    ) : (
                      ""
                    )
                  }
                />

                <Can
                  role={session.get().role}
                  perform="port-call-entry:create"
                  yes={() =>
                    props.portcall.status_id === 0 ||
                    props.portcall.status_id === constants.REJECTED_STATUS ? (
                      <>
                        <Button
                          color="aesi-primary"
                          block
                          onClick={() => {
                            this.handleConfirm({
                              msg: "Are you sure you want to submit this?",
                              success: "Entry application submitted!",
                              action: "submit_entry",
                              data: {
                                is_vessel_entry_submitted: true,
                                status: constants.SUBMITTED_STATUS,
                              },
                            });
                          }}
                        >
                          <Icon name="paper-plane" />
                          Submit
                        </Button>
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <Can
                  role={session.get().role}
                  perform="port-call-vessel-doc:process"
                  yes={() =>
                    props.portcall.status_id === constants.SUBMITTED_STATUS ? (
                      <Row>
                        <Button
                          color="aesi-danger"
                          block
                          onClick={() => {
                            this.handleConfirm({
                              msg:
                                "Are you sure you want to reject this entry application?",
                              success: "Entry application rejected!",
                              doApproval: true,
                              data: {
                                data: {
                                  status_id: constants.REJECTED_STATUS,
                                  is_vessel_entry_approved: false,
                                  is_vessel_entry_processed: false,
                                },
                                action: "reject",
                                timeline_action:
                                  "Vessel entry application rejected",
                                module: "entry_application",
                              },
                            });
                          }}
                        >
                          <Icon name="times" />
                          Reject
                        </Button>
                        <Button
                          color="aesi-primary"
                          block
                          onClick={() => {
                            this.handleConfirm({
                              msg:
                                "Endorse Vessel Entry Application for Approval?",
                              success: "Entry application endorsed!",
                              action: "endorse",
                              data: {
                                data: {
                                  status_id: constants.PROCESSED_STATUS,
                                  is_vessel_entry_approved: false,
                                  is_vessel_entry_processed: true,
                                },
                                action: "approve",
                                timeline_action:
                                  "Vessel entry application endorsed",
                                module: "entry_approvals",
                              },
                              doApproval: true,
                            });
                          }}
                        >
                          <Icon name="paper-plane" />
                          Submit
                        </Button>
                      </Row>
                    ) : (
                      ""
                    )
                  }
                />
                <Can
                  role={session.get().role}
                  perform="port-call-vessel-doc:manage"
                  yes={() =>
                    props.portcall.status_id === constants.PROCESSED_STATUS ? (
                      <Row>
                        <Button
                          color="aesi-danger"
                          block
                          onClick={() => {
                            this.handleConfirm({
                              msg:
                                "Are you sure you want to reject this entry application?",
                              success: "Entry application rejected!",
                              doApproval: true,
                              data: {
                                data: {
                                  status_id: constants.REJECTED_STATUS,
                                  is_vessel_entry_approved: false,
                                  is_vessel_entry_processed: false,
                                },
                                action: "reject",
                                timeline_action:
                                  "Vessel entry application rejected",
                                module: "entry_application",
                              },
                            });
                          }}
                        >
                          <Icon name="times" />
                          Reject
                        </Button>
                        <Button
                          color="aesi-primary"
                          block
                          onClick={() => {
                            this.handleConfirm({
                              msg: "Approve vessel entry application?",
                              success: "Entry application aproved!",
                              action: "approve_entry",
                              data: {
                                data: {
                                  status_id: constants.ANNOUNCED_STATUS,
                                  is_vessel_entry_approved: true,
                                },
                                action: "approve",
                                timeline_action:
                                  "Vessel entry application approved",
                                module: "entry_approvals",
                              },
                              doApproval: true,
                            });
                          }}
                        >
                          <Icon name="check" />
                          Approve
                        </Button>
                      </Row>
                    ) : (
                      ""
                    )
                  }
                />
              </Col>
              {this.props.portcall.has_departure_details &&
              !this.props.portcall.is_vessel_exit_approved ? (
                <Col className="toolbar-buttons">
                  <Button
                    color="aesi-active"
                    block
                    onClick={() => {
                      this.toggleExitClearanceModal();
                    }}
                  >
                    <Icon name="eye" />
                    Preview Vessel Exit Clearance
                  </Button>
                  <Can
                    role={session.get().role}
                    perform="departure:create"
                    yes={() =>
                      !this.props.portcall.is_vessel_exit_submitted ? (
                        <Button
                          color="aesi-primary"
                          block
                          onClick={() => {
                            this.handleSubmitDepartureDetails();
                          }}
                        >
                          <Icon name="paper-plane" />
                          Submit
                        </Button>
                      ) : (
                        ""
                      )
                    }
                  />

                  <Can
                    role={session.get().role}
                    perform="departure-approval:endorse"
                    yes={() => (
                      <>
                        {!this.props.portcall.is_port_tarrifs_filled_out ? (
                          <Button color="aesi-primary" block onClick={() => {}}>
                            Fill out Port Tarrifs
                          </Button>
                        ) : (
                          ""
                        )}

                        {this.props.portcall.is_vessel_exit_submitted &&
                        this.props.portcall.is_port_tarrifs_filled_out &&
                        !this.props.portcall
                          .is_vessel_exit_application_endorsed ? (
                          <Row>
                            <Button
                              color="aesi-danger"
                              block
                              onClick={() => {
                                this.handleConfirm({
                                  msg:
                                    "Are you sure you want to reject this exit application?",
                                  success: "Entry application rejected!",
                                  doApproval: true,
                                  action: "return_exit",
                                  data: {
                                    data: {
                                      status_id: constants.REJECTED_STATUS,
                                    },
                                    action: "reject",
                                    timeline_action:
                                      "Vessel exit application rejected",
                                    module: "exit_application",
                                  },
                                });
                              }}
                            >
                              <Icon name="times" />
                              Reject
                            </Button>
                            <Button
                              color="aesi-primary"
                              block
                              onClick={() => {
                                this.handleConfirm({
                                  msg:
                                    "Endorse Vessel Exit Application for Approval?",
                                  success: "Vessel exit application endorsed!",
                                  action: "endorse_exit",
                                  data: {
                                    data: {
                                      status_id:
                                        constants.EXIT_APPLICATION_ENDORSED_STATUS,
                                      is_vessel_exit_application_endorsed: true,
                                    },
                                    action: "approve",
                                    timeline_action:
                                      "Vessel exit application endorsed",
                                    module: "exit_approvals",
                                  },
                                  doApproval: true,
                                });
                              }}
                            >
                              <Icon name="check" />
                              Endorse
                            </Button>
                          </Row>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  />
                  <Can
                    role={session.get().role}
                    perform="departure-approval:approve"
                    yes={() =>
                      !this.props.portcall.is_cleared_for_exit &&
                      this.props.portcall
                        .is_vessel_exit_application_endorsed ? (
                        <Row>
                          <Button
                            color="aesi-danger"
                            block
                            onClick={() => {
                              this.handleConfirm({
                                msg:
                                  "Are you sure you want to reject this exit application?",
                                success: "Entry application rejected!",
                                doApproval: true,
                                data: {
                                  data: {
                                    status_id: constants.REJECTED_STATUS,
                                  },
                                  action: "reject",
                                  timeline_action:
                                    "Vessel exit application rejected",
                                  module: "exit_application",
                                },
                              });
                            }}
                          >
                            <Icon name="times" />
                            Reject
                          </Button>
                          <Button
                            color="aesi-primary"
                            block
                            onClick={() => {
                              this.handleConfirm({
                                msg: "Approve Vessel Exit Application?",
                                success: "Vessel exit application approved!",
                                data: {
                                  data: {
                                    status_id:
                                      constants.CLEARED_FOR_EXIT_STATUS,
                                    is_cleared_for_exit: true,
                                    is_vessel_exit_approved: true,
                                  },
                                  action: "approve",
                                  timeline_action:
                                    "Vessel exit application approved",
                                  module: "exit_approvals",
                                },
                                doApproval: true,
                              });
                            }}
                          >
                            <Icon name="check" />
                            Approve
                          </Button>
                        </Row>
                      ) : (
                        ""
                      )
                    }
                  />
                </Col>
              ) : session.get().company &&
                this.props.portcall.company_id === session.get().company._id ? (
                <Can
                  role={session.get().role}
                  perform="departure:create"
                  yes={() => (
                    <>
                      <Col className="toolbar-buttons">
                        <Button
                          color="primary"
                          block
                          onClick={this.toggleConfirmDeparture}
                        >
                          Create Departure Application
                        </Button>
                      </Col>
                    </>
                  )}
                />
              ) : (
                ""
              )}

              <Can
                role={session.get().role}
                perform="berth-management:create"
                yes={() => (
                  <>
                    {!this.props.portcall.is_berth_assigned ? (
                      <Col className="toolbar-buttons">
                        <Button
                          color="primary"
                          block
                          onClick={() => {
                            window.open(
                              window.location.protocol +
                                "//" +
                                window.location.host +
                                "/port_call/berth_plans/" +
                                props.portcall._id
                            );
                          }}
                        >
                          Plan Berth
                        </Button>
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col className="toolbar-buttons actions">
                      <Button
                        disabled={this.props.portcall.is_arrived}
                        color="aesi-primary"
                        onClick={() => {
                          this.handleGetTugBoats();
                          this.setState({
                            from_arrived: true,
                            pc_status: "Anchorage",
                          });
                          this.toggleActionsModal();
                        }}
                      >
                        <ToolbarArrive />
                        <span>Arrived</span>
                      </Button>

                      <Button
                        color="aesi-primary"
                        className="btn-smallerIcon"
                        disabled={
                          !this.state.isAllMovementCompleted ||
                          this.props.portcall.is_departed
                        }
                        onClick={() => {
                          this.handleGetTugBoats();
                          this.setState({
                            from_arrived: false,
                            pc_status: "Anchorage",
                          });
                          this.toggleActionsModal();
                        }}
                      >
                        <ToolbarBerth />
                        <span>Berth</span>
                      </Button>

                      <Button
                        color="aesi-primary"
                        onClick={() => {
                          this.setState({
                            from_arrived: false,
                            pc_status: "Departure",
                          });
                          this.toggleActionsModal();
                        }}
                        disabled={
                          !this.props.portcall.is_cleared_for_exit ||
                          this.props.portcall.is_departed
                        }
                      >
                        <ToolbarDepart />
                        <span>Departed</span>
                      </Button>
                    </Col>
                  </>
                )}
              />

              {/* session / user role below */}
              {true ? (
                <Col className="toolbar-notif">
                  {/* <div className="aesi-alert">
                    <p className="toolbar-title">Notifications</p>
                    <div className="alert-icon">
                      <Icon name="info" />
                    </div>
                    <div className="alert-content">
                      <Row className="content" xs="1">
                        {true && (
                          <Col>
                            <p>
                              You have <span>{"10"} new messages</span> in
                              inbox.
                            </p>
                          </Col>
                        )}
                        {true && (
                          <Col>
                            <p>
                              Ship agent has{" "}
                              <span>{"10"} Ammendment Requests</span> that
                              require your approval.
                            </p>
                          </Col>
                        )}
                        {true && (
                          <Col>
                            <p>
                              Ship Agent has registered for{" "}
                              <span>Vessel Exit Clearance</span>
                            </p>
                          </Col>
                        )}
                        {true && (
                          <Col>
                            <p>
                              Ship Agent is requesting for{" "}
                              <span>Vessel Entry Clearance</span>.
                            </p>
                          </Col>
                        )}
                        {true && (
                          <Col>
                            <p>
                              Ship Agent is requesting for{" "}
                              <span>Berth Assignment</span>.
                            </p>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div> */}
                </Col>
              ) : (
                ""
              )}
              <Col className="visit-checklist">
                <Checklist
                  sm
                  label="Pre-Arrival/Berth"
                  data={this.state.preArrivalChecklist}
                />
                <Checklist
                  sm
                  label="Pre-Departure"
                  data={this.state.preDepartureChecklist}
                />
              </Col>
            </Row>
          </div>
        </div>
      );
    };

    return (
      <>
        <Modal
          keyboard={false}
          backdrop="static"
          isOpen={openModal}
          toggle={this.toggleModal}
          className="preview-set"
          zIndex="2001"
          centered
        >
          <ModalHeader toggle={this.toggleModal}>
            {selectedPreview === 0 && "Vessel Entry Clearance"}
            {selectedPreview === 1 && "Crew List"}
            {selectedPreview === 2 && "Manifest"} Preview
          </ModalHeader>
          <ModalBody className={openDocList ? "doc-list-opened" : undefined}>
            <Row>
              <Col
                xs={{ size: 12, order: 2 }}
                md={{ size: 2, order: 1 }}
                className="doc-list"
              >
                <div
                  className={
                    selectedPreview === 0 ? "doc-item active" : "doc-item"
                  }
                  onClick={() => {
                    this.togglePreview(0);
                  }}
                >
                  <div>
                    <PDFEntry />
                  </div>
                  <span>Vessel Entry Clearance</span>
                </div>
                <div
                  className={
                    selectedPreview === 1 ? "doc-item active" : "doc-item"
                  }
                  onClick={() => {
                    this.togglePreview(1);
                  }}
                >
                  <div>
                    <PDFCrews />
                  </div>
                  <span>Crew List</span>
                </div>
                <div
                  className={
                    selectedPreview === 2 ? "doc-item active" : "doc-item"
                  }
                  onClick={() => {
                    this.togglePreview(2);
                  }}
                >
                  <div>
                    <PDFShips />
                  </div>
                  <span>Cargo Manifest</span>
                </div>
              </Col>
              <Col
                xs={{ size: 12, order: 1 }}
                md={{ size: 10, order: 2 }}
                className="doc-view"
              >
                <div className="doc-title">
                  <p>
                    {selectedPreview === 0 && "Vessel Entry Clearance"}
                    {selectedPreview === 1 && "Crew List"}
                    {selectedPreview === 2 && "Manifest"}
                  </p>
                </div>
                <div
                  className={
                    viewFullSize ? "doc-cont open-fullsize" : "doc-cont"
                  }
                >
                  <div className="a4-cont">
                    {selectedPreview === 0 ? (
                      <VesselEntryClearanceView
                        visit={this.props.portcall}
                        pcID={this.props.portcall._id}
                        approvals={this.state.entry_approvals}
                      />
                    ) : (
                      ""
                    )}
                    {selectedPreview === 1 ? (
                      <CrewListViewPDF
                        type="Pre Arrival"
                        portcall_id={this.props.portcall._id}
                        search_type="prearrival"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class="bottom-tools">
                  <Can
                    role={session.get().role}
                    perform="port-call-vessel-doc:process"
                    yes={() =>
                      this.props.portcall.status_id ===
                      constants.SUBMITTED_STATUS ? (
                        <div>
                          <Button
                            color="aesi-danger"
                            onClick={() => {
                              this.handleConfirm({
                                msg:
                                  "Are you sure you want to reject this entry application?",
                                success: "Entry application rejected!",
                                doApproval: true,
                                data: {
                                  data: {
                                    status_id: constants.REJECTED_STATUS,
                                    is_vessel_entry_approved: false,
                                    is_vessel_entry_processed: false,
                                  },
                                  action: "reject",
                                  timeline_action:
                                    "Vessel entry application rejected",
                                  module: "entry_application",
                                },
                              });
                            }}
                          >
                            Reject
                          </Button>
                          <Button
                            color="aesi-primary"
                            onClick={() => {
                              this.handleConfirm({
                                msg:
                                  "Endorse Vessel Entry Application for Approval?",
                                success: "Entry application endorsed!",
                                action: "endorse",
                                data: {
                                  data: {
                                    status_id: constants.PROCESSED_STATUS,
                                    is_vessel_entry_approved: false,
                                    is_vessel_entry_processed: true,
                                  },
                                  action: "approve",
                                  timeline_action:
                                    "VEssel entry application endorsed",
                                  module: "entry_approvals",
                                },
                                doApproval: true,
                              });
                            }}
                          >
                            Approve
                          </Button>
                        </div>
                      ) : (
                        ""
                      )
                    }
                  />

                  <Can
                    role={session.get().role}
                    perform="resource-management:edit"
                    yes={() =>
                      this.props.portcall.status_id === 0 ||
                      this.props.portcall.status_id ===
                        constants.REJECTED_STATUS ? (
                        <div>
                          <Button
                            color="aesi-primary"
                            block
                            onClick={() => {
                              this.handleConfirm({
                                msg: "Are you sure you want to submit this?",
                                success: "Entry application submitted!",
                                action: "submit_entry",
                                data: {
                                  is_vessel_entry_submitted: true,
                                  status: constants.SUBMITTED_STATUS,
                                },
                              });
                            }}
                          >
                            <Icon name="paper-plane" />
                            Submit
                          </Button>
                        </div>
                      ) : (
                        ""
                      )
                    }
                  />

                  <Media queries={constants.media_queries}>
                    {(matches) =>
                      matches.xs ? (
                        <>
                          <div
                            onClick={() => {
                              this.togglePreviewList();
                            }}
                          >
                            <Icon
                              name={openDocList ? "chevron-down" : "list"}
                            />
                          </div>
                          <div
                            onClick={() => {
                              this.toggleFullSize();
                            }}
                          >
                            <Icon name={viewFullSize ? "compress" : "expand"} />
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    }
                  </Media>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Media queries={constants.media_queries}>
          {(matches) =>
            matches.xs ? (
              <ToolbarCont portcall={this.props.portcall} />
            ) : (
              <div className="stickyControl">
                <Sticky
                  style={{ zIndex: "2000" }}
                  topOffset={-40}
                  stickyStyle={{
                    transition: "all 200ms",
                    transform: "translateY(calc(60px + 1rem - 14px))",
                  }}
                >
                  <ToolbarCont
                    portcall={this.props.portcall}
                    berthPlans={this.props.berthPlans}
                  />
                </Sticky>
              </div>
            )
          }
        </Media>

        <Modal
          keyboard={false}
          backdrop="static"
          isOpen={openActionsModal}
          toggle={this.toggleActionsModal}
          className=""
          zIndex="2001"
          centered
        >
          <ModalHeader toggle={this.toggleActionsModal}>
            {this.state.pc_status}
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            {this.state.vesselMovementError.length > 0 ? (
              <Alert color="danger" className="err-div">
                <ul>
                  {this.state.vesselMovementError.map((err, i) => {
                    return (
                      <li key={i} className="err-item" color="danger">
                        {err}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            ) : (
              ""
            )}

            {this.state.pc_status === "Anchorage" ||
            this.state.pc_status === "Berth" ? (
              <div className="col-md-12 d-flex flex-row ab-switch">
                <div
                  onClick={() => this.changePcStatus("Anchorage")}
                  className={
                    this.state.pc_status === "Anchorage"
                      ? "col-md-6 active"
                      : "col-md-6"
                  }
                  style={{ borderRadius: "6px 0 0 6px" }}
                >
                  Anchorage
                </div>
                <div
                  onClick={() => {
                    let { status_change } = this.state;
                    status_change.status = "Berthed";
                    status_change.status_id = constants.BERTHED_STATUS;

                    this.setState(
                      { status_change, use_berth_plan: true },
                      () => {
                        this.changePcStatus("Berth");
                      }
                    );
                  }}
                  className={
                    this.state.pc_status === "Berth"
                      ? "col-md-6 active"
                      : "col-md-6"
                  }
                  style={{ borderRadius: "0 6px 6px 0" }}
                >
                  Berth
                </div>
              </div>
            ) : (
              ""
            )}

            <Form className="d-flex flex-column">
              {this.state.pc_status !== "Closed" &&
              this.state.pc_status !== "Complete SPAM" &&
              this.state.pc_status !== "Arrived" ? (
                <FormGroup className="mb-5 col">
                  <Label for="exampleFile">
                    Enter Actual Date of {this.state.pc_status}
                  </Label>
                  <Datetime
                    dateFormat={this.state.__date_format}
                    timeFormat={this.state.__time_format}
                    value={
                      this.state.status_change.date_start
                        ? new Date(this.state.status_change.date_start)
                        : ""
                    }
                    onChange={(e) => {
                      let { status_change } = this.state;
                      status_change.date_start = e;
                      this.setState({ status_change });
                    }}
                  />
                </FormGroup>
              ) : (
                <div></div>
              )}
              {(this.state.pc_status === "Anchorage" ||
                this.state.pc_status === "Berth") &&
              this.state.pc_status !== "Arrived" ? (
                <div className="d-flex flex-column col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-12">
                    <Label for="exampleFile">
                      Actual {this.state.pc_status}
                    </Label>
                    {this.state.pc_status === "Anchorage" ? (
                      <DropdownList
                        filter
                        value={this.state.status_change.anchorage}
                        textField="name"
                        data={constants.anchorages}
                        onChange={(anchorage) => {
                          let { status_change } = this.state;
                          status_change.anchorage = anchorage.name;
                          status_change.anchorage_id = anchorage.id;
                          status_change.lat = anchorage.lat;
                          status_change.long = anchorage.long;
                          this.setState({ status_change });
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                    {this.state.use_berth_plan &&
                    this.state.pc_status === "Berth" ? (
                      <>
                        <DropdownList
                          filter
                          className="mb-5"
                          value={this.state.status_change.drop_down_display}
                          textField="drop_down_display"
                          data={this.state.berthPlans}
                          onChange={(plan) => {
                            let { status_change } = this.state;
                            status_change.berth = plan.berth;
                            status_change.berth_id = plan.berth_id;
                            status_change.berth_plan_id = plan._id;
                            status_change.actual_berth = plan;
                            status_change.service =
                              plan.service;
                            status_change.drop_down_display =
                              plan.drop_down_display;
                            this.setState({ status_change });
                            // console.log("STATS CHENJ", status_change);
                          }}
                        />
                        <span
                          className="berth-switch"
                          onClick={() => {
                            let { use_berth_plan } = this.state;
                            use_berth_plan = false;
                            this.setState({ use_berth_plan });

                            this.handleGetBerth();
                          }}
                        >
                          Use other berth
                        </span>
                      </>
                    ) : (
                      <div></div>
                    )}

                    {!this.state.use_berth_plan &&
                    this.state.pc_status === "Berth" ? (
                      <>
                        <DropdownList
                          filter
                          className="mb-5"
                          value={this.state.status_change.berth}
                          textField="name"
                          data={this.state.berths}
                          onChange={(plan) => {
                            let { status_change } = this.state;
                            status_change.berth = plan.name;
                            status_change.berth_id = plan._id;
                            this.setState({ status_change });
                          }}
                        />
                        <span
                          className="berth-switch"
                          onClick={() => {
                            let { use_berth_plan } = this.state;
                            use_berth_plan = true;
                            this.setState({ use_berth_plan });
                          }}
                        >
                          Use Planned Berth
                        </span>
                      </>
                    ) : (
                      <div></div>
                    )}
                  </FormGroup>
                  {this.state.status_change.tug_boats.map((tb, i) => {
                    return (
                      <>
                        <div className="each-tugboat-con col-md-12 d-flex flex-column">
                          <div
                            class="col d-flex flex-column each-tugboat"
                            key={i}
                          >
                            <div className="statusBtns p-0 mt-1">
                              <span
                                onClick={(e) => {
                                  this.handleRemoveTugBoat(i);
                                }}
                              >
                                {" "}
                                <Icon name="minus" />{" "}
                              </span>
                            </div>

                            <FormGroup className="mb-5 col">
                              <Label for="exampleFile">Tugboat</Label>
                              <div className="col-md-12 p-0 d-flex flex-row">
                                <DropdownList
                                  className="col-md-12 p-0"
                                  style={{ paddingLeft: 0 }}
                                  filter
                                  value={tb.name}
                                  textField="name"
                                  data={tug_boats}
                                  onChange={(ctb) => {
                                    let {
                                      status_change,
                                      selected_tug_boats,
                                    } = this.state;
                                    status_change.tug_boats[i].name = ctb;
                                    selected_tug_boats.push(ctb._id);

                                    this.setState({
                                      status_change,
                                      selected_tug_boats,
                                    });
                                  }}
                                />
                              </div>
                            </FormGroup>

                            <FormGroup className="mb-5 col-md-12">
                              <Label for="exampleFile">Pilot</Label>
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                id=""
                                placeholder=""
                                value={tb.pilot}
                                onChange={(e) => {
                                  let {
                                    status_change,
                                    selected_tug_boats,
                                  } = this.state;
                                  status_change.tug_boats[i].pilot =
                                    e.target.value;
                                  selected_tug_boats.push(e.target.value);

                                  this.setState({
                                    status_change,
                                    selected_tug_boats,
                                  });
                                }}
                              />
                            </FormGroup>

                            <div className="col d-flex flex-row p-0">
                              <div className="col-md-6">
                                <FormGroup className="col-md-12 p-0">
                                  <Label>Pilot Time In</Label>
                                  <Datetime
                                    dateFormat={this.state.__date_format}
                                    timeFormat={this.state.__time_format}
                                    value={tb.pilot_time_in}
                                    onChange={(pti) => {
                                      let {
                                        status_change,
                                        selected_tug_boats,
                                      } = this.state;
                                      status_change.tug_boats[
                                        i
                                      ].pilot_time_in = pti;
                                      selected_tug_boats.push(pti);

                                      this.setState({
                                        status_change,
                                        selected_tug_boats,
                                      });
                                    }}
                                  />
                                </FormGroup>
                              </div>

                              <div className="col-md-6">
                                <FormGroup className="col-md-12 p-0">
                                  <Label>Pilot Time Out</Label>
                                  <Datetime
                                    dateFormat={this.state.__date_format}
                                    timeFormat={this.state.__time_format}
                                    value={tb.pilot_time_out}
                                    onChange={(pto) => {
                                      let {
                                        status_change,
                                        selected_tug_boats,
                                      } = this.state;
                                      status_change.tug_boats[
                                        i
                                      ].pilot_time_out = pto;
                                      selected_tug_boats.push(pto);

                                      this.setState({
                                        status_change,
                                        selected_tug_boats,
                                      });
                                    }}
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="statusBtns col-md-12 p-0 mt-1 pl-3">
                    <span
                      onClick={(e) => {
                        this.handleAddTugBoat();
                      }}
                    >
                      <Icon name="plus" />
                    </span>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {this.state.pc_status === "Anchorage" ? (
                <div className="col-md-12 p-0">
                  <FormGroup className="mb-0 col-md-12">
                    <Label for="exampleFile">Latitude</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      value={this.state.status_change.lat}
                      onChange={(e) => {
                        let { status_change } = this.state;
                        status_change.lat = e.target.value;
                        this.setState({ status_change });
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="col-md-12">
                    <Label for="exampleFile">Longitude</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      value={this.state.status_change.long}
                      onChange={(e) => {
                        let { status_change } = this.state;
                        status_change.long = e.target.value;
                        this.setState({ status_change });
                      }}
                    />
                  </FormGroup>
                </div>
              ) : (
                <div></div>
              )}
              <FormGroup className="mb-5 col-md-12">
                <Label for="">Enter Remarks (Optional)</Label>
                <Input
                  className="fs13"
                  type="textarea"
                  name="mmsi_no"
                  id=""
                  placeholder=""
                  value={this.state.status_change.remarks}
                  onChange={(e) => {
                    let { status_change } = this.state;
                    status_change.remarks = e.target.value;
                    this.setState({ status_change });
                  }}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="sm-btn-font"
              color="danger"
              onClick={this.toggleActionsModal}
            >
              Cancel
            </Button>
            <Button
              className="sm-btn-font"
              color="primary"
              onClick={() => {
                if (!this.handleValidateVesselMovement()) {
                  return false;
                }

                if (this.state.pc_status === "Anchorage") {
                  this.handleAnchorageUpdate({
                    anchorage: this.state.status_change.anchorage,
                    anchorage_id: this.state.status_change.anchorage_id,
                    from_arrived: this.state.from_arrived,
                    status: 1,
                    remarks: this.state.status_change.remarks,
                    date_start: new Date(this.state.status_change.date_start),
                    tug_boats: this.state.status_change.tug_boats,
                    pilot: this.state.status_change.pilot,
                  }).then((res) => {
                    if (res.status === 200) {
                      let { portcall, portcallStateKey } = this.props;
                      portcall = res.data;
                      let obj = {};
                      obj[portcallStateKey] = portcall;
                      this.props.digestHandler({
                        ...obj,
                        vessel_movement_logs: portcall.vessel_movement,

                        selectedLog: "Vessel Movement Logs",
                        portcall_timeline: toTimeline(
                          portcall.status_change_log
                        ),
                      });
                      this.handleDetermineIncompleteVesselMovement();
                      this.handleSuccessAlert("Anchorage Added!");
                      this.toggleActionsModal();
                      this.setState({ no_ws: true });
                    } else {
                      this.showAlert(
                        "error",
                        "Something went wrong. Please contact your System Administrator!",
                        true
                      );
                      this.toggleActionsModal();
                    }
                  });
                }

                if (this.state.pc_status === "Departure") {
                  this.handleDeparture({
                    is_in_port: false,
                    date: this.state.status_change.date_start,
                    status: constants.DEPARTED_STATUS,
                  });
                }

                if (this.state.pc_status === "Berth") {
                  // this.showAlert("loading", "", true);
                  // console.log("CHECK STATS CHENJ", this.state.status_change);

                  this.handleBerthPlanUpdate({
                    date: new Date(),
                    date_start: this.state.status_change.date_start,
                    berth_plan_id: this.state.status_change.berth_plan_id,
                    berth_id: this.state.status_change.berth_id,
                    berth: this.state.status_change.berth,
                    service: this.state.status_change.service,
                    tug_boats: this.state.status_change.tug_boats,
                  }).then((res) => {
                    if (res.status === 200) {
                      let { portcall, portcallStateKey } = this.props;
                      portcall = res.data;
                      let obj = {};
                      obj[portcallStateKey] = portcall;
                      this.props.digestHandler({
                        ...obj,
                        vessel_movement_logs: portcall.vessel_movement,

                        selectedLog: "Vessel Movement Logs",
                        portcall_timeline: toTimeline(
                          portcall.status_change_log
                        ),
                      });
                      this.toggleActionsModal();
                      this.handleSuccessAlert("Vessel Berthed!");
                      this.handleDetermineIncompleteVesselMovement();
                      this.setState({ no_ws: true });
                    } else {
                      this.showAlert(
                        "error",
                        "Something went wrong. Please contact your System Administrator!",
                        true
                      );
                      this.toggleActionsModal();
                    }
                  });
                }
              }}
            >
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          keyboard={false}
          backdrop="static"
          style={{}}
          isOpen={this.state._markAs}
          toggle={this.toggleMarkAs}
          centered
        >
          <ModalHeader toggle={this.toggleMarkAs}>Mark As Complete</ModalHeader>
          <ModalBody className="d-flex flex-column">
            {this.state.formError ? (
              <Alert color="danger" className="err-div">
                <span className="err-item" color="danger">
                  Completion date cannot be before its start.
                </span>
              </Alert>
            ) : (
              ""
            )}

            <p>
              Start Date:{" "}
              {this.state.movementLog ? (
                <Moment format={constants.__dateTimeFormat}>
                  {this.state.movementLog.date_start || "-"}
                </Moment>
              ) : (
                ""
              )}
            </p>
            <FormGroup className="mb-5 col-md-12">
              <Label for="exampleFile" className="fs13">
                Enter Actual Date and Time of
                {this.state.incompleteVesselMovementType === "berth"
                  ? "Berth Completion"
                  : "Completion at Anchorage"}
              </Label>
              <Datetime
                dateFormat={this.state.__date_format}
                timeFormat={this.state.__time_format}
                // value={}
                onChange={(e) => {
                  this.setState({ modal_actual_end: e });
                }}
              />
            </FormGroup>

            {this.state.status_change.tug_boats.map((tb, i) => {
              return (
                <>
                  <div className="each-tugboat-con col-md-12 d-flex flex-column">
                    <div class="col d-flex flex-column each-tugboat" key={i}>
                      <div className="statusBtns p-0 mt-1">
                        <span
                          onClick={(e) => {
                            this.handleRemoveTugBoat(i);
                          }}
                        >
                          {" "}
                          <Icon name="minus" />{" "}
                        </span>
                      </div>

                      <FormGroup className="mb-5 col">
                        <Label for="exampleFile">Tugboat</Label>
                        <div className="col-md-12 p-0 d-flex flex-row">
                          <DropdownList
                            className="col-md-12 p-0"
                            style={{ paddingLeft: 0 }}
                            filter
                            value={tb.name}
                            textField="name"
                            data={tug_boats}
                            onChange={(ctb) => {
                              let {
                                status_change,
                                selected_tug_boats,
                              } = this.state;
                              status_change.tug_boats[i].name = ctb;
                              selected_tug_boats.push(ctb._id);

                              this.setState({
                                status_change,
                                selected_tug_boats,
                              });
                            }}
                          />
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-5 col-md-12">
                        <Label for="exampleFile">Pilot</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          value={tb.pilot}
                          onChange={(e) => {
                            let {
                              status_change,
                              selected_tug_boats,
                            } = this.state;
                            status_change.tug_boats[i].pilot = e.target.value;
                            selected_tug_boats.push(e.target.value);

                            this.setState({
                              status_change,
                              selected_tug_boats,
                            });
                          }}
                        />
                      </FormGroup>

                      <div className="col d-flex flex-row p-0">
                        <div className="col-md-6">
                          <FormGroup className="col-md-12 p-0">
                            <Label>Pilot Time In</Label>
                            <Datetime
                              dateFormat={this.state.__date_format}
                              timeFormat={this.state.__time_format}
                              value={tb.pilot_time_in}
                              onChange={(pti) => {
                                let {
                                  status_change,
                                  selected_tug_boats,
                                } = this.state;
                                status_change.tug_boats[i].pilot_time_in = pti;
                                selected_tug_boats.push(pti);

                                this.setState({
                                  status_change,
                                  selected_tug_boats,
                                });
                              }}
                            />
                          </FormGroup>
                        </div>

                        <div className="col-md-6">
                          <FormGroup className="col-md-12 p-0">
                            <Label>Pilot Time Out</Label>
                            <Datetime
                              dateFormat={this.state.__date_format}
                              timeFormat={this.state.__time_format}
                              value={tb.pilot_time_out}
                              onChange={(pto) => {
                                let {
                                  status_change,
                                  selected_tug_boats,
                                } = this.state;
                                status_change.tug_boats[i].pilot_time_out = pto;
                                selected_tug_boats.push(pto);

                                this.setState({
                                  status_change,
                                  selected_tug_boats,
                                });
                              }}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="statusBtns col-md-12 p-0 mt-1 pl-3">
              <span
                onClick={(e) => {
                  this.handleAddTugBoat();
                }}
              >
                <Icon name="plus" />
              </span>
            </div>

            <FormGroup
              className="mb-5 col-md-12 fs13"
              style={{ marginTop: "0.5em" }}
            >
              <Label for="">Enter Remarks (Optional)</Label>
              <Input
                type="textarea"
                name="mmsi_no"
                id=""
                placeholder=""
                // value={}
                // onChange={}
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button
              className="sm-btn-font"
              color="danger"
              onClick={this.toggleMarkAs}
            >
              Cancel
            </Button>
            <Button
              className="sm-btn-font"
              color="primary"
              onClick={() => {
                if (
                  !this.handleValidateMovementCompletion(
                    this.state.movementLog.date_start,
                    this.state.modal_actual_end
                  )
                ) {
                  this.setState({ formError: true });
                  return false;
                }

                this.setState({ formError: false });
                if (this.state.incompleteVesselMovementType === "anchorage") {
                  this.handleAnchorageUpdate({
                    anchorage: this.state.movementLog.anchorage,
                    anchorage_id: this.state.movementLog.anchorage_id,
                    status: 2,
                    date_end: new Date(this.state.modal_actual_end),
                    tug_boats: this.state.status_change.tug_boats,
                    remarks: this.state.status_change.remarks,
                  }).then((res) => {
                    if (res.status === 200) {
                      let { portcall, portcallStateKey } = this.props;
                      portcall = res.data;
                      let obj = {};
                      obj[portcallStateKey] = portcall;
                      this.props.digestHandler({
                        ...obj,
                        vessel_movement_logs: portcall.vessel_movement,
                        selectedLog: "Vessel Movement Logs",
                        portcall_timeline: toTimeline(
                          portcall.status_change_log
                        ),
                      });
                      this.handleDetermineIncompleteVesselMovement();
                      this.setState({ isAllMovementCompleted: true });
                      this.handleSuccessAlert("Anchorage Completed!");
                      this.toggleMarkAs();
                    }
                  });
                }

                if (this.state.incompleteVesselMovementType === "berth") {
                  const { berth_logs, vessel_movement } = this.props.portcall;
                  const latestMovement =
                    vessel_movement[vessel_movement.length - 1];
                  const latestBerthLog = berth_logs[berth_logs.length - 1];
                  // console.log("latest", latestMovement);

                  this.handleBerthPlanUpdate({
                    date: new Date(),
                    berth_plan_id: latestBerthLog.berth_plan_id,
                    date_end: new Date(this.state.modal_actual_end),
                    berth_id: latestBerthLog.berth_id,
                    tug_boats: this.state.status_change.tug_boats,
                    berth: latestBerthLog.berth,
                    service: latestMovement.service,
                    status_id: 2,
                    status: "Completed",
                  }).then((res) => {
                    if (res.status === 200) {
                      let { portcall, portcallStateKey } = this.props;
                      portcall = res.data;
                      let obj = {};
                      obj[portcallStateKey] = portcall;
                      this.props.digestHandler({
                        ...obj,
                        vessel_movement_logs: portcall.vessel_movement,

                        selectedLog: "Vessel Movement Logs",
                        portcall_timeline: toTimeline(
                          portcall.status_change_log
                        ),
                      });
                      this.toggleMarkAs();
                      this.handleSuccessAlert("Berthing Completed!");
                      this.handleDetermineIncompleteVesselMovement();
                      this.setState({ no_ws: true });
                    } else {
                      this.showAlert(
                        "error",
                        "Something went wrong. Please contact your System Administrator!",
                        true
                      );
                      this.toggleMarkAs();
                    }
                  });
                }
              }}
            >
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          keyboard={false}
          backdrop="static"
          style={{}}
          isOpen={this.state.isVesselExitOpen}
          toggle={() => {
            this.setState({
              isVesselExitOpen: !this.state.isVesselExitOpen,
            });
          }}
          className="preview-modal"
          zIndex="2001"
          centered
        >
          <ModalHeader
            toggle={() => {
              this.setState({
                isVesselExitOpen: !this.state.isVesselExitOpen,
              });
            }}
          >
            Vessel Exit Clearance
          </ModalHeader>
          <ModalBody
            className="d-flex flex-column"
            style={{ minHeight: "50em" }}
          >
            <VesselExitPDF
              pcID={this.props.portcall._id}
              visit={this.props.portcall}
              approvals={this.state.exit_approvals}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withRouter(PortCallToolbar);
