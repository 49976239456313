import React, { Component } from "react";
import { Col, Button, Table } from "reactstrap";
import { Icon } from "react-fa";
import back_icon from "../../../img/svg/BackIcon.svg";
import "./BerthPlan.scss";
import Navbar from "../../Navbar/Navbar";
import Sidenav from "../../Sidenav/Sidenav";
import api from "../../../helpers/api.helper";
import constants from "../../../constants";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import Can from "../../Can/Can";
import session from "../../../session";
import PortCallToolbar from "../../PortCallToolbar/PortCallToolbar";
class BerthPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAudit: false,
      __ID: this.props.match.params.id,
      __port_call: {},
      __berth_plan: []
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call"
        })
        .then(data => {
          api
            .get(this, {
              endpoint: "berth_plan",
              state_key: "__berth_plan",
              request_params: { search: { port_call_id: data._id } }
            })
            .then(berth_plans => {});
        });
    }
  };

  handleAudit = () => {
    this.setState(prevState => ({
      showAudit: !prevState.showAudit
    }));
  };

  render() {
    const { showAudit, __berth_plan } = this.state;
    const vd = this.state.__port_call;

    return (
      <div>
        <div className="d-flex flex-column">
          <Navbar />

          <div md={{ size: 12 }} className="d-flex flex-column page-header">
            <Col md={{ size: 12 }} className="d-flex flex-row">
              <Col className="d-flex flex-row col-md-6">
                <img src={back_icon} alt="back_icon" className="" width="25" />
                <p className="vessel-name aligner justify-content-start">
                  {vd.vessel_name}, Voyage {vd.voyage_number}
                </p>
              </Col>
            </Col>
          </div>

          <Col md={{ size: 12 }} className="main-content d-flex flex-row mt-3">
            <Col md={{ size: 2 }}>
              <Sidenav dataFromParent={this.props.match.params.id} visit={vd} />
            </Col>

            <Col md={{ size: 10 }} className="mc-right d-flex flex-column">
              <div
                className="form-container-box bt-green"
                style={{ margin: "0 5px", minHeight: "30em" }}
              >
                <div className="fcb-title">Berth Plan</div>

                <div className="col-md-12 d-flex justify-content-end mt-3 p-0">
                  <Can
                    role={session.get().role}
                    perform="berth-management:create"
                    yes={() => (
                      <Button
                        className="btn fs13"
                        color="primary"
                        onClick={() =>
                          this.props.history.push(
                            "/port_call/berth_plan/" +
                              this.state.__ID +
                              "/create"
                          )
                        }
                      >
                        New Berth Plan
                      </Button>
                    )}
                  />
                </div>

                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "20px" }}
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Berth Plan Date</th>
                      <th>Berth</th>
                      <th>ETA</th>
                      <th>ETD</th>
                      <th>ATA</th>
                      <th>ATD</th>
                      <th>Created by</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {__berth_plan.map((bp, i) => (
                      <tr
                        className="cur-p"
                        key={i}
                        berth-plan-id={bp._id}
                        onClick={() =>
                          this.props.history.push(
                            "/port_call/berth_plan/update/" +
                              bp.port_call_id +
                              "/" +
                              bp._id
                          )
                        }
                      >
                        <td>{i + 1}</td>
                        <td>
                          <Moment format={constants.__dateTimeFormat}>
                            {bp.created_at}
                          </Moment>
                        </td>
                        <td>{bp.berth}</td>
                        <td>
                          {(
                            <Moment format={constants.__dateTimeFormat}>
                              {bp.eta}
                            </Moment>
                          ) || "-"}
                        </td>
                        <td>
                          {(
                            <Moment format={constants.__dateTimeFormat}>
                              {bp.etd}
                            </Moment>
                          ) || "-"}
                        </td>
                        <td>
                          {(
                            <Moment format={constants.__dateTimeFormat}>
                              {bp.ata}
                            </Moment>
                          ) || "-"}
                        </td>
                        <td>
                          {(
                            <Moment format={constants.__dateTimeFormat}>
                              {bp.atd}
                            </Moment>
                          ) || "-"}
                        </td>
                        <td>-</td>
                        <td>{bp.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <Col col={{ size: 12 }} className="d-flex flex-row p-2 mt-3">
                <div className="form-container-box bt-green col-md-12">
                  <div className="col-md-12 d-flex flex-row auditHead">
                    {showAudit ? (
                      <Icon
                        className=""
                        name="angle-up"
                        onClick={this.handleAudit}
                      />
                    ) : (
                      <Icon
                        className=""
                        name="angle-down"
                        onClick={this.handleAudit}
                      />
                    )}

                    <p>Audit Trails</p>
                  </div>

                  <div>
                    {showAudit ? (
                      <Col md={{ size: 12 }} className="p-0">
                        <Table
                          className="custom-table"
                          bordered
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Details</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </Col>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

export default withRouter(BerthPlan);
