import api from "./api.helper";

const handleApproval = ({ portcall, approvaldData }) => {
  if (!portcall && !approvaldData) {
    return Promise.reject({
      message: "Could not approve portcall. Missing required information."
    });
  }

  return api.manualUpdate({
    endpoint: "portcall/approve/" + portcall._id,
    request_params: {
      ...approvaldData
    }
  });
};

export default handleApproval;
