import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./InPortServicesForm.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import { Row, Col, FormGroup, Label, Form, Input, Button } from "reactstrap";
import { DropdownList } from "react-widgets";
import back_icon from "../../img/svg/BackIcon.svg";
import api from "../../helpers/api.helper";
import constants from "../../constants";
import AlertModal from "../Tools/AlertModal/AlertModal";
import Media from "react-media";
import Icon from "react-fa";
import PageHeader from "../Tools/PageHeader/PageHeader";

class InPortServicesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __data: {},
      _services: constants.purpose_of_portcalls,
      selected_services: {},
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
      errorMsg: {
        errService: "",
      },
      disableSave: false,
      CARGO_HANDLERS: [],
      CH_NAMES: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
    this.loadCargoHandlers();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api.get(this, {
        endpoint: "portcall/" + this.state.__ID,
      });
    }
  };

  loadCargoHandlers = () => {
    api
      .get(this, {
        endpoint: "cargo_handler",
        state_key: "CARGO_HANDLERS",
      })
      .then((data) => {
        // let cargo_handlers = [];
        // for (let x in data) {
        //   cargo_handlers[x] = data[x].name;
        // }
        // cargo_handlers = [...cargo_handlers];
        // this.setState({
        //   CH_NAMES: cargo_handlers,
        // });
        // console.log("CARGOOOSF", this.state.CH_NAMES);
      });
  };

  handleSave = () => {
    let { selected_services } = this.state;
    if (!selected_services.service) {
      let { errorMsg } = this.state;
      errorMsg.errService = !selected_services.service
        ? "This is required"
        : "";

      this.setState(errorMsg);
    } else {
      this.showAlert("loading", "", true);
      this.setState({
        disableSave: true,
      });

      let user = localStorage.getItem("port_session");
      if (user) {
        user = JSON.parse(user);
      }

      api
        .update(this, {
          endpoint: "/portcall/portcall_service/" + this.state.__ID,
          _data: {
            ...selected_services,
            submitted_by: user.last_name + ", " + user.first_name,
            user_id: user._id,
            status: "Pending",
          },
        })
        .then((data) => {
          this.setState(
            {
              tglAlert: false,
              disableSave: false,
            },
            () => this.showAlert("success", "Service successfully saved!", true)
          );

          // console.log("ID", data);

          this.props.history.push(
            "/port_call/in_port_services/" + this.state.__ID
          );
        });
    }
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  render() {
    const vd = this.state.__data;
    const service = this.state.__data.service;
    const arrastre = this.state.__data.arrastre;
    const stevedoring = this.state.__data.stevedoring;
    const { _services, isAlert, alertMsg, tglAlert, errorMsg } = this.state;

    return (
      <div className="inPortServicesForm">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}

        <div className="d-flex flex-column">
          <Navbar />

          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav dataFromParent={this.state.__ID} visit={vd} />
              ) : (
                <PageHeader
                  hideDocToggle
                  sendVName={vd.vessel_name}
                  sendVoyageNo={vd.voyage_number}
                />
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav dataFromParent={this.state.__ID} visit={vd} />
                  </Col>
                ) : (
                  <PageHeader
                    hideDocToggle
                    sendVName={vd.vessel_name}
                    sendVoyageNo={vd.voyage_number}
                    pagename="Add Service"
                  />
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              <Row className="form-container-box bt-green vessel-detail-form">
                <Form>
                  <div className="fcb-title">
                    <span
                      className="cur-p"
                      onClick={() =>
                        this.props.history.push(
                          "/port_call/in_port_services/" + this.state.__ID
                        )
                      }
                    >
                      Purpose of Port Call >{" "}
                    </span>
                    Add Service
                  </div>

                  <Row md="2" xs="1">
                    <Col>
                      <FormGroup
                        className={
                          errorMsg.errService
                            ? "col-md-12 p-0 required-field"
                            : "col-md-12 p-0"
                        }
                      >
                        <Label for="exampleSelect">Service</Label>
                        <DropdownList
                          filter
                          value={service}
                          data={_services}
                          onChange={(service) => {
                            let { selected_services } = this.state;
                            selected_services.service = service;

                            errorMsg.errService = !selected_services.service
                              ? "This is required"
                              : "";

                            this.setState({ selected_services, errorMsg });
                            console.log("SERVICE", selected_services);
                          }}
                        />
                        <div className="error-msg col-md-12">
                          {errorMsg.errService}
                        </div>
                      </FormGroup>
                      {!this.state.selected_services.service ? (
                        ""
                      ) : (
                        <FormGroup className="col-md-12 p-0">
                          <Label for="exampleText">Purpose</Label>
                          <Input
                            className="fs13"
                            type="textarea"
                            rows="2"
                            name="text"
                            id="exampleText"
                            style={{ resize: "none" }}
                            value={this.state.selected_services.purpose}
                            onChange={(e) => {
                              let { selected_services } = this.state;
                              selected_services.purpose = e.target.value;
                              this.setState({ selected_services });
                            }}
                          />
                        </FormGroup>
                      )}
                    </Col>

                    {this.state.selected_services.service ===
                      constants.CARGO_HANDLING_SERVICE_LOAD ||
                    this.state.selected_services.service ===
                      constants.CARGO_HANDLING_SERVICE_UNLOAD ? (
                      <div className="col-md-6">
                        <FormGroup className="col-md-12 p-0">
                          <Label for="exampleSelect">Arrastre</Label>
                          <DropdownList
                            filter
                            value={arrastre}
                            textField="name"
                            data={this.state.CARGO_HANDLERS}
                            onChange={(arrastre) => {
                              let { selected_services } = this.state;
                              selected_services.arrastre = arrastre.name;
                              selected_services.arrastre_id = arrastre._id;
                              this.setState({ selected_services });
                              console.log("ARR", selected_services);
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-12 p-0">
                          <Label for="exampleSelect">Stevedoring</Label>
                          <DropdownList
                            filter
                            value={stevedoring}
                            textField="name"
                            data={this.state.CARGO_HANDLERS}
                            onChange={(stevedoring) => {
                              let { selected_services } = this.state;
                              selected_services.stevedoring = stevedoring.name;
                              selected_services.stevedoring_id =
                                stevedoring._id;
                              this.setState({ selected_services });
                            }}
                          />
                        </FormGroup>
                      </div>
                    ) : !this.state.selected_services.service ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <FormGroup className="col-md-12 p-0">
                          <Label for="exampleSelect">Service Provider</Label>
                          <Input
                            type="text"
                            name="provider"
                            id="provider_txt"
                            value={this.state.selected_services.provider}
                            onChange={(e) => {
                              let { selected_services } = this.state;
                              selected_services.provider = e.target.value;
                              this.setState({ selected_services });
                            }}
                          />
                        </FormGroup>
                      </div>
                    )}
                  </Row>
                </Form>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="footer-actions d-flex flex-row justify-content-end">
          <Button
            onClick={(e) => this.handleSave()}
            className={
              this.state.disableSave
                ? "form-save btn-sm fs13 dis-btn"
                : "form-save btn-sm fs13"
            }
            color="success"
            style={{ margin: "0 0.5em" }}
          >
            <Media queries={constants.media_queries}>
              {(matches) => (matches.xs ? <Icon name="plus" /> : "SAVE")}
            </Media>
          </Button>
        </div>
      </div>
    );
  }
}
export default withRouter(InPortServicesForm);
