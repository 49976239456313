import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./css/main.scss";
import "./css/proxima-nova.css";
import * as serviceWorker from "./serviceWorker";

import { Route, BrowserRouter as Router } from "react-router-dom";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ArrivalTicker from "./components/Dashboard/Tickers/ArrivalTicker";
import VesselsInPort from "./components/Dashboard/Tickers/VesselsInPort";
import PortCalls from "./components/PortCalls/PortCalls";
import AIS from "./components/AIS/AIS";
import VisitDetail from "./components/VisitDetail/VisitDetail";
import VesselEntry from "./components/VesselEntry/VesselEntry";
import Parties from "./components/Parties/Parties";
import CrewList from "./components/CrewList/CrewList";
import Anchorage from "./components/Anchorage/Anchorage";
import BerthPlan from "./components/BerthPlan/List/BerthPlan";
import BerthPlanForm from "./components/BerthPlan/Form/BerthPlanForm";
import CargoDetails from "./components/CargoDetails/CargoDetails";
import ResourcePlan from "./components/ResourcePlan/ResourcePlan";
import DocumentManagement from "./components/DocumentManagement/DocumentManagement";
import VesselExit from "./components/VesselExit/VesselExit";
import Inbox from "./components/Inbox/Inbox";
import InboxView from "./components/InboxView/InboxView";
import InboxCreate from "./components/InboxCreate/InboxCreate";
import Amendments from "./components/Amendments/Amendments";
import Vessels from "./components/Vessels/Vessels";
import BerthManagement from "./components/BerthManagement/BerthManagement";
import BerthSched from "./components/BerthSched/BerthSched";
import VesselDetail from "./components/Maintenance/VesselDetail/List/VesselDetail";
import VesselDetailAdd from "./components/Maintenance/VesselDetail/Form/VesselDetailForm";
import VesselDetailView from "./components/Maintenance/VesselDetail/View/VesselDetailView";
import Manifest from "./components/Manifest/Manifest";
import InPortServices from "./components/InPortServices/InPortServices";
import InPortServicesForm from "./components/InPortServicesForm/InPortServicesForm";
import VesselInfo from "./components/VesselInfo/VesselInfo";
import ManifestDocView from "./components/Manifest/ManifestDocView/ManifestDocView";
import DepartureDetails from "./components/DepartureDetails/DepartureDetails";
import PortTariffs from "./components/PortTariffs/PortTariffs";
import VesselMovementLog from "./components/VesselMovementLog/VesselMovementLog";
import Notifications from "./components/Notifications/Notifications";
import PrintCoastingManifest from "./components/CargoDetails/PrintCoastingManifest";
import Cookies from "js-cookie";
import { Redirect } from "react-router";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import BerthMaintenance from "./components/Maintenance/BerthMaintenance/BerthMaintenance";
import AnchorageMaintenance from "./components/Maintenance/Anchorage/AnchorageMaintenance";
import Maintenance from "./components/Maintenance/Maintenance";
import TugboatCompanies from "./components/Maintenance/TugboatCompanies/TugboatCompanies";
import CargoHandlerMaintenance from "./components/Maintenance/CargoHandlerMaintenance/CargoHandlerMaintenance";
import BerthMonitoring from "./components/Dashboard/Tickers/BerthMonitoring";
import DepartureTicker from "./components/Dashboard/Tickers/Departure";
import RecentsTicker from "./components/Dashboard/Tickers/RecentsTicker";
JavascriptTimeAgo.locale(en);

// import session from "./session";
// import VesselEntryClearanceView from "./components/VesselEntryClearanceView/VesselEntryClearanceView";

const isAuthenticated = (props) => {
  return Cookies.get("port_token");
};

const routing = (
  <Router>
    <Route exact path="/" component={Login} />
    <Route
      path="/dashboard"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Dashboard {...props} />
      }
    />
    <Route
      path="/ticker/arrivals"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <ArrivalTicker {...props} />
      }
    />

    <Route
      path="/ticker/vessels_in_port"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <VesselsInPort {...props} />
      }
    />

    <Route
      path="/ticker/berth_monitoring"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <BerthMonitoring {...props} />
        )
      }
    />

    <Route
      path="/ticker/departure"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <DepartureTicker {...props} />
        )
      }
    />

    <Route
      path="/ticker/recents"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <RecentsTicker {...props} />
      }
    />

    <Route
      path="/port_calls"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <PortCalls {...props} />
      }
    />
    <Route
      path="/ais"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <AIS {...props} />
      }
    />
    <Route
      path="/port_call/visit_detail/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <VisitDetail {...props} />
      }
    />
    <Route
      path="/port_call/vessel_entry/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <VesselEntry {...props} />
      }
    />
    <Route
      path="/port_call/create_vessel_entry"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <VesselEntry {...props} />
      }
    />
    <Route
      path="/port_call/vessel_detail/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <VesselInfo {...props} />
      }
    />
    <Route
      path="/port_call/create_vessel_detail"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <VesselInfo {...props} />
      }
    />
    <Route
      path="/port_call/parties/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Parties {...props} />
      }
    />
    <Route
      path="/port_call/in_port_services/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <InPortServices {...props} />
      }
    />
    <Route
      path="/port_call/in_port_services_create/:id"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <InPortServicesForm {...props} />
        )
      }
    />
    {/* <Route
      path="/port_call/in_port_services_create/:id"
      render={props =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <InPortServicesForm {...props} />
        )
      }
    /> */}
    <Route
      path="/port_call/crew_list_pre_arrival/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <CrewList {...props} />
      }
    />
    <Route
      path="/port_call/crew_list_pre_departure/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <CrewList {...props} />
      }
    />
    <Route
      path="/port_call/anchorage/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Anchorage {...props} />
      }
    />
    <Route
      path="/port_call/berth_plans/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <BerthPlan {...props} />
      }
    />
    <Route
      path="/port_call/berth_plan/:id/create"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <BerthPlanForm {...props} />
      }
    />
    <Route
      path="/port_call/berth_plan/update/:id/:berth_plan_id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <BerthPlanForm {...props} />
      }
    />
    <Route
      path="/port_call/berth_plan/view/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <BerthPlanForm {...props} />
      }
    />
    <Route
      path="/port_call/manifest/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Manifest {...props} />
      }
    />
    <Route
      path="/port_call/manifest_view/:id"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <ManifestDocView {...props} />
        )
      }
    />
    <Route
      path="/port_call/cargo_details/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <CargoDetails {...props} />
      }
    />

    <Route
      path="/print_coasting"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <PrintCoastingManifest {...props} />
        )
      }
    />

    <Route
      path="/port_call/resource_plan/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <ResourcePlan {...props} />
      }
    />
    <Route
      path="/port_call/document_management/:id"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <DocumentManagement {...props} />
        )
      }
    />
    <Route
      path="/port_call/departure_details/:id"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <DepartureDetails {...props} />
        )
      }
    />
    <Route
      path="/port_call/port_tariffs/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <PortTariffs {...props} />
      }
    />
    <Route
      path="/port_call/vessel_exit/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <VesselExit {...props} />
      }
    />
    <Route
      path="/port_call/inbox/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Inbox {...props} />
      }
    />
    <Route
      path="/port_call/inbox_view/:id/:parent_id?"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <InboxView {...props} />
      }
    />
    <Route
      path="/port_call/inbox_create/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <InboxCreate {...props} />
      }
    />
    <Route
      path="/port_call/amendments/:id"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Amendments {...props} />
      }
    />
    <Route
      path="/port_call/vessel_movement_logs/:id"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <VesselMovementLog {...props} />
        )
      }
    />
    {/* <Route
      path="/port_call/event_scheduler/:id"
      render={props =>
        !isAuthenticated() ? <Redirect to="/" /> : <EventScheduler {...props} />
      }
    /> */}
    {/* <Route
      path="/port_call/event_scheduler/view/:id"
      render={props =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <EventSchedulerView {...props} />
        )
      }
    /> */}
    <Route
      path="/vessels"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Vessels {...props} />
      }
    />
    <Route
      path="/berth_management"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <BerthManagement {...props} />
        )
      }
    />
    <Route
      path="/maintenance/list"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Maintenance {...props} />
      }
    />
    <Route
      path="/maintenance/berth"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <BerthMaintenance {...props} />
        )
      }
    />
    <Route
      path="/berth_sched"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <BerthSched {...props} />
      }
    />
    <Route
      path="/maintenance/vessels"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <VesselDetail {...props} />
      }
    />
    <Route
      path="/maintenance/anchorage"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <AnchorageMaintenance {...props} />
        )
      }
    />

    <Route
      path="/maintenance/tugboat_companies"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <TugboatCompanies {...props} />
        )
      }
    />

    <Route
      path="/maintenance/cargo_handlers"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <CargoHandlerMaintenance {...props} />
        )
      }
    />

    <Route
      path="/maintenance/vessel/create"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <VesselDetailAdd {...props} />
        )
      }
    />
    <Route
      path="/maintenance/vessel/edit/:id"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <VesselDetailAdd {...props} />
        )
      }
    />
    <Route
      path="/maintenance/vessel/view/:id"
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : (
          <VesselDetailView {...props} />
        )
      }
    />
    <Route
      path="/notifications"
      render={(props) =>
        !isAuthenticated() ? <Redirect to="/" /> : <Notifications {...props} />
      }
    />
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
