import React, { Component, useState } from "react";
import { BrowserRouter as Router, withRouter, NavLink } from "react-router-dom";
import {
  ToastBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Input,
  Form,
} from "reactstrap";
import Sticky from "react-sticky-el";
import "./Navbar.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import portWebsocket from "../../portWebsocket";
import ekonek_logo from "../../img/ekonek-logo.jpg";
import settings_svg from "../../img/svg/settings.svg";
import notification_bell from "../../img/svg/alarm.svg";
import sbma_logo from "../../img/sbma-logo.png";
import { Popover, PopoverBody } from "reactstrap";
import api from "../../helpers/api.helper";
import Cookies from "js-cookie";
import Can from "../Can/Can";
import session from "../../session";
import str_helper from "../../helpers/string.helper";
import { NotificationsList } from "../Notifications/Notifications";
import { ReactComponent as NavHome } from "../../img/svg/nav-house.svg";
import { ReactComponent as NavPortCall } from "../../img/svg/nav-anchor.svg";
import { ReactComponent as NavBerth } from "../../img/svg/nav-mooring-point.svg";
import { ReactComponent as NavVessels } from "../../img/svg/nav-transportation.svg";
import { ReactComponent as NavAis } from "../../img/svg/nav-global.svg";
import { ReactComponent as Password } from "../../img/svg/password.svg";
import { Icon } from "react-fa";
import Swal from "sweetalert2";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.pageHeader = React.createRef();

    this.state = {
      hideBar: true,
      popoverOpen: false,
      notifsPopoverOpen: false,
      openPassModal: false,
      USER_INFO: {
        password: "",
      },
      userID: "",
      NEW_PASSWORD: "",
      peek: false,
      confirm_peek: false,
      current_peek: false,
      ERR_LIST: {
        sixChar: false,
        hasUpper: false,
        hasNumber: false,
        hasSpecial: false,
      },
      CREDENTIALS: [],
      LOGIN: {},
      confirmPassword: "",
      showNewPass: false,
      newConfirmMatch: false,
    };

    this.popoverOpen = false;
    this.notifsPopoverOpen = false;

    // if (Cookies.get("port_token") && session.get() !== null) {
    //   window.location.replace("/dashboard");
    // }

    toast.configure({
      autoClose: 3500,
      draggable: false,
      hideProgressBar: true,
      //etc you get the idea
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    // console.log(this.isMobileDevice());

    let cookie = Cookies;

    portWebsocket.events.connected = (oMsg) => {
      if (
        oMsg.port_token &&
        oMsg.uid &&
        cookie.get("port_token") !== oMsg.port_token &&
        cookie.get("uid") === oMsg.uid &&
        window.location.hostname !== "localhost"
      ) {
        toast.error(
          <ToastBody>
            <b>Duplicate Login</b> <br />
            Your Account has been logged in to another browser
          </ToastBody>
        );

        this.handleLogout();
      }
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  loadUserInfo = () => {
    api
      .get(this, { endpoint: "user/info", state_key: "USER_INFO" })
      .then((data) => {
        data.password = "";

        this.setState({
          userID: data._id,
          data,
        });
      });
  };

  getNavLinkClass = (path) => {
    // return this.props.location.pathname === path ? "active" : "";
  };

  handleScroll = () => {
    const distanceY = window.pageYOffset,
      shrinkOn = 100;

    if (distanceY > shrinkOn) {
      this.setState({
        hideBar: false,
      });
    } else {
      this.setState({
        hideBar: true,
      });
    }
  };

  handleLogout = () => {
    let component = this;

    api
      .post(component, {
        endpoint: "user/logout",
        _data: {
          filler: true,
        },
      })
      .then((res) => {
        // console.log(res);
        if (res) {
          this.unsetSession().then((data) => {
            if (data) {
              window.location.replace("/");
            }
          });
        }
      })
      .catch((e, f) => {
        //alert(e)
      });
  };

  unsetSession() {
    return new Promise((resolve, reject) => {
      Cookies.remove("port_token", { path: "" });
      Cookies.remove("uid", { path: "" });
      Cookies.remove("role", { path: "" });
      localStorage.removeItem("port_session");

      resolve(true);
    });
  }

  handleLogoutAll = () => {
    let component = this;

    api
      .post(component, {
        endpoint: "user/logoutall",
        _data: {},
      })
      .then((res) => {
        if (res) {
          setTimeout(() => {
            Cookies.remove("port_token", { path: "" });
            localStorage.removeItem("port_session");
            window.location.replace("/");
          }, 500);
        }
      })
      .catch((e, f) => {
        //alert(e)
      });
  };

  handleToggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  handleNotifToggle = () => {
    this.setState({
      notifsPopoverOpen: !this.state.notifsPopoverOpen,
    });
  };

  isMobileDevice = () => {
    return window.innerWidth <= 575;
  };

  // here
  handlePassModal = () => {
    let { ERR_LIST } = this.state;

    ERR_LIST.sixChar = false;
    ERR_LIST.hasUpper = false;
    ERR_LIST.hasNumber = false;
    ERR_LIST.hasSpecial = false;

    this.setState({
      openPassModal: !this.state.openPassModal,
      showNewPass: false,
      peek: false,
      confirm_peek: false,
      current_peek: false,
      ERR_LIST,
    });

    this.loadUserInfo();
  };

  handlePeek = (p) => {
    if (p === "peek") {
      this.setState({
        peek: !this.state.peek,
      });
    } else if (p === "current_peek") {
      this.setState({
        current_peek: !this.state.current_peek,
      });
    } else {
      this.setState({
        confirm_peek: !this.state.confirm_peek,
      });
    }
  };

  handleChangePass = () => {
    let { USER_INFO, userID, ERR_LIST, confirmPassword } = this.state;

    if (USER_INFO.password === confirmPassword) {
      if (
        ERR_LIST.sixChar ||
        ERR_LIST.hasUpper ||
        ERR_LIST.hasNumber ||
        ERR_LIST.hasSpecial
      ) {
        toast.error(
          <ToastBody>
            <b>Please follow password requirements!</b>
          </ToastBody>
        );
      } else {
        api
          .update(this, {
            endpoint: "user/change_password/" + this.state.userID,
            _data: USER_INFO,
          })
          .then((data) => {
            this.setState({
              openPassModal: false,
            });

            toast.success(
              <ToastBody>
                <b>Password updated!</b>
              </ToastBody>
            );
          });
      }
    } else {
      toast.error(
        <ToastBody>
          <b>Entered new password confirmation does not match!</b>
        </ToastBody>
      );
    }
  };

  handleOldPass = () => {
    let component = this;

    api
      .post(component, {
        endpoint: "user/login",
        _data: component.state.USER_INFO,
      })
      .then((res) => {
        let { USER_INFO, confirmPassword } = this.state;

        USER_INFO.password = "";

        this.setState({
          showNewPass: true,
          USER_INFO,
          confirmPassword: "",
        });

        if (res.data.token) {
          this.handleSetSession(res.data).then((user) => {
            if (user) {
              toast.success(
                <ToastBody>
                  <b>Entered current password confirmed!</b>
                </ToastBody>
              );
            }
          });
        }
      })
      .catch((e, f) => {
        this.setState({
          showNewPass: false,
        });

        if (e.response && e.response.data && e.response.data.error) {
          toast.error(
            <ToastBody>
              <b>Entered password does not match current password!</b>
            </ToastBody>
          );

          //toast.error(e.response.data.error)
        }
      });
  };

  handleSetSession = (data) => {
    return new Promise((resolve, reject) => {
      let d = new Date(),
        oJwt = this.parseJwt(data.token);

      Cookies.set("port_token", data.token, {
        path: "/",
        expires: new Date(d.setTime(d.getTime() + 480 * 60 * 1000)),
      });

      Cookies.set("uid", oJwt._id, {
        path: "/",
        expires: new Date(d.setTime(d.getTime() + 480 * 60 * 1000)),
      });

      Cookies.set("role", data.user.role, {
        path: "/",
        expires: new Date(d.setTime(d.getTime() + 480 * 60 * 1000)),
      });

      portWebsocket.initialize();

      session.set(data.user);

      //localStorage.setItem("port_session", JSON.stringify(data.user));

      resolve(data.user);
    }).catch((e) => {
      // console.log(e);
    });
  };

  parseJwt = (token) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  render() {
    const {
      openPassModal,
      USER_INFO,
      NEW_PASSWORD,
      peek,
      confirm_peek,
      ERR_LIST,
      current_peek,
    } = this.state;

    return (
      <div className="Navbar">
        <div className="hdg-con">
          <div className="nav-eKonek-header row">
            <div className="col-sm col-lg-7 row m-0">
              <img
                src={ekonek_logo}
                alt="ekonek-logo"
                className="nav-ekonek-logo"
              />
              <p className="nav-port-title">
                SBMA <span>PORT OPERATIONS MANAGEMENT SYSTEM</span>
                <span>POMS</span>
              </p>
            </div>

            <div className="col col-lg-5 row m-0 d-flex justify-content-end align-items-center">
              <div className="">
                {/* <img
                  src={message}
                  alt="message-svg"
                  className="nav-settings-svg cur-p"
                /> */}
                <img
                  src={settings_svg}
                  alt="settings-svg"
                  className="nav-settings-svg cur-p"
                  onClick={() => this.props.history.push("/maintenance/list")}
                />

                <div
                  className={
                    true
                      ? "nav-notif-svg nav-mobile unread"
                      : "nav-notif-svg nav-mobile"
                  }
                >
                  <img
                    src={notification_bell}
                    alt="notification_bell"
                    onClick={() => {
                      this.props.history.push("/notifications");
                    }}
                  />
                </div>

                <div
                  id="NotifsPopover"
                  className={true ? "nav-notif-svg unread" : "nav-notif-svg"}
                >
                  <img
                    src={notification_bell}
                    alt="notification_bell"
                    className=""
                  />
                </div>
                <Popover
                  placement="bottom-end"
                  isOpen={this.state.notifsPopoverOpen}
                  target="NotifsPopover"
                  toggle={this.handleNotifToggle}
                  trigger="legacy"
                  className="notifications-po"
                >
                  <PopoverBody className="notif-header">
                    <div>Notifications</div>
                    <div
                      className="oncl"
                      onClick={() => {
                        // // console.log("Mark All as Read");
                      }}
                    >
                      Mark All as Read
                    </div>
                  </PopoverBody>
                  <PopoverBody>
                    <NotificationsList />
                  </PopoverBody>
                  <PopoverBody className="notif-footer">
                    <div
                      className="oncl"
                      onClick={() => {
                        // // console.log("See All Notifications");
                        this.props.history.push("/notifications");
                      }}
                    >
                      See All Notifications
                    </div>
                  </PopoverBody>
                </Popover>
              </div>

              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center" id="Popover1">
                  <img
                    src={require("../../img/profile/profile_" +
                      session.get().first_name.charAt(0).toLowerCase() +
                      ".png")}
                    alt="profile-img"
                    className="nav-profile-img cur-p"
                  />
                  <div className="user-name">
                    <p>
                      {session.get().first_name} {session.get().last_name}
                    </p>
                    <p className="align-self-start">
                      {str_helper.human_readable(session.get().role)}
                    </p>
                  </div>
                  <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target="Popover1"
                    toggle={this.handleToggle}
                    trigger="legacy"
                  >
                    <PopoverBody className="logout-btns">
                      <div className="user-name">
                        <p>
                          {session.get().first_name} {session.get().last_name}
                        </p>
                        <p>{str_helper.human_readable(session.get().role)}</p>
                      </div>
                      <ul>
                        <li>
                          <div
                            onClick={() => this.handlePassModal()}
                            style={{ color: "#555" }}
                          >
                            Change Password
                          </div>
                        </li>
                        <li>
                          <div class="divider"></div>
                        </li>
                        <li onClick={() => this.handleLogoutAll()}>
                          <div>Logout All Sessions</div>
                        </li>
                        <li onClick={() => this.handleLogout()}>
                          <div>Logout</div>
                        </li>
                      </ul>
                    </PopoverBody>
                  </Popover>

                  <Modal
                    isOpen={openPassModal}
                    style={{ width: "20em", marginTop: "10%" }}
                    // unmountOnClose={unmountOnClose}
                  >
                    <div class="col-md-12 p-0 d-flex flex-row justify-content-center">
                      <Password width="125" style={{ marginTop: "-4em" }} />
                    </div>
                    {/* <ModalHeader toggle={() => this.handlePassModal()}>
                      Change Password
                    </ModalHeader> */}
                    <ModalBody style={{ paddingTop: "1em" }}>
                      <div class="col-md-12 p-0 d-flex flex-row justify-content-center change-pass-con">
                        <p className="change-pass-title">Change Password</p>
                      </div>

                      {/* <Input placeholder="Current password" /> */}
                      <div class="col-md-12 p-0 d-flex flex-column pass-input-con">
                        <FormGroup
                          className="col-md-12 d-flex flex-column p-0"
                          style={{ marginBottom: "0.75em" }}
                        >
                          <Label>Current Password</Label>
                          <div class="p-0 d-flex flex-row">
                            <Input
                              className=""
                              type={current_peek ? "text" : "password"}
                              onChange={(e) => {
                                let { USER_INFO } = this.state;

                                USER_INFO.password = e.target.value;
                              }}
                            />
                            <div
                              className="col d-flex flex-row cur-p align-items-center"
                              style={{ paddingRight: "0", paddingLeft: "10px" }}
                            >
                              <Icon
                                onClick={() => this.handlePeek("current_peek")}
                                className="peek-btn"
                                name={current_peek ? "eye-slash" : "eye"}
                              />
                            </div>
                          </div>
                        </FormGroup>

                        {this.state.showNewPass ? (
                          <>
                            <FormGroup
                              className="col-md-12 d-flex flex-column p-0"
                              style={{ marginBottom: "0.75em" }}
                            >
                              <Label>New Password</Label>
                              <div class="p-0 d-flex flex-row">
                                <Input
                                  className=""
                                  type={peek ? "text" : "password"}
                                  value={USER_INFO.password}
                                  onChange={(e) => {
                                    let { USER_INFO, ERR_LIST } = this.state;
                                    let pass = e.target.value;
                                    let each = true;

                                    ERR_LIST.sixChar =
                                      pass.length < 6 ? true : false;
                                    ERR_LIST.hasUpper = !/[A-Z]/.test(pass)
                                      ? true
                                      : false;
                                    ERR_LIST.hasNumber = !/[0-9]/.test(pass)
                                      ? true
                                      : false;
                                    ERR_LIST.hasSpecial =
                                      !/^[a-zA-Z0-9-_]+$/.test(pass)
                                        ? true
                                        : false;

                                    USER_INFO.password = e.target.value;

                                    this.setState({ USER_INFO, ERR_LIST });

                                    // console.log("NEW PASS", USER_INFO);
                                  }}
                                />
                                <div
                                  className="col d-flex flex-row cur-p align-items-center"
                                  style={{
                                    paddingRight: "0",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Icon
                                    onClick={() => this.handlePeek("peek")}
                                    className="peek-btn"
                                    name={peek ? "eye-slash" : "eye"}
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup
                              className="col-md-12 d-flex flex-column p-0"
                              style={{ marginBottom: "0.75em" }}
                            >
                              <Label>Confirm New Password</Label>
                              <div class="p-0 d-flex flex-row">
                                <Input
                                  className=""
                                  type={confirm_peek ? "text" : "password"}
                                  value={this.state.confirmPassword}
                                  onChange={(e) => {
                                    let {
                                      USER_INFO,
                                      ERR_LIST,
                                      confirmPassword,
                                    } = this.state;
                                    let pass = e.target.value;
                                    let each = true;

                                    ERR_LIST.sixChar =
                                      pass.length < 6 ? true : false;
                                    ERR_LIST.hasUpper = !/[A-Z]/.test(pass)
                                      ? true
                                      : false;
                                    ERR_LIST.hasNumber = !/[0-9]/.test(pass)
                                      ? true
                                      : false;
                                    ERR_LIST.hasSpecial =
                                      !/^[a-zA-Z0-9-_]+$/.test(pass)
                                        ? true
                                        : false;

                                    this.setState({
                                      confirmPassword: pass,
                                      ERR_LIST,
                                    });

                                    // // console.log("NEW PASS", USER_INFO);
                                  }}
                                />
                                <div
                                  className="col d-flex flex-row cur-p align-items-center"
                                  style={{
                                    paddingRight: "0",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Icon
                                    onClick={() =>
                                      this.handlePeek("confirm_peek")
                                    }
                                    className="peek-btn"
                                    name={confirm_peek ? "eye-slash" : "eye"}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      {ERR_LIST.sixChar ||
                      ERR_LIST.hasUpper ||
                      ERR_LIST.hasNumber ||
                      ERR_LIST.hasSpecial ? (
                        <div class="col-md-12 p-0 pass-input-con m-0">
                          <Label>Should contain:</Label>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className={
                          ERR_LIST.sixChar ||
                          ERR_LIST.hasUpper ||
                          ERR_LIST.hasNumber ||
                          ERR_LIST.hasSpecial
                            ? "col-md-12 d-flex flex-column pass-err"
                            : "col-md-12 d-flex flex-column"
                        }
                      >
                        {this.state.ERR_LIST.sixChar ||
                        this.state.ERR_LIST.hasUpper ||
                        this.state.ERR_LIST.hasNumber ? (
                          <span>
                            At least
                            {this.state.ERR_LIST.sixChar ? (
                              <span className="fw600">
                                {" "}
                                six (6) characters long
                              </span>
                            ) : (
                              ""
                            )}
                            {this.state.ERR_LIST.sixChar &&
                            (this.state.ERR_LIST.hasUpper ||
                              this.state.ERR_LIST.hasNumber)
                              ? this.state.ERR_LIST.hasUpper &&
                                this.state.ERR_LIST.hasNumber
                                ? ", "
                                : " and "
                              : this.state.ERR_LIST.sixChar
                              ? "."
                              : " "}
                            {this.state.ERR_LIST.hasUpper ? (
                              <span className="fw600">
                                one (1) uppercase letter
                              </span>
                            ) : (
                              ""
                            )}
                            {(this.state.ERR_LIST.sixChar &&
                              this.state.ERR_LIST.hasNumber) ||
                            (this.state.ERR_LIST.hasUpper &&
                              this.state.ERR_LIST.hasNumber)
                              ? " and "
                              : this.state.ERR_LIST.sixChar
                              ? this.state.ERR_LIST.sixChar &&
                                (this.state.ERR_LIST.hasUpper ||
                                  this.state.ERR_LIST.hasNumber)
                                ? "."
                                : ""
                              : this.state.ERR_LIST.hasUpper
                              ? this.state.ERR_LIST.hasNumber
                                ? ""
                                : "."
                              : ""}
                            {this.state.ERR_LIST.hasNumber ? (
                              <span className="fw600">one (1) number</span>
                            ) : (
                              ""
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                        <span>
                          {this.state.ERR_LIST.hasSpecial ? (
                            <span>
                              <span class="fw600">No special characters</span>{" "}
                              except <span class="fw600">underscore '_'</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>

                      {/* <div class="col-md-12 p-0 d-flex flex-row">
                        <Input
                          type={confirm_peek ? "text" : "password"}
                          className="mt-2"
                          placeholder="Confirm new password"
                        />
                        <div
                          class="col d-flex flex-row cur-p align-items-center"
                          style={{ paddingRight: "0", paddingLeft: "10px" }}
                        >
                          <Icon
                            onClick={() => this.handlePeek("confirm_peek")}
                            style={{ marginTop: "0.5em" }}
                            className="peek-btn"
                            name={confirm_peek ? "eye-slash" : "eye"}
                          />
                        </div>
                      </div> */}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        className="fs13"
                        color="secondary"
                        onClick={() => this.handlePassModal()}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="fs13"
                        color="success"
                        onClick={() =>
                          this.state.showNewPass
                            ? this.handleChangePass()
                            : this.handleOldPass()
                        }
                      >
                        {this.state.showNewPass ? "SAVE" : "CONFIRM"}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
                <img
                  src={sbma_logo}
                  alt="sbma-logo"
                  className="nav-sbma-logo"
                />
              </div>
            </div>
          </div>

          <Sticky style={{ zIndex: "10" }}>
            <div className="eKonek-nav">
              <div className="top-nav">
                <Router component={Navbar}>
                  <ul>
                    <li className={this.getNavLinkClass("/dashboard")}>
                      <NavLink
                        activeClassName="active"
                        to="/dashboard"
                        onClick={() => this.props.history.push("/dashboard")}
                      >
                        <span className="nav-text">DASHBOARD</span>
                        <span className="nav-icon">
                          <NavHome />
                        </span>
                      </NavLink>
                    </li>
                    <li className={this.getNavLinkClass("/port_calls")}>
                      <Can
                        role={session.get().role}
                        perform="port-call:list"
                        yes={() => (
                          <NavLink
                            activeClassName="active"
                            to="/port_calls"
                            onClick={() =>
                              this.props.history.push("/port_calls")
                            }
                          >
                            <span className="nav-text">PORT CALLS</span>
                            <span className="nav-icon">
                              <NavPortCall />
                            </span>
                          </NavLink>
                        )}
                      />
                    </li>
                    <li className={this.getNavLinkClass("/berth_management")}>
                      <NavLink
                        activeClassName="active"
                        to="/berth_management"
                        onClick={() =>
                          this.props.history.push("/berth_management")
                        }
                      >
                        <span className="nav-text">BERTH MANAGEMENT</span>
                        <span className="nav-icon">
                          <NavBerth />
                        </span>
                      </NavLink>
                    </li>
                    <li className={this.getNavLinkClass("/vessels")}>
                      <NavLink
                        activeClassName="active"
                        to="/vessels"
                        onClick={() => this.props.history.push("/vessels")}
                      >
                        <span className="nav-text">VESSELS</span>
                        <span className="nav-icon">
                          <NavVessels />
                        </span>
                      </NavLink>
                    </li>
                    <li className={this.getNavLinkClass("/ais")}>
                      <NavLink
                        activeClassName="active"
                        to="/ais"
                        onClick={() => this.props.history.push("/ais")}
                      >
                        <span className="nav-text">AIS</span>
                        <span className="nav-icon">
                          <NavAis />
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </Router>
              </div>
            </div>
          </Sticky>
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar);
