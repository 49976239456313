import React, { Component } from "react";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import { Icon } from "react-fa";

import "./VesselExit.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import back_icon from "../../img/svg/BackIcon.svg";
import pdf_file from "../../img/svg/pdf-file.svg";
import api from "../../helpers/api.helper";
import { withRouter } from "react-router-dom";

class VesselExit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAudit: false,
      __ID: this.props.match.params.id,
      __port_call: {}
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call"
        })
        .then(data => {});
    }
  };

  handleAudit = () => {
    this.setState(prevState => ({
      showAudit: !prevState.showAudit
    }));
  };

  render() {
    const vd = this.state.__port_call;

    return (
      <div className="VesselExit">
        <div className="d-flex flex-column">
          <Navbar />
          <PortCallToolbar />
          <div
            md={{ size: 12 }}
            className="d-flex flex-column page-header"
            ref={this.pageHeader}
          >
            <Col md={{ size: 12 }} className="d-flex flex-row">
              <Col className="d-flex flex-row col-md-6">
                <img src={back_icon} alt="back_icon" className="" width="25" />
                <p className="vessel-name aligner justify-content-start">
                  {vd.vessel_name}, Voyage {vd.voyage_number}
                </p>
              </Col>
            </Col>
          </div>
          <Col md={{ size: 12 }} className="main-content d-flex flex-row mt-3">
            <Col md={{ size: 2 }}>
              <Sidenav dataFromParent={this.props.match.params.id} visit={vd} />
            </Col>

            <Col md={{ size: 10 }} className="mc-right d-flex flex-column">
              <Form
                className="d-flex flex-column"
                style={{ padding: "0 10px" }}
              >
                <div
                  className="form-container-box bt-green w100per"
                  style={{ margin: "0 5px" }}
                >
                  <div className="col-md-12 p-0 d-flex flex-row position-relative">
                    <div className="fcb-title">
                      Vessel Exit
                      <div className="d-flex flex-row headerStatus" style={{}}>
                        <div className="d-flex flex-column">
                          <span
                            className="hsLabel"
                            style={{ fontSize: "10px" }}
                          >
                            STATUS
                          </span>
                          <span className="hsText" style={{ color: "#FF9800" }}>
                            PENDING
                          </span>
                          {/* <span className="hsText"
                        style={{'color' : '#8BC34A'}}
                      >
                        CONFIRMED
                      </span> */}
                        </div>
                        <img
                          src={pdf_file}
                          alt="pdf_file"
                          style={{
                            width: "25px",
                            height: "25px",
                            margin: "4px 0 0 35px",
                            cursor: "pointer"
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row justify-content-end mt-3">
                    <p className="requiredWarning">
                      <Icon
                        className=""
                        name="exclamation-triangle"
                        width="13"
                      />
                      <span className="rwNote">NOTE: </span>FIELDS WITH (
                      <span style={{ color: "#F44336" }}>*</span>) ASTERISK ARE
                      REQUIRED
                    </p>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row">
                    <div className="fcb-title">Itinerary of Exit</div>
                  </div>
                  <div className="col-md-12 p-0 d-flex flex-row mt-2">
                    <FormGroup className="col-md-4">
                      <Label for="exampleText">Purpose of Exit</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="purpose_of_exit_input"
                        row="2"
                      />
                    </FormGroup>

                    <FormGroup className="col-md-4">
                      <Label for="exampleSelect">Next Port of Call</Label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        className="form-control-sm"
                      >
                        <option>-</option>
                      </Input>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <Label for="exampleDate">
                        Date and Time of Departure
                      </Label>
                      <Input
                        className="form-control-sm"
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                      />
                    </FormGroup>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-2">
                    <div className="fcb-title">Passenger Information</div>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-2">
                    <FormGroup className="col-md-4">
                      <Label for="">Disembarking</Label>
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                      />
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <Label for="">Embarking</Label>
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                      />
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <Label for="">Total Number of Crew</Label>
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                      />
                    </FormGroup>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-2">
                    <div className="fcb-title">Exit Mandatory Requirements</div>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-3 emReq">
                    <div className="col-md-3">
                      <p>Cargo Documents Cleared</p>
                    </div>

                    <FormGroup
                      tag="fieldset"
                      className="col-md-2"
                      style={{ display: "contents" }}
                    >
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                      >
                        <Label check>
                          <Input type="radio" name="cargo_docs_cleared" /> Yes
                        </Label>
                      </FormGroup>
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "1em" }}
                      >
                        <Label check>
                          <Input type="radio" name="cargo_docs_cleared" /> No
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup
                      className="col-md-4"
                      style={{ padding: "0 1.5em 0 2em" }}
                    >
                      <Input
                        className="form-control-sm"
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                      />
                    </FormGroup>

                    <div className="d-flex">
                      <Icon
                        className=""
                        name="file"
                        width="15"
                        style={{ paddingTop: "0.4em" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-2 emReq">
                    <div className="col-md-3">
                      <p>Port Charges Paid</p>
                    </div>

                    <FormGroup
                      tag="fieldset"
                      className="col-md-2"
                      style={{ display: "contents" }}
                    >
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                      >
                        <Label check>
                          <Input type="radio" name="pilotage_services_paid" />{" "}
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "1em" }}
                      >
                        <Label check>
                          <Input type="radio" name="port_charges_paid" /> No
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup
                      className="col-md-4"
                      style={{ padding: "0 1.5em 0 2em" }}
                    >
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="date"
                      />
                    </FormGroup>

                    <div className="d-flex">
                      <Icon
                        className=""
                        name="file"
                        width="15"
                        style={{ paddingTop: "0.4em" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-2 emReq">
                    <div className="col-md-3">
                      <p>Pilotage Services Paid</p>
                    </div>

                    <FormGroup
                      tag="fieldset"
                      className="col-md-2"
                      style={{ display: "contents" }}
                    >
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                      >
                        <Label check>
                          <Input type="radio" name="pilotage_services_paid" />{" "}
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "1em" }}
                      >
                        <Label check>
                          <Input type="radio" name="pilotage_services_paid" />{" "}
                          No
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup
                      className="col-md-4"
                      style={{ padding: "0 1.5em 0 2em" }}
                    >
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="date"
                      />
                    </FormGroup>

                    <div className="d-flex">
                      <Icon
                        className=""
                        name="file"
                        width="15"
                        style={{ paddingTop: "0.4em" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-2 emReq">
                    <div className="col-md-3">
                      <p>Vessel Pre-Departure Inspection</p>
                    </div>

                    <FormGroup
                      tag="fieldset"
                      className="col-md-2"
                      style={{ display: "contents" }}
                    >
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                      >
                        <Label check>
                          <Input
                            type="radio"
                            name="vessel_pre_depart_inspect"
                          />{" "}
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "1em" }}
                      >
                        <Label check>
                          <Input
                            type="radio"
                            name="vessel_pre_depart_inspect"
                          />{" "}
                          No
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup
                      className="col-md-4"
                      style={{ padding: "0 1.5em 0 2em" }}
                    >
                      <Input
                        className="form-control-sm"
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                      />
                    </FormGroup>

                    <div className="d-flex">
                      <Icon
                        className=""
                        name="file"
                        width="15"
                        style={{ paddingTop: "0.4em" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-2 emReq">
                    <div className="col-md-3">
                      <p>Penalties Paid</p>
                    </div>

                    <FormGroup
                      tag="fieldset"
                      className="col-md-2"
                      style={{ display: "contents" }}
                    >
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                      >
                        <Label check>
                          <Input type="radio" name="penalties_paid" /> Yes
                        </Label>
                      </FormGroup>
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "1em" }}
                      >
                        <Label check>
                          <Input type="radio" name="penalties_paid" /> No
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup
                      className="col-md-4"
                      style={{ padding: "0 1.5em 0 2em" }}
                    >
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="date"
                      />
                    </FormGroup>

                    <div className="d-flex">
                      <Icon
                        className=""
                        name="file"
                        width="15"
                        style={{ paddingTop: "0.4em" }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 p-0 d-flex flex-row mt-2 emReq">
                    <div className="col-md-3">
                      <p>Tugboat Services Paid</p>
                    </div>

                    <FormGroup
                      tag="fieldset"
                      className="col-md-2"
                      style={{ display: "contents" }}
                    >
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                      >
                        <Label check>
                          <Input type="radio" name="tugboat_services-paid" />{" "}
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup
                        check
                        className="d-flex justify-content-between"
                        style={{ marginLeft: "1em" }}
                      >
                        <Label check>
                          <Input type="radio" name="tugboat_services-paid" /> No
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup
                      className="col-md-4"
                      style={{ padding: "0 1.5em 0 2em" }}
                    >
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="date"
                      />
                    </FormGroup>

                    <div className="d-flex">
                      <Icon
                        className=""
                        name="file"
                        width="15"
                        style={{ paddingTop: "0.4em" }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

export default withRouter(VesselExit);
