import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../../../helpers/api.helper";
import constants from "../../../constants";
import moment from "moment";
import "./ArrivalTicker.scss";
import { ReactComponent as Recents } from "../svg/Recents.svg";

class RecentsTicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DASHBOARD_TICKER: [],
      RECENTS_TICKER: [],
    };
  }

  componentDidMount = () => {
    this.loadTicker();
  };

  loadTicker = () => {
    api
      .get(this, {
        endpoint: "dashboard/ticker",
        state_key: "dashboardTicker",
      })
      .then((data) => {
        // console.log("TICKER", data);
        this.setState({
          RECENTS_TICKER: data.recents,
        });
      });
  };

  render() {
    return (
      <>
        <div className="col-md-12 p0 tickerCon">
          <div class="col-md-12 d-flex flex-row align-items-center">
            <div class="col-md-6 p0 d-flex flex-row">
              <Recents />{" "}
              <p className="table-title d-flex align-items-center col-md-10 p0">
                RECENTS
              </p>
            </div>
            <div class="col-md-6 p0 d-flex flex-row justify-content-end">
              <div className="d-flex flex-column align-items-end date-con">
                <span className="date-no">
                  {moment(new Date()).format(constants.__timeFormat)}
                </span>
                <span className="date-label">
                  {moment(new Date()).format(constants.__dateFormat) +
                    " " +
                    moment(new Date()).format(constants.__dayFormat)}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center page-con">
                <span className="page-no">1</span>
                <span className="page-label">PAGE</span>
              </div>
            </div>
          </div>
          <table className="arrivalTable ticker-table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Vessel Name</th>
                <th style={{ width: "20%" }}>Arrival</th>
                <th style={{ width: "20%" }}>Departure</th>
                <th style={{ width: "20%" }}>Berth Laytime</th>
                <th style={{ width: "20%" }}>Port Laytime</th>
              </tr>
            </thead>
            <tbody>
              {this.state.RECENTS_TICKER.map((tr, i) => (
                <tr key={i}>
                  <td>{tr.vessel_name}</td>
                  <td>
                    {tr.eta ? (
                      <p className="m0">
                        <span className="fw600">ETD: </span>
                        {moment(tr.eta).format(constants.__dateTimeFormat)}
                      </p>
                    ) : (
                      ""
                    )}
                    {tr.ata ? (
                      <p className="m0" style={{ paddingTop: "0.5em" }}>
                        <span className="fw600">ATD: </span>
                        {moment(tr.ata).format(constants.__dateTimeFormat)}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {tr.etd ? (
                      <p className="m0">
                        <span className="fw600">ETD: </span>
                        {moment(tr.etd).format(constants.__dateTimeFormat)}
                      </p>
                    ) : (
                      ""
                    )}
                    {tr.atd ? (
                      <p className="m0" style={{ paddingTop: "0.5em" }}>
                        <span className="fw600">ATD: </span>
                        {moment(tr.atd).format(constants.__dateTimeFormat)}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default withRouter(RecentsTicker);
