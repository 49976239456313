import React, { Component } from "react";
import { Col, Button, Form, FormGroup, Label, Input, Table } from "reactstrap";
import { DropdownList } from "react-widgets";
import { Icon } from "react-fa";
import back_icon from "../../img/svg/BackIcon.svg";
import constants from "../../constants";
import "./CargoDetails.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import AlertModal from "../Tools/AlertModal/AlertModal";
import api from "../../helpers/api.helper";
import { withRouter } from "react-router-dom";
import BackToTop from "../Tools/BackToTop/BackToTop";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import PrintCoastingManifest from "./PrintCoastingManifest";
// import { threadId } from "worker_threads";

let pcID = "";

class CargoDetails extends Component {
  defaultCargoDetails = {
    product: "",
    description: "",
    total_qty: "",
    uom: "",
    vendor: "",
    isOpen: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      showAudit: false,
      showItemAcc: true,
      __port_call: {},
      __ID: this.props.match.params.id,
      __cargoDetail: [
        {
          product: "",
          description: "",
          total_qty: "",
          uom: "",
          vendor: "",
        },
      ],
      __uom: [],
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
      disableSave: false,
      prod_val: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
    this.loadConstants();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "portcall/" + this.state.__ID,
        state_key: "__port_call",
      })
      .then((data) => {
        // console.log("KARGO", data);
        let { __cargoDetail } = this.state;

        this.setState({ __cargoDetail: data.cargo_details });

        if (__cargoDetail.length < 1) {
          this.addCargo();
        }
      });

    // api
    //   .get(this, {
    //     endpoint: "cargo_details/" + this.state.__ID,
    //     state_key: "__cargoDetail"
    //   })
    //   .then(data => {});
  };

  loadConstants = () => {
    let uom_arr = [];

    for (let x in constants.uoms) {
      uom_arr[x] = constants.uoms[x].name;
    }

    this.setState({
      __uom: uom_arr,
    });
  };

  updateCargo = (val, index, key) => {
    const { __cargoDetail } = this.state;
    __cargoDetail[index][key] = val;
    this.setState({ __cargoDetail });
    // console.log(__cargoDetail);
  };

  addCargo = () => {
    const currCargo = this.state.__cargoDetail;

    this.setState({
      __cargoDetail: [...currCargo, { ...this.defaultCargoDetails }],
    });

    // console.log("CHECK", this.state.__cargoDetail);
  };

  rmvCargo = (i, prod) => {
    if (prod) {
      Swal.fire({
        icon: "question",
        title: "Delete " + prod + "?",
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            showConfirmButton: false,
            timer: 1500,
          });

          const currCargo = this.state.__cargoDetail;

          currCargo.splice(i, 1);
          this.setState({
            __cargoDetail: currCargo,
          });

          this.addCargo();
        }
      });
    } else {
      const currCargo = this.state.__cargoDetail;

      currCargo.splice(i, 1);
      this.setState({
        __cargoDetail: currCargo,
      });
    }
  };

  handleAudit = () => {
    this.setState((prevState) => ({
      showAudit: !prevState.showAudit,
    }));
  };

  handleItemAcc = (index) => {
    let { __cargoDetail } = this.state;

    if (__cargoDetail[index].isOpen) {
      __cargoDetail[index].isOpen = false;
    } else {
      __cargoDetail[index].isOpen = true;
    }

    this.setState({ __cargoDetail });
  };

  handleChange = (name, value, index) => {
    // console.log("ZZZZZZZ", name, value, index);

    let { __cargoDetail, __port_call } = this.state;

    __cargoDetail[index][name] = value;
    __port_call.cargo_details = __cargoDetail;

    this.setState({
      __port_call,
    });

    // console.log("CARGO", this.state.__cargoDetail);
    // console.log("ALL", this.state.__port_call);
  };

  handleSave = () => {
    this.showAlert("loading", "", true);
    this.setState({
      disableSave: true,
    });
    let { __cargoDetail, __port_call, prod_val } = this.state;

    // console.log("SEYB", __port_call);
    if (__cargoDetail.length !== 0) {
      api
        .update(this, {
          endpoint: "portcall/" + this.state.__ID,
          _data: { ...__port_call, has_coasting_manifest: true },
        })
        .then((data) => {
          // console.log("DEYTA", data);

          this.setState(
            {
              tglAlert: false,
              disableSave: false,
            },
            () =>
              this.showAlert(
                "success",
                "Coasting Manifest successfully saved!",
                true
              )
          );
        });
    } else {
      api
        .update(this, {
          endpoint: "portcall/" + this.state.__ID,
          _data: { ...__port_call, has_coasting_manifest: false },
        })
        .then((data) => {
          this.setState(
            {
              tglAlert: false,
              disableSave: false,
            },
            () =>
              this.showAlert(
                "success",
                "Coasting Manifest successfully saved!",
                true
              )
          );
        });
    }
    console.log("TEST", prod_val);
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  render() {
    const { __uom, tglAlert, isAlert, alertMsg, prod_val } = this.state;
    const vd = this.state.__port_call;
    const cd = this.state.__cargoDetail;

    // console.log("KARGOOO", cd);

    return (
      <div className="CargoHandler">
        <div className="d-flex flex-column">
          {tglAlert ? (
            <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
          ) : (
            ""
          )}

          <Navbar />

          <div
            md={{ size: 12 }}
            className="d-flex flex-column page-header"
            ref={this.pageHeader}
          >
            <Col md={{ size: 12 }} className="d-flex flex-row">
              <Col className="d-flex flex-row col-md-6">
                <img src={back_icon} alt="back_icon" className="" width="25" />
                <p className="vessel-name aligner justify-content-start">
                  {vd.vessel_name}, Voyage {vd.voyage_number}
                </p>
              </Col>
            </Col>
          </div>

          <Col md={{ size: 12 }} className="main-content d-flex flex-row mt-3">
            <Col md={{ size: 2 }}>
              <Sidenav
                dataFromParent={this.props.match.params.id}
                visit={vd}
                prodVal={prod_val}
              />
            </Col>

            <Col md={{ size: 10 }} className="mc-right d-flex flex-column">
              <Form
                className="d-flex flex-column"
                style={{ padding: "0 10px" }}
              >
                <div
                  className="form-container-box bt-green w100per"
                  style={{ margin: "0 5px" }}
                >
                  <div className="col-md-12 p-0 d-flex flex-row position-relative">
                    <div className="fcb-title">Coasting Manifest</div>
                  </div>
                </div>

                {cd.map((cd, i) => (
                  <div
                    key={i}
                    className="d-flex flex-column itemAccordion mt-3 w100per"
                    style={{ padding: "0" }}
                  >
                    <div className="form-container-box col-md-12 p-2 bt-green panel-heading d-flex flex-row">
                      <div
                        className="col-md-10 d-flex flex-row cur-p"
                        onClick={() => this.handleItemAcc(i)}
                      >
                        {cd.isOpen ? (
                          <Icon className="" name="angle-up" />
                        ) : (
                          <Icon className="" name="angle-down" />
                        )}

                        <span className="acc-title">{cd.product}</span>
                      </div>
                      <div className="col-md-2 pr-0 d-flex flex-row justify-content-end">
                        <Icon
                          className="cur-p delete-acc"
                          name="trash"
                          onClick={() => this.rmvCargo(i, cd.product)}
                        />
                      </div>
                    </div>

                    <div>
                      {cd.isOpen ? (
                        <div className="form-container-box d-flex flex-column">
                          <div className="col-md-12 d-flex flex-row">
                            <div className="col-md-6">
                              <FormGroup className="p-0">
                                <Label for="">Product</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="product"
                                  id=""
                                  placeholder=""
                                  value={cd.product}
                                  onChange={(e) => {
                                    let _name = e.target.name;
                                    let _value = e.target.value;
                                    let _index = i;

                                    this.handleChange(_name, _value, _index);

                                    this.setState({ prod_val: e.target.value });
                                  }}
                                />
                              </FormGroup>
                            </div>

                            <div className="col-md-6 d-flex flex-row">
                              <FormGroup className="col-md-9">
                                <Label for="">Total Quantity</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="total_qty"
                                  id=""
                                  placeholder=""
                                  value={cd.total_qty}
                                  onChange={(e) => {
                                    let _name = e.target.name;
                                    let _value = e.target.value;
                                    let _index = i;

                                    this.handleChange(_name, _value, _index);
                                  }}
                                />
                              </FormGroup>
                              <FormGroup
                                className="col-md-3 p-0"
                                style={{ marginTop: "1.5em" }}
                              >
                                <DropdownList
                                  filter
                                  value={cd.uom}
                                  name="uom"
                                  textField="uom"
                                  data={__uom}
                                  onChange={(uom_value) => {
                                    let _name = "uom";
                                    let _value = uom_value;
                                    let _index = i;

                                    this.handleChange(_name, _value, _index);
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </div>

                          <div className="col-md-12 d-flex flex-row">
                            <div className="col-md-6">
                              <FormGroup className="">
                                <Label for="">Description</Label>
                                <Input
                                  className="form-control-sm fs13"
                                  type="textarea"
                                  name="description"
                                  id=""
                                  placeholder=""
                                  value={cd.description}
                                  onChange={(e) => {
                                    let _name = e.target.name;
                                    let _value = e.target.value;
                                    let _index = i;

                                    this.handleChange(_name, _value, _index);
                                  }}
                                />
                              </FormGroup>
                            </div>

                            <div
                              className="col-md-6"
                              style={{ paddingLeft: "2em" }}
                            >
                              <FormGroup className="p-0">
                                <Label for="">Consignee</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="vendor"
                                  id=""
                                  placeholder=""
                                  value={cd.vendor}
                                  onChange={(e) => {
                                    let _name = e.target.name;
                                    let _value = e.target.value;
                                    let _index = i;

                                    this.handleChange(_name, _value, _index);
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                ))}

                <div className="w100per" style={{ padding: "15px 0 0 0" }}>
                  <Button
                    className="btn-sm fs13"
                    color="primary"
                    onClick={this.addCargo}
                  >
                    ADD PRODUCT
                  </Button>
                </div>
                <div className="col-md-12 footer-actions d-flex flex-row">
                  <div className="col-md-12 p-0 d-flex flex-row justify-content-end">
                    <PrintCoastingManifest ID={this.state.__ID} />
                    <Button
                      onClick={() => this.handleSave()}
                      className={
                        this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"
                      }
                      color="success"
                      style={{ margin: "0 0.5em" }}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
          </Col>
        </div>
        <BackToTop />
      </div>
    );
  }
}

export default withRouter(CargoDetails);
