import React, { Component, useState } from "react";
import Navbar from "../Navbar/Navbar";
import "./Notifications.scss";
import ReactTimeAgo from "react-time-ago";
import { Icon } from "react-fa";
import { Tooltip } from "reactstrap";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import api from "../../helpers/api.helper";
import portWebsocket from "../../portWebsocket";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";

TimeAgo.addLocale(en);
// const timeAgo = new TimeAgo("en-US");

class NotificationsListClass extends Component {
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      notifications: []
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    let component = this;

    api
      .get(this, {
        endpoint: "notification/" + Cookies.get("uid"),
        state_key: "notifications"
      })
      .then(data => {
        portWebsocket.events.notification = oMsg => {
          component.loadData();
        };
      });
  };

  handleReadUpdate = (index, notif) => {
    if (index !== undefined && notif) {
      let notifications = this.state.notifications,
        component = this;

      if (notifications[index]) {
        notifications[index].unread = false;

        api
          .update(this, {
            endpoint: "notification/" + notif._id,
            _data: notifications[index]
          })
          .then(v => {
            component.setState({
              notifications: notifications
            });
            component.props.history.push(notifications[index].route);
          });
      }
    }
  };

  render() {
    const notif_actions = [
      { id: 0, name: "submitted" },
      { id: 1, name: "approved" },
      { id: 2, name: "returned" },
      { id: 3, name: "posted" }
    ];

    const { notifications } = this.state;

    const MarkAsRead = props => {
      const [tooltipOpen, setTooltipOpen] = useState(false);
      const toggle = () => setTooltipOpen(!tooltipOpen);

      let id_prefix = "markAsRead-";
      if (props.fullpage) {
        id_prefix = "markAsRead-fp-";
      }

      return (
        <>
          <div className="read-mark" id={id_prefix + props.sig}></div>
          <Tooltip
            placement="left"
            isOpen={tooltipOpen}
            target={id_prefix + props.sig}
            toggle={toggle}
          >
            Mark As {props.unread ? "Read" : "Unread"}
          </Tooltip>
        </>
      );
    };

    return (
      <ul
        className={
          this.props.fullPage ? "notifs-list full-page" : "notifs-list"
        }
      >
        {notifications
          .sort((a, b) => {
            return new Date(b.timestamp) - new Date(a.timestamp);
          })
          .map((a, i) => (
            <li
              key={i}
              className={a.unread ? "unread" : ""}
              onClick={e => {
                this.handleReadUpdate(i, a);
              }}
            >
              {/* {
              let sched = plans_data.some(e => e.id === this.state.__ID)
                ? plans_data.find(e => e.id === this.state.__ID)
                : [];
            } */}
              <div className="notif-pic">
                {/* Replace with Notif Author's Avatar */}
                <img src={"https://i.pravatar.cc/150?u=" + a.id} alt="" />
              </div>
              <div className="col notif-content">
                <div className="notif-message">
                  {a.action === 3 ? (
                    <>
                      <span>
                        {a.title}
                        <Icon name="ship" />
                      </span>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  <span>{a.author.first_name + " " + a.author.last_name}</span>{" "}
                  {a.description}
                </div>
                <div className="notif-timestamp">
                  <div
                    className={
                      a.action === 0
                        ? "notif-icon submit"
                        : a.action === 1
                        ? "notif-icon approve"
                        : a.action === 2
                        ? "notif-icon return"
                        : "notif-icon note"
                    }
                  >
                    <Icon
                      name={
                        a.action === 0
                          ? "ellipsis-h"
                          : a.action === 1
                          ? "check"
                          : a.action === 2
                          ? "rotate-left"
                          : "comment"
                      }
                    />
                  </div>
                  <span>&middot;</span>
                  <ReactTimeAgo date={a.timestamp} />
                </div>
              </div>
              <div className="notif-tools">
                <MarkAsRead
                  unread={a.unread}
                  sig={a.id}
                  fullpage={this.props.fullPage}
                />
              </div>
            </li>
          ))}
      </ul>
    );
  }
}

export const NotificationsList = withRouter(NotificationsListClass);

class NotificationsPage extends Component {
  render() {
    return (
      <div className="flex-column">
        <Navbar />
        <div className="flex-column notifs-page">
          <h1>Your Notifications</h1>
          <NotificationsList fullPage />
        </div>
      </div>
    );
  }
}

export default withRouter(NotificationsPage);
