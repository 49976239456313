import React, { Component } from "react";
import { Row, Col, Button, Table, Form, Input } from "reactstrap";
import { Icon } from "react-fa";
import back_icon from "../../img/svg/BackIcon.svg";
import folder_empty from "../../img/svg/folder-empty.svg";
import pdf_colored from "../../img/svg/pdf-colored.svg";
import "./DocumentManagement.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import { API_URL } from "../../config";
import api from "../../helpers/api.helper";
import { withRouter } from "react-router-dom";
import constants from "../../constants";
import Media from "react-media";
import PageHeader from "../Tools/PageHeader/PageHeader";

class DocumentManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAudit: false,
      isFolderActive: false,
      isFileActive: false,
      show_folder_textbox: false,
      show_upload: false,
      __ID: this.props.match.params.id,
      __port_call: {},
      folders: [],
      dir_name: "",
      files: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call",
        })
        .then((data) => {
          this.getFolders();
        });
    }
  };

  handleAudit = () => {
    this.setState((prevState) => ({
      showAudit: !prevState.showAudit,
    }));
  };

  handleIsFolderActive = (folder_id) => {
    this.setState((prevState) => ({
      // isFolderActive: !prevState.isFolderActive,
      selected_parent_id: folder_id,
    }));
    this.getFiles(folder_id);

    let _folders = this.state.folders;

    for (let x in _folders) {
      let folder = _folders[x];
      folder.is_active = false;
      if (folder._id === folder_id) {
        folder.is_active = true;
      }
    }
  };

  handleIsFileActive = (file) => {
    const { files } = this.state;
    files.forEach((f, i) => {
      if (file._id !== f._id) {
        files[i].is_active = false;
      } else {
        files[i].is_active = true;
      }
    });
    this.setState({ files });
    const filePath = file.physical_path.split("/");
    if (filePath.length > 0) {
      window.open(
        `${API_URL}file_manager/download/${filePath[filePath.length - 1]}`
      );
    }
  };

  onChangeHandler = (event) => {
    this.setState({
      file_upload: event.target.files[0],
    });
  };

  handleUpload = (event) => {
    const data = new FormData();

    if (this.state.file_upload && this.state.selected_parent_id) {
      data.append("file", this.state.file_upload);
      api.save(this, {
        endpoint:
          "file_manager/upload/" +
          this.state.__ID +
          "/" +
          this.state.selected_parent_id,
        _data: data,
        state_key: "files",
        append: true,
        append_from_response: true,
      });
    } else {
      // put error emssage here
    }
  };

  handleShowTextBox = () => {
    // let { show_folder_textbox } = this.state;
    // show_folder_textbox = true;
    // this.setState({ show_folder_textbox });
    this.setState((prevState) => ({
      show_folder_textbox: !prevState.show_folder_textbox,
    }));
  };

  handleShowUpload = () => {
    this.setState((prevState) => ({
      show_upload: !prevState.show_upload,
    }));
  };

  getFolders = () => {
    api.get(this, {
      endpoint: "file_manager/" + this.state.__ID,
      state_key: "folders",
      request_params: { search: { type: "d" } },
    });
  };

  handleSaveDir = (e) => {
    e.preventDefault();
    let { dir_name } = this.state;
    api
      .save(this, {
        endpoint: "file_manager/make_dir/" + this.state.__ID,
        _data: { name: dir_name },
        state_key: "folders",
        append: true,
      })
      .then((response) => {
        this.setState({ dir_name: "" });
      });
  };

  getFiles = (parent_id) => {
    if (parent_id) {
      api.get(this, {
        endpoint: "file_manager/" + this.state.__ID + "/" + parent_id,
        state_key: "files",
        request_params: { search: { type: "f" } },
      });
    }
  };

  render() {
    const {
      showAudit,

      show_folder_textbox,
      show_upload,
    } = this.state;
    const vd = this.state.__port_call;

    return (
      <div className="DocumentManagement">
        <div className="d-flex flex-column">
          <Navbar />

          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav
                  dataFromParent={this.props.match.params.id}
                  visit={vd}
                />
              ) : (
                <PageHeader
                  hideDocToggle
                  sendVName={vd.vessel_name}
                  sendVoyageNo={vd.voyage_number}
                />
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      visit={vd}
                    />
                  </Col>
                ) : (
                  <PageHeader
                    hideDocToggle
                    sendVName={vd.vessel_name}
                    sendVoyageNo={vd.voyage_number}
                    pagename="Document Management"
                  />
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="form-container-box bt-green">
                  <div className="col-md-12 fcb-title">Document Management</div>

                  <Row xs="1" md="2">
                    <Col>
                      <div className="folders-con">
                        <div className="foldersTitle">
                          <div>
                            <p>Folders</p>
                            <Icon
                              name={show_folder_textbox ? "minus" : "plus"}
                              className="add-folder"
                              onClick={this.handleShowTextBox}
                            />
                          </div>
                        </div>
                        {show_folder_textbox ? (
                          <div className="input-folder">
                            <p className="col-md-9">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name=""
                                id=""
                                placeholder=""
                                onChange={(e) => {
                                  let { dir_name } = this.state;
                                  dir_name = e.target.value;
                                  this.setState({ dir_name });
                                }}
                              />
                            </p>
                            <Button
                              className="btn-sm btn col-md-3"
                              color="success"
                              onClick={this.handleSaveDir}
                            >
                              ADD FOLDER
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.folders.length === 0 ? (
                          <div className="no-files">
                            <span>
                              <img
                                src={folder_empty}
                                alt="folder_empty"
                                className=""
                                width="15"
                                style={{ marginRight: "5px" }}
                              />
                              No Folders
                            </span>
                          </div>
                        ) : (
                          <div className="folderList">
                            {this.state.folders.map((folder, $index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    this.handleIsFolderActive(folder._id);
                                  }}
                                  className={
                                    folder.is_active
                                      ? "active folderItem"
                                      : "folderItem"
                                  }
                                >
                                  <img
                                    src={folder_empty}
                                    alt="folder_empty"
                                    className=""
                                    width="15"
                                  />
                                  <p className="folderName">{folder.name}</p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col>
                      <div className="folders-con">
                        <div className="foldersTitle">
                          <div>
                            <p>Files</p>
                            <Icon
                              name={show_upload ? "minus" : "plus"}
                              className="add-folder"
                              onClick={this.handleShowUpload}
                            />
                          </div>
                        </div>
                        {show_upload ? (
                          <Form>
                            <div className="input-folder">
                              <input
                                className="col-md-9"
                                type="file"
                                name="file"
                                onChange={this.onChangeHandler}
                              />
                              <Button
                                className="btn-sm col-md-3"
                                color="success"
                                onClick={this.handleUpload}
                              >
                                ADD FILE
                              </Button>
                            </div>
                          </Form>
                        ) : (
                          ""
                        )}

                        {this.state.files.length === 0 ? (
                          <div className="no-files">
                            <span>
                              <img
                                src={folder_empty}
                                alt="folder_empty"
                                className=""
                                width="15"
                                style={{ marginRight: "5px" }}
                              />
                              No Files
                            </span>
                          </div>
                        ) : (
                          <div className="folderList">
                            {this.state.files.map((file, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    file.is_active
                                      ? "active folderItem"
                                      : "folderItem"
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleIsFileActive(file);
                                  }}
                                >
                                  <img
                                    src={pdf_colored}
                                    alt="pdf_colored"
                                    className=""
                                    width="15"
                                  />
                                  <p className="folderName">{file.name}</p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="form-container-box bt-green">
                  <div className="col-md-12 d-flex flex-row auditHead">
                    {showAudit ? (
                      <Icon
                        className=""
                        name="angle-up"
                        onClick={this.handleAudit}
                      />
                    ) : (
                      <Icon
                        className=""
                        name="angle-down"
                        onClick={this.handleAudit}
                      />
                    )}

                    <p>Audit Trails</p>
                  </div>

                  <div>
                    {showAudit ? (
                      <Col md={{ size: 12 }} className="p-0">
                        <Table
                          className="custom-table"
                          bordered
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Details</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(DocumentManagement);
