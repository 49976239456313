import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { BrowserRouter as Router, withRouter, NavLink } from "react-router-dom";
import "./Sidenav.scss";
import Icon from "react-fa";
import Can from "../Can/Can";
import session from "../../session";
import Swal from "sweetalert2";
import api from "../../helpers/api.helper";
import { slide as Menu } from "react-burger-menu";
import constants from "../../constants";
import Media from "react-media";

class Sidenav extends Component {
  constructor(props) {
    super(props);

    // console.log(props);

    this.state = {
      preDep_create: true,
      eManifest_is_complete: false,
      tooltipOpen: false,
      _viewConfirmManifest: false,
      _viewVEApplication: false,
      _preDep: false,
      _destId: "",
      isAlert: "",
      alertMsg: "",
      confirmManifest: false,
      enable_depart_link: false,
      scrolled: false,
      isNavOpen: false,
    };
  }

  componentDidMount = () => {
    if (
      window.location.href.match(
        "/port_call/departure_details/" + this.props.dataFromParent
      )
    ) {
      this.setState({
        enable_depart_link: true,
      });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({
      tooltipOpen: !prevState.tooltipOpen,
    }));
  };

  toggleConfirmManifest = () => {
    // this.setState(prevState => ({
    //   _viewConfirmManifest: !prevState._viewConfirmManifest
    // }));
    // console.log("CONFIRM!");
    Swal.fire({
      icon: "question",
      title: "Confirm eManifest submission?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Confirmed!",
        });

        if (this.props.visit.is_manifest_complete === false) {
          api.update(this, {
            endpoint: "portcall/" + this.props.dataFromParent,
            _data: { is_manifest_complete: true },
          });
        }

        this.setState({
          confirmManifest: true,
        });
      }
    });
  };

  toggleConfirmDeparture = () => {
    Swal.fire({
      icon: "question",
      title: "Do you want to create Vessel Exit Application?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Confirmed!",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          enable_depart_link: true,
        });

        this.props.history.push(
          "/port_call/departure_details/" + this.props.dataFromParent
        );
      }
    });
  };

  toggleVEApplication = (id) => {
    this.setState((prevState) => ({
      _viewVEApplication: !prevState._viewVEApplication,
      _destId: id,
    }));
  };

  togglePreDep = () => {
    this.setState((prevState) => ({
      _preDep: !prevState._preDep,
    }));
  };

  render() {
    const __ID = this.props.dataFromParent;
    const DO_LATER = this.props.doLater;
    const vd = this.props.visit;
    const {
      _viewConfirmManifest,
      _viewVEApplication,
      enable_depart_link,
      scrolled,
      isNavOpen,
    } = this.state;
    const berth_assigned = this.props.berthAssigned;
    const prod = this.props.prodVal;
    const userSession = localStorage.getItem("port_session");
    // const cd = vd.cargo_details;
    // console.log("HEY", cd);

    const SidenavCont = (props) => {
      return (
        <Media queries={constants.media_queries}>
          {(matches) =>
            matches.xs ? (
              <div>{props.children}</div>
            ) : (
              <div className="sBarUl-con">{props.children}</div>
            )
          }
        </Media>
      );
    };

    const SideNavContent = (props) => {
      return (
        <>
          <Router>
            <SidenavCont>
              <ul>
                {__ID !== undefined ? (
                  <li className="fwb">
                    <NavLink
                      to={"/port_call/visit_detail/" + __ID}
                      onClick={() =>
                        this.props.history.push(
                          "/port_call/visit_detail/" + __ID
                        )
                      }
                    >
                      Dashboard
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                <li className="fwb pen">
                  <span className="sbtitle">Pre - Arrival</span>
                </li>
                <Can
                  role={session.get().role}
                  perform="sidenav-vessel-detail"
                  yes={() => (
                    <li className="step">
                      <div className="check-con">
                        <Can
                          role={session.get().role}
                          perform="side-cue:vessel-detail"
                          yes={() =>
                            vd && vd._id ? (
                              <Icon name="check" />
                            ) : (
                              <div className="no-fill-check"></div>
                            )
                          }
                        />
                      </div>
                      <NavLink
                        to={
                          !__ID
                            ? "/port_call/create_vessel_entry"
                            : "/port_call/vessel_entry/" + __ID
                        }
                        onClick={() =>
                          this.props.history.push(
                            !__ID
                              ? "/port_call/create_vessel_entry"
                              : "/port_call/vessel_entry/" + __ID
                          )
                        }
                      >
                        Vessel Detail
                      </NavLink>
                    </li>
                  )}
                />
                <Can
                  role={session.get().role}
                  perform="sidenav-pro-call-detail"
                  yes={() => (
                    <li className="step">
                      <div className="check-con">
                        <Can
                          role={session.get().role}
                          perform="side-cue:purpose-of-port-call"
                          yes={() =>
                            vd &&
                            vd.selected_port_call_purposes &&
                            vd.selected_port_call_purposes.length > 0 ? (
                              <Icon name="check" />
                            ) : (
                              <div className="no-fill-check"></div>
                            )
                          }
                        />
                      </div>

                      <NavLink
                        // to={
                        //   !__ID
                        //     ? "/port_call/create_in_port_services"
                        //     : "/port_call/in_port_services/" + __ID
                        // }
                        // onClick={() =>
                        //   this.props.history.push(
                        //     !__ID
                        //       ? "/port_call/create_in_port_services"
                        //       : "/port_call/in_port_services/" + __ID
                        //   )
                        // }
                        className={!__ID ? "disabled" : ""}
                        to={"/port_call/in_port_services/" + __ID}
                        onClick={() =>
                          this.props.history.push(
                            "/port_call/in_port_services/" + __ID
                          )
                        }
                      >
                        Purpose of Port Call
                      </NavLink>
                    </li>
                  )}
                />
                <Can
                  role={session.get().role}
                  perform="sidenav-crew-details"
                  yes={() => (
                    <li className="step">
                      <div className="check-con p-rel">
                        <Can
                          role={session.get().role}
                          perform="side-cue:crew-details"
                          yes={() =>
                            DO_LATER || vd.do_later ? (
                              <Icon name="exclamation-triangle" />
                            ) : vd.total_number_of_pa_crew ? (
                              <Icon name="check" />
                            ) : (
                              <div className="no-fill-check"></div>
                            )
                          }
                        />
                      </div>
                      <NavLink
                        className={!__ID ? "disabled" : ""}
                        to={
                          !__ID
                            ? "/port_call/create_crew_list"
                            : "/port_call/crew_list_pre_arrival/" + __ID
                        }
                        onClick={() =>
                          this.props.history.push(
                            !__ID
                              ? "/port_call/create_crew_list"
                              : "/port_call/crew_list_pre_arrival/" + __ID
                          )
                        }
                      >
                        Crew Details
                      </NavLink>
                    </li>
                  )}
                />
                <Can
                  role={session.get().role}
                  perform="sidenav-general-manifest:view"
                  yes={() => (
                    <>
                      <li className="fwb pen">
                        <span className="sbtitle">General Manifest</span>
                      </li>
                      <li
                        className={
                          !__ID || vd.is_local ? "step disabled" : "step"
                        }
                      >
                        <div
                          className={
                            vd.is_manifest_complete
                              ? "pen check-con cur-p"
                              : "check-con cur-p"
                          }
                        >
                          {/* <div className="nofill"></div> */}
                          <div
                            id="e_manifest"
                            className="p-rel"
                            onClick={this.toggleConfirmManifest}
                          >
                            {vd.is_manifest_complete ? (
                              <Icon name="check" />
                            ) : (
                              <div className="no-fill-check"></div>
                            )}
                            {/* <div className="no-fill-check"></div> */}
                          </div>
                        </div>

                        <NavLink
                          className={!__ID || vd.is_local ? "disabled" : ""}
                          to={
                            !__ID ? "portcalls" : "/port_call/manifest/" + __ID
                          }
                          onClick={() =>
                            this.props.history.push(
                              !__ID
                                ? "/port_calls"
                                : "/port_call/manifest/" + __ID
                            )
                          }
                        >
                          eManifest System
                        </NavLink>
                      </li>
                      <li className="step">
                        <div className="check-con">
                          {vd.has_coasting_manifest || prod ? (
                            <Icon name="check" />
                          ) : (
                            <div className="no-fill-check"></div>
                          )}
                        </div>
                        <NavLink
                          className={vd.is_local ? "" : "disabled"}
                          to={"/port_call/cargo_details/" + __ID}
                          onClick={() =>
                            this.props.history.push(
                              "/port_call/cargo_details/" + __ID
                            )
                          }
                        >
                          Coasting Manifest
                        </NavLink>
                      </li>
                    </>
                  )}
                />
                <Can
                  role={session.get().role}
                  perform="sidenav-berth-management:view"
                  yes={() => (
                    <>
                      <li className="fwb pen">
                        <span className="sbtitle">Berth Management</span>
                      </li>
                      <li className="step">
                        <div className="check-con">
                          <Can
                            role={session.get().role}
                            perform="side-cue:berth-assignment"
                            yes={() =>
                              vd.is_berth_assigned || berth_assigned ? (
                                <Icon name="check" />
                              ) : (
                                <div className="no-fill-check"></div>
                              )
                            }
                          />
                        </div>
                        <NavLink
                          to={"/port_call/berth_plans/" + __ID}
                          onClick={() =>
                            this.props.history.push(
                              "/port_call/berth_plans/" + __ID
                            )
                          }
                        >
                          Berth Assignment
                        </NavLink>
                      </li>
                      <li className="step">
                        <div className="check-con"></div>
                        <NavLink
                          to={"/port_call/anchorage/" + __ID}
                          onClick={() =>
                            this.props.history.push(
                              "/port_call/anchorage/" + __ID
                            )
                          }
                        >
                          Anchorage
                        </NavLink>
                      </li>
                    </>
                  )}
                />
                <Can
                  role={session.get().role}
                  perform="sidenav-resource-management:manage"
                  yes={() => (
                    <li className="step">
                      <div className="check-con">
                        {vd.has_resource_management ? (
                          <Icon name="check" />
                        ) : (
                          <div className="no-fill-check"></div>
                        )}
                      </div>
                      <NavLink
                        to={"/port_call/resource_plan/" + __ID}
                        onClick={() =>
                          this.props.history.push(
                            "/port_call/resource_plan/" + __ID
                          )
                        }
                      >
                        Resource Management
                      </NavLink>
                    </li>
                  )}
                />

                {enable_depart_link || vd.has_departure_details ? (
                  <Can
                    role={session.get().role}
                    perform="sidenav-pre-departure:view"
                    yes={() => (
                      <>
                        <li className="fwb d-flex flex-row">
                          <a href="#">Pre - Departure</a>
                        </li>
                        {/* <li className={!_preDep ? "step pen" : "step"}> */}
                        <li className="step">
                          <div className="check-con">
                            {vd.has_departure_details ? (
                              <Icon name="check" />
                            ) : (
                              <div className="no-fill-check"></div>
                            )}
                          </div>
                          <NavLink
                            className={
                              enable_depart_link || vd.has_departure_details
                                ? ""
                                : "disabled"
                            }
                            to={"/port_call/departure_details/" + __ID}
                            onClick={() =>
                              this.props.history.push(
                                "/port_call/departure_details/" + __ID
                              )
                            }
                          >
                            Departure Details
                          </NavLink>
                        </li>
                        <li className="step">
                          <div className="check-con">
                            {vd.total_number_of_pd_crew ? (
                              <Icon name="check" />
                            ) : (
                              <div className="no-fill-check"></div>
                            )}
                          </div>
                          <NavLink
                            className={
                              vd.has_departure_details ? "" : "disabled"
                            }
                            to={"/port_call/crew_list_pre_departure/" + __ID}
                            onClick={() =>
                              this.props.history.push(
                                "/port_call/crew_list_pre_departure/" + __ID
                              )
                            }
                          >
                            Crew Details
                          </NavLink>
                        </li>
                        <Can
                          role={session.get().role}
                          perform="departure-approval:endorse"
                          yes={() => (
                            <li className="step">
                              <div className="check-con">
                                {/* <Icon name="check" /> */}
                                {vd.is_port_tarrifs_filled_out ? (
                                  <Icon name="check" />
                                ) : (
                                  <div className="no-fill-check"></div>
                                )}
                              </div>
                              <NavLink
                                className={
                                  !vd.total_number_of_pd_crew ? "disabled" : ""
                                }
                                to={"/port_call/port_tariffs/" + __ID}
                                onClick={() =>
                                  this.props.history.push(
                                    "/port_call/port_tariffs/" + __ID
                                  )
                                }
                              >
                                Port Tariffs & Services
                              </NavLink>
                            </li>
                          )}
                        >
                          {" "}
                        </Can>
                      </>
                    )}
                  />
                ) : (
                  ""
                )}

                <Can
                  role={session.get().role}
                  perform="sidenav-document-management:view"
                  yes={() => (
                    <li className="fwb">
                      <NavLink
                        to={"/port_call/document_management/" + __ID}
                        onClick={() =>
                          this.props.history.push(
                            "/port_call/document_management/" + __ID
                          )
                        }
                      >
                        Document Management
                      </NavLink>
                    </li>
                  )}
                />
              </ul>

              <ul className="sBar-footer">
                <Can
                  role={session.get().role}
                  perform="sidenav-pre-departure:view"
                  yes={() =>
                    vd && vd.has_departure_details ? (
                      ""
                    ) : vd &&
                      vd.company_id === userSession &&
                      userSession.company_id ? (
                      <li
                        className={
                          !__ID
                            ? "disabled no-active preDepbtn"
                            : "no-active preDepbtn"
                        }
                        onClick={this.toggleConfirmDeparture}
                      >
                        Create Departure Details
                      </li>
                    ) : (
                      ""
                    )
                  }
                />

                <li className="hr-div my-3 ml-0"></li>

                <li>
                  <NavLink
                    to={"/port_call/inbox/" + __ID}
                    onClick={() =>
                      this.props.history.push("/port_call/inbox/" + __ID)
                    }
                  >
                    Inbox
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/port_call/amendments/" + __ID}
                    onClick={() =>
                      this.props.history.push("/port_call/amendments/" + __ID)
                    }
                  >
                    Amendments
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/port_call/vessel_movement_logs/" + __ID}
                    onClick={() =>
                      this.props.history.push(
                        "/port_call/vessel_movement_logs/" + __ID
                      )
                    }
                  >
                    Vessel Movement Logs
                  </NavLink>
                </li>

                <li className="hr-div my-3 ml-0"></li>

                <Can
                  role={session.get().role}
                  perform="sidenav-portcall:delete"
                  yes={() => (
                    <li className="color-aesi-red-lighten fs12">
                      Delete Transaction
                    </li>
                  )}
                />
              </ul>
            </SidenavCont>
          </Router>

          <Modal
            keyboard={false}
            backdrop="static"
            isOpen={_viewConfirmManifest}
            toggle={this.toggleConfirmManifest}
            className=""
          >
            <ModalHeader className="fs13" toggle={this.toggleConfirmManifest}>
              {/* Confirm eManifest Submission? */}
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
              <p className="m-0">Confirm eManifest submission?</p>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex flex-row">
                <Button
                  className="btn-sm ml-auto mr-1"
                  color="danger"
                  onClick={this.toggleConfirmManifest}
                >
                  CANCEL
                </Button>
                <Button className="btn-sm ml-auto" color="primary">
                  CONFIRM
                </Button>
              </div>
            </ModalFooter>
          </Modal>

          <Modal
            keyboard={false}
            backdrop="static"
            isOpen={_viewVEApplication}
            toggle={this.toggleVEApplication}
            className=""
          >
            <ModalHeader className="fs13" toggle={this.toggleVEApplication}>
              {/* Confirm Vessel Exit Application Creation? */}
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
              <p className="m-0">
                Do you want to create Vessel Exit Application?
              </p>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex flex-row">
                <Button
                  className="btn-sm ml-auto mr-1"
                  color="danger"
                  onClick={this.toggleVEApplication}
                >
                  NO
                </Button>
                <NavLink
                  to={"/port_call/departure_details/" + this.state._destId}
                >
                  <Button
                    className="btn-sm ml-auto"
                    color="primary"
                    onClick={this.togglePreDep}
                  >
                    YES
                  </Button>
                </NavLink>
              </div>
            </ModalFooter>
          </Modal>
        </>
      );
    };

    return (
      <>
        <Media queries={constants.media_queries}>
          {(matches) =>
            !matches.xs ? (
              <div className="sidenav">
                {/* {isAlert ? <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} /> : ""} */}
                <SideNavContent />
              </div>
            ) : (
              <>
                <Menu
                  disableCloseOnEsc
                  width={"280px"}
                  className={
                    scrolled ? "VisitDetail-nav scrolled" : "VisitDetail-nav"
                  }
                >
                  <SideNavContent />
                </Menu>
              </>
            )
          }
        </Media>
      </>
    );
  }
}

export default withRouter(Sidenav);
