import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./VesselExitPDF.scss";
import sbma_logo from "../../../img/Sbma_logo.png";
import api from "../../../helpers/api.helper";
import constants from "../../../constants";
import moment from "moment";
import Moment from "react-moment";
class VesselExitPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ID: this.props.pcID,
      EXIT_CLEARANCE: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "exit_clearance/" + this.state.ID,
        state_key: "EXIT_CLEARANCE",
      })
      .then((data) => {});
  };

  render() {
    const pc = this.props.visit;
    const ec = this.state.EXIT_CLEARANCE;
    const today = new Date();

    return (
      <div className="VEC-container">
        <img className="sbma-logo" src={sbma_logo} alt={sbma_logo} />

        <div className="subic-name">
          <h4>SUBIC BAY METROPOLITAN AUTHORITY</h4>
          <h5>Subic Bay Freeport Zone, Philippines</h5>
        </div>

        <div className="dept-header">
          <p>Department Quality Form</p>
          <p>SED-VENC-39</p>
          <p>Revision No. 02</p>
          <p>
            Effectivity Date:{" "}
            {moment(today.setDate(today.getDate() + 1)).format(
              constants.__dateFormat
            )}
          </p>
        </div>

        <div className="control-no">
          <p style={{ fontWeight: "bold", fontSize: "8px" }}>CONTROL NO.</p>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>-</p>
        </div>

        <div className="col-md-12 d-flex flex-row justify-content-center">
          <div className="vec-title">
            <h5>VESSEL EXIT CLEARANCE</h5>
          </div>
        </div>

        <div className="mt-4">
          <table className="table-pdf">
            <tr style={{ verticalAlign: "middle" }}>
              <td colSpan="3" className="p-0">
                <div className="tbl-title fs15">SHIP PARTICULARS</div>
              </td>
            </tr>
            <tr>
              <td>
                NAME OF VESSEL: <br />
                <b>{pc.vessel_name}</b>
              </td>
              <td>
                CLASS/TYPE: <br />
                <b>{pc.vessel_class}</b>
              </td>
              <td>
                REG/VOYAGE NO: <br />
                <b>{pc.voyage_number}</b>
              </td>
            </tr>
            <tr>
              <td>
                GRT: <br />
                <b>{pc.grt}</b>
              </td>
              <td>
                NRT: <br />
                <b>{pc.nrt}</b>
              </td>
              <td>
                DWT: <br />
                <b>{pc.dwt}</b>
              </td>
            </tr>
            <tr>
              <td>
                LOA: <br />
                <b>{pc.loa}</b>
              </td>
              <td>
                DRAFT: <br />
                <b>{pc.draft}</b>
              </td>
              <td>
                BEAM: <br />
                <b>{pc.beam}</b>
              </td>
            </tr>
          </table>
          <table className="table-pdf">
            <tr style={{ verticalAlign: "middle" }}>
              <td colSpan="4" className="p-0">
                <div className="tbl-title fs15">ITINERARY OF EXIT</div>
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                PURPOSE OF EXIT <br />
                <b>{ec.purpose_of_exit}</b>
              </td>
              <td rowspan="2">
                NEXT PORT OF CALL: <br />
                <b>{ec.next_port_of_call}</b>
              </td>
              <td colspan="2">Departure</td>
            </tr>
            <tr>
              <td>
                TIME: <br />
                <b>
                  {moment(ec.date_and_time_of_departure).format(
                    constants.__timeFormat
                  )}
                </b>
              </td>
              <td>
                DATE: <br />
                <b>
                  {moment(ec.date_and_time_of_departure).format(
                    constants.__dateFormat
                  )}
                </b>
              </td>
            </tr>
          </table>
          <table className="table-pdf">
            <tr style={{ verticalAlign: "middle" }}>
              <td colSpan="4" className="p-0">
                <div className="tbl-title fs15">PASSENGER INFORMATION</div>
              </td>
            </tr>
            <tr>
              <td>
                DISEMBARKING <br />
                <b>{this.props.visit.total_number_of_pd_crew}</b>
              </td>
              <td>
                EMBARKING <br />
                <b>{this.props.visit.total_number_of_pa_crew}</b>
              </td>
              <td>
                TOTAL NUMBER OF CREW: <br />
                <b>{this.props.visit.total_number_of_crew}</b>
              </td>
            </tr>
          </table>
          <table className="table-pdf">
            <tr style={{ verticalAlign: "middle" }}>
              <td colSpan="4" className="p-0">
                <div className="tbl-title fs15">
                  EXIT MANDATORY REQUIREMENTS
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan="3" style={{ padding: "20px", textAlign: "center" }}>
                <div>
                  <div style={{ width: "270px", display: "inline-block" }}>
                    <label style={{ display: "block" }}>
                      CARGO DOCUMENTS CLEARED
                    </label>
                    <div class="col-md-12 p-0 d-flex flex-row">
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={ec.is_cargo_documents_cleared}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>Yes</div>
                      </div>
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{ alignItems: "flex-end" }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={!ec.is_cargo_documents_cleared}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "270px", display: "inline-block" }}>
                    <label style={{ display: "block" }}>
                      PORT CHARGES PAID
                    </label>
                    <div class="col-md-12 p-0 d-flex flex-row">
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={ec.is_port_charges_paid}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>Yes</div>
                      </div>
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{ alignItems: "flex-end" }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={!ec.is_port_charges_paid}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "270px", display: "inline-block" }}>
                    <label style={{ display: "block" }}>
                      PILOTAGE SERVICES PAID
                    </label>
                    <div class="col-md-12 p-0 d-flex flex-row">
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={ec.is_pilotage_services_paid}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>Yes</div>
                      </div>
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{ alignItems: "flex-end" }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={!ec.is_pilotage_services_paid}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "30px" }}>
                  <div style={{ width: "270px", display: "inline-block" }}>
                    <label style={{ display: "block" }}>
                      VESSEL PRE-DEPARTURE INSPECTION
                    </label>
                    <div class="col-md-12 p-0 d-flex flex-row">
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={ec.is_vessel_predeparture_inspection}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>Yes</div>
                      </div>
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{ alignItems: "flex-end" }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={!ec.is_vessel_predeparture_inspection}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "270px", display: "inline-block" }}>
                    <label style={{ display: "block" }}>PENALTIES PAID</label>
                    <div class="col-md-12 p-0 d-flex flex-row">
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={ec.is_penalties_paid}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>Yes</div>
                      </div>
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{ alignItems: "flex-end" }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={!ec.is_penalties_paid}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "270px", display: "inline-block" }}>
                    <label style={{ display: "block" }}>
                      TUGBOAT SERVICES PAID
                    </label>
                    <div class="col-md-12 p-0 d-flex flex-row">
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={ec.tugboat_services_paid}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>Yes</div>
                      </div>
                      <div
                        class="col-md-6 d-flex flex-row"
                        style={{ alignItems: "flex-end" }}
                      >
                        <input
                          type="checkbox"
                          disabled
                          checked={!ec.tugboat_services_paid}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <table className="table-pdf">
            <tr>
              <td colspan="6">
                SHIPPING AGENCY:
                <br />
                <b>{pc.shipping_agency.name}</b>
              </td>
            </tr>
            <tr>
              <td rowspan="2" style={{ width: "75%" }}>
                ADDRESS:
                <br />
                <b>{pc.shipping_agency.address}</b>
              </td>
              <td>
                TEL NO:
                <br />
                <b>{pc.shipping_agency.tel_no}</b>
              </td>
            </tr>
            <tr>
              <td>
                FAX NO:
                <br />
                <b>{pc.shipping_agency.fax_no}</b>
              </td>
            </tr>
          </table>

          <table className="table-pdf">
            <tr>
              <td
                width="50%"
                style={{
                  height: "100px",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <div style={{ paddingTop: "55px" }}>
                  <p style={{ fontSize: "18px" }}>{this.props.visit.name}</p>
                  <p>AUTHORIZED SHIP AGENT</p>
                  <i>(Print Name & Signature)</i>
                </div>
              </td>
              <td
                width="50%"
                style={{ textAlign: "center", position: "relative" }}
              >
                <p style={{ textAlign: "left" }}>APPROVED BY:</p>
                <div style={{ paddingTop: "40px" }}>
                  <p style={{ fontSize: "18px" }}>
                    {this.props.approvals[2]
                      ? this.props.approvals[2].user
                      : ""}
                  </p>
                  <p>AUTHORIZED SEAPORT OFFICIAL</p>
                  <i>(Print Name & Signature)</i>
                </div>
              </td>
            </tr>

            <tr>
              <td width="50%">
                DATE TIME FILED:
                <br />
                <Moment
                  format={constants.__dateFormat + " " + constants.__timeFormat}
                >
                  {this.state.EXIT_CLEARANCE.date_submitted || "-"}
                </Moment>
              </td>
              <td width="50%">
                DATE APPROVED:
                <br />
                {this.props.approvals[2] ? (
                  <Moment
                    format={
                      constants.__dateFormat + " " + constants.__timeFormat
                    }
                  >
                    {this.props.approvals[2].date || "-"}
                  </Moment>
                ) : (
                  ""
                )}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(VesselExitPDF);
