import React, { Component } from "react";
import { Row, Col, Button, Form, FormGroup, Input } from "reactstrap";
import "./InboxView.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import api from "../../helpers/api.helper";
import moment from "moment";
import constants from "../../constants";
import str_helper from "../../helpers/string.helper";
import "./InboxView.scss";
import AlertModal from "../Tools/AlertModal/AlertModal";
import portWebsocket from "../../portWebsocket";
import session from "../../session";
import Media from "react-media";
import Icon from "react-fa";
import PageHeader from "../Tools/PageHeader/PageHeader";

export default class InboxView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __port_call: {},
      _INBOX: [],
      _currMsg: [],
      _NOTES: [],
      curr_id: "",
      isAlert: "",
      alertMsg: "",
      tglAlert: false
    };
  }

  componentDidMount = () => {
    this.loadData();
    if (this.textAreaRef) {
      this.textAreaRef.style.height = this.textAreaRef.scrollHeight + "px";
    }
  };

  componentDidUpdate = () => {
    if (this.textAreaRef) {
      this.textAreaRef.style.height = this.textAreaRef.scrollHeight + "px";
    }
  };

  loadData = () => {
    let pageURL = window.location.href;
    let lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);
    console.log(lastURLSegment);

    this.setState({
      curr_id: lastURLSegment
    });

    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call"
        })
        .then(data => {});

      api
        .get(this, {
          endpoint: "inbox/" + this.props.match.params.id,
          state_key: "_INBOX"
        })
        .then(data => {
          let curr_message = [];

          for (let x in data) {
            if (data[x]._id === lastURLSegment) {
              curr_message = data[x];
            }
          }

          this.setState({
            _currMsg: curr_message
          });

          this.handleUpdateSeen(lastURLSegment);
        });

      api
        .get(this, {
          endpoint: "inbox/" + this.state.__ID + "/" + lastURLSegment,
          state_key: "_NOTES"
        })
        .then(data => {
          /*add websocket event listener */
          portWebsocket.events.inbox = oMsg => {
            if (
              oMsg.parent_id === this.state.curr_id &&
              this.state.__ID === oMsg.portcall_id
            ) {
              console.log("this msg is yours, update immediately");

              this.setState({
                _NOTES: this.state._NOTES.concat([oMsg])
              });
            }
          };
        });
    }
  };

  // loadReplies = () => {
  //   let pageURL = window.location.href;
  //   let lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);
  //   console.log(lastURLSegment);

  //   this.setState({
  //     curr_id: lastURLSegment
  //   });

  //   if (!this.state.__ID) {
  //     api
  //       .get(this, {
  //         endpoint: "inbox/" + this.state.__ID + "/" + lastURLSegment,
  //         state_key: "_NOTES"
  //       })
  //       .then(data => {
  //         console.log("CHECK", data);
  //       });
  //   }
  // };

  handleSave = () => {
    let { _INBOX } = this.state;
    this.showAlert("loading", "", true);

    let user = localStorage.getItem("port_session");
    if (user) {
      user = JSON.parse(user);
    }

    api
      .save(this, {
        endpoint: "inbox/" + this.state.__ID,
        _data: {
          ..._INBOX,
          parent_id: this.state.curr_id,
          user: user.first_name + " " + user.last_name,
          position: str_helper.human_readable(user.role)
        }
      })
      .then(data => {
        this.setState(
          {
            tglAlert: false
          },
          () => this.showAlert("success", "Note Added!", true)
        );

        console.log(this.state._currMsg);

        let oParentMsg = this.state._currMsg;

        oParentMsg.message_count += 1;

        if (oParentMsg.seen_by) {
          for (let i = 0; i < oParentMsg.seen_by.length; i++) {
            if (oParentMsg.seen_by[i].role !== session.get().role) {
              oParentMsg.seen_by[i].unread_count += 1;
            }
          }
        }

        api
          .update(this, {
            endpoint: "inbox/" + oParentMsg._id,
            _data: oParentMsg
          })
          .then(v => {
            console.log(v);
          });

        //update parent then broadcast

        //window.location.reload();
        this.textAreaRef.style.height = "33px";
      });

    // this.loadData();
  };

  goBackToInbox = () => {
    this.props.history.push("/inbox");
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool
    });

    // console.log("ALERT", bool);
  };

  handleUpdateSeen = () => {
    if (this.state._currMsg) {
      let session_role = session.get().role,
        oInbox = this.state._currMsg;

      if (oInbox.seen_by) {
        let roleIndex = oInbox.seen_by
          .map(v => {
            return v.role;
          })
          .indexOf(session_role);

        if (
          roleIndex > -1 &&
          oInbox.seen_by[roleIndex].unread_count !== undefined
        ) {
          oInbox.seen_by[roleIndex].unread_count = 0;
        } else {
          oInbox.seen_by.push({
            role: session_role,
            unread_count: 0
          });
        }

        api
          .update(this, {
            endpoint: "inbox/" + oInbox._id,
            _data: oInbox
          })
          .then(v => {
            console.log(v);
          });
      }

      //check if role exists in seen by ,if yes zero out unread count

      console.log(this.state._currMsg);
    }
  };

  render() {
    const vd = this.state.__port_call;
    const msg = this.state._currMsg;
    const { tglAlert, isAlert, alertMsg, alertSuccess } = this.state;

    return (
      <div className="InboxView">
        {tglAlert ? (
          <AlertModal
            sendTrigger={isAlert}
            sendMsg={alertMsg}
            successMsg={alertSuccess}
          />
        ) : (
          ""
        )}

        <div className="d-flex flex-column">
          <Navbar />
          <PortCallToolbar />
          <Media queries={constants.media_queries}>
            {matches =>
              matches.xs ? (
                <Sidenav
                  dataFromParent={this.props.match.params.id}
                  visit={vd}
                />
              ) : (
                <PageHeader
                  hideDocToggle
                  sendVName={vd.vessel_name}
                  sendVoyageNo={vd.voyage_number}
                />
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {matches =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      visit={vd}
                    />
                  </Col>
                ) : (
                  <PageHeader
                    hideDocToggle
                    sendVName={vd.vessel_name}
                    sendVoyageNo={vd.voyage_number}
                    pagename="View Note"
                  />
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              <Form>
                <Row className="inboxCont">
                  <Col className="inboxMessage">
                    <Row className="inboxDetails">
                      <Col>
                        {/* <img
                          src={require("../../img/profile/profile_" +
                            msg.user.charAt(0).toLowerCase() +
                            ".png")}
                          alt="user img"
                        /> */}
                      </Col>
                      <Col>
                        <span className="user">{msg.user}</span>
                        <span className="userDesc">{msg.position}</span>
                      </Col>
                      <Col xs="12">
                        <span>
                          <p>{msg.subject}</p>
                        </span>
                        <span>
                          {msg.category} &middot;{" "}
                          {moment(msg.created_at).format(
                            constants.__dateFormat
                          )}{" "}
                          {moment(msg.created_at).format(
                            constants.__timeFormat_ss
                          )}
                        </span>
                      </Col>
                    </Row>

                    <Row className="inboxContent">
                      <Col>{msg.message}</Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="form-container-box bt-blue">
                  <div className="col-md-12 p-0 d-flex flex-row position-relative">
                    <div className="col-md-12 fcb-title">
                      <span>Sub-notes</span>
                    </div>
                  </div>

                  <Row xs="1">
                    {this.state._NOTES.map((note, i) => (
                      <Col key={i} className="note-comment">
                        <div className="ncomment-author">{note.user} </div>
                        <div className="ncomment-message">{note.message}</div>
                        <div className="ncomment-timestamp">
                          {moment(note.created_at).format(
                            constants.__dateFormat +
                              " " +
                              constants.__timeFormat_ss
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <FormGroup className="row note-add">
                    <textarea
                      className="form-control-sm col-md-10"
                      ref={textAreaRef => (this.textAreaRef = textAreaRef)}
                      rows={1}
                      value={this.state._INBOX.message}
                      name="mmsi_no"
                      placeholder="Add Note"
                      onChange={e => {
                        let { _INBOX } = this.state;

                        _INBOX.message = e.target.value;

                        this.setState({ _INBOX });

                        //console.log("ASDCCC", _INBOX);
                      }}
                    />
                    <div className="col-md-2">
                      <Button
                        block
                        className="btn-sm"
                        color="primary"
                        onClick={() => {
                          this.handleSave();
                          this.setState({
                            _INBOX: {
                              message: ""
                            }
                          });
                        }}
                      >
                        <Media queries={constants.media_queries}>
                          {matches =>
                            matches.xs ? <Icon name="paper-plane" /> : "SEND"
                          }
                        </Media>
                      </Button>
                    </div>
                  </FormGroup>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
