import React, { Component } from "react";
import { Row, Col, Button, Table } from "reactstrap";
import { Icon } from "react-fa";

import "./InPortServices.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import api from "../../helpers/api.helper";
import constants from "../../constants";
import { withRouter } from "react-router-dom";
import PageHeader from "../Tools/PageHeader/PageHeader";
import DocView from "../DocView/DocView";
import AlertModal from "../Tools/AlertModal/AlertModal";
import session from "../../session";
import Swal from "sweetalert2";
import Media from "react-media";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import { ReactComponent as Service } from "../../img/svg/service.svg";

class InPortServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAudit: false,
      __ID: this.props.match.params.id,
      __port_call: {},
      addService: false,
      __services: constants.purpose_of_portcalls,
      _service: "",
      selected_port_call_purposes: [],
      viewMode: "editMode",
      is_save_btn: "",
      editTable: false,
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
      readBtn: false,
    };
  }

  componentDidMount = () => {
    this.loadData();
    this.disableBtn();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call",
        })
        .then((data) => {
          let { selected_port_call_purposes } = data;
          this.setState({ selected_port_call_purposes });
        });
    }
  };

  handleAudit = () => {
    this.setState((prevState) => ({
      showAudit: !prevState.showAudit,
    }));
  };

  toggleService = () => {
    this.setState((prevState) => ({
      addService: !prevState.addService,
    }));
  };

  handleRemoveService = (index, service) => {
    Swal.fire({
      icon: "question",
      title: "Delete " + service + "?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showConfirmButton: false,
          timer: 1500,
        });

        let { selected_port_call_purposes } = this.state;
        selected_port_call_purposes.splice(index, 1);
        this.setState({ ...selected_port_call_purposes, editTable: true });
      }
    });
  };

  handleSave = () => {
    this.showAlert("loading", "", true);
    let { selected_port_call_purposes, __port_call } = this.state;
    __port_call = Object.assign(__port_call, { selected_port_call_purposes });
    api
      .update(this, {
        endpoint: "/portcall",
        _data: __port_call,
      })
      .then(() => {
        this.setState(
          {
            tglAlert: false,
          },
          () => this.showAlert("success", "Services Updated!", true)
        );
      });

    // this.props.history.push(
    //   "/port_call/crew_list_pre_arrival/" + this.state.__ID
    // );
  };

  getViewMode = (view_mode) => {
    this.setState({
      viewMode: view_mode,
    });
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  disableBtn = () => {
    if (session.get().role === "port_admin") {
      this.setState({
        readBtn: true,
      });
    }
  };

  render() {
    const {
      showAudit,
      viewMode,
      tglAlert,
      isAlert,
      alertMsg,
      readBtn,
    } = this.state;
    const vd = this.state.__port_call;
    const VNAME = vd.vessel_name;
    const VOYAGE_NO = vd.voyage_number;

    return (
      <div className="inPortServices">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}

        <div className="d-flex flex-column">
          <Navbar />

          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav dataFromParent={this.state.__ID} visit={vd} />
              ) : (
                <PageHeader
                  parentCallback={this.getViewMode}
                  sendVName={VNAME}
                  sendVoyageNo={VOYAGE_NO}
                />
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav dataFromParent={this.state.__ID} visit={vd} />
                  </Col>
                ) : (
                  <PageHeader
                    parentCallback={this.getViewMode}
                    sendVName={VNAME}
                    sendVoyageNo={VOYAGE_NO}
                    pagename="Purpose of Port Call"
                  />
                )
              }
            </Media>

            <Col md="10" xs="12">
              {viewMode === "editMode" ? (
                <React.Fragment>
                  {this.state.selected_port_call_purposes.length > 0 ? (
                    <Row className="form-container-box bt-green">
                      <Col xs="12" className="fcb-title">
                        Purpose of Port Call
                        {session.get().role === "shipping_agent" ? (
                          <Button
                            className="btn-floatOnXS"
                            color="primary"
                            disabled={readBtn}
                            onClick={() =>
                              this.props.history.push(
                                "/port_call/in_port_services_create/" +
                                  this.state.__ID
                              )
                            }
                            hidden={readBtn}
                          >
                            <Media queries={constants.media_queries}>
                              {(matches) =>
                                matches.xs ? (
                                  <Icon name="plus" />
                                ) : (
                                  "ADD SERVICE"
                                )
                              }
                            </Media>
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>

                      <Media queries={constants.media_queries}>
                        {(matches) =>
                          !matches.xs ? (
                            <Table className="custom-table" bordered>
                              <thead>
                                <tr>
                                  <th>Service</th>
                                  <th>Submitted by</th>
                                  <th>Status</th>
                                  <th>Provider / Arrastre / Stevedoring</th>
                                  {/* <th>Arrastre / Stevedoring</th> */}
                                  {session.get().role === "shipping_agent" ? (
                                    <th></th>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.selected_port_call_purposes.map(
                                  (ps, i) => {
                                    return (
                                      <tr>
                                        <td>
                                          {ps.service}{" "}
                                          <p class="italized"> {ps.purpose}</p>
                                        </td>
                                        <td>{ps.submitted_by}</td>
                                        <td>{ps.status}</td>
                                        {ps.service ===
                                          constants.CARGO_HANDLING_SERVICE_LOAD ||
                                        ps.service ===
                                          constants.CARGO_HANDLING_SERVICE_UNLOAD ? (
                                          <td>
                                            {ps.arrastre +
                                              " / " +
                                              ps.stevedoring}
                                          </td>
                                        ) : (
                                          <td>{ps.provider}</td>
                                        )}
                                        {session.get().role ===
                                        "shipping_agent" ? (
                                          <td>
                                            <div
                                              className="tblActions tblRemove"
                                              onClick={() => {
                                                this.handleRemoveService(
                                                  i,
                                                  ps.service
                                                );
                                              }}
                                              hidden={readBtn}
                                            >
                                              <Icon name="trash" />
                                            </div>
                                          </td>
                                        ) : (
                                          ""
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          ) : (
                            <>
                              <SwipeableList>
                                {this.state.selected_port_call_purposes.map(
                                  (ps, i) => {
                                    return (
                                      <SwipeableListItem
                                        swipeLeft={{
                                          content: (
                                            <div>
                                              <div>
                                                <Icon name="trash" />
                                              </div>
                                              <div>Delete</div>
                                            </div>
                                          ),
                                          action: () =>
                                            this.handleRemoveService(
                                              i,
                                              ps.service
                                            ),
                                        }}
                                      >
                                        <div className="name-stat">
                                          <div>
                                            <span>{ps.service}</span>
                                            <span>{ps.submitted_by}</span>
                                          </div>
                                          <div>
                                            <span>{ps.status}</span>
                                          </div>
                                        </div>
                                        {ps.service ===
                                          constants.CARGO_HANDLING_SERVICE_LOAD ||
                                        ps.service ===
                                          constants.CARGO_HANDLING_SERVICE_UNLOAD ? (
                                          <span>
                                            {ps.arrastre +
                                              " / " +
                                              ps.stevedoring}
                                          </span>
                                        ) : (
                                          <span>{ps.provider}</span>
                                        )}
                                      </SwipeableListItem>
                                    );
                                  }
                                )}
                              </SwipeableList>
                              <div
                                class="add-service-xs"
                                disabled={readBtn}
                                onClick={() =>
                                  this.props.history.push(
                                    "/port_call/in_port_services_create/" +
                                      this.state.__ID
                                  )
                                }
                                hidden={readBtn}
                              >
                                <div>
                                  <Icon name="plus" /> ADD SERVICE
                                </div>
                              </div>
                            </>
                          )
                        }
                      </Media>
                    </Row>
                  ) : (
                    <div className="no-service">
                      <Service />
                      <p>No Services</p>
                      <Button
                        className="btn-floatOnXS btn-float-3"
                        color="primary"
                        disabled={readBtn}
                        onClick={() =>
                          this.props.history.push(
                            "/port_call/in_port_services_create/" +
                              this.state.__ID
                          )
                        }
                        hidden={readBtn}
                      >
                        <Media queries={constants.media_queries}>
                          {(matches) =>
                            matches.xs ? <Icon name="plus" /> : "ADD SERVICE"
                          }
                        </Media>
                      </Button>
                    </div>
                  )}

                  <Row className="form-container-box bt-green auditTrail">
                    <Col xs="12" className="d-flex flex-row auditHead">
                      {showAudit ? (
                        <Icon
                          className=""
                          name="angle-up"
                          onClick={this.handleAudit}
                        />
                      ) : (
                        <Icon
                          className=""
                          name="angle-down"
                          onClick={() => {
                            this.handleAudit();
                          }}
                          hidden={readBtn}
                        />
                      )}

                      <p>Audit Trails</p>
                    </Col>

                    <Row>
                      {showAudit ? (
                        <Col md={{ size: 12 }} className="p-0">
                          <Table
                            className="custom-table"
                            bordered
                            style={{ marginTop: "20px" }}
                          >
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Details</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                    </Row>
                  </Row>

                  <div className="footer-actions">
                    <div>
                      {!this.state.editTable ? (
                        <Button
                          onClick={() =>
                            this.props.history.push(
                              "/port_call/crew_list_pre_arrival/" +
                                this.state.__ID
                            )
                          }
                          className="btn-sm fs13"
                          color="primary"
                          hidden={readBtn}
                        >
                          <Media queries={constants.media_queries}>
                            {(matches) =>
                              matches.xs ? (
                                <Icon name="chevron-right" />
                              ) : (
                                "NEXT"
                              )
                            }
                          </Media>
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.handleSave()}
                          className="btn-sm fs13"
                          color="success"
                          disabled={readBtn}
                        >
                          <Media queries={constants.media_queries}>
                            {(matches) =>
                              matches.xs ? <Icon name="save" /> : "SAVE"
                            }
                          </Media>
                        </Button>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <DocView visit={vd} />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(InPortServices);
