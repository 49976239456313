import React, { Component } from "react";
import { Table } from "reactstrap";

import ekonek_logo from "../../img/ekonek-logo.jpg";
import sbma_logo from "../../img/sbma-logo.png";

import "./BerthSched.scss";

export default class BerthSched extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noDays: 365
    };
  }

  componentDidMount = () => {
    this.setState(prevState => {
      return {
        noDays: prevState.noDays * 0.55
      };
    });
  };

  render() {
    const noDays = this.state.noDays;

    return (
      <div className="berthSched">
        <div className="hdg-con">
          <div className="nav-eKonek-header row">
            <div className="col-md-7 row" style={{ marginLeft: 0 }}>
              <img
                src={ekonek_logo}
                alt="ekonek-logo"
                className="nav-ekonek-logo"
              />
              <div className="">
                <p className="nav-port-title">PORT MANAGEMENT SYSTEM</p>
                <p className="powered-by-apollo">POWERED BY AESI</p>
              </div>
            </div>

            <div className="col-md-5 row d-flex justify-content-end align-items-center m-0">
              <div className="row d-flex align-items-center">
                <img
                  src={sbma_logo}
                  alt="sbma-logo"
                  className="nav-sbma-logo"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 berthSched-calendar d-flex p-0">
          <div className="col-md-12 d-flex flex-row">
            <div className="col-md-6 vesselDetails p-0">
              <Table
                className="schedTable"
                bordered
                style={{ marginTop: "20px" }}
              >
                <thead>
                  <tr>
                    <th>Vessel Name</th>
                    <th>ETA</th>
                    <th>ETD</th>
                    <th>Duration (Days)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="col-md-6 vesselTimeline p-0">
              <Table
                className="timelineTable"
                bordered
                style={{ marginTop: "20px" }}
              >
                <thead>
                  <tr>
                    <th colSpan="2" className="year">
                      2018
                    </th>
                    <th colSpan="2" className="year">
                      2019
                    </th>
                  </tr>
                  <tr>
                    <th className="semester">Semester 1</th>
                    <th className="semester">Semester 2</th>
                    <th className="semester">Semester 1</th>
                    <th className="semester">Semester 2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="tLineDays">
                    <td>
                      <div
                        className="progressDays"
                        style={{ width: `${noDays}%` }}
                      ></div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
