import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Icon } from "react-fa";
import back_icon from "../../img/svg/BackIcon.svg";
import AlertModal from "../Tools/AlertModal/AlertModal";
import "./ResourcePlan.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import api from "../../helpers/api.helper";
import { withRouter } from "react-router-dom";
import constants from "../../constants";
import Can from "../Can/Can";
import session from "../../session";
import handleApproval from "../../helpers/approvals.helper";
import Swal from "sweetalert2";
import Media from "react-media";
import PageHeader from "../Tools/PageHeader/PageHeader";

class ResourcePlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAudit: false,
      showItemAcc: true,
      __ID: this.props.match.params.id,
      __port_call: {},
      __berth_plans: [],
      __equipment: [
        {
          name: "",
          capacity: "",
          uom: "",
        },
      ],
      __manpower: [
        {
          name: "",
          role: "",
        },
      ],
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
      disableSave: false,
    };
  }

  showAlert = (mode, msg, bool, success) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
      alertSuccess: success,
    });
  };

  handleSuccessAlert = (success) => {
    this.setState({
      tglAlert: false,
    });

    Swal.fire({
      icon: "success",
      title: success,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  handleSaveNotification = (type) => {
    //submit entry

    let voyageNumber = this.state.__port_call.voyage_number
      ? "(" + this.state.__port_call.voyage_number + ")"
      : "";
    switch (type) {
      case "submit_plan":
        api
          .save(this, {
            endpoint: "notification/port_admin/" + this.state.__port_call._id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.state.__port_call.vessel_name,
              voyage_number: this.state.__port_call.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              subject:
                this.state.__port_call.vessel_name +
                voyageNumber +
                " Resource Allocation Plan ",
              action: 0,
              description:
                " has submitted a Resource allocation plan for " +
                this.state.__port_call.vessel_name +
                " and requires your approval",
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has submitted a resource allocation plan.",
              role: "engineering",
              portcall_id: this.state.__port_call._id,
            },
          })
          .then(() => {});

        break;

      case "approve_plan":
        api
          .save(this, {
            endpoint:
              "notification/company/" + this.state.__port_call.company_id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.state.__port_call.vessel_name,
              voyage_number: this.state.__port_call.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              subject:
                "Endorsement of " +
                this.state.__port_call.vessel_name +
                voyageNumber +
                ") Entry Application ",
              action: 1,
              description:
                " has  approved your Resource Allocation Plan for " +
                this.state.__port_call.vessel_name +
                ".",
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has approved your Resource Allocation Plan for" +
                this.state.__port_call.vessel_name +
                ".",
              portcall_id: this.state.__port_call._id,
            },
          })
          .then(() => {});

        api
          .save(this, {
            endpoint: "notification/port_admin/" + this.state.__port_call._id,
            preserve_state: true,
            _data: {
              filler: true,
              vessel_name: this.state.__port_call.vessel_name,
              voyage_number: this.state.__port_call.voyage_number,
              first_name: session.get().first_name,
              last_name: session.get().last_name,
              subject:
                this.state.__port_call.vessel_name +
                voyageNumber +
                " Resource Allocation Plan ",
              action: 0,
              description:
                " has approved Resource Allocation Plan for " +
                this.state.__port_call.vessel_name +
                " you can now move the vessel in planned pier.",
              message:
                session.get().first_name +
                " " +
                session.get().last_name +
                " has approved a Resource Allocation Plan for " +
                this.state.__port_call.vessel_name +
                ". You can now move the vessel in planned pier.",
              role: "harbor_master",
              portcall_id: this.state.__port_call._id,
            },
          })
          .then(() => {});

        break;

      default:
        console.log("none");
        break;
    }
  };

  handleConfirm = (msg, success, data) => {
    Swal.fire({
      icon: "warning",
      title: msg,
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.dismiss) {
        return;
      }
      this.showAlert("loading", "", true);

      handleApproval({
        portcall: this.state.__port_call,
        approvaldData: { ...data },
      }).then((response) => {
        const { __port_call } = this.state;
        __port_call.status_id = constants.RESOURCE_PLAN_APPROVED_STATUS;
        this.setState(
          {
            __data: {
              ...this.state.__data,
              ...data,
            },
            __port_call,
          },
          () => {
            this.handleSuccessAlert(success);
            this.handleSaveNotification("approve_plan");
          }
        );
      });
    });
  };

  handleStatusChange = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to submit this to Engineering department?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      return new Promise((resolve, reject) => {
        api
          .update(this, {
            endpoint: "portcall/status_change/" + this.state.__ID,
            state_key: "portcall_status_change",
            preserve_state: true,
            _data: {
              status: constants.RESOURCE_PLAN_SUBMITTED_STATUS,
              date: new Date(),
            },
          })
          .then(() => {
            const { __port_call } = this.state;
            __port_call.status_id = constants.RESOURCE_PLAN_SUBMITTED_STATUS;
            this.setState({ __port_call });
            this.handleSuccessAlert("Submitted to Engineering department!");
          });
      });
    });
  };

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call",
        })
        .then((data) => {
          api
            .get(this, {
              endpoint: "berth_plan",
              state_key: "__berth_plans",
              preserve_state: true,
              request_params: { search: { port_call_id: data._id } },
            })
            .then((berth_plans) => {
              let { __berth_plans } = this.state;
              if (berth_plans) {
                if (berth_plans.length > 0) {
                  for (let x in berth_plans) {
                    if (!berth_plans[x].equipments) {
                      berth_plans[x].equipments = [];
                    }
                    if (berth_plans[x].equipments.length === 0) {
                      berth_plans[x].equipments = this.state.__equipment;
                    }

                    if (berth_plans[x].manpower.length === 0) {
                      berth_plans[x].manpower = this.state.__manpower;
                    }
                  }

                  __berth_plans = berth_plans;
                }
              }

              this.setState({ __berth_plans });
            });
        });
    }
  };

  handleAdd = (berth_plan_index, key) => {
    let { __berth_plans } = this.state;

    if (__berth_plans[berth_plan_index][key]) {
      __berth_plans[berth_plan_index][key] = [
        ...__berth_plans[berth_plan_index][key],
        {},
      ];
      this.setState({ __berth_plans });
    }
  };

  handleRemove = (berth_plan_index, section_index, key) => {
    let { __berth_plans } = this.state;

    if (
      __berth_plans[berth_plan_index] &&
      __berth_plans[berth_plan_index][key] &&
      __berth_plans[berth_plan_index][key][section_index]
    ) {
      __berth_plans[berth_plan_index][key].splice(section_index, 1);
      this.setState({ __berth_plans });
    }
  };

  handleSave = () => {
    let { __port_call } = this.state;

    this.showAlert("loading", "", true);
    this.setState({
      disableSave: true,
    });

    let { __berth_plans } = this.state;
    if (__berth_plans) {
      api
        .update(this, {
          endpoint: "berth_plan/resources/" + this.state.__ID,
          _data: __berth_plans,
        })
        .then((data) => {
          this.handleSaveNotification("submit_plan");

          __port_call.has_resource_management = true;

          this.setState(
            {
              tglAlert: false,
              disableSave: false,
              __port_call,
            },
            () =>
              this.showAlert(
                "success",
                "Resource Plan successfully saved!",
                true
              )
          );
        });
    }
  };

  handleAudit = () => {
    this.setState((prevState) => ({
      showAudit: !prevState.showAudit,
    }));
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  render() {
    const {
      showAudit,

      __manpower,
      __berth_plans,
      tglAlert,
      isAlert,
      alertMsg,
    } = this.state;
    const vd = this.state.__port_call;

    return (
      <div className="ResourcePlan">
        <div className="d-flex flex-column">
          {tglAlert ? (
            <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
          ) : (
            ""
          )}

          <Navbar />

          <div
            md={{ size: 12 }}
            className="d-flex flex-column page-header"
            ref={this.pageHeader}
          >
            <Col md={{ size: 12 }} className="d-flex flex-row">
              <Col className="d-flex flex-row col-md-6">
                <img src={back_icon} alt="back_icon" className="" width="25" />
                <p className="vessel-name aligner justify-content-start">
                  {vd.vessel_name}, Voyage {vd.voyage_number}
                </p>
              </Col>
            </Col>
          </div>

          <Col md={{ size: 12 }} className="main-conte@nt d-flex flex-row mt-3">
            <Col md={{ size: 2 }}>
              <Sidenav dataFromParent={this.props.match.params.id} visit={vd} />
            </Col>

            <Can
              role={session.get().role}
              perform="sidenav-resource-management:manage"
              yes={() => (
                <Col
                  md={{ size: 10 }}
                  className={
                    session.get().role === "engineering"
                      ? "mc-right d-flex flex-column pen"
                      : "mc-right d-flex flex-column"
                  }
                >
                  <Form
                    className="d-flex flex-column"
                    style={{ padding: "0 10px" }}
                  >
                    <div
                      className="form-container-box bt-green w100per"
                      style={{ margin: "0 5px" }}
                    >
                      <div className="col-md-12 p-0 d-flex flex-row position-relative">
                        <div className="col-md-12 fcb-title">
                          Resource Management
                        </div>
                      </div>

                      {__berth_plans.map((bp, i) => (
                        <div
                          key={i}
                          className="col-md-12 d-flex flex-column berth-con-outer"
                        >
                          <div
                            className="col-md-12 berth-con d-flex flex-row m-0"
                            style={{ padding: "1em 0 0 0" }}
                          >
                            <div className="col-md-4">
                              <Label className="col-md-12 p-0 big-label">
                                Berth
                              </Label>
                              <div className="view-text">{bp.berth || "-"}</div>
                            </div>
                            <div className="col-md-4">
                              <Label className="col-md-12 p-0 big-label">
                                Service
                              </Label>
                              <div className="view-text">
                                {bp.service || "-"}
                              </div>
                            </div>
                            {bp.service === "Cargo Handling Services - Load" ||
                            bp.service ===
                              "Cargo Handling Services - Unload" ? (
                              <div className="col-md-4">
                                <Label className="col-md-12 p-0">
                                  Cargo Handler
                                </Label>
                                <div className="view-text">
                                  {bp.cargo_handler || "-"}
                                </div>
                              </div>
                            ) : (
                              <div className="col-md-4">
                                <Label className="col-md-12 p-0 big-label">
                                  Provider
                                </Label>
                                <div className="view-text">
                                  {bp.provider || "-"}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-12 p-0 d-flex flex-row mt-2">
                            <div className="col-md-4">
                              <FormGroup className="col-md-12 p-0 m-0">
                                <Label for="" className="big-label">
                                  Equipment
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                          {bp.equipments.map((eq, i_eq) => (
                            <div
                              key={i_eq}
                              className="col-md-12 p-0 d-flex flex-row mt-2"
                            >
                              <div className="col-md-4">
                                <FormGroup className="col-md-12 p-0">
                                  <Label>Name</Label>
                                  <Input
                                    className="form-control-sm"
                                    type="text"
                                    name="mmsi_no"
                                    id=""
                                    placeholder=""
                                    value={eq.name}
                                    onChange={(e) => {
                                      let { __berth_plans } = this.state;
                                      let { equipments } = __berth_plans[i];
                                      equipments[i_eq].name = e.target.value;
                                      __berth_plans[i].equipments = equipments;
                                      this.setState({ __berth_plans });
                                    }}
                                  />
                                </FormGroup>
                              </div>

                              <div className="col-md-4">
                                <FormGroup className="col-md-12 p-0">
                                  <Label>Capacity</Label>
                                  <Input
                                    className="form-control-sm"
                                    type="number"
                                    name="mmsi_no"
                                    id=""
                                    placeholder=""
                                    value={eq.capacity}
                                    onChange={(e) => {
                                      let { __berth_plans } = this.state;
                                      let { equipments } = __berth_plans[i];
                                      equipments[i_eq].capacity =
                                        e.target.value;
                                      __berth_plans[i].equipments = equipments;
                                      this.setState({ __berth_plans });
                                    }}
                                  />
                                </FormGroup>
                              </div>

                              <div className="col-md-4 d-flex flex-row">
                                <FormGroup className="col-md-9 p-0">
                                  <Label>UOM</Label>
                                  <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect"
                                    className="form-control-sm"
                                    value={eq.uom}
                                    onChange={(e) => {
                                      let { __berth_plans } = this.state;
                                      let { equipments } = __berth_plans[i];
                                      equipments[i_eq].uom = e.target.value;
                                      __berth_plans[i].equipments = equipments;
                                      this.setState({ __berth_plans });
                                    }}
                                  >
                                    {constants.uoms.map((uom, i) => {
                                      return <option>{uom.name}</option>;
                                    })}
                                  </Input>
                                </FormGroup>
                                <div
                                  className="col-md-3 d-flex actions"
                                  style={{ marginTop: "1.75em" }}
                                >
                                  {/* <Icon
                              className=""
                              name="minus"
                              style={{ marginTop: "-1.05em" }}
                            /> */}
                                  {bp.equipments.length === 1 ? (
                                    <Can
                                      role={session.get().role}
                                      perform="resource-management:edit"
                                      yes={() => (
                                        <div
                                          className="tblActions tblAdd"
                                          onClick={() => {
                                            this.handleAdd(i, "equipments");
                                          }}
                                        >
                                          <Icon name="plus" />
                                        </div>
                                      )}
                                    />
                                  ) : bp.equipments.length === i_eq + 1 ? (
                                    <Can
                                      role={session.get().role}
                                      perform="resource-management:edit"
                                      yes={() => (
                                        <div className="d-flex flex-row">
                                          <div
                                            style={{ marginRight: "0.5em" }}
                                            className="tblActions tblRemove"
                                            onClick={() =>
                                              this.handleRemove(
                                                i,
                                                i_eq,
                                                "equipments"
                                              )
                                            }
                                          >
                                            <Icon name="minus" />
                                          </div>
                                          <div
                                            className="tblActions tblAdd"
                                            onClick={() => {
                                              this.handleAdd(i, "equipments");
                                            }}
                                          >
                                            <Icon name="plus" />
                                          </div>
                                        </div>
                                      )}
                                    />
                                  ) : (
                                    <Can
                                      role={session.get().role}
                                      perform="resource-management:edit"
                                      yes={() => (
                                        <div
                                          className="tblActions tblRemove"
                                          onClick={() =>
                                            this.handleRemove(
                                              i,
                                              i_eq,
                                              "equipments"
                                            )
                                          }
                                        >
                                          <Icon name="minus" />
                                        </div>
                                      )}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="col-md-12 p-0 d-flex flex-row mt-2">
                            <div className="col-md-4">
                              <FormGroup className="col-md-12 p-0 m-0">
                                <Label for="" className="big-label">
                                  Manpower
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                          {bp.manpower.map((mp, i_mp) => (
                            <div
                              key={i_mp}
                              className="col-md-12 p-0 d-flex flex-row mt-2"
                            >
                              <div className="col-md-4">
                                <FormGroup className="col-md-12 p-0">
                                  <Label>Name</Label>
                                  <Input
                                    className="form-control-sm"
                                    type="text"
                                    name="mmsi_no"
                                    id=""
                                    placeholder=""
                                    value={mp.name}
                                    onChange={(e) => {
                                      let { __berth_plans } = this.state;
                                      let { manpower } = __berth_plans[i];
                                      manpower[i_mp].name = e.target.value;
                                      __berth_plans[i].manpower = manpower;
                                      this.setState({ __berth_plans });
                                    }}
                                  />
                                </FormGroup>
                              </div>

                              <div className="col-md-4">
                                <FormGroup className="col-md-12 p-0">
                                  <Label>Role</Label>
                                  <Input
                                    className="form-control-sm"
                                    type="text"
                                    name="mmsi_no"
                                    id=""
                                    placeholder=""
                                    value={mp.role}
                                    onChange={(e) => {
                                      let { __berth_plans } = this.state;
                                      let { manpower } = __berth_plans[i];
                                      manpower[i_mp].role = e.target.value;
                                      __berth_plans[i].manpower = manpower;
                                      this.setState({ __berth_plans });
                                    }}
                                  />
                                </FormGroup>
                              </div>

                              <div className="col-md-4 d-flex flex-row">
                                <FormGroup className="col-md-9 p-0"></FormGroup>
                                <Can
                                  role={session.get().role}
                                  perform="resource-management:edit"
                                  yes={() => (
                                    <div
                                      className="col-md-3 d-flex actions"
                                      style={{ marginTop: "1.75em" }}
                                    >
                                      {/* <Icon
                                    className=""
                                    name="minus"
                                    style={{ marginTop: "-1.05em" }}
                                  /> */}
                                      {bp.manpower.length === 1 ? (
                                        <Can
                                          role={session.get().role}
                                          perform="resource-management:edit"
                                          yes={() => (
                                            <div
                                              className="tblActions tblAdd"
                                              onClick={() => {
                                                this.handleAdd(i, "manpower");
                                              }}
                                            >
                                              <Icon name="plus" />
                                            </div>
                                          )}
                                        />
                                      ) : bp.manpower.length === i_mp + 1 ? (
                                        <Can
                                          role={session.get().role}
                                          perform="resource-management:edit"
                                          yes={() => (
                                            <div className="d-flex flex-row">
                                              <div
                                                style={{ marginRight: "0.5em" }}
                                                className="tblActions tblRemove"
                                                onClick={() =>
                                                  this.handleRemove(
                                                    i,
                                                    i_mp,
                                                    "manpower"
                                                  )
                                                }
                                              >
                                                <Icon name="minus" />
                                              </div>
                                              <div
                                                className="tblActions tblAdd"
                                                onClick={() => {
                                                  this.handleAdd(i, "manpower");
                                                }}
                                              >
                                                <Icon name="plus" />
                                              </div>
                                            </div>
                                          )}
                                        />
                                      ) : (
                                        <Can
                                          role={session.get().role}
                                          perform="resource-management:edit"
                                          yes={() => (
                                            <div
                                              className="tblActions tblRemove"
                                              onClick={() =>
                                                this.handleRemove(
                                                  i,
                                                  i_mp,
                                                  "equipments"
                                                )
                                              }
                                            >
                                              <Icon name="minus" />
                                            </div>
                                          )}
                                        />
                                      )}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                    <div
                      className="form-container-box bt-green w100per d-none"
                      style={{ margin: "1em 5px 0 5px" }}
                    >
                      <div className="col-md-12 p-0 d-flex flex-row position-relative">
                        <div className="col-md-12 fcb-title">Manpower</div>
                      </div>

                      <div className="col-md-12 p-0 d-flex flex-row mt-2">
                        <div className="col-md-3">
                          <FormGroup className="col-md-12 p-0 m-0">
                            <Label for="">Role</Label>
                          </FormGroup>
                        </div>

                        <div className="col-md-3">
                          <FormGroup className="col-md-12 p-0 m-0">
                            <Label for="">Name</Label>
                          </FormGroup>
                        </div>

                        <div className="col-md-3 d-flex flex-row">
                          <FormGroup className="col-md-12 p-0 m-0">
                            <Label for="exampleSelect">Location</Label>
                          </FormGroup>
                        </div>

                        <div className="col-md-3 d-flex flex-row">
                          <FormGroup className="col-md-12 p-0 m-0">
                            <Label for="exampleSelect">Service</Label>
                          </FormGroup>
                        </div>
                      </div>

                      {__manpower.map((mp, i) => (
                        <div className="col-md-12 p-0 d-flex flex-row mt-2">
                          <div className="col-md-3">
                            <FormGroup className="col-md-12 p-0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                id=""
                                placeholder=""
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-3">
                            <FormGroup className="col-md-12 p-0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                id=""
                                placeholder=""
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-3 d-flex flex-row">
                            <FormGroup className="col-md-12 p-0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                id=""
                                placeholder=""
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-3 d-flex flex-row">
                            <FormGroup className="col-md-9 p-0">
                              <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                                className="form-control-sm"
                                value={mp.service}
                                onChange={(e) => {
                                  let __manpower = this.state.___manpower;

                                  __manpower.service = e.target.value;
                                  this.setState({ __manpower });
                                }}
                              >
                                <option>-</option>
                              </Input>
                            </FormGroup>
                            <Can
                              role={session.get().role}
                              perform="resource-management:edit"
                              yes={() => (
                                <div className="col-md-3 d-flex mt-1">
                                  {/* <Icon
                                  className=""
                                  name="minus"
                                  style={{ marginTop: "-1.05em" }}
                                /> */}
                                  {__manpower.length === 1 ? (
                                    <div
                                      className="tblActions tblAdd"
                                      onClick={this.addManpower}
                                    >
                                      <Icon name="plus" />
                                    </div>
                                  ) : __manpower.length === i + 1 ? (
                                    <div className="d-flex flex-row">
                                      <div
                                        style={{ marginRight: "0.5em" }}
                                        className="tblActions tblRemove"
                                        onClick={() => this.rmvManpower(i)}
                                      >
                                        <Icon name="minus" />
                                      </div>
                                      <div
                                        className="tblActions tblAdd"
                                        onClick={this.addManpower}
                                      >
                                        <Icon name="plus" />
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="tblActions tblRemove"
                                      onClick={() => this.rmvManpower(i)}
                                    >
                                      <Icon name="minus" />
                                    </div>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <Col
                      col={{ size: 12 }}
                      className="d-flex flex-row p-0 mt-3"
                    >
                      <div className="form-container-box bt-green col-md-12">
                        <div className="col-md-12 d-flex flex-row auditHead">
                          {showAudit ? (
                            <Icon
                              className=""
                              name="angle-up"
                              onClick={this.handleAudit}
                            />
                          ) : (
                            <Icon
                              className=""
                              name="angle-down"
                              onClick={this.handleAudit}
                            />
                          )}

                          <p>Audit Trails</p>
                        </div>

                        <div>
                          {showAudit ? (
                            <Col md={{ size: 12 }} className="p-0">
                              <Table
                                className="custom-table"
                                bordered
                                style={{ marginTop: "20px" }}
                              >
                                <thead>
                                  <tr>
                                    <th>Action</th>
                                    <th>Details</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Form>
                </Col>
              )}
              no={() => <h1>Access Denied</h1>}
            />
          </Col>
        </div>

        <Can
          role={session.get().role}
          perform="sidenav-resource-management:manage"
          yes={() => (
            <div className="col-md-12 footer-actions d-flex flex-row justify-content-end">
              <Can
                role={session.get().role}
                perform="resource-management:edit"
                yes={() =>
                  (vd.status_id !== constants.RESOURCE_PLAN_SUBMITTED_STATUS &&
                    vd.status_id !== constants.RESOURCE_PLAN_APPROVED_STATUS) ||
                  vd.status_id === constants.RESOURCE_PLAN_REJECTED_STATUS ? (
                    <>
                      <Button
                        onClick={() => this.handleStatusChange()}
                        className={
                          this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"
                        }
                        color="primary"
                      >
                        SUBMIT
                      </Button>
                      <Button
                        onClick={() => this.handleSave()}
                        className={
                          this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"
                        }
                        color="success"
                        style={{ margin: "0 0.5em" }}
                      >
                        SAVE
                      </Button>
                    </>
                  ) : (
                    ""
                  )
                }
              />

              {!vd.is_resource_allocation_approved &&
              vd.status_id === constants.RESOURCE_PLAN_SUBMITTED_STATUS ? (
                <Can
                  role={session.get().role}
                  perform="resource-management:approve"
                  yes={() => (
                    <>
                      <Button
                        onClick={() =>
                          this.handleConfirm(
                            "Reject Resource plan?",
                            "Resource Plan rejected!",
                            {
                              data: {
                                status_id:
                                  constants.RESOURCE_PLAN_REJECTED_STATUS,
                                is_vessel_entry_submitted: true,
                              },
                              action: "reject",
                              module: "resource_plan_approvals",
                              timeline_action:
                                "Resource Allocation Plan approved.",
                            },
                            true
                          )
                        }
                        className="btn-sm"
                        color="aesi-danger"
                        style={{ margin: "0 0.5em" }}
                      >
                        Reject
                      </Button>

                      <Button
                        onClick={() =>
                          this.handleConfirm(
                            "Approve Resource plan?",
                            "Resource Plan approved!",
                            {
                              data: {
                                status_id:
                                  constants.RESOURCE_PLAN_APPROVED_STATUS,
                                is_resource_allocation_approved: true,
                              },
                              action: "approve",
                              module: "resource_plan_approvals",
                              timeline_action:
                                "Resource Allocation Plan approved.",
                            },
                            true
                          )
                        }
                        className="btn-sm"
                        color="success"
                        style={{ margin: "0 0.5em" }}
                      >
                        APPROVE
                      </Button>
                    </>
                  )}
                />
              ) : (
                ""
              )}
            </div>
          )}
        />
      </div>
    );
  }
}

export default withRouter(ResourcePlan);
