import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import "./SkeletonScreen.scss";

export class SkellyBar extends Component {
  render() {
    let width = "150px";
    let height = "12px";
    let primaryColor = "#c8c8c8";
    let secondaryColor = "#e3e1e1";

    if (this.props.width) {
      width = this.props.width + "px";
    }
    if (this.props.height) {
      height = this.props.height + "px";
    }

    if (this.props.dark) {
      primaryColor = "#484848";
      secondaryColor = "#5a5a5a";
    }

    return (
      <ContentLoader
        style={{
          width: width,
          height: height
        }}
        width={width}
        height={height}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      >
        <rect x="0" y="0" rx=".2em" ry=".2em" height={height} width={width} />
      </ContentLoader>
    );
  }
}

export class SkellyBarTableRow extends Component {
  render() {
    const skellycount = [1, 2, 3];
    return (
      <tbody
        className={this.props.loading ? "skelly-tbody" : "skelly-tbody d-none"}
      >
        {skellycount.map((repeat, r) => {
          return (
            <tr key={r}>
              {this.props.widths.map((cell, i) => {
                return (
                  <td key={i}>
                    <SkellyBar
                      width={cell ? cell : false}
                      height={this.props.height ? this.props.height : false}
                      dark={this.props.dark}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }
}
