import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

class AIS extends Component {
  doEmbed = () => {
    let width = "100%"; // the width of the embedded map in pixels or percentage
    let height = "800"; // the height of the embedded map in pixels or percentage
    let border = "0"; // the width of the border around the map (zero means no border)
    let shownames = "true"; // to display ship names on the map (true or false)

    let latitude = "14.8020"; // the latitude of the center of the map, in decimal degrees
    let longitude = "120.2687"; // the longitude of the center of the map, in decimal degrees

    /*
        FOR PORT OF MARIVELES
    let   latitude='14.4296'; // the latitude of the center of the map, in decimal degrees
    let    longitude='120.4992';  // the longitude of the center of the map, in decimal degrees
    let */

    let zoom = "14"; // the zoom level of the map (values between 2 and 17)
    let maptype = "1"; // use 0 for Normal Map, 1 for Satellite, 2 for OpenStreetMap
    let trackvessel = "0"; // MMSI of a vessel (note: vessel will be displayed only if within range of the system) - overrides "zoom" option
    let fleet = ""; // the registered email address of a user-defined fleet (user's default fleet is used)
    let language = "en";
    let trackshipid = null;
    let fleet_id = null;
    let vtypes = null;
    let showmenu = null;
    let remember = false;
    let localembed = "";
    let src = localembed
      ? "http://marinetraffic.local/"
      : "http://www.marinetraffic.com/";

    if ("https:" === document.location.protocol) {
      /* secure */
      src = localembed
        ? "https://marinetraffic.local/"
        : "https://www.marinetraffic.com/";
    }

    if (
      window.latitude === undefined &&
      window.longitude === undefined &&
      ((window.fleet !== undefined && window.fleet !== "") ||
        (window.fleet_id !== undefined && window.fleet_id !== ""))
    ) {
      window.latitude = 0;
      window.longitude = 0;
    }

    if (typeof window.mtembedcode != "undefined") {
      var overridenLatLon = "";
      if (
        window.latitude !== undefined &&
        window.latitude !== "" &&
        window.longitude !== undefined &&
        window.longitude !== ""
      ) {
        if (window.zoom === undefined) {
          window.zoom = 3;
        }
        overridenLatLon =
          "/zoom:" +
          (window.zoom === undefined ? "" : zoom) +
          "/centery:" +
          (window.latitude === undefined ? "" : latitude) +
          "/centerx:" +
          (window.longitude === undefined ? "" : longitude);
      }
      document.write(
        '<iframe name="marinetraffic" id="marinetraffic"' +
          ' width="' +
          (window.width === undefined ? "550" : width) +
          '"' +
          ' height="' +
          (window.height === undefined ? "300" : height) +
          '"' +
          ' scrolling="no" frameborder="' +
          (window.border === undefined ? "0" : border) +
          '"' +
          ' src="' +
          src +
          (window.language === undefined ? "en" : language) +
          "/ais/customembed" +
          overridenLatLon +
          "/mmsi:" +
          (window.trackvessel === undefined ? "0" : trackvessel) +
          (window.trackimo !== undefined
            ? "/trackimo:" + window.trackimo
            : "") +
          "/mtembedcode:" +
          window.mtembedcode +
          '">Browser does not support embedded objects.<br/>Visit directly <a href="http://www.marinetraffic.com/">www.marinetraffic.com</a>' +
          "</iframe>\n"
      );
    } else {
      document.write(
        '<iframe name="marinetraffic" id="marinetraffic"' +
          ' width="' +
          width +
          '"' +
          ' height="' +
          height +
          '"' +
          ' scrolling="no" frameborder="' +
          border +
          '"' +
          ' src="' +
          src +
          language +
          "/ais/embed" +
          "/zoom:" +
          zoom +
          "/centery:" +
          latitude +
          "/centerx:" +
          longitude +
          "/maptype:" +
          maptype +
          "/shownames:" +
          shownames +
          "/mmsi:" +
          (window.trackvessel === undefined ? "0" : trackvessel) +
          "/shipid:" +
          (window.trackshipid === undefined ? "0" : trackshipid) +
          "/fleet:" +
          (window.fleet === undefined ? "" : fleet) +
          "/fleet_id:" +
          (window.fleet_id === undefined ? "" : fleet_id) +
          "/vtypes:" +
          (window.vtypes === undefined ? "" : vtypes) +
          "/showmenu:" +
          (window.showmenu === undefined ? "" : showmenu) +
          "/remember:" +
          (window.remember === undefined ? "false" : remember) +
          '">Browser does not support embedded objects.<br/>Visit directly <a href="http://www.marinetraffic.com/">www.marinetraffic.com</a>' +
          "</iframe>\n"
      );
    }
  };

  componentDidMount = () => {
    this.doEmbed();
  };

  render() {
    return (
      <div>
        <Navbar />
      </div>
    );
  }
}

export default withRouter(AIS);
