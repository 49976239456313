import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import "./Inbox.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import back_icon from "../../img/svg/BackIcon.svg";
import api from "../../helpers/api.helper";
import moment from "moment";
import constants from "../../constants";
import portWebsocket from "../../portWebsocket";
import session from "../../session";
import Media from "react-media";
import Icon from "react-fa";
import PageHeader from "../Tools/PageHeader/PageHeader";
import { DropdownList } from "react-widgets";

let ValueInput = ({ item }) => (
  <>
    <Icon name="filter" />
    {item !== undefined ? (
      <span>{item}</span>
    ) : (
      <span className="plch">Filter</span>
    )}
  </>
);

class Inbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __port_call: {},
      _INBOX: []
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call"
        })
        .then(data => {});

      api
        .get(this, {
          endpoint: "inbox/" + this.state.__ID,
          state_key: "_INBOX"
        })
        .then(data => {
          portWebsocket.events.inbox = oMsg => {
            if (oMsg.parent_id === "" && this.state.__ID === oMsg.portcall_id) {
              console.log("this msg is yours, update immediately");

              let aCurrentInbox = this.state._INBOX;

              if (
                oMsg.parent_id === "" &&
                aCurrentInbox.find(v => {
                  return v._id === oMsg._id;
                })
              ) {
                let iIndexOfUpdatedMsg = aCurrentInbox
                  .map(v => {
                    return v._id;
                  })
                  .indexOf(oMsg._id);

                aCurrentInbox.splice(iIndexOfUpdatedMsg, 1);
                aCurrentInbox = aCurrentInbox.concat(oMsg);
              } else {
                aCurrentInbox = aCurrentInbox.concat(oMsg);
              }

              this.setState({
                _INBOX: aCurrentInbox
              });
            }
          };
        });
    }
  };

  render() {
    const vd = this.state.__port_call;
    const { _INBOX } = this.state;
    const { _SESSION } = session.get();
    console.log("TEST", _INBOX);

    return (
      <div className="Inbox">
        <div className="d-flex flex-column">
          <Navbar />
          <PortCallToolbar />
          <Media queries={constants.media_queries}>
            {matches =>
              matches.xs ? (
                <Sidenav
                  dataFromParent={this.props.match.params.id}
                  visit={vd}
                />
              ) : (
                <PageHeader
                  hideDocToggle
                  sendVName={vd.vessel_name}
                  sendVoyageNo={vd.voyage_number}
                />
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {matches =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      visit={vd}
                    />
                  </Col>
                ) : (
                  <PageHeader
                    hideDocToggle
                    sendVName={vd.vessel_name}
                    sendVoyageNo={vd.voyage_number}
                    pagename="Inbox"
                  />
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              <div className="form-container-box bt-green">
                <Form>
                  <Col xs="12" className="fcb-title">
                    <p>Inbox</p>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() =>
                        this.props.history.push(
                          "/port_call/inbox_create/" + this.state.__ID
                        )
                      }
                    >
                      <Media queries={constants.media_queries}>
                        {matches =>
                          matches.xs ? <Icon name="plus" /> : "ADD NOTE"
                        }
                      </Media>
                    </Button>
                  </Col>

                  <DropdownList
                    data={["-", "--", "---"]}
                    value={this.state.value}
                    valueComponent={ValueInput}
                    onChange={value => this.setState({ value })}
                  />

                  <Row className="inboxList" xs="1" md="3">
                    {_INBOX
                      .sort((a, b) => {
                        return ("" + b._id).localeCompare(a._id);
                      })
                      .map((inbox, i) => (
                        <Col
                          key={i}
                          className="inboxMessage"
                          onClick={() =>
                            this.props.history.push(
                              "/port_call/inbox_view/" +
                                this.state.__ID +
                                "/" +
                                inbox._id
                            )
                          }
                        >
                          {inbox.seen_by &&
                          (inbox.seen_by
                            .map(v => {
                              return v.role;
                            })
                            .indexOf(session.get().role) < 0 ||
                            inbox.seen_by.length === 0 ||
                            (inbox.seen_by
                              .map(v => {
                                return v.role;
                              })
                              .indexOf(session.get().role) > -1 &&
                              inbox.seen_by.find(v => {
                                return v.role === session.get().role;
                              }).unread_count > 0)) ? (
                            <div className="msgCountCon">
                              <span className="msgCount">
                                {(inbox.seen_by.find(v => {
                                  return v.role === session.get().role;
                                }) &&
                                  inbox.seen_by.find(v => {
                                    return v.role === session.get().role;
                                  }).unread_count) ||
                                  inbox.message_count}{" "}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          <Row className="inboxDetails">
                            <Col>
                              <img
                                src={require("../../img/profile/profile_" +
                                  inbox.user.charAt(0).toLowerCase() +
                                  ".png")}
                                alt="user img"
                                className=""
                                width="40"
                                height="40"
                              />
                            </Col>
                            <Col>
                              <span className="user">{inbox.user}</span>
                              <span className="userDesc">{inbox.position}</span>
                            </Col>
                            <Col xs="12">
                              <span>
                                <p>{inbox.subject}</p>
                              </span>
                              <span>
                                {inbox.category} &middot;{" "}
                                {moment(inbox.created_at).format(
                                  constants.__dateFormat
                                )}{" "}
                                {moment(inbox.created_at).format(
                                  constants.__timeFormat_ss
                                )}
                              </span>
                            </Col>
                          </Row>

                          <Row className="inboxContent">
                            <Col>{inbox.message}</Col>
                          </Row>
                        </Col>
                      ))}
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(Inbox);
