// define all amendable data
const amendableFields = {
  eta: "ETA",
  etd: "ETD",
  vessel_name: "Vessel Name",
  beam: "Beam",
  grt: "GRT",
  nrt: "NRT",
  dwt: "DWT",
  loa: "LOA",
  draft: "Draft",
  vessel_imo: "IMO Number",
  vessel_class: "Vessel Class",
  country: "Country",
  vessel_owner: "Owner",
  voyage_number: "Voyage Number",
  port_of_origin: "Port of Origin",
  port_of_destination: "Port of Destination",
  next_port_of_destination: "Next Port of Destination",
  master: "Master",
  tug_boat_company: "Tug Boat Company",
};

const relatedData = {
  tug_boat_company: ["tugboat_company_id"],
  vessel_name: ["vessel_id"],
};

const makeAmendment = ({
  origData,
  newData,
  user_id,
  amended_by,
  portcall_id,
}) => {
  // console.log("MAKE AMENDMENT");
  let amendments = [];
  const fields = {};
  console.log(origData, newData);
  if (origData && newData) {
    Object.keys(amendableFields).forEach((field) => {
      let displayField = amendableFields[field];
      if (
        typeof origData[field] !== "undefined" &&
        typeof newData[field] !== "undefined" &&
        newData[field] !== origData[field]
      ) {
        // data changed! create amendment

        let additionalData = null;
        if (typeof relatedData[field] !== "undefined") {
          additionalData = {};
          relatedData[field].forEach((val) => {
            additionalData[val] = newData[val];
          });
        }

        fields[field] = {
          display_field: displayField,
          from_value: origData[field],
          to_value: newData[field],
        };

        amendments = {
          fields,
          user_id: user_id,
          amended_by: amended_by,
          portcall_id: portcall_id,
          additional_fields: {
            additionalData,
          },
        };
      }
    });
  }

  // console.log("AMEND", amendments);

  return amendments;
};

module.exports = makeAmendment;
