import React, { Component, useState } from "react";
import {
  Col,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
  Form,
  FormGroup,
  Label,
  Popover,
  PopoverHeader,
  PopoverBody
} from "reactstrap";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { Icon } from "react-fa";
import Moment from "react-moment";
import constants from "../../constants";
import Datetime from "react-datetime";
import { DropdownList } from "react-widgets";
import moment from "moment";
import api from "../../helpers/api.helper";
import "./BerthManagement.scss";
import Navbar from "../Navbar/Navbar";
import apiHelper from "../../helpers/api.helper";
import AlertModal from "../Tools/AlertModal/AlertModal";
import vessel_placeholder from "../../img/VesselImagePlaceholder.jpg";
import session from "../../session";

export default class BerthManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideBar: false,
      is_active: false,
      __berths: [],
      __data: {},
      __currently_berthed: [],
      __cb_vessel: [],
      __first_berth: "",
      __berth_id: "",
      __date_time_format: constants.__dateTimeFormat,
      schedToggle: "day",
      schedToggle1: "day",
      berthSchedToggle: false,
      berthPlans: [],
      berthSchedule: [],
      berth_plan_schedule: {},
      dates: [],
      dayIndex: 0,
      unassignedVessels: [],
      berthAssignment: {},
      formattedUnassignedVessels: {},
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
      PUBLIC_BP: []
    };
  }

  handleAssembleCalendarByDay = dayIndex => {
    let { dates, tmp_berth_sched } = this.state;

    if (dates[dayIndex]) {
      let date = dates[dayIndex];

      for (let x in tmp_berth_sched) {
        let b_sched = tmp_berth_sched[x];
        let bar = {};
        tmp_berth_sched[x].bars = [];
        for (let y in b_sched.plans) {
          let plan = b_sched.plans[y];
          bar = {};

          let prev_status = null; // Previous status

          // looping through dates
          /**
           *  Check if there's an existing date inside dt_checker variable, if there's no existing date, it means that the entire day is available,
           * therefore, we will either add 24 to the counter, or assign 24 to counter depending on the previous state.
           */

          if (typeof plan.dates[date] !== "undefined") {
            /**
             *   IF THE DATE EXISTS INSIDE THE b_sched, therefore some time or the entire day is already taken,
             * to check we should loop through 0-23 (00 = 12 AM , 23 = 11 PM)
             *
             */
            for (let z = 0; z <= 23; z++) {
              /**
               * curr_status - will check if the HH (hour) exists inside the b_sched. if it is existing, the time is already taken.
               * if false, time is available.
               */
              let curr_status = plan.dates[date].indexOf(this.pad(z)) > -1; // NOTE we are padding the z variable sothat 0 will become 00, 1 will become 01 and so on.

              if (curr_status) {
                /**
                 *  as stated, if curr_status = true, the time is alrady taken
                 */
                if (!prev_status || prev_status === null) {
                  // prev_status is the checking in prev loop pass. check if prev_status is also null (meaning it's the first pass)
                  /** if prev_status and curr_status are not equal, therefore, we have to push the prev count and create new one. */

                  if (curr_status !== prev_status || prev_status === null) {
                    if (bar.type) {
                      /**
                       * in here we will assemble the to and from for available, for click function of available
                       *
                       */
                      bar.btn_props = {};
                      bar.btn_props.from = new Date(
                        bar.base_date + " " + bar.base_time + ":00"
                      );
                      bar.btn_props.to = new Date(
                        date + " " + this.pad(z - 1) + ":00"
                      );

                      tmp_berth_sched[x].bars.push(bar);
                    }

                    bar = {};
                    bar.type = "taken";
                    bar.vessel_name = plan.details.vessel_name;
                    bar.service = plan.details.service;
                    bar.eta = plan.details.eta;
                    bar.etd = plan.details.etd;

                    bar.count = 0;
                  }
                }
              } else {
                // we are just doing the same thing here, but for available slot.
                if (prev_status || prev_status === null) {
                  if (curr_status !== prev_status || prev_status === null) {
                    if (bar.type) {
                      tmp_berth_sched[x].bars.push(bar);
                    }
                    bar = {};
                    bar.base_date = date;
                    bar.base_time = this.pad(z);
                    bar.type = "available";
                    bar.count = 0;
                  }
                }
              }
              bar.count++;
              prev_status = curr_status;
            }
          } else {
            bar = {
              btn_props: {
                to: new Date(date + " 23:59"),
                from: new Date(date + " 00:00")
              },
              class: "available",
              count: 24
            };
          }
        }
        tmp_berth_sched[x].bars.push(bar);
      }

      let calendar_header_display = moment(date).format("MMMM DD, YYYY");
      let berthSchedule = [];

      for (let x in tmp_berth_sched) {
        berthSchedule.push({
          berth: tmp_berth_sched[x].details.berth,
          bars: tmp_berth_sched[x].bars
        });
      }

      this.setState({
        berthSchedule,
        calendar_header_display,
        dayIndex
      });
    }
  };

  getBerthPlan = () => {
    api
      .get(this, {
        endpoint: "berth_plan",
        state_key: "berthPlans"
      })
      .then(data => {
        let bPlans = [];

        for (let x in data) {
          if (data[x].is_public === true || data[x].status_id === 1) {
            bPlans[x] = data[x];
          }
        }

        this.setState({
          PUBLIC_BP: bPlans
        });

        // console.log("BPssss", bPlans);
      });
  };

  componentDidMount = () => {
    this.loadData();
    this.getBerthPlan();
    this.handleAssembleCalendar().then(() => {
      this.handleAssembleCalendarByDay(0);
    });
    this.handleGetUnassigned();
    // this.showCurrentlyBerthed();
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool
    });

    // console.log("ALERT", bool);
  };

  pad = (num, size) => {
    size = 2;
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  loadData = () => {
    api.get(this, { endpoint: "berth", state_key: "__berths" }).then(data => {
      if (data && data.length > 0) {
        this.showCurrentlyBerthed(data[0]._id);
      }
    });
  };

  handleGetBerthSchedule = () => {
    let eta = moment(new Date()),
      etd = moment(eta).add(7, "days");
    api
      .get(this, {
        endpoint:
          "berth_plan/schedule/all" +
          "/" +
          moment(eta).format("YYYY-MM-DD") +
          "/" +
          moment(etd).format("YYYY-MM-DD"),
        state_key: "berth_plan_schedule",
        preserve_state: true // NOTE, WE SHOULD PRESERVE THE STATE BECAUSE WE WILL BE DOING SOME PROCESSING IN THE DATA.
      })
      .then(res => {
        return new Promise.resolve(res);
      });
  };

  /**
   *  THE METHOD THAT WILL PLOT THE GANTT CHART
   *
   * This method will connect to an API end point that will return the berth plans that is inside the date range
   */
  handleAssembleCalendar = (eta, etd) => {
    // let eta = moment(new Date()).subtract(3, "days"),
    //   etd = moment(new Date()).add(4, "days");
    eta = eta ? eta : moment(new Date());
    etd = etd ? etd : moment(eta).add(7, "days");

    let gantt_start = eta;
    let gantt_end = etd;
    if (eta && etd) {
      /**
       * send api request to get all berth plan for the selected berth between the given dates.
       */
      return api
        .get(this, {
          endpoint:
            "berth_plan/schedule/all" +
            "/" +
            moment(eta).format("YYYY-MM-DD") +
            "/" +
            moment(etd).format("YYYY-MM-DD"),
          state_key: "berth_schedule",
          preserve_state: true // NOTE, WE SHOULD PRESERVE THE STATE BECAUSE WE WILL BE DOING SOME PROCESSING IN THE DATA.
        })
        .then(berth_plan_schedule => {
          let range_dt_start = moment(eta);
          let range_dt_end = moment(etd);
          let tmp = [];
          let calendar_header_display = "";
          let dates = []; // This will contain the flat array of the dates between the ETA and ETD. ['2019-11-04', '2019-11-05', '2019-11-06', ....]
          while (range_dt_start <= range_dt_end) {
            dates.push(moment(range_dt_start).format("YYYY-MM-DD"));
            range_dt_start.add(1, "days");
          }

          if (berth_plan_schedule && berth_plan_schedule.length > 0) {
            for (let x in berth_plan_schedule) {
              if (typeof tmp[berth_plan_schedule[x].berth_id] === "undefined") {
                tmp[berth_plan_schedule[x].berth_id] = [];
              }
              tmp[berth_plan_schedule[x].berth_id].push(berth_plan_schedule[x]);
            }

            for (let x in tmp) {
              let berthPlans = tmp[x];
              for (let y in berthPlans) {
                let dt_checker = [];
                let bar = {};
                let prev_status = null; // Previous status
                let running_hours = null;
                let sched = berthPlans[y];
                let end_time = moment(sched.etd).add(1, "hours");
                if (running_hours === null) {
                  running_hours = moment(sched.eta);
                }

                if (!tmp[x][y].bars) {
                  tmp[x][y].bars = [];
                }

                while (running_hours <= end_time) {
                  let start_hour = moment(running_hours).format("HH");

                  if (
                    typeof dt_checker[
                      moment(running_hours).format("YYYY-MM-DD")
                    ] === "undefined"
                  ) {
                    dt_checker[moment(running_hours).format("YYYY-MM-DD")] = [];
                  }

                  if (
                    dt_checker[
                      moment(running_hours).format("YYYY-MM-DD")
                    ].indexOf(start_hour) === -1
                  ) {
                    dt_checker[moment(running_hours).format("YYYY-MM-DD")].push(
                      start_hour
                    );
                  }

                  running_hours = moment(running_hours).add(1, "hours");
                }

                for (let dt in dates) {
                  // looping through dates
                  /**
                   *  Check if there's an existing date inside dt_checker variable, if there's no existing date, it means that the entire day is available,
                   * therefore, we will either add 24 to the counter, or assign 24 to counter depending on the previous state.
                   */
                  if (typeof dt_checker[dates[dt]] !== "undefined") {
                    /**
                     *   IF THE DATE EXISTS INSIDE THE dt_checker, therefore some time or the entire day is already taken,
                     * to check we should loop through 0-23 (00 = 12 AM , 23 = 11 PM)
                     *
                     */
                    for (let z = 0; z <= 23; z++) {
                      /**
                       * curr_status - will check if the HH (hour) exists inside the dt_checker. if it is existing, the time is already taken.
                       * if false, time is available.
                       */
                      let curr_status =
                        dt_checker[dates[dt]].indexOf(this.pad(z)) > -1; // NOTE we are padding the z variable sothat 0 will become 00, 1 will become 01 and so on.

                      if (curr_status) {
                        /**
                         *  as stated, if curr_status = true, the time is alrady taken
                         */
                        if (!prev_status || prev_status === null) {
                          // prev_status is the checking in prev loop pass. check if prev_status is also null (meaning it's the first pass)
                          /** if prev_status and curr_status are not equal, therefore, we have to push the prev count and create new one. */

                          if (
                            curr_status !== prev_status ||
                            prev_status === null
                          ) {
                            if (bar.class) {
                              /**
                               * in here we will assemble the to and from for available, for click function of available
                               *
                               */
                              bar.btn_props = {};
                              bar.btn_props.from = new Date(
                                bar.base_date + " " + bar.base_time + ":00"
                              );
                              bar.btn_props.to = new Date(
                                dates[dt] + " " + this.pad(z - 1) + ":00"
                              );

                              tmp[x][y].bars.push(bar);
                            }

                            bar = {};
                            bar.class = "taken";
                            bar.count = 0;
                          }
                        }
                      } else {
                        // we are just doing the same thing here, but for available slot.
                        if (prev_status || prev_status === null) {
                          if (
                            curr_status !== prev_status ||
                            prev_status === null
                          ) {
                            if (bar.class) {
                              tmp[x][y].bars.push(bar);
                            }
                            bar = {};
                            bar.base_date = dates[dt];
                            bar.base_time = this.pad(z);
                            bar.class = "available";
                            bar.count = 0;
                          }
                        }
                      }
                      bar.count++;
                      prev_status = curr_status;
                    }
                  } else {
                    /**
                     * as stated, the entire day is available.
                     * let's check prev_status, if prev_status === FALSE, therefore, the prev loop pass is an available time(Hours) therefore we should add 24.
                     *
                     * IF prev_status === TRUE the prev loop pass is a taken time (hours) therefore we should push the existing bar in our bar collection called bars and create new one for available.
                     */
                    if (!prev_status && prev_status !== null) {
                      bar.count += 24;
                    } else {
                      if (bar.class) {
                        tmp[x][y].bars.push(bar); // the prev bar is taken, therefore, we should stop counting. push the bar inside bars and create new one for available.
                      }
                      prev_status = false;
                      bar.base_date = dates[dt];
                      bar.base_time = this.pad(0);
                      bar.class = "available";
                      bar.count = 24;
                    }
                  }
                }
                tmp[x][y].bars.push(bar);
              }
            }
          }

          if (dates.length > 0) {
            calendar_header_display = moment(dates[0]).format("MMMM DD");

            if (
              moment(dates[0]).format("YYYY") !==
              moment(dates[dates.length - 1]).format("YYYY")
            ) {
              calendar_header_display += ", " + moment(dates[0]).format("YYYY");
            }

            calendar_header_display += " – ";

            if (
              moment(dates[0]).format("MMMM") !==
              moment(dates[dates.length - 1]).format("MMMM")
            ) {
              calendar_header_display += moment(dates[dates.length - 1]).format(
                "MMMM"
              );
            }

            calendar_header_display += moment(dates[dates.length - 1]).format(
              " DD, "
            );

            // if (!is_year_equal) {
            //   calendar_header_display += ", ";
            // }

            calendar_header_display +=
              " " + moment(dates[dates.length - 1]).format("YYYY");
          }
          this.setState({
            berthSchedule: tmp,
            dates,
            calendar_header_display,
            gantt_start,
            gantt_end
          });
        });
    }
  };

  showCurrentlyBerthed = id => {
    let berths = this.state.__berths;
    let _berth_arr = {
      __currently_berthed: [],
      __selected_berth_name: []
    };
    for (let x in berths) {
      let berth = berths[x];
      berths[x].is_active = false;
      if (berth._id === id) {
        berths[x].is_active = true;
        _berth_arr.__currently_berthed = berths[x].currently_berthed;
        _berth_arr.__selected_berth_name = berths[x].name;
      }
    }
    // console.log(_berth_arr);
    this.setState({ ..._berth_arr, __berths: berths });
  };

  handleGetUnassigned = () => {
    api
      .get(this, {
        endpoint: "portcall",
        state_key: "unassignedVessels",
        request_params: {
          search: {
            is_berth_assigned: false
          }
        }
      })
      .then(() => {
        // this.handleAssembleUnassignedBar();
      });
  };

  addWeek = () => {
    let { gantt_start, gantt_end } = this.state;
    gantt_start = gantt_end;
    gantt_end = moment(gantt_start).add(7, "days");

    return { gantt_start, gantt_end };
  };

  subtractWeek = () => {
    let { gantt_start, gantt_end } = this.state;
    gantt_end = gantt_start;
    gantt_start = moment(gantt_start).subtract(7, "days");

    return { gantt_start, gantt_end };
  };

  render() {
    const {
      __berths,
      snavFixed,

      __currently_berthed,
      __selected_berth_name,

      schedToggle,
      schedToggle1,
      berthSchedToggle,
      berthSchedule,
      tglAlert,
      isAlert,
      alertMsg,
      PUBLIC_BP
    } = this.state;

    const Duration = props => {
      const [tooltipOpen, setTooltipOpen] = useState(false);

      const toggle = () => setTooltipOpen(!tooltipOpen);

      return (
        <span>
          <div
            className={
              props.label === "Berth Duration"
                ? "vessel-ata fwb d-flex flex-row"
                : "vessel-ata d-flex flex-row justify-content-end"
            }
          >
            <Moment
              format={constants.__dateTimeFormat}
              id={"Tooltip-" + props.id}
            >
              {props.dateFrom || "-"}
            </Moment>
            <div className="mx-1">to</div>
            <Moment format={constants.__dateTimeFormat}>
              {props.dateTo || "-"}
            </Moment>
          </div>
          <Tooltip
            placement="left"
            isOpen={tooltipOpen}
            target={"Tooltip-" + props.id}
            toggle={toggle}
          >
            {props.label}
          </Tooltip>
        </span>
      );
    };

    const BerthSlot = props => {
      const [popoverOpen, setPopoverOpen] = useState(false);
      const toggle = () => setPopoverOpen(!popoverOpen);
      let classes =
        "berthSlot d-flex flex-row flex-wrap " + props.className + " ";
      if (props.status === "0") {
        classes += "pending";
      } else if (props.status === "1") {
        classes += "active";
      }

      if (!props.fulldetail && !props.justStatus) {
        return (
          <div className={classes}>
            <img
              src={props.image ? props.image : vessel_placeholder}
              alt="vessel img"
              className="col-md-2"
            />
            <div className="col-md-5 d-flex flex-column justify-content-between p-0">
              <div className="vessel-name">
                {props.name}, {props.voyage_num}
              </div>
              {props.berthSched_from !== null &&
              props.berthSched_to !== null ? (
                <Duration
                  dateFrom={props.berthSched_from}
                  dateTo={props.berthSched_to}
                  label="Berth Duration"
                  id={"berth-" + props.id}
                />
              ) : (
                <div className="unassigned">Unassigned</div>
              )}
            </div>
            <div className="col-md-5 d-flex flex-column justify-content-between p-0">
              <Duration
                dateFrom={props.port_from}
                dateTo={props.port_to}
                label="Port Duration"
                id={"port-" + props.id}
              />
              <ul className="vessel-purpose">
                <li>{props.purpose}</li>
              </ul>
              {props.berthSched_from === null &&
              props.berthSched_to === null ? (
                <AssignVessel
                  className="col-md-12 p-0 d-flex flex-row"
                  portcall_id={props.portcall_id}
                  vessel_name={props.vessel_name}
                  services={props.selected_port_call_purposes}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        );
      } else if (props.fulldetail && !props.justStatus) {
        return (
          <div className={"fulldetail " + classes}>
            <div className="d-flex flex-column justify-content-between col-md-6 p-0">
              <div className="vessel-name">
                {props.name}, {props.voyage_num}
              </div>
              <div className="vessel-ata">
                {props.bs_from !== null && props.bs_to !== null ? (
                  <React.Fragment>
                    <Moment format="HH:mm">{props.bs_from || "-"}</Moment>
                    {" - "}
                    <Moment format="HH:mm">{props.bs_to || "-"}</Moment>
                  </React.Fragment>
                ) : (
                  <div className="unassigned">Unassigned</div>
                )}
              </div>
              <div className="vessel-purpose">{props.purpose}</div>
            </div>
            <div className="d-flex flex-column justify-content-between col-md-6 p-0 text-right">
              <div className="vessel-ata">
                <Moment format="MMM DD HH:mm">{props.ata || "-"}</Moment>
                {" - "}
                <Moment format="MMM DD HH:mm">{props.atd || "-"}</Moment>
              </div>
              <div className="vessel-ata">{props.product}</div>
              <div className="vessel-ata">{props.product_weight}</div>
            </div>
            {props.bs_from === null && props.bs_to === null ? (
              <AssignVessel
                className="col-md-12 p-0 d-flex flex-row"
                portcall_id={props.portcall_id}
                vessel_name={props.vessel_name}
                services={props.selected_port_call_purposes}
              />
            ) : (
              ""
            )}
          </div>
        );
      } else if (!props.fulldetail && props.justStatus) {
        return (
          <span>
            <div className={"justStatus " + classes} id={"Popover-" + props.id}>
              <div>{props.purpose}</div>
            </div>
            <Popover
              className={
                props.status === "0"
                  ? "justStatus-popover pending"
                  : props.status === "1"
                  ? "justStatus-popover active"
                  : "justStatus-popover"
              }
              placement="bottom"
              isOpen={popoverOpen}
              target={"Popover-" + props.id}
              toggle={toggle}
              trigger="legacy"
            >
              <PopoverHeader className="d-flex flex-row">
                <div className="vessel-name col-md-6 p-0">
                  {props.name}, {props.voyage_num}
                </div>
                <div className="vessel-ata col-md-6 p-0 text-right">
                  {props.bs_from !== null && props.bs_to !== null ? (
                    <React.Fragment>
                      <Moment format="HH:mm">{props.bs_from || "-"}</Moment>
                      {" - "}
                      <Moment format="HH:mm">{props.bs_to || "-"}</Moment>
                    </React.Fragment>
                  ) : (
                    <div className="unassigned">Unassigned</div>
                  )}
                </div>
              </PopoverHeader>
              <PopoverBody className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-column justify-content-between col-md-6 p-0">
                  <div className="vessel-ata">
                    <Moment format="MMM DD HH:mm">{props.ata || "-"}</Moment>
                    {" - "}
                    <Moment format="MMM DD HH:mm">{props.atd || "-"}</Moment>
                  </div>
                  <div className="vessel-purpose">{props.purpose}</div>
                </div>
                <div className="d-flex flex-column justify-content-between col-md-6 p-0 text-right">
                  <div className="vessel-ata">{props.product}</div>
                  <div className="vessel-ata">{props.product_weight}</div>
                </div>
                {props.bs_from === null && props.bs_to === null ? (
                  <AssignVessel
                    className="col-md-12 p-0 d-flex flex-row"
                    portcall_id={props.portcall_id}
                    vessel_name={props.vessel_name}
                    services={props.selected_port_call_purposes}
                  />
                ) : (
                  ""
                )}
              </PopoverBody>
            </Popover>
          </span>
        );
      }
    };

    const BerthSchedule = props => {
      let prev_day = null;
      let week_start = null;
      let week_end = null;
      let this_month = null;
      const today =
        new Date().getFullYear() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getDate();
      const thisMonthVal = new Date().getMonth() + 1;
      function compare(a, b) {
        const berthSchedA = a.berthSched;
        const berthSchedB = b.berthSched;

        let comparison = 0;
        if (berthSchedA > berthSchedB) {
          comparison = 1;
        } else if (berthSchedA < berthSchedB) {
          comparison = -1;
        }
        return comparison;
      }

      function prevDay(d) {
        if (!d) {
          return <li className="schedule-day d-flex">-</li>;
        }
        let dtItem = moment(d).format("YYYY/MM/DD");

        if (prev_day !== dtItem) {
          prev_day = dtItem;
          return (
            <li className="schedule-day d-flex">
              {dtItem === today ? (
                <div>Today</div>
              ) : (
                <Moment format={"MMMM DD, YYYY, dddd"}>{d || "-"}</Moment>
              )}
            </li>
          );
        } else {
          return <div className="hr-div"></div>;
        }
      }

      function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
      }

      function thisWeek(d) {
        let week_temp = getMonday(d);
        let thisWeek_start =
          week_temp.getFullYear() +
          "/" +
          (week_temp.getMonth() + 1) +
          "/" +
          week_temp.getDate();
        let thisWeek_end =
          week_temp.getFullYear() +
          "/" +
          (week_temp.getMonth() + 1) +
          "/" +
          (week_temp.getDate() + 6);

        if (!(thisWeek_start === week_start && thisWeek_end === week_end)) {
          week_start = thisWeek_start;
          week_end = thisWeek_end;

          return (
            <li className="schedule-day d-flex">
              {new Date(today) >= new Date(week_start) &&
              new Date(today) <= new Date(week_end) ? (
                <div>This Week</div>
              ) : (
                <React.Fragment>
                  <Moment format={"MMMM DD"}>
                    {new Date(week_start) || "-"}
                  </Moment>
                  <span className="mx-1">-</span>
                  <Moment
                    format={
                      new Date(week_start).getMonth() ===
                      new Date(week_end).getMonth()
                        ? "DD, YYYY"
                        : "MMMM DD, YYYY"
                    }
                  >
                    {new Date(week_end) || "-"}
                  </Moment>
                </React.Fragment>
              )}
            </li>
          );
        } else {
          return <div className="hr-div"></div>;
        }
      }

      function thisMonth(d) {
        let dtItem = d.getMonth() + 1;
        if (this_month !== dtItem) {
          this_month = dtItem;
          return (
            <li className="schedule-day d-flex">
              {dtItem === thisMonthVal ? (
                <div>This Month</div>
              ) : (
                <Moment format={"MMMM"}>{d || "-"}</Moment>
              )}
            </li>
          );
        } else {
          return <div className="hr-div"></div>;
        }
      }

      return (
        <React.Fragment>
          {props.data === null ? (
            <React.Fragment>{"No Schedules"}</React.Fragment>
          ) : (
            <ul
              className="scheduleList"
              style={{ width: "100%", maxHeight: "unset" }}
            >
              {props.data.sort(compare).map((b, $index) => {
                // console.log("DATA", props.data);
                //here

                return (
                  <React.Fragment key={$index}>
                    {props.schedType === "day"
                      ? prevDay(b.eta)
                      : props.schedType === "week"
                      ? thisWeek(b.eta)
                      : thisMonth(b.eta)}
                    <li className="schedule-item d-flex flex-row">
                      <div className="time-cont d-flex col-md-1">
                        <Moment
                          format={
                            props.schedType === "day"
                              ? constants.__timeFormat
                              : "MMM DD HH:mm"
                          }
                        >
                          {b.eta || "-"}
                        </Moment>
                      </div>
                      <BerthSlot
                        id={"sched" + $index}
                        className="col-md-11 m-0"
                        image={b.vessel_image}
                        status="0"
                        name={b.vessel_name}
                        voyage_num={b.voyage_number}
                        berthSched_from={props.unassigned ? null : b.eta}
                        berthSched_to={props.unassigned ? null : b.etd}
                        port_from={b.port_eta}
                        port_to={b.port_etd}
                        purpose={b.service}
                      ></BerthSlot>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          )}
        </React.Fragment>
      );
    };

    const AssignVessel = props => {
      const [modal, setModal] = useState(false);

      const toggle = () => setModal(!modal);
      // if (props.portcall_id) {
      //   setPortcallId(props.portcall_id);
      // }
      const berthAssignment = {};
      const handleAssignBerth = () => {
        this.showAlert("loading", "", true);
        berthAssignment.status_id = 0;
        berthAssignment.status = "Pending";
        berthAssignment.port_call_id = props.portcall_id;
        berthAssignment.vessel_name = props.vessel_name;
        apiHelper
          .save(this, {
            endpoint: "berth_plan",
            _data: berthAssignment
          })
          .then(() => {
            toggle();
            this.setState(
              {
                tglAlert: false
              },
              () => this.showAlert("success", "Vessel Assigned!", true)
            );
          });
      };
      return (
        <React.Fragment>
          <Button
            className="mt-2 ml-auto px-3"
            color="aesi-green"
            size="xs"
            onClick={toggle}
          >
            Assign
          </Button>
          <Modal isOpen={modal} toggle={toggle} className={props.className}>
            <ModalHeader toggle={toggle}>Assign Vessel</ModalHeader>
            <ModalBody>
              <div className="col-md-12 p-0">
                <FormGroup className="mb-5 col-md-12">
                  <Label for="exampleFile">Berth</Label>
                  <DropdownList
                    filter
                    // value={}
                    textField="name"
                    data={this.state.__berths}
                    onChange={berth => {
                      berthAssignment.berth_id = berth._id;
                      berthAssignment.berth = berth.name;
                    }}
                  />
                </FormGroup>
              </div>
              <div className="col-md-12 p-0">
                <FormGroup className="mb-5 col-md-12">
                  <Label for="exampleSelect">Service</Label>
                  <DropdownList
                    filter
                    // value={}
                    textField="service"
                    data={props.services}
                    onChange={pp => {
                      //let { berthAssignment } = this.state;
                      berthAssignment.service = pp.service;
                      berthAssignment.stevedoring = pp.stevedoring;
                      berthAssignment.arrastre = pp.arrastre;
                      // this.setState({ berthAssignment });
                    }}
                  />
                </FormGroup>
              </div>
              <div className="col-md-12 p-0 mt-3">
                <FormGroup className="mb-5 col-md-12">
                  <Label for="exampleFile">From</Label>
                  <Datetime
                    dateFormat={constants.__dateFormat}
                    timeFormat={constants.__timeFormat_ss}
                    // value={}
                    onChange={e => {
                      //let { berthAssignment } = this.state;
                      berthAssignment.eta = e;
                      // this.setState({ berthAssignment });
                    }}
                  />
                </FormGroup>
              </div>
              <div className="col-md-12 p-0 mt-3">
                <FormGroup className="mb-5 col-md-12">
                  <Label for="exampleFile">To</Label>
                  <Datetime
                    dateFormat={constants.__dateFormat}
                    timeFormat={constants.__timeFormat_ss}
                    // value={}
                    onChange={e => {
                      // let { berthAssignment } = this.state;
                      berthAssignment.etd = e;
                      // this.setState({ berthAssignment });
                    }}
                  />
                </FormGroup>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" outline onClick={toggle}>
                Cancel
              </Button>{" "}
              <Button
                color="aesi-green"
                onClick={e => {
                  // toggle();
                  handleAssignBerth();
                }}
              >
                Assign
              </Button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      );
    };

    const DisplayBars = props => {
      let display = [];
      let berths = [];
      let prevBerth = "";
      let data = props.data;
      for (let i in data) {
        for (let j in data[i]) {
          let index = berths.findIndex(x => x === data[i][j].berth);

          if (index === -1) {
            berths.push(data[i][j].berth);
          }
        }
      }
      berths.sort();

      berths.map((b, index) => {
        for (let i in data) {
          for (let j in data[i]) {
            if (data[i][j].berth === b) {
              let cell = [];
              let cellcount = 1;
              cell.push(
                <th>
                  <div>{`${data[i][j].vessel_name}`}</div>
                </th>
              );
              for (let k in data[i][j].bars) {
                let slot = [];
                if (data[i][j].bars[k].class === "taken") {
                  slot.push(
                    <BerthSlot
                      key={index}
                      justStatus
                      id={data[i][j]._id}
                      className="col-md-12"
                      status="0"
                      berth={data[i][j].berth}
                      name={data[i][j].vessel_name}
                      voyage_num={data[i][j].voyage_number}
                      bs_from={new Date(data[i][j].eta)}
                      bs_to={new Date(data[i][j].etd)}
                      purpose={data[i][j].service}
                    ></BerthSlot>
                  );
                }
                cell.push(<td colSpan={data[i][j].bars[k].count}>{slot}</td>);
                cellcount += data[i][j].bars[k].count;
              }

              if (b !== prevBerth) {
                display.push(
                  <tr className="berth-label">
                    <th colSpan={cellcount}>{b}</th>
                  </tr>
                );
                prevBerth = b;
              }

              display.push(<tr className="berth-sched">{cell}</tr>);
            }
          }
        }
        return "";
      });

      return display;
    };

    return (
      <Col md={{ size: 12 }} className="d-flex flex-column p-0 berthMgmt">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}
        <Navbar />

        <Col md={{ size: 12 }} className="main-content d-flex flex-row mt-3">
          <Col md={{ size: 2 }}>
            <div className={snavFixed ? "sidenav p-fixed" : "sidenav"}>
              <Router>
                <div className="sBarUl-con">
                  <ul className="">
                    <li className="title">Berths</li>
                    {__berths.map((b, i) => (
                      <li
                        key={i}
                        index={i}
                        onClick={() => {
                          let _bID = this.state.__berth_id;
                          _bID = b._id;

                          // this.setState({
                          //   isActive: true
                          // });

                          // console.log(isActive);

                          this.showCurrentlyBerthed(_bID);

                          this.setState({
                            berthSchedToggle: false
                          });
                        }}
                      >
                        <NavLink
                          className={
                            b.is_active && !berthSchedToggle ? "" : "no-active"
                          }
                          to="#"
                        >
                          {b.name}
                        </NavLink>
                      </li>
                    ))}
                    <div className="hr-div mt-3"></div>
                    <li
                      className="m-3"
                      onClick={() => {
                        this.setState({
                          berthSchedToggle: true
                        });
                      }}
                    >
                      <NavLink
                        className={berthSchedToggle ? "" : "no-active"}
                        to="#"
                      >
                        Manage Berth Schedule
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Router>
            </div>
          </Col>

          <Col md={{ size: 10 }} className="mc-right d-flex flex-column">
            {!berthSchedToggle ? (
              <React.Fragment>
                <div className="selectedBerthed px-2">
                  {__selected_berth_name}
                </div>

                {Object.keys(__currently_berthed).length > 0 ? (
                  <Form className="d-flex flex-column px-2 mb-3">
                    <div className="form-container-box bt-green w100per">
                      <div className="col-md-12 p-0 d-flex flex-row position-relative">
                        <div className="fcb-title">Currently Berthed</div>
                      </div>

                      <div className="d-flex flex-row flex-wrap mt-3">
                        {__currently_berthed.map((c, i) => (
                          <BerthSlot
                            key={i}
                            className="col-md-12"
                            image="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTQfpo-MM20jIX77YCO1tUlfUjto3ETPlYBHZly3gt83GOEoBS2"
                            status="1"
                            name={c.vessel_name}
                            voyage_num={c.voyage_number}
                            id={"current" + i}
                            berthSched_from={new Date("11-20-2019-00:00")}
                            berthSched_to={new Date("11-20-2019-01:00")}
                            port_from={new Date("11-20-2019-00:00")}
                            port_to={new Date("11-22-2019-18:00")}
                            purpose="Cargo Handling"
                          ></BerthSlot>
                        ))}
                      </div>
                    </div>
                  </Form>
                ) : (
                  <div className="aesi-alert alert-success d-flex align-items-center flex-row">
                    <div className="alert-icon">
                      <Icon name="exclamation" />
                    </div>
                    <div className="alert-content">
                      <div className="content">
                        <span>No vessels</span> are currently berthed.
                      </div>
                    </div>
                  </div>
                )}

                <Form className="d-flex flex-column px-2 mb-3">
                  <div className="form-container-box bt-orange w100per">
                    <div className="col-md-12 p-0 d-flex flex-row position-relative">
                      <div className="fcb-title d-flex flex-row">
                        Berth Schedule
                        <ButtonGroup className="ml-auto">
                          <Button
                            className="px-3"
                            color="aesi-green"
                            size="xs"
                            // onClick={}
                          >
                            <Icon name="chevron-left"></Icon>
                          </Button>
                          <Button
                            className="px-3"
                            color="aesi-green"
                            size="xs"
                            // onClick={}
                          >
                            <Icon name="chevron-right"></Icon>
                          </Button>
                        </ButtonGroup>
                        <ButtonGroup className="ml-3">
                          <Button
                            className="px-3"
                            color={
                              schedToggle1 === "day"
                                ? "aesi-active"
                                : "secondary"
                            }
                            size="xs"
                            onClick={() => {
                              this.setState({
                                schedToggle1: "day"
                              });
                            }}
                            active={schedToggle1 === "day"}
                            outline={schedToggle1 !== "day"}
                          >
                            Day
                          </Button>
                          <Button
                            className="px-3"
                            color={
                              schedToggle1 === "week"
                                ? "aesi-active"
                                : "secondary"
                            }
                            size="xs"
                            onClick={() => {
                              this.setState({
                                schedToggle1: "week"
                              });
                            }}
                            active={schedToggle1 === "week"}
                            outline={schedToggle1 !== "week"}
                          >
                            Week
                          </Button>
                          <Button
                            className="px-3"
                            color={
                              schedToggle1 === "month"
                                ? "aesi-active"
                                : "secondary"
                            }
                            size="xs"
                            onClick={() => {
                              this.setState({
                                schedToggle1: "month"
                              });
                            }}
                            active={schedToggle1 === "month"}
                            outline={schedToggle1 !== "month"}
                          >
                            Month
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>

                    <div className="d-flex flex-row flex-wrap mt-3">
                      <BerthSchedule
                        schedType={schedToggle1}
                        data={
                          session.get().role === "shipping_agent"
                            ? this.state.PUBLIC_BP
                            : this.state.berthPlans
                        }
                      />
                    </div>
                  </div>
                </Form>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Form className="d-flex flex-column px-2 mb-3">
                  <div className="form-container-box bt-green w100per">
                    <div className="col-md-12 p-0 d-flex flex-row position-relative">
                      <div className="fcb-title">Manage Berth Schedule</div>
                    </div>

                    <div className="col-md-12 d-flex flex-column p-0">
                      <table className="berths-schedule">
                        <thead>
                          <tr className="toolbar">
                            <th>&nbsp;</th>
                            <td colSpan={schedToggle === "day" ? "12" : "120"}>
                              <ButtonGroup className="mr-auto">
                                <Button
                                  className="border-none"
                                  color="secondary"
                                  size="sm"
                                  onClick={e => {
                                    e.preventDefault();
                                    if (schedToggle === "day") {
                                      let { dayIndex } = this.state;
                                      dayIndex--;

                                      if (dayIndex < 0) {
                                        let {
                                          gantt_start,
                                          gantt_end
                                        } = this.subtractWeek();

                                        this.handleAssembleCalendar(
                                          gantt_start,
                                          gantt_end
                                        ).then(() => {
                                          let { dates } = this.state;
                                          dayIndex = dates.length - 2;
                                          this.handleAssembleCalendarByDay(
                                            dayIndex
                                          );
                                        });
                                      } else {
                                        this.handleAssembleCalendarByDay(
                                          dayIndex
                                        );
                                      }
                                    } else {
                                      let {
                                        gantt_start,
                                        gantt_end
                                      } = this.subtractWeek();

                                      this.handleAssembleCalendar(
                                        gantt_start,
                                        gantt_end
                                      );
                                    }
                                  }}
                                  outline
                                >
                                  <Icon name="chevron-left"></Icon>
                                </Button>
                                <span>
                                  {this.state.calendar_header_display}
                                </span>
                                <Button
                                  className="border-none"
                                  color="secondary"
                                  size="sm"
                                  onClick={e => {
                                    e.preventDefault();
                                    if (schedToggle === "day") {
                                      let { dayIndex, dates } = this.state;
                                      dayIndex++;

                                      if (dates.length <= dayIndex) {
                                        let {
                                          gantt_start,
                                          gantt_end
                                        } = this.addWeek();
                                        this.handleAssembleCalendar(
                                          gantt_start,
                                          gantt_end
                                        ).then(() => {
                                          dayIndex = 1;
                                          this.handleAssembleCalendarByDay(
                                            dayIndex
                                          );
                                        });
                                      } else {
                                        this.handleAssembleCalendarByDay(
                                          dayIndex
                                        );
                                      }
                                    } else {
                                      let {
                                        gantt_start,
                                        gantt_end
                                      } = this.addWeek();

                                      this.handleAssembleCalendar(
                                        gantt_start,
                                        gantt_end
                                      );
                                    }
                                  }}
                                  outline
                                >
                                  <Icon name="chevron-right"></Icon>
                                </Button>
                              </ButtonGroup>
                            </td>
                            <td
                              colSpan={schedToggle === "day" ? "12" : "72"}
                              className="text-right"
                            >
                              <ButtonGroup className="ml-auto">
                                <Button
                                  className="px-3"
                                  color={
                                    schedToggle === "day"
                                      ? "aesi-active"
                                      : "secondary"
                                  }
                                  size="xs"
                                  onClick={() => {
                                    this.setState({
                                      schedToggle: "day"
                                    });
                                    let { dayIndex } = this.state;
                                    let {
                                      gantt_start,
                                      gantt_end
                                    } = this.subtractWeek();
                                    this.handleAssembleCalendar(
                                      gantt_start,
                                      gantt_end
                                    ).then(() => {
                                      let { dates } = this.state;
                                      dayIndex = dates.length - 2;
                                      this.handleAssembleCalendarByDay(
                                        dayIndex
                                      );
                                    });
                                    console.log("day", berthSchedule);
                                  }}
                                  active={schedToggle === "day"}
                                  outline={schedToggle !== "day"}
                                >
                                  Day
                                </Button>
                                <Button
                                  className="px-3"
                                  color={
                                    schedToggle === "week"
                                      ? "aesi-active"
                                      : "secondary"
                                  }
                                  size="xs"
                                  onClick={() => {
                                    this.setState({
                                      schedToggle: "week"
                                    });
                                    let { gantt_start, gantt_end } = this.state;
                                    this.handleAssembleCalendar(
                                      gantt_start,
                                      gantt_end
                                    );
                                  }}
                                  active={schedToggle === "week"}
                                  outline={schedToggle !== "week"}
                                >
                                  Week
                                </Button>
                                {/* <Button
                                  className="px-3"
                                  color={
                                    schedToggle === "month"
                                      ? "aesi-active"
                                      : "secondary"
                                  }
                                  size="xs"
                                  onClick={() => {
                                    this.setState({
                                      schedToggle: "month"
                                    });
                                  }}
                                  active={schedToggle === "month"}
                                  outline={schedToggle !== "month"}
                                >
                                  Month
                                </Button> */}
                              </ButtonGroup>
                            </td>
                          </tr>
                          <tr className="dates">
                            <th>&nbsp;</th>
                            {schedToggle === "day" ? (
                              <React.Fragment>
                                <td>00:00</td>
                                <td>01:00</td>
                                <td>02:00</td>
                                <td>03:00</td>
                                <td>04:00</td>
                                <td>05:00</td>
                                <td>06:00</td>
                                <td>07:00</td>
                                <td>08:00</td>
                                <td>09:00</td>
                                <td>10:00</td>
                                <td>11:00</td>
                                <td>12:00</td>
                                <td>13:00</td>
                                <td>14:00</td>
                                <td>15:00</td>
                                <td>16:00</td>
                                <td>17:00</td>
                                <td>18:00</td>
                                <td>19:00</td>
                                <td>20:00</td>
                                <td>21:00</td>
                                <td>22:00</td>
                                <td>23:00</td>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {this.state.dates.map((dt, $i) => {
                                  return (
                                    <td colSpan="24">
                                      <Moment format="MMM DD">{dt}</Moment>
                                    </td>
                                  );
                                })}
                              </React.Fragment>
                            )}
                          </tr>
                        </thead>

                        {schedToggle === "day" ? (
                          <tbody className="berth-day">
                            <tr className="dayguide">
                              <th>&nbsp;</th>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="am"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                              <td className="pm"></td>
                            </tr>
                            <DisplayBars data={berthSchedule} />
                          </tbody>
                        ) : (
                          <tbody className="berth-week">
                            <tr className="dayguide">
                              <th>&nbsp;</th>
                              {this.state.dates.map((dt, $i) => {
                                return (
                                  <>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="am"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                    <td className="pm"></td>
                                  </>
                                );
                              })}
                            </tr>
                            <DisplayBars data={berthSchedule} />
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </Form>

                {Object.keys(this.state.unassignedVessels).length > 0 ? (
                  <Form className="d-flex flex-column px-2 mb-3">
                    <div className="form-container-box bt-red w100per">
                      <div className="col-md-12 p-0 d-flex flex-row position-relative">
                        <div className="fcb-title">
                          Unassigned Vessels ({" "}
                          {this.state.unassignedVessels.length} )
                        </div>
                      </div>

                      <div className="col-md-12 d-flex flex-column p-0">
                        {/* <div className="d-flex flex-row flex-wrap mt-3 mx-n2">
                          {this.state.unassignedVessels.map(
                            (portcall, $index) => {
                              return (
                                <div className="col-md-4 px-2 pb-3">
                                  <BerthSlot
                                    fulldetail
                                    key={$index}
                                    className="col-md-12"
                                    status="0"
                                    name={portcall.vessel_name}
                                    voyage_num={portcall.voyage_number}
                                    ata={new Date(portcall.eta)}
                                    atd={new Date(portcall.eta)}
                                    vessel_name={portcall.vessel_name}
                                    selected_port_call_purposes={
                                      portcall.selected_port_call_purposes
                                    }
                                    bs_from={null}
                                    bs_to={null}
                                    purpose=""
                                    portcall_id={portcall._id}
                                    product=""
                                    product_weight=""
                                  ></BerthSlot>
                                </div>
                              );
                            }
                          )}
                        </div>
                        */}
                        <div className="d-flex flex-row flex-wrap mt-3">
                          <BerthSchedule
                            unassigned
                            schedType={schedToggle1}
                            data={this.state.unassignedVessels}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                ) : (
                  <div className="aesi-alert alert-success d-flex align-items-center flex-row">
                    <div className="alert-icon">
                      <Icon name="exclamation" />
                    </div>
                    <div className="alert-content">
                      <div className="content">
                        <span>No unassigned vessels.</span>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </Col>
        </Col>
      </Col>
    );
  }
}
