import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Input,
  Table,
  Button,
  Badge,
} from "reactstrap";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import Navbar from "../Navbar/Navbar";
import "./PortCalls.scss";
import Moment from "react-moment";
import constants from "../../constants";
import BackToTop from "../Tools/BackToTop/BackToTop";
import api from "../../helpers/api.helper";
import session from "../../session";
import Can from "../Can/Can";
import { withRouter } from "react-router-dom";
import { SkellyBarTableRow } from "../Tools/SkeletonScreen/SkeletonScreen";
import { Icon } from "react-fa";
import moment from "moment";
import Media from "react-media";
import PageHeader from "../Tools/PageHeader/PageHeader";
import { debounce } from "lodash";

class PortCalls extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.typing_timeout = 0;
    this.state = {
      activeTab: "Port Calls",
      openDataTable: "",
      dropdownOpen: false,
      __port_call: [],
      __date_format: constants.__dateFormat,
      __date_time_format: constants.__dateTimeFormat,
      __time_format: constants.__timeFormat,
      total_document_count: 0,
      total_page_number: 0,
      pagination: [],
      current_page: 1,
      isLoading: true,
      SkellyWidths: [80, 60, 60, 48, 60, 48, 55, 75, 60, 50, 48, 50, 60],
      entry_request: 0,
      tabTitle: "",
      PORT_CALLS: [],
      endorsedApp: [],
      clearedForBilling: [],
      planningBerth: [],
      clearedForExit: [],
      exitSubmitted: [],
      resourceAllocation: [],
      entryCount: 0,
      exitCount: 0,
      forApprovalCount: 0,
      actionItemsCount: 0,
      forClearingCount: 0,
      rPlanSubmittedCount: 0,
      resourceAllocationCount: 0,
      filter_text: "All Port Calls",
    };
  }

  handleGetPortcallCount = () => {
    let role = session.get().role;
    let filter = {};
    if (
      role === constants.SHIP_AGENT_ROLE ||
      role === constants.CARGO_HANDLER_ROLE
    ) {
      filter.company_id = session.get().company._id;
    } else {
      filter.user_role = session.get().role;
    }

    api
      .get(this, {
        endpoint: "portcall/count/" + session.get().role,
        request_params: {
          ...filter,
        },
      })
      .then((resp) => {
        const { counter } = resp;

        if (!counter) {
          return;
        }

        let tab1ctr = 0,
          tab2ctr = 0,
          tab3ctr = 0;

        switch (session.get().role) {
          case constants.SHIP_AGENT_ROLE:
            tab1ctr = counter.entry_application ? counter.entry_application : 0;
            tab2ctr = counter.exit_application ? counter.exit_application : 0;
            break;
          case constants.CARGO_HANDLER_ROLE:
            tab1ctr = counter.entry_application ? counter.entry_application : 0;
            tab2ctr = counter.exit_application ? counter.exit_application : 0;
            tab3ctr = counter.resource_plan ? counter.resource_plan : 0;
            break;
          case constants.PROCESSING_ROLE:
            tab1ctr = counter.entry_application ? counter.entry_application : 0;
            break;
          case constants.SEAPORT_ROLE:
            let entry_application = counter.entry_application
              ? counter.entry_application
              : 0;
            let exit_application = counter.exit_application
              ? counter.exit_application
              : 0;

            tab1ctr = parseInt(entry_application) + parseInt(exit_application);

            break;
          case constants.HARBOR_MASTER_ROLE:
            tab1ctr = counter.action_required ? counter.action_required : 0;
            break;
          case constants.ENGINEERING_ROLE:
            tab1ctr = counter.resource_plan_approvals
              ? counter.resource_plan_approvals
              : 0;
            break;

          case constants.BILLING_ROLE:
            tab1ctr = counter.exit_application ? counter.exit_application : 0;
            break;
          default:
            break;
        }

        this.setState({ portcallCounters: counter, tab1ctr, tab2ctr, tab3ctr });
      });
  };
  componentDidMount = () => {
    this.setState({
      isLoading: true,
    });

    this.handleGetPortcallCount();

    this.loadData({
      options: {
        sort: { created_at: -1 },
      },
      initial_load: true,
    }).then(() => {
      this.setState({
        isLoading: false,
      });
    });

    this.activeTabs();

    window.addEventListener("scroll", this.handleInfiniteScroll, true);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleInfiniteScroll, true);
  }

  handleInfiniteScroll = (e) => {
    if (e.target !== document) {
      return;
    }

    if (this.state.end_page) {
      return;
    }

    e.stopPropagation();
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      // you're at the bottom of the page

      this.setState({
        isLoading: true,
      });
      let curr_page = this.state.current_page;
      curr_page = parseInt(curr_page) + 1;
      let offset = (curr_page - 1) * constants.__pagination_limit;
      this.setState({ current_page: curr_page });

      this.loadData({
        selTab: this.state.activeTab,
        options: {
          offset: offset,
          sort: { created_at: -1 },
          append: true,
        },
      }).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  };

  activeTabs = () => {
    if (
      session.get().role === constants.SHIP_AGENT_ROLE ||
      session.get().role === constants.CARGO_HANDLER_ROLE
    ) {
      this.setState({
        tabTitle: "Entry Application",
      });
    }

    if (
      session.get().role === constants.PROCESSING_ROLE ||
      session.get().role === constants.SEAPORT_ROLE ||
      session.get().role === constants.ENGINEERING_ROLE
    ) {
      this.setState({
        tabTitle: "For Approval",
      });
    }

    if (session.get().role === constants.HARBOR_MASTER_ROLE) {
      this.setState({
        tabTitle: "Action Items",
      });
    }

    if (session.get().role === constants.BILLING_ROLE) {
      this.setState({
        tabTitle: "For Clearing",
      });
    }
  };

  loadData = ({ selTab, options, initial_load }) => {
    let paginate = this.state.total_document_count === 0 ? 0 : 1;
    let userInfo = JSON.parse(localStorage.getItem("port_session"));
    let search = { status_id: constants.SUBMITTED_STATUS };
    let append = options.append;
    switch (userInfo.role) {
      case constants.ADMIN_ROLE:
        // case constants.SHIP_AGENT_ROLE:
        if (initial_load) {
          search.status_id = { $ne: constants.PENDING_STATUS };
        }

        if (selTab === "Entry Application") {
          search.status_id = { $ne: constants.PENDING_STATUS };
        }

        break;
      case constants.SHIP_AGENT_ROLE:
        search = { company_id: userInfo.company._id };
        if (selTab === "Port Calls") {
          if (!initial_load) {
            search.status_id = { $ne: constants.PENDING_STATUS };
          }
        }

        if (selTab === "Entry Application") {
          if (!initial_load) {
            search.is_vessel_entry_approved = false;
          } else {
            search.is_vessel_entry_approved = false;
          }
        }

        if (selTab === "Exit Application") {
          if (!initial_load) {
            search.status_id = constants.CLEARED_FOR_EXIT_STATUS;
          }
        }

        break;

      case constants.PROCESSING_ROLE:
        if (selTab === "Port Calls") {
          if (!initial_load) {
            search.status_id = { $ne: constants.PENDING_STATUS };
          }
        }

        if (selTab === "For Approval") {
          if (!initial_load) {
            search.status_id = constants.SUBMITTED_STATUS;
          } else {
            search.status_id = constants.SUBMITTED_STATUS;
          }
        } else {
          if (initial_load) {
            search.status_id = { $ne: constants.PENDING_STATUS };
          } else {
            search.status_id = { $ne: constants.PENDING_STATUS };
          }
        }

        break;

      case constants.ENGINEERING_ROLE:
        if (selTab === "For Approval") {
          if (!initial_load) {
            search.status_id = constants.RESOURCE_PLAN_SUBMITTED_STATUS;
          }
        } else {
          search.status_id = { $ne: constants.PENDING_STATUS };
        }

        break;

      case constants.SEAPORT_ROLE:
        if (initial_load) {
          search.status_id = { $ne: constants.PENDING_STATUS };
        }

        if (selTab ? selTab : this.activeTab === "Port Calls") {
          if (!initial_load) {
            search.status_id = { $ne: constants.PENDING_STATUS };
          }
        }

        if (selTab === "For Approval" || selTab === "Action Items") {
          if (!initial_load) {
            search.status_id = {
              $or: [
                { is_vessel_exit_application_endorsed: true },
                { status_id: constants.PROCESSED_STATUS },
              ],
            };
          }
        }
        break;
      case constants.HARBOR_MASTER_ROLE:
      case constants.BILLING_ROLE:
        if (initial_load) {
          search.status_id = { $ne: constants.PENDING_STATUS };
        }

        if (selTab ? selTab : this.activeTab === "Port Calls") {
          if (!initial_load) {
            search.status_id = { $ne: constants.PENDING_STATUS };
          }
        }

        if (selTab === "For Approval" || selTab === "Action Items") {
          if (!initial_load) {
            search.status_id = { $ne: constants.PENDING_STATUS };
          }
        }

        break;

      case constants.CARGO_HANDLER_ROLE:
        search = {
          $or: [
            { company_id: userInfo.company._id },
            { cargo_handler_id: { $all: [userInfo.company._id] } },
          ],
        };
        if (initial_load) {
          // search.status_id = { $ne: constants.PENDING_STATUS };
        }
        if (selTab ? selTab : this.activeTab === "Port Calls") {
          if (!initial_load) {
            // search.status_id = { $ne: constants.PENDING_STATUS };
          } else {
            search.status_id = { $ne: constants.PENDING_STATUS };
          }
        } else {
          if (!initial_load) {
            search.status_id = { $ne: constants.PENDING_STATUS };
          }
        }

        if (selTab === "Entry Application") {
          if (!initial_load) {
            search.is_vessel_entry_approved = false;
          }
        }

        if (selTab === "Exit Application") {
          if (!initial_load) {
            search.status_id = constants.CLEARED_FOR_EXIT_STATUS;
          }
        }

        break;

      default:
        break;
    }

    if (options.search_filter) {
      search = { ...search, ...options.search_filter };
    }

    return api
      .get(this, {
        endpoint: "portcall",
        state_key: "__port_call",
        append_data: append,
        request_params: { ...options, search, paginate },
      })
      .then((data) => {
        if (data.length === 0) {
          this.setState({ end_page: true });
          return;
        }

        let endorsed_app = [];
        let cleared_for_billing = [];
        let cleared_for_exit = [];
        let planning_berth = [];
        let exit_submitted = [];
        let resource_allocation = [];
        let cargo_handler_ids = [];
        let resource_allocation_count = 0;

        // console.log("IDDD", session.get().cargo_handler_id);

        for (let x in data) {
          if (session.get().role === constants.SEAPORT_ROLE) {
            if (data[x].status_id === constants.PROCESSED_STATUS) {
              endorsed_app[x] = data[x];
            }

            if (
              data[x].is_vessel_exit_application_endorsed &&
              !data[x].is_vessel_exit_approved
            ) {
              cleared_for_billing[x] = data[x];
            }
          }

          if (session.get().role === constants.HARBOR_MASTER_ROLE) {
            if (data[x].is_berth_assigned === false && data[x]) {
              planning_berth[x] = data[x];
            }

            if (data[x].status_id === constants.CLEARED_FOR_EXIT_STATUS) {
              cleared_for_exit[x] = data[x];
            }
          }

          if (session.get().role === constants.BILLING_ROLE) {
            if (
              data[x].is_vessel_exit_submitted === true &&
              !data[x].is_vessel_exit_application_endorsed
            ) {
              exit_submitted[x] = data[x];
            }
          }

          if (session.get().role === constants.CARGO_HANDLER_ROLE) {
            if (
              data[x].cargo_handler_id.indexOf(session.get().cargo_handler_id) >
                -1 &&
              !data[x].is_resource_allocation_approved
            ) {
              resource_allocation.push(data[x]);
            }
          }
        }

        this.setState({
          endorsedApp: endorsed_app,
          clearedForBilling: cleared_for_billing,
          planningBerth: planning_berth,
          clearedForExit: cleared_for_exit,
          exitSubmitted: exit_submitted,
          resourceAllocation: resource_allocation,
        });

        // console.log("1ST", resourceAllocation);

        return Promise.resolve();
      });
  };

  toggle = (tab) => {
    let active_tab = tab;
    // console.log("TGLTAB", tab);

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: active_tab,
        isLoading: true,
      });

      this.toggleLoad(tab);
    }
  };

  toggleLoad = debounce((tab) => {
    console.log("wow");
    this.loadData({
      selTab: tab,
      options: {
        sort: { created_at: -1 },
      },
    }).then(() => {
      this.setState({
        isLoading: false,
      });
    });
  }, 500);

  toggleDD = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  dataTableToggle = (i, open, suf) => {
    let row = i + suf;

    if (open !== row) {
      this.setState({
        openDataTable: row,
      });
    } else {
      this.setState({
        openDataTable: i,
      });
    }
  };

  handleFilter = (type) => {
    this.setState({ filter_text: type });
  };

  handleFilter = (type, search) => {
    const filter = {
      "All Port Calls": {},
      Arrived: { ata: { $ne: null }, atd: null },
      "In Port": { is_in_port: true },
      Berthed: { is_berthed: true },
      Departed: { atd: { $ne: null } },
    };
    this.setState({ current_page: 1 });
    const options = { search_filter: filter[type] };

    if (search && search.trim().length > 0) {
      options.search_filter["$or"] = [
        { vessel_name: { $regex: search, $options: "i" } },
      ];
    }

    this.loadData({
      options,
    });
  };

  handleSearch = (searchKey) => {
    if (this.typing_timeout) {
      clearTimeout(this.typing_timeout);
    }

    this.typing_timeout = setTimeout(() => {
      this.handleFilter(this.state.filter_text, searchKey);
    }, 1000);
  };

  render() {
    const {
      __port_call,
      isLoading,
      SkellyWidths,
      tabTitle,
      openDataTable,
    } = this.state;

    const EntryApplication = (props) => {
      return (
        <>
          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs || matches.sm ? (
                <div className="pc-mobileList">
                  {props.data.length !== 0
                    ? props.data.map((port_call, i) => (
                        <>
                          <div
                            key={i + "-title"}
                            className={
                              openDataTable === i + "-entryDetails"
                                ? "row-title active"
                                : "row-title"
                            }
                          >
                            <div
                              onClick={() =>
                                this.props.history.push(
                                  "/port_call/visit_detail/" + port_call._id
                                )
                              }
                            >
                              <span>{port_call.vessel_name}</span>
                              <br />
                              <span
                                className={
                                  !port_call.voyage_number
                                    ? "no-data"
                                    : undefined
                                }
                              >
                                {port_call.voyage_number
                                  ? port_call.voyage_number
                                  : "----"}
                              </span>
                            </div>
                            <div
                              onClick={() => {
                                this.dataTableToggle(
                                  i,
                                  openDataTable,
                                  "-entryDetails"
                                );
                              }}
                            >
                              <Icon
                                name={
                                  openDataTable === i + "-entryDetails"
                                    ? "caret-up"
                                    : "caret-down"
                                }
                              />
                            </div>
                          </div>
                          {openDataTable === i + "-entryDetails" && (
                            <div
                              className="row-details"
                              key={i + "-entryDetails"}
                            >
                              <div>
                                <div className="row-label">Arrival</div>
                                <div
                                  className={
                                    !port_call.eta ? "no-data" : undefined
                                  }
                                >
                                  {port_call.eta
                                    ? moment(port_call.eta).format(
                                        constants.__dateTimeFormat
                                      )
                                    : "00-AAA-0000 00:00"}
                                </div>
                              </div>
                              <div>
                                <div className="row-label">Departure</div>
                                <div
                                  className={
                                    !port_call.etd ? "no-data" : undefined
                                  }
                                >
                                  {" "}
                                  {port_call.etd
                                    ? moment(port_call.etd).format(
                                        constants.__dateTimeFormat
                                      )
                                    : "00-AAA-0000 00:00"}
                                </div>
                              </div>
                              <div>
                                <div className="row-label">Ship Agent</div>
                                <div
                                  className={
                                    !port_call.shipping_agency && "no-data"
                                  }
                                >
                                  {port_call.shipping_agency &&
                                  port_call.shipping_agency.name
                                    ? port_call.shipping_agency.name
                                    : "---"}
                                </div>
                              </div>
                              <div>
                                <div className="row-label">Port of Origin</div>
                                <div
                                  className={
                                    !port_call.port_of_origin && "no-data"
                                  }
                                >
                                  {port_call.port_of_origin
                                    ? port_call.port_of_origin
                                    : "---"}
                                </div>
                              </div>
                              <div>
                                <div className="row-label">Cargo</div>
                                <div
                                  className={
                                    !(
                                      port_call.cargo_details &&
                                      port_call.cargo_details.length > 0
                                    )
                                      ? "no-data"
                                      : undefined
                                  }
                                >
                                  {port_call.cargo_details &&
                                  port_call.cargo_details.length > 0
                                    ? port_call.cargo_details[0].item
                                    : "---"}
                                </div>
                              </div>
                              <div>
                                <div className="row-label">B/L Quantity</div>
                                <div
                                  className={
                                    !(
                                      port_call.cargo_details &&
                                      port_call.cargo_details.length > 0
                                    )
                                      ? "no-data"
                                      : undefined
                                  }
                                >
                                  {port_call.cargo_details &&
                                  port_call.cargo_details.length > 0 &&
                                  port_call.cargo_details[0].amount
                                    ? port_call.cargo_details[0].amount +
                                      " " +
                                      port_call.cargo_details[0].uom
                                    : "-"}
                                </div>
                              </div>
                              <div>
                                <div className="row-label">Cargo Handlers</div>
                                <div
                                  className={
                                    !(
                                      port_call.selected_port_call_purposes
                                        .length > 0
                                    )
                                      ? "no-data"
                                      : undefined
                                  }
                                >
                                  {port_call.selected_port_call_purposes
                                    .length > 0
                                    ? port_call.selected_port_call_purposes.map(
                                        (sp, i) => (
                                          <div key={i}>
                                            {sp.arrastre ? sp.arrastre : ""}
                                            {sp.arrastre && sp.stevedoring
                                              ? " / "
                                              : ""}
                                            {sp.stevedoring
                                              ? sp.stevedoring
                                              : ""}
                                          </div>
                                        )
                                      )
                                    : "---"}
                                </div>
                              </div>
                              <div>
                                <div className="row-label">Berth</div>
                                <div className={!true ? "no-data" : undefined}>
                                  {false ? "---" : "---"}
                                </div>
                              </div>
                              <div>
                                <div className="row-label">Status</div>
                                <div
                                  className={
                                    !port_call.status ? "no-data" : undefined
                                  }
                                >
                                  {port_call.status ? port_call.status : "---"}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))
                    : "No Data"}
                </div>
              ) : (
                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "" }}
                >
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>Vessel Name</th>
                      <th>Voyage No.</th>
                      <th colSpan="2">Arrival</th>
                      <th colSpan="2">Departure</th>
                      <th>Ship Agent</th>
                      <th>Port of Origin</th>
                      <th>Cargo</th>
                      <th>B/L Qty</th>
                      <th>Cargo Handler</th>
                      <th>Berth</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.length !== 0 ? (
                      props.data.map((port_call, $index) => {
                        return (
                          // <Can
                          //   role={session.get().role}
                          //   perform="port-call:view"
                          //   key={$index}
                          //   data={{
                          //     user_id: session.get()._id,
                          //     port_call_user_id: port_call.user_id
                          //   }}
                          //   yes={() => (
                          //     )}
                          // />

                          <tr
                            className="cur-p"
                            key={port_call._id}
                            onClick={() =>
                              this.props.history.push(
                                "/port_call/visit_detail/" + port_call._id
                              )
                            }
                          >
                            <td>{port_call.vessel_name}</td>
                            <td>{port_call.voyage_number || "-"}</td>
                            <td>
                              {port_call.eta ? (
                                <Moment format={this.state.__date_format}>
                                  {port_call.eta || "-"}
                                </Moment>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {port_call.eta ? (
                                <Moment format={this.state.__time_format}>
                                  {port_call.eta || "-"}
                                </Moment>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {port_call.etd ? (
                                <Moment format={this.state.__date_format}>
                                  {port_call.etd || "-"}
                                </Moment>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {port_call.etd ? (
                                <Moment format={this.state.__time_format}>
                                  {port_call.etd || "-"}
                                </Moment>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {port_call.shipping_agency &&
                              port_call.shipping_agency.name
                                ? port_call.shipping_agency.name
                                : ""}
                            </td>
                            <td>{port_call.port_of_origin}</td>
                            <td>
                              {port_call.cargo_details &&
                              port_call.cargo_details.length > 0
                                ? port_call.cargo_details[0].item
                                : "-"}
                            </td>
                            <td>
                              {port_call.cargo_details &&
                              port_call.cargo_details.length > 0 &&
                              port_call.cargo_details[0].amount
                                ? port_call.cargo_details[0].amount +
                                  " " +
                                  port_call.cargo_details[0].uom
                                : "-"}
                            </td>
                            <td>
                              {port_call.arrastre ? port_call.arrastre : ""} /
                              {port_call.stevedoring
                                ? port_call.stevedoring
                                : ""}
                            </td>
                            <td></td>
                            <td>{port_call.status || "-"}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="13" className="no-data">
                          - No Data -
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <SkellyBarTableRow
                    loading={isLoading}
                    widths={SkellyWidths}
                  />
                </Table>
              )
            }
          </Media>
        </>
      );
    };

    const ActionItems = (props) => {
      return (
        <>
          {" "}
          <p className="tbl-title">
            {tabTitle === "For Approval"
              ? "VESSEL ENTRY APPLICATION"
              : "PLANNING"}
          </p>
          <Table className="custom-table" bordered style={{ marginTop: "5px" }}>
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Vessel Name</th>
                <th>Voyage No.</th>
                <th colSpan="2">Arrival</th>
                <th colSpan="2">Departure</th>
                <th>Ship Agent</th>
                <th>Port of Origin</th>
                <th>Cargo</th>
                <th>B/L Qty</th>
                <th>Cargo Handler</th>
                <th>Berth</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {props.entryData.length !== 0 ? (
                props.entryData.map((port_call, $index) => {
                  return (
                    // <Can
                    //   role={session.get().role}
                    //   perform="port-call:view"
                    //   key={$index}
                    //   data={{
                    //     user_id: session.get()._id,
                    //     port_call_user_id: port_call.user_id
                    //   }}
                    //   yes={() => (
                    //     )}
                    // />

                    <tr
                      className="cur-p"
                      key={port_call._id}
                      onClick={() =>
                        this.props.history.push(
                          "/port_call/visit_detail/" + port_call._id
                        )
                      }
                    >
                      <td>{port_call.vessel_name}</td>
                      <td>{port_call.voyage_number || "-"}</td>
                      <td>
                        {port_call.eta ? (
                          <Moment format={this.state.__date_format}>
                            {port_call.eta || "-"}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {port_call.eta ? (
                          <Moment format={this.state.__time_format}>
                            {port_call.eta || "-"}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {port_call.etd ? (
                          <Moment format={this.state.__date_format}>
                            {port_call.etd || "-"}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {port_call.etd ? (
                          <Moment format={this.state.__time_format}>
                            {port_call.etd || "-"}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {port_call.shipping_agency &&
                        port_call.shipping_agency.name
                          ? port_call.shipping_agency.name
                          : ""}
                      </td>
                      <td>{port_call.port_of_origin}</td>
                      <td>
                        {port_call.cargo_details &&
                        port_call.cargo_details.length > 0 &&
                        port_call.cargo_details[0].item
                          ? port_call.cargo_details[0].item
                          : "-"}
                      </td>
                      <td>
                        {port_call.cargo_details &&
                        port_call.cargo_details.length > 0 &&
                        port_call.cargo_details[0].amount
                          ? port_call.cargo_details[0].amount +
                            " " +
                            port_call.cargo_details[0].uom
                          : "-"}
                      </td>
                      <td>
                        {port_call.arrastre ? port_call.arrastre : ""} /
                        {port_call.stevedoring ? port_call.stevedoring : ""}
                      </td>
                      <td>-</td>
                      <td>{port_call.status || "-"}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="13" className="no-data">
                    - No Data -
                  </td>
                </tr>
              )}
            </tbody>
            <SkellyBarTableRow loading={isLoading} widths={SkellyWidths} />
          </Table>
          <p className="tbl-title mt-4">
            {tabTitle === "For Approval"
              ? "VESSEL EXIT APPLICATION"
              : "FOR DEPARTURE"}
          </p>
          <Table className="custom-table" bordered style={{ marginTop: "5px" }}>
            <thead>
              <tr>
                <th>Vessel Name - Voyage No.</th>
                <th>ETA</th>
                <th>ETD</th>
                <th>Ship Agent</th>
                <th>Port of Origin</th>
                <th>Cargo</th>
                <th>B/L Qty</th>
                <th>DATE & TIME OF ANNOUNCEMENT</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {props.exitData.length != 0 ? (
                props.exitData.map((port_call, $index) => {
                  return (
                    <tr
                      className="cur-p"
                      key={port_call._id}
                      onClick={() =>
                        this.props.history.push(
                          "/port_call/visit_detail/" + port_call._id
                        )
                      }
                    >
                      <td>
                        <div className="d-flex flex-column">
                          {/* <span
                       style={{
                         fontWeight: "bold",
                         color: "#333"
                       }}
                     >
                       SHOYO - V123
                     </span>
                     <span
                       style={{
                         paddingLeft: "7px",
                         fontStyle: "italic"
                       }}
                     >
                       Dominic Canillo Sama
                     </span> */}
                          {port_call.vessel_name}
                        </div>
                      </td>
                      <td style={{ color: "red" }}>04-Apr-2019 02:10</td>
                      <td style={{ color: "red" }}>13-Apr-2019 11:55</td>
                      <td>Fafnir Oyami</td>
                      <td>UUKR</td>
                      <td>-</td>
                      <td>TBA</td>
                      <td>02-Apr-2019 14:55</td>
                      <td>Submitted</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="13" className="no-data">
                    - No Data -
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      );
    };

    const AESiMenu = (props) => {
      return (
        <NavLink
          id={"tab" + props.tabno + "-tab"}
          activeClassName="active"
          className={classnames({
            active: this.state.activeTab === props.title,
            "tab-item": true,
          })}
          onClick={(e) => {
            this.toggle(props.title);
          }}
        >
          <p className={props.titleClass}>
            {props.title}
            {props.badge ? (
              <>
                {" "}
                <Badge color="primary" pill>
                  {props.badge}
                </Badge>
              </>
            ) : (
              ""
            )}
          </p>
        </NavLink>
      );
    };

    return (
      <Can
        role={session.get().role}
        perform="port-call:visit"
        no={() => <div>Not Authorized</div>}
        yes={() => (
          <>
            <div className="flex-column">
              <Navbar />

              <div className="port-calls-page">
                <Media queries={constants.media_queries}>
                  {(matches) =>
                    matches.xs && (
                      <PageHeader
                        hideDocToggle
                        subtitle={this.state.activeTab}
                        pagename="Port Calls List"
                      />
                    )
                  }
                </Media>

                <div className="aesi-nav">
                  <div className="tabnos_4">
                    <AESiMenu tabno={1} title="Port Calls" />
                    <Can
                      role={session.get().role}
                      perform="port-call-resource-allocation:list"
                      yes={() => (
                        <AESiMenu
                          tabno={2}
                          title="For Resource Allocation"
                          titleClass={classnames({
                            yellowBg:
                              session.get().role ===
                              constants.CARGO_HANDLER_ROLE,
                          })}
                          badge={this.state.tab3ctr}
                        />
                      )}
                    />
                    <AESiMenu
                      tabno={3}
                      title={tabTitle}
                      titleClass={classnames({
                        yellowBg:
                          session.get().role === constants.PROCESSING_ROLE ||
                          session.get().role === constants.SEAPORT_ROLE ||
                          session.get().role === constants.HARBOR_MASTER_ROLE,
                      })}
                      badge={this.state.tab1ctr}
                    />
                    <Can
                      role={session.get().role}
                      perform="port-call-exit:list"
                      yes={() => (
                        <AESiMenu
                          tabno={4}
                          title="Exit Application"
                          badge={this.state.tab2ctr}
                        />
                      )}
                    />
                    <span className="tab-bar"></span>
                  </div>
                </div>

                <TabContent
                  activeTab={this.state.activeTab}
                  className="pc-tab-content"
                >
                  <TabPane
                    tabId="Port Calls"
                    className="form-container-box"
                    style={{ padding: "20px 10px" }}
                  >
                    <Col
                      md={{ size: 12 }}
                      className="pc-toolbar d-flex flex-row"
                    >
                      <div className="pc-filter">
                        <DropdownList
                          className="pc-dd"
                          value={this.state.filter_text}
                          defaultValue="All Port Calls"
                          data={[
                            "All Port Calls",
                            "Arrived",
                            "Berthed",
                            "Departed",
                            "In Port",
                          ]}
                          onChange={(vPor) => {
                            this.setState({ filter_text: vPor });
                            this.handleFilter(vPor);
                          }}
                        />
                      </div>

                      <div className="pc-search col-md-4">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend"></InputGroupAddon>
                          <Input
                            placeholder="Search"
                            onChange={(e) => {
                              this.handleSearch(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </div>

                      <Media queries={constants.media_queries}>
                        {(matches) =>
                          !matches.xs && (
                            <Can
                              role={session.get().role}
                              perform="port-call:create"
                              yes={() => (
                                <div className="pc-create">
                                  <Button
                                    className="btn-sm ml-auto"
                                    color="primary"
                                    onClick={() =>
                                      this.props.history.push(
                                        "/port_call/create_vessel_entry"
                                      )
                                    }
                                  >
                                    CREATE PORT CALL
                                  </Button>
                                </div>
                              )}
                            />
                          )
                        }
                      </Media>
                    </Col>

                    <Col md={{ size: 12 }} className="pc-pages">
                      <span>Showing</span> 1 - {__port_call.length} of{" "}
                      {__port_call.length}{" "}
                      <span>result{__port_call.length > 1 && "s"}.</span>
                    </Col>

                    <Col md={{ size: 12 }} className="pc-table-cont">
                      <Media queries={constants.media_queries}>
                        {(matches) =>
                          matches.xs || matches.sm ? (
                            <div className="pc-mobileList">
                              {__port_call.map((port_call, i) => (
                                <>
                                  <div
                                    key={i + "-title"}
                                    className={
                                      openDataTable === i + "-portCallDetails"
                                        ? "row-title active"
                                        : "row-title"
                                    }
                                  >
                                    <div
                                      onClick={() =>
                                        this.props.history.push(
                                          "/port_call/visit_detail/" +
                                            port_call._id
                                        )
                                      }
                                    >
                                      <span>{port_call.vessel_name}</span>
                                      <br />
                                      <span
                                        className={
                                          !port_call.voyage_number
                                            ? "no-data"
                                            : undefined
                                        }
                                      >
                                        {port_call.voyage_number
                                          ? port_call.voyage_number
                                          : "----"}
                                      </span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        this.dataTableToggle(
                                          i,
                                          openDataTable,
                                          "-portCallDetails"
                                        );
                                      }}
                                    >
                                      <Icon
                                        name={
                                          openDataTable ===
                                          i + "-portCallDetails"
                                            ? "caret-up"
                                            : "caret-down"
                                        }
                                      />
                                    </div>
                                  </div>
                                  {openDataTable === i + "-portCallDetails" && (
                                    <div
                                      className="row-details"
                                      key={i + "-portCallDetails"}
                                    >
                                      <div>
                                        <div className="row-label">Arrival</div>
                                        <div
                                          className={
                                            !port_call.eta
                                              ? "no-data"
                                              : undefined
                                          }
                                        >
                                          {port_call.eta
                                            ? moment(port_call.eta).format(
                                                constants.__dateTimeFormat
                                              )
                                            : "00-AAA-0000 00:00"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="row-label">
                                          Departure
                                        </div>
                                        <div
                                          className={
                                            !port_call.etd
                                              ? "no-data"
                                              : undefined
                                          }
                                        >
                                          {port_call.etd
                                            ? moment(port_call.etd).format(
                                                constants.__dateTimeFormat
                                              )
                                            : "00-AAA-0000 00:00"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="row-label">
                                          Ship Agent
                                        </div>
                                        <div
                                          className={
                                            !port_call.shipping_agency &&
                                            "no-data"
                                          }
                                        >
                                          {port_call.shipping_agency &&
                                          port_call.shipping_agency.name
                                            ? port_call.shipping_agency.name
                                            : "---"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="row-label">
                                          Port of Origin
                                        </div>
                                        <div
                                          className={
                                            !port_call.port_of_origin &&
                                            "no-data"
                                          }
                                        >
                                          {port_call.port_of_origin
                                            ? port_call.port_of_origin
                                            : "---"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="row-label">Cargo</div>
                                        <div
                                          className={
                                            !(
                                              port_call.cargo_details &&
                                              port_call.cargo_details.length > 0
                                            )
                                              ? "no-data"
                                              : undefined
                                          }
                                        >
                                          {port_call.cargo_details &&
                                          port_call.cargo_details.length > 0
                                            ? port_call.cargo_details[0].item
                                            : "---"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="row-label">
                                          B/L Quantity
                                        </div>
                                        <div
                                          className={
                                            !(
                                              port_call.cargo_details &&
                                              port_call.cargo_details.length > 0
                                            )
                                              ? "no-data"
                                              : undefined
                                          }
                                        >
                                          {port_call.cargo_details &&
                                          port_call.cargo_details.length > 0 &&
                                          port_call.cargo_details[0].amount
                                            ? port_call.cargo_details[0]
                                                .amount +
                                              " " +
                                              port_call.cargo_details[0].uom
                                            : "-"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="row-label">
                                          Cargo Handlers
                                        </div>
                                        <div
                                          className={
                                            !(
                                              port_call
                                                .selected_port_call_purposes
                                                .length > 0
                                            )
                                              ? "no-data"
                                              : undefined
                                          }
                                        >
                                          {port_call.selected_port_call_purposes
                                            .length > 0
                                            ? port_call.selected_port_call_purposes.map(
                                                (sp, i) => (
                                                  <div key={i}>
                                                    {sp.arrastre
                                                      ? sp.arrastre
                                                      : ""}
                                                    {sp.arrastre &&
                                                    sp.stevedoring
                                                      ? " / "
                                                      : ""}
                                                    {sp.stevedoring
                                                      ? sp.stevedoring
                                                      : ""}
                                                  </div>
                                                )
                                              )
                                            : "---"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="row-label">Berth</div>
                                        <div
                                          className={
                                            !true ? "no-data" : undefined
                                          }
                                        >
                                          {false ? "---" : "---"}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="row-label">Status</div>
                                        <div
                                          className={
                                            !port_call.status
                                              ? "no-data"
                                              : undefined
                                          }
                                        >
                                          {port_call.status
                                            ? port_call.status
                                            : "---"}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          ) : (
                            <Table className="custom-table" bordered>
                              <thead>
                                <tr>
                                  {/* <th></th> */}
                                  <th>Vessel Name</th>
                                  <th>Voyage No.</th>
                                  <th colSpan="2">Arrival</th>
                                  <th colSpan="2">Departure</th>
                                  <th>Ship Agent</th>
                                  <th>Port of Origin</th>
                                  <th>Cargo</th>
                                  <th>B/L Qty</th>
                                  <th>Cargo Handler</th>
                                  <th>Berth</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {__port_call.length != 0 ? (
                                  __port_call.map((port_call) => {
                                    return (
                                      <tr
                                        className="cur-p"
                                        key={port_call._id}
                                        onClick={() =>
                                          this.props.history.push(
                                            "/port_call/visit_detail/" +
                                              port_call._id
                                          )
                                        }
                                      >
                                        <td>{port_call.vessel_name}</td>
                                        <td>
                                          {port_call.voyage_number || "-"}
                                        </td>
                                        <td>
                                          {port_call.eta ? (
                                            <Moment
                                              format={this.state.__date_format}
                                            >
                                              {port_call.eta || "-"}
                                            </Moment>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {port_call.eta ? (
                                            <Moment
                                              format={this.state.__time_format}
                                            >
                                              {port_call.eta || "-"}
                                            </Moment>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {port_call.etd ? (
                                            <Moment
                                              format={this.state.__date_format}
                                            >
                                              {port_call.etd || "-"}
                                            </Moment>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {port_call.etd ? (
                                            <Moment
                                              format={this.state.__time_format}
                                            >
                                              {port_call.etd || "-"}
                                            </Moment>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {port_call.shipping_agency &&
                                          port_call.shipping_agency.name
                                            ? port_call.shipping_agency.name
                                            : ""}
                                        </td>
                                        <td>{port_call.port_of_origin}</td>
                                        <td>
                                          {port_call.cargo_details &&
                                          port_call.cargo_details.length > 0
                                            ? port_call.cargo_details[0].product
                                            : "-"}
                                        </td>
                                        <td>
                                          {port_call.cargo_details &&
                                          port_call.cargo_details.length > 0 &&
                                          port_call.cargo_details[0].total_qty
                                            ? port_call.cargo_details[0]
                                                .total_qty +
                                              " " +
                                              port_call.cargo_details[0].uom
                                            : "-"}
                                        </td>
                                        <td>
                                          {port_call.selected_port_call_purposes &&
                                          port_call.selected_port_call_purposes
                                            .length > 0
                                            ? port_call
                                                .selected_port_call_purposes[0]
                                                .arrastre
                                            : ""}{" "}
                                          /{" "}
                                          {port_call.selected_port_call_purposes &&
                                          port_call.selected_port_call_purposes
                                            .length > 0
                                            ? port_call
                                                .selected_port_call_purposes[0]
                                                .stevedoring
                                            : ""}
                                        </td>
                                        <td>
                                          {port_call.berth_log &&
                                          port_call.berth_log.length > 0
                                            ? port_call.berth_log[0].berth
                                            : "Not assigned"}
                                        </td>
                                        <td>{port_call.status || "-"}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="13" className="no-data">
                                      - No Data -
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                              <SkellyBarTableRow
                                loading={isLoading}
                                widths={SkellyWidths}
                              />
                            </Table>
                          )
                        }
                      </Media>
                    </Col>
                  </TabPane>

                  <TabPane
                    tabId="For Resource Allocation"
                    className="form-container-box"
                    style={{ padding: "20px 10px" }}
                  >
                    <Col
                      md={{ size: 12 }}
                      className="pc-toolbar d-flex flex-row"
                    >
                      <div className="pc-filter">
                        <DropdownList
                          className="pc-dd"
                          value={this.state.filter_text}
                          defaultValue="All Port Calls"
                          data={[
                            "All Port Calls",
                            "Arrived",
                            "Berthed",
                            "Departed",
                            "In Port",
                          ]}
                          onChange={(vPor) => {
                            this.setState({ filter_text: vPor });
                            this.handleFilter(vPor);
                          }}
                        />
                      </div>

                      <div className="pc-search col-md-4">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend"></InputGroupAddon>
                          <Input
                            placeholder="Search"
                            onChange={(e) => {
                              this.handleSearch(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </Col>

                    <Col md={{ size: 12 }} className="pc-pages">
                      <span>
                        Showing 1 -{" "}
                        {session.get().role === constants.SHIP_AGENT_ROLE
                          ? this.state.entryCount
                          : session.get().role === constants.CARGO_HANDLER_ROLE
                          ? this.state.resourceAllocationCount
                          : ""}{" "}
                        of{" "}
                        {session.get().role === constants.SHIP_AGENT_ROLE
                          ? this.state.entryCount
                          : session.get().role === constants.SHIP_AGENT_ROLE
                          ? this.state.entryCount
                          : session.get().role === constants.CARGO_HANDLER_ROLE
                          ? this.state.resourceAllocationCount
                          : ""}{" "}
                        results.
                      </span>
                    </Col>

                    <Col md={{ size: 12 }} className="pc-table-cont">
                      <Can
                        role={session.get().role}
                        perform="port-call-entry:list"
                        yes={() => (
                          <EntryApplication
                            data={this.state.resourceAllocation}
                          />
                        )}
                      />
                    </Col>

                    {/* <Row md={{ size: 12 }} style={{ marginTop: "20px" }}></Row> */}
                  </TabPane>

                  <TabPane
                    tabId={tabTitle}
                    className="form-container-box"
                    style={{ padding: "20px 10px" }}
                  >
                    <Col
                      md={{ size: 12 }}
                      className="pc-toolbar d-flex flex-row"
                    >
                      <div className="pc-filter">
                        <DropdownList
                          className="pc-dd"
                          value={this.state.filter_text}
                          defaultValue="All Port Calls"
                          data={[
                            "All Port Calls",
                            "Arrived",
                            "Berthed",
                            "Departed",
                            "In Port",
                          ]}
                          onChange={(vPor) => {
                            this.setState({ filter_text: vPor });
                            this.handleFilter(vPor);
                          }}
                        />
                      </div>

                      <div className="pc-search col-md-4">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend"></InputGroupAddon>
                          <Input
                            placeholder="Search"
                            onChange={(e) => {
                              this.handleSearch(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </Col>

                    <Col md={{ size: 12 }} className="pc-pages">
                      <span>
                        Showing 1 -{" "}
                        {session.get().role === constants.SHIP_AGENT_ROLE
                          ? this.state.entryCount
                          : session.get().role === constants.CARGO_HANDLER_ROLE
                          ? this.state.resourceAllocationCount
                          : ""}{" "}
                        of{" "}
                        {session.get().role === constants.SHIP_AGENT_ROLE
                          ? this.state.entryCount
                          : session.get().role === constants.SHIP_AGENT_ROLE
                          ? this.state.entryCount
                          : session.get().role === constants.CARGO_HANDLER_ROLE
                          ? this.state.resourceAllocationCount
                          : ""}{" "}
                        results.
                      </span>
                    </Col>

                    <Col md={{ size: 12 }} className="pc-table-cont">
                      <Can
                        role={session.get().role}
                        perform="port-call-entry:list"
                        yes={() => (
                          <EntryApplication
                            data={
                              session.get().role === constants.BILLING_ROLE
                                ? this.state.exitSubmitted
                                : __port_call
                            }
                          />
                        )}
                      />
                    </Col>

                    <Can
                      role={session.get().role}
                      perform="port-call-entry-exit:approve"
                      yes={() =>
                        session.get().role === constants.SEAPORT_ROLE ? (
                          <ActionItems
                            entryData={this.state.endorsedApp}
                            exitData={this.state.clearedForBilling}
                          />
                        ) : (
                          <ActionItems
                            entryData={this.state.planningBerth}
                            exitData={this.state.clearedForExit}
                          />
                        )
                      }
                    />

                    {/* <Row md={{ size: 12 }} style={{ marginTop: "20px" }}></Row> */}
                  </TabPane>

                  <Can
                    role={session.get().role}
                    perform="port-call-exit:list"
                    yes={() => (
                      <TabPane
                        tabId="Exit Application"
                        className="form-container-box"
                        style={{ padding: "20px 10px" }}
                      >
                        <Col
                          md={{ size: 12 }}
                          className="pc-toolbar d-flex flex-row"
                        >
                          <div className="pc-search full col-md-6">
                            <InputGroup>
                              <InputGroupAddon addonType="prepend"></InputGroupAddon>
                              <Input
                                placeholder="Search"
                                onChange={(e) => {
                                  this.handleSearch(e.target.value);
                                }}
                              />
                            </InputGroup>
                          </div>
                        </Col>

                        <Col md={{ size: 12 }} className="pc-pages">
                          <span>
                            1 -{" "}
                            {session.get().role === constants.SHIP_AGENT_ROLE
                              ? this.state.exitCount
                              : ""}{" "}
                            of{" "}
                            {session.get().role === constants.SHIP_AGENT_ROLE
                              ? this.state.exitCount
                              : ""}
                          </span>
                        </Col>

                        <Col md={{ size: 12 }} className="pc-table-cont">
                          <Media queries={constants.media_queries}>
                            {(matches) =>
                              matches.xs || matches.sm ? (
                                <div className="pc-mobileList">
                                  {__port_call.map((port_call, i) => (
                                    <>
                                      <div
                                        key={i + "-title"}
                                        className={
                                          openDataTable === i + "-exitDetails"
                                            ? "row-title active"
                                            : "row-title"
                                        }
                                      >
                                        <div
                                          onClick={() =>
                                            this.props.history.push(
                                              "/port_call/visit_detail/" +
                                                port_call._id
                                            )
                                          }
                                        >
                                          <span>{port_call.vessel_name}</span>
                                          <br />
                                          <span
                                            className={
                                              !port_call.voyage_number
                                                ? "no-data"
                                                : undefined
                                            }
                                          >
                                            {port_call.voyage_number
                                              ? port_call.voyage_number
                                              : "----"}
                                          </span>
                                        </div>
                                        <div
                                          onClick={() => {
                                            this.dataTableToggle(
                                              i,
                                              openDataTable,
                                              "-exitDetails"
                                            );
                                          }}
                                        >
                                          <Icon
                                            name={
                                              openDataTable ===
                                              i + "-exitDetails"
                                                ? "caret-up"
                                                : "caret-down"
                                            }
                                          />
                                        </div>
                                      </div>
                                      {openDataTable === i + "-exitDetails" && (
                                        <div
                                          className="row-details"
                                          key={i + "-exitDetails"}
                                        >
                                          <div>
                                            <div className="row-label">ETA</div>
                                            <div
                                              className={
                                                !port_call.eta
                                                  ? "no-data"
                                                  : undefined
                                              }
                                            >
                                              {port_call.eta
                                                ? moment(port_call.eta).format(
                                                    constants.__dateTimeFormat
                                                  )
                                                : "00-AAA-0000 00:00"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="row-label">ETD</div>
                                            <div
                                              className={
                                                !port_call.etd
                                                  ? "no-data"
                                                  : undefined
                                              }
                                            >
                                              {port_call.etd
                                                ? moment(port_call.etd).format(
                                                    constants.__dateTimeFormat
                                                  )
                                                : "00-AAA-0000 00:00"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="row-label">
                                              Ship Agent
                                            </div>
                                            <div
                                              className={
                                                !port_call.shipping_agency &&
                                                "no-data"
                                              }
                                            >
                                              {port_call.shipping_agency &&
                                              port_call.shipping_agency.name
                                                ? port_call.shipping_agency.name
                                                : "---"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="row-label">
                                              Port of Origin
                                            </div>
                                            <div
                                              className={
                                                !port_call.port_of_origin &&
                                                "no-data"
                                              }
                                            >
                                              {port_call.port_of_origin
                                                ? port_call.port_of_origin
                                                : "---"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="row-label">
                                              Cargo
                                            </div>
                                            <div
                                              className={
                                                !(
                                                  port_call.cargo_details &&
                                                  port_call.cargo_details
                                                    .length > 0
                                                )
                                                  ? "no-data"
                                                  : undefined
                                              }
                                            >
                                              {port_call.cargo_details &&
                                              port_call.cargo_details.length > 0
                                                ? port_call.cargo_details[0]
                                                    .item
                                                : "---"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="row-label">
                                              B/L Quantity
                                            </div>
                                            <div
                                              className={
                                                !(
                                                  port_call.cargo_details &&
                                                  port_call.cargo_details
                                                    .length > 0
                                                )
                                                  ? "no-data"
                                                  : undefined
                                              }
                                            >
                                              {port_call.cargo_details &&
                                              port_call.cargo_details.length >
                                                0 &&
                                              port_call.cargo_details[0].amount
                                                ? port_call.cargo_details[0]
                                                    .amount +
                                                  " " +
                                                  port_call.cargo_details[0].uom
                                                : "-"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="row-label">
                                              Date & Time of Announcement
                                            </div>
                                            <div
                                              className={
                                                !(
                                                  port_call
                                                    .selected_port_call_purposes
                                                    .length > 0
                                                )
                                                  ? "no-data"
                                                  : undefined
                                              }
                                            >
                                              {port_call.announcement
                                                ? moment(
                                                    port_call.announcement
                                                  ).format(
                                                    constants.__dateTimeFormat
                                                  )
                                                : "00-AAA-0000 00:00"}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="row-label">
                                              Status
                                            </div>
                                            <div
                                              className={
                                                !port_call.status
                                                  ? "no-data"
                                                  : undefined
                                              }
                                            >
                                              {port_call.status
                                                ? port_call.status
                                                : "---"}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </div>
                              ) : (
                                <Table className="custom-table" bordered>
                                  <thead>
                                    <tr>
                                      <th>Vessel Name - Voyage No.</th>
                                      <th>ETA</th>
                                      <th>ETD</th>
                                      <th>Ship Agent</th>
                                      <th>Port of Origin</th>
                                      <th>Cargo</th>
                                      <th>B/L Qty</th>
                                      <th>DATE & TIME OF ANNOUNCEMENT</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {__port_call.length != 0 ? (
                                      __port_call.map((port_call, $index) => {
                                        return (
                                          <tr
                                            className="cur-p"
                                            key={port_call._id}
                                            onClick={() =>
                                              this.props.history.push(
                                                "/port_call/create_vessel_entry"
                                              )
                                            }
                                          >
                                            <td>
                                              <div className="d-flex flex-column">
                                                {port_call.vessel_name}
                                              </div>
                                            </td>
                                            <td style={{ color: "red" }}>
                                              04-Apr-2019 02:10
                                            </td>
                                            <td style={{ color: "red" }}>
                                              13-Apr-2019 11:55
                                            </td>
                                            <td>Fafnir Oyami</td>
                                            <td>UUKR</td>
                                            <td>-</td>
                                            <td>TBA</td>
                                            <td>02-Apr-2019 14:55</td>
                                            <td>Submitted</td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="13" className="no-data">
                                          - No Data -
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              )
                            }
                          </Media>
                        </Col>
                      </TabPane>
                    )}
                  />
                </TabContent>
              </div>
            </div>

            <BackToTop />

            <Media queries={constants.media_queries}>
              {(matches) =>
                matches.xs && (
                  <Can
                    role={session.get().role}
                    perform="port-call:create"
                    yes={() => (
                      <div className="pc-create">
                        <Button
                          className="btn-sm ml-auto"
                          color="primary"
                          onClick={() =>
                            this.props.history.push(
                              "/port_call/create_vessel_entry"
                            )
                          }
                        >
                          {" "}
                          <Icon name="plus" />
                        </Button>
                      </div>
                    )}
                  />
                )
              }
            </Media>
          </>
        )}
      />
    );
  }
}

export default withRouter(PortCalls);
