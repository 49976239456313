import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import "./SPAM.scss";
import moment from "moment";
import api from "../../../helpers/api.helper";
import constants from "../../../constants";
import sbmaLogo from "../../../img/sbma-logo.png";
import ReactToPrint from "react-to-print";

class PrintPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PORT_CALL: [],
      CREW_LIST: [],
      NATIONALITY: [],
      CH_ID: [],
      CARGO_HANDLERS: [],
      CH_NAMES: [],
      ID: this.props.sendID
    };
  }

  componentDidMount = () => {
    this.loadData();
    this.getCrew();
    // this.getCargoHandlers();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "portcall/" + this.state.ID,
        state_key: "PORT_CALL"
      })
      .then(data => {
        this.setState({
          CH_ID: data.cargo_handler_id
        });

        // console.log("CID", this.state.CH_ID);
      });
  };

  getCrew = () => {
    api
      .get(this, {
        endpoint: "crew_list/" + this.state.ID,
        state_key: "CREW_LIST"
      })
      .then(data => {
        let nationality = [];

        for (let x in data) {
          nationality[x] = data[x].nationality;
        }

        nationality = [...new Set(nationality)];

        // console.log("NATION", nationality);

        this.setState({
          NATIONALITY: nationality
        });
      });
  };

  //   getCargoHandlers = () => {
  //     api
  //       .get(this, {
  //         endpoint: "cargo_handler",
  //         state_key: "CARGO_HANDLERS"
  //       })
  //       .then(data => {
  //         let { CH_ID } = this.state;
  //         let cargo_handlers = [];

  //         for (let x in data) {
  //           for (let y in CH_ID) {
  //             if (data[x]._id === CH_ID[y]) {
  //               cargo_handlers[y] = data[x].name;
  //             }
  //           }
  //         }

  //         this.setState({
  //           CH_NAMES: cargo_handlers
  //         });

  //         // console.log("NAMES", cargo_handlers);
  //       });
  //   };

  render() {
    const pc = this.state.PORT_CALL;

    return (
      <div className="SPAMPDF col-md-12" style={{ position: "relative" }}>
        <div className="print-source">
          <div style={{ top: "1.5em", position: "absolute" }}>
            <img style={{ width: "100px", height: "90px" }} src={sbmaLogo} />
          </div>
          <div
            style={{
              fontWeight: "600",
              position: "absolute",
              top: "1.5em",
              left: "10em"
            }}
          >
            <h4 style={{ margin: "0", fontWeight: "600" }}>
              Seaport Department
            </h4>
            <h3 style={{ margin: "0", fontWeight: "600" }}>
              OFFICE OF THE GENERAL MANAGER
            </h3>
            <div style={{ margin: "0", fontWeight: "600", fontSize: "12px" }}>
              New Seaport Administraion Bldg. Waterfront Rd., Corner Sampson
              Rd., SBFZ, Philippines 2222
              <br />
              Tel 047.252.4225/4541 Fax 047.252.4694 E-MAIL: seaport@sbma.com
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              marginTop: "6.5em",
              width: "100%"
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "16px",
                margin: "0.5em 0"
              }}
            >
              SHIP'S PRE-ARRIVAL MEETING (SPAM)
            </p>
            <p style={{ textAlign: "center" }}>
              <span style={{ fontWeight: "600" }}>DATE:</span>{" "}
              <span
                className="blank-space"
                style={{ marginRight: "15px" }}
              ></span>{" "}
              <span style={{ fontWeight: "600" }}>TIME:</span>{" "}
              <span className="blank-space"></span>
            </p>

            <p className="flex-row" style={{ marginTop: "0.5em" }}>
              <div className="flex-row col-md-6 p0">
                <span className="col-md-4 p0" style={{ fontWeight: "600" }}>
                  NAME OF VESSEL:
                </span>{" "}
                <span
                  className="blank-space col-md-8 p0"
                  style={{ width: "270px", marginRight: "15px" }}
                >
                  {" "}
                  <span>{pc.vessel_name}</span>
                </span>
              </div>
              <div className="flex-row col-md-3 p0">
                <input type="checkbox" name="" className="check-box" />
                <span className="fw600">Anchorage:</span>{" "}
                <span
                  className="blank-space"
                  style={{ marginRight: "15px" }}
                ></span>
              </div>
              <input type="checkbox" name="" className="check-box" />
              <span className="fw600">Berth:</span>{" "}
              <span
                className="blank-space"
                style={{ marginRight: "15px" }}
              ></span>
            </p>

            <p>
              <span className="fw600">Estimated Date & Time of Arrival:</span>{" "}
              <span
                className="blank-space"
                style={{ width: "195px", marginRight: "15px" }}
              >
                <span>{moment(pc.eta).format(constants.__dateTimeFormat)}</span>
              </span>
              <span className="fw600">Date & Time of Departure:</span>{" "}
              <span
                className="blank-space"
                style={{ width: "195px", marginRight: "15px" }}
              >
                <span>{moment(pc.etd).format(constants.__dateTimeFormat)}</span>
              </span>
            </p>

            <p style={{ fontWeight: "bold" }}>
              Venue :{" "}
              <input type="checkbox" name="" style={{ marginLeft: "50px" }} />{" "}
              Conference Room
              <input
                type="checkbox"
                name=""
                style={{ marginLeft: "50px" }}
              />{" "}
              Mini-conf. Rm.
              <input
                type="checkbox"
                name=""
                style={{ marginLeft: "50px" }}
              />{" "}
              Other{" "}
              <span
                className="blank-space"
                style={{ width: "195px", marginRight: "15px" }}
              ></span>
            </p>

            <div
              className="fw600"
              style={{
                fontSize: "15px",
                marginTop: "1em",
                marginBottom: "0.25em"
              }}
            >
              1. ATTENDANCE: PLEASE PRINT LEGIBLY
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Name of attendee</th>
                <th>Company name</th>
                <th>
                  Contact No. /<br /> e-mail Address
                </th>
                <th>Signature</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>2.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>3.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>4.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>5.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>6.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>7.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>8.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>9.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>10.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>11.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>12.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>13.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>14.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>15.</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em"
            }}
          >
            2. ROLES AND RESPONSIBILITY
          </div>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em"
            }}
          >
            a.) SEAPORT DEPARTMENT shall:
          </div>
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "justify"
            }}
          >
            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.1.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Preside/lead the conduct of Ship's Pre-Arrival meeting (SPAM)
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.2.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Assign berthing space for the vessel,
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.3.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Conduct pier inspection, and monitor loading and unloading
                operations,
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.4.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Maintain the right to transfer vessel to another berth in case
                the projected/agreed duration of opertaions is not accomplished.
                Expense shall be shouldered by owner or pricipal of vessel;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.5.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Oversee with absolute reponsibility and authority the proper
                discharging and loading of cargoes; and,
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.6.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Act as the point of contact for the whole operation being the
                recognized Port Authority in the Port of Subic
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.7.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Presiding Officer of SPAM has the authority to defer SPAM, if he
                found some violation of the requirements to proceed SPAM with
                the concurrence of Seaport Department attendee/s.
              </p>
            </div>
          </div>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em"
            }}
          >
            b.) SHIP AGENT shall:
          </div>
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "justify"
            }}
          >
            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.1.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Be responsible for the submission of all required documents and
                thoroughly/accurately write down all particulars for the entry
                of ship into the Port of Subic;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.2.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Be responsible in notifying the Seaport Department and
                submitting amended entry if the ETA of vessel is not
                met/delayed. Likewise, amended Exit Clearance if ETD will not be
                achieved;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.3.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Assure the attendance of cargo consignees, cargo handler, and
                other concerned agencies in the SPAM or their authorized
                representative and be responsible and liable for any/all damages
                that shall be considered as an agreement to all conditions
                discussed in the SPAM, and a waiver of their right to claim
                damage/s or payments against SBMA on any untoward incident that
                may happne to theri cargo/es;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.4.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                e responsible in notifying the Ship Boarding Team (SBT) of the
                Seaport Department and BOC, Immigration and Quarantine (CIQ)
                personnel for the arrival of the vessel at the Port of Subic;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.5.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Provide transportation requirement of the SBT when vessel is at
                the Anchorage area, Agusuhin Point and Subic Shipyard
                Engineering Inc. (SSEI);
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.6.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Coordinate with assigned Harbor Pilot, and Tug Boat service
                provider before the arrival and departure of vessel;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.7.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Notify Seaport Department in writing of any change in schedule
                of arrival, vessel movement or any other concerns not discussed
                during the conduct of SPAM, while the vessel is in the port.
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.8.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Inform, advice, and obliged the vessel crew to follow pertinent
                SBMArules and regulations specifically on pollution and waste
                disposal. Compliance with ISPS-code and SBMA Ecology
                requirements;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.9.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Provide coordination/assistance to Seaport Department personnel
                in coduting onboard inspection relative to repair and safety and
                to submit <b>"Endorsement for Ship Services"</b> such as:
                bunkering, water tendering, ship repair, chandling etc., Ship
                Agent must give priority to SBMA Accredited port service
                providers;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.10.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Submit "Daily Discharging Report" to the Terminal Operation
                Division of Seaport Department for bulkbr /eak bulk cargo/ "Post
                Operation Report" if operation is done within the day of stay;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.11.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Arrange services of SBMA-accredited security agency to be
                assigned at the ship gangway if needed; Billing Section before
                departure of vessel. Promissory Note must be duly approved by
                the General Manager of Seaport Department;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.12.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Arrange for vessel's departure clearance and settle all
                payments/payable with Seaport Department Billing Section before
                departure of vessel. Promissory Note must be duly approved by
                the General Manager of Seaport Department;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.13.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Comply with all port facility procedures and security measures
                as provided for in the Internation Ship and Port facility
                particularly on security measures, access to the port facility,
                delivery of ship's store, handling of cargo and monitoring the
                security of the port facility and ship;{" "}
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.14.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Immediately inform Seaport Department of any untoward incident
                followed by written incident report the soonest possible time.
                Non-reporting of incident will be dealt accordingly;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.15.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Be the sole authorized and recognized representative of the
                vessel, all transaction involving vessel must course through the
                Ship Agent.
              </p>
            </div>
          </div>{" "}
          {/* B end */}
          <p className="fs13 fw600 mt-3">
            2.{" "}
            <span
              className="fw600"
              style={{
                fontSize: "15px",
                marginTop: "1em",
                marginBottom: "0.25em",
                textDecoration: "underline"
              }}
            >
              PURPOSE/OBJECTIVE:
            </span>{" "}
            To inform all parties concerned of respective duties and systematic,
            safe and effiecient berthing/anchorage of ship, unloading and
            loading of cargoes of vessels can be achieved in consonance with
            International Ship and Port Facility Security SBMA's Total Quality
            Management System in accordance with ISO- 9001:2008 standard.
          </p>
          <div className="fs13 fw600 mt-3">
            3.{" "}
            <span
              className="fw600"
              style={{
                fontSize: "15px",
                marginTop: "1em",
                marginBottom: "0.25em",
                textDecoration: "underline"
              }}
            >
              VESSEL AND CARGO PARTICULARS:
            </span>
          </div>
          <table className="mt-3 vc-particulars">
            <tbody>
              <tr>
                <td width="6%">a.</td>
                <td width="47%">Name of Ship and Voyage No</td>
                <td width="47%">
                  <b>{pc.vessel_name + " - " + pc.voyage_number}</b>
                </td>
              </tr>
              <tr>
                <td width="6%">b.</td>
                <td width="47%">Shipping Company</td>
                <td width="47%">
                  <b></b>
                </td>
              </tr>
              <tr>
                <td width="6%">c.</td>
                <td width="47%">Name of Ship Agent/Sub-Agent</td>
                <td width="47%">
                  <b>
                    {pc.shipping_agency && pc.shipping_agency.name
                      ? pc.shipping_agency.name
                      : ""}
                  </b>
                </td>
              </tr>
              <tr>
                <td width="6%">d.</td>
                <td width="47%">Last Port of Call/Port of Origin</td>
                <td width="47%">
                  <b>{pc.port_of_origin}</b>
                </td>
              </tr>
              <tr>
                <td width="6%">e.</td>
                <td width="47%">Next Port of Origin</td>
                <td width="47%">
                  <b></b>
                </td>
              </tr>
              <tr>
                <td width="6%">f.</td>
                <td width="47%">Estimated Date & Time of Arrival</td>
                <td width="47%">
                  <b>{moment(pc.eta).format(constants.__dateTimeFormat)}</b>
                </td>
              </tr>
              <tr>
                <td width="-6%">g.</td>
                <td width="47%">Estimated Date & Time of Departure</td>
                <td width="47%">
                  <b>{moment(pc.etd).format(constants.__dateTimeFormat)}</b>
                </td>
              </tr>
              <tr>
                <td width="6%">h.</td>
                <td width="47%">Total number or crew</td>
                <td width="47%">
                  <b>{pc.total_number_of_crew}</b>
                </td>
              </tr>
              <tr>
                <td width="6%">i.</td>
                <td width="47%">Nationality of Master and crews</td>
                <td width="47%">
                  <b>
                    {this.state.NATIONALITY.map((n, $i) => (
                      <span>
                        {n}
                        {this.state.NATIONALITY.length === $i + 1 ? "" : ", "}
                      </span>
                    ))}
                  </b>
                </td>
              </tr>
              <tr>
                <td width="6%">j.</td>
                <td width="47%">Gross Registered Tonnage (GRT)</td>
                <td width="47%">
                  <b>{pc.grt}</b>
                </td>
              </tr>
              <tr>
                <td width="6%">k.</td>
                <td width="47%">Vessel's measurements in meter</td>
                <td width="47%">
                  <b></b>
                </td>
              </tr>
              <tr>
                <td width="6%">l.</td>
                <td width="47%">Consignee/s</td>
                <td width="47%"></td>
              </tr>
              <tr>
                <td width="6%">m.</td>
                <td width="47%">Cargo Handler/s</td>
                <td width="47%"></td>
              </tr>
              <tr>
                <td width="6%"></td>
                <td width="47%">1. Arrastre</td>
                <td width="47%">
                  <b>{pc.arrastre ? pc.arrastre : ""}</b>
                </td>
              </tr>
              <tr>
                <td width="6%"></td>
                <td width="47%">2. Stevedoring</td>
                <td width="47%">
                  <b>{pc.stevedoring ? pc.stevedoring : ""}</b>
                </td>
              </tr>
              <tr>
                <td width="6%">n.</td>
                <td width="47%">Cargo Name & Specifications</td>
                <td width="47%">
                  <b>
                    {" "}
                    {pc.cargo_details && pc.cargo_details.length > 0
                      ? pc.cargo_details[0].item
                      : "-"}
                  </b>
                </td>
              </tr>
              <tr>
                <td width="6%">o.</td>
                <td width="47%">
                  Tugboat & number of tug boat to be utilized:
                </td>
                <td width="47%"></td>
              </tr>
              <tr>
                <td width="6%"></td>
                <td width="47%"></td>
                <td width="47%"></td>
              </tr>
              <tr>
                <td width="6%">p.</td>
                <td width="47%">Harbor Pilot Assigned</td>
                <td width="47%"></td>
              </tr>
              <tr>
                <td width="6%">q.</td>
                <td width="47%">Assigned Berth</td>
                <td width="47%"></td>
              </tr>
            </tbody>
          </table>
          <div className="fs13 fw600 mt-3">
            4.{" "}
            <span
              className="fw600"
              style={{
                fontSize: "15px",
                marginTop: "1em",
                marginBottom: "0.25em",
                textDecoration: "underline"
              }}
            >
              DOCUMENTATION/CLEARANCES:
            </span>
          </div>
          <div
            style={{
              paddingleft: "20px",
              paddingRight: "20px",
              textAlign: "justify"
            }}
          >
            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Processing of pertinent documents, clearances and other
                requirements, including storwage plan of the vessel must be
                accompanied / submitted prior to unloading/loading of cargoes;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Original Application for Entry and Vessel Exit Clearance, and
                Special Power of Attorney (SPA) for representative/s must be
                presented during the conduct of Ship Pre-Arrival Meeting (SPAM).
                Submitted SPA if not available must be conferred with Seaport
                Billing Section representative;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Payment/settement of applicable fess/charges/penalties must be
                settled first prior to departure of vessel;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                d.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                SBMA reserves the right to impose penalty not exceeding Three
                Thousand U.S Dollars( $3,000.00) to hold the vessel in abeyance
                if documentary requirements are not met.
              </p>
            </div>
          </div>
          <div className="fs13 fw600 mt-3">
            5.{" "}
            <span
              className="fw600"
              style={{
                fontSize: "15px",
                marginTop: "1em",
                marginBottom: "0.25em",
                textDecoration: "underline"
              }}
            >
              OPERATIONAL DISCHARGING/LOADING SCHEME:
            </span>
          </div>
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "justify"
            }}
          >
            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                a.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                The cargo <span className="blank-space"></span> will be
                discharged/loaded at <span className="blank-space"></span>.
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                b.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                The contacted Cargo Handler is:
                <br /> <b>
                  Arrastre:
                </b> <span className="blank-space"></span> <b>Stevedoring:</b>{" "}
                <span className="blank-space"></span>
                <br />
                that shall provide a master list of workers/dock workers to the
                Terminal Division of Seaport Department.{" "}
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                The operation will start on{" "}
                <span className="blank-space"></span>, and estimated to be
                completed on or before <b>Arrastre:</b>{" "}
                <span className="blank-space"></span>, weather permitting (WP).
                Delay/change in completion date shall be requested in writing
                addressed to General Manager, Seaport Department in advance and
                must specify number of days to accomplish and the reason for the
                delay therof.{" "}
              </p>
            </div>
          </div>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em",
              marginLeft: "1em"
            }}
          >
            c.) CARGO HANDLER AND CONSIGNEE shall:
          </div>
          <div style={{ marginLeft: "2.25em" }}>
            <div className="d-flex flex-row col-md-12 p0 mt-2">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.1.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Arrange the manpower requirements, including deployment of cargo
                scheme. List of personnel involved in the cargo handling
                operation Teminal Operation Division (OTD) and Marine Division
                (MD) of the Seaport
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.2.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Strictly comply in usage of appropriate Personal Protective
                Equipment. all Cargo/Dock workers must be in proper uniform
                (Color coded) with clear worker printed on it;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.3.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Responsible in determining ownership of cargo of different
                consginees, submitted to the TOD on matters with special
                concerns / activity needing immediate pull-off within the yellow
                lane;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.4.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Provide suffiecient number of required manpower on a daily
                basis, submit listings;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.5.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Log-in and tag all equipment and other cargo handling
                paraphernalia upon entry to the Controlled areas/premises.
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.6.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Usage of pier apron as a temporary holding area for trucks and
                cargo equipment includes discharge from the ship is strictly
                prohibited;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.7.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Furnish/proved clean and well maintained portable toilet for
                dockworkers;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.8.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Immediately report untoward/unusual incident/accident to the
                TOD/MD or to the office of the Manager of Seaport Department,
                followed by incident report in writing. Medical expenses sole
                responsibility of cargo handling company involved;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.9.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Comply with SBMA-Ecology environmental requirements. Smoke
                Belching vehiclesm truck/equipments is not allowed within
                Seaport areas of responsibility
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.10.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Payment of SBMA share on all services provided by SBMA
                accredited and non- accredited port services company;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.11.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Comply with all International Ship and Port Facility Security
                (ISPS) Code requirementsm including deliveries of all ship
                provision and unaccompanied cargoes/baggage;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                c.12.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Be responsible and liable if proven to be at fault in case/s of
                untoward incident/accident
              </p>
            </div>
          </div>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em"
            }}
          >
            7.) OTHER RELATED ISSUES:
          </div>
          <div style={{ marginLeft: "2.25em" }}>
            <div className="d-flex flex-row col-md-12 p0 mt-3">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                7.1.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Any safety and security-related concerns/incidents shall be
                reported to and coordinated with office of the Port Facility
                Security Officer (PFSO) or the Port Security Officer (PSO) or to
                General Manager, Seaport Department
              </p>
            </div>
            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                7.2.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Cooking, smoking and any activity utilizing open/naked flames is
                strictly prohibited within the Area;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                7.3.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Cargo handlers shall ensure workers are issued with and in
                possession of SBMA identification while at the work premises. ID
                cards shall be surrenderd at the end of the work shift;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                7.4.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Cargo truck/s to be utilized must be accredited by SBMA, must
                follow the allowable load/weight limit, must park at the
                designated parking area only, must obey traffic regulations,
                traffic signage and speed limits at all time. Pay the
                appropriate SBMA share.
              </p>
            </div>
          </div>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em"
            }}
          >
            8.) REMINDDER TO PORT-USER:
          </div>
          <div style={{ marginLeft: "1.25em" }}>
            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                e.1.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Undeclared cargo must not be discharged/loaded until appropriate
                documentation and pertinent requirements are submitted, fully
                met and satisfied;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                e.2.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                No Right-hand vehicle will be allowed to disembark except
                consigned to accredited importer of used trucks/Heavy Eqmts.
                Inform SBMA LED if cargo will transfer from Seaport yard to
                Consignees yard.
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                e.3.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Promissory notes will not be honered as payment for applicable
                fees requiring immediate cash payments unless approved by the
                General Manager, Seaport Department or by the Senior Deputy
                Administrator for Operations;
              </p>
            </div>

            <div className="d-flex flex-row col-md-12 p0">
              <span
                className="fw600"
                style={{ fontSize: "13px", width: "2.5em" }}
              >
                e.4.
              </span>
              <p className="w100per p0" style={{ fontSize: "13px" }}>
                Other concerns and requirements that may arise dealing with
                operation shall be brought to the attention of the General
                Manager of Seaport Department. Notification by phone may be
                written/formal request shall be submitted first hour of the
                following day.
              </p>
            </div>
          </div>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em"
            }}
          >
            CONCERNS: PLEASE CHECK (/) APPROPRIATE BOXES/CROSS(x) IF NOT
            APPLICABLE:
          </div>
          <div
            style={{
              fontSize: "13px",
              paddingLeft: "20px",
              paddingRight: "20px",
              textAlign: "justify",
              display: "inline-block"
            }}
          >
            <input type="checkbox" style={{ marginRight: "10px" }} /> Docking
            upon arrival at{" "}
            <span style={{ textDecoration: "underline" }}></span>
            <input type="checkbox" style={{ marginRight: "20px" }} /> Star Board{" "}
            <input type="checkbox" style={{ marginRight: "20px" }} /> Bow Out;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
            Anchorage/OPL, at <span className="blank-space"></span> while
            awaiting advice from <span className="blank-space"></span>, trough
            Seaport Port Comm;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> All ship
            related radio communications must course through Seaport Port Comm;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> Tug boat
            to be utilize;{" "}
            <input type="checkbox" style={{ marginRight: "10px" }} /> Malayan{" "}
            <input type="checkbox" style={{ marginRight: "10px" }} /> Eagle Asia
            Number of Tug Boat
            <span className="blank-space"></span> / Pilot discretion
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> Stay at
            anchorage/OPL while awaiting completion and exit of{" "}
            <span className="blank-space"></span> at{" "}
            <span className="blank-space"></span>
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> ISPS-Code
            and SBMA Ecology requirements for strict compliance;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> Marine
            Tanker vessel is not allowed to stay at Anchorage area after Loading
            at PCSPC;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
            CONTAINERIZED CARGO{" "}
            <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
            Discharging; 40 footer{" "}
            <span class="blank-space" style={{ width: "40px" }}></span> 20
            footer <span class="blank-space" style={{ width: "40px" }}></span>{" "}
            Others <span class="blank-space" style={{ width: "40px" }}></span>{" "}
            <br />
            <input
              type="checkbox"
              style={{ marginRight: "10px", marginLeft: "15em" }}
            />{" "}
            Loading; 40 footer{" "}
            <span class="blank-space" style={{ width: "40px" }}></span> 20
            footer <span class="blank-space" style={{ width: "40px" }}></span>{" "}
            Others <span class="blank-space" style={{ width: "40px" }}></span>{" "}
            <br />
            Discharging:{" "}
            <input type="checkbox" style={{ marginRight: "10px" }} /> FCL{" "}
            <input type="checkbox" style={{ marginRight: "10px" }} /> Empty{" "}
            <span style={{ marginRight: "10px" }}>
              {" "}
              Loading: <input
                type="checkbox"
                style={{ marginRight: "10px" }}
              />{" "}
              FCL <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
              Empty <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
              Others
              <br />
              <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
              BULK/BREAK BULK CARGO{" "}
              <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
              <span style={{ marginRight: "50px" }}>
                {" "}
                Discharging{" "}
                <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
                Loading
                <br />
                <input
                  type="checkbox"
                  style={{ marginRight: "10px", marginLeft: "150px" }}
                />{" "}
                Kind of Cargo <span class="blank-space"></span>
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} />
                Metric Ton <span class="blank-space"></span>
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} /> Usage
                of appropriate PPE during operations is advised and strictly
                required;
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
                Non-attendance to SPAM is tantamount to waiver of right to
                complain/to file complaint against SBMA/Seaport Department;
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} /> Actual
                unloading/loading of cargoes will be checked / monitor by
                Seaport cargo Control Branch;
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
                Seaport cargo checker will use Ad-Measurement for break-bulk
                cargoes of <span class="blank-space"></span>
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} /> Submit
                daily Discharging Report/Post Operation Reports for grain
                cargoes/used trucks & heavy equipment or bulk/break-bulk
                cargoes;
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
                Conduct Tool Box meeting before commencement of cargo
                operations;
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
                Dock/Port workers must wear appropriate uniform and SBMA ID
                while inside Seaport work areas;
                <br />
                <input type="checkbox" style={{ marginRight: "10px" }} />
                All details of SPAM are clearly explaned, duties and
                responsibilities emphasized;
                <br />
              </span>
            </span>
            <input type="checkbox" style={{ marginRight: "10px" }} /> Any
            untoward incident that may occur, ship agent/cargo handler is
            obliged to report immediately to Seaport Department, followed by
            written incident report the soonest possible time;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> Terminal
            Operations Division (TOD) personnel together with Ship Agent and
            Cargo Handler must check physical conditions of pier, wharf and
            fenders, before docking, and unlocking of vessel, and before, during
            and after cargo handling operations
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} />{" "}
            Cleanliness and orderliness of port work area must be observed at
            all times;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> All
            documentary requirements must be submitted before/during/after
            boarding formality;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> All
            payables to Seaport Department must be settled before departure of
            vessel;
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} />
            Other concern/s not taken during conduct of SPAM, must be address to
            General MAnager of Seaport Department for approval.
            <br />
            <input type="checkbox" style={{ marginRight: "10px" }} /> Logistic
            Requirements <span class="blank-space"></span>{" "}
            <input type="checkbox" name="" /> Bunkering/Watering, etc{" "}
            <input type="checkbox" name="" /> Provisions{" "}
            <span class="blank-space"></span>, writter request addressed to GM
            of Seaport Department for approval.
            <br />
          </div>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em"
            }}
          >
            FURTHER CONCERNS:
          </div>
          <div className="blank-space col-md-12 w100per"></div>
          <div className="blank-space col-md-12 w100per"></div>
          <div className="blank-space col-md-12 w100per"></div>
          <div className="blank-space col-md-12 w100per"></div>
          <div className="blank-space col-md-12 w100per"></div>
          <div className="blank-space col-md-12 w100per"></div>
          <div className="blank-space col-md-12 w100per"></div>
          <div
            className="fw600"
            style={{
              fontSize: "15px",
              marginTop: "1em",
              marginBottom: "0.25em"
            }}
          >
            ALL CONTENTS OF THIS SHIP'S PRE-ARRIVAL MEETING SERVES AS SHALL BE
            LEGALLY BINDING FOR ALL PARTIES AND ENTITIES CONCERNED
          </div>
          <div
            class="col-md-12 d-flex flex-row mt-5"
            style={{ fontSize: "15px", fontWeight: "600" }}
          >
            <div
              className="col-md-6 d-flex flex-row justify-content-center"
              style={{ textAlign: "center" }}
            >
              TIME STARTED: <span className="blank-space"></span>
            </div>
            <div
              className="col-md-6 d-flex flex-row justify-content-center"
              style={{ textAlign: "center" }}
            >
              TIME ADJOURNED: <span className="blank-space"></span>
            </div>
          </div>
          <div className="col-md-12 mt-5 " style={{ textAlign: "center" }}>
            <p className="blank-space" style={{ width: "18em" }}></p>
            <br />
            <p className="fw600">PRESIDING OFFICER</p>
          </div>
        </div>
      </div>
    );
  }
}

class SPAMpdf extends Component {
  render() {
    return (
      <div>
        <div
          className="col-md-12 d-flex justify-content-end p0"
          style={{ marginBottom: "0.5em" }}
        >
          <ReactToPrint
            trigger={() => (
              <Button className="btn-sm" color="primary">
                PRINT
              </Button>
            )}
            content={() => this.componentRef}
          />
        </div>

        <PrintPDF
          className="mt-2"
          sendID={this.props.getID}
          ref={el => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default withRouter(SPAMpdf);
