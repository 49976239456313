import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import "./ShipsParticularPDF.scss";
import api from "../../../helpers/api.helper";
import moment from "moment";
import constants from "../../../constants";
import ReactToPrint from "react-to-print";

class PrintPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PORT_CALL: [],
      ID: this.props.sendID,
      VESSELS: [],
      VESSEL_D: [],
      V_NAME: "",
      ownerPicArr: [
        {
          pic: "",
          tel_no: "",
          email: ""
        }
      ],

      chartPIC: [
        {
          pic: "",
          tel_no: "",
          email: ""
        }
      ]
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    let { VESSEL_D } = this.state;

    api
      .get(this, {
        endpoint: "portcall/" + this.state.ID,
        state_key: "PORT_CALL"
      })
      .then(pc => {
        api
          .get(this, {
            endpoint: "vessel",
            state_key: "VESSELS"
          })
          .then(vessels => {
            let v_details = [];

            for (let x in vessels) {
              if (vessels[x].vessel_name === pc.vessel_name) {
                v_details = vessels[x];
              }
            }

            this.setState({
              VESSEL_D: v_details
            });

            // console.log("VESSSSELS", this.state.VESSEL_D);
          });
      });

    let owner_pic = [
      {
        pic: "",
        tel_no: "",
        email: ""
      }
    ];

    let chart_pic = [
      {
        pic: "",
        tel_no: "",
        email: ""
      }
    ];

    this.setState({
      ownerPicArr: VESSEL_D.owner_pic ? VESSEL_D.owner_pic : owner_pic,
      chartPIC: VESSEL_D.charterer_pic ? VESSEL_D.charterer_pic : chart_pic
    });
  };

  render() {
    const pc = this.state.PORT_CALL;
    const vd = this.state.VESSEL_D;
    const { ownerPicArr, chartPIC } = this.state;

    return (
      <div className="shipsParticular col-md-12">
        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            SHIP'S PARTICULARS
          </p>
        </div>

        <div
          className="mt-2"
          style={{
            width: "60%",
            border: "1px solid black",
            padding: "2px 0 2px 2px",
            display: "inline-block",
            verticalAlign: "top",
            marginRight: "18px"
          }}
        >
          <table>
            <tbody>
              <tr>
                <td style={{ width: "40%" }}>Name of Vessel</td>
                <td style={{ width: "60%" }}>{vd.vessel_name}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Flag & Port of Registry</td>
                <td style={{ width: "60%" }}>{vd.port_of_registry}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Type of Vessel</td>
                <td style={{ width: "60%" }}>{vd.type}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Classification / Class No.</td>
                <td style={{ width: "60%" }}>{vd.classification}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>P & I Club</td>
                <td style={{ width: "60%" }}>{vd.p_and_i_club}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Hull & Machinery Underwriters</td>
                <td style={{ width: "60%" }}>
                  {vd.hull_and_machinery_underwriters}
                </td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Builder's Name & Place</td>
                <td style={{ width: "60%" }}>{vd.builders_name_and_place}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Hull Number/Type</td>
                <td style={{ width: "60%" }}>{vd.hull_number}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Date of keel Laid / Launch</td>
                <td style={{ width: "60%" }}>
                  {moment(vd.date_of_keel_laid).format(constants.__dateFormat)}
                </td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Date of Delivery</td>
                <td style={{ width: "60%" }}>
                  {moment(vd.date_of_delivery).format(constants.__dateFormat)}
                </td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Main Diesel Engine / Number</td>
                <td style={{ width: "60%" }}>{vd.main_diesel_engine}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Output Maximum Rating</td>
                <td style={{ width: "60%" }}>{vd.output_maximum_rating}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Output Normal Rating</td>
                <td style={{ width: "60%" }}>{vd.output_normal_rating}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>
                  Gen. Engine- Maker/ TYpe x Output/ No.
                </td>
                <td style={{ width: "60%" }}>{vd.gen_engine_maker}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>
                  Auxilliary Boiler Type / Number
                </td>
                <td style={{ width: "60%" }}>{vd.aux_boiler_type}</td>
              </tr>
              <tr>
                <td style={{ width: "40%" }}>Service Speed</td>
                <td style={{ width: "60%" }}>{vd.service_speed}</td>
              </tr>
            </tbody>
          </table>

          <table>
            <tr>
              <td
                rowspan="3"
                style={{ width: "10%", borderTop: "0px", textAlign: "center" }}
              >
                Tanks <br />
                Capa- <br />
                city
              </td>
              <td
                style={{ width: "30%", borderTop: "0px", padding: "3px 10px" }}
              >
                F.O. / D.O.
              </td>
              <td
                style={{ width: "60%", borderTop: "0px", padding: "3px 10px" }}
              >
                {vd.fo_do}
              </td>
            </tr>
            <tr>
              <td style={{ width: "30%", padding: "3px 10px" }}>F.W. & D.W.</td>
              <td style={{ width: "60%", padding: "3px 10px" }}></td>
            </tr>
            <tr>
              <td style={{ width: "30%" }}>Water Ballast / No. of Tanks</td>
              <td style={{ width: "60%" }}>{vd.water_ballast}</td>
            </tr>
            <tr>
              <td colspan="2">Ballast Pump Rate/ Hr- (Number)</td>
              <td></td>
            </tr>
          </table>
        </div>

        <div
          style={{
            width: "36%",
            border: "1px solid black",
            padding: "2px",
            display: "inline-block",
            verticalAlign: "top"
          }}
          className="mt-2"
        >
          <table className="vc-particulars">
            <tbody>
              <tr>
                <td style={{ width: "60%" }}>MMSI Number</td>
                <td style={{ width: "40%" }}></td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Official Number</td>
                <td style={{ width: "40%" }}>{vd.official_number}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>IMO Number</td>
                <td style={{ width: "40%" }}>{vd.imo_number}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Call Sign</td>
                <td style={{ width: "40%" }}>{vd.call_sign}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>LOA</td>
                <td style={{ width: "40%" }}>{vd.loa}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>LBP</td>
                <td style={{ width: "40%" }}></td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Gross Tonnage</td>
                <td style={{ width: "40%" }}>{vd.grt}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Net Tonnage</td>
                <td style={{ width: "40%" }}>{vd.nrt}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Suez Canal Gross Tonnage</td>
                <td style={{ width: "40%" }}>{vd.suez_canal_gt}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Suez Canal Net Tonnage</td>
                <td style={{ width: "40%" }}>{vd.suez_canal_nt}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Lightship</td>
                <td style={{ width: "40%" }}>{vd.lightship}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Summer TPC</td>
                <td style={{ width: "40%" }}>{vd.summer_tpc}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>FWA</td>
                <td style={{ width: "40%" }}>{vd.fwa}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Moulded Breadth</td>
                <td style={{ width: "40%" }}></td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Moulded Depth</td>
                <td style={{ width: "40%" }}>{vd.moulded_depth}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Air Draft</td>
                <td style={{ width: "40%" }}>{vd.draft}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Dist From Bridge to Bow</td>
                <td style={{ width: "40%" }}>{vd.dist_from_bridge_to_bow}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Dist From Bridge to Aft</td>
                <td style={{ width: "40%" }}>{vd.dist_from_bridge_to_aft}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Suez Canal I.D. No.</td>
                <td style={{ width: "40%" }}>{vd.suez_canal_id}</td>
              </tr>
              <tr>
                <td style={{ width: "60%" }}>Panama Canal I.D. No.</td>
                <td style={{ width: "40%" }}></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            border: "1px solid black",
            padding: "2px",
            display: "inline-block",
            verticalAlign: "top"
          }}
        >
          <table className="vc-particulars">
            <tbody>
              <tr>
                <td colspan="6" style={{ textAlign: "center" }}>
                  OWNER / CHARTERER / SHIP MANAGEMENT COMPANY
                </td>
              </tr>
              <tr>
                <td style={{ width: "20%" }}>Registered Owner</td>
                <td colspan="5">{vd.registered_owner}</td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Address:</td>
                <td colspan="5">{vd.owner_address}</td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Tel:</td>
                <td style={{ width: "20%" }}>{vd.owner_telephone_no}</td>
                <td style={{ width: "10%" }}>PIC:</td>
                <td style={{ width: "20%" }}></td>
                <td style={{ width: "10%" }}>PIC:</td>
                <td style={{ width: "20%" }}></td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Fax:</td>
                <td style={{ width: "20%" }}>{vd.owner_fax_no}</td>
                <td style={{ width: "10%" }}>Tel:</td>
                <td style={{ width: "20%" }}></td>
                <td style={{ width: "10%" }}>Tel:</td>
                <td style={{ width: "20%" }}></td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Email:</td>
                <td style={{ width: "20%" }}>{vd.owner_email}</td>
                <td style={{ width: "10%" }}>Email:</td>
                <td style={{ width: "20%" }}></td>
                <td style={{ width: "10%" }}>Email:</td>
                <td style={{ width: "20%" }}></td>
              </tr>
              <tr>
                <td style={{ width: "20%" }}>Charterer</td>
                <td colspan="5">{vd.charterer}</td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Address:</td>
                <td colspan="5">{vd.charterer_address}</td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Tel:</td>
                <td style={{ width: "20%" }}>{vd.charterer_telephone_no}</td>
                <td style={{ width: "10%" }}>PIC:</td>
                <td style={{ width: "50%" }} colspan="3"></td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Fax:</td>
                <td style={{ width: "20%" }}>{vd.charterer_fax_no}</td>
                <td style={{ width: "10%" }}>Mob:</td>
                <td style={{ width: "50%" }} colspan="3"></td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Email:</td>
                <td style={{ width: "20%" }}>{vd.charterer_email}</td>
                <td style={{ width: "10%" }}>Email:</td>
                <td style={{ width: "50%" }} colspan="3"></td>
              </tr>
              <tr>
                <td style={{ width: "20%" }}>Ship Management Company</td>
                <td colspan="3"></td>
                <td>IMO Company ID:</td>
                <td>{vd.imo_company_no}</td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Address:</td>
                <td colspan="5">{vd.ship_management_address}</td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Tel:</td>
                <td style={{ width: "20%" }}>
                  {vd.ship_management_telephone_no}
                </td>
                <td style={{ width: "10%" }}></td>
                <td style={{ width: "50%" }} colspan="3"></td>
              </tr>
              <tr>
                <td style={{ width: "20%", textAlign: "right" }}>Fax:</td>
                <td style={{ width: "20%" }}>{vd.ship_management_fax_no}</td>
                <td style={{ width: "10%" }}>Email:</td>
                <td style={{ width: "50%" }} colspan="3">
                  {vd.ship_management_email}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            width: "48%",
            marginTop: "10px",
            border: "1px solid black",
            padding: "2px",
            display: "inline-block",
            verticalAlign: "top",
            marginRight: "18px"
          }}
        >
          <table className="vc-particulars">
            <tbody>
              <tr>
                <td colspan="5" style={{ textAlign: "center" }}>
                  FREEBOARD / DRAFT / DISPLACEMENT / DEADWEIGHT
                </td>
              </tr>
              <tr>
                <td style={{ width: "20%" }}>ZONE</td>
                <td style={{ width: "20%" }}>Freeboard</td>
                <td style={{ width: "20%" }}>Draft</td>
                <td style={{ width: "20%" }}>Displacement</td>
                <td style={{ width: "20%" }}>Deadweight</td>
              </tr>
              <tr>
                <td>Summer</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Tropical</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Tropical FW</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Fresh Water</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Winter</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            width: "48%",
            marginTop: "10px",
            border: "1px solid black",
            padding: "2px",
            display: "inline-block",
            verticalAlign: "top"
          }}
        >
          <table className="vc-particulars">
            <tbody>
              <tr>
                <td colspan="5" style={{ textAlign: "center" }}>
                  HOLD CAPACITY
                </td>
              </tr>
              <tr>
                <td rowspan="2">Cargo Hold</td>
                <td colspan="2">GRAIN</td>
                <td colspan="2">BALE</td>
              </tr>
              <tr>
                <td>
                  M<sup>3</sup>
                </td>
                <td>
                  Ft<sup>3</sup>
                </td>
                <td>
                  M<sup>3</sup>
                </td>
                <td>
                  Ft<sup>3</sup>
                </td>
              </tr>
              <tr>
                <td>CH #1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>CH #2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>CH #3</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>CH #4</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>CH #5</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            width: "48%",
            marginTop: "10px",
            border: "1px solid black",
            padding: "2px",
            display: "inline-block",
            verticalAign: "top",
            marginRight: "18px"
          }}
        >
          <table className="vc-particulars">
            <tbody>
              <tr>
                <td colspan="2">CARGO GEAR</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>DECK CRANE- Number</td>
                <td style={{ width: "50%" }}>{vd.deck_crane_no}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Type & Manufacturer</td>
                <td style={{ width: "50%" }}>{vd.type_and_manufacturer}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Hoisting Speed (Hook / Grab)</td>
                <td style={{ width: "50%" }}>{vd.hoisting_speed}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Lowering Speed (at rated load)</td>
                <td style={{ width: "50%" }}>{vd.lowering_speed}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Luffing / Slewing Speed</td>
                <td style={{ width: "50%" }}></td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Winding Hieght / Slewing Angle</td>
                <td style={{ width: "50%" }}>{vd.slewing_angle}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}></td>
                <td style={{ width: "50%" }}></td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Max. Outreach from Ship's side</td>
                <td style={{ width: "50%" }}>{vd.outreach_from_ships_side}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>GRAB BUCKET- Type / Number</td>
                <td style={{ width: "50%" }}>{vd.grab_bucket_type}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>
                  Grab Cap. / Normal Out / Input Source
                </td>
                <td style={{ width: "50%" }}>{vd.grab_cap}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Lifting Capacity / Empty Wt.</td>
                <td style={{ width: "50%" }}>{vd.lifting_capacity}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            width: "48%",
            marginTop: "10px",
            border: "1px solid black",
            padding: "2px",
            display: "inline-block",
            verticalAlign: "top"
          }}
        >
          <table className="vc-particulars">
            <tbody>
              <tr>
                <td colspan="2">SHIP'S COMMUNICATION</td>
              </tr>
              <tr>
                <td style={{ width: "30%" }}>INM FBB Tel.</td>
                <td>{vd.inm_fbb_tel}</td>
              </tr>
              <tr>
                <td style={{ width: "30%" }}>Iridium Tel.</td>
                <td>{vd.iridium_tel}</td>
              </tr>
              <tr>
                <td style={{ width: "30%" }}>INM Fax</td>
                <td>{vd.inm_fax}</td>
              </tr>
              <tr>
                <td style={{ width: "30%" }}>INM C Telex</td>
                <td>{vd.inm_c_telex}</td>
              </tr>
              <tr>
                <td style={{ width: "30%" }}>E-Mail</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "2em",
            right: "3em",
            lineHeight: "1em",
            textAlign: "center"
          }}
        >
          <p style={{ fontWeight: "bold" }}>{pc.master}</p>
          <p
            style={{
              fontSize: "13spx",
              borderTop: "1px solid #000",
              width: "14em",
              paddingTop: "5px",
              marginTop: "5px"
            }}
          >
            Master of {vd.vessel_name}
          </p>
        </div>
      </div>
    );
  }
}

class ShipsParticularPDF extends Component {
  render() {
    return (
      <div>
        <div
          className="col-md-12 d-flex justify-content-end p0"
          style={{ marginBottom: "0.5em" }}
        >
          <ReactToPrint
            trigger={() => (
              <Button className="btn-sm" color="primary">
                PRINT
              </Button>
            )}
            content={() => this.componentRef}
          />
        </div>

        <PrintPDF
          className="mt-2"
          sendID={this.props.getID}
          ref={el => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default withRouter(ShipsParticularPDF);
