import React, { Component } from "react";
import { Col, Table } from "reactstrap";
import { Icon } from "react-fa";

import back_icon from "../../img/svg/BackIcon.svg";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./Anchorage.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";

import api from "../../helpers/api.helper";
import { withRouter } from "react-router-dom";

class Anchorage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAudit: false,
      __ID: this.props.match.params.id,
      __portcall: {},
      __anchorage: []
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__portcall"
        })
        .then(data => {
          this.setState({
            __anchorage: data.anchorage_logs
          });
        });
    }
  };

  handleAudit = () => {
    this.setState(prevState => ({
      showAudit: !prevState.showAudit
    }));
  };

  render() {
    const { showAudit, __anchorage } = this.state;
    const vd = this.state.__portcall;
    // const __anchorage = vd.anchorage_logs;

    return (
      <div>
        <div className="d-flex flex-column">
          <Navbar />
          <PortCallToolbar />

          <div
            md={{ size: 12 }}
            className="d-flex flex-column page-header"
            ref={this.pageHeader}
          >
            <Col md={{ size: 12 }} className="d-flex flex-row">
              <Col className="d-flex flex-row col-md-6">
                <img src={back_icon} alt="back_icon" className="" width="25" />
                <p className="vessel-name aligner justify-content-start">
                  {vd.vessel_name}, Voyage {vd.voyage_number}
                </p>
              </Col>
            </Col>
          </div>

          <Col md={{ size: 12 }} className="main-content d-flex flex-row mt-3">
            <Col md={{ size: 2 }}>
              <Sidenav dataFromParent={this.props.match.params.id} visit={vd} />
            </Col>

            <Col md={{ size: 10 }} className="mc-right d-flex flex-column">
              <div
                className="form-container-box bt-green"
                style={{ margin: "0 5px", minHeight: "30em" }}
              >
                <div className="fcb-title">Anchorage</div>

                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "20px" }}
                >
                  <thead>
                    <tr>
                      <th>Anchorage Date</th>
                      <th>Date Time Completed</th>
                      <th>Location</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Service</th>
                      <th>Tug Boats</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {__anchorage.map((a, i) => (
                      <tr key={i}>
                        <td>{a.date || "-"}</td>
                        <td>{a.date_end || "-"}</td>
                        <td>{a.anchorage || "-"}</td>
                        <td>{a.lat || "-"}</td>
                        <td>{a.long || "-"}</td>
                        <td>{a.service || "-"}</td>
                        <td>
                          {a.tug_boats.map((tb, i_tb) => (
                            <span key={i_tb}>{tb.name}</span>
                          ))}
                        </td>
                        <td>
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <label>
                              <Toggle
                                defaultChecked={this.state.tofuIsReady}
                                onChange={this.handleTofuChange}
                              />
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <Col col={{ size: 12 }} className="d-flex flex-row p-2 mt-3">
                <div className="form-container-box bt-green col-md-12">
                  <div className="col-md-12 d-flex flex-row auditHead">
                    {showAudit ? (
                      <Icon
                        className=""
                        name="angle-up"
                        onClick={this.handleAudit}
                      />
                    ) : (
                      <Icon
                        className=""
                        name="angle-down"
                        onClick={this.handleAudit}
                      />
                    )}

                    <p>Audit Trails</p>
                  </div>

                  <div>
                    {showAudit ? (
                      <Col md={{ size: 12 }} className="p-0">
                        <Table
                          className="custom-table"
                          bordered
                          style={{ marginTop: "20px" }}
                        >
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Details</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </Col>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

export default withRouter(Anchorage);
