import React, { Component } from "react";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import { Icon } from "react-fa";
import "./Parties.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import { withRouter } from "react-router-dom";

import back_icon from "../../img/svg/BackIcon.svg";

import api from "../../helpers/api.helper";

class Parties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noVmb: true,
      __ID: this.props.match.params.id,
      __data: {}
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, { endpoint: "portcall/" + this.state.__ID })
        .then(data => {});
    }
  };

  render() {
    const distanceY = window.pageYOffset;
    const vd = this.state.__data;

    return (
      <div className="parties">
        <div className="d-flex flex-column">
          <Navbar className="no-vmb" />

          <div
            md={{ size: 12 }}
            className={`d-flex flex-column page-header ${
              distanceY > 100 ? "d-none" : ""
            }`}
            ref={this.pageHeader}
          >
            <Col md={{ size: 12 }} className="d-flex flex-row">
              <Col className="d-flex flex-row col-md-6">
                <img src={back_icon} alt="back_icon" className="" width="25" />
                <p className="vessel-name aligner justify-content-start">
                  {vd.vessel_name}, Voyage {vd.voyage_number}
                </p>
              </Col>
            </Col>
          </div>

          <Col md={{ size: 12 }} className="main-content d-flex flex-row mt-3">
            <Col md={{ size: 2 }}>
              <Sidenav dataFromParent={this.props.match.params.id} visit={vd} />
            </Col>
            <Col md={{ size: 10 }} className="mc-right d-flex flex-column">
              <div
                className="form-container-box bt-green"
                style={{ margin: "0 5px", minHeight: "30em" }}
              >
                <Form className="col-md-12 p-0">
                  <div className="fcb-title">Parties</div>

                  <Col md={{ size: 12 }} className="p-0 mt-3 d-flex flex-row">
                    <div className="col-md-4 d-flex flex-row">
                      <FormGroup className="col-md-10 p-0">
                        <Label for="exampleSelect">Surveyors</Label>
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          className="form-control-sm"
                        >
                          <option>-</option>
                        </Input>
                      </FormGroup>

                      <div className="col-md-2 d-flex align-items-center">
                        <Icon className="" name="plus" />
                      </div>
                    </div>

                    <div className="col-md-4 d-flex flex-row">
                      <FormGroup className="col-md-10 p-0">
                        <Label for="exampleSelect">Brokers</Label>
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          className="form-control-sm"
                        >
                          <option>-</option>
                        </Input>
                      </FormGroup>

                      <div className="col-md-2 d-flex align-items-center">
                        <Icon className="" name="plus" />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex flex-row">
                      <FormGroup className="col-md-10 p-0">
                        <Label for="exampleSelect">Agents</Label>
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          className="form-control-sm"
                        >
                          <option>-</option>
                        </Input>
                      </FormGroup>

                      <div className="col-md-2 d-flex align-items-center">
                        <Icon className="" name="plus" />
                      </div>
                    </div>
                  </Col>
                </Form>
              </div>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

export default withRouter(Parties);
