import api from "./api.helper";

const statusChange = ({ portcall, statusData }) => {
  return new Promise((resolve, reject) => {
    if (!portcall || !statusData) {
      return reject({ message: "Could not update port call status." });
    }

    return api
      .manualUpdate({
        endpoint: "portcall/status_change/" + portcall._id,
        request_params: {
          ...statusData
        }
      })
      .then(response => {
        if (response.status === 200) {
          return resolve(response.data);
        } else {
          return reject(response);
        }
      });
  });
};

export default statusChange;
