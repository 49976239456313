import React, { Component } from "react";
import "./PageHeader.scss";
import { Col } from "reactstrap";
import Icon from "react-fa";

import { SkellyBar } from "../../Tools/SkeletonScreen/SkeletonScreen";

export default class PageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewMode: "editMode",
    };
  }

  sendData = (vm) => {
    this.setState({
      viewMode: vm,
    });
    this.props.parentCallback(vm);
  };

  render() {
    const { subtitle } = this.props;
    const NAME = this.props.sendVName;
    const VOYAGE_NO = this.props.sendVoyageNo;

    return (
      <React.Fragment>
        <Col
          md={{ size: 12 }}
          className={
            this.props.pagename
              ? this.props.pagename === "hide-me"
                ? "pageHeader hideMe"
                : "pageHeader withPageName"
              : "pageHeader"
          }
        >
          <Col md={{ size: 12 }} className="d-flex flex-row vessel-title">
            {/* <img src={back_icon} alt="back_icon" className="" width="25" /> */}
            <span className="back-icon">
              <Icon name="chevron-left" />
            </span>
            {this.props.pagename && !(this.props.pagename === "hide-me") ? (
              <p className="page-name">{this.props.pagename}</p>
            ) : (
              ""
            )}
            {NAME || VOYAGE_NO || subtitle ? (
              !subtitle ? (
                <p className="vessel-name">
                  {NAME}
                  {VOYAGE_NO ? <span>, Voyage {VOYAGE_NO}</span> : ""}
                </p>
              ) : (
                <p className="vessel-name">{subtitle}</p>
              )
            ) : (
              <SkellyBar height="25" width="200" className="ml-2" />
            )}
          </Col>

          {/* <Col md={{ size: 12 }} className="d-flex flex-row">
            <Col md={{ size: 2 }} className="view-mode-button">
              {this.props.hideDocToggle ? (
                ""
              ) : (
                <>
                  <span
                    onClick={() => {
                      let view_mode = "docMode";
                      this.sendData(view_mode);
                    }}
                    className={
                      viewMode === "docMode" ? "vmb vmb-selected" : "vmb"
                    }
                  >
                    <span>Document View</span>
                    <Icon name="file" />
                  </span>
                  <span
                    onClick={() => {
                      let view_mode = "editMode";
                      this.sendData(view_mode);
                    }}
                    className={
                      viewMode === "editMode" ? "vmb vmb-selected" : "vmb"
                    }
                  >
                    <span>Edit View</span>
                    <Icon name="edit" />
                  </span>
                </>
              )}
            </Col>
          </Col> */}
        </Col>
      </React.Fragment>
    );
  }
}
