import React, { Component } from "react";
import "./ProgressSteps.scss";
import Icon from "react-fa";
import SwipeableViews from "react-swipeable-views";
import constants from "../../../constants";
import Media from "react-media";

export class ProgressSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stat_index: 0
    };
  }

  handleChange = value => {
    const current_tab = this.stat_index;
    if (current_tab !== value) {
      this.setState({
        stat_index: value
      });
    }
  };

  handleChangeIndex = stat_index => {
    this.setState({
      stat_index
    });
  };

  render() {
    const { stat_index } = this.state;
    const props = this.props;
    const childrencount = React.Children.count(props.children) - 1;
    const width = 100 / childrencount;
    const { toArray } = React.Children;
    const progress =
      toArray(props.children).filter(c => c.props.visited).length +
      toArray(props.children).filter(c => c.props.pending).length -
      1;
    const progressper = width * progress + "%";

    const children = React.Children.map(props.children, child => {
      return React.cloneElement(child, { width: width + "%" });
    });

    return (
      <div
        className={
          children.length > 5
            ? "progress-bar-wrapper fivePlus"
            : "progress-bar-wrapper"
        }
      >
        <div className="status-bar" style={{ width: "85%" }}>
          <div
            className="current-status"
            style={{
              width: progressper
            }}
          ></div>
        </div>
        <ul className="progress-bar d-flex flex-row">
          <Media queries={constants.media_queries}>
            {matches =>
              !matches.xs ? (
                <>{children}</>
              ) : (
                <>
                  {children.map((step, i) => {
                    return (
                      <>
                        {React.cloneElement(step, {
                          onView: i === stat_index,
                          //   onClick: () => this.handleChange(i),
                          key: i
                        })}
                      </>
                    );
                  })}
                </>
              )
            }
          </Media>
        </ul>
        <div className="shadow-scroll">
          <div className={!(stat_index === 0) ? "shadow-on" : ""}></div>
          <div
            className={!(stat_index === children.length - 1) ? "shadow-on" : ""}
          ></div>
        </div>
        {children.length > 5 ? (
          <Media queries={constants.media_queries}>
            {matches =>
              matches.xs ? (
                <SwipeableViews
                  resistance
                  index={stat_index}
                  onChangeIndex={this.handleChangeIndex}
                  className={
                    stat_index === 0
                      ? "swipeable-portStatus index0"
                      : stat_index === children.length - 1
                      ? "swipeable-portStatus indexLast"
                      : "swipeable-portStatus"
                  }
                >
                  {children.map((step, i) => {
                    return (
                      //   <div onClick={() => this.handleChange(i)}>
                      <div>
                        {React.cloneElement(step, { onView: i === stat_index })}
                      </div>
                    );
                  })}
                </SwipeableViews>
              ) : (
                ""
              )
            }
          </Media>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export class Step extends Component {
  render() {
    const props = this.props;
    let classes = "section";
    if (props.onView) {
      classes = "section onView";
    }

    return (
      <li
        key={props.key}
        className={
          props.visited
            ? classes + " visited"
            : props.pending
            ? classes + " pending"
            : classes + " blank"
        }
        style={{ width: props.width }}
        onClick={props.onClick}
      >
        <div className="step">
          <Icon
            name={props.visited ? "check" : props.pending ? "ellipsis-h" : ""}
          />
        </div>
        <p>{props.title}</p>
      </li>
    );
  }
}
