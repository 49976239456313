import React, { Component } from "react";
import { Col } from "reactstrap";
import Navbar from "../../Navbar/Navbar";
import Sidenav from "../../Sidenav/Sidenav";
import "./ManifestDocView.scss";
import back_icon from "../../../img/svg/BackIcon.svg";
import api from "../../../helpers/api.helper";
import { withRouter } from "react-router-dom";

class ManifestDocView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __port_calls: []
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "portcall/" + this.state.__ID,
        state_key: "__port_calls"
      })
      .then(data => {});
  };

  render() {
    const vd = this.state.__port_calls;

    return (
      <div className="manifestDocView">
        <div className="d-flex flex-column">
          <Navbar />

          <Col md={{ size: 12 }} className="d-flex flex-row">
            <Col className="d-flex flex-row col-md-6">
              <img src={back_icon} alt="back_icon" className="" width="25" />
              <p className="vessel-name aligner justify-content-start">
                {vd.vessel_name}, Voyage {vd.voyage_number}
              </p>
            </Col>
          </Col>

          <Col md={{ size: 12 }} className="main-content d-flex flex-row">
            <Col md={{ size: 2 }}>
              <Sidenav dataFromParent={this.state.__ID} visit={vd} />
            </Col>

            <Col md={{ size: 10 }} className="mc-right d-flex flex-column">
              <div className="viewContainer col-md-12">
                <div className="viewInnerContainer">
                  <div className="col-md-12 disclaimer">
                    <b>Disclaimer:</b>
                    <span>
                      {" "}
                      This document is for information purposes only and shall
                      NOT be submitted or used for processing at the Bureau of
                      Customs nor be relied upon as basis for compliance with
                      any legal requirement.
                    </span>
                  </div>

                  <div className="manifestGenInfo col-md-12">
                    <p className="col-md-12 title">
                      Manifest - General Information
                    </p>

                    <div className="col-md-12 p-0">
                      <div className="col-md-6 d-flex flex-row p-0">
                        <div className="vLabel col-md-4">Custom Office:</div>
                        <div className="vText col-md-3">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                        <div className="vText col-md-5">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 p-0 mt-2 d-flex flex-row">
                      <div className="col-md-6 d-flex flex-column p-0">
                        <div className="vLabel col-md-5">Registry Number:</div>
                      </div>
                      <div className="col-md-6 d-flex flex-row p-0">
                        <div className="vLabel col-md-5">Date of Arrival:</div>
                        <div className="vLabel col-md-5">Time of Arrival:</div>
                      </div>
                    </div>

                    <div className="col-md-12 p-0 mt-2 d-flex flex-row">
                      <div className="col-md-6 p-0">
                        <div className="vText col-md-5">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                      </div>
                      <div className="col-md-6 p-0 d-flex flex-row">
                        <div className="vText col-md-5">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                        <div className="vText col-md-3">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 p-0 mt-2 d-flex flex-row">
                      <div className="col-md-6 d-flex flex-column p-0"></div>
                      <div className="col-md-6 d-flex flex-row p-0">
                        <div className="vLabel col-md-5">
                          Date of Registration:
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 p-0 mt-2 d-flex flex-row">
                      <div className="col-md-6 p-0"></div>
                      <div className="col-md-6 p-0 d-flex flex-row">
                        <div className="vText col-md-5">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                        <div className="vText col-md-3">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 p-0 mt-2 d-flex flex-row">
                      <div className="col-md-6 d-flex flex-column p-0">
                        <div className="vLabel col-md-12">
                          Place of Departure:
                        </div>
                      </div>
                      <div className="col-md-6 d-flex flex-row p-0">
                        <div className="vLabel col-md-12">
                          Place of Destination:
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 p-0 mt-2 d-flex flex-row">
                      <div className="col-md-6 p-0 d-flex flex-row">
                        <div className="vText col-md-3">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                        <div className="vText col-md-9">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                      </div>
                      <div className="col-md-6 p-0 d-flex flex-row">
                        <div className="vText col-md-3">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                        <div className="vText col-md-9">
                          <div className="text-line col-md-12 p-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

export default withRouter(ManifestDocView);
