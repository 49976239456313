import React, { Component } from "react";
import "./VesselEntryClearanceView.scss";
import sbma_logo from "../../img/Sbma_logo.png";
import constants from "../../constants";
import Moment from "react-moment";
import moment from "moment";
import api from "../../helpers/api.helper";

export default class VesselEntryClearanceView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CARGO_SERVICES: [],
      ID: this.props.pcID,
      PORT_CALL: {
        shipping_agency: {
          name: "",
          address: "",
          tel_no: "",
          fax_no: "",
        },
      },
      PURPOSES: [],
    };
  }

  componentDidMount = () => {
    this.loadServices();

    // this.loadData();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "portcall/" + this.state.ID,
        state_key: "PORT_CALL",
      })
      .then((data) => {
        console.log("DATA>>>", data);
      });
  };

  loadServices = () => {
    let services = this.props.visit.selected_port_call_purposes;
    let cargoServices = [];
    let purposes = [];

    for (let x in services) {
      if (services[x].service.startsWith("Cargo")) {
        if (services[x].arrastre && services[x].stevedoring) {
          cargoServices[x] =
            services[x].arrastre + " / " + services[x].stevedoring + ", ";
        } else {
          cargoServices[x] = "";
        }
      }

      if (services[x].purpose) {
        purposes[x] = services[x].service + " (" + services[x].purpose + ") ,";
      } else {
        purposes[x] = "";
      }
    }
    if (cargoServices && cargoServices[cargoServices.length - 1]) {
      cargoServices[cargoServices.length - 1] = cargoServices[
        cargoServices.length - 1
      ].replace(", ", "");
    }

    if (purposes && purposes[purposes.length - 1]) {
      purposes[purposes.length - 1] = purposes[purposes.length - 1].replace(
        ", ",
        ""
      );
    }

    this.setState({ CARGO_SERVICES: cargoServices, PURPOSES: purposes });
  };

  render() {
    const { CARGO_SERVICES, PURPOSES } = this.state;
    const pc = this.state.PORT_CALL;
    const today = new Date();

    if (this.props.visit) {
      return (
        <div className="VEC-container">
          <img className="sbma-logo" src={sbma_logo} alt={sbma_logo} />

          <div className="subic-name">
            <h4>SUBIC BAY METROPOLITAN AUTHORITY</h4>
            <h5>Subic Bay Freeport Zone, Philippines</h5>
          </div>

          <div className="dept-header">
            <p>Department Quality Form</p>
            <p>SED-VENC-39</p>
            <p>Revision No. 02</p>
            <p>
              Effectivity Date:{" "}
              {moment(today.setDate(today.getDate() + 1)).format(
                constants.__dateFormat
              )}
            </p>
          </div>

          <div className="control-no">
            <p style={{ fontWeight: "bold", fontSize: "8px" }}>CONTROL NO.</p>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>-</p>
          </div>

          <div className="col-md-12 d-flex flex-row justify-content-center">
            <div className="vec-title">
              <h5>VESSEL ENTRY CLEARANCE</h5>
            </div>
          </div>

          <table className="table-pdf mt-4">
            <tbody>
              <tr className="pdf-cellwidthguide">
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
                <td width="8.33333%"></td>
              </tr>
              <tr style={{ verticalAlign: "middle" }}>
                <td colSpan="12" className="p-0">
                  <div className="tbl-title fs15">SHIP PARTICULARS</div>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  NAME OF VESSEL: <br />{" "}
                  <span className="fwb">{this.props.visit.vessel_name}</span>
                </td>
                <td colspan="4">
                  CLASS/TYPE: <br />{" "}
                  <span className="fwb">{this.props.visit.vessel_class}</span>
                </td>
                <td colspan="4">
                  REG/VOYAGE NO: <br />{" "}
                  <span className="fwb">{this.props.visit.voyage_number}</span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  GRT: <br />{" "}
                  <span className="fwb">{this.props.visit.grt}</span>
                </td>
                <td colspan="4">
                  NRT: <br />{" "}
                  <span className="fwb">{this.props.visit.nrt}</span>
                </td>
                <td colspan="4">
                  DWT: <br />{" "}
                  <span className="fwb">{this.props.visit.dwt}</span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  LOA: <br />{" "}
                  <span className="fwb">{this.props.visit.loa}</span>
                </td>
                <td colspan="4">
                  DRAFT: <br />{" "}
                  <span className="fwb">{this.props.visit.draft}</span>
                </td>
                <td colspan="4">
                  BEAM: <br />{" "}
                  <span className="fwb">{this.props.visit.beam}</span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  FLAG/COUNTRY: <br />{" "}
                  <span className="fwb">{this.props.visit.country}</span>
                </td>
                <td colspan="4">
                  OWNER: <br />{" "}
                  <span className="fwb">{this.props.visit.owner}</span>
                </td>
                <td colspan="4">
                  MASTER: <br />{" "}
                  <span className="fwb">{this.props.visit.master}</span>
                </td>
              </tr>
              <tr>
                <td colSpan="8">INSURANCE COVERAGE & LIABILITIES (P&I):</td>
                <td colspan="4">DATE COVERAGE</td>
              </tr>

              <tr style={{ verticalAlign: "middle" }}>
                <td colSpan="12" className="p-0">
                  <div className="tbl-title fs15">PORT CALLS OF ITINERARY</div>
                </td>
              </tr>
              <tr>
                <td rowSpan="4" colspan="4">
                  PURPOSE OF PORT CALL: <br />
                  {PURPOSES.map((p, i) => (
                    <span className="fwb" key={i}>
                      {p}
                    </span>
                  ))}
                </td>
                <td colspan="8">
                  PORT OF ORIGIN: <br />{" "}
                  <span className="fwb">{this.props.visit.port_of_origin}</span>
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  PORT OF DESTINATION: <br />{" "}
                  <span className="fwb">
                    {this.props.visit.port_of_destination}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  ETA:
                  <br />{" "}
                  <span className="fwb">
                    <Moment
                      format={
                        constants.__dateFormat + " " + constants.__timeFormat
                      }
                    >
                      {this.props.visit.eta || "-"}
                    </Moment>
                  </span>
                </td>
                <td colspan="4">
                  ETD:
                  <br />{" "}
                  <span className="fwb">
                    <Moment
                      format={
                        constants.__dateFormat + " " + constants.__timeFormat
                      }
                    >
                      {this.props.visit.etd || "-"}
                    </Moment>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  NEXT PORT OF DESTINATION: <br />{" "}
                  <span className="fwb">
                    {this.props.visit.next_port_of_destination}
                  </span>
                </td>
              </tr>

              <tr style={{ verticalAlign: "middle" }}>
                <td colSpan="12" className="p-0">
                  <div className="tbl-title fs15">
                    {" "}
                    CARGO DETAILS (Nature / Tonnage)
                    <br /> <span className="fwb">N/A</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="12">
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                    }}
                  ></div>
                </td>
              </tr>

              <tr>
                <td colSpan="12" className="p-0">
                  <div className="tbl-title fs15">PASSENGER INFORMATION</div>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  DISEMBARKING <br />{" "}
                  <span className="fwb">
                    {this.props.visit.total_number_of_pd_crew}
                  </span>
                </td>
                <td colspan="4">
                  EMBARKING <br />{" "}
                  <span className="fwb">
                    {this.props.visit.total_number_of_pa_crew}
                  </span>
                </td>
                <td colspan="4">
                  TOTAL NUMBER OF CREW <br />{" "}
                  <span className="fwb">
                    {this.props.visit.total_number_of_crew}
                  </span>
                </td>
              </tr>

              <tr>
                <td colSpan="12" className="p-0">
                  <div className="tbl-title fs15">
                    LOGISTICS REQUIREMENTS (LOGREQ)
                  </div>
                </td>
              </tr>
              <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                <td colSpan="12">
                  <i>
                    Note: Chandling services shall be through the SBMA
                    Accredited Chandlers
                  </i>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  CRANE SERVICES: <br />{" "}
                  <span className="fwb">{this.props.visit.crane_services}</span>
                </td>
                <td colspan="3">
                  ARRASTRE/STEVEDORING: <br />
                  {CARGO_SERVICES.map((cs, i) => (
                    <span className="fwb" key={i}>
                      {cs}
                    </span>
                  ))}
                </td>
                <td colspan="3">
                  FRESHWATER ARRASTRE/STEVEDORING: <br />{" "}
                  <span className="fwb">{this.props.visit.fresh_water}</span>
                </td>
                <td colSpan="3">
                  OTHERS: <br />{" "}
                  <span className="fwb">{this.props.visit.others}</span>
                </td>
              </tr>
              <tr>
                <td colSpan="12">
                  <div style={{ textAlign: "center" }}>
                    <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                      INSTRUCTIONS
                    </label>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <div
                      style={{
                        width: "48%",
                        verticalAlign: "top",
                        display: "inline-block",
                      }}
                    >
                      <ol style={{ fontSize: "8px" }}>
                        <li>
                          Application for entry must be filed 72 hours prior to
                          vessel's ETA at Subic Bay for Foreign Vessel and 12
                          hours for Domestic Vessel.
                        </li>
                        <li>
                          Approval for entry shall be based on the information
                          provided herein with option to be withheld when
                          requirements are not met.
                        </li>
                        <li>
                          Clearance for berthing shall be granted only aftrer
                          the formalities of the Bureau of Customs. Immigration
                          and Quarantine (BCIQ) have been satisfactory
                          accomplished, and after the inspection of Seaport
                          Shipboarding Party has been satisfied.
                        </li>
                      </ol>
                    </div>
                    <div
                      style={{
                        width: "48%",
                        verticalAlign: "top",
                        display: "inline-block",
                      }}
                    >
                      <ol start="5" style={{ fontSize: "8px" }}>
                        <li>
                          Standard radio communication facilities either VHF or
                          HF (SSB) must be used by the English-speaking operator
                          when vessel is approaching Subic Bay.
                        </li>
                        <li>
                          Exit Clearance shall only be issued after payment of
                          all applicable fees and charges
                        </li>
                        <li>
                          Please check your declaration for accuracy and
                          completeness prior to submission; otherwise it will
                          affect the approval of clearance.
                        </li>
                        <li>
                          Submission of false or inaccurate declaration is an
                          offense which is punishable by law.
                        </li>
                      </ol>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="12">
                  SHIPPING AGENCY: <br />{" "}
                  <span className="fwb">{pc.shipping_agency.name}</span>
                </td>
              </tr>
              <tr>
                <td colSpan="8" rowSpan="2">
                  ADDRESS: <br />{" "}
                  <span className="fwb">{pc.shipping_agency.address}</span>
                </td>
                <td colSpan="4">
                  TEL NO.: <br />{" "}
                  <span className="fwb">{pc.shipping_agency.tel_no}</span>
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  FAX NO.: <br />{" "}
                  <span className="fwb">{pc.shipping_agency.fax_no}</span>
                </td>
              </tr>
              <tr>
                <td
                  colSpan="4"
                  style={{
                    verticalAlign: "bottom",
                    height: "120px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {this.props.visit.name}
                  <p style={{ fontSize: "18px" }}></p>
                  <p>(Print Name & Signature)</p>
                </td>
                <td
                  colSpan="4"
                  style={{
                    verticalAlign: "bottom",
                    height: "120px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {this.props.approvals[1] ? this.props.approvals[1].user : ""}
                  <p style={{ fontSize: "18px" }}></p>
                  <p>(Print Name & Signature)</p>
                </td>
                <td
                  colSpan="4"
                  style={{
                    verticalAlign: "bottom",
                    height: " 80px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {this.props.approvals[2] ? this.props.approvals[2].user : ""}
                  <p style={{ fontSize: "18px" }}></p>
                  <p>(Print Name & Signature)</p>
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  DATE/TIME FILED:{" "}
                  {this.props.visit.status_change_log[0] ? (
                    <Moment
                      format={
                        constants.__dateFormat + " " + constants.__timeFormat
                      }
                    >
                      {this.props.visit.status_change_log[0].date || "-"}
                    </Moment>
                  ) : (
                    ""
                  )}
                </td>
                <td colSpan="4">
                  DATE ENDORSED:
                  {this.props.approvals[1] ? (
                    <Moment
                      format={
                        constants.__dateFormat + " " + constants.__timeFormat
                      }
                    >
                      {this.props.approvals[1].date || "-"}
                    </Moment>
                  ) : (
                    ""
                  )}
                </td>
                <td colSpan="4">
                  DATE APPROVED:
                  {this.props.approvals[2] ? (
                    <Moment
                      format={
                        constants.__dateFormat + " " + constants.__timeFormat
                      }
                    >
                      {this.props.approvals[2].date || "-"}
                    </Moment>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }
}
