import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../../../helpers/api.helper";
import constants from "../../../constants";
import moment from "moment";
import "./ArrivalTicker.scss";
import classnames from "classnames";
import { ReactComponent as InPort } from "../svg/In Port.svg";

class VesselsInPort extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DASHBOARD_TICKER: [],
      VESSELS_IN_PORT: [],
    };
  }

  componentDidMount = () => {
    this.loadTicker();
  };

  loadTicker = () => {
    api
      .get(this, {
        endpoint: "dashboard/ticker",
        state_key: "dashboardTicker",
      })
      .then((data) => {
        // console.log("TICKER", data);
        this.setState({
          VESSELS_IN_PORT: data.vessels_in_port,
        });
      });
  };

  render() {
    return (
      <>
        <div className="col-md-12 p0 tickerCon">
          <div class="col-md-12 d-flex flex-row align-items-center">
            <div class="col-md-6 p0 d-flex flex-row">
              <InPort />{" "}
              <p className="table-title d-flex align-items-center col-md-10 p0">
                VESSELS IN PORT
              </p>
            </div>
            <div class="col-md-6 p0 d-flex flex-row justify-content-end">
              <div className="d-flex flex-column align-items-end date-con">
                <span className="date-no">
                  {moment(new Date()).format(constants.__timeFormat)}
                </span>
                <span className="date-label">
                  {moment(new Date()).format(constants.__dateFormat) +
                    " " +
                    moment(new Date()).format(constants.__dayFormat)}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center page-con">
                <span className="page-no">1</span>
                <span className="page-label">PAGE</span>
              </div>
            </div>
          </div>
          <table className="arrivalTable ticker-table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Name of Ship</th>
                <th style={{ width: "12%" }} className="tar">
                  Voyage No.
                </th>
                <th style={{ width: "20%" }}>Cargo</th>
                <th style={{ width: "12%" }} className="tar">
                  Cargo Handlers
                </th>
                <th style={{ width: "18%" }}>B/L Quantity</th>
                <th style={{ width: "9%" }}>Berth</th>
                <th style={{ width: "9%" }} className="tar">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.VESSELS_IN_PORT.map((ip, i) => (
                <tr key={i}>
                  <td>{ip.vessel_name}</td>
                  <td className="tar yellowTD">{ip.voyage_no}</td>
                  <td>
                    {ip.shipping_agency && ip.shipping_agency.name
                      ? ip.shipping_agency.name
                      : ""}
                  </td>
                  <td className="tar">
                    {ip.selected_port_call_purposes.map((sp, i) => (
                      <div key={i}>
                        {sp.arrastre ? sp.arrastre : ""}
                        {sp.arrastre && sp.stevedoring ? " / " : ""}
                        {sp.stevedoring ? sp.stevedoring : ""}
                      </div>
                    ))}
                  </td>
                  <td>
                    {" "}
                    {ip.cargo_details && ip.cargo_details.length > 0
                      ? ip.cargo_details[0].amount +
                        " " +
                        ip.cargo_details[0].uom
                      : "-"}
                  </td>
                  <td></td>
                  <td
                    className={
                      "tar " +
                      classnames({
                        orangeTD:
                          ip.status === "Pending" ||
                          ip.status === "In Transit" ||
                          ip.status === "Announced",
                        greenTD:
                          ip.status === "Arrived" ||
                          ip.status === "Anchorage" ||
                          ip.status === "Berthed" ||
                          ip.status === "Cleared for Exit" ||
                          ip.status === "Resource Plan Approved",
                        blueTD:
                          ip.status === "Submitted" ||
                          ip.status === "Entry Application Endorsed" ||
                          ip.status === "Vessel Exit Application Endorsed" ||
                          ip.status === "Resource Plan Submitted",
                        redTD:
                          ip.status === "Rejected" ||
                          ip.status === "Departed" ||
                          ip.status === "Closed" ||
                          ip.status === "Resource Plan Rejected",
                      })
                    }
                  >
                    {ip.status}
                  </td>
                </tr>
              ))}
            </tbody>{" "}
          </table>
        </div>
      </>
    );
  }
}

export default withRouter(VesselsInPort);
