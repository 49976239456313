import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Input,
  Form,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { withRouter } from "react-router-dom";
import { Icon } from "react-fa";

import moment from "moment";
import constants from "../../constants";
import Datetime from "react-datetime";
import "./CrewList.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import { DropdownList } from "react-widgets";
import PageHeader from "../Tools/PageHeader/PageHeader";
import DocView from "../DocView/DocView";
import AlertModal from "../Tools/AlertModal/AlertModal";
import session from "../../session";
import Swal from "sweetalert2";
import api from "../../helpers/api.helper";
import Media from "react-media";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import SwipeableViews from "react-swipeable-views";
import Moment from "react-moment";

class CrewList extends Component {
  crew_type = window.location.href.match("pre_arrival")
    ? "prearrival"
    : "predeparture";

  is_prearrival = this.crew_type === "prearrival";
  is_predeparture = this.crew_type === "predeparture";

  defaultCrewInfo = {
    portcall_id: "",
    first_name: "",
    last_name: "",
    rank: "",
    nationality: "",
    date_of_birth: "",
    age: "",
    passport_no: "",
    sbn: "",
    date_of_embark: "",
    place_of_birth: "",
    sex: "",
    passport_expiry_date: "",
    sbn_expiry_date: "",
    place_of_embark: "",
    status: "",
    type: this.crew_type,
    err_list: [
      {
        last_name: false,
        first_name: false,
        date_of_birth: false,
        date_of_embark: false,
        passport_expiry_date: false,
        sbn_expiry_date: false,
      },
    ],
  };

  constructor(props) {
    super(props);

    this.state = {
      showAudit: false,
      __ID: this.props.match.params.id,
      __port_call: {},
      __crew_list: [],
      __pc_ID: "",
      Embarking_count: 0,
      Disembarking_count: 0,
      _countries: [],
      _gender: [],
      _CREW_TOTAL: 0,
      _do_later: false,
      viewMode: "editMode",
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
      errorMsg: {
        errLastName: "",
        errFirstName: "",
      },
      disableSave: false,
      viewOnly: false,
      showErr: false,
      _CREW_ERR: [],
      showDoLater: false,
      addedPre: false,
      editCrewItem: -1,
      swipe_index: 0,
    };
  }

  countCrewStatus = (data) => {
    let count = { Disembarking_count: 0, Embarking_count: 0 };
    for (let x in data) {
      count[data[x].status + "_count"]++;
    }

    this.setState({ ...count });
  };

  handleCount = (data) => {
    let { __port_call } = this.state;

    let crew_count = 0;

    for (let x in data) {
      let crew = data[x];

      if (crew.last_name !== undefined && crew.first_name !== undefined) {
        crew_count++;
      }
    }

    // console.log("KAWNT", crew_count);

    this.setState({
      _CREW_TOTAL: crew_count,
    });
  };

  handlePreArrivalCrew = () => {
    Swal.fire({
      icon: "question",
      title: "Add Pre-Arrival Crew?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Pre-Arrival Crew Added!",
          text: "Don't forget to save to apply changes.",
          showConfirmButton: false,
          timer: 3000,
        });
        api
          .get(this, {
            endpoint: "crew_list/" + this.state.__ID,
            state_key: "__crew_list",
            preserve_state: true,
            request_params: { search: { type: "prearrival" } },
          })
          .then((crew_list) => {
            let appendData =
              this.state.__crew_list[0].last_name.trim().length > 0;

            let __crew_list = [];

            if (appendData) {
              __crew_list = this.state.__crew_list;
            }
            for (let x in crew_list) {
              crew_list[x].type = "predeparture";
              if (crew_list[x]._id) {
                delete crew_list[x]._id;
              }

              __crew_list = [...__crew_list, crew_list[x]];
            }
            this.setState({ __crew_list });
          });
      }
    });
  };

  componentDidMount = () => {
    this.loadData();
    this.loadCountry();
    this.loadGender();
    this.disableField();

    // this.handleCount();
  };

  loadGender = () => {
    let gender_names = [];
    let gender_list = constants.GENDER;

    for (let x in gender_list) {
      let gender = gender_list[x];

      gender_names[x] = gender.name;
    }

    // console.log("GENDERRR", gender_names);

    this.setState({
      _gender: gender_names,
    });
  };

  loadCountry = () => {
    let country_names = [];
    let countries = constants.__countries;

    for (let x in countries) {
      let country = countries[x];

      country_names[x] = country.nationality;
    }

    country_names.sort();

    // console.log("ASMDKNMAKLSDLKANSD", country_names);

    this.setState({
      _countries: country_names,
    });
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call",
        })
        .then((data) => {
          this.setState({
            __pc_ID: data._id,
          });
        })
        .then(() => {
          let search = { type: this.crew_type };

          api
            .get(this, {
              endpoint: "crew_list/" + this.state.__ID,
              state_key: "__crew_list",
              request_params: { search },
            })
            .then((data) => {
              if (data.length === 0) {
                this.setState({
                  __crew_list: [
                    { ...this.defaultCrewInfo, portcall_id: this.state.__ID },
                  ],
                });
              } else {
                this.countCrewStatus(data);
                this.handleCount(data);
              }

              if (
                window.location.href.match(
                  "/port_call/crew_list_pre_departure/" + data
                ) &&
                !data.last_name &&
                !data.first_name
              ) {
                this.setState({
                  is_save_btn: false,
                });
              } else {
                this.setState({
                  is_save_btn: true,
                });
              }
            });
        });
    }
  };

  // updateCrew = (val, index, key) => {
  //   const { crewRow } = this.state;
  //   crewRow[index][key] = val;
  //   this.setState({ crewRow });
  // };

  addCrew = () => {
    const currCrew = this.state.__crew_list;

    this.setState({
      __crew_list: [
        ...currCrew,
        {
          ...this.defaultCrewInfo,
          ...this.crew_err,
          portcall_id: this.state.__ID,
        },
      ],
    });

    // console.log("CHECHSKDHFS", this.state.__crew_list);
  };

  rmvCrew = (i, ln, fn) => {
    if (ln) {
      Swal.fire({
        icon: "question",
        title: "Delete " + ln + ", " + fn + "?",
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",

            title: "Deleted!",
            showConfirmButton: false,
            timer: 1500,
          });

          const currCrew = this.state.__crew_list;

          currCrew.splice(i, 1);
          this.countCrewStatus(currCrew);
          this.setState({
            __crew_list: currCrew,
          });

          this.handleCount(currCrew);
        }
      });
    } else {
      const currCrew = this.state.__crew_list;

      currCrew.splice(i, 1);
      this.countCrewStatus(currCrew);
      this.setState({
        __crew_list: currCrew,
      });

      this.handleCount(currCrew);
    }
  };

  handleAudit = () => {
    this.setState((prevState) => ({
      showAudit: !prevState.showAudit,
    }));
  };

  handleSave = () => {
    let { __crew_list } = this.state;
    let has_invalid = false;
    let errArr = [];

    for (let x in __crew_list) {
      let crew = __crew_list[x];

      if (
        __crew_list[x].last_name.trim().length === 0 ||
        __crew_list[x].first_name.trim().length === 0 ||
        !moment(__crew_list[x].date_of_birth).isValid() ||
        !moment(__crew_list[x].date_of_embark).isValid() ||
        !moment(__crew_list[x].passport_expiry_date).isValid() ||
        !moment(__crew_list[x].sbn_expiry_date).isValid()
      ) {
        has_invalid = true;

        errArr[x] = __crew_list[x];
        __crew_list[x].hasErr = true;
        // break;
      } else {
        __crew_list[x].hasErr = false;
      }

      if (__crew_list[x].last_name.trim().length === 0) {
        crew.err_list.last_name = true;
      }

      if (__crew_list[x].first_name.trim().length === 0) {
        crew.err_list.first_name = true;
      }

      if (!moment(__crew_list[x].date_of_birth).isValid()) {
        crew.err_list.date_of_birth = true;
      }

      if (!moment(__crew_list[x].date_of_embark).isValid()) {
        crew.err_list.date_of_embark = true;
      }

      if (!moment(__crew_list[x].passport_expiry_date).isValid()) {
        crew.err_list.passport_expiry_date = true;
      }

      if (!moment(__crew_list[x].sbn_expiry_date).isValid()) {
        crew.err_list.sbn_expiry_date = true;
      }

      // if (!moment(__crew_list[x].date_of_birth).isValid()) {
      //   has_invalid = true;
      //   break;
      // }

      // if (!moment(__crew_list[x].date_of_embark).isValid()) {
      //   has_invalid = true;
      //   break;
      // }

      // if (!moment(__crew_list[x].passport_expiry_date).isValid()) {
      //   has_invalid = true;
      //   break;
      // }

      // if (!moment(__crew_list[x].sbn_expiry_date).isValid()) {
      //   has_invalid = true;
      //   break;
      // }

      this.setState({
        __crew_list,
      });
    }

    // console.log("ERRR", errArr);

    if (has_invalid) {
      // alert("May mali!");
      // this.setState({
      //   isRequired: true
      // });

      this.setState(
        {
          tglAlert: false,
          showErr: true,
        },
        () => this.showAlert("error", "Field(s) cannot be empty!", true)
      );
      return;
    } else {
      this.showAlert("loading", "", true);

      this.setState({
        disableSave: true,
        showErr: false,
      });

      api
        .save(this, {
          endpoint: "crew_list/" + this.state.__ID + "/" + this.crew_type,
          _data: __crew_list,
        })
        .then((data) => {
          this.loadData();
          this.handleCount(data);

          this.setState(
            {
              tglAlert: false,
              disableSave: false,
            },
            () =>
              this.showAlert("success", "Crew List successfully saved!", true)
          );
        });

      api
        .update(this, {
          endpoint: "portcall/" + this.state.__ID,
          _data: {
            do_later: false,
            total_number_of_crew: this.state._CREW_TOTAL,
          },
        })
        .then((data) => {
          this.handleCount(data);
        });
    }
  };

  handleDoLater = () => {
    Swal.fire({
      icon: "question",
      title: "Do Crew Details Later?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Confirmed!",
          showConfirmButton: false,
          timer: 1000,
        });

        if (this.state.__port_call.do_later === false) {
          api.update(this, {
            endpoint: "portcall/" + this.state.__ID,
            _data: { do_later: true },
          });
        }

        this.setState({
          _do_later: true,
          showDoLater: false,
        });
      }
    });
  };

  getViewMode = (view_mode) => {
    this.setState({
      viewMode: view_mode,
    });
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  handleChange = (name, value, index) => {
    let { __crew_list } = this.state;

    if (value) {
      if (!__crew_list[index].err_list) {
        __crew_list[index].err_list = {
          name: false,
        };
      } else {
        __crew_list[index][name] = value;
        __crew_list[index].err_list[name] = false;
      }
    } else {
      if (!__crew_list[index].err_list) {
        __crew_list[index].err_list = {
          name: true,
        };
      } else {
        __crew_list[index].err_list[name] = true;
        __crew_list[index][name] = "";
      }
    }

    this.setState({
      __crew_list,
    });

    // console.log("LIST", __crew_list);
  };

  disableField = () => {
    if (session.get().role === "port_admin") {
      this.setState({
        viewOnly: true,
      });
    }
  };

  handleChangeIndex = (swipe_index) => {
    this.setState({
      swipe_index,
    });
  };

  handleIndex = (index) => {
    this.setState({
      swipe_index: index,
    });
  };

  render() {
    const {
      showAudit,
      __crew_list,
      _countries,
      _gender,
      _CREW_TOTAL,
      _do_later,
      viewMode,
      isAlert,
      alertMsg,
      is_save_btn,
      tglAlert,
      viewOnly,
      editCrewItem,
      swipe_index,
    } = this.state;
    const vd = this.state.__port_call;

    const VNAME = vd.vessel_name;
    const VOYAGE_NO = vd.voyage_number;

    const ModalInput = (props) => {
      const [modal, setModal] = useState(false);

      const toggle = () => setModal(!modal);
      return (
        <>
          <p className="popUp-input" onClick={toggle}>
            {props.calendar ? (
              <Moment format="DD-MMM-YYYY">{props.val}</Moment>
            ) : (
              props.val
            )}
            <Icon
              name={
                props.caret ? "caret-down" : props.calendar ? "calendar" : ""
              }
            />
          </p>
          <Modal
            className="modalInput"
            isOpen={modal}
            toggle={toggle}
            zIndex="2001"
            centered
          >
            <ModalBody>{props.children}</ModalBody>
            <ModalFooter>
              <Button outline color="secondary" size="sm" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
    };

    return (
      <div className="crewList">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}

        <div className="d-flex flex-column">
          <Navbar />

          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav
                  dataFromParent={this.props.match.params.id}
                  doLater={_do_later}
                  crewTotal={_CREW_TOTAL}
                  visit={vd}
                />
              ) : this.state.__ID ? (
                <PageHeader
                  parentCallback={this.getViewMode}
                  sendVName={VNAME}
                  sendVoyageNo={VOYAGE_NO}
                />
              ) : (
                <Col className="pageHeader">
                  <Col className="d-flex flex-row vessel-title">
                    <p className="vessel-name">Vessel Entry Registration</p>
                  </Col>
                </Col>
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      doLater={_do_later}
                      crewTotal={_CREW_TOTAL}
                      visit={vd}
                    />
                  </Col>
                ) : this.state.__ID ? (
                  <PageHeader
                    parentCallback={this.getViewMode}
                    sendVName={VNAME}
                    sendVoyageNo={VOYAGE_NO}
                    pagename="Crew Details"
                  />
                ) : (
                  <Col className="pageHeader">
                    <Col className="d-flex flex-row vessel-title">
                      <p className="vessel-name">Vessel Entry Registration</p>
                    </Col>
                  </Col>
                )
              }
            </Media>

            <Col md="10" xs="12">
              {viewMode === "editMode" ? (
                <React.Fragment>
                  {this.state.showErr ? (
                    <Alert
                      color="danger"
                      className="err-div w100per sticky-err"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      <span className="fs12">
                        Field(s) with <b>red border</b> is/are required.
                      </span>
                    </Alert>
                  ) : (
                    ""
                  )}

                  <Media queries={constants.media_queries}>
                    {(matches) =>
                      matches.xs ? (
                        <Row className="total-crew">
                          <div>
                            <p>total no. of crew</p>
                            <p>{_CREW_TOTAL}</p>
                          </div>
                          <div>
                            {this.crew_type === "predeparture" ? (
                              <button
                                onClick={() => this.handlePreArrivalCrew()}
                              >
                                Get Pre-Arrival Crew
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </Row>
                      ) : (
                        ""
                      )
                    }
                  </Media>

                  <Row className="form-container-box bt-green crew-content">
                    <Form>
                      <div className="fcb-title p-rel">
                        Crew Details
                        <div className="embark">
                          <div
                            className="d-flex flex-row"
                            style={{ fontSize: "14px", marginTop: "0.90em" }}
                          >
                            <p className="">Total No. of Crew:&nbsp;</p>
                            <span
                              className="fw600"
                              style={{ paddingTop: "2px" }}
                            >
                              {_CREW_TOTAL}
                            </span>
                          </div>

                          {this.crew_type === "predeparture" ? (
                            <Button
                              className="btn-sm fs13 smol-btn"
                              color="primary"
                              style={{ margin: "6px -12px 0 15px" }}
                              onClick={() => this.handlePreArrivalCrew()}
                            >
                              Get Pre-Arrival Crew
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <Media queries={constants.media_queries}>
                        {(matches) =>
                          !matches.xs ? (
                            <div className="col-md-12 p-0 div-with-scroll-x">
                              <Table
                                className={
                                  "custom-table long-table crew-table " +
                                  (session.get().role != "shipping_agent"
                                    ? "pen"
                                    : "")
                                }
                                bordered
                              >
                                <thead>
                                  <tr>
                                    <th>No.</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>Rank or Rating</th>
                                    <th width="11%">Nationality</th>
                                    <th>Date of Birth</th>
                                    <th>Place of Birth</th>
                                    <th>Age</th>
                                    <th width="7%">Sex</th>
                                    <th>Date of Embark</th>
                                    <th>Place of Embark</th>
                                    <th>Passport No.</th>
                                    <th>Expiry Date</th>
                                    <th>Seaman's Book No.</th>
                                    <th>Expiry Date</th>
                                    <th></th>
                                    <th
                                      width="10%"
                                      className="invisible-th bgc-none"
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {__crew_list.map((crew, i) => {
                                    return __crew_list.length < 1 ? (
                                      <tr>
                                        <td style={{ textAlign: "center" }}>
                                          No Data
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr
                                        key={i}
                                        className={
                                          __crew_list[i].hasErr
                                            ? "required-row"
                                            : ""
                                        }
                                      >
                                        <td>{i + 1}</td>
                                        <td>
                                          <Input
                                            className={
                                              crew.err_list.last_name
                                                ? "form-control-sm req-td"
                                                : "form-control-sm"
                                            }
                                            type="text"
                                            name="last_name"
                                            errmodel="last_name"
                                            placeholder=""
                                            value={crew.last_name}
                                            onChange={(e) => {
                                              let _NAME = e.target.name;
                                              let _VALUE = e.target.value;
                                              let _INDEX = i;

                                              this.handleChange(
                                                _NAME,
                                                _VALUE,
                                                _INDEX
                                              );
                                            }}
                                            onFocus={(e) => {
                                              let _NAME = e.target.name;
                                              let _VALUE = e.target.value;
                                              let _INDEX = i;

                                              this.handleChange(
                                                _NAME,
                                                _VALUE,
                                                _INDEX
                                              );
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            className={
                                              crew.err_list.first_name
                                                ? "form-control-sm req-td"
                                                : "form-control-sm"
                                            }
                                            type="text"
                                            name="first_name"
                                            id=""
                                            placeholder=""
                                            value={crew.first_name}
                                            onChange={(e) => {
                                              let _NAME = e.target.name;
                                              let _VALUE = e.target.value;
                                              let _INDEX = i;

                                              this.handleChange(
                                                _NAME,
                                                _VALUE,
                                                _INDEX
                                              );
                                            }}
                                            onFocus={(e) => {
                                              let _NAME = e.target.name;
                                              let _VALUE = e.target.value;
                                              let _INDEX = i;

                                              this.handleChange(
                                                _NAME,
                                                _VALUE,
                                                _INDEX
                                              );
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="freeboard"
                                            id=""
                                            placeholder=""
                                            value={crew.rank}
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              __crew_list[i].rank =
                                                e.target.value;
                                              this.setState({ __crew_list });
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <DropdownList
                                            filter
                                            value={crew.nationality}
                                            textField="name"
                                            data={_countries}
                                            onChange={(crew_nationality) => {
                                              let { __crew_list } = this.state;
                                              __crew_list[
                                                i
                                              ].nationality = crew_nationality;
                                              this.setState({ __crew_list });
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <Datetime
                                            className={
                                              crew.err_list.date_of_birth
                                                ? "req-td"
                                                : ""
                                            }
                                            dateFormat={constants.__dateFormat}
                                            timeFormat={false}
                                            value={
                                              crew.date_of_birth
                                                ? new Date(crew.date_of_birth)
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              __crew_list[i].date_of_birth = e;

                                              let newDt = moment(),
                                                bday = moment(e);

                                              if (bday.isValid()) {
                                                __crew_list[i].age = newDt.diff(
                                                  bday,
                                                  "year"
                                                );
                                                __crew_list[
                                                  i
                                                ].err_list.date_of_birth = false;
                                              } else {
                                                __crew_list[i].age = "";
                                                __crew_list[
                                                  i
                                                ].err_list.date_of_birth = true;
                                              }

                                              this.setState({ __crew_list });
                                            }}
                                            inputProps={{ disabled: viewOnly }}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="freeboard"
                                            id=""
                                            placeholder=""
                                            value={crew.place_of_birth}
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              __crew_list[i].place_of_birth =
                                                e.target.value;
                                              this.setState({ __crew_list });
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            className="form-control-sm"
                                            type="number"
                                            name="freeboard"
                                            id=""
                                            placeholder=""
                                            value={crew.age}
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              __crew_list[i].age =
                                                e.target.value;
                                              this.setState({ __crew_list });
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <DropdownList
                                            filter
                                            value={crew.sex}
                                            textField="name"
                                            data={_gender}
                                            onChange={(crew_sex) => {
                                              let { __crew_list } = this.state;
                                              // let crew_nationality = crew.nationality;
                                              __crew_list[i].sex = crew_sex;
                                              this.setState({ __crew_list });
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <Datetime
                                            className={
                                              crew.err_list.date_of_embark
                                                ? "req-td"
                                                : ""
                                            }
                                            dateFormat={constants.__dateFormat}
                                            timeFormat={false}
                                            disabled={true}
                                            value={
                                              crew.date_of_embark
                                                ? new Date(crew.date_of_embark)
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              if (e) {
                                                __crew_list[
                                                  i
                                                ].date_of_embark = e;
                                                __crew_list[
                                                  i
                                                ].err_list.date_of_embark = false;
                                              } else {
                                                __crew_list[i].date_of_embark =
                                                  "";
                                                __crew_list[
                                                  i
                                                ].err_list.date_of_embark = true;
                                              }
                                              this.setState({ __crew_list });
                                            }}
                                            inputProps={{ disabled: viewOnly }}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="freeboard"
                                            id=""
                                            placeholder=""
                                            value={crew.place_of_embark}
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              __crew_list[i].place_of_embark =
                                                e.target.value;
                                              this.setState({ __crew_list });
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="freeboard"
                                            id=""
                                            placeholder=""
                                            value={crew.passport_no}
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              __crew_list[i].passport_no =
                                                e.target.value;
                                              this.setState({ __crew_list });
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <Datetime
                                            className={
                                              crew.err_list.passport_expiry_date
                                                ? "req-td"
                                                : ""
                                            }
                                            dateFormat={constants.__dateFormat}
                                            timeFormat={false}
                                            value={
                                              crew.passport_expiry_date
                                                ? new Date(
                                                    crew.passport_expiry_date
                                                  )
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              if (e) {
                                                __crew_list[
                                                  i
                                                ].passport_expiry_date = e;
                                                __crew_list[
                                                  i
                                                ].err_list.passport_expiry_date = false;
                                              } else {
                                                __crew_list[
                                                  i
                                                ].passport_expiry_date = "";
                                                __crew_list[
                                                  i
                                                ].err_list.passport_expiry_date = true;
                                              }
                                              this.setState({ __crew_list });
                                            }}
                                            inputProps={{ disabled: viewOnly }}
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            className="form-control-sm mt-1"
                                            type="text"
                                            name="freeboard"
                                            id=""
                                            placeholder=""
                                            value={crew.sbn}
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;
                                              __crew_list[i].sbn =
                                                e.target.value;
                                              this.setState({ __crew_list });
                                            }}
                                            disabled={viewOnly}
                                          />
                                        </td>
                                        <td>
                                          <Datetime
                                            className={
                                              crew.err_list.sbn_expiry_date
                                                ? "req-td"
                                                : ""
                                            }
                                            dateFormat={constants.__dateFormat}
                                            timeFormat={false}
                                            value={
                                              crew.sbn_expiry_date
                                                ? new Date(crew.sbn_expiry_date)
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let { __crew_list } = this.state;

                                              if (e) {
                                                __crew_list[
                                                  i
                                                ].sbn_expiry_date = e;
                                                __crew_list[
                                                  i
                                                ].err_list.sbn_expiry_date = false;
                                              } else {
                                                __crew_list[i].sbn_expiry_date =
                                                  "";
                                                __crew_list[
                                                  i
                                                ].err_list.sbn_expiry_date = true;
                                              }

                                              this.setState({ __crew_list });
                                            }}
                                            inputProps={{ disabled: viewOnly }}
                                          />
                                        </td>
                                        <td>
                                          {__crew_list.length === 1 &&
                                          _CREW_TOTAL === 0 ? (
                                            ""
                                          ) : (
                                            <div
                                              style={{ marginRight: "0.5em" }}
                                              className="tblActions tblRemove"
                                              onClick={() => {
                                                this.rmvCrew(
                                                  i,
                                                  crew.last_name,
                                                  crew.first_name
                                                );
                                              }}
                                              hidden={viewOnly}
                                            >
                                              <Icon name="trash" />
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>

                              {session.get().role === "shipping_agent" ? (
                                <div
                                  className="tblActions tblAdd ml-2"
                                  onClick={() => {
                                    this.addCrew();
                                  }}
                                  hidden={viewOnly}
                                >
                                  <Icon name="plus" />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <>
                              <SwipeableList>
                                {__crew_list.map((crew, i) => {
                                  const swipeableDots = [0, 1, 2];
                                  return (
                                    <SwipeableListItem
                                      blockSwipe={editCrewItem === i}
                                      swipeRight={{
                                        content: (
                                          <div>
                                            <div>
                                              <Icon name="edit" />
                                            </div>
                                            <div>Edit</div>
                                          </div>
                                        ),
                                        action: () => {
                                          this.setState({
                                            editCrewItem: i,
                                          });
                                          this.handleIndex(0);
                                        },
                                      }}
                                      swipeLeft={{
                                        content: (
                                          <div>
                                            <div>
                                              <Icon name="trash" />
                                            </div>
                                            <div>Delete</div>
                                          </div>
                                        ),
                                        action: () => {
                                          if (
                                            __crew_list.length === 1 &&
                                            _CREW_TOTAL === 0
                                          ) {
                                            Swal.fire({
                                              icon: "error",
                                              title: "Can't do that right now.",
                                              showConfirmButton: false,
                                              timer: 3000,
                                            });
                                          } else {
                                            this.rmvCrew(
                                              i,
                                              crew.last_name,
                                              crew.first_name
                                            );
                                          }
                                        },
                                      }}
                                    >
                                      <div
                                        className={
                                          editCrewItem === i
                                            ? "crew-item edit-item"
                                            : "crew-item"
                                        }
                                      >
                                        <div className="list-view">
                                          <div>
                                            <p>{i + 1}</p>
                                            {crew.last_name.trim().length ===
                                              0 ||
                                            crew.first_name.trim().length ===
                                              0 ||
                                            !moment(
                                              crew.date_of_birth
                                            ).isValid() ||
                                            !moment(
                                              crew.date_of_embark
                                            ).isValid() ||
                                            !moment(
                                              crew.passport_expiry_date
                                            ).isValid() ||
                                            !moment(
                                              crew.sbn_expiry_date
                                            ).isValid() ? (
                                              <p className="error-m">!</p>
                                            ) : undefined}
                                          </div>
                                          <div>
                                            <p>
                                              {crew.last_name &&
                                              crew.first_name &&
                                              crew.rank ? (
                                                crew.last_name +
                                                ", " +
                                                crew.first_name
                                              ) : (
                                                <span>New Crew</span>
                                              )}
                                            </p>
                                            <p>
                                              {crew.last_name &&
                                              crew.first_name &&
                                              crew.rank ? (
                                                crew.rank
                                              ) : (
                                                <span>
                                                  Swipe Right to Input Crew Data
                                                </span>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="edit-view">
                                          <SwipeableViews
                                            // animateHeight
                                            resistance
                                            index={swipe_index}
                                            onChangeIndex={
                                              this.handleChangeIndex
                                            }
                                            class={
                                              swipe_index === 0
                                                ? "swipeable-editCrew index0"
                                                : swipe_index === 2
                                                ? "swipeable-editCrew indexLast"
                                                : "swipeable-editCrew"
                                            }
                                          >
                                            <Row xs="1">
                                              <Col>
                                                <label for="last_name">
                                                  Last Name
                                                </label>
                                                <Input
                                                  className={
                                                    crew.err_list.last_name
                                                      ? "form-control-sm req-td"
                                                      : "form-control-sm"
                                                  }
                                                  type="text"
                                                  name="last_name"
                                                  errmodel="last_name"
                                                  placeholder=""
                                                  value={crew.last_name}
                                                  onChange={(e) => {
                                                    let _NAME = e.target.name;
                                                    let _VALUE = e.target.value;
                                                    let _INDEX = i;

                                                    this.handleChange(
                                                      _NAME,
                                                      _VALUE,
                                                      _INDEX
                                                    );
                                                  }}
                                                  onFocus={(e) => {
                                                    let _NAME = e.target.name;
                                                    let _VALUE = e.target.value;
                                                    let _INDEX = i;

                                                    this.handleChange(
                                                      _NAME,
                                                      _VALUE,
                                                      _INDEX
                                                    );
                                                  }}
                                                  disabled={viewOnly}
                                                />
                                              </Col>
                                              <Col>
                                                <label for="first_name">
                                                  First Name
                                                </label>
                                                <Input
                                                  className={
                                                    crew.err_list.first_name
                                                      ? "form-control-sm req-td"
                                                      : "form-control-sm"
                                                  }
                                                  type="text"
                                                  name="first_name"
                                                  id=""
                                                  placeholder=""
                                                  value={crew.first_name}
                                                  onChange={(e) => {
                                                    let _NAME = e.target.name;
                                                    let _VALUE = e.target.value;
                                                    let _INDEX = i;

                                                    this.handleChange(
                                                      _NAME,
                                                      _VALUE,
                                                      _INDEX
                                                    );
                                                  }}
                                                  onFocus={(e) => {
                                                    let _NAME = e.target.name;
                                                    let _VALUE = e.target.value;
                                                    let _INDEX = i;

                                                    this.handleChange(
                                                      _NAME,
                                                      _VALUE,
                                                      _INDEX
                                                    );
                                                  }}
                                                  disabled={viewOnly}
                                                />
                                              </Col>
                                              <Col>
                                                <label for="freeboard">
                                                  Rank
                                                </label>
                                                <Input
                                                  className="form-control-sm"
                                                  type="text"
                                                  name="freeboard"
                                                  id=""
                                                  placeholder=""
                                                  value={crew.rank}
                                                  onChange={(e) => {
                                                    let {
                                                      __crew_list,
                                                    } = this.state;
                                                    __crew_list[i].rank =
                                                      e.target.value;
                                                    this.setState({
                                                      __crew_list,
                                                    });
                                                  }}
                                                  disabled={viewOnly}
                                                />
                                              </Col>
                                              <Col>
                                                <label for="natlity">
                                                  Nationality
                                                </label>
                                                <ModalInput
                                                  caret
                                                  val={crew.nationality}
                                                >
                                                  <DropdownList
                                                    open
                                                    filter
                                                    value={crew.nationality}
                                                    textField="name"
                                                    data={_countries}
                                                    name="natlity"
                                                    onChange={(
                                                      crew_nationality
                                                    ) => {
                                                      let {
                                                        __crew_list,
                                                      } = this.state;
                                                      __crew_list[
                                                        i
                                                      ].nationality = crew_nationality;
                                                      this.setState({
                                                        __crew_list,
                                                      });
                                                    }}
                                                    disabled={viewOnly}
                                                  />
                                                </ModalInput>
                                              </Col>
                                            </Row>
                                            <Row xs="1">
                                              <Col>
                                                <Row>
                                                  <Col xs="7">
                                                    <label for="">
                                                      Date of birth
                                                    </label>
                                                    <ModalInput
                                                      calendar
                                                      val={crew.date_of_birth}
                                                    >
                                                      <Datetime
                                                        open={true}
                                                        className={
                                                          crew.err_list
                                                            .date_of_birth
                                                            ? "req-td"
                                                            : ""
                                                        }
                                                        dateFormat={
                                                          constants.__dateFormat
                                                        }
                                                        timeFormat={false}
                                                        value={
                                                          crew.date_of_birth
                                                            ? new Date(
                                                                crew.date_of_birth
                                                              )
                                                            : ""
                                                        }
                                                        onChange={(e) => {
                                                          let {
                                                            __crew_list,
                                                          } = this.state;
                                                          __crew_list[
                                                            i
                                                          ].date_of_birth = e;

                                                          let newDt = moment(),
                                                            bday = moment(e);

                                                          if (bday.isValid()) {
                                                            __crew_list[
                                                              i
                                                            ].age = newDt.diff(
                                                              bday,
                                                              "year"
                                                            );
                                                            __crew_list[
                                                              i
                                                            ].err_list.date_of_birth = false;
                                                          } else {
                                                            __crew_list[i].age =
                                                              "";
                                                            __crew_list[
                                                              i
                                                            ].err_list.date_of_birth = true;
                                                          }

                                                          this.setState({
                                                            __crew_list,
                                                          });
                                                        }}
                                                        inputProps={{
                                                          disabled: viewOnly,
                                                        }}
                                                      />
                                                    </ModalInput>
                                                  </Col>
                                                  <Col xs="5">
                                                    <label for="">Age</label>
                                                    <Input
                                                      className="form-control-sm"
                                                      type="number"
                                                      name="freeboard"
                                                      id=""
                                                      placeholder=""
                                                      value={crew.age}
                                                      onChange={(e) => {
                                                        let {
                                                          __crew_list,
                                                        } = this.state;
                                                        __crew_list[i].age =
                                                          e.target.value;
                                                        this.setState({
                                                          __crew_list,
                                                        });
                                                      }}
                                                      disabled={viewOnly}
                                                    />
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col>
                                                <Row>
                                                  <Col xs="7">
                                                    <label for="">
                                                      Place of birth
                                                    </label>
                                                    <Input
                                                      className="form-control-sm"
                                                      type="text"
                                                      name="freeboard"
                                                      id=""
                                                      placeholder=""
                                                      value={
                                                        crew.place_of_birth
                                                      }
                                                      onChange={(e) => {
                                                        let {
                                                          __crew_list,
                                                        } = this.state;
                                                        __crew_list[
                                                          i
                                                        ].place_of_birth =
                                                          e.target.value;
                                                        this.setState({
                                                          __crew_list,
                                                        });
                                                      }}
                                                      disabled={viewOnly}
                                                    />
                                                  </Col>
                                                  <Col xs="5">
                                                    <label for="">Sex</label>
                                                    <ModalInput
                                                      caret
                                                      val={crew.sex}
                                                    >
                                                      <DropdownList
                                                        open
                                                        filter
                                                        value={crew.sex}
                                                        textField="name"
                                                        data={_gender}
                                                        onChange={(
                                                          crew_sex
                                                        ) => {
                                                          let {
                                                            __crew_list,
                                                          } = this.state;
                                                          // let crew_nationality = crew.nationality;
                                                          __crew_list[
                                                            i
                                                          ].sex = crew_sex;
                                                          this.setState({
                                                            __crew_list,
                                                          });
                                                        }}
                                                        disabled={viewOnly}
                                                      />
                                                    </ModalInput>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col>
                                                <label for="">
                                                  Date of Embark
                                                </label>
                                                <ModalInput
                                                  calendar
                                                  val={crew.date_of_embark}
                                                >
                                                  <Datetime
                                                    open={true}
                                                    className={
                                                      crew.err_list
                                                        .date_of_embark
                                                        ? "req-td"
                                                        : ""
                                                    }
                                                    dateFormat={
                                                      constants.__dateFormat
                                                    }
                                                    timeFormat={false}
                                                    disabled={true}
                                                    value={
                                                      crew.date_of_embark
                                                        ? new Date(
                                                            crew.date_of_embark
                                                          )
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      let {
                                                        __crew_list,
                                                      } = this.state;
                                                      if (e) {
                                                        __crew_list[
                                                          i
                                                        ].date_of_embark = e;
                                                        __crew_list[
                                                          i
                                                        ].err_list.date_of_embark = false;
                                                      } else {
                                                        __crew_list[
                                                          i
                                                        ].date_of_embark = "";
                                                        __crew_list[
                                                          i
                                                        ].err_list.date_of_embark = true;
                                                      }
                                                      this.setState({
                                                        __crew_list,
                                                      });
                                                    }}
                                                    inputProps={{
                                                      disabled: viewOnly,
                                                    }}
                                                  />
                                                </ModalInput>
                                              </Col>
                                              <Col>
                                                <label for="">
                                                  Place of Embark
                                                </label>
                                                <Input
                                                  className="form-control-sm"
                                                  type="text"
                                                  name="freeboard"
                                                  id=""
                                                  placeholder=""
                                                  value={crew.place_of_embark}
                                                  onChange={(e) => {
                                                    let {
                                                      __crew_list,
                                                    } = this.state;
                                                    __crew_list[
                                                      i
                                                    ].place_of_embark =
                                                      e.target.value;
                                                    this.setState({
                                                      __crew_list,
                                                    });
                                                  }}
                                                  disabled={viewOnly}
                                                />
                                              </Col>
                                            </Row>
                                            <Row xs="1">
                                              <Col>
                                                <label for="">
                                                  Passport No.
                                                </label>
                                                <Input
                                                  className="form-control-sm"
                                                  type="text"
                                                  name="freeboard"
                                                  id=""
                                                  placeholder=""
                                                  value={crew.passport_no}
                                                  onChange={(e) => {
                                                    let {
                                                      __crew_list,
                                                    } = this.state;
                                                    __crew_list[i].passport_no =
                                                      e.target.value;
                                                    this.setState({
                                                      __crew_list,
                                                    });
                                                  }}
                                                  disabled={viewOnly}
                                                />
                                              </Col>
                                              <Col>
                                                <label for="">
                                                  Expiry Date
                                                </label>
                                                <ModalInput
                                                  calendar
                                                  val={
                                                    crew.passport_expiry_date
                                                  }
                                                >
                                                  <Datetime
                                                    open={true}
                                                    className={
                                                      crew.err_list
                                                        .passport_expiry_date
                                                        ? "req-td"
                                                        : ""
                                                    }
                                                    dateFormat={
                                                      constants.__dateFormat
                                                    }
                                                    timeFormat={false}
                                                    value={
                                                      crew.passport_expiry_date
                                                        ? new Date(
                                                            crew.passport_expiry_date
                                                          )
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      let {
                                                        __crew_list,
                                                      } = this.state;
                                                      if (e) {
                                                        __crew_list[
                                                          i
                                                        ].passport_expiry_date = e;
                                                        __crew_list[
                                                          i
                                                        ].err_list.passport_expiry_date = false;
                                                      } else {
                                                        __crew_list[
                                                          i
                                                        ].passport_expiry_date =
                                                          "";
                                                        __crew_list[
                                                          i
                                                        ].err_list.passport_expiry_date = true;
                                                      }
                                                      this.setState({
                                                        __crew_list,
                                                      });
                                                    }}
                                                    inputProps={{
                                                      disabled: viewOnly,
                                                    }}
                                                  />
                                                </ModalInput>
                                              </Col>
                                              <Col>
                                                <label for="">
                                                  Seaman's Book No.
                                                </label>
                                                <Input
                                                  className="form-control-sm"
                                                  type="text"
                                                  name="freeboard"
                                                  id=""
                                                  placeholder=""
                                                  value={crew.sbn}
                                                  onChange={(e) => {
                                                    let {
                                                      __crew_list,
                                                    } = this.state;
                                                    __crew_list[i].sbn =
                                                      e.target.value;
                                                    this.setState({
                                                      __crew_list,
                                                    });
                                                  }}
                                                  disabled={viewOnly}
                                                />
                                              </Col>
                                              <Col>
                                                <label for="">
                                                  Expiry Date
                                                </label>
                                                <ModalInput
                                                  calendar
                                                  val={crew.sbn_expiry_date}
                                                >
                                                  <Datetime
                                                    open={true}
                                                    className={
                                                      crew.err_list
                                                        .sbn_expiry_date
                                                        ? "req-td"
                                                        : ""
                                                    }
                                                    dateFormat={
                                                      constants.__dateFormat
                                                    }
                                                    timeFormat={false}
                                                    value={
                                                      crew.sbn_expiry_date
                                                        ? new Date(
                                                            crew.sbn_expiry_date
                                                          )
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      let {
                                                        __crew_list,
                                                      } = this.state;

                                                      if (e) {
                                                        __crew_list[
                                                          i
                                                        ].sbn_expiry_date = e;
                                                        __crew_list[
                                                          i
                                                        ].err_list.sbn_expiry_date = false;
                                                      } else {
                                                        __crew_list[
                                                          i
                                                        ].sbn_expiry_date = "";
                                                        __crew_list[
                                                          i
                                                        ].err_list.sbn_expiry_date = true;
                                                      }

                                                      this.setState({
                                                        __crew_list,
                                                      });
                                                    }}
                                                    inputProps={{
                                                      disabled: viewOnly,
                                                    }}
                                                  />
                                                </ModalInput>
                                              </Col>
                                            </Row>
                                          </SwipeableViews>
                                          <div className="swipeableDots">
                                            {swipeableDots.map((dot, i) => {
                                              return (
                                                <div
                                                  className={
                                                    swipe_index === i
                                                      ? "swipeable-dot selected-dot"
                                                      : "swipeable-dot"
                                                  }
                                                ></div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </SwipeableListItem>
                                  );
                                })}
                              </SwipeableList>
                              <div
                                class="add-crew-xs"
                                onClick={() => {
                                  this.addCrew();
                                }}
                                hidden={viewOnly}
                              >
                                <div>
                                  <Icon name="plus" /> ADD CREW MEMBER
                                </div>
                              </div>
                            </>
                          )
                        }
                      </Media>
                    </Form>
                  </Row>

                  <Row className="form-container-box bt-green auditTrails">
                    <div className="col-md-12 d-flex flex-row auditHead">
                      {showAudit ? (
                        <Icon
                          className=""
                          name="angle-up"
                          onClick={this.handleAudit}
                        />
                      ) : (
                        <Icon
                          className=""
                          name="angle-down"
                          onClick={this.handleAudit}
                          hidden={viewOnly}
                        />
                      )}

                      <p>Audit Trails</p>
                    </div>

                    <div>
                      {showAudit ? (
                        <Col md={{ size: 12 }} className="p-0">
                          <Table
                            className="custom-table"
                            bordered
                            style={{ marginTop: "20px" }}
                          >
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Details</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </Row>

                  {session.get().role === "shipping_agent" ? (
                    <div className="footer-actions">
                      <div className="col-md-6 p-0">
                        {_CREW_TOTAL !== 0 ? (
                          ""
                        ) : (
                          <Button
                            onClick={() => this.handleDoLater()}
                            className="btn-sm fs13"
                            color="aesi-orange"
                            style={{ margin: "0 0.5em" }}
                            hidden={viewOnly}
                          >
                            <Media queries={constants.media_queries}>
                              {(matches) =>
                                matches.xs ? (
                                  <Icon name="step-forward" />
                                ) : (
                                  "DO LATER"
                                )
                              }
                            </Media>
                          </Button>
                        )}
                      </div>
                      <div className="col-md-6 p-0 d-flex flex-row justify-content-end">
                        <Button
                          onClick={() => this.handleSave()}
                          className={
                            this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"
                          }
                          color="success"
                          style={{ margin: "0 0.5em" }}
                          hidden={viewOnly}
                        >
                          <Media queries={constants.media_queries}>
                            {(matches) =>
                              matches.xs ? <Icon name="save" /> : "SAVE"
                            }
                          </Media>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                <DocView visit={vd} />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(CrewList);
