import React, { Component } from "react";

import "./Maintenance.scss";
import Navbar from "../Navbar/Navbar";
import shipWhite from "../../img/svg/ship.svg";
import tugBoat from "../../img/svg/boat.svg";
import pier from "../../img/svg/pier.svg";
import cargoHandler from "../../img/svg/cargo-handler.svg";
import Can from "../Can/Can";
import session from "../../session";

export default class Maintenance extends Component {
  render() {
    return (
      <div className="Maintenance">
        <div className="d-flex flex-column">
          <Navbar />

          <div className="col-md-12 d-flex flex-row justify-content-center">
            <div className="col-md-3" style={{ padding: "0 1em" }}>
              <div
                className="menu-con w100per d-flex flex-column form-container-box justify-content-center align-items-center"
                style={{ backgroundColor: "rgb(76, 175, 80)" }}
              >
                <div class="d-flex flex-row justify-content-center align-items-center icon-con">
                  <img src={shipWhite} alt="ship-white" width="50" />
                </div>
                <div className="list-container w100per">
                  <div
                    class="list-item"
                    onClick={() =>
                      this.props.history.push("/maintenance/vessels")
                    }
                  >
                    VESSELS
                  </div>
                </div>
              </div>
            </div>
            <Can
              role={session.get().role}
              perform="settings:tugboat-companies"
              yes={() => (
                <div className="col-md-3" style={{ padding: "0 1em" }}>
                  <div
                    className="menu-con w100per d-flex flex-column form-container-box justify-content-center align-items-center"
                    style={{ backgroundColor: "#FF9800" }}
                  >
                    <div class="d-flex flex-row justify-content-center align-items-center icon-con">
                      <img src={tugBoat} alt="ship-white" width="45" />
                    </div>
                    <div className="list-container w100per">
                      <div
                        class="list-item"
                        onClick={() =>
                          this.props.history.push(
                            "/maintenance/tugboat_companies"
                          )
                        }
                      >
                        TUGBOAT COMPANIES
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>

          <div className="col-md-12 d-flex flex-row justify-content-center">
            <Can
              role={session.get().role}
              perform="settings:berth"
              yes={() => (
                <div className="col-md-3" style={{ padding: "0 1em" }}>
                  <div
                    className="menu-con w100per d-flex flex-column form-container-box justify-content-center align-items-center"
                    style={{ backgroundColor: "#2196F3" }}
                  >
                    <div class="d-flex flex-row justify-content-center align-items-center icon-con">
                      <img src={pier} alt="ship-white" width="45" />
                    </div>
                    <div className="list-container w100per">
                      <div
                        className="list-item"
                        onClick={() =>
                          this.props.history.push(
                            "/maintenance/tugboat_companies"
                          )
                        }
                      >
                        BERTH
                      </div>
                      <div
                        className="list-item-smol"
                        onClick={() =>
                          this.props.history.push("/maintenance/berth")
                        }
                      >
                        Pier
                      </div>
                      <div
                        className="list-item-smol"
                        onClick={() =>
                          this.props.history.push("/maintenance/anchorage")
                        }
                      >
                        Anchorage
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />

            <Can
              role={session.get().role}
              perform="settings:cargo-handlers"
              yes={() => (
                <div className="col-md-3" style={{ padding: "0 1em" }}>
                  <div
                    className="menu-con w100per d-flex flex-column form-container-box justify-content-center align-items-center"
                    style={{ backgroundColor: "#673AB7" }}
                  >
                    <div class="d-flex flex-row justify-content-center align-items-center icon-con">
                      <img src={cargoHandler} alt="ship-white" width="45" />
                    </div>
                    <div className="list-container w100per">
                      <div
                        className="list-item"
                        onClick={() =>
                          this.props.history.push("/maintenance/cargo_handlers")
                        }
                      >
                        CARGO HANDLERS
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}
