import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import "./InboxCreate.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import api from "../../helpers/api.helper";
import { DropdownList } from "react-widgets";
import constants from "../../constants";
import str_helper from "../../helpers/string.helper";
import AlertModal from "../Tools/AlertModal/AlertModal";
import session from "../../session";
import Cookies from "js-cookie";
import Media from "react-media";
import PageHeader from "../Tools/PageHeader/PageHeader";
import Icon from "react-fa";

class InboxCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __port_call: {},
      _CATEGORIES: constants.__note_categories,
      _INBOX: {},
      isAlert: "",
      alertMsg: "",
      tglAlert: false
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call"
        })
        .then(data => {});
    }
  };

  handleChange = (n, v) => {
    let { _INBOX } = this.state;

    _INBOX[n] = v;

    this.setState({
      _INBOX
    });

    // console.log("INBAKS", _INBOX);
  };

  handleSave = () => {
    let { _INBOX } = this.state;
    this.showAlert("loading", "", true);

    let user = localStorage.getItem("port_session");
    if (user) {
      user = JSON.parse(user);
    }

    // console.log("Check", JSON.stringify(this.state._INBOX), this.state._INBOX);

    api
      .save(this, {
        endpoint: "inbox/" + this.state.__ID,
        _data: {
          ..._INBOX,
          user: user.first_name + " " + user.last_name,
          seen_by: [
            {
              role: session.get().role,
              unread_count: 0
            }
          ],
          vessel_name: this.state.__port_call.vessel_name,
          voyage_number: this.state.__port_call.voyage_number,
          position: str_helper.human_readable(user.role),
          author: Cookies.get("uid")
        }
      })
      .then(_data => {
        this.setState(
          {
            tglAlert: false
          },
          () => {
            this.showAlert("success", "Note Added!", true);
          }
        );
      });

    this.props.history.push("/port_call/inbox/" + this.state.__ID);
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool
    });

    // console.log("ALERT", bool);
  };

  render() {
    const vd = this.state.__port_call;
    const _in = this.state._INBOX;
    const { tglAlert, isAlert, alertMsg, alertSuccess } = this.state;

    return (
      <div className="InboxCreate">
        {tglAlert ? (
          <AlertModal
            sendTrigger={isAlert}
            sendMsg={alertMsg}
            successMsg={alertSuccess}
          />
        ) : (
          ""
        )}
        <div className="d-flex flex-column">
          <Navbar />
          <PortCallToolbar />
          <Media queries={constants.media_queries}>
            {matches =>
              matches.xs ? (
                <Sidenav
                  dataFromParent={this.props.match.params.id}
                  visit={vd}
                />
              ) : (
                <PageHeader
                  hideDocToggle
                  sendVName={vd.vessel_name}
                  sendVoyageNo={vd.voyage_number}
                />
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {matches =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      visit={vd}
                    />
                  </Col>
                ) : (
                  <PageHeader
                    hideDocToggle
                    sendVName={vd.vessel_name}
                    sendVoyageNo={vd.voyage_number}
                    pagename="Add Note"
                  />
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              <div className="form-container-box bt-green">
                <Form>
                  <Col xs="12" className="fcb-title">
                    <p>Create Note</p>
                  </Col>

                  <Row>
                    <Row xs="1" md="2">
                      <Col>
                        <FormGroup>
                          <Label for="">Subject</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="subject"
                            value={_in.subject}
                            onChange={e => {
                              let name = e.target.name;
                              let value = e.target.value;

                              this.handleChange(name, value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="exampleSelect">Category</Label>
                          <DropdownList
                            filter
                            value={_in.category}
                            textField="name"
                            name="category"
                            data={this.state._CATEGORIES}
                            onChange={category => {
                              let name = "category";
                              let value = category;

                              this.handleChange(name, value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="">Note</Label>
                          <Input
                            className="form-control-sm"
                            type="textarea"
                            rows="10"
                            name="message"
                            style={{ resize: "none" }}
                            value={_in.message}
                            onChange={e => {
                              let name = e.target.name;
                              let value = e.target.value;

                              this.handleChange(name, value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>

        <div className="col-md-12 footer-actions d-flex flex-row">
          <div className="col-md-12 d-flex flex-row justify-content-end p-0">
            <Button
              className="btn-sm"
              color="danger"
              style={{ margin: "0 0.5em" }}
              onClick={() =>
                this.props.history.push("/port_call/inbox/" + this.state.__ID)
              }
            >
              CANCEL
            </Button>
            <Button
              className="btn-sm"
              color="success"
              style={{ margin: "0 0.5em" }}
              onClick={() => this.handleSave()}
            >
              <Media queries={constants.media_queries}>
                {matches => (matches.xs ? <Icon name="plus" /> : "SEND")}
              </Media>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InboxCreate);
