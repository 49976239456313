import React, { Component } from "react";
import { Col, Table, Form, FormGroup, Input, Label } from "reactstrap";
import Sticky from "react-sticky-el";

import "./Vessels.scss";
import Navbar from "../Navbar/Navbar";
import api from "../../helpers/api.helper";

export default class Vessels extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __vessels: [],
      __selectedVessel: [],
      __vesselImages: []
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    api.get(this, { endpoint: "vessel", state_key: "__vessels" }).then(data => {
      // let img = this.state.__vesselImages;
      // console.log("DATA", data);
      // this.setState({
      //   img: data.images
      // });
      // console.log("IMG >>>", img);
    });
  };

  showVesselDetail = id => {
    let vessels = this.state.__vessels;
    let _vessel_select = [];

    for (let x in vessels) {
      let vessel = vessels[x];

      if (vessel._id === id) {
        _vessel_select = vessel;

        let img = [];

        this.setState({
          __selectedVessel: _vessel_select,
          img: _vessel_select.images
        });

        console.log("Test >>>>", img.len);
      }
    }
  };

  render() {
    const { __vessels } = this.state;
    const selectedVessel = this.state.__selectedVessel;

    return (
      <div className="EventSchedulerView">
        <div className="d-flex flex-column">
          <Navbar />

          <Form
            className="main-content d-flex flex-row mt-3 col-md-12"
            style={{ marginBottom: "2em", alignItems: "inherit" }}
          >
            <Col md={{ size: 3 }} className="d-flex flex-column">
              <Sticky className="d-flex flex-column" topOffset={80}>
                <div
                  className="form-container-box bt-green"
                  style={{ padding: "10px 20px" }}
                >
                  <FormGroup className="col-md-12 p-0">
                    <Label for="exampleSelect">Select Vessel</Label>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      className="form-control-sm"
                      value={this.state.__vessels.name}
                      onChange={e => {
                        let v_id = e.target.value;

                        this.showVesselDetail(v_id);
                      }}
                    >
                      {__vessels.map((v, i) => (
                        <option key={i} value={v._id}>
                          {v.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>

                <div className="form-container-box bt-green mt-3" style={{}}>
                  <div className="fcb-title">Vessel Information</div>

                  {/* <img
                    className="mt-3"
                    src={vessel_placeholder}
                    alt="vessel_placeholder"
                    width="100%"
                  /> */}

                  <img
                    className="mt-3"
                    src={selectedVessel.images}
                    alt="vessel_placeholder"
                    width="100%"
                  />

                  <div className="mt-2">
                    <label htmlFor="">Name</label>
                    <div className="cus-text">{selectedVessel.name || "-"}</div>
                  </div>

                  <div className="mt-2">
                    <label htmlFor="">Description</label>
                    <div className="cus-text">-</div>
                  </div>

                  <div className="mt-2">
                    <label htmlFor="">Type</label>
                    <div className="cus-text">{selectedVessel.type || "-"}</div>
                  </div>
                </div>
              </Sticky>
            </Col>

            <Col md={{ size: 9 }} className="d-flex p-0">
              <div className="form-container-box bt-green col-md-12" style={{}}>
                <div className="fcb-title">Vessel Visit History</div>

                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "20px" }}
                >
                  <thead>
                    <tr>
                      <th>Voyage No.</th>
                      <th>Date of Arrival</th>
                      <th>Date of Departure</th>
                      <th>Cargo</th>
                      <th>B/L Qty</th>
                      <th>Berth</th>
                      <th>Shipping Agent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Form>
        </div>
      </div>
    );
  }
}
