import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";

import api from "../../helpers/api.helper";
import Datetime from "react-datetime";
import constants from "../../constants";
import seaports from "../../../src/sea-ports.json";
import { DropdownList } from "react-widgets";
import moment from "moment";
import PageHeader from "../Tools/PageHeader/PageHeader";
import DocView from "../DocView/DocView";
import AlertModal from "../Tools/AlertModal/AlertModal";
import Media from "react-media";
import Icon from "react-fa";
import "./DepartureDetails.scss";
import makeAmendment from "../../helpers/vesselExitAmendments";
import Swal from "sweetalert2";

class DepartureDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __port_call: {},
      exit_clearance: {},
      seaports: seaports,

      viewMode: "editMode",
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
      is_save_btn: false,
      errorMsg: {
        purposeOfExit: "",
        nextPort: "",
      },
      disableSave: false,
      origData: {},
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call",
        })
        .then((data) => {});

      api
        .get(this, {
          endpoint: "exit_clearance/" + this.state.__ID,
          state_key: "exit_clearance",
        })
        .then((data) => {
          let { origData } = this.state;

          origData = null;

          if (
            parseInt(data.status_id) !== parseInt(constants.PENDING_STATUS) ||
            parseInt(data.status_id) !== parseInt(constants.REJECTED_STATUS)
          ) {
            origData = Object.assign({}, data);
          }

          if (!data.purpose_of_exit && !data.next_port_of_call) {
            this.setState({
              is_save_btn: false,
            });
          } else {
            this.setState({
              is_save_btn: true,
            });
          }

          this.setState({ origData });
        });
    }
  };

  createAmendment = () => {
    // console.log("CREATE AMEND !!!!!!!!");
    let userSession = localStorage.getItem("port_session");
    if (userSession) {
      userSession = JSON.parse(userSession);
    }

    console.log("SFASFDS", this.state.__port_call);
    console.log("EXIT", this.state.exit_clearance);

    if (
      parseInt(this.state.__port_call.status_id) !==
        parseInt(constants.PENDING_STATUS) ||
      parseInt(this.state.__port_call.status_id) !==
        parseInt(constants.REJECTED_STATUS)
    ) {
      let user_id = userSession._id,
        portcall_id = this.state.__ID,
        user_name = userSession.last_name + ", " + userSession.first_name;
      let amendment_data = makeAmendment({
        origData: this.state.origData,
        newData: this.state.exit_clearance,
        user_id,
        amended_by: user_name,
        portcall_id,
      });

      this.setState({
        tglAlert: false,
      });

      Swal.fire({
        icon: "question",
        title:
          "Vessel Exit Application already submitted. <br> You are about to submit an amendment. <br> Are you sure you want to continue?",
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        customClass: "vdetail-confirm-amendment",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          console.log("AMENDDATA", amendment_data);

          Swal.fire({
            icon: "success",
            title: "Amendment Submitted!",
            showConfirmButton: false,
            timer: 1500,
          });

          api.save(this, {
            endpoint: "amendment/" + this.state.__port_call._id,
            _data: amendment_data,
            state_key: "amendments",
          });
        } else {
          this.setState({
            disableSave: false,
          });
        }
      });
    }
  };

  handleSave = () => {
    let { exit_clearance } = this.state;
    if (
      exit_clearance.purpose_of_exit &&
      exit_clearance.next_port_of_call &&
      moment(exit_clearance.date_and_time_of_departure).isValid()
    ) {
      this.showAlert("loading", "", true);
      this.setState({
        disableSave: true,
      });
      if (exit_clearance._id) {
        if (
          parseInt(this.state.__port_call.status_id) ===
            parseInt(constants.PENDING_STATUS) ||
          parseInt(this.state.__port_call.status_id) ===
            parseInt(constants.REJECTED_STATUS)
        ) {
          // update
          api
            .update(this, {
              endpoint: "exit_clearance/" + this.state.__ID,
              _data: exit_clearance,
            })
            .then((data) => {
              this.setState(
                {
                  tglAlert: false,
                  errorMsg: {
                    purposeOfExit: "",
                    nextPort: "",
                  },
                  disableSave: false,
                },
                () =>
                  this.showAlert("success", "Departure Details updated!", true)
              );
            });
        } else {
          this.createAmendment();
        }
      } else {
        api
          .save(this, {
            endpoint: "exit_clearance/" + this.state.__ID,
            _data: exit_clearance,
          })
          .then((data) => {
            this.setState(
              {
                tglAlert: false,
                disableSave: false,
              },
              () =>
                this.showAlert(
                  "success",
                  "Departure Details successfully saved!",
                  true
                )
            );

            this.props.history.push(
              "/port_call/crew_list_pre_departure/" + this.state.__ID
            );
          });
      }
    } else {
      let { errorMsg } = this.state;

      errorMsg.purposeOfExit = !exit_clearance.purpose_of_exit
        ? "This is required"
        : "";
      errorMsg.nextPort = !exit_clearance.next_port_of_call
        ? "This is required"
        : "";

      this.setState({ errorMsg });
    }
  };

  getViewMode = (view_mode) => {
    this.setState({
      viewMode: view_mode,
    });
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  render() {
    const vd = this.state.__port_call;
    const ec = this.state.exit_clearance;
    const VNAME = vd.vessel_name;
    const VOYAGE_NO = vd.voyage_number;
    const {
      viewMode,
      isAlert,
      alertMsg,
      is_save_btn,
      tglAlert,
      errorMsg,
    } = this.state;

    return (
      <div className="departureDetails">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}

        <div className="d-flex flex-column">
          <Navbar />

          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav dataFromParent={this.state.__ID} visit={vd} />
              ) : this.state.__ID ? (
                <PageHeader
                  parentCallback={this.getViewMode}
                  sendVName={VNAME}
                  sendVoyageNo={VOYAGE_NO}
                />
              ) : (
                <Col className="pageHeader">
                  <Col className="d-flex flex-row vessel-title">
                    <p className="vessel-name">Vessel Entry Registration</p>
                  </Col>
                </Col>
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      visit={vd}
                      exit={ec}
                    />
                  </Col>
                ) : this.state.__ID ? (
                  <PageHeader
                    parentCallback={this.getViewMode}
                    sendVName={VNAME}
                    sendVoyageNo={VOYAGE_NO}
                    pagename="Departure Details"
                  />
                ) : (
                  <Col className="pageHeader">
                    <Col className="d-flex flex-row vessel-title">
                      <p className="vessel-name">Vessel Entry Registration</p>
                    </Col>
                  </Col>
                )
              }
            </Media>

            <Col md="10" xs="12">
              {viewMode === "editMode" ? (
                <Row className="form-container-box bt-green">
                  <Form>
                    <div className="col-md-12 p-0 d-flex flex-row position-relative">
                      <div className="fcb-title">Departure Details</div>
                    </div>

                    <Row className="mt-2" md="3" xs="1">
                      <Col>
                        <FormGroup
                          className={
                            errorMsg.purposeOfExit ? "required-field" : ""
                          }
                        >
                          <Label for="exampleText">Purpose of Exit</Label>
                          <Input
                            className="fs13"
                            type="textarea"
                            name="text"
                            id="purpose_of_exit_input"
                            row="2"
                            style={{ resize: "none" }}
                            value={this.state.exit_clearance.purpose_of_exit}
                            onChange={(e) => {
                              let { exit_clearance, errorMsg } = this.state;
                              exit_clearance.purpose_of_exit = e.target.value;

                              errorMsg.purposeOfExit = !exit_clearance.purpose_of_exit
                                ? "This is required"
                                : "";

                              this.setState({ exit_clearance, errorMsg });
                            }}
                          />
                          <div className="error-msg col-md-12">
                            {errorMsg.purposeOfExit}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup
                          className={errorMsg.nextPort ? "required-field" : ""}
                        >
                          <Label for="exampleSelect">
                            Next Port of Destination
                          </Label>
                          <DropdownList
                            filter
                            value={this.state.exit_clearance.next_port_of_call}
                            textField="name"
                            data={this.state.seaports}
                            onChange={(port) => {
                              let { exit_clearance, errorMsg } = this.state;
                              exit_clearance.next_port_of_call = port.name;
                              errorMsg.nextPort = !exit_clearance.next_port_of_call
                                ? "This is required"
                                : "";

                              this.setState({ exit_clearance, errorMsg });
                            }}
                          />

                          <div className="error-msg col-md-12">
                            {errorMsg.nextPort}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="exampleFile">
                            Date & Time of Departure
                          </Label>
                          <Datetime
                            dateFormat={constants.__dateFormat}
                            timeFormat={constants.__timeFormat}
                            value={
                              this.state.exit_clearance
                                .date_and_time_of_departure
                                ? new Date(
                                    this.state.exit_clearance.date_and_time_of_departure
                                  )
                                : ""
                            }
                            onChange={(e) => {
                              let { exit_clearance } = this.state;
                              exit_clearance.date_and_time_of_departure = e;
                              this.setState({ exit_clearance });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Row>
              ) : (
                <DocView visit={vd} />
              )}
            </Col>
          </Row>
        </div>

        <div className="footer-actions d-flex flex-row justify-content-end">
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="btn-sm"
              color="danger"
              style={{ margin: "0 0.5em" }}
            >
              CANCEL
            </Button>
            {is_save_btn ? (
              <Button
                className={this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"}
                color="success"
                onClick={() => this.handleSave()}
              >
                <Media queries={constants.media_queries}>
                  {(matches) => (matches.xs ? <Icon name="save" /> : "SAVE")}
                </Media>
              </Button>
            ) : (
              <Button
                className={this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"}
                color="primary"
                onClick={() => this.handleSave()}
              >
                <Media queries={constants.media_queries}>
                  {(matches) =>
                    matches.xs ? <Icon name="chevron-right" /> : "NEXT"
                  }
                </Media>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DepartureDetails);
