import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Alert,
} from "reactstrap";
import "./VesselEntry.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import { DropdownList } from "react-widgets";
import seaports from "../../../src/sea-ports.json";
import api from "../../helpers/api.helper";
import constants from "../../constants";
import Datetime from "react-datetime";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import PageHeader from "../Tools/PageHeader/PageHeader";
import DocView from "../DocView/DocView";
import AlertModal from "../Tools/AlertModal/AlertModal";
import session from "../../session";
import Toggle from "react-toggle";
import Media from "react-media";
import Icon from "react-fa";
import Swal from "sweetalert2";

class LocalVesselForm extends Component {
  constructor(props) {
    super(props);

    this.vnRef = React.createRef();

    this.state = {
      __ID: this.props.sendID,
      openModal: false,

      __data: {
        mother_vessel: {},
        cargo_details: [
          {
            cargo_type: "",
            item: "",
            amount: 0,
            uom: null,
          },
        ],
        shipping_agency: {
          name: "",
          address: "",
          tel_no: "",
          fax_no: "",
        },
      },
      __vessels: [],
      __vessel_names: [],
      selected_vessel: {},
      selected_port_call_purposes: [],
      seaports: seaports,
      __dateF: constants.__dateFormat,
      __timeF_ss: constants.__timeFormat_ss,
      pending_amendment_eta: null,
      pending_amendment_etd: null,
      pending_amendment_vessel_name: null,
      viewMode: "editMode",
      isAlert: "",
      modalMsg: "",
      tglAler: false,
      is_save_btn: false,
      errList: {
        vesselName: {
          name: "Vessel Name",
          msg: "",
        },
        _eta: {
          name: "ETA",
          msg: "",
        },
        _etd: {
          name: "ETD",
          msg: "",
        },
      },
      hasError: false,
      disableSave: false,
      readOnly: false,
      TUGBOATS: [],
      _TUGBOATS: [{ name: "" }],
      PC_VESSELS: [],
      PCALLS: [],
    };
  }

  toggleModal = (stat) => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
      pc_status: stat,
    }));
  };

  closeModal = () => {
    this.setState({ status_change: {} });
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));

    this.revertStatus();
  };

  // componentWillMount = () => {
  //   localStorage.getItem("save_btn") &&
  //     this.setState({
  //       is_save_btn: localStorage.getItem("save_btn")
  //     });
  // };

  componentDidMount = () => {
    this.getData();
    this.disableFields();
    this.loadTugboats();
    this.loadPCvessels();
  };

  // componentWillUpdate = () => {
  //   localStorage.setItem("save_btn", true);
  // };

  getData = () => {
    api
      .get(this, { endpoint: "vessel", state_key: "__vessels" })
      .then((data) => {
        let vessel_names = [];

        for (let x in data) {
          let vessel = data[x];

          vessel_names[x] = vessel.vessel_name;
        }

        this.setState({
          __vessel_names: vessel_names,
        });
      });

    if (!this.state.__ID) {
    } else {
      api
        .get(this, { endpoint: "portcall/" + this.state.__ID })
        .then((data) => {
          // console.log(data)
          // console.log("AL", data.mother_vessel.vessel_name);

          let original_eta = null,
            original_etd = null,
            selected_port_call_purposes = null,
            pending_amendment_eta = null,
            pending_amendment_etd = null,
            original_vessel_name = null,
            pending_amendment_vessel_name = null,
            original_beam = null,
            pending_amendment_beam = null,
            original_grt = null,
            pending_amendment_grt = null,
            original_nrt = null,
            pending_amendment_nrt = null,
            original_total_number_of_crew = null,
            pending_amendment_total_number_of_crew = null,
            original_shipping_lines = null,
            pending_amendment_shipping_lines = null,
            original_tugboat_company = null,
            pending_amendment_tugboat_company = null,
            original_call_sign = null,
            pending_amendment_call_sign = null,
            original_dwt = null,
            pending_amendment_dwt = null,
            original_loa = null,
            pending_amendment_loa = null,
            original_draft = null,
            pending_amendment_draft = null,
            original_imo = null,
            pending_amendment_imo = null;
          if (data.selected_port_call_purposes) {
            selected_port_call_purposes = data.selected_port_call_purposes;
          }

          if (
            parseInt(data.status_id) !== parseInt(constants.PENDING_STATUS) ||
            parseInt(data.status_id) !== parseInt(constants.REJECTED_STATUS)
          ) {
            original_eta = data.eta;
            original_etd = data.etd;
            original_vessel_name = data.vessel_name;
            original_beam = data.beam;
            original_grt = data.grt;
            original_nrt = data.nrt;
            original_total_number_of_crew = data.total_number_of_crew;
            original_shipping_lines = data.shipping_agency.name;
            original_tugboat_company = data.tugboat_company;
            original_call_sign = data.vessel_callsign;
            original_dwt = data.dwt;
            original_loa = data.loa;
            original_draft = data.draft;
            original_imo = data.imo;
          }

          if (data.pending_amendments && data.pending_amendments.length > 0) {
            for (let x in data.pending_amendments) {
              if (data.pending_amendments[x].eta) {
                pending_amendment_eta = data.pending_amendments[x].eta;
              }
              if (data.pending_amendments[x].etd) {
                pending_amendment_etd = data.pending_amendments[x].etd;
              }
              if (data.pending_amendments[x].vessel_name) {
                pending_amendment_vessel_name =
                  data.pending_amendments[x].vessel_name;
              }
              if (data.pending_amendments[x].beam) {
                pending_amendment_beam = data.pending_amendments[x].beam;
              }
              if (data.pending_amendments[x].grt) {
                pending_amendment_grt = data.pending_amendments[x].grt;
              }
              if (data.pending_amendments[x].nrt) {
                pending_amendment_nrt = data.pending_amendments[x].nrt;
              }
              if (data.pending_amendments[x].total_number_of_crew) {
                pending_amendment_total_number_of_crew =
                  data.pending_amendments[x].total_number_of_crew;
              }
              if (data.pending_amendments[x].shipping_lines) {
                pending_amendment_shipping_lines =
                  data.pending_amendments[x].shipping_lines;
              }
              if (data.pending_amendments[x].tugboat_company) {
                pending_amendment_tugboat_company =
                  data.pending_amendments[x].tugboat_company;
              }
              if (data.pending_amendments[x].call_sign) {
                pending_amendment_call_sign =
                  data.pending_amendments[x].call_sign;
              }
              if (data.pending_amendments[x].dwt) {
                pending_amendment_dwt = data.pending_amendments[x].dwt;
              }
              if (data.pending_amendments[x].loa) {
                pending_amendment_loa = data.pending_amendments[x].loa;
              }
              if (data.pending_amendments[x].draft) {
                pending_amendment_draft = data.pending_amendments[x].draft;
              }
              if (data.pending_amendments[x].imo) {
                pending_amendment_imo = data.pending_amendments[x].imo;
              }
            }
          }

          if (!data.vessel_name && !data) {
            this.setState({
              is_save_btn: false,
            });
          } else {
            this.setState({
              is_save_btn: true,
            });
          }

          // console.log("PCV", data);

          this.setState({
            selected_port_call_purposes,
            original_eta,
            original_etd,
            pending_amendment_etd,
            pending_amendment_eta,
            original_vessel_name,
            pending_amendment_vessel_name,
            original_beam,
            pending_amendment_beam,
            original_grt,
            pending_amendment_grt,
            original_nrt,
            pending_amendment_nrt,
            original_total_number_of_crew,
            pending_amendment_total_number_of_crew,
            original_shipping_lines,
            pending_amendment_shipping_lines,
            original_tugboat_company,
            pending_amendment_tugboat_company,
            original_call_sign,
            pending_amendment_call_sign,
            original_dwt,
            pending_amendment_dwt,
            original_loa,
            pending_amendment_loa,
            original_draft,
            pending_amendment_draft,
            original_imo,
            pending_amendment_imo,
          });
        });
    }
  };

  loadPCvessels = () => {
    api
      .get(this, { endpoint: "portcall", state_key: "PCALLS" })
      .then((data) => {
        let pc_vessels = [];

        for (let x in data) {
          pc_vessels[x] = data[x].vessel_name + " - " + data[x].voyage_number;
        }

        // console.log("PCV", pc_vessels);

        this.setState({ PC_VESSELS: pc_vessels });
      });
  };

  loadTugboats = () => {
    let tugboats_ = [];

    api
      .get(this, { endpoint: "tugboat_company", state_key: "_TUGBOATS" })
      .then((data) => {
        for (let x in data) {
          tugboats_[x] = data[x].name;
        }

        this.setState({
          TUGBOATS: tugboats_,
        });

        // console.log("TUG", tugboats_);
      });
  };

  setSelectedVessel = (vesselInformation) => {
    // console.log("VINFO", vesselInformation);
    if (vesselInformation) {
      let { __data } = this.state;

      __data.grt = vesselInformation.grt ? vesselInformation.grt : 0;
      __data.nrt = vesselInformation.nrt ? vesselInformation.nrt : 0;
      __data.dwt = vesselInformation.dwt ? vesselInformation.dwt : 0;
      __data.loa = vesselInformation.loa ? vesselInformation.loa : 0;
      __data.draft = vesselInformation.draft ? vesselInformation.draft : 0;
      __data.beam = vesselInformation.beam ? vesselInformation.beam : 0;
      __data.vessel_imo = vesselInformation.imo_number
        ? vesselInformation.imo_number
        : 0;
      __data.country = vesselInformation.port_of_registry
        ? vesselInformation.port_of_registry
        : "";
      __data.vessel_owner = vesselInformation.registered_owner
        ? vesselInformation.registered_owner
        : "";
      // __data.master = vesselInformation.master ? vesselInformation.master : "";
      __data.vessel_class = vesselInformation.type
        ? vesselInformation.type
        : "";
      __data.vessel_callsign = vesselInformation.call_sign
        ? vesselInformation.call_sign
        : "";

      this.setState({ __data });
      // console.log("DATA", __data);
    }
  };

  handleRemoveSelectedPurpose = (index) => {
    let { selected_port_call_purposes } = this.state;
    selected_port_call_purposes.splice(index, 1);

    this.setState({ selected_port_call_purposes });
  };

  handleSelectPurpose = (purpose) => {
    if (purpose) {
      let { selected_port_call_purposes } = this.state;
      selected_port_call_purposes = [...selected_port_call_purposes, purpose];

      this.setState({ selected_port_call_purposes });
    }
  };

  handleAddCargoDetails = () => {
    let { __data } = this.state;
    let { cargo_details } = __data;
    cargo_details = [
      ...cargo_details,
      {
        cargo_type: "",
        item: "",
        amount: 0,
        uom: null,
      },
    ];
    __data.cargo_details = cargo_details;
    this.setState({ __data });
  };

  createAmendment = () => {
    let userSession = localStorage.getItem("port_session");
    if (userSession) {
      userSession = JSON.parse(userSession);
    }

    if (
      parseInt(this.state.__data.status_id) !==
        parseInt(constants.PENDING_STATUS) ||
      parseInt(this.state.__data.status_id) !==
        parseInt(constants.REJECTED_STATUS)
    ) {
      let amendment_data = [],
        has_eta_changed =
          this.state.original_eta !== null &&
          this.state.original_eta !== this.state.__data.eta,
        has_etd_changed =
          this.state.original_etd !== null &&
          this.state.original_etd !== this.state.__data.etd,
        has_vessel_name_changed =
          this.state.original_vessel_name !== null &&
          this.state.original_vessel_name !== this.state.__data.vessel_name,
        has_beam_changed =
          this.state.original_beam !== null &&
          this.state.original_beam !== this.state.__data.beam,
        has_grt_changed =
          this.state.original_grt !== null &&
          this.state.original_grt !== this.state.__data.grt,
        has_nrt_changed =
          this.state.original_nrt !== null &&
          this.state.original_nrt !== this.state.__data.nrt,
        has_total_number_of_crew_changed =
          this.state.original_total_number_of_crew !== null &&
          this.state.original_total_number_of_crew !==
            this.state.__data.total_number_of_crew,
        has_shipping_lines_changed =
          this.state.original_shipping_lines !== null &&
          this.state.original_shipping_lines !==
            this.state.__data.shipping_agency.name,
        has_tugboat_company_changed =
          this.state.original_tugboat_company !== null &&
          this.state.original_tugboat_company !==
            this.state.__data.tugboat_company,
        has_call_sign_changed =
          this.state.original_call_sign !== null &&
          this.state.original_call_sign !== this.state.__data.call_sign,
        has_dwt_changed =
          this.state.original_dwt !== null &&
          this.state.original_dwt !== this.state.__data.dwt,
        has_loa_changed =
          this.state.original_loa !== null &&
          this.state.original_loa !== this.state.__data.loa,
        has_draft_changed =
          this.state.original_draft !== null &&
          this.state.original_draft !== this.state.__data.draft,
        has_imo_changed =
          this.state.original_imo !== null &&
          this.state.original_imo !== this.state.__data.vessel_imo;
      let user_id = userSession._id,
        user_name = userSession.last_name + ", " + userSession.first_name;
      if (has_eta_changed) {
        amendment_data.push({
          field: "ETA",
          from_value: this.state.original_eta,
          to_value: this.state.__data.eta,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_etd_changed) {
        amendment_data.push({
          field: "ETD",
          from_value: this.state.original_etd,
          to_value: this.state.__data.etd,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_vessel_name_changed) {
        amendment_data.push({
          field: "Vessel Name",
          from_value: this.state.original_vessel_name,
          to_value: this.state.__data.vessel_name,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_beam_changed) {
        amendment_data.push({
          field: "Beam",
          from_value: this.state.original_beam,
          to_value: this.state.__data.beam,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_grt_changed) {
        amendment_data.push({
          field: "GRT",
          from_value: this.state.original_grt,
          to_value: this.state.__data.grt,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_nrt_changed) {
        amendment_data.push({
          field: "NRT",
          from_value: this.state.original_nrt,
          to_value: this.state.__data.nrt,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_total_number_of_crew_changed) {
        amendment_data.push({
          field: "No. of Crew",
          from_value: this.state.original_total_number_of_crew,
          to_value: this.state.__data.total_number_of_crew,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_shipping_lines_changed) {
        amendment_data.push({
          field: "Total no. of Crew",
          from_value: this.state.original_shipping_lines,
          to_value: this.state.__data.shipping_agency.name,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_tugboat_company_changed) {
        amendment_data.push({
          field: "Tugboat Company",
          from_value: this.state.original_tugboat_company,
          to_value: this.state.__data.tugboat_company,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_call_sign_changed) {
        amendment_data.push({
          field: "Call Sign",
          from_value: this.state.original_call_sign,
          to_value: this.state.__data.vessel_callsign,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_dwt_changed) {
        amendment_data.push({
          field: "DWT",
          from_value: this.state.original_dwt,
          to_value: this.state.__data.dwt,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_loa_changed) {
        amendment_data.push({
          field: "LOA",
          from_value: this.state.original_loa,
          to_value: this.state.__data.loa,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_draft_changed) {
        amendment_data.push({
          field: "Draft",
          from_value: this.state.original_draft,
          to_value: this.state.__data.draft,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      if (has_imo_changed) {
        amendment_data.push({
          field: "IMO Number",
          from_value: this.state.original_imo,
          to_value: this.state.__data.vessel_imo,
          user_id: user_id,
          amended_by: user_name,
          portcall_id: this.state.__data._id,
        });
      }

      Swal.fire({
        icon: "question",
        title:
          "Port Call already submitted. <br> You are about to submit an amendment. <br> Are you sure you want to continue?",
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        customClass: "vdetail-confirm-amendment",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Amendment Submitted!",
            showConfirmButton: false,
            timer: 1500,
          });

          api.save(this, {
            endpoint: "amendment/" + this.state.__data._id,
            _data: amendment_data,
            state_key: "amendments",
          });
        } else {
          this.setState({
            disableSave: false,
          });
        }
      });
    }
  };

  handleCancel = () => {
    if (!this.state.__ID) {
      this.props.changeVtype("");
    } else {
      this.props.history.push(
        `/port_call/visit_detail/5dc4e8f366d32a2624f300ab`
      );
    }
  };

  handleSave = () => {
    this.setState({ showButton: true });
    let { __data, selected_port_call_purposes } = this.state;
    let userSession = localStorage.getItem("port_session");
    if (userSession) {
      userSession = JSON.parse(userSession);
    }
    __data.company_id = userSession.company._id;

    if (__data.vessel_name) {
      this.setState({
        hasError: false,
        disableSave: true,
      });
      if (!this.state.__ID) {
        this.showAlert("loading", "", true);
        __data = { ...__data, selected_port_call_purposes };
        __data.status_id = constants.PENDING_STATUS;
        __data.status = "Pending";

        if (!__data.is_along_side_vessel) {
          __data.mother_vessel.vessel_name = "";
        }

        __data.local_or_foreign = "Local";

        api
          .save(this, {
            endpoint: "portcall",
            _data: __data,
          })
          .then((data) => {
            this.setState(
              {
                tglAlert: false,
                disableSave: false,
              },
              () =>
                this.showAlert(
                  "success",
                  "Vessel Detail successfully saved!",
                  true
                )
            );

            this.props.history.push("/port_call/in_port_services/" + data);
          });
      } else {
        this.showAlert("loading", "", true);
        if (
          parseInt(this.state.__data.status_id) ===
            parseInt(constants.PENDING_STATUS) ||
          parseInt(this.state.__data.status_id) ===
            parseInt(constants.REJECTED_STATUS)
        ) {
          if (!__data.is_along_side_vessel) {
            __data.mother_vessel.vessel_name = "";
          }

          api
            .update(this, {
              endpoint: "portcall/" + this.state.__ID,
              _data: __data,
            })
            .then(() => {
              this.setState(
                {
                  tglAlert: false,
                  disableSave: false,
                },
                () => this.showAlert("success", "Vessel Detail updated!", true)
              );
            });
        } else {
          this.createAmendment();
        }
      }
    } else {
      let { errList } = this.state;

      errList.vesselName.msg = !__data.vessel_name ? "This is required!" : "";
      errList._eta.msg = !__data.eta ? "This is required!" : "";
      errList._etd.msg = !__data.etd ? "This is required!" : "";

      this.setState({ ...errList, hasError: true });

      // console.log("err", errList);
    }
  };

  getViewMode = (view_mode) => {
    this.setState({
      viewMode: view_mode,
    });
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  disableFields = () => {
    if (session.get().role === "port_admin") {
      this.setState({
        readOnly: true,
      });
    }
  };

  render() {
    const {
      viewMode,
      isAlert,
      alertMsg,
      is_save_btn,
      tglAlert,
      errList,
      TUGBOATS,
      readOnly,
      PC_VESSELS,
    } = this.state;
    const vd = this.state.__data;

    const vessel = this.state.__data.vessel_name;
    const port_of_origin = this.state.__data.port_of_origin;

    const VNAME = vd.vessel_name;
    const VOYAGE_NO = vd.voyage_number;
    const { referrer } = this.state;

    if (referrer) return <redirect to={referrer} />;

    return (
      <div className="vesselEntry">
        {this.state.__ID ? (
          vd.is_vessel_entry_submitted || vd.is_vessel_entry_approved ? (
            <Alert color="warning" className="col-md-12 fs13">
              <b>CAUTION: </b>Changes will be considered as an <b>amendment.</b>
            </Alert>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <Row className="form-container-box bt-green vessel-detail-form">
          <Form>
            <div className="fcb-title">Local Vessel Details</div>

            <Row xs="1" md="2">
              <Col>
                <FormGroup>
                  <Label for="">IMO Number</Label>
                  <Input
                    className="form-control-sm"
                    type="text"
                    name="mmsi_no"
                    value={this.state.__data.vessel_imo}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.vessel_imo = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col></Col>
            </Row>

            <div className="grn-divider"></div>

            <Row xs="1" md="2">
              <Col>
                <span id="vd_id"></span>
                <FormGroup
                  ref={this.vnRef}
                  className={
                    errList.vesselName.msg
                      ? "required-field m-0"
                      : "m-0"
                      ? "required-field m-0"
                      : "m-0"
                  }
                  // className="col-md-6"
                >
                  <Label for="exampleSelect">Vessel Name {vd.is_local}</Label>
                  <DropdownList
                    filter
                    value={vessel}
                    textField="vessel_name"
                    data={this.state.__vessels}
                    onChange={(vessel) => {
                      let { errList, __data } = this.state;

                      __data.vessel_name = vessel.vessel_name;
                      this.setSelectedVessel(vessel);

                      errList.vesselName.msg = !__data.vessel_name
                        ? "This is required!"
                        : "";

                      this.setState({ __data, errList });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />

                  <div className="error-msg col-md-12">
                    {errList.vesselName.msg}
                  </div>

                  {/* <div className="react-toggle-cont">
                    <Toggle
                      className="custom-toggle-color"
                      icons={false}
                      checked={vd.is_local}
                      onChange={(e) => {
                        let { __data } = this.state;
                        __data.is_local = e.target.checked;

                        this.setState({ __data });
                        // console.log("ESDFSDFSDF", e.target.checked);
                      }}
                    />
                    <span>Local {vd.is_local}</span>
                  </div> */}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="">Beam</Label>
                  <Input
                    className="form-control-sm"
                    type="number"
                    name="mmsi_no"
                    id=""
                    placeholder=""
                    value={this.state.__data.beam}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.beam = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="position-relative">
                  <Label for="">GRT</Label>
                  <Input
                    className="form-control-sm wAddon"
                    type="number"
                    name="mmsi_no"
                    id=""
                    placeholder=""
                    value={this.state.__data.grt}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.grt = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                  <span className="inputAddon">MT</span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="">Call Sign</Label>
                  <Input
                    className="form-control-sm"
                    type="text"
                    name="mmsi_no"
                    id=""
                    placeholder=""
                    value={this.state.__data.vessel_callsign}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.vessel_callsign = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="position-relative">
                  <Label for="">NRT</Label>
                  <Input
                    className="form-control-sm wAddon"
                    type="number"
                    name="mmsi_no"
                    id=""
                    placeholder=""
                    value={this.state.__data.nrt}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.nrt = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                  <span className="inputAddon">MT</span>
                </FormGroup>
              </Col>
              {/* <Col>
                <FormGroup>
                  <Label for="">Deadweight</Label>
                  <Input
                    className="form-control-sm"
                    type="text"
                    name="mmsi_no"
                    id=""
                    placeholder=""
                    value={this.state.__data.vessel_callsign}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.vessel_callsign = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col> */}
              <Col>
                <FormGroup className="position-relative">
                  <Label for="">DWT</Label>
                  <Input
                    className="form-control-sm wAddon"
                    type="number"
                    name="mmsi_no"
                    id=""
                    placeholder=""
                    value={this.state.__data.dwt}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.dwt = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                  <span className="inputAddon">MT</span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="">No. of Crew</Label>
                  <Input
                    className="form-control-sm"
                    type="number"
                    name="mmsi_no"
                    id=""
                    placeholder=""
                    value={this.state.__data.total_number_of_crew}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.total_number_of_crew = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Row>
                  <Col xs="7">
                    <FormGroup>
                      <Label for="">LOA</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                        value={this.state.__data.loa}
                        onChange={(e) => {
                          let { __data } = this.state;
                          __data.loa = e.target.value;
                          this.setState({ __data });
                        }}
                        //note: i add disable
                        disabled={readOnly}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="5">
                    <FormGroup>
                      <Label for="">&nbsp;</Label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        className="form-control-sm"
                        //note: i add disable
                        disabled={readOnly}
                      >
                        <option>-</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="">Shipping Lines</Label>
                  <Input
                    className="form-control-sm"
                    type="text"
                    name="mmsi_no"
                    id=""
                    placeholder=""
                    value={this.state.__data.shipping_agency.name}
                    onChange={(e) => {
                      let { __data } = this.state;
                      __data.shipping_agency.name = e.target.value;
                      this.setState({ __data });
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Row>
                  <Col xs="7">
                    <FormGroup>
                      <Label for="">Draft</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                        value={this.state.__data.draft}
                        onChange={(e) => {
                          let { __data } = this.state;
                          __data.draft = e.target.value;
                          this.setState({ __data });
                        }}
                        //note: i add disable
                        disabled={readOnly}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="5">
                    <FormGroup>
                      <Label for="">&nbsp;</Label>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        className="form-control-sm"
                        //note: i add disable
                        disabled={readOnly}
                      >
                        <option>-</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="exampleSelect">Tugboat Company</Label>
                  <DropdownList
                    filter
                    value={this.state.__data.tugboat_company}
                    textField="name"
                    data={TUGBOATS}
                    onChange={(boat) => {
                      let { __data, _TUGBOATS } = this.state;
                      let tug_id = "";

                      for (let x in _TUGBOATS) {
                        if (_TUGBOATS[x].name === boat) {
                          tug_id = _TUGBOATS[x]._id;
                        }
                      }

                      __data.tugboat_company = boat;
                      __data.tugboat_company_id = tug_id;
                      this.setState({ __data });

                      // console.log("TUGUGUG", __data);
                    }}
                    //note: i add disable
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>

              <Col></Col>
              <Col></Col>
            </Row>
          </Form>
        </Row>

        <div className="footer-actions">
          <div>
            <Button
              className="btn-sm"
              color="danger"
              onClick={() => this.handleCancel()}
            >
              CANCEL
            </Button>
            {is_save_btn ? (
              <Button
                className={this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"}
                color="success"
                onClick={() => this.handleSave()}
                hidden={readOnly}
              >
                <Media queries={constants.media_queries}>
                  {(matches) => (matches.xs ? <Icon name="save" /> : "SAVE")}
                </Media>
              </Button>
            ) : (
              <Button
                onClick={() => this.handleSave()}
                className={this.state.disableSave ? "dis-btn btn-sm" : "btn-sm"}
                color="primary"
                hidden={readOnly}
              >
                <Media queries={constants.media_queries}>
                  {(matches) =>
                    matches.xs ? <Icon name="chevron-right" /> : "NEXT"
                  }
                </Media>
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LocalVesselForm);
