import React, { Component, useState } from "react";
import {
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Form,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Icon } from "react-fa";
import Moment from "react-moment";
import Navbar from "../../Navbar/Navbar";
import "./BerthMaintenance.scss";
import Swal from "sweetalert2";
import api from "../../../helpers/api.helper";
import { DropdownList } from "react-widgets";
import AlertModal from "../../Tools/AlertModal/AlertModal";

class BerthMaintenance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      isEdit: false,
      isAlert: "",
      modalMsg: "",
      tglAler: false,
      BERTHS: [],
      BERTH: [],
      ERR: {
        name: false,
        depth: false,
        allowableDraft: false,
        loa: false,
        minLoa: false,
        maxLoa: false,
        cHandler: false,
      },
      CARGO_HANDLERS: [],
      CARGO_HANDLER_NAMES: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
    this.getCargoHandlers();
    // this.handleAddTugBoat();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "berth",
        state_key: "BERTHS",
      })
      .then((data) => {
        let berths = [];

        for (let x in data) {
          if (!data[x].deleted_at) {
            berths[x] = data[x];
          }
        }

        this.setState({
          BERTHS: berths,
        });
      });
  };

  getCargoHandlers = () => {
    api
      .get(this, { endpoint: "cargo_handler", state_key: "CARGO_HANDLERS" })
      .then((data) => {
        let cargo_handlers = [];

        for (let x in data) {
          cargo_handlers[x] = data[x].name;
        }

        this.setState({
          CARGO_HANDLER_NAMES: cargo_handlers,
        });

        // console.log("CARGO", this.state.CARGO_HANDLERS);
      });
  };

  toggleAddBerth = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  newBerth = () => {
    let { BERTH } = this.state;

    let berth = [];

    BERTH = berth;

    this.setState({
      openModal: !this.state.openModal,
      isEdit: false,
      BERTH,
    });
  };

  handleSave = () => {
    let { BERTH, CARGO_HANDLERS } = this.state;
    let cargoHandlerID = "";

    for (let x in CARGO_HANDLERS) {
      if (CARGO_HANDLERS.name === BERTH.cargo_handler) {
        cargoHandlerID = CARGO_HANDLERS._id;
      }
    }

    if (BERTH.name && BERTH.depth && BERTH.loa && BERTH.allowable_draft) {
      api
        .save(this, {
          endpoint: "berth",
          _data: {
            name: BERTH.name,
            depth: BERTH.depth,
            allowable_draft: BERTH.allowable_draft,
            loa: BERTH.loa,
            min_loa: BERTH.min_loa,
            max_loa: BERTH.max_loa,
            cargo_handler: BERTH.cargo_handler,
            cargo_handler_id: cargoHandlerID,
          },
        })
        .then((data) => {
          this.showAlert("success", "Berth successfully saved!", true);

          this.toggleAddBerth();
          this.loadData();
        });
    } else {
      let { ERR } = this.state;

      ERR.name = BERTH.name ? false : true;
      ERR.depth = BERTH.depth ? false : true;
      ERR.loa = BERTH.loa ? false : true;
      ERR.allowableDraft = BERTH.allowable_draft ? false : true;
      ERR.minLoa = BERTH.min_loa ? false : true;
      ERR.maxLoa = BERTH.max_loa ? false : true;
      ERR.cHandler = BERTH.cargo_handler ? false : true;

      this.setState({
        ERR,
      });
    }
  };

  handleDeleteBerth = (i, name) => {
    Swal.fire({
      icon: "question",
      title: "Delete " + name + "?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Deleted " + name + "!",
          showConfirmButton: false,
          timer: 1500,
        });

        api
          .update(this, {
            endpoint: "berth/" + i,
            _data: {
              deleted_at: new Date(),
            },
          })
          .then((data) => {
            this.loadData();
          });
      }
    });
  };

  handleBerthData = (i) => {
    api
      .get(this, {
        endpoint: "berth/" + i,
        state_key: "BERTH",
      })
      .then((data) => {
        this.setState({
          isEdit: true,
        });

        this.toggleAddBerth();
      });
  };

  handleUpdateBerth = () => {
    let { BERTH, CARGO_HANDLERS } = this.state;
    let cargoHandlerID = "";

    for (let x in CARGO_HANDLERS) {
      if (CARGO_HANDLERS[x].name === BERTH.cargo_handler) {
        cargoHandlerID = CARGO_HANDLERS[x]._id;
      }
    }
    console.log("CARDOID", CARGO_HANDLERS);

    if (
      BERTH.name &&
      BERTH.depth &&
      BERTH.loa &&
      BERTH.allowable_draft &&
      BERTH.min_loa &&
      BERTH.max_loa &&
      BERTH.cargo_handler
    ) {
      Swal.fire({
        icon: "question",
        title: "Save changes for " + BERTH.name + "?",
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Updated " + BERTH.name + "!",
            showConfirmButton: false,
            timer: 1500,
          });

          api
            .update(this, {
              endpoint: "berth/" + BERTH._id,
              _data: {
                name: BERTH.name,
                depth: BERTH.depth,
                allowable_draft: BERTH.allowable_draft,
                loa: BERTH.loa,
                min_loa: BERTH.min_loa,
                max_loa: BERTH.max_loa,
                cargo_handler: BERTH.cargo_handler,
                cargo_handler_id: cargoHandlerID,
              },
            })
            .then((data) => {
              this.setState({
                openModal: !this.state.openModal,
                isEdit: false,
              });

              this.loadData();
            });
        }
      });
    } else {
      let { ERR } = this.state;

      ERR.name = BERTH.name ? false : true;
      ERR.depth = BERTH.depth ? false : true;
      ERR.loa = BERTH.loa ? false : true;
      ERR.allowableDraft = BERTH.allowable_draft ? false : true;
      ERR.minLoa = BERTH.min_loa ? false : true;
      ERR.maxLoa = BERTH.max_loa ? false : true;
      ERR.cHandler = BERTH.cargo_handler ? false : true;

      this.setState({
        ERR,
      });
    }
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  render() {
    return (
      <div>
        {this.state.tglAlert ? (
          <AlertModal
            sendTrigger={this.state.isAlert}
            sendMsg={this.state.alertMsg}
          />
        ) : (
          ""
        )}
        <div className="flex-column">
          <Navbar />

          <div className="col-md-12 p-20">
            <Col
              md={{ size: 12 }}
              className="form-container-box d-flex flex-column bt-green"
            >
              <div className="col-md-12 d-flex flex-column p-0">
                <div className="col-md-12 fcb-title">Maintenance > Berth</div>
                <div className="col-md-12 d-flex justify-content-end p-0 mt-3">
                  <Button
                    className="btn-sm"
                    color="primary"
                    onClick={() => this.newBerth()}
                  >
                    NEW
                  </Button>
                </div>
                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "10px" }}
                >
                  <thead>
                    <tr>
                      <th>Berth</th>
                      <th>Depth</th>
                      <th>Allowable Draft</th>
                      <th>LOA</th>
                      <th>Min. LOA</th>
                      <th>Max. LOA</th>
                      <th>Cargo Handler</th>
                      <th width="5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.BERTHS.map((b, $i) => {
                      return (
                        <tr key={$i}>
                          <td>{b.name}</td>
                          <td>{parseFloat(b.depth).toFixed(3)}</td>
                          <td>{parseFloat(b.allowable_draft).toFixed(3)}</td>
                          <td>{parseFloat(b.loa).toFixed(3)}</td>
                          <td>{parseFloat(b.min_loa).toFixed(3)}</td>
                          <td>{parseFloat(b.max_loa).toFixed(3)}</td>
                          <td>{b.cargo_handler}</td>
                          <td>
                            <div className="d-flex flex-row tblActions w100per">
                              <Icon
                                name="pencil"
                                style={{
                                  backgroundColor: "#4CAF50",
                                  marginRight: "0.5em",
                                }}
                                onClick={() => this.handleBerthData(b._id)}
                              />
                              <Icon
                                // onClick={() =>
                                //   this.handleRemoveTBC(
                                //     this.state.TUGBOAT_COMPANIES,
                                //     $i,
                                //     ac._id
                                //   )
                                // }
                                onClick={() =>
                                  this.handleDeleteBerth(b._id, b.name)
                                }
                                name="trash"
                                style={{ backgroundColor: "#f44336" }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </div>

          <Modal isOpen={this.state.openModal} toggle={this.toggleAddBerth}>
            <ModalHeader toggle={this.toggleAddBerth}>
              {this.state.isEdit
                ? "Edit " + this.state.BERTH.name
                : "New Berth"}
            </ModalHeader>
            <ModalBody>
              <Form className="d-flex flex-column">
                <div className="col-md-12 p-0 d-flex flex-row">
                  <div className="col-md-6">
                    <FormGroup
                      className={
                        this.state.ERR.name
                          ? "p-0 mb-5 w100per required-field"
                          : "p-0 mb-5 w100per"
                      }
                    >
                      <Label for="">Berth Name</Label>
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="mmsi_no"
                        value={this.state.BERTH.name}
                        onChange={(e) => {
                          let { BERTH, ERR } = this.state;
                          BERTH.name = e.target.value;
                          ERR.name = false;
                          this.setState({
                            BERTH,
                            ERR,
                          });
                        }}
                      />
                      {this.state.ERR.name ? (
                        <span className="error-msg">This is required.</span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup
                      className={
                        this.state.ERR.loa
                          ? "p-0 mb-5 w100per required-field"
                          : "p-0 mb-5 w100per"
                      }
                    >
                      <Label for="">LOA</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        value={this.state.BERTH.loa}
                        onChange={(e) => {
                          let { BERTH, ERR } = this.state;
                          BERTH.loa = e.target.value;
                          ERR.loa = false;
                          this.setState({
                            BERTH,
                            ERR,
                          });
                        }}
                      />
                      {this.state.ERR.loa ? (
                        <span className="error-msg">This is required.</span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </div>
                </div>

                <div className="col-md-12 p-0 d-flex flex-row">
                  <div className="col-md-6">
                    <FormGroup
                      className={
                        this.state.ERR.depth
                          ? "p-0 mb-5 w100per required-field"
                          : "p-0 mb-5 w100per"
                      }
                    >
                      <Label for="">Depth</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        value={this.state.BERTH.depth}
                        onChange={(e) => {
                          let { BERTH, ERR } = this.state;
                          BERTH.depth = e.target.value;
                          ERR.depth = false;
                          this.setState({
                            BERTH,
                            ERR,
                          });
                        }}
                      />
                      {this.state.ERR.depth ? (
                        <span className="error-msg">This is required.</span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </div>

                  <div className="col-md-6">
                    <FormGroup
                      className={
                        this.state.ERR.minLoa
                          ? "p-0 mb-5 w100per required-field"
                          : "p-0 mb-5 w100per"
                      }
                    >
                      <Label for="">Min. LOA</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        value={this.state.BERTH.min_loa}
                        onChange={(e) => {
                          let { BERTH, ERR } = this.state;
                          BERTH.min_loa = e.target.value;
                          ERR.minLoa = false;
                          this.setState({
                            BERTH,
                            ERR,
                          });
                        }}
                      />
                      {this.state.ERR.minLoa ? (
                        <span className="error-msg">This is required.</span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </div>
                </div>

                <div className="col-md-12 p-0 d-flex flex-row">
                  <div className="col-md-6">
                    <FormGroup
                      className={
                        this.state.ERR.allowableDraft
                          ? "p-0 mb-5 w100per required-field"
                          : "p-0 mb-5 w100per"
                      }
                    >
                      <Label for="">Allowable Draft</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        value={this.state.BERTH.allowable_draft}
                        onChange={(e) => {
                          let { BERTH, ERR } = this.state;
                          BERTH.allowable_draft = e.target.value;
                          ERR.allowableDraft = false;
                          this.setState({
                            BERTH,
                            ERR,
                          });
                        }}
                      />
                      {this.state.ERR.allowableDraft ? (
                        <span className="error-msg">This is required.</span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup
                      className={
                        this.state.ERR.maxLoa
                          ? "p-0 mb-5 w100per required-field"
                          : "p-0 mb-5 w100per"
                      }
                    >
                      <Label for="">Max. LOA</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        value={this.state.BERTH.max_loa}
                        onChange={(e) => {
                          let { BERTH, ERR } = this.state;
                          BERTH.max_loa = e.target.value;
                          ERR.maxLoa = false;
                          this.setState({
                            BERTH,
                            ERR,
                          });
                        }}
                      />
                      {this.state.ERR.maxLoa ? (
                        <span className="error-msg">This is required.</span>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </div>
                </div>

                <div
                  className={
                    this.state.ERR.cHandler
                      ? "col-md-12 d-flex flex-row required-field"
                      : "col-md-12 d-flex flex-row"
                  }
                >
                  {/* <FormGroup className="p-0 mb-5 w100per">
                    <Label for="">Cargo Handler</Label>
                    <DropdownList
                      filter
                      value={this.state.BERTH.cargo_handler}
                      textField="vessel_name"
                      data={this.state.CARGO_HANDLER_NAMES}
                      onChange={ch => {
                        let { BERTH, ERR } = this.state;

                        BERTH.cargo_handler = ch;
                        ERR.cHandler = BERTH.cargo_handler ? false : true;

                        this.setState({ BERTH, ERR });

                        // console.log("CHEK", ch);
                      }}
                    />
                    {this.state.ERR.cHandler ? (
                      <span className="error-msg">This is required.</span>
                    ) : (
                      ""
                    )}
                  </FormGroup> */}
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                className="fs13"
                color="danger"
                onClick={this.toggleAddBerth}
              >
                CANCEL
              </Button>
              <Button
                className="fs13"
                color="success"
                onClick={() =>
                  this.state.isEdit
                    ? this.handleUpdateBerth()
                    : this.handleSave()
                }
              >
                SAVE
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(BerthMaintenance);
