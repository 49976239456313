const permissions = {
  port_admin: {
    static: [
      "port-call:visit",
      "port-call:list",
      "port-call:view",
      "port-call-entry-exit:approve",
      "sidenav-berth-management:view",
      "sidenav-document-management:view",
      "port-call-vessel-doc:manage",
      "sidenav-vessel-detail",
      "sidenav-pro-call-detail",
      "sidenav-crew-details",
      "berth-assignment:create",
      "departure-approval:approve",
      "settings:tugboat-companies",
      "settings:berth",
      "settings:cargo-handlers",
    ],
  },

  admin: {
    static: [
      "port-call:visit",
      "port-call:list",
      "port-call:view",
      "sidenav-pre-departure:view",
      "sidenav-general-manifest:view",
      "sidenav-document-management:view",
      "sidenav-berth-management:view",
      "sidenav-general-manifest:view",
      "port-call-vessel-doc:manage",
      "port-call-vessel-doc:submit",
      "port-call:create",
      "sidenav-vessel-detail",
      "sidenav-pro-call-detail",
      "sidenav-crew-details",
    ],
  },

  processing: {
    static: [
      "port-call:visit",
      "port-call:list",
      "port-call:view",
      "port-call-entry:list",
      "sidenav-document-management:view",
      "port-call-vessel-doc:process",
      "sidenav-vessel-detail",
      "sidenav-pro-call-detail",
      "sidenav-crew-details",
    ],
  },

  billing: {
    static: [
      "port-call:visit",
      "port-call:list",
      "port-call:view",
      "port-call-entry:list",
      "sidenav-document-management:view",
      "sidenav-pre-departure:view",
      "port-tariffs-and-services:process",
      "sidenav-vessel-detail",
      "sidenav-pro-call-detail",
      "sidenav-crew-details",
      "sidenav-port-tariffs-and-services",
      "departure-approval:endorse",
    ],
  },

  engineering: {
    static: [
      "port-call:visit",
      "port-call:list",
      "port-call:view",
      "port-call-entry:list",
      "sidenav-document-management:view",
      "sidenav-resource-management:manage",
      "sidenav-vessel-detail",
      "sidenav-pro-call-detail",
      "sidenav-crew-details",
      "resource-management:approve",
    ],
  },

  harbor_master: {
    static: [
      "port-call:visit",
      "port-call:list",
      "port-call:view",
      "port-call-entry-exit:approve",
      "sidenav-berth-management:view",
      "berth-management:create",
      "berth-assignment:create",
      "sidenav-document-management:view",
      "sidenav-vessel-detail",
      "sidenav-pro-call-detail",
      "sidenav-crew-details",
      "pc-dashboard-command-buttons",
      "settings:tugboat-companies",
      "settings:berth",
      "settings:cargo-handlers",
      "side-cue:berth-assignment",
    ],
  },

  shipping_agent: {
    static: [
      "dashboard:visit",
      "port-call:visit",
      "port-call:list",
      "port-call-entry:list",
      "port-call-entry:create",
      "port-call-exit:list",
      "sidenav-pre-departure:view",
      "departure:create",
      "sidenav-general-manifest:view",
      "sidenav-document-management:view",
      "sidenav-general-manifest:view",
      "port-call-vessel-doc:submit",
      "sidenav-portcall:delete",
      "port-call:create",
      "sidenav-vessel-detail",
      "sidenav-pro-call-detail",
      "sidenav-crew-details",
      "side-cue:vessel-detail",
      "side-cue:purpose-of-port-call",
      "side-cue:crew-details",
      "side-cue:manifest",
      "vessel-entry:create",
    ],

    // dynamic: {
    //   "port-call:view": ({ company_id, port_call_company_id }) => {
    //     if (!company_id || !port_call_company_id) return false;
    //     return company_id === port_call_company_id;
    //   },
    // },
  },
  cargo_handler: {
    static: [
      "dashboard:visit",
      "port-call:visit",
      "port-call:list",
      "port-call-entry:list",
      "port-call-exit:list",
      "port-call-resource-allocation:list",
      "port-call-entry:create",
      "sidenav-pre-departure:view",
      "departure:create",
      "sidenav-general-manifest:view",
      "sidenav-document-management:view",
      "sidenav-general-manifest:view",
      "port-call-vessel-doc:submit",
      "sidenav-portcall:delete",
      "port-call:create",
      "sidenav-vessel-detail",
      "sidenav-pro-call-detail",
      "sidenav-crew-details",
      "sidenav-resource-management:manage",
      "resource-management:edit",
      "resource-management:create",
    ],
    // dynamic: {
    //   "port-call:view": ({ company_id, port_call_company_id }) => {
    //     if (!company_id || !port_call_company_id) return false;
    //     return company_id === port_call_company_id;
    //   }
    // }
  },
};

export default permissions;
