export default {

  human_readable: (str) => {
    if (!str) {
      return '';
    }
    let frags = str.split('_');

    for (var i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }


};
