import React, { Component, useRef } from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import { Col, Button, Form, FormGroup, Label, Input, Table } from "reactstrap";
import { DropdownList } from "react-widgets";
import { Icon } from "react-fa";
import back_icon from "../../img/svg/BackIcon.svg";
import constants from "../../constants";
import "./CargoDetails.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import AlertModal from "../Tools/AlertModal/AlertModal";
import api from "../../helpers/api.helper";
import { withRouter } from "react-router-dom";
import BackToTop from "../Tools/BackToTop/BackToTop";
import Swal from "sweetalert2";

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PORT_CALL: [],
      ID: this.props.getID,
    };
  }

  componentWillMount = () => {
    this.loadData();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "portcall/" + this.props.getID,
        state_key: "PORT_CALL",
      })
      .then((data) => {});
  };

  createTableGuide = (count) => {
    let row_guide = [];
    let width = 100 / count + "%";

    for (let i = 0; i < count; i++) {
      row_guide.push(<td width={width} style={{ maxWidth: width }}></td>);
    }
    return row_guide;
  };

  render() {
    const pc = this.state.PORT_CALL;

    return (
      <div className="print-source">
        <div
          className="col-md-12 d-flex flex-column justify-content-end align-items-center mt-4 print1-content"
          style={{ fontSize: "19px", width: "1600px" }}
        >
          <span>REPUBLIC OF THE PHILIPPINES</span>
          <span>DEPARTMENT OF FINANCE</span>
          <span>BUREAU OF CUSTOMS</span>

          <p className="mt-3">C O A S T I N G &nbsp; M A N I F E S T</p>
        </div>

        <div
          className="col-md-12 d-flex flex-column mt-2 print1-content"
          style={{ fontSize: "19px", width: "1580px" }}
        >
          <div className="d-flex flex-row">
            <span className="" style={{ textIndent: "50px" }}>
              Manifest of the whole cargo laden on board the{" "}
            </span>
            <div className="w-underline w25em"></div>class coasting vessel{""}
            <div className="w-underline w20em">{pc.vessel_name}</div>
          </div>
          <div className="d-flex flex-row">
            License No. <div className="w-underline w20em"></div> License at{" "}
            <div className="w-underline w20em"></div>propelled by STEAM, Voyage
            No. <div className="w-underline w10em">{pc.voyage_number}</div>{" "}
            whereof
          </div>
          <div className="d-flex flex-row">
            <div className="w-underline w25em"></div> is master Burden{" "}
            <div className="w-underline w10em"></div> tons, and{" "}
            <div className="w-underline w10em">
              {pc.total_number_of_pa_crew
                ? pc.total_number_of_pa_crew
                : pc.total_number_of_pd_crew}
            </div>
            crew bound from{" "}
            <div className="w-underline w15em p-relative">
              {pc.port_of_origin}
              <span style={{ position: "absolute", top: "5.5em" }}>
                (Here give port of)
              </span>
            </div>
          </div>
          <div className="d-flex flex-row">
            for{" "}
            <div className="w-underline w40em p-relative">
              {pc.port_of_destination}
              <span style={{ position: "absolute", top: "7em" }}>
                (Give port or ports of call)
              </span>
            </div>
            , <div className="w-underline w10em"></div>
          </div>

          <Table
            className="w100per mt-5 print-table ptable1"
            style={{ fontSize: "19px" }}
          >
            <thead>
              <tr>
                <th>62838 BL Number</th>
                <th>
                  <div style={{ marginTop: "-2.5em" }}>Marks</div>
                </th>
                <th>Number of Packages</th>
                <th>Kind of Parcel</th>
                <th>
                  <div style={{ marginTop: "-2.5em" }}>CONTENTS</div>
                </th>
                <th>Value (Philippine Currency)</th>
                <th>Weight in Kilograms</th>
                <th>Measurement in Cubic Meters</th>
                <th>
                  <div style={{ marginTop: "-2.5em" }}>SHIPPERS</div>
                </th>
                <th>
                  <div style={{ marginTop: "-2.5em" }}>CONSIGNEE</div>
                </th>
                <th>
                  <div style={{ marginTop: "-2.5em" }}>RESID</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>

        <p style={{ pageBreakBefore: "always" }}></p>

        <div
          className="col-md-12 d-flex flex-column justify-content-end align-items-center"
          // style={{ marginTop: "40em" }}
        >
          <div className="d-flex flex-row w100per">
            <div className="col-md-3 d-flex flex-column">
              <p className="m0 fw600" style={{ fontSize: "16px" }}>
                Phil. Port Authority
              </p>
              <p className="m0">FORM No.</p>
              <p className="m0">Revised 1984</p>
            </div>
            <div className="col-md-6 tac fwb" style={{ fontSize: "22px" }}>
              <p className="">C O A S T I N G &nbsp; M A N I F E S T</p>
            </div>
            <div className="col-md-3 d-flex flex-row justify-content-end">
              PAGE<div className="w-underline w5em"></div>OF
              <div className="w-underline w5em"></div>
            </div>
          </div>
        </div>

        <div
          className="col-md-12 d-flex flex-column mt-2"
          style={{ textAlign: "justify" }}
        >
          <Table
            className="w100per mt-3 print-table"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            <thead>
              <tr className="row-guide">{this.createTableGuide(82)}</tr>
              <tr>
                <th
                  colSpan="50"
                  rowSpan="2"
                  style={{
                    borderTopColor: "transparent",
                    borderLeftColor: "transparent",
                  }}
                ></th>
                <th
                  colSpan="5"
                  style={{
                    borderBottomColor: "transparent",
                    borderRightColor: "transparent",
                  }}
                ></th>
                <th
                  colSpan="4"
                  style={{ borderRightColor: "transparent" }}
                ></th>
                <th
                  colSpan="7"
                  style={{
                    borderBottomColor: "transparent",
                    borderRightColor: "transparent",
                  }}
                ></th>
                <th
                  colSpan="4"
                  style={{ borderRightColor: "transparent" }}
                ></th>
                <th
                  colSpan="11"
                  style={{
                    borderBottomColor: "transparent",
                    borderRightColor: "transparent",
                  }}
                ></th>
                <th colSpan="2" style={{ borderRightColor: "transparent" }}>
                  YR
                </th>
                <th colSpan="2" style={{ borderRightColor: "transparent" }}>
                  MO
                </th>
                <th colSpan="2">DAY</th>
              </tr>
              <tr>
                <th colSpan="5" style={{ borderTopColor: "transparent" }}>
                  ENTRANCE
                </th>
                <th colSpan="4" style={{ borderTop: "1px solid" }}></th>
                <th colSpan="7">CLEARANCE</th>
                <th colSpan="4"></th>
                <th colSpan="11">DATE</th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
              </tr>
              <tr>
                <th
                  colSpan="6"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  <span>a. Vessel Name</span>
                </th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th
                  colSpan="8"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  b. GRT
                </th>

                <th
                  colSpan="8"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  c. NRT
                </th>

                <th
                  colSpan="8"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  d. VOYAGE No.
                </th>

                <th
                  colSpan="6"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  e. PORT OF ORIGIN
                </th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th
                  colSpan="6"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  f. PORT OF DESTINATION
                </th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th
                  colSpan="11"
                  className="tal"
                  style={{ borderBottom: "none" }}
                >
                  g. DATE OF ENTRY AT PORT
                </th>

                <th
                  colSpan="5"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  h. CONTROL
                </th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th
                  colSpan="8"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  i. REGISTRY No.
                </th>
              </tr>
              <tr>
                <th colSpan="12">{pc.vessel_name}</th>

                <th colSpan="8">{pc.grt}</th>

                <th colSpan="8">{pc.nrt}</th>

                <th colSpan="8">{pc.voyage_number}</th>

                <th colSpan="12">{pc.port_of_origin}</th>

                <th colSpan="12">{pc.port_of_destination}</th>

                <th colSpan="5" style={{ borderTopColor: "transparent" }}></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th colSpan="8"></th>

                <th colSpan="8"></th>
              </tr>
              <tr>
                <th rowspan="4" colSpan="2">
                  <div> No.</div>
                  <div>(1)</div>
                </th>
                <th rowspan="4" colSpan="4">
                  CONTAINER No. CARGO MARKS
                  <div>(2)</div>
                </th>
                <th rowspan="1" colSpan="9">
                  CONTAINER
                </th>
                <th rowspan="1" colSpan="6">
                  PACKAGE
                </th>
                <th rowspan="4" colSpan="6">
                  CONTENTS
                  <div>(8)</div>
                </th>
                <th rowspan="1" colSpan="7">
                  -
                </th>
                <th rowspan="4" colSpan="4">
                  VOLUME <br />
                  (Cubic) <br />
                  (9)
                </th>
                <th rowspan="4" colSpan="4">
                  WEIGHT <br />
                  (KILOS) <br />
                  (10)
                </th>
                <th rowspan="4" colSpan="4">
                  VALUE (PESOS) <br />
                  (11)
                </th>
                <th rowspan="4" colSpan="6">
                  SHIPPER <br />
                  (12)
                </th>
                <th rowspan="4" colSpan="6">
                  CONSIGNEE <br />
                  (13)
                </th>
                <th rowspan="1" colSpan="14">
                  FREIGHT CHARGES
                </th>
                <th rowspan="4" colSpan="4">
                  AUXI <br />
                  LIARY <br />
                  CHARGES <br />
                  (19)
                </th>
                <th rowspan="4" colSpan="3">
                  STAMP <br />
                  (20)
                </th>
                <th rowspan="4" colSpan="3">
                  OR NO. <br />
                  (21)
                </th>
                <th rowspan="4" colSpan="5">
                  REMARKS <br />
                  (22)
                </th>
              </tr>
              <tr>
                <th rowspan="3" colSpan="3">
                  CON <br />
                  TAI <br />
                  NER <br />
                  SIZE <br />
                  (PT)
                  <div>(3)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  SERVICE
                  <div>(4)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  SEALING
                  <div>(5)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  NO.
                  <div>(6)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  NRD
                  <div>(7)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  TYPE <br />
                  OF <br />
                  HAND <br />
                  LING <br />
                  CODE
                  <div>(8)</div>
                </th>
                <th rowspan="3" colSpan="4">
                  COMMO
                  <div>DITY</div>
                  <div>CODE</div>
                  <div>(9)</div>
                </th>
                <th rowspan="3" colSpan="4">
                  PREPAID <br />
                  AT <br />
                  ORIGIN <br />
                  (16)
                </th>
                <th rowspan="3" colSpan="5">
                  ACCOUNTS <br />
                  RECEIVABLE <br />
                  AT ORIGIN <br />
                  (17)
                </th>
                <th rowspan="3" colSpan="5">
                  PAYABLE <br />
                  AT <br />
                  DESTINATION <br />
                  (18)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>{" "}
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

class ComponentToPrint2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PORT_CALL: [],
      ID: this.props.getID,
    };
  }

  componentWillMount = () => {
    this.loadData();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "portcall/" + this.props.getID,
        state_key: "PORT_CALL",
      })
      .then((data) => {});
  };

  createTableGuide = (count) => {
    let row_guide = [];
    let width = 100 / count + "%";

    for (let i = 0; i < count; i++) {
      row_guide.push(<td width={width} style={{ maxWidth: width }}></td>);
    }
    return row_guide;
  };

  render() {
    const pc = this.state.PORT_CALL;

    return (
      <div className="print-source print2">
        <div className="col-md-12 d-flex flex-column justify-content-end align-items-center mt-4">
          <div className="d-flex flex-row w100per">
            <div className="col-md-3 d-flex flex-column">
              <p className="m0 fw600" style={{ fontSize: "16px" }}>
                Phil. Port Authority
              </p>
              <p className="m0">FORM No.</p>
              <p className="m0">Revised 1984</p>
            </div>
            <div className="col-md-6 tac fwb" style={{ fontSize: "22px" }}>
              <p className="">C O A S T I N G &nbsp; M A N I F E S T</p>
            </div>
            <div className="col-md-3 d-flex flex-row justify-content-end">
              PAGE<div className="w-underline w5em"></div>OF
              <div className="w-underline w5em"></div>
            </div>
          </div>
        </div>

        <div
          className="col-md-12 d-flex flex-column mt-2"
          style={{ textAlign: "justify" }}
        >
          <Table
            className="w100per mt-3 print-table"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            <thead>
              <tr className="row-guide">{this.createTableGuide(82)}</tr>
              <tr>
                <th
                  colSpan="50"
                  rowSpan="2"
                  style={{
                    borderTopColor: "transparent",
                    borderLeftColor: "transparent",
                  }}
                ></th>
                <th
                  colSpan="5"
                  style={{
                    borderBottomColor: "transparent",
                    borderRightColor: "transparent",
                  }}
                ></th>
                <th
                  colSpan="4"
                  style={{ borderRightColor: "transparent" }}
                ></th>
                <th
                  colSpan="7"
                  style={{
                    borderBottomColor: "transparent",
                    borderRightColor: "transparent",
                  }}
                ></th>
                <th
                  colSpan="4"
                  style={{ borderRightColor: "transparent" }}
                ></th>
                <th
                  colSpan="11"
                  style={{
                    borderBottomColor: "transparent",
                    borderRightColor: "transparent",
                  }}
                ></th>
                <th colSpan="2" style={{ borderRightColor: "transparent" }}>
                  YR
                </th>
                <th colSpan="2" style={{ borderRightColor: "transparent" }}>
                  MO
                </th>
                <th colSpan="2">DAY</th>
              </tr>
              <tr>
                <th colSpan="5" style={{ borderTopColor: "transparent" }}>
                  ENTRANCE
                </th>
                <th colSpan="4" style={{ borderTop: "1px solid" }}></th>
                <th colSpan="7">CLEARANCE</th>
                <th colSpan="4"></th>
                <th colSpan="11">DATE</th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
              </tr>
              <tr>
                <th
                  colSpan="6"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  <span>a. Vessel Name</span>
                </th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th
                  colSpan="8"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  b. GRT
                </th>

                <th
                  colSpan="8"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  c. NRT
                </th>

                <th
                  colSpan="8"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  d. VOYAGE No.
                </th>

                <th
                  colSpan="6"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  e. PORT OF ORIGIN
                </th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th
                  colSpan="6"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  f. PORT OF DESTINATION
                </th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th
                  colSpan="11"
                  className="tal"
                  style={{ borderBottom: "none" }}
                >
                  g. DATE OF ENTRY AT PORT
                </th>

                <th
                  colSpan="5"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  h. CONTROL
                </th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th
                  colSpan="8"
                  className="tal"
                  style={{ borderBottomColor: "transparent" }}
                >
                  i. REGISTRY No.
                </th>
              </tr>
              <tr>
                <th colSpan="12">{pc.vessel_name}</th>

                <th colSpan="8">{pc.grt}</th>

                <th colSpan="8">{pc.nrt}</th>

                <th colSpan="8">{pc.voyage_number}</th>

                <th colSpan="12">{pc.port_of_origin}</th>

                <th colSpan="12">{pc.port_of_destination}</th>

                <th colSpan="5" style={{ borderTopColor: "transparent" }}></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>
                <th colSpan="1"></th>

                <th colSpan="8"></th>

                <th colSpan="8"></th>
              </tr>
              <tr>
                <th rowspan="4" colSpan="2">
                  <div> No.</div>
                  <div>(1)</div>
                </th>
                <th rowspan="4" colSpan="4">
                  CONTAINER No. CARGO MARKS
                  <div>(2)</div>
                </th>
                <th rowspan="1" colSpan="9">
                  CONTAINER
                </th>
                <th rowspan="1" colSpan="6">
                  PACKAGE
                </th>
                <th rowspan="4" colSpan="6">
                  CONTENTS
                  <div>(8)</div>
                </th>
                <th rowspan="1" colSpan="7">
                  -
                </th>
                <th rowspan="4" colSpan="4">
                  VOLUME <br />
                  (Cubic) <br />
                  (9)
                </th>
                <th rowspan="4" colSpan="4">
                  WEIGHT <br />
                  (KILOS) <br />
                  (10)
                </th>
                <th rowspan="4" colSpan="4">
                  VALUE (PESOS) <br />
                  (11)
                </th>
                <th rowspan="4" colSpan="6">
                  SHIPPER <br />
                  (12)
                </th>
                <th rowspan="4" colSpan="6">
                  CONSIGNEE <br />
                  (13)
                </th>
                <th rowspan="1" colSpan="14">
                  FREIGHT CHARGES
                </th>
                <th rowspan="4" colSpan="4">
                  AUXI <br />
                  LIARY <br />
                  CHARGES <br />
                  (19)
                </th>
                <th rowspan="4" colSpan="3">
                  STAMP <br />
                  (20)
                </th>
                <th rowspan="4" colSpan="3">
                  OR NO. <br />
                  (21)
                </th>
                <th rowspan="4" colSpan="5">
                  REMARKS <br />
                  (22)
                </th>
              </tr>
              <tr>
                <th rowspan="3" colSpan="3">
                  CON <br />
                  TAI <br />
                  NER <br />
                  SIZE <br />
                  (PT)
                  <div>(3)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  SERVICE
                  <div>(4)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  SEALING
                  <div>(5)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  NO.
                  <div>(6)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  NRD
                  <div>(7)</div>
                </th>
                <th rowspan="3" colSpan="3">
                  TYPE <br />
                  OF <br />
                  HAND <br />
                  LING <br />
                  CODE
                  <div>(8)</div>
                </th>
                <th rowspan="3" colSpan="4">
                  COMMO
                  <div>DITY</div>
                  <div>CODE</div>
                  <div>(9)</div>
                </th>
                <th rowspan="3" colSpan="4">
                  PREPAID <br />
                  AT <br />
                  ORIGIN <br />
                  (16)
                </th>
                <th rowspan="3" colSpan="5">
                  ACCOUNTS <br />
                  RECEIVABLE <br />
                  AT ORIGIN <br />
                  (17)
                </th>
                <th rowspan="3" colSpan="5">
                  PAYABLE <br />
                  AT <br />
                  DESTINATION <br />
                  (18)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>{" "}
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="6"></td>
                <td colspan="3"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="4"></td>
                <td colspan="6"></td>
                <td colspan="6"></td>
                <td colspan="4"></td>
                <td colspan="5"></td>
                <td colspan="5"></td>
                <td colspan="4"></td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td colspan="5"></td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

class PrintCoastingManifest extends Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <Button
              className="btn-sm"
              color="primary"
              style={{ margin: "0 0.5em" }}
            >
              PRINT
            </Button>
          )}
          content={() => this.componentRef}
        />

        {/* <ReactToPrint
          trigger={() => (
            <Button className="btn-sm" color="primary">
              PRINT 2
            </Button>
          )}
          content={() => this.componentRef2}
        /> */}

        <ComponentToPrint
          getID={this.props.ID}
          ref={(el) => (this.componentRef = el)}
        />

        <ComponentToPrint2
          getID={this.props.ID}
          ref={(el) => (this.componentRef2 = el)}
        />
      </div>
    );
  }
}

export default withRouter(PrintCoastingManifest);
