import React, { Component } from "react";
import { Form, FormGroup, Label, Col, Input, Table, Button } from "reactstrap";
import { Icon } from "react-fa";
import axios from "axios";
import { DropdownList } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";
import Navbar from "../../../Navbar/Navbar";
import "./VesselDetailForm.scss";
import { API_URL } from "../../../../config.js";
import constants from "../../../../constants.js";

import api from "../../../../helpers/api.helper";

export default class VesselDetailForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "Ship Particulars",
      showAudit: false,
      vesselData: {},
      __data: {},
      _vesselType: [],
      _classification: [],
      _vesselNames: [],
      _countries: [],
      _v_images: [],

      fbRows: [
        {
          zone: "",
          freeboard: "",
          draft: "",
          displacement: "",
          deadweight: "",
        },
      ],

      holdCapRows: [
        {
          product: "",
          cargo_hold: "",
          qty: "",
        },
      ],

      holdCapQty: [
        {
          qty: "",
          cargo_hold: "",
        },
      ],

      regOwnerPIC: [
        {
          pic: "",
          tel_no: "",
          email: "",
        },
      ],

      chartPIC: [
        {
          pic: "",
          tel_no: "",
          email: "",
        },
      ],
      vesselID: "",
      ERRLIST: {
        vName: "",
      },
    };
  }

  componentDidMount() {
    this.loadData();
    this.loadSelectData();
    this.loadCountry();
  }

  loadData = () => {
    if (this.props.match.params.id !== undefined) {
      api
        .get(this, { endpoint: "vessel/" + this.props.match.params.id })
        .then((data) => {
          this.setState({
            ownerPicArr: data.owner_pic,
            chartPIC: data.charterer_pic,
            freeboardDraft: data.freeboard_draft,
            images: data.images,
          });
        });
    }
  };

  loadCountry = () => {
    let country_names = [];
    let countries = constants.__countries;

    for (let x in countries) {
      let country = countries[x];

      country_names[x] = country.name;
    }

    console.log(country_names);

    this.setState({
      _countries: country_names,
    });
  };

  loadSelectData = () => {
    this.setState({
      _vesselType: constants.__vesselType,
      _classification: constants.__classification,
      // _countries_hash: constants.__countries_hash
    });
  };

  gotoList = () => {
    this.props.history.push("/maintenance/vessels");
  };

  addEditVessel = (e) => {
    // wandrea.save(this, { endpoint: "vessel" });

    let { __data, ERRLIST } = this.state;

    if (!__data.vessel_name) {
      ERRLIST.vName = "Vessel name is required.";

      this.setState({
        ERRLIST,
      });
    } else {
      if (this.props.match.params.id !== undefined) {
        api
          .update(this, {
            endpoint: "vessel/" + this.props.match.params.id,
            _data: this.state.__data,
          })
          .then((data) => {
            this.props.history.push(
              "/maintenance/vessel/view/" + this.props.match.params.id
            );
          });
      } else {
        api
          .save(this, { endpoint: "vessel", _data: this.state.__data })
          .then((data) => {
            this.props.history.push("/maintenance/vessel/view/" + data);
          });
      }
    }
  };

  deleteVessel = () => {
    axios
      .delete(
        API_URL + "vessel/" + this.props.match.params.id,
        this.state.vesselData
      )
      .then((res) => {
        this.loadData();
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  updateFbRows = (val, index, key) => {
    const { fbRows } = this.state;
    fbRows[index][key] = val;
    this.setState({ fbRows });
    console.log(fbRows);
  };

  addFBRow = () => {
    const currFBrows = this.state.fbRows;
    const addObj_FB = {
      zone: "",
      freeboard: "",
      draft: "",
      displacement: "",
      deadweight: "",
    };
    this.setState({
      fbRows: [...currFBrows, addObj_FB],
    });

    console.log(this.state.fbRows);
  };

  rmvFBRow = (i) => {
    const currFBrows = this.state.fbRows;

    currFBrows.splice(i, 1);
    this.setState({
      fbRows: currFBrows,
    });

    console.log(i, currFBrows);
  };

  // Hold Capacity Qty
  update_HCqty = (val, index, key) => {
    const { holdCapQty } = this.state;
    holdCapQty[index][key] = val;
    this.setState({ holdCapQty });
    console.log(holdCapQty);
  };

  add_HCqty = (i) => {
    const currHCqty = this.state.holdCapQty;
    const addObj_HCqty = {
      qty: "",
      cargo_hold: "",
    };
    this.setState({
      holdCapQty: [...currHCqty, addObj_HCqty],
    });

    console.log(this.state.holdCapQty);
  };

  rmv_HCqty = (i) => {
    const currHCqty = this.state.holdCapQty;

    currHCqty.splice(i, 1);
    this.setState({
      holdCapQty: currHCqty,
    });

    console.log(i, currHCqty);
  };

  // Hold Capacity Rows
  update_HCrow = (val, index, key) => {
    const { holdCapRows } = this.state;
    holdCapRows[index][key] = val;
    this.setState({ holdCapRows });
    console.log(holdCapRows);
  };

  add_HCrow = () => {
    const currHCrow = this.state.holdCapRows;
    const addObj = {
      product: "",
      cargo_hold: "",
      qty: "",
    };
    this.setState({
      holdCapRows: [...currHCrow, addObj],
    });

    console.log(this.state.holdCapRows);
  };

  rmv_HCrow = (i) => {
    const currHCrow = this.state.holdCapRows;

    currHCrow.splice(i, 1);
    this.setState({
      holdCapRows: currHCrow,
    });

    console.log(i, currHCrow);
  };

  // Registered Owner PIC
  update_regOwnerPIC = (val, index, key) => {
    const { regOwnerPIC } = this.state;
    regOwnerPIC[index][key] = val;
    this.setState({ regOwnerPIC });
    console.log(regOwnerPIC);
  };

  add_regOwnerPIC = () => {
    const cRegOwnerPIC = this.state.regOwnerPIC;
    const addObj = {
      pic: "",
      tel_no: "",
      email: "",
    };
    this.setState({
      regOwnerPIC: [...cRegOwnerPIC, addObj],
    });

    console.log(this.state.update_regOwnerPIC);
  };

  rmv_regOwnerPIC = (i) => {
    const cRegOwnerPIC = this.state.regOwnerPIC;

    cRegOwnerPIC.splice(i, 1);
    this.setState({
      regOwnerPIC: cRegOwnerPIC,
    });

    console.log(i, cRegOwnerPIC);
  };

  // Charterer PIC rows
  update_chartPIC = (val, index, key) => {
    const { chartPIC } = this.state;
    chartPIC[index][key] = val;
    this.setState({ chartPIC });
    console.log(chartPIC);
  };

  add_chartPIC = () => {
    const currChartPIC = this.state.chartPIC;
    const addObj = {
      pic: "",
      tel_no: "",
      email: "",
    };
    this.setState({
      chartPIC: [...currChartPIC, addObj],
    });
  };

  rmv_chartPIC = (i) => {
    const currChartPIC = this.state.chartPIC;

    currChartPIC.splice(i, 1);
    this.setState({
      chartPIC: currChartPIC,
    });
  };
  // end of Charterer PIC rows

  handleAudit = () => {
    this.setState((prevState) => ({
      showAudit: !prevState.showAudit,
    }));
  };

  render() {
    const {
      showAudit,
      fbRows,
      holdCapRows,
      holdCapQty,
      regOwnerPIC,
      chartPIC,
      _vesselType,
      _classification,
      _countries,
      ERRLIST,
    } = this.state;

    const vType = this.state.__data.type;
    const vClass = this.state.__data.classification;
    const vPor = this.state.__data.port_of_registry;
    const vessel_id = this.props.match.params.id;

    return (
      <div className="VesselDetailForm">
        <div className="flex-column">
          <Navbar className="no-shrink" />
          <Form
            className="d-flex flex-column"
            style={{ padding: "5px 20px 4em 20px" }}
          >
            <div className="form-container-box bt-green w100per" style={{}}>
              <div className="col-md-12 flex-column d-flex p-0 shipParticulars align-items-start">
                <div className="fcb-title">
                  <span className="cur-p toVesselList" onClick={this.gotoList}>
                    Vessels
                  </span>
                  <span>&nbsp;> Ship Particulars</span>
                  {!vessel_id ? (
                    <span>
                      {" "}
                      > <span className="fw600">Create</span>{" "}
                    </span>
                  ) : (
                    <span>
                      {" "}
                      > {this.state.__data.vessel_name} >
                      <span className="fw600"> Edit</span>
                    </span>
                  )}
                </div>

                <div className="d-flex flex-row col-md-12 p-0 mt-4">
                  <div className="col-md-4">
                    {/* { _v_images === 0 ? () => {
                       _v_images.map((img) => (
                        <img key={img} src={img} alt=""></img>
                       ))
                    } : (
                      <div className="imageView">
                        <Icon name="image" />
                        <span className="upText">Upload Image</span>
                      </div>
                    )
                    } */}
                    <img src={this.state.__data.images} alt="ship" />
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <FormGroup className="mb-5">
                      <Label for="">MMSI Number</Label>
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                        tabIndex="1"
                      />
                    </FormGroup>
                    <FormGroup className="mb-5">
                      <Label for="exampleSelect">Flag & Port of Registry</Label>
                      {/* <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        className="form-control-sm"
                        tabIndex="3"
                        value={this.state.__data.port_of_registry}
                        onChange={e => {
                          let __data = this.state.__data;
                          let _target = e.target;
                          let index = _target.options[
                            _target.selectedIndex
                          ].getAttribute("country-key");
                          __data.country_code = _countries[index].code;
                          __data.flag = _countries[index].emoji;
                          __data.port_of_registry = _target.value;
                          this.setState({ __data });
                        }}
                      >
                        {_countries.map((c, index) => (
                          <option
                            key={index}
                            value={c.name}
                            country-key={index}
                          >
                            {c.emoji + " - " + c.name}
                          </option>
                          // console.log(c.name)
                        ))}
                      </Input> */}
                      <DropdownList
                        filter
                        value={vPor}
                        data={_countries}
                        onChange={(vPor) => {
                          let __data = this.state.__data;

                          __data.port_of_registry = vPor;

                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-5">
                      <Label for="exampleSelect">
                        Classification / Class No.
                      </Label>
                      {/* <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        className="form-control-sm"
                        tabIndex="5"
                        value={this.state.__data.classification}
                        onChange={e => {
                          let __data = this.state.__data;

                          __data.classification = e.target.value;

                          this.setState({ __data });
                        }}
                      >
                        {_classification.map(i => (
                          <option key={i} value={i}>
                            {i}
                          </option>
                        ))}
                      </Input> */}

                      <DropdownList
                        filter
                        value={vClass}
                        data={_classification}
                        onChange={(vClass) => {
                          let __data = this.state.__data;

                          __data.classification = vClass;

                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <FormGroup
                      className={ERRLIST.vName ? "mb-5 required-field" : "mb-5"}
                    >
                      <Label for="">Vessel Name</Label>
                      <Input
                        data-cy="vessel_name"
                        className="form-control-sm"
                        type="text"
                        name="name"
                        id=""
                        placeholder=""
                        tabIndex="2"
                        value={this.state.__data.vessel_name}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.vessel_name = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                      <span className="error-msg">{ERRLIST.vName}</span>
                    </FormGroup>

                    <FormGroup className="mb-5">
                      <Label for="exampleSelect">Type of Vessel</Label>
                      {/* <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        className="form-control-sm"
                        tabIndex="4"
                        value={this.state.__data.type}
                        onChange={e => {
                          let __data = this.state.__data;

                          __data.type = e.target.value;

                          this.setState({ __data });
                        }}
                      >
                        {_vesselType.map(i => (
                          <option key={i} value={i}>
                            {i}
                          </option>
                        ))}
                      </Input> */}
                      <DropdownList
                        filter
                        value={vType}
                        data={_vesselType}
                        onChange={(vType) => {
                          let __data = this.state.__data;

                          __data.type = vType;

                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>

                    <FormGroup className="mb-5">
                      <Label for="exampleFile">P & I Club</Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleFile"
                        tabIndex="6"
                        value={this.state.__data.p_and_i_club}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.p_and_i_club = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleFile">Upload Image</Label>
                    <Input
                      type="file"
                      name="file"
                      id="exampleFile"
                      // value={this.state.__data.images}
                      // onChange={e => {
                      //   let __data = this.state.__data;

                      //   __data.images = e.target.value;
                      //   this.setState({ __data });
                      // }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleFile">
                      Hull & Macinery Underwriters
                    </Label>
                    <Input
                      type="number"
                      name="text"
                      id="exampleFile"
                      tabIndex="7"
                      value={this.state.__data.hull_and_machinery_underwriters}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.hull_and_machinery_underwriters = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleFile">Builder's Name & Place</Label>
                    <Input
                      type="text"
                      name="text"
                      id="exampleFile"
                      tabIndex="8"
                      value={this.state.__data.builders_name_and_place}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.builders_name_and_place = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleFile">Hull Number / Type</Label>
                    <Input
                      type="number"
                      name="number"
                      id="exampleFile"
                      tabIndex="9"
                      value={this.state.__data.hull_number}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.hull_number = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleFile">Date of Keel Laid</Label>
                    <Input
                      data-cy="keel_laid_date"
                      className="form-control-sm"
                      type="date"
                      name="date"
                      id="exampleDate"
                      placeholder="date placeholder"
                      tabIndex="10"
                      value={this.state.__data.date_of_keel_laid}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.date_of_keel_laid = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleDate">Date of Delivery</Label>
                    <Input
                      className="form-control-sm"
                      type="date"
                      name="date"
                      id="exampleDate"
                      placeholder="date placeholder"
                      tabIndex="11"
                      value={this.state.__data.date_of_delivery}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.date_of_delivery = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Main Diesel Engine / Number</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="12"
                      value={this.state.__data.main_diesel_engine}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.main_diesel_engine = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Output Maximum Rating</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="13"
                      value={this.state.__data.output_maximum_rating}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.output_maximum_rating = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Output Normal Rating</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="14"
                      value={this.state.__data.output_normal_rating}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.output_normal_rating = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">
                      Gen. Engine Maker / Type x Output / No.
                    </Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="15"
                      value={this.state.__data.gen_engine_maker}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.gen_engine_maker = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Auxilliary Boiler Type / Number</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="16"
                      value={this.state.__data.aux_boiler_type}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.aux_boiler_type = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Service Speed</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="17"
                      value={this.state.__data.service_speed}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.service_speed = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">F.O. / D.O.</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="18"
                      value={this.state.__data.fo_do}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.fo_do = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">DWT</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      step="0.1"
                      placeholder=""
                      tabIndex="19"
                      value={this.state.__data.dwt}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.dwt = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4 d-flex flex-row p-0">
                    <div
                      className="col-md-6"
                      style={{ padding: "0 5px 0 15px" }}
                    >
                      <Label for="">Water Ballast</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                        tabIndex="20"
                        value={this.state.__data.no_of_tanks}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.no_of_tanks = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                    </div>
                    <div
                      className="col-md-6"
                      style={{ padding: "0 15px 0 5px" }}
                    >
                      <Label for="">No. of Tanks</Label>
                      <Input
                        className="form-control-sm"
                        type="number"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                        tabIndex="20"
                        value={this.state.__data.water_ballast}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.water_ballast = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                    </div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Moulded Depth</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="21"
                      value={this.state.__data.moulded_depth}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.moulded_depth = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Draft</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="22"
                      value={this.state.__data.draft}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.draft = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Dist. from Bridge to Bow</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="23"
                      value={this.state.__data.dist_from_bridge_to_bow}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.dist_from_bridge_to_bow = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Dist. from Bridge to Aft</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="24"
                      value={this.state.__data.dist_from_bridge_to_aft}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.dist_from_bridge_to_aft = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Official Number</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="25"
                      value={this.state.__data.official_number}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.official_number = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">IMO Number</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="26"
                      value={this.state.__data.imo_number}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.imo_number = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Call Sign</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="call_sign"
                      id=""
                      placeholder=""
                      tabIndex="27"
                      value={this.state.__data.call_sign}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.call_sign = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">LOA</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="27"
                      value={this.state.__data.loa}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.loa = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Beam</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="28"
                      value={this.state.__data.beam}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.beam = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">GRT</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="29"
                      value={this.state.__data.grt}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.grt = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">NRT</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="30"
                      value={this.state.__data.nrt}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.nrt = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Suez Canal Gross Tonnage</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="31"
                      value={this.state.__data.suez_canal_gt}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.suez_canal_gt = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Suez Canal Net Tonnage</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="32"
                      value={this.state.__data.suez_canal_nt}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.suez_canal_nt = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Lightship</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="33"
                      value={this.state.__data.lightship}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.lightship = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Summer TPC</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="34"
                      value={this.state.__data.summer_tpc}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.summer_tpc = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">FWA</Label>
                    <Input
                      className="form-control-sm"
                      type="text"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="35"
                      value={this.state.__data.fwa}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.fwa = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Suez Canal I.D. No.</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="36"
                      value={this.state.__data.suez_canal_id}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.suez_canal_id = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Canal I.D. No.</Label>
                    <Input
                      className="form-control-sm"
                      type="number"
                      name="mmsi_no"
                      id=""
                      placeholder=""
                      tabIndex="37"
                      value={this.state.__data.us_canal_id}
                      onChange={(e) => {
                        let __data = this.state.__data;

                        __data.us_canal_id = e.target.value;
                        this.setState({ __data });
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="fcb-title mt-4">
                  Owner / Charterer / Ship Management Company
                </div>

                <div className="col-md-12 p-0 d-flex flex-row mt-4 ocs-section">
                  <div className="col-md-4 d-flex flex-column">
                    <div className="d-flex flex-column ocs-column">
                      <FormGroup className="mb-5">
                        <Label for="" className="label-lg">
                          Registered Owner
                        </Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="38"
                          value={this.state.__data.registered_owner}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.registered_owner = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Address</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="39"
                          value={this.state.__data.owner_address}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.owner_address = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Telephone No.</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="40"
                          value={this.state.__data.owner_telephone_no}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.owner_telephone_no = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Fax No.</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="41"
                          value={this.state.__data.owner_fax_no}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.owner_fax_no = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Email</Label>
                        <Input
                          className="form-control-sm"
                          type="email"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="42"
                          value={this.state.__data.owner_email}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.owner_email = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </div>

                    {regOwnerPIC.map((ro_pic, index) => {
                      return (
                        <div key={index} className="d-flex flex-column ocs-pic">
                          <FormGroup className="mb-5">
                            <Label for="">PIC</Label>
                            <Input
                              className="form-control-sm"
                              type="text"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              tabIndex="43"
                              value={ro_pic.name}
                              onChange={(e) => {
                                let __data = this.state.__data;
                                regOwnerPIC[index].name = e.target.value;
                                __data.regOwnerPIC = regOwnerPIC;
                                this.setState({ __data });
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="">Telephone No.</Label>
                            <Input
                              className="form-control-sm"
                              type="text"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              tabIndex="44"
                              value={ro_pic.telephone}
                              onChange={(e) => {
                                let __data = this.state.__data;
                                regOwnerPIC[index].telephone = e.target.value;
                                __data.regOwnerPIC = regOwnerPIC;
                                this.setState({ __data });
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="">Email</Label>
                            <Input
                              className="form-control-sm"
                              type="email"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              tabIndex="45"
                              value={ro_pic.email}
                              onChange={(e) => {
                                let __data = this.state.__data;
                                regOwnerPIC[index].email = e.target.value;
                                __data.regOwnerPIC = regOwnerPIC;
                                this.setState({ __data });
                              }}
                            />
                          </FormGroup>
                          <div className="d-flex flex-row justify-content-center">
                            {regOwnerPIC.length === 1 ? (
                              <Icon
                                name="plus"
                                onClick={this.add_regOwnerPIC}
                              />
                            ) : regOwnerPIC.length === index + 1 ? (
                              <div className="d-flex flex-row">
                                <Icon
                                  name="minus"
                                  onClick={this.rmv_regOwnerPIC}
                                />
                                <Icon
                                  name="plus"
                                  onClick={this.add_regOwnerPIC}
                                />
                              </div>
                            ) : (
                              <Icon
                                name="minus"
                                onClick={this.rmv_regOwnerPIC}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {/* <div className="divider"></div> */}
                    {/* <div className="divider"></div> */}
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <div className="d-flex flex-column ocs-column">
                      <FormGroup className="mb-5">
                        <Label for="" className="label-lg">
                          Charterer
                        </Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="46"
                          value={this.state.__data.charterer}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.charterer = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Address</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="47"
                          value={this.state.__data.charterer_email}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.charterer_email = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Telephone No.</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="48"
                          value={this.state.__data.charterer_telephone_no}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.charterer_telephone_no = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Fax No.</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="49"
                          value={this.state.__data.charterer_fax_no}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.charterer_fax_no = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Email</Label>
                        <Input
                          className="form-control-sm"
                          type="email"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="50"
                          value={this.state.__data.charterer_email}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.charterer_email = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </div>

                    {chartPIC.map((c_pic, index) => {
                      return (
                        <div key={index} className="d-flex flex-column ocs-pic">
                          <FormGroup className="mb-5">
                            <Label for="">PIC</Label>
                            <Input
                              className="form-control-sm"
                              type="text"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              tabIndex="51"
                            />
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="">Telephone No.</Label>
                            <Input
                              className="form-control-sm"
                              type="text"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              tabIndex="52"
                            />
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="">Email</Label>
                            <Input
                              className="form-control-sm"
                              type="email"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              tabIndex="53"
                            />
                          </FormGroup>

                          <div className="d-flex flex-row justify-content-center">
                            {chartPIC.length === 1 ? (
                              <Icon name="plus" onClick={this.add_chartPIC} />
                            ) : chartPIC.length === index + 1 ? (
                              <div className="d-flex flex-row">
                                <Icon
                                  name="minus"
                                  onClick={() => this.rmv_chartPIC(index)}
                                />
                                <Icon name="plus" onClick={this.add_chartPIC} />
                              </div>
                            ) : (
                              <Icon
                                name="minus"
                                onClick={() => this.rmv_chartPIC(index)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {/* <div className="divider"></div> */}
                    {/* <div className="divider"></div> */}
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <div className="d-flex flex-column ocs-column">
                      <FormGroup className="mb-5">
                        <Label for="" className="label-lg">
                          Ship Management Company
                        </Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="54"
                          value={this.state.__data.ship_management_company}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.ship_management_company = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">IMO Company ID</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="55"
                          value={this.state.__data.imo_company_no}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.imo_company_no = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Address</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="56"
                          value={this.state.__data.ship_management_address}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.ship_management_address = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Telephone No.</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="57"
                          value={this.state.__data.ship_management_telephone_no}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.ship_management_telephone_no =
                              e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Fax No.</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="58"
                          value={this.state.__data.ship_management_fax_no}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.ship_management_fax_no = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Email</Label>
                        <Input
                          className="form-control-sm"
                          type="email"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="59"
                          value={this.state.__data.ship_management_email}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.ship_management_email = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>

                <div className="fcb-title mt-4">
                  Freeboard / Draft / Displacement / Deadweight
                </div>

                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "20px" }}
                >
                  <thead>
                    <tr>
                      <th>Zone</th>
                      <th>Freeboard</th>
                      <th>Draft</th>
                      <th>Displacement</th>
                      <th>Deadweight</th>
                      <th width="3%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fbRows.map((fDraft, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <FormGroup className="mb-0">
                              <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                                className="form-control-sm"
                              >
                                <option>-</option>
                              </Input>
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className="mb-0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="freeboard"
                                id=""
                                placeholder=""
                                value={fDraft.freeboard}
                                onChange={(e) => {
                                  let __data = this.state.__data;
                                  fbRows[index].freeboard = e.target.value;
                                  __data.fbRows = fbRows;
                                  this.setState({ __data });
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className="mb-0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                id=""
                                placeholder=""
                                value={fDraft.draft}
                                onChange={(e) => {
                                  let __data = this.state.__data;
                                  fbRows[index].draft = e.target.value;
                                  __data.fbRows = fbRows;
                                  this.setState({ __data });
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className="mb-0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                id=""
                                placeholder=""
                                value={fDraft.displacement}
                                onChange={(e) => {
                                  let __data = this.state.__data;
                                  fbRows[index].displacement = e.target.value;
                                  __data.fbRows = fbRows;
                                  this.setState({ __data });
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className="mb-0">
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                id=""
                                placeholder=""
                                value={fDraft.deadweight}
                                onChange={(e) => {
                                  let __data = this.state.__data;
                                  fbRows[index].deadweight = e.target.value;
                                  __data.fbRows = fbRows;
                                  this.setState({ __data });
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            {fbRows.length === 1 ? (
                              <div
                                className="tblActions tblAdd"
                                onClick={this.addFBRow}
                              >
                                <Icon name="plus" />
                              </div>
                            ) : fbRows.length === index + 1 ? (
                              <div className="d-flex flex-row">
                                <div
                                  style={{ marginRight: "0.5em" }}
                                  className="tblActions tblRemove"
                                  onClick={() => this.rmvFBRow(index)}
                                >
                                  <Icon name="minus" />
                                </div>
                                <div
                                  className="tblActions tblAdd"
                                  onClick={this.addFBRow}
                                >
                                  <Icon name="plus" />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="tblActions tblRemove"
                                onClick={() => this.rmvFBRow(index)}
                              >
                                <Icon name="minus" />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <div className="fcb-title mt-4">Hold Capacity</div>
                <div className="col-md-12 d-flex flex-column holdCap">
                  <div className="col-md-12 d-flex flex-row mt-3 p-0">
                    <div className="col-md-4 d-flex flex-column">
                      <p className="title">Product</p>
                    </div>
                    <div className="col-md-2 d-flex flex-column">
                      <p className="title">Cargo Hold</p>
                    </div>
                    <div className="col-md-2 d-flex flex-column">
                      <p className="d-flex flex-row title">
                        Quantity(m<sup style={{ top: "0.80em" }}>3</sup>)
                      </p>
                    </div>
                    <div className="col-md-2 d-flex flex-column"></div>
                    <div className="col-md-2 d-flex flex-column">
                      {/* <Button
                        className="btn-sm"
                        color="primary"
                        onClick={this.add_HCrow}
                      >
                        Add Product
                      </Button> */}
                    </div>
                  </div>

                  {holdCapRows.map((hcRow, index) => {
                    return (
                      <div
                        key={index}
                        className="col-md-12 d-flex flex-row mt-3 p-0"
                      >
                        <Icon name="plus" />
                        <div className="col-md-4 d-flex flex-column">
                          <FormGroup className="mb-0">
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              className="form-control-sm"
                              value={hcRow.product}
                              onChange={(e) =>
                                this.update_HCqty(
                                  e.target.value,
                                  index,
                                  "product"
                                )
                              }
                            >
                              <option>Product 1</option>
                              <option>Product 2</option>
                            </Input>
                          </FormGroup>
                        </div>

                        <div className="d-flex flex-column col-md-12 p-0">
                          {holdCapQty.map((hcQty, subIndex) => {
                            return (
                              <div
                                key={subIndex}
                                className="d-flex flex-row"
                                style={{ marginBottom: "0.5em" }}
                              >
                                <div className="col-md-2 d-flex flex-column">
                                  <p className="fs13">CH #1</p>
                                </div>
                                <div className="col-md-3 d-flex flex-column">
                                  <FormGroup className="mb-0">
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="hCap_qty"
                                      id=""
                                      placeholder=""
                                      value={hcQty.qty}
                                      onChange={(e) =>
                                        this.update_HCqty(
                                          e.target.value,
                                          subIndex,
                                          "qty"
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </div>

                                <div
                                  className="col-md-4 d-flex flex-column"
                                  style={{ paddingTop: "0.25em" }}
                                >
                                  {holdCapQty.length === 1 ? (
                                    <div
                                      className="tblActions tblAdd"
                                      onClick={() => this.add_HCqty(subIndex)}
                                    >
                                      <Icon name="plus" />
                                    </div>
                                  ) : holdCapQty.length === subIndex + 1 ? (
                                    <div className="d-flex flex-row">
                                      <div
                                        style={{ marginRight: "0.5em" }}
                                        className="tblActions tblRemove"
                                        onClick={() => this.rmv_HCqty(subIndex)}
                                      >
                                        <Icon name="minus" />
                                      </div>
                                      <div
                                        className="tblActions tblAdd"
                                        onClick={() => this.add_HCqty(subIndex)}
                                      >
                                        <Icon name="plus" />
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="tblActions tblRemove"
                                      onClick={() => this.rmv_HCqty(subIndex)}
                                    >
                                      <Icon name="minus" />
                                    </div>
                                  )}
                                </div>

                                <div className="col-md-5 d-flex flex-column"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="col-md-12 d-flex flex-row mt-4">
                  <div className="col-md-8 fcb-title mt-4 p-0">Cargo Gear</div>
                  <div className="col-md-4 fcb-title mt-4 p-0">
                    Ship's Communication
                  </div>
                </div>

                <div className="col-md-12 d-flex flex-row mt-3">
                  <div className="col-md-8 p-0 d-flex flex-column">
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Deck Crane No.</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="60"
                          value={this.state.__data.deck_crane_no}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.deck_crane_no = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Slewing Angle</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="61"
                          value={this.state.__data.slewing_angle}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.slewing_angle = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Type & Manufacturer</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="62"
                          value={this.state.__data.type_and_manufacturer}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.type_and_manufacturer = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Max. Outreach from Ship's Side</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="63"
                          value={this.state.__data.outreach_from_ships_side}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.outreach_from_ships_side = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Hoisting Speed (Hook/Grab)</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="64"
                          value={this.state.__data.hoisting_speed}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.hoisting_speed = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Grab Bucket - Type / Number</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="65"
                          value={this.state.__data.grab_bucket_type}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.grab_bucket_type = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Lowering Speed (at rated load)</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="66"
                          value={this.state.__data.lowering_speed}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.lowering_speed = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Grab Cap. / Normal Out/ Input Source</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="67"
                          value={this.state.__data.grab_cap}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.grab_cap = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </div>
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Winding Height</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="68"
                          value={this.state.__data.winding_height}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.winding_height = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Lifting Capacity / Empty Wt.</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          tabIndex="69"
                          value={this.state.__data.lifting_capacity}
                          onChange={(e) => {
                            let __data = this.state.__data;

                            __data.lifting_capacity = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">INM FBB Tel.</Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleFile"
                        className="sm-input"
                        tabIndex="70"
                        value={this.state.__data.inm_fbb_tel}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.inm_fbb_tel = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">Iridium Tel.</Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleFile"
                        className="sm-input"
                        tabIndex="71"
                        value={this.state.__data.iridium_tel}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.iridium_tel = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">INM Fax</Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleFile"
                        className="sm-input"
                        tabIndex="72"
                        value={this.state.__data.inm_fax}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.inm_fax = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">INM C Telex</Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleFile"
                        className="sm-input"
                        tabIndex="73"
                        value={this.state.__data.inm_c_telefax}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.inm_c_telefax = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">Email</Label>
                      <Input
                        type="email"
                        name="text"
                        id="exampleFile"
                        className="sm-input"
                        tabIndex="74"
                        value={this.state.__data.ship_com_email}
                        onChange={(e) => {
                          let __data = this.state.__data;

                          __data.ship_com_email = e.target.value;
                          this.setState({ __data });
                        }}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>

            <Col col={{ size: 12 }} className="d-flex flex-row p-0 mt-3">
              <div className="form-container-box bt-green col-md-12">
                <div className="col-md-12 d-flex flex-row auditHead">
                  {showAudit ? (
                    <Icon
                      className=""
                      name="angle-up"
                      onClick={this.handleAudit}
                    />
                  ) : (
                    <Icon
                      className=""
                      name="angle-down"
                      onClick={this.handleAudit}
                    />
                  )}

                  <p>Audit Trails</p>
                </div>

                <div>
                  {showAudit ? (
                    <Col md={{ size: 12 }} className="p-0">
                      <Table
                        className="custom-table"
                        bordered
                        style={{ marginTop: "20px" }}
                      >
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Details</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </Col>

            <div className="col-md-12 footer-actions d-flex flex-row w100per">
              <div className="col-md-6 p-0">
                <Button
                  className="btn-sm"
                  color="danger"
                  style={{ margin: "0 0.5em" }}
                  onClick={this.deleteVessel}
                >
                  DELETE
                </Button>
              </div>
              <div className="col-md-6 d-flex flex-row justify-content-end p-0">
                <Button
                  className="btn-sm"
                  color="danger"
                  style={{ margin: "0 0.5em" }}
                  onClick={() => {
                    if (!vessel_id) {
                      this.props.history.push("/maintenance/vessels");
                    } else {
                      this.props.history.push(
                        "/maintenance/vessel/view/" + this.props.match.params.id
                      );
                    }
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  className="btn-sm"
                  color="primary"
                  onClick={this.addEditVessel}
                  data-cy="vessel_save_btn"
                >
                  SAVE
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
