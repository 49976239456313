import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import Moment from "react-moment";
import { Icon } from "react-fa";
import back_icon from "../../img/svg/BackIcon.svg";
import { withRouter } from "react-router-dom";
import "./Amendments.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import api from "../../helpers/api.helper";
import constants from "../../constants";
import Media from "react-media";
import PageHeader from "../Tools/PageHeader/PageHeader";
import session from "../../session";
import AlertModal from "../Tools/AlertModal/AlertModal";
import Swal from "sweetalert2";

class Amendments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __port_call: {},
      __amendment: [],
      modal_action: "",
      openModal: false,
      amendment_index: null,
      isAlert: "",
      modalMsg: "",
      tglAlert: false,
      FIELDS: [],
    };
  }

  componentDidMount = () => {
    this.loadData();
    console.log("ID", this.state.__ID);
  };

  loadData = () => {
    if (!this.state.__ID) {
    } else {
      api
        .get(this, {
          endpoint: "portcall/" + this.state.__ID,
          state_key: "__port_call",
        })
        .then((data) => {});

      api
        .get(this, {
          endpoint: "amendment",
        })
        .then((data) => {
          // console.log("AMENFSDFD", data);
          let amendments = [];

          for (let x in data) {
            if (data[x].portcall_id === this.state.__ID) {
              amendments[x] = data[x];
            }
          }

          this.setState({
            __amendment: amendments,
          });
        });
    }
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  changeAmendmentStatus = (status_id, status, amendment_id) => {
    console.log(status_id, status, amendment_id);
    if (!status_id || !amendment_id) {
      return Promise.reject({ err: "Incomplete data" });
    }

    let user = localStorage.getItem("port_session");
    if (user) {
      user = JSON.parse(user);
    }

    let approvals = {
      name: user.last_name + ", " + user.first_name,
      user_id: user._id,
      remarks: this.state.remarks,
      status_id,
      status,
    };

    api
      .update(this, {
        endpoint: "amendment/" + amendment_id,
        _data: { status, status_id, approvals },
        state_key: "amendment_approval",
      })
      .then((data) => {
        let { __amendment } = this.state;
        __amendment[this.state.amendment_index].status = this.state.status;
        __amendment[
          this.state.amendment_index
        ].status_id = this.state.status_id;

        this.setState({ __amendment });

        this.closeModal();
        return Promise.resolve(data);
      });
  };

  handleApprove = (amendment_id, index) => {
    if (!amendment_id) {
      return;
    }

    this.setState({
      modal_action: "Approve",
      status: constants.AMENDMENT_STATUS_TEXT_APPROVED,
      status_id: constants.AMENDMENT_STATUS_APPROVED,
      amendment_id,
      amendment_index: index,
    });
    this.toggleModal();
  };

  handleReject = (amendment_id, index) => {
    if (!amendment_id) {
      return;
    }
    this.setState({
      modal_action: "Reject",
      status: constants.AMENDMENT_STATUS_TEXT_REJECTED,
      status_id: constants.AMENDMENT_STATUS_REJECTED,
      amendment_id,
      amendment_index: index,
    });
    this.toggleModal();
  };

  toggleModal = (stat) => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));
  };

  closeModal = () => {
    this.setState((prevState) => ({
      openModal: !prevState.openModal,
    }));
  };

  fieldsFunc = (data) => {
    let fieldsArr = [];
    fieldsArr = [data];

    this.setState({
      FIELDS: fieldsArr,
    });

    // console.log("DATATDTDA", data);
  };

  render() {
    const { __amendment, isAlert, alertMsg } = this.state;
    const vd = this.state.__port_call;

    const FieldsTable = (props) => {
      let arrFields = [];
      let count = 0;

      Object.keys(props.data).forEach((field) => {
        arrFields[count] = {
          display_field: props.data[field].display_field,
          from_value: props.data[field].from_value,
          to_value: props.data[field].to_value,
        };

        count++;
      });

      return (
        <table className="w100per custom-table">
          <tbody>
            {arrFields.map((f, $i) => (
              <tr key={$i}>
                <td width="30%">{f.display_field}</td>
                <td width="30%">{f.from_value}</td>
                <td width="30%">{f.to_value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

    return (
      <div className="Amendments">
        <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        <div className="d-flex flex-column">
          <Navbar />
          {/* <PortCallToolbar /> */}
          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav
                  dataFromParent={this.props.match.params.id}
                  visit={vd}
                />
              ) : (
                <PageHeader
                  hideDocToggle
                  sendVName={vd.vessel_name}
                  sendVoyageNo={vd.voyage_number}
                />
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav
                      dataFromParent={this.props.match.params.id}
                      visit={vd}
                    />
                  </Col>
                ) : (
                  <PageHeader
                    hideDocToggle
                    sendVName={vd.vessel_name}
                    sendVoyageNo={vd.voyage_number}
                    pagename="Amendments"
                  />
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              <div className="form-container-box bt-green">
                <div className="fcb-title">Amendments</div>

                <Media queries={constants.media_queries}>
                  {(matches) =>
                    matches.xs ? (
                      <ul>
                        {__amendment.map((a, i) => (
                          <li key={i}>
                            <Row className="am-author" xs="1">
                              <p>
                                <span>{a.field}</span> amended by{" "}
                                <span>{a.amended_by}</span>.
                              </p>
                              <Moment format="DD-MMM-YYYY HH:mm">
                                {a.created_at || "-"}
                              </Moment>
                            </Row>
                            <Row className="am-details">
                              <Col xs="3">{a.field}</Col>
                              <Col xs="9">
                                <p>
                                  <Moment format="DD-MMM-YYYY HH:mm">
                                    {a.from_value}
                                  </Moment>
                                </p>
                                <p>
                                  <Moment format="DD-MMM-YYYY HH:mm">
                                    {a.to_value}
                                  </Moment>
                                </p>
                              </Col>
                            </Row>
                            <Row className="list-actions">
                              {parseInt(a.status_id) ===
                              parseInt(constants.AMENDMENT_STATUS_PENDING) ? (
                                <>
                                  <div
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleReject(a._id, i);
                                    }}
                                  >
                                    Reject
                                  </div>
                                  <div
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleApprove(a._id, i);
                                    }}
                                  >
                                    Approve
                                  </div>
                                </>
                              ) : (
                                <div className="approved-status">Approved</div>
                              )}
                            </Row>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <Table className="custom-table" bordered>
                        <thead>
                          <tr>
                            <th width="14%">Date & Time</th>
                            <th width="15%">Field/s</th>
                            <th width="15%">From</th>
                            <th width="15%">To</th>
                            <th>Amended by</th>
                            <th>Status</th>
                            {session.get().role === "port_admin" ? (
                              <th width="7%">Action</th>
                            ) : (
                              ""
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {__amendment.map((a, i) => (
                            <tr key={i}>
                              <td>
                                <Moment format="DD-MMM-YYYY HH:mm">
                                  {a.created_at || "-"}
                                </Moment>
                              </td>
                              <td colSpan="3" className="p0">
                                <FieldsTable data={a.fields} />
                              </td>
                              <td>{a.amended_by}</td>
                              <td>{a.status}</td>
                              {session.get().role === "port_admin" ? (
                                <td>
                                  {parseInt(a.status_id) ===
                                  parseInt(
                                    constants.AMENDMENT_STATUS_PENDING
                                  ) ? (
                                    <div className="d-flex flex-row tbl-action-btn">
                                      <Icon
                                        name="check"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleApprove(a._id, i);
                                        }}
                                      />
                                      <Icon
                                        name="times"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleReject(a._id, i);
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )
                  }
                </Media>
              </div>
            </Col>
          </Row>
        </div>

        <Modal
          isOpen={this.state.openModal}
          toggle={this.toggleModal}
          onClosed={this.revertStatus}
          keyboard={false}
          backdrop="static"
          zIndex="2001"
          centered
        >
          <ModalHeader toggle={this.closeModal}>
            {this.state.modal_action} Amendment
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <Form>
              <FormGroup className="mb-5 col-md-12">
                <Label for="">Enter Remarks (Optional)</Label>
                <Input
                  type="textarea"
                  name="mmsi_no"
                  id=""
                  placeholder=""
                  value={this.state.remarks}
                  onChange={(e) => {
                    this.setState({ remarks: e.target.value });
                  }}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="sm-btn-font"
              color="danger"
              onClick={this.closeModal}
            >
              Cancel
            </Button>
            <Button
              className="sm-btn-font"
              color="primary"
              onClick={(e) => {
                this.changeAmendmentStatus(
                  this.state.status_id,
                  this.state.status,
                  this.state.amendment_id
                );

                this.state.status_id === constants.AMENDMENT_STATUS_APPROVED
                  ? this.showAlert("success", "Amendment approved!", true)
                  : this.showAlert("error", "Amendment rejected!", true);

                this.state.status_id === constants.AMENDMENT_STATUS_APPROVED
                  ? Swal.fire({
                      icon: "success",
                      title: "Amendment approved!",
                      showConfirmButton: false,
                      timer: 1500,
                    })
                  : Swal.fire({
                      icon: "error",
                      title: "Amendment rejected!",
                      showConfirmButton: false,
                      timer: 1500,
                    });
              }}
            >
              Save
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Amendments);
