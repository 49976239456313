import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../../../helpers/api.helper";
import constants from "../../../constants";
import moment from "moment";
import "./ArrivalTicker.scss";
import { ReactComponent as BerthMonitoringSVG } from "../svg/Berth Monitoring.svg";
import classnames from "classnames";

class BerthMonitoring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DASHBOARD_TICKER: [],
      BERTH_MONITORING: [],
      berthIdx: 0,
      _BERTHS: [],
      BERTHS: [],
      berthTitle: "",
    };
  }

  componentDidMount() {
    this.loadTicker();
    // this.timeout = setInterval(() => {
    //   let currentIdx = this.state.berthIdx;
    //   this.setState({ berthIdx: currentIdx + 1 });
    // }, 1500);
    this.loadBerths();
  }

  //   componentDidUnmount() {
  //     clearInterval(this.timeout);

  //   }

  loadBerths = () => {
    this.setState({
      show_status: false,
    });

    api
      .get(this, {
        endpoint: "berth",
        state_key: "_BERTHS",
      })
      .then((data) => {
        let totalSecs = 0;

        data.sort((a, b) => {
          return ("" + a.name).localeCompare(b.name);
        });

        totalSecs = data.length * 5000;

        this.showBerths(data, totalSecs);
      });
  };

  showBerths = (data) => {
    // console.log("DATA", data);

    let count = 0;
    let dataLength = data.length;

    if (data[count]) {
      this.setState({
        BERTH_MONITORING: data[0].currently_berthed,
        berthTitle: data[0].name,
      });

      setInterval(() => {
        if (count < dataLength) {
          this.setState({
            BERTH_MONITORING: data[count].currently_berthed,
            berthTitle: data[count].name,
          });

          count++;
        } else {
          count = 0;
        }
      }, 60000);
    }
  };

  //   changeView = (data) => {

  //   };

  loadTicker = () => {
    api
      .get(this, {
        endpoint: "dashboard/ticker",
        state_key: "dashboardTicker",
      })
      .then((data) => {
        // console.log("TICKER", data);
        // this.setState({
        //   BERTH_MONITORING: data.berth_monitoring,
        // });
        // console.log("DATABERTH", data);
      });
  };

  render() {
    return (
      <>
        <div className="col-md-12 p0 tickerCon">
          <div class="col-md-12 d-flex flex-row align-items-center">
            <div class="col-md-6 p0 d-flex flex-row">
              <BerthMonitoringSVG />{" "}
              <p className="table-title d-flex align-items-center col-md-10 p0">
                Berth Monitoring{" "}
                {this.state.berthTitle ? "(" + this.state.berthTitle + ")" : ""}
              </p>
            </div>
            <div class="col-md-6 p0 d-flex flex-row justify-content-end">
              <div className="d-flex flex-column align-items-end date-con">
                <span className="date-no">
                  {moment(new Date()).format(constants.__timeFormat)}
                </span>
                <span className="date-label">
                  {moment(new Date()).format(constants.__dateFormat) +
                    " " +
                    moment(new Date()).format(constants.__dayFormat)}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center page-con">
                <span className="page-no">1</span>
                <span className="page-label">PAGE</span>
              </div>
            </div>
          </div>
          <table className="arrivalTable ticker-table">
            <thead>
              <tr>
                <th style={{ width: "15%" }}>Name of Ship</th>
                <th style={{ width: "17%" }}>
                  Estimated Date <br /> & Time of Arrival
                </th>
                <th style={{ width: "17%" }}>
                  Estimated Date <br /> & Time of Departure.
                </th>
                <th style={{ width: "10%" }} className="tar">
                  Voyage <br /> No.
                </th>
                <th style={{ width: "15%" }}>Cargo</th>
                <th style={{ width: "15%" }} className="tar">
                  Cargo Handlers
                </th>
                <th style={{ width: "10%" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.BERTH_MONITORING.length != 0 ? (
                this.state.BERTH_MONITORING.map((bs, i) => (
                  <tr key={i}>
                    <td>{bs.vessel_name}</td>
                    <td>
                      {bs.berth_eta
                        ? moment(bs.berth_eta).format(
                            constants.__dateTimeFormat
                          )
                        : ""}
                    </td>
                    <td>
                      {bs.berth_etd
                        ? moment(bs.berth_etd).format(
                            constants.__dateTimeFormat
                          )
                        : ""}
                    </td>
                    <td className="tar yellowTD">{bs.voyage_number}</td>
                    <td>{bs.cargo_details}</td>
                    <td className="tar">{bs.cargo_handlers}</td>
                    <td
                      className={
                        "tar " +
                        classnames({
                          orangeTD:
                            bs.status === "Pending" ||
                            bs.status === "In Transit" ||
                            bs.status === "Announced",
                          greenTD:
                            bs.status === "Arrived" ||
                            bs.status === "Anchorage" ||
                            bs.status === "Berthed" ||
                            bs.status === "Cleared for Exit" ||
                            bs.status === "Resource Plan Approved",
                          blueTD:
                            bs.status === "Submitted" ||
                            bs.status === "Entry Application Endorsed" ||
                            bs.status === "Vessel Exit Application Endorsed" ||
                            bs.status === "Resource Plan Submitted",
                          redTD:
                            bs.status === "Rejected" ||
                            bs.status === "Departed" ||
                            bs.status === "Closed" ||
                            bs.status === "Resource Plan Rejected",
                        })
                      }
                    >
                      {bs.status}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    AVAILABLE
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default withRouter(BerthMonitoring);
