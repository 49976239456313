import axios from "axios";
import { API_URL } from "../config";
import Cookies from "js-cookie";
import session from "../session";

export default {
  /**
   *
   * @param {*} _classIntance - Class instance (this) yung ipapasa mo dito
   * @param {*} options - object - module/URL
   *
   * e.g
   *          {
   *              'endpoint' : 'crew_list',
   *              'meta' : "whatevs",
   *              'state_key' : "__vessel_whatevs"
   *          }
   */

  get: function(_classIntance, options) {
    this.set_access_token_and_user_id(options);
    if (_classIntance && options) {
      const { endpoint } = options;
      let params = {};

      if (options.request_params) {
        params = options.request_params;
      }

      return axios
        .get(API_URL + endpoint, { params })
        .then(res => {
          let key = "__data";

          if (options.state_key) {
            key = options.state_key;
          }
          let b = {};
          const data = res.data.data ? res.data.data : res.data;

          if (!options.append_data) {
            b[key] = data;
          } else {
            let tmp = _classIntance.state[key];
            b[key] = [...tmp, ...data];
          }

          if (!options.preserve_state) {
            _classIntance.setState(b);
          }

          return Promise.resolve(data);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
  },

  save: function(_classIntance, options) {
    this.set_access_token_and_user_id(options);
    if (_classIntance && options) {
      const { endpoint, _data } = options;

      return axios
        .post(API_URL + endpoint, _data)
        .then(res => {
          let o = {},
            key = "__data";
          if (options.state_key) {
            key = options.state_key;
          }

          if (!options.append) {
            o[key] = { ..._data, id: res.data._id };
          } else {
            let d = _classIntance.state[key];

            if (options.append_from_response) {
              d = [...d, res.data];
            } else {
              d = [...d, _data];
            }

            o[key] = d;
          }

          if (!options.preserve_state) {
            _classIntance.setState({ ...o });
          }

          return Promise.resolve(res.data._id);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
  },

  update: function(_classIntance, options) {
    this.set_access_token_and_user_id(options);
    if (_classIntance && options) {
      const { endpoint, _data } = options;

      return axios
        .put(API_URL + endpoint, _data)
        .then(res => {
          let o = {},
            key = "__data";
          if (options.state_key) {
            key = options.state_key;
          }

          if (!options.append) {
            o[key] = { ..._data, id: res.data._id };
          } else {
            let d = _classIntance.state[key];
            d = [...d, _data];
            o[key] = d;
          }

          if (!options.preserve_state) {
            _classIntance.setState({ ...o });
          }
          let ret = options.return_true_value ? res : true;
          return Promise.resolve(ret);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
  },

  post: function(_classIntance, options) {
    this.set_access_token_and_user_id(options);
    if (_classIntance && options) {
      const { endpoint, _data } = options;

      return axios
        .post(API_URL + endpoint, _data)
        .then(res => {
          return Promise.resolve(res);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
  },

  set_access_token_and_user_id: options => {
    let s = session;

    if (Cookies.get("port_token")) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
        "port_token"
      )}`;

      if (options && options.request_params && s.get()) {
        options.request_params["user_id"] = s.get()._id;
        options.request_params["full_name"] = s.getName();
      }

      if (options && options._data && s.get()) {
        options._data["user_id"] = s.get()._id;
        options._data["full_name"] = s.getName();
      }
    }
  },

  manualUpdate: function(options) {
    this.set_access_token_and_user_id(options);
    const { endpoint, request_params } = options;

    return axios.put(API_URL + endpoint, request_params);
  }
};
