import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./Manifest.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import { Icon } from "react-fa";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import Datetime from "react-datetime";
import api from "../../helpers/api.helper";
import constant from "../../constants";
import BackToTop from "../Tools/BackToTop/BackToTop";
import AlertModal from "../Tools/AlertModal/AlertModal";
import constants from "../../../src/constants";
import Swal from "sweetalert2";
import Media from "react-media";
import PageHeader from "../Tools/PageHeader/PageHeader";
import carriers from "../../data/carrier";
import modeOfTransport from "../../data/modeOfTransport";
import transportationNationality from "../../data/nationality";

class Manifest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      __ID: this.props.match.params.id,
      __port_call: [],
      __dateF: constant.__dateFormat,
      __timeF_ss: constant.__timeFormat_ss,
      showAll: false,
      WAYBILL: [],
      DEFAULT_WAYBILL: [
        {
          custom_office: "",
          registry_number: "",
        },
      ],
      ACTIVE_TAB: 0,
      waybillName: "Waybill",
      waybillNo: 1,
      MANIFEST: {
        transport: {
          bills: 0,
          packages: 0,
          containers: 0,
          gross_mass: 0,
          last_discharge: 0,
        },
      },
      MANIFEST_DATA: [],
      TOTALS_DATA: {
        bills: 0,
        packages: 0,
        containers: 0,
        gross_mass: 0,
        last_discharge: 0,
      },
      TRANSPORT_DATA: {
        mode_of_transport_code: "",
        mode_of_transport_name: "",
        nationality_of_transport_code: "",
        nationality_of_transport_name: "",
        registration: "",
        master: "",
        vessel: "",
        place_of_registration: "",
        date: "",
      },
      WAYBILL_DATA: [
        {
          transport: {
            mode_of_transport_code: "",
            mode_of_transport_name: "",
            nationality_of_transport_code: "",
            nationality_of_transport_name: "",
            name_of_transporter: "",
          },
          notify: {
            code: "",
            name: "",
            address: "",
            total_containers: 0,
            status: "",
            packages_codes: "",
            description_of_packages: "",
          },
          values_declared_for: {
            customs: "",
            customs_currency: "",
            transport: "",
            transport_currency: "",
            insurance: "",
            insurance_currency: "",
          },
          seals: {
            number: 0,
            marks: "",
            sealing_party_code: "",
            sealting_party_description: "",
            information: "",
          },
          onward_transport: {
            transit: "",
            transit_customs_office_name: "",
            doc_reference: "",
          },
          transhipment: {
            transhipment_location_code: "",
            transhipment_location_name: "",
            doc_reference: "",
          },
          carrier: {
            onward_carrier_code: "",
            onward_carrier_name: "",
          },
        },
      ],
      WAYBILL_SAVE: {},
      TOTALS_DATA: {},
      MANIFEST_SAVE: {},
      WB_TRANSPORT: {},
      currentWBi: 0,
      currentWBname: "",
      is_saved: false,
      is_edit_mode: false,
      isAlert: "",
      alertMsg: "",
      tglAlert: false,
      is_ep_submitted: false,
    };
  }

  addContainerWaybill = (waybillIndex) => {
    this.state.MANIFEST.manifest_waybill.push({});
  };

  componentDidMount = () => {
    this.loadData();
    // this.addWaybill();
  };

  loadData = () => {
    api
      .get(this, {
        endpoint: "portcall/" + this.state.__ID,
        state_key: "__port_call",
      })
      .then((data) => {
        if (data.is_emanifest_saved) {
          // console.log("DUMAAN HERE");
          api
            .get(this, {
              endpoint: "emanifest_poms/" + this.state.__ID,
              state_key: "MANIFEST",
            })
            .then((data) => {
              const totals_data = {
                bills: 0,
                packages: 0,
                containers: 0,
                gross_mass: 0,
                last_discharge: 0,
              };

              const trans_data = {
                mode_of_transport_code: "",
                mode_of_transport_name: "",
                nationality_of_transport_code: "",
                nationality_of_transport_name: "",
                registration: "",
                master: "",
                vessel: "",
                place_of_registration: "",
                date: new Date(),
              };

              this.setState({
                TOTALS_DATA: data.totals ? data.totals : totals_data,
                TRANSPORT_DATA: data.transport ? data.transport : trans_data,
                WAYBILL_DATA: data.manifest_waybill,
              });

              console.log("testdatabui", data);
            });
        }
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        ACTIVE_TAB: tab,
      });
    }
  };

  addWaybill = () => {
    const currWaybill = this.state.WAYBILL_DATA;
    const addWaybill = {
      transport: {
        mode_of_transport_code: "",
        mode_of_transport_name: "",
        nationality_of_transport_code: "",
        nationality_of_transport_name: "",
        name_of_transporter: "",
      },
      notify: {
        code: "",
        name: "",
        address: "",
        total_containers: 0,
        status: "",
        packages_codes: "",
        description_of_packages: "",
      },
      values_declared_for: {
        customs: "",
        customs_currency: "",
        transport: "",
        transport_currency: "",
        insurance: "",
        insurance_currency: "",
      },
      seals: {
        number: 0,
        marks: "",
        sealing_party_code: "",
        sealting_party_description: "",
        information: "",
      },
      onward_transport: {
        transit: "",
        transit_customs_office_name: "",
        doc_reference: "",
      },
      transhipment: {
        transhipment_location_code: "",
        transhipment_location_name: "",
        doc_reference: "",
      },
      carrier: {
        onward_carrier_code: "",
        onward_carrier_name: "",
      },
    };

    this.setState({
      WAYBILL_DATA: [...currWaybill, addWaybill],
    });

    console.log("CURRB", this.state.WAYBILL_DATA);
  };

  handleSave = () => {
    let { MANIFEST, TOTALS_DATA, WAYBILL_DATA, TRANSPORT_DATA } = this.state;

    if (!this.state.__port_call.is_emanifest_saved) {
      api
        .save(this, {
          endpoint: "emanifest_poms/" + this.state.__ID,
          _data: {
            ...MANIFEST,
            portcall_id: this.state.__ID,
            totals: TOTALS_DATA,
            transport: TRANSPORT_DATA,
            manifest_waybill: WAYBILL_DATA,
            is_ep_saved: true,
          },
        })
        .then((data) => {
          this.showAlert("success", "eManifest saved!", true);

          this.setState({
            is_saved: true,
            tglAlert: false,
            is_edit_mode: false,
          });

          this.loadData();
        });
    } else {
      let test = {};
      test = {
        ...MANIFEST,
        portcall_id: this.state.__ID,
        totals: TOTALS_DATA,
        transport: TRANSPORT_DATA,
        manifest_waybill: WAYBILL_DATA,
        is_ep_saved: true,
        is_edit_mode: false,
      };

      console.log("TEST", test);

      api
        .update(this, {
          endpoint: "emanifest_poms/" + this.state.__ID,
          _data: {
            ...MANIFEST,
            portcall_id: this.state.__ID,
            totals: TOTALS_DATA,
            transport: TRANSPORT_DATA,
            manifest_waybill: WAYBILL_DATA,
            is_ep_saved: true,
            is_edit_mode: false,
          },
        })
        .then((data) => {
          this.showAlert("success", "eManifest updated!", true);

          this.setState({
            is_saved: true,
            tglAlert: false,
            is_edit_mode: false,
          });

          this.loadData();
        });
    }
  };

  handleUpdate = () => {
    Swal.fire({
      icon: "question",
      title: "Switch to edit mode?",
      // text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Swal.fire({
        //   icon: "success",
        //   title: "Deleted!",
        //   showConfirmButton: false,
        //   timer: 1500
        // });

        api
          .update(this, {
            endpoint: "emanifest_poms/" + this.state.__ID,
            _data: {
              is_ep_saved: false,
              is_edit_mode: true,
            },
          })
          .then((data) => {
            this.setState({
              is_saved: false,
              is_edit_mode: true,
            });

            this.loadData();
          });
      }
    });
  };

  handleSubmit = () => {
    Swal.fire({
      icon: "question",
      title: "Submit eManifest?",
      text: "Once submitted this action can never be undone.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "eManifest submitted and waiting for confirmation!",
          showConfirmButton: false,
          timer: 1500,
        });

        api
          .update(this, {
            endpoint: "emanifest_poms/submit/" + this.state.__ID,
            _data: {},
          })
          .then((data) => {});

        api
          .update(this, {
            endpoint: "emanifest_poms/" + this.state.__ID,
            _data: {
              is_ep_submitted: true,
            },
          })
          .then((data) => {
            this.setState({
              is_ep_submitted: true,
            });

            this.loadData();
          });
      }
    });
  };

  showAlert = (mode, msg, bool) => {
    this.setState({
      isAlert: mode,
      alertMsg: msg,
      tglAlert: bool,
    });

    // console.log("ALERT", bool);
  };

  render() {
    const {
      __dateF,
      __timeF_ss,
      TOTALS_DATA,
      tglAlert,
      isAlert,
      alertMsg,
    } = this.state;
    const vd = this.state.__port_call;
    const md = this.state.MANIFEST;

    return (
      <div className="Manifest">
        {tglAlert ? (
          <AlertModal sendTrigger={isAlert} sendMsg={alertMsg} />
        ) : (
          ""
        )}

        <div className="d-flex flex-column">
          <Navbar />
          {/* <PortCallToolbar /> */}

          <Media queries={constants.media_queries}>
            {(matches) =>
              matches.xs ? (
                <Sidenav
                  getVType={this.state.vesselType}
                  dataFromParent={this.state.__ID}
                  visit={vd}
                />
              ) : this.state.__ID ? (
                <PageHeader
                  parentCallback={this.getViewMode}
                  sendVName={vd.vessel_name}
                  sendVoyageNo={vd.voyage_number}
                />
              ) : (
                <Col className="pageHeader">
                  <Col className="d-flex flex-row vessel-title">
                    <p className="vessel-name">Manifest</p>
                  </Col>
                </Col>
              )
            }
          </Media>

          <Row className="main-content">
            <Media queries={constants.media_queries}>
              {(matches) =>
                !matches.xs ? (
                  <Col xs="2">
                    <Sidenav dataFromParent={this.state.__ID} visit={vd} />
                  </Col>
                ) : this.state.__ID ? (
                  <PageHeader
                    parentCallback={this.getViewMode}
                    sendVName={vd.vessel_name}
                    sendVoyageNo={vd.voyage_number}
                    pagename="Vessel Details"
                  />
                ) : (
                  <Col className="pageHeader">
                    <Col className="d-flex flex-row vessel-title">
                      <p className="vessel-name">Vessel Entry Registration</p>
                    </Col>
                  </Col>
                )
              }
            </Media>

            <Col md={{ size: 10 }}>
              <Row className="manifestatus-cont">
                <Col
                  className={classnames({
                    manifestatus: true, //stay as true
                    draft: true,
                    pending: false,
                    registered: false,
                  })}
                >
                  <div>
                    <div>
                      <Icon
                        name={classnames({
                          edit: true,
                          pending: false,
                          registered: false,
                        })}
                      />
                    </div>
                  </div>
                  <div>
                    {true && (
                      <div>
                        This is a DRAFT Version.
                        <br />
                        To submit, please click the Submit button below.
                      </div>
                    )}
                    {false && (
                      <div>
                        You have submitted this Manifest Document and is now
                        pending for approval.
                      </div>
                    )}
                    {false && (
                      <div>
                        Your Manifest Document has been approved!
                        <br />
                        This document is now registered.
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <Form>
                <Row className="form-container-box bt-green manifest-gen-form">
                  <div className="fcb-title">
                    Manifest - General Information
                  </div>

                  <Row xs="1" md="2">
                    <Col>
                      <FormGroup>
                        <Label for="exampleSelect">Custom Office</Label>
                        <DropdownList
                          filters
                          value={md.custom_office}
                          onChange={(e) => {
                            let { MANIFEST } = this.state;

                            MANIFEST.custom_office = e;
                            this.setState({ MANIFEST });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="exampleFile">Date & Time of Arrival</Label>
                        <Datetime
                          dateFormat={__dateF}
                          timeFormat={__timeF_ss}
                          value={moment(md.date_and_time_of_arrival).format(
                            constants.__dateTimeFormat
                          )}
                          onChange={(e) => {
                            let { MANIFEST } = this.state;

                            MANIFEST.date_and_time_of_arrival = e;
                            this.setState({ MANIFEST });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="">Registry Number</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="registry_number"
                          value={md.registry_number}
                          onChange={(e) => {
                            let { MANIFEST } = this.state;

                            MANIFEST.registry_number = e.target.value;
                            this.setState({ MANIFEST });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="exampleFile">
                          Date & Time of Registration
                        </Label>
                        <Datetime
                          dateFormat={__dateF}
                          timeFormat={__timeF_ss}
                          value={moment(
                            md.date_and_time_of_registration
                          ).format(constants.__dateTimeFormat)}
                          onChange={(e) => {
                            let { MANIFEST } = this.state;

                            MANIFEST.date_and_time_of_registration = e;
                            this.setState({ MANIFEST });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {this.state.showAll && (
                      <>
                        <Col>
                          <Label className="big-label">
                            Place of Departure
                          </Label>
                          <Row>
                            <Col xs="5">
                              <FormGroup>
                                <Label for="">Code</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={md.place_of_departure_code}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.place_of_departure_code =
                                      e.target.value;
                                    this.setState({ MANIFEST });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="7">
                              <FormGroup>
                                <Label for="">Name</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={md.place_of_departure_name}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.place_of_departure_name =
                                      e.target.value;
                                    this.setState({ MANIFEST });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Label className="big-label">
                            Place of Destination
                          </Label>
                          <Row>
                            <Col xs="5">
                              <FormGroup>
                                <Label for="">Code</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={md.place_of_destination_code}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.place_of_destination_code =
                                      e.target.value;
                                    this.setState({ MANIFEST });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="7">
                              <FormGroup>
                                <Label for="">Name</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={md.place_of_destination_name}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.place_of_destination_name =
                                      e.target.value;
                                    this.setState({ MANIFEST });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Label className="big-label">Carrier</Label>
                          <Row xs="1">
                            <Col>
                              <FormGroup>
                                <DropdownList
                                  filters
                                  data={carriers}
                                  textField="CAR_NAM"
                                  value={md.carrier_name}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.carrier_code = e.CAR_COD;
                                    MANIFEST.carrier_name = e.CAR_NAM;
                                    this.setState({ MANIFEST });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="">Name</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={md.carrier_name}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.carrier_name = e.target.value;
                                    this.setState({ MANIFEST });
                                  }}
                                />{" "}
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="exampleText">Address</Label>
                                <Input
                                  className="fs13"
                                  rows="6"
                                  type="textarea"
                                  name="text"
                                  id="exampleText"
                                  style={{ resize: "none" }}
                                  value={md.carrier_address}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.carrier_address = e.target.value;
                                    this.setState({ MANIFEST });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Label className="big-label">Totals</Label>
                          <Row xs="1">
                            <Col>
                              <FormGroup>
                                <Label for="">Bills</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="mmsi_no"
                                  value={this.state.TOTALS_DATA.bills}
                                  onChange={(e) => {
                                    let { TOTALS_DATA } = this.state;

                                    TOTALS_DATA.bills = e.target.value;
                                    this.setState({ TOTALS_DATA });

                                    console.log("BILLS", TOTALS_DATA);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="">Packages</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="mmsi_no"
                                  value={this.state.TOTALS_DATA.packages}
                                  onChange={(e) => {
                                    let { TOTALS_DATA } = this.state;

                                    TOTALS_DATA.packages = e.target.value;
                                    this.setState({ TOTALS_DATA });
                                  }}
                                />{" "}
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="">Containers</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="mmsi_no"
                                  value={this.state.TOTALS_DATA.containers}
                                  onChange={(e) => {
                                    let { TOTALS_DATA } = this.state;
                                    TOTALS_DATA.containers = e.target.value;
                                    this.setState({ TOTALS_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="">Gross Mass</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="mmsi_no"
                                  value={this.state.TOTALS_DATA.gross_mass}
                                  onChange={(e) => {
                                    let { TOTALS_DATA } = this.state;

                                    TOTALS_DATA.gross_mass = e.target.value;
                                    this.setState({ TOTALS_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="">Last Discharge</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="mmsi_no"
                                  value={this.state.TOTALS_DATA.last_discharge}
                                  onChange={(e) => {
                                    let { TOTALS_DATA } = this.state;

                                    TOTALS_DATA.last_discharge = e.target.value;
                                    this.setState({ TOTALS_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Label className="big-label">Transport</Label>
                        </Col>
                        <Col></Col>
                        <Col>
                          <Row>
                            <Col xs="5">
                              <FormGroup>
                                <Label for="">Mode of Transport - Code</Label>
                                <DropdownList
                                  filters
                                  data={modeOfTransport}
                                  textField="MOT_DSC"
                                  value={
                                    this.state.TRANSPORT_DATA
                                      .mode_of_transport_name
                                  }
                                  onChange={(e) => {
                                    let { TRANSPORT_DATA } = this.state;

                                    TRANSPORT_DATA.mode_of_transport_code =
                                      e.MOT_COD;
                                    TRANSPORT_DATA.mode_of_transport_name =
                                      e.MOT_DSC;
                                    this.setState({ TRANSPORT_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="7">
                              <Label for="">Mode of Transport - Name</Label>
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                value={
                                  this.state.TRANSPORT_DATA
                                    .mode_of_transport_name
                                }
                                onChange={(e) => {
                                  let { TRANSPORT_DATA } = this.state;

                                  TRANSPORT_DATA.mode_of_transport_name =
                                    e.target.value;
                                  this.setState({ TRANSPORT_DATA });
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="">Vessel</Label>
                            <Input
                              className="form-control-sm"
                              type="text"
                              name="mmsi_no"
                              value={this.state.TRANSPORT_DATA.vessel}
                              onChange={(e) => {
                                let { TRANSPORT_DATA } = this.state;

                                TRANSPORT_DATA.vessel = e.target.value;
                                this.setState({ TRANSPORT_DATA });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <Row>
                            <Col xs="5">
                              <Label for="">
                                Nationality of Transport - Code
                              </Label>

                              <DropdownList
                                filters
                                data={transportationNationality}
                                textField="CTY_DSC"
                                value={
                                  this.state.TRANSPORT_DATA
                                    .nationality_of_transport_name
                                }
                                onChange={(e) => {
                                  let { TRANSPORT_DATA } = this.state;

                                  TRANSPORT_DATA.nationality_of_transport_code =
                                    e.CTY_COD;
                                  TRANSPORT_DATA.nationality_of_transport_name =
                                    e.CTY_DSC;
                                  this.setState({ TRANSPORT_DATA });
                                }}
                              />
                            </Col>
                            <Col xs="7">
                              <Label for="">
                                Nationality of Transport - Name
                              </Label>
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                value={
                                  this.state.TRANSPORT_DATA
                                    .nationality_of_transport_name
                                }
                                onChange={(e) => {
                                  let { TRANSPORT_DATA } = this.state;

                                  TRANSPORT_DATA.nationality_of_transport_name =
                                    e.target.value;
                                  this.setState({ TRANSPORT_DATA });
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="">Place of Registration</Label>
                            <Input
                              className="form-control-sm"
                              type="text"
                              name="mmsi_no"
                              value={
                                this.state.TRANSPORT_DATA.place_of_registration
                              }
                              onChange={(e) => {
                                let { TRANSPORT_DATA } = this.state;

                                TRANSPORT_DATA.place_of_registration =
                                  e.target.value;
                                this.setState({ TRANSPORT_DATA });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="">Registration</Label>
                            <Input
                              className="form-control-sm"
                              type="text"
                              name="mmsi_no"
                              value={this.state.TRANSPORT_DATA.registration}
                              onChange={(e) => {
                                let { TRANSPORT_DATA } = this.state;

                                TRANSPORT_DATA.registration = e.target.value;
                                this.setState({ TRANSPORT_DATA });
                              }}
                            />{" "}
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="exampleFile">Date</Label>
                            <Datetime
                              dateFormat={__dateF}
                              timeFormat={false}
                              value={moment(
                                this.state.TRANSPORT_DATA.date
                              ).format(constants.__dateTimeFormat)}
                              onChange={(e) => {
                                let { TRANSPORT_DATA } = this.state;

                                TRANSPORT_DATA.date = e;
                                this.setState({ TRANSPORT_DATA });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="exampleText">Master</Label>
                            <Input
                              rows="3"
                              type="textarea"
                              name="text"
                              id="exampleText"
                              style={{ resize: "none" }}
                              className="fs13"
                              value={this.state.TRANSPORT_DATA.master}
                              onChange={(e) => {
                                let { TRANSPORT_DATA } = this.state;

                                TRANSPORT_DATA.master = e.target.value;
                                this.setState({ TRANSPORT_DATA });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col></Col>
                        <Col>
                          <Label className="big-label">Tonnage</Label>
                          <Row xs="2">
                            <Col>
                              <FormGroup>
                                <Label for="">Gross</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="mmsi_no"
                                  value={md.tonnage_gross}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.tonnage_gross = e.target.value;
                                    this.setState({ MANIFEST });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="">Net</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="mmsi_no"
                                  value={md.tonnage_net}
                                  onChange={(e) => {
                                    let { MANIFEST } = this.state;

                                    MANIFEST.tonnage_net = e.target.value;
                                    this.setState({ MANIFEST });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row
                    className={classnames({
                      "manifest-toggle-cont": true,
                      isOpen: this.state.showAll,
                    })}
                  >
                    <Col
                      onClick={(e) => {
                        if (this.state.showAll) {
                          document.documentElement.scrollTop = 0;
                        }
                        this.setState({
                          showAll: !this.state.showAll,
                        });
                      }}
                    >
                      <Icon
                        className="manifest-toggle"
                        name={this.state.showAll ? "angle-up" : "angle-down"}
                      />
                      <div>
                        <Icon
                          name={this.state.showAll ? "angle-up" : "angle-down"}
                        />{" "}
                        Show {this.state.showAll ? "Less" : "More"} Info{" "}
                        <Icon
                          name={this.state.showAll ? "angle-up" : "angle-down"}
                        />
                      </div>
                    </Col>
                  </Row>
                </Row>

                <div className="fcb-title bord-0 w100per mt-3 d-flex flex-row">
                  <div className="col-md-6 p-0">Manifest - Waybill</div>
                </div>

                <div className="col-md-12 p-0">
                  <Nav
                    id="waybill_tabs"
                    tabs
                    className="port-calls-tab col-md-12"
                    style={{
                      border: "1px solid #fff",
                      flexWrap: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {this.state.WAYBILL_DATA.map((wb, i) => (
                      <NavItem key={i}>
                        <NavLink
                          className={classnames({
                            active: this.state.ACTIVE_TAB === i,
                          })}
                          onClick={() => {
                            this.toggle(i);
                          }}
                        >
                          <span>
                            {wb.waybill_name
                              ? wb.waybill_name
                              : "Waybill " + (i + 1)}
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}

                    {md.is_ep_saved || this.state.is_saved ? (
                      ""
                    ) : (
                      <NavItem className="d-flex flex-row align-items-center">
                        <NavLink className="waybill-add-btn">
                          <div className="slideWaybill">
                            <Icon
                              name="plus"
                              onClick={() => this.addWaybill()}
                            />
                          </div>
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>

                  {this.state.WAYBILL_DATA.map((wb, i) =>
                    this.state.ACTIVE_TAB === i ? (
                      <TabContent
                        activeTab={this.state.ACTIVE_TAB}
                        className={
                          md.is_ep_saved || this.state.is_saved
                            ? "pc-tab-content pen"
                            : "pc-tab-content"
                        }
                      >
                        <TabPane
                          tabId={this.state.ACTIVE_TAB}
                          className="form-container-box"
                        >
                          <Row xs="1" md="2">
                            <Col
                              xs={{ order: "1", size: "12" }}
                              md={{ order: "1", size: "6" }}
                            >
                              <FormGroup>
                                <Label for="">Waybill Name</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="registry_number"
                                  id=""
                                  value={
                                    wb.waybill_name
                                      ? wb.waybill_name
                                      : "Waybill" + " " + (i + 1)
                                  }
                                  placeholder={
                                    wb.waybill_name
                                      ? wb.waybill_name
                                      : "Waybill" + " " + (i + 1)
                                  }
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].waybill_name =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs={{ order: "2", size: "12" }}
                              md={{ order: "2", size: "6" }}
                            >
                              <FormGroup>
                                <Label for="exampleSelect">Custom Office</Label>
                                <DropdownList
                                  filter
                                  value={wb.custom_office}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].custom_office = e;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs={{ order: "3", size: "12" }}
                              md={{ order: "3", size: "6" }}
                            >
                              <FormGroup>
                                <Label for="">Registry Number</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="registry_number"
                                  value={wb.registry_number}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].registry_number =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs={{ order: "4", size: "12" }}
                              md={{ order: "4", size: "6" }}
                            >
                              <FormGroup>
                                <Label for="exampleFile">
                                  Date & Time of Registration
                                </Label>
                                <Datetime
                                  dateFormat={__dateF}
                                  timeFormat={__timeF_ss}
                                  value={wb.date_and_time_of_registration}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[
                                      i
                                    ].date_and_time_of_registration = e;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs={{ order: "5", size: "12" }}
                              md={{ order: "5", size: "6" }}
                            >
                              <Row>
                                <Col md="4">
                                  <FormGroup>
                                    <Label for="">B/L Ref Number</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="number"
                                      name="mmsi_no"
                                      value={wb.bl_ref_number}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].bl_ref_number =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <Label for="">B/L Type - Code</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.bl_type_code}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].bl_type_code =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="5">
                                  <FormGroup>
                                    <Label for="">B/L Type - Name</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.bl_type_name}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].bl_type_name =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={{ order: "6", size: "12" }}
                              md={{ order: "6", size: "6" }}
                            >
                              <Row>
                                <Col md="4">
                                  <FormGroup>
                                    <Label for="">Nature</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.nature}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].nature = e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <Label for="">B/L Number</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="number"
                                      name="mmsi_no"
                                      value={wb.bl_number}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].bl_number =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="5">
                                  <FormGroup>
                                    <Label for="">B/L Sub-line Number</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="number"
                                      name="mmsi_no"
                                      value={wb.bl_sub_line_number}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].bl_sub_line_number =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={{ order: "7", size: "12" }}
                              md={{ order: "7", size: "6" }}
                            >
                              <FormGroup>
                                <Label for="">Previous Document</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={wb.previous_document}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].previous_document =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col
                              xs={{ order: "8", size: "12" }}
                              md={{ order: "8", size: "6" }}
                            >
                              <FormGroup>
                                <Label for="">UCR</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={wb.ucr}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].ucr = e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row xs="1" md="2">
                            <Col>
                              <Label className="big-label">
                                Place of Loading
                              </Label>
                              <Row>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">Code</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.place_of_loading_code}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].place_of_loading_code =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">Name</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.place_of_loading_name}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].place_of_loading_name =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Label className="big-label">
                                Place of Unloading
                              </Label>
                              <Row>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">Code</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.place_of_unloading_code}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].place_of_unloading_code =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">Name</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.place_of_unloading_name}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].place_of_unloading_name =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Label className="big-label">Carrier</Label>
                              <Row>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">Code</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="carrier_code"
                                      value={wb.CAR_COD}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].carrier_code =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">Name</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.carrier_name}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].carrier_name =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="12">
                                  <Label for="exampleText">Address</Label>
                                  <Input
                                    rows="3"
                                    type="textarea"
                                    name="text"
                                    id="exampleText"
                                    className="fs13"
                                    style={{ resize: "none" }}
                                    value={wb.carrier_address}
                                    onChange={(e) => {
                                      let { WAYBILL_DATA } = this.state;

                                      WAYBILL_DATA[i].carrier_address =
                                        e.target.value;

                                      this.setState({ WAYBILL_DATA });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Label className="big-label">
                                Exporter/Shipper
                              </Label>
                              <Row>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">Code</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.exporter_or_shipper_code}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].exporter_or_shipper_code =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">Name</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.exporter_or_shipper_name}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].exporter_or_shipper_name =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="12">
                                  <Label for="exampleText">Address</Label>
                                  <Input
                                    rows="3"
                                    type="textarea"
                                    name="text"
                                    id="exampleText"
                                    className="fs13"
                                    style={{ resize: "none" }}
                                    value={wb.exporter_or_shipper_address}
                                    onChange={(e) => {
                                      let { WAYBILL_DATA } = this.state;

                                      WAYBILL_DATA[
                                        i
                                      ].exporter_or_shipper_address =
                                        e.target.value;

                                      this.setState({ WAYBILL_DATA });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row xs="1">
                            <Col>
                              <Label className="big-label">Transport</Label>
                              <Row xs="1" md="2">
                                <Col>
                                  <Row>
                                    <Col xs="5">
                                      <FormGroup>
                                        <Label for="">Mode - Code</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={
                                            wb.transport.mode_of_transport_code
                                          }
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].transport.mode_of_transport_code =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="7">
                                      <FormGroup>
                                        <Label for="">Mode - Name</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={
                                            wb.transport.mode_of_transport_name
                                          }
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].transport.mode_of_transport_name =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for="">Name of Transporter</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.transport.name_of_transporter}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].transport.name_of_transporter =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col xs="5">
                                      <FormGroup>
                                        <Label for="">
                                          Nationality of Transport - Code
                                        </Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={
                                            wb.transport
                                              .nationality_of_transport_code
                                          }
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].transport.nationality_of_transport_code =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="7">
                                      <FormGroup>
                                        <Label for="">
                                          Nationality of Transport - Name
                                        </Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={
                                            wb.transport
                                              .nationality_of_transport_name
                                          }
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].transport.nationality_of_transport_name =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={{ order: "1", size: "12" }} md="6">
                              <Label className="big-label">Notify</Label>
                              <Row xs="1">
                                <Col>
                                  <Row>
                                    <Col xs="5">
                                      <FormGroup>
                                        <Label for="">Code</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.notify.code}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[i].notify.code =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });

                                            console.log("NOT", WAYBILL_DATA);
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="7">
                                      <FormGroup>
                                        <Label for="">Name</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.notify.name}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[i].notify.name =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for="exampleText">Address</Label>
                                    <Input
                                      rows="3"
                                      type="textarea"
                                      name="text"
                                      id="exampleText"
                                      style={{ resize: "none" }}
                                      className="fs13"
                                      value={wb.notify.address}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].notify.address =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col xs="5">
                                      <FormGroup>
                                        <Label for="">Total Containers</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.notify.total_containers}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].notify.total_containers =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="7">
                                      <FormGroup>
                                        <Label for="">Status</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.notify.status}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[i].notify.status =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col xs="5">
                                      <FormGroup>
                                        <Label for="">Packages Codes</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.notify.packages_codes}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].notify.packages_codes =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="7">
                                      <FormGroup>
                                        <Label for="">
                                          Description of Packages
                                        </Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={
                                            wb.notify.description_of_packages
                                          }
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].notify.description_of_packages =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={{ order: "2", size: "12" }} md="6">
                              <Label className="big-label">Consignee</Label>
                              <Row xs="1">
                                <Col>
                                  <Row>
                                    <Col xs="5">
                                      <FormGroup>
                                        <Label for="">Code</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.consignee_code}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[i].consignee_code =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="7">
                                      <FormGroup>
                                        <Label for="">Name</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.consignee_name}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[i].consignee_name =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for="exampleText">Address</Label>
                                    <Input
                                      rows="3"
                                      type="textarea"
                                      name="text"
                                      id="exampleText"
                                      style={{ resize: "none" }}
                                      className="fs13"
                                      value={wb.consignee_address}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].consignee_address =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for="">
                                      Item (number of degrouped B/L)
                                    </Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={
                                        wb.consignee_number_of_degrouped_bl
                                      }
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].consignee_number_of_degrouped_bl =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={{ order: "3", size: "12" }}>
                              <Row xs="1">
                                <Col>
                                  <FormGroup>
                                    <Label for="exampleText">Marks & NB</Label>
                                    <Input
                                      rows="3"
                                      type="textarea"
                                      name="text"
                                      id="exampleText"
                                      style={{ resize: "none" }}
                                      className="fs13"
                                      value={wb.marks_and_nb}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].marks_and_nb =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col xs="4">
                                      <FormGroup>
                                        <Label for="">
                                          Manifested Packages
                                        </Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.manifested_packages}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].manifested_packages =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="4">
                                      <FormGroup>
                                        <Label for="">
                                          Manifested Gross Mass
                                        </Label>
                                        <Input
                                          className="form-control-sm"
                                          type="number"
                                          name="mmsi_no"
                                          value={wb.manifested_gross_mass}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].manifested_gross_mass =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col xs="4">
                                      <FormGroup>
                                        <Label for="">Remaining Packages</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="text"
                                          name="mmsi_no"
                                          value={wb.remainining_packages}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].remainining_packages =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="4">
                                      <FormGroup>
                                        <Label for="">
                                          Remaining Gross Mass
                                        </Label>
                                        <Input
                                          className="form-control-sm"
                                          type="number"
                                          name="mmsi_no"
                                          value={wb.remaining_gross_mass}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[
                                              i
                                            ].remaining_gross_mass =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="4">
                                      <FormGroup>
                                        <Label for="">Volume (CBM)</Label>
                                        <Input
                                          className="form-control-sm"
                                          type="number"
                                          name="mmsi_no"
                                          value={wb.volume_cbm}
                                          onChange={(e) => {
                                            let { WAYBILL_DATA } = this.state;

                                            WAYBILL_DATA[i].volume_cbm =
                                              e.target.value;

                                            this.setState({ WAYBILL_DATA });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for="exampleText">
                                      Description of Goods
                                    </Label>
                                    <Input
                                      rows="3"
                                      type="textarea"
                                      name="text"
                                      id="exampleText"
                                      style={{ resize: "none" }}
                                      className="fs13"
                                      value={wb.description_of_goods}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].description_of_goods =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs="12">
                              <Label className="big-label">Location</Label>
                            </Col>
                            <Col xs="5" md="2">
                              <FormGroup>
                                <Label for="">Code</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={wb.location_code}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].location_code =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="7" md="5">
                              <FormGroup>
                                <Label for="">Name</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={wb.location_name}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].location_name =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="5">
                              <FormGroup>
                                <Label for="">Info</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={wb.location_info}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].location_info =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs="12">
                              <Label className="big-label">Freight</Label>
                            </Col>
                            <Col xs="12" md="6">
                              <FormGroup>
                                <Label for="">P/C Ind</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={wb.freight_pc_ind}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].freight_pc_ind =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="6">
                              <Row>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">Amount</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="number"
                                      name="mmsi_no"
                                      value={wb.freight_amount}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].freight_amount =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">Freight Currency</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.freight_currency}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].freight_currency =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row xs="1">
                            <Col>
                              <Label className="big-label">
                                Values declared for
                              </Label>
                            </Col>
                            <Col>
                              <Row xs="1" md="3">
                                <Col>
                                  <Row>
                                    <Col xs="7">
                                      <Label for="">Customs</Label>
                                      <Input
                                        className="form-control-sm"
                                        type="text"
                                        name="mmsi_no"
                                        value={wb.values_declared_for.customs}
                                        onChange={(e) => {
                                          let { WAYBILL_DATA } = this.state;

                                          WAYBILL_DATA[
                                            i
                                          ].values_declared_for.customs =
                                            e.target.value;

                                          this.setState({ WAYBILL_DATA });
                                        }}
                                      />
                                    </Col>
                                    <Col xs="5">
                                      <Label for="">Currency</Label>
                                      <Input
                                        className="form-control-sm"
                                        type="text"
                                        name="mmsi_no"
                                        value={
                                          wb.values_declared_for
                                            .customs_currency
                                        }
                                        onChange={(e) => {
                                          let { WAYBILL_DATA } = this.state;

                                          WAYBILL_DATA[
                                            i
                                          ].values_declared_for.customs_currency =
                                            e.target.value;

                                          this.setState({ WAYBILL_DATA });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col xs="7">
                                      <Label for="">Transport</Label>
                                      <Input
                                        className="form-control-sm"
                                        type="text"
                                        name="mmsi_no"
                                        value={wb.values_declared_for.transport}
                                        onChange={(e) => {
                                          let { WAYBILL_DATA } = this.state;

                                          WAYBILL_DATA[
                                            i
                                          ].values_declared_for.transport =
                                            e.target.value;

                                          this.setState({ WAYBILL_DATA });
                                        }}
                                      />
                                    </Col>
                                    <Col xs="5">
                                      <Label for="">Currency</Label>
                                      <Input
                                        className="form-control-sm"
                                        type="text"
                                        name="mmsi_no"
                                        value={
                                          wb.values_declared_for
                                            .transport_currency
                                        }
                                        onChange={(e) => {
                                          let { WAYBILL_DATA } = this.state;

                                          WAYBILL_DATA[
                                            i
                                          ].values_declared_for.transport_currency =
                                            e.target.value;

                                          this.setState({ WAYBILL_DATA });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    <Col xs="7">
                                      <Label for="">Insurance</Label>
                                      <Input
                                        className="form-control-sm"
                                        type="text"
                                        name="mmsi_no"
                                        value={wb.values_declared_for.insurance}
                                        onChange={(e) => {
                                          let { WAYBILL_DATA } = this.state;

                                          WAYBILL_DATA[
                                            i
                                          ].values_declared_for.insurance =
                                            e.target.value;

                                          this.setState({ WAYBILL_DATA });
                                        }}
                                      />
                                    </Col>
                                    <Col xs="5">
                                      <Label for="">Currency</Label>
                                      <Input
                                        className="form-control-sm"
                                        type="text"
                                        name="mmsi_no"
                                        value={
                                          wb.values_declared_for
                                            .insurance_currency
                                        }
                                        onChange={(e) => {
                                          let { WAYBILL_DATA } = this.state;

                                          WAYBILL_DATA[
                                            i
                                          ].values_declared_for.insurance_currency =
                                            e.target.value;

                                          this.setState({ WAYBILL_DATA });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs="12">
                              <Label className="big-label">Seals</Label>
                            </Col>
                            <Col xs="12" md="6">
                              <Row>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">Seals Number</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="number"
                                      name="mmsi_no"
                                      value={wb.seals.number}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].seals.number =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">Marks</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.seals.marks}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[i].seals.marks =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs="12" md="6">
                              <Row>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">Sealing Party - Code</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.seals.sealing_party_code}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].seals.sealing_party_code =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">
                                      Sealing Party - Description
                                    </Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={
                                        wb.seals.sealting_party_description
                                      }
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].seals.sealting_party_description =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs="12">
                              <FormGroup>
                                <Label for="exampleText">Information</Label>
                                <Input
                                  rows="3"
                                  type="textarea"
                                  name="text"
                                  id="exampleText"
                                  style={{ resize: "none" }}
                                  className="fs13"
                                  value={wb.seals.information}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].seals.information =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row xs="1" md="2">
                            <Col>
                              <Label className="big-label">
                                Onward Transport
                              </Label>
                            </Col>
                            <Col></Col>
                            <Col>
                              <Row>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">Transit</Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={wb.onward_transport.transit}
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].onward_transport.transit =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">
                                      Transit Customs Office - Name
                                    </Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={
                                        wb.onward_transport
                                          .transit_customs_office_name
                                      }
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].onward_transport.transit_customs_office_name =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="">Doc. Reference</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={wb.onward_transport.doc_reference}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[
                                      i
                                    ].onward_transport.doc_reference =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row xs="1" md="2">
                            <Col>
                              <Label className="big-label">Transhipment</Label>
                            </Col>
                            <Col></Col>
                            <Col>
                              <Row>
                                <Col xs="5">
                                  <FormGroup>
                                    <Label for="">
                                      Transhipment Location - Code
                                    </Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={
                                        wb.transhipment
                                          .transhipment_location_code
                                      }
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].transhipment.transhipment_location_code =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="7">
                                  <FormGroup>
                                    <Label for="">
                                      Transhipment Location - Name
                                    </Label>
                                    <Input
                                      className="form-control-sm"
                                      type="text"
                                      name="mmsi_no"
                                      value={
                                        wb.transhipment
                                          .transhipment_location_name
                                      }
                                      onChange={(e) => {
                                        let { WAYBILL_DATA } = this.state;

                                        WAYBILL_DATA[
                                          i
                                        ].transhipment.transhipment_location_name =
                                          e.target.value;

                                        this.setState({ WAYBILL_DATA });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="">Doc. Reference</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  value={wb.transhipment.doc_reference}
                                  onChange={(e) => {
                                    let { WAYBILL_DATA } = this.state;

                                    WAYBILL_DATA[i].transhipment.doc_reference =
                                      e.target.value;

                                    this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row xs="1">
                            <Col>
                              <Label className="big-label">Carrier</Label>
                            </Col>
                            <Col>
                              <Row>
                                <Col xs="5">
                                  <Label for="">Onward Carrier - Code</Label>
                                  <Input
                                    className="form-control-sm"
                                    type="text"
                                    name="mmsi_no"
                                    value={wb.carrier.onward_carrier_code}
                                    onChange={(e) => {
                                      let { WAYBILL_DATA } = this.state;

                                      WAYBILL_DATA[
                                        i
                                      ].carrier.onward_carrier_code =
                                        e.target.value;

                                      this.setState({ WAYBILL_DATA });
                                    }}
                                  />
                                </Col>
                                <Col xs="7">
                                  <Label for="">Onward Carrier - Name</Label>
                                  <Input
                                    className="form-control-sm"
                                    type="text"
                                    name="mmsi_no"
                                    value={wb.carrier.onward_carrier_name}
                                    onChange={(e) => {
                                      let { WAYBILL_DATA } = this.state;

                                      WAYBILL_DATA[
                                        i
                                      ].carrier.onward_carrier_name =
                                        e.target.value;

                                      this.setState({ WAYBILL_DATA });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <div className="fcb-title bord-0 w100per mt-4 d-flex flex-row">
                            <div className="col-md-6 p-0">
                              Waybill - Containers
                            </div>
                          </div>
                          <Row>
                            <Col xs="6" md="1">
                              <FormGroup>
                                <Label for="">Type</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  // value={wb.carrier.onward_carrier_code}
                                  onChange={(e) => {
                                    // let { WAYBILL_DATA } = this.state;
                                    // WAYBILL_DATA[i].carrier.onward_carrier_code =
                                    //   e.target.value;
                                    // this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="2">
                              <FormGroup>
                                <Label for="">Reference</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  // value={wb.carrier.onward_carrier_code}
                                  onChange={(e) => {
                                    // let { WAYBILL_DATA } = this.state;
                                    // WAYBILL_DATA[i].carrier.onward_carrier_code =
                                    //   e.target.value;
                                    // this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="1">
                              <FormGroup>
                                <Label for="">Number</Label>
                                <Input
                                  className="form-control-sm"
                                  type="number"
                                  name="mmsi_no"
                                  // value={wb.carrier.onward_carrier_code}
                                  onChange={(e) => {
                                    // let { WAYBILL_DATA } = this.state;
                                    // WAYBILL_DATA[i].carrier.onward_carrier_code =
                                    //   e.target.value;
                                    // this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="1">
                              <FormGroup>
                                <Label for="">E/F</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  // value={wb.carrier.onward_carrier_code}
                                  onChange={(e) => {
                                    // let { WAYBILL_DATA } = this.state;
                                    // WAYBILL_DATA[i].carrier.onward_carrier_code =
                                    //   e.target.value;
                                    // this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="2">
                              <FormGroup>
                                <Label for="">Seals</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  // value={wb.carrier.onward_carrier_code}
                                  onChange={(e) => {
                                    // let { WAYBILL_DATA } = this.state;
                                    // WAYBILL_DATA[i].carrier.onward_carrier_code =
                                    //   e.target.value;
                                    // this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="2">
                              <FormGroup>
                                <Label for="">Marks</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  // value={wb.carrier.onward_carrier_code}
                                  onChange={(e) => {
                                    // let { WAYBILL_DATA } = this.state;
                                    // WAYBILL_DATA[i].carrier.onward_carrier_code =
                                    //   e.target.value;
                                    // this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="1">
                              <FormGroup>
                                {/* <Label for="">Marks</Label> */}
                                <Input
                                  className="form-control-sm mt-4"
                                  type="text"
                                  name="mmsi_no"
                                  onChange={(e) => {}}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="6" md="2">
                              <FormGroup>
                                <Label for="">Sealing Party</Label>
                                <Input
                                  className="form-control-sm"
                                  type="text"
                                  name="mmsi_no"
                                  // value={wb.carrier.onward_carrier_code}
                                  onChange={(e) => {
                                    // let { WAYBILL_DATA } = this.state;
                                    // WAYBILL_DATA[i].carrier.onward_carrier_code =
                                    //   e.target.value;
                                    // this.setState({ WAYBILL_DATA });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="waybill-table">
                            <Col>
                              <Row md="9" className="thead">
                                <Col xs="6">Reference</Col>
                                <Col xs="6">Number of Package</Col>
                                <Col xs="6">Type of Container</Col>
                                <Col xs="6">Empty/Full</Col>
                                <Col xs="6">Seals</Col>
                                <Col xs="6">Marks</Col>
                                <Col xs="6">Marks</Col>
                                <Col xs="6">Sealing Party</Col>
                                <Col xs="6"></Col>
                              </Row>
                              <Row className="tbody">
                                <Col>
                                  {/* use .map on fragment ( <></> ) below */}
                                  <>
                                    <Row xs="2" className="tr-header">
                                      <Col>#1</Col>
                                      <Col>
                                        <div
                                          className="tblActions tblRemove"
                                          onClick={() => {
                                            console.log("delete");
                                          }}
                                        >
                                          <Icon name="trash" />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="tr" md="9">
                                      <Col xs="6">
                                        <FormGroup>
                                          <label for="">Reference</label>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="mmsi_no"
                                            onChange={(e) => {}}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="6">
                                        <FormGroup>
                                          <label for="">
                                            Number of Package
                                          </label>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="mmsi_no"
                                            onChange={(e) => {}}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="6">
                                        <FormGroup>
                                          <label for="">
                                            Type of Container
                                          </label>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="mmsi_no"
                                            onChange={(e) => {}}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="6">
                                        <FormGroup>
                                          <label for="">Empty/Full</label>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="mmsi_no"
                                            onChange={(e) => {}}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="6">
                                        <FormGroup>
                                          <label for="">Seals</label>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="mmsi_no"
                                            onChange={(e) => {}}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="6">
                                        <FormGroup>
                                          <label for="">Marks</label>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="mmsi_no"
                                            onChange={(e) => {}}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="6">
                                        <FormGroup>
                                          <label for="">Marks</label>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="mmsi_no"
                                            onChange={(e) => {}}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="6">
                                        <FormGroup>
                                          <label for="">Sealing Party</label>
                                          <Input
                                            className="form-control-sm"
                                            type="text"
                                            name="mmsi_no"
                                            onChange={(e) => {}}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="12">
                                        <div
                                          className="tblActions tblRemove"
                                          onClick={() => {
                                            console.log("delete");
                                          }}
                                        >
                                          <Icon name="trash" />
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          {/* meowmeow */}

                          <div className="col-md-12 p0">
                            <Button
                              className="btn-sm"
                              color="primary"
                              onClick={() => {
                                this.addContainerWaybill(i);
                              }}
                            >
                              ADD +
                            </Button>
                          </div>
                        </TabPane>
                      </TabContent>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </div>

        <div className="col-md-12 footer-actions d-flex flex-row">
          <div className="col-md-6 d-flex flex-row p0">
            {this.state.is_edit_mode || md.is_edit_mode ? (
              <div className="d-flex flex-row justify-content-center align-items-center edit-mode">
                <span style={{ fontSize: "13px" }}>
                  <Icon name="exclamation-triangle" /> EDIT MODE
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 p-0 d-flex flex-row justify-content-end">
            {md.is_ep_saved || this.state.is_saved ? (
              <Button
                onClick={() => this.handleUpdate()}
                className="btn-sm"
                color="warning"
                disabled={md.is_ep_submitted || this.state.is_ep_submitted}
              >
                <Media queries={constants.media_queries}>
                  {(matches) => (matches.xs ? <Icon name="edit" /> : "EDIT")}
                </Media>
              </Button>
            ) : (
              ""
            )}

            {md.is_ep_saved || this.state.is_saved ? (
              ""
            ) : (
              <Button
                onClick={() => this.handleSave()}
                className="btn-sm"
                color="success"
                style={{ margin: "0 0.5em" }}
              >
                <Media queries={constants.media_queries}>
                  {(matches) => (matches.xs ? <Icon name="save" /> : "SAVE")}
                </Media>
              </Button>
            )}

            {md.is_ep_saved || this.state.is_saved ? (
              <Button
                onClick={() => this.handleSubmit()}
                className="btn-sm"
                color="primary"
                style={{ margin: "0 0.5em" }}
                disabled={md.is_ep_submitted || this.state.is_ep_submitted}
              >
                <Media queries={constants.media_queries}>
                  {(matches) =>
                    matches.xs ? <Icon name="step-forward" /> : "SUBMIT"
                  }
                </Media>
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>

        <BackToTop />
      </div>
    );
  }
}

export default withRouter(Manifest);
