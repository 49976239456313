import React, { Component } from "react";
import { Col, Form, FormGroup, Button, Label, Input } from "reactstrap";
import { Icon } from "react-fa";

import "./VesselInfo.scss";
import Navbar from "../Navbar/Navbar";
import Sidenav from "../Sidenav/Sidenav";
import PortCallToolbar from "../PortCallToolbar/PortCallToolbar";
import back_icon from "../../img/svg/BackIcon.svg";
import pdf_file from "../../img/svg/pdf-file.svg";

import { DropdownList } from "react-widgets";
import seaports from "../../../src/sea-ports.json";
import api from "../../helpers/api.helper";
import constants from "../../constants";
import Datetime from "react-datetime";

import { withRouter } from "react-router-dom";

class VesselInfo extends Component {
  constructor(props) {
    super(props);
    console.log("anderskor id: ", this.props.match.params.id);

    this.state = {
      __ID: this.props.match.params.id,
      __data: {
        cargo_details: [
          {
            cargo_type: "",
            item: "",
            amount: 0,
            uom: null
          }
        ],
        shipping_agency: {}
      },
      __vessels: [],
      __vessel_names: [],
      selected_vessel: {},
      selected_port_call_purposes: [],
      seaports: seaports,
      __dateF: constants.__dateFormat,
      __timeF_ss: constants.__timeFormat_ss
    };
  }

  componentDidMount = () => {
    this.getData();
  };

  getData = () => {
    api.get(this, { endpoint: "vessel", state_key: "__vessels" }).then(data => {
      let vessel_names = [];

      for (let x in data) {
        let vessel = data[x];

        vessel_names[x] = vessel;
      }

      this.setState({
        __vessel_names: vessel_names
      });
    });
    if (!this.state.__ID) {
    } else {
      api.get(this, { endpoint: "portcall/" + this.state.__ID }).then(data => {
        // console.log(data)

        if (data.selected_port_call_purposes) {
          let selected_port_call_purposes = data.selected_port_call_purposes;
          this.setState({ selected_port_call_purposes });
        }
      });
    }
  };

  setSelectedVessel = vesselInformation => {
    if (vesselInformation) {
      let { __data } = this.state;

      __data.grt = vesselInformation.grt ? vesselInformation.grt : 0;
      __data.nrt = vesselInformation.nrt ? vesselInformation.nrt : 0;
      __data.dwt = vesselInformation.dwt ? vesselInformation.dwt : 0;
      __data.loa = vesselInformation.loa ? vesselInformation.loa : 0;
      __data.draft = vesselInformation.draft ? vesselInformation.draft : 0;
      __data.beam = vesselInformation.beam ? vesselInformation.beam : 0;
      __data.country = vesselInformation.country
        ? vesselInformation.country
        : "";
      __data.registered_owner = vesselInformation.registered_owner
        ? vesselInformation.registered_owner
        : "";
      __data.master = vesselInformation.master ? vesselInformation.master : "";
      __data.classification = vesselInformation.classification
        ? vesselInformation.classification
        : "";

      this.setState({ __data });
    }
  };

  handleRemoveSelectedPurpose = index => {
    let { selected_port_call_purposes } = this.state;
    selected_port_call_purposes.splice(index, 1);

    this.setState({ selected_port_call_purposes });
  };

  handleSelectPurpose = purpose => {
    if (purpose) {
      let { selected_port_call_purposes } = this.state;
      selected_port_call_purposes = [...selected_port_call_purposes, purpose];

      this.setState({ selected_port_call_purposes });
    }
  };

  handleAddCargoDetails = () => {
    let { __data } = this.state;
    let { cargo_details } = __data;
    cargo_details = [
      ...cargo_details,
      {
        cargo_type: "",
        item: "",
        amount: 0,
        uom: null
      }
    ];
    __data.cargo_details = cargo_details;
    this.setState({ __data });
  };

  handleSave = () => {
    let { __data, selected_port_call_purposes } = this.state;

    if (!this.state.__ID) {
      __data = { ...__data, selected_port_call_purposes };
      __data.status_id = constants.PENDING_STATUS;
      __data.status = "Pending";
      api
        .save(this, {
          endpoint: "portcall",
          _data: __data
        })
        .then(() => {});
    } else {
    }
  };

  render() {
    const { __vessel_names } = this.state;
    const vd = this.state.__data;
    const vessel = this.state.__data.vessel_name;
    const port_of_origin = this.state.__data.port_of_origin;
    const arrastre = this.state.__data.arrastre;
    const stevedoring = this.state.__data.stevedoring;
    const shipping_agency = this.state.__data.shipping_agency
      ? this.state.__data.shipping_agency.name
      : "";

    return (
      <div className="vesselEntry">
        <div className="d-flex flex-column">
          <Navbar />
          <PortCallToolbar />
          {this.state.__ID ? (
            <div
              md={{ size: 12 }}
              className="d-flex flex-column page-header"
              ref={this.pageHeader}
            >
              <Col md={{ size: 12 }} className="d-flex flex-row">
                <Col className="d-flex flex-row col-md-6">
                  <img
                    src={back_icon}
                    alt="back_icon"
                    className=""
                    width="25"
                  />
                  <p className="vessel-name aligner justify-content-start">
                    {vd.vessel_name}, Voyage {vd.voyage_number}
                  </p>
                </Col>
              </Col>

              <Col
                style={{ padding: "12px 20px" }}
                md={{ size: 12 }}
                className="d-flex flex-row"
              >
                <Col className="view-mode-button col-md-6">
                  <span
                    className="vmb"
                    style={{
                      borderRadius: "10px 0 0 10px",
                      padding: "2px 15px 2px 15px"
                    }}
                  >
                    Document View
                  </span>
                  <span
                    className="vmb vmb-selected"
                    style={{
                      borderRadius: "0 10px 10px 0",
                      padding: "2px 20px 2px 20px"
                    }}
                  >
                    Edit View
                  </span>
                </Col>
              </Col>
            </div>
          ) : (
            ""
          )}

          <Col md={{ size: 12 }} className="main-content d-flex flex-row">
            {/* {
              this.state.__ID ? (<Col md={{ size: 2 }}>
                <Sidenav dataFromParent={this.state.__ID} />
               </Col>) : ("")

            } */}

            <Col md={{ size: 2 }}>
              <Sidenav dataFromParent={this.state.__ID} visit={vd} />
            </Col>

            {/* <Col md={{ size: this.state.__ID ? 10 : 12  }} className={ this.state._ID ? 'mc-right d-flex flex-column' : 'mc-right d-flex flex-column mb-5em' }> */}
            <Col
              md={{ size: 10 }}
              className={
                this.state._ID
                  ? "mc-right d-flex flex-column"
                  : "mc-right d-flex flex-column mb-5em"
              }
            >
              <div
                className="form-container-box bt-green"
                style={{ margin: "0 5px" }}
              >
                <Form className="col-md-12 p-0">
                  <div className="fcb-title">
                    Vessel Detail
                    <div className="d-flex flex-row headerStatus" style={{}}>
                      <div className="d-flex flex-column">
                        <span className="hsLabel" style={{ fontSize: "10px" }}>
                          STATUS
                        </span>
                        <span className="hsText" style={{ color: "#FF9800" }}>
                          PENDING
                        </span>
                        {/* <span className="hsText"
                        style={{'color' : '#8BC34A'}}
                      >
                        CONFIRMED
                      </span> */}
                      </div>
                      <img
                        src={pdf_file}
                        alt="pdf_file"
                        style={{
                          width: "25px",
                          height: "25px",
                          margin: "4px 0 0 35px",
                          cursor: "pointer"
                        }}
                      />
                    </div>
                  </div>

                  <Col
                    md={{ size: 12 }}
                    className="d-flex flex-column p0 align-items-start mt-2"
                  >
                    <FormGroup className="col-md-4">
                      <Label for="">MMSI Number</Label>
                      <Input
                        className="form-control-sm"
                        type="text"
                        name="mmsi_no"
                        id=""
                        placeholder=""
                      />
                    </FormGroup>
                  </Col>

                  <div className="fcb-title mt-1">Ship Particulars</div>

                  {this.state.__ID ? (
                    <div className="col-md-12 p-0 _SHIP_AGENT">
                      <Col
                        md={{ size: 12 }}
                        className="p-0 mt-2 d-flex flex-row"
                      >
                        <FormGroup className="col-md-4">
                          <Label for="exampleSelect">Vessel Name</Label>
                          {/* <Input type="select" name="select" id="exampleSelect" className="form-control-sm">
                                    { v.map(i => (
                                      <option key={i} value={i.name}>{i.name}</option>
                                    ))

                                    }
                                  </Input> */}
                          <DropdownList
                            filter
                            value={vessel}
                            textField="name"
                            data={__vessel_names}
                            onChange={vessel => {
                              let __data = this.state.__data;
                              __data.vessel_name = vessel.name;
                              __data.vessel_class = vessel.classification;
                              this.setSelectedVessel(vessel);
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-4 position-relative">
                          <Label for="">GRT</Label>
                          <Input
                            className="form-control-sm wAddon"
                            type="number"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.grt}
                          />
                          <span className="inputAddon">MT</span>
                        </FormGroup>

                        <FormGroup className="col-md-4">
                          <Label for="">Flag / Country</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.country}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.country = e.target.value;
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="">Voyage Number</Label>
                          <Input
                            className="form-control-sm"
                            type="number"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.voyage_number}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.voyage_number = e.target.value;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-4 position-relative">
                          <Label for="">NRT</Label>
                          <Input
                            className="form-control-sm wAddon"
                            type="number"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.nrt}
                          />
                          <span className="inputAddon">MT</span>
                        </FormGroup>

                        <FormGroup className="col-md-4">
                          <Label for="">Owner</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.registered_owner}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="">Class / Type</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.vessel_class}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.vessel_class = e.target.value;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-4 position-relative">
                          <Label for="">DWT</Label>
                          <Input
                            className="form-control-sm wAddon"
                            type="number"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.dwt}
                          />
                          <span className="inputAddon">MT</span>
                        </FormGroup>

                        <FormGroup className="col-md-4">
                          <Label for="">Master</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.master}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="exampleDate">Date Coverage</Label>
                          <Input
                            className="form-control-sm"
                            type="date"
                            name="date"
                            id="exampleDate"
                            placeholder="date placeholder"
                          />
                        </FormGroup>

                        <div className="col-md-4 d-flex flex-row">
                          <FormGroup className="col-md-7 p-0">
                            <Label for="">LOA</Label>
                            <Input
                              className="form-control-sm"
                              type="number"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              value={this.state.__data.loa}
                            />
                          </FormGroup>
                          <FormGroup
                            className="col-md-5"
                            style={{ padding: "1.45em 0 0 0.5em" }}
                          >
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              className="form-control-sm"
                            >
                              <option>-</option>
                            </Input>
                          </FormGroup>
                        </div>

                        <FormGroup className="col-md-4">
                          <Label for="exampleDate">ETA</Label>
                          <Datetime
                            dateFormat={this.state.__dateF}
                            timeFormat={this.state.__timeF_ss}
                            value={
                              this.state.__data.eta
                                ? new Date(this.state.__data.eta)
                                : ""
                            }
                            onChange={e => {
                              let __data = this.state.__data;
                              __data.eta = e;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="exampleSelect">Port of Origin</Label>
                          <DropdownList
                            filter
                            value={port_of_origin}
                            textField="name"
                            data={this.state.seaports}
                            onChange={port => {
                              let { __data } = this.state;
                              __data.port_of_origin = port.name;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <div className="col-md-4 d-flex flex-row">
                          <FormGroup className="col-md-7 p-0">
                            <Label for="">Draft</Label>
                            <Input
                              className="form-control-sm"
                              type="number"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              value={this.state.__data.draft}
                            />
                          </FormGroup>
                          <FormGroup
                            className="col-md-5"
                            style={{ padding: "1.45em 0 0 0.5em" }}
                          >
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              className="form-control-sm"
                            >
                              <option>-</option>
                            </Input>
                          </FormGroup>
                        </div>

                        <FormGroup className="col-md-4">
                          <Label for="exampleDate">ETD</Label>
                          <Datetime
                            dateFormat={this.state.__dateF}
                            timeFormat={this.state.__timeF_ss}
                            value={
                              this.state.__data.eta
                                ? new Date(this.state.__data.etd)
                                : ""
                            }
                            onChange={e => {
                              let __data = this.state.__data;
                              __data.etd = e;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="exampleSelect">Port of Destination</Label>
                          <DropdownList
                            filter
                            value={this.state.__data.port_of_destination}
                            textField="name"
                            data={this.state.seaports}
                            onChange={port => {
                              let { __data } = this.state;
                              __data.port_of_destination = port.name;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <div className="col-md-4 d-flex flex-row">
                          <FormGroup className="col-md-7 p-0">
                            <Label for="">Beam</Label>
                            <Input
                              className="form-control-sm"
                              type="number"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              value={this.state.__data.beam}
                            />
                          </FormGroup>
                          <FormGroup
                            className="col-md-5"
                            style={{ padding: "1.45em 0 0 0.5em" }}
                          >
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              className="form-control-sm"
                            >
                              <option>-</option>
                            </Input>
                          </FormGroup>
                        </div>

                        <FormGroup className="col-md-4"></FormGroup>
                      </Col>

                      <div className="col-md-12 d-flex flex-row p-0">
                        <FormGroup className="col-md-4">
                          <Label for="exampleSelect">
                            Next Port of Destination
                          </Label>
                          <DropdownList
                            filter
                            value={this.state.__data.next_port_of_destination}
                            textField="name"
                            data={this.state.seaports}
                            onChange={port => {
                              let { __data } = this.state;
                              __data.next_port_of_destination = port.name;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 p-0 _ADMIN">
                      <Col
                        md={{ size: 12 }}
                        className="p-0 mt-2 d-flex flex-row"
                      >
                        <FormGroup className="col-md-4">
                          <Label for="exampleSelect">Vessel Name</Label>
                          {/* <Input type="select" name="select" id="exampleSelect" className="form-control-sm">
                                    { v.map(i => (
                                      <option key={i} value={i.name}>{i.name}</option>
                                    ))

                                    }
                                  </Input> */}
                          <DropdownList
                            filter
                            value={vessel}
                            textField="name"
                            data={__vessel_names}
                            onChange={vessel => {
                              let __data = this.state.__data;
                              __data.vessel_name = vessel.name;
                              __data.vessel_class = vessel.classification;
                              this.setSelectedVessel(vessel);
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-4 position-relative">
                          <Label for="">GRT</Label>
                          <Input
                            className="form-control-sm wAddon"
                            type="number"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.grt}
                          />
                          <span className="inputAddon">MT</span>
                        </FormGroup>

                        <FormGroup className="col-md-4">
                          <Label for="">Flag / Country</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.country}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.country = e.target.value;
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="">Voyage Number</Label>
                          <Input
                            className="form-control-sm"
                            type="number"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.voyage_number}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.voyage_number = e.target.value;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-4 position-relative">
                          <Label for="">NRT</Label>
                          <Input
                            className="form-control-sm wAddon"
                            type="number"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.nrt}
                          />
                          <span className="inputAddon">MT</span>
                        </FormGroup>

                        <FormGroup className="col-md-4">
                          <Label for="">Owner</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.registered_owner}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="">Class / Type</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.vessel_class}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.vessel_class = e.target.value;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-4 position-relative">
                          <Label for="">DWT</Label>
                          <Input
                            className="form-control-sm wAddon"
                            type="number"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.dwt}
                          />
                          <span className="inputAddon">MT</span>
                        </FormGroup>

                        <FormGroup className="col-md-4">
                          <Label for="">Master</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.master}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="exampleDate">Date Coverage</Label>
                          <Input
                            className="form-control-sm"
                            type="date"
                            name="date"
                            id="exampleDate"
                            placeholder="date placeholder"
                          />
                        </FormGroup>

                        <div className="col-md-4 d-flex flex-row">
                          <FormGroup className="col-md-7 p-0">
                            <Label for="">LOA</Label>
                            <Input
                              className="form-control-sm"
                              type="number"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              value={this.state.__data.loa}
                            />
                          </FormGroup>
                          <FormGroup
                            className="col-md-5"
                            style={{ padding: "1.45em 0 0 0.5em" }}
                          >
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              className="form-control-sm"
                            >
                              <option>-</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4"></FormGroup>

                        <div className="col-md-4 d-flex flex-row">
                          <FormGroup className="col-md-7 p-0">
                            <Label for="">Draft</Label>
                            <Input
                              className="form-control-sm"
                              type="number"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              value={this.state.__data.draft}
                            />
                          </FormGroup>
                          <FormGroup
                            className="col-md-5"
                            style={{ padding: "1.45em 0 0 0.5em" }}
                          >
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              className="form-control-sm"
                            >
                              <option>-</option>
                            </Input>
                          </FormGroup>
                        </div>

                        <FormGroup className="col-md-4"></FormGroup>
                      </Col>

                      <Col md={{ size: 12 }} className="p-0 d-flex flex-row">
                        <FormGroup className="col-md-4"></FormGroup>

                        <div className="col-md-4 d-flex flex-row">
                          <FormGroup className="col-md-7 p-0">
                            <Label for="">Beam</Label>
                            <Input
                              className="form-control-sm"
                              type="number"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              value={this.state.__data.beam}
                            />
                          </FormGroup>
                          <FormGroup
                            className="col-md-5"
                            style={{ padding: "1.45em 0 0 0.5em" }}
                          >
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              className="form-control-sm"
                            >
                              <option>-</option>
                            </Input>
                          </FormGroup>
                        </div>

                        <FormGroup className="col-md-4"></FormGroup>
                      </Col>

                      <div className="col-md-12 -d-flex flex-row">
                        <FormGroup className="col-md-4"></FormGroup>
                      </div>
                    </div>
                  )}

                  <div className={this.state.__ID ? "d-none" : "col-md-12 p-0"}>
                    <div className="fcb-title mt-1">
                      Port of Calls Itinerary
                    </div>
                    <Col md={{ size: 12 }} className="p-0 d-flex flex-row mt-2">
                      <div className="col-md-4 d-flex flex-column">
                        <FormGroup className="p-0">
                          <Label for="exampleSelect">
                            Purpose of Port Call
                          </Label>
                          <Input
                            type="select"
                            name="select"
                            id="exampleSelect"
                            className="form-control-sm"
                            onChange={e => {
                              this.handleSelectPurpose(e.target.value);
                            }}
                          >
                            <option>-</option>
                            {constants.purpose_of_portcalls.map((val, i) => {
                              if (
                                this.state.selected_port_call_purposes.indexOf(
                                  val
                                ) === -1
                              ) {
                                return <option>{val}</option>;
                              }
                              return "";
                            })}
                          </Input>
                        </FormGroup>
                        <div className="d-flex flex-row flex-wrap">
                          {this.state.selected_port_call_purposes.map(
                            (sp, i) => {
                              return (
                                <div className="services-chips" key={i}>
                                  {sp}{" "}
                                  <span
                                    onClick={e => {
                                      this.handleRemoveSelectedPurpose(i);
                                    }}
                                    className="sc-del"
                                  >
                                    <Icon name="times" />
                                  </span>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 d-flex flex-column">
                        <FormGroup className="p-0">
                          <Label for="exampleSelect">Port of Origin</Label>
                          <DropdownList
                            filter
                            value={port_of_origin}
                            textField="name"
                            data={this.state.seaports}
                            onChange={port => {
                              let { __data } = this.state;
                              __data.port_of_origin = port.name;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="p-0">
                          <Label for="exampleSelect">Port of Destination</Label>
                          <DropdownList
                            filter
                            value={this.state.__data.port_of_destination}
                            textField="name"
                            data={this.state.seaports}
                            onChange={port => {
                              let { __data } = this.state;
                              __data.port_of_destination = port.name;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>

                        <FormGroup className="p-0">
                          <Label for="exampleSelect">
                            Next Port of Destination
                          </Label>
                          <DropdownList
                            filter
                            value={this.state.__data.next_port_of_destination}
                            textField="name"
                            data={this.state.seaports}
                            onChange={port => {
                              let { __data } = this.state;
                              __data.next_port_of_destination = port.name;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-4 d-flex flex-column">
                        <FormGroup className="">
                          <Label for="exampleDate">ETA</Label>
                          <Datetime
                            dateFormat={this.state.__dateF}
                            timeFormat={this.state.__timeF_ss}
                            value={
                              this.state.__data.eta
                                ? new Date(this.state.__data.eta)
                                : ""
                            }
                            onChange={e => {
                              let __data = this.state.__data;
                              __data.eta = e;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="">
                          <Label for="exampleDate">ETD</Label>
                          <Datetime
                            dateFormat={this.state.__dateF}
                            timeFormat={this.state.__timeF_ss}
                            value={
                              this.state.__data.eta
                                ? new Date(this.state.__data.etd)
                                : ""
                            }
                            onChange={e => {
                              let __data = this.state.__data;
                              __data.etd = e;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                      </div>
                    </Col>

                    <div className="fcb-title mt-1">
                      Cargo Details (Nature/Tonnage)
                    </div>

                    {this.state.__data.cargo_details.map((cd, i) => {
                      return (
                        <>
                          <Col
                            md={{ size: 12 }}
                            className="p-0 d-flex flex-row mt-2"
                          >
                            <FormGroup className="col-md-3">
                              <Label for="exampleSelect">Cargo Type</Label>
                              <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                                className="form-control-sm"
                                onChange={e => {
                                  let { __data } = this.state;
                                  let { cargo_details } = __data;
                                  cargo_details[i]["cargo_type"] =
                                    e.target.value;
                                  __data.cargo_details = cargo_details;
                                  this.setState({ __data });
                                }}
                                value={cd.cargo_type}
                              >
                                <option>-</option>
                                {constants.cargo_types.map((ctype, i2) => {
                                  return <option>{ctype.name}</option>;
                                })}
                              </Input>
                            </FormGroup>
                            <FormGroup className="col-md-3">
                              <Label for="">Item</Label>
                              <Input
                                className="form-control-sm"
                                type="text"
                                placeholder=""
                                value={cd.item}
                                onChange={e => {
                                  let { __data } = this.state;
                                  let { cargo_details } = __data;
                                  cargo_details[i]["item"] = e.target.value;
                                  __data.cargo_details = cargo_details;
                                  this.setState({ __data });
                                }}
                              />
                            </FormGroup>
                            <FormGroup className="col-md-3">
                              <Label for="">Amount</Label>
                              <Input
                                className="form-control-sm"
                                type="text"
                                name="mmsi_no"
                                id=""
                                placeholder=""
                                value={cd.amount}
                                onChange={e => {
                                  let { __data } = this.state;
                                  let { cargo_details } = __data;
                                  cargo_details[i]["amount"] = e.target.value;
                                  __data.cargo_details = cargo_details;
                                  this.setState({ __data });
                                }}
                              />
                            </FormGroup>
                            <div className="col-md-3 d-flex flex-row">
                              <FormGroup className="col-md-9 p-0">
                                <Label for="exampleSelect">
                                  Unit of Measure
                                </Label>
                                <Input
                                  type="select"
                                  name="select"
                                  id="exampleSelect"
                                  className="form-control-sm"
                                  value={cd.uom}
                                  onChange={e => {
                                    let { __data } = this.state;
                                    let { cargo_details } = __data;
                                    cargo_details[i]["uom"] = e.target.value;
                                    __data.cargo_details = cargo_details;
                                    this.setState({ __data });
                                  }}
                                >
                                  <option>-</option>
                                  {constants.uoms.map((uom, i2) => {
                                    return <option key={i2}>{uom.name}</option>;
                                  })}
                                </Input>
                              </FormGroup>
                              <div className="col-md-3 d-flex align-items-center">
                                <Icon
                                  className=""
                                  name="plus"
                                  onClick={this.handleAddCargoDetails}
                                />
                              </div>
                            </div>
                          </Col>
                        </>
                      );
                    })}

                    <div className="fcb-title mt-1">Passenger Information</div>
                    <Col md={{ size: 12 }} className="p-0 d-flex flex-row mt-2">
                      <FormGroup className="col-md-4">
                        <Label for="">Disembarking</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          value={this.state.__data.disembarking}
                          onChange={e => {
                            let { __data } = this.state;
                            __data.disembarking = e.target.value;
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <Label for="">Embarking</Label>
                        <Input
                          className="form-control-sm"
                          type="text"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          value={this.state.__data.embarking}
                          onChange={e => {
                            let { __data } = this.state;
                            __data.embarking = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-4">
                        <Label for="">Total Number of Crew</Label>
                        <Input
                          className="form-control-sm"
                          type="number"
                          name="mmsi_no"
                          id=""
                          placeholder=""
                          value={this.state.__data.total_number_of_crew}
                          onChange={e => {
                            let { __data } = this.state;
                            __data.total_number_of_crew = e.target.value;
                            this.setState({ __data });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <div className="fcb-title mt-1">
                      Logistics Requirements (LOGREQ)
                    </div>
                    <Col
                      md={{ size: 12 }}
                      className="p-0 d-flex flex-column mt-2"
                    >
                      <div className="col-md-12 p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="">Crane Services</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.crane_services}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.crane_services = e.target.value;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                          <Label for="">Fresh Water</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.fresh_water}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.fresh_water = e.target.value;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-4"></FormGroup>
                      </div>

                      <div className="col-md-12 p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="exampleSelect">Arrastre</Label>
                          <DropdownList
                            filter
                            value={arrastre}
                            textField="name"
                            data={constants.arrastres}
                            onChange={arrastre => {
                              let __data = this.state.__data;
                              __data.arrastre = arrastre.name;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                          <Label for="exampleSelect">Shipping Agency</Label>
                          <DropdownList
                            filter
                            value={shipping_agency}
                            textField="name"
                            data={constants.shipping_agencies}
                            onChange={shipping_agency => {
                              let __data = this.state.__data;
                              __data.shipping_agency = shipping_agency;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                        <div className="col-md-4 p-0 d-flex flex-row">
                          <FormGroup className="col-md-6">
                            <Label for="">Tel No.</Label>
                            <Input
                              className="form-control-sm"
                              type="number"
                              name="tel_no"
                              id=""
                              placeholder=""
                              value={this.state.__data.shipping_agency.tel_no}
                              onChange={e => {
                                let { __data } = this.state;
                                __data.shipping_agency.tel_no = e.target.value;
                                this.setState({ __data });
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-6">
                            <Label for="">Fax No.</Label>
                            <Input
                              className="form-control-sm"
                              type="number"
                              name="mmsi_no"
                              id=""
                              placeholder=""
                              value={this.state.__data.shipping_agency.fax_no}
                              onChange={e => {
                                let { __data } = this.state;
                                __data.shipping_agency.fax_no = e.target.value;
                                this.setState({ __data });
                              }}
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <div className="col-md-12 p-0 d-flex flex-row">
                        <FormGroup className="col-md-4">
                          <Label for="exampleSelect">Stevedoring</Label>
                          <DropdownList
                            filter
                            value={stevedoring}
                            textField="name"
                            data={constants.stevedorings}
                            onChange={stevedoring => {
                              let __data = this.state.__data;
                              __data.stevedoring = stevedoring.name;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                          <Label for="">Address</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.shipping_agency.address}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.shipping_agency.address = e.target.value;
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                          <Label for="">Others</Label>
                          <Input
                            className="form-control-sm"
                            type="text"
                            name="mmsi_no"
                            id=""
                            placeholder=""
                            value={this.state.__data.shipping_agency.others}
                            onChange={e => {
                              let { __data } = this.state;
                              __data.shipping_agency.others = e.target.value;
                              this.setState({ __data });
                            }}
                          />
                        </FormGroup>
                      </div>
                    </Col>
                  </div>

                  <div className="col-md-12 footer-actions d-flex flex-row">
                    {!this.state.__ID ? (
                      <div className="col-md-12 d-flex flex-row justify-content-end p-0">
                        <Button
                          className="btn-sm"
                          color="danger"
                          style={{ margin: "0 0.5em" }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          className="btn-sm"
                          color="primary"
                          onClick={() => this.handleSave()}
                        >
                          SAVE
                        </Button>
                      </div>
                    ) : (
                      <div className="col-md-12 d-flex flex-row justify-content-end p-0">
                        <Button
                          onClick={() =>
                            this.props.history.push(
                              "/port_call/in_port_services/" + this.state.__ID
                            )
                          }
                          className="btn-sm fs13"
                          color="primary"
                          style={{ margin: "0 0.5em" }}
                        >
                          NEXT
                        </Button>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
        </div>
      </div>
    );
  }
}

export default withRouter(VesselInfo);
