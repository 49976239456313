import React, { Component } from "react";
import { Form, FormGroup, Label, Col, Table, Button } from "reactstrap";
import { Icon } from "react-fa";
import Moment from "react-moment";
import Navbar from "../../../Navbar/Navbar";
import "./VesselDetailView.scss";

import api from "../../../../helpers/api.helper";

export default class VesselDetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "Ship Particulars",
      showAudit: false,
      vesselData: {},
      __data: {},

      ownerPicArr: [
        {
          pic: "",
          tel_no: "",
          email: ""
        }
      ],

      chartPIC: [
        {
          pic: "",
          tel_no: "",
          email: ""
        }
      ],

      freeboardDraft: [
        {
          zone: "",
          freeboard: "",
          draft: "",
          displacement: "",
          deadweight: ""
        }
      ]
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    if (this.props.match.params.id !== undefined) {
      api
        .get(this, { endpoint: "vessel/" + this.props.match.params.id })
        .then(data => {
          // console.log("VIEWSDAASD", data);

          this.setState({
            ownerPicArr: data.owner_pic,
            chartPIC: data.charterer_pic,
            freeboardDraft: data.freeboard_draft
          });
        });
    }
  };

  gotoList = () => {
    this.props.history.push("/maintenance/vessels");
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  handleAudit = () => {
    this.setState(prevState => ({
      showAudit: !prevState.showAudit
    }));
  };

  render() {
    const { showAudit, freeboardDraft, chartPIC, ownerPicArr } = this.state;
    const vessel = this.state.__data;
    return (
      <div className="VesselDetailView">
        <div className="flex-column">
          <Navbar className="no-shrink" />
          <Form
            className="d-flex flex-column"
            style={{ padding: "5px 20px 4em 20px" }}
          >
            <div className="form-container-box bt-green w100per" style={{}}>
              <div className="col-md-12 flex-column d-flex p-0 shipParticulars align-items-start">
                <div className="fcb-title">
                  <span className="cur-p toVesselList" onClick={this.gotoList}>
                    Vessels
                  </span>
                  <span>&nbsp;> Ship Particulars</span>
                  <span>
                    {" "}
                    > <span className="fw600">{vessel.vessel_name || "-"}</span>
                  </span>
                </div>

                <div className="d-flex flex-row col-md-12 p-0 mt-4">
                  <div className="col-md-4">
                    <div className="imageView">
                      <Icon name="image" />
                      <span className="upText">Upload Image</span>
                    </div>
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <FormGroup className="mb-5">
                      <Label for="">MMSI Number</Label>
                      <div className="view-text">-</div>
                    </FormGroup>
                    <FormGroup className="mb-5">
                      <Label for="exampleSelect">Flag & Port of Registry</Label>
                      <div className="view-text">
                        {vessel.flag + " - " + vessel.port_of_registry || "-"}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-5">
                      <Label for="exampleSelect">
                        Classification / Class No.
                      </Label>
                      <div className="view-text">
                        {vessel.classification || "-"}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-5">
                      <Label for="exampleFile">
                        Hull & Macinery Underwriters
                      </Label>
                      <div className="view-text">
                        {vessel.hull_and_machinery_underwriters || "-"}
                      </div>
                    </FormGroup>
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <FormGroup className="mb-5">
                      <Label for="">Vessel Name</Label>
                      <div className="view-text">
                        {vessel.vessel_name || "-"}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-5">
                      <Label for="exampleSelect">Type of Vessel</Label>
                      <div className="view-text">{vessel.type || "-"}</div>
                    </FormGroup>
                    <FormGroup className="mb-5">
                      <Label for="exampleFile">P & I Club</Label>
                      <div className="view-text">
                        {vessel.p_and_i_club || "-"}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-5">
                      <Label for="exampleFile">Builder's Name & Place</Label>
                      <div className="view-text">
                        {vessel.builders_name_and_place || "-"}
                      </div>
                    </FormGroup>
                  </div>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleFile">Hull Number / Type</Label>
                    <div className="view-text">{vessel.hull_number || "-"}</div>
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleFile">Date of Keel Laid</Label>
                    <div className="view-text">
                      <Moment format="DD-MMM-YYYY">
                        {vessel.date_of_keel_laid || "-"}
                      </Moment>
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="exampleDate">Date of Delivery</Label>
                    <div className="view-text">
                      <Moment format="DD-MMM-YYYY">
                        {vessel.date_of_delivery || "-"}
                      </Moment>
                    </div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Main Diesel Engine / Number</Label>
                    <div className="view-text">
                      {vessel.main_diesel_engine || "-"}
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Output Maximum Rating</Label>
                    <div className="view-text">
                      {vessel.output_maximum_rating || "-"}
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Output Normal Rating</Label>
                    <div className="view-text">
                      {vessel.output_normal_rating || "-"}
                    </div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">
                      Gen. Engine Maker / Type x Output / No.
                    </Label>
                    <div className="view-text">
                      {vessel.gen_engine_maker || "-"}
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Auxilliary Boiler Type / Number</Label>
                    <div className="view-text">
                      {vessel.aux_boiler_type || "-"}
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Service Speed</Label>
                    <div className="view-text">
                      {vessel.service_speed || "-"}
                    </div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">F.O. / D.O.</Label>
                    <div className="view-text">{vessel.fo_do || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">DWT</Label>
                    <div className="view-text">{vessel.dwt || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Water Ballast / No. of Tanks</Label>
                    <div className="view-text">
                      {vessel.water_ballast + " / " + vessel.no_of_tanks || "-"}
                    </div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Moulded Breadth</Label>
                    <div className="view-text">
                      {vessel.moulded_depth || "-"}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Draft</Label>
                    <div className="view-text">{vessel.draft || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Dist. from Bridge to Bow</Label>
                    <div className="view-text">
                      {vessel.dist_from_bridge_to_bow || "-"}
                    </div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Dist. from Bridge to Aft</Label>
                    <div className="view-text">
                      {vessel.dist_from_bridge_to_aft || "-"}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Official Number</Label>
                    <div className="view-text">
                      {vessel.official_number || "-"}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">IMO Number</Label>
                    <div className="view-text">{vessel.imo_number || "-"}</div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Call Sign</Label>
                    <div className="view-text">{vessel.call_sign || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">LOA</Label>
                    <div className="view-text">{vessel.loa || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Beam</Label>
                    <div className="view-text">{vessel.beam || "-"}</div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">GRT</Label>
                    <div className="view-text">{vessel.grt || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">NRT</Label>
                    <div className="view-text">{vessel.nrt || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Suez Canal Gross Tonnage</Label>
                    <div className="view-text">
                      {vessel.suez_canal_gt || "-"}
                    </div>
                  </FormGroup>
                </div>

                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Suez Canal Net Tonnage</Label>
                    <div className="view-text">
                      {vessel.suez_canal_nt || "-"}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Lightship</Label>
                    <div className="view-text">{vessel.lightship || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Summer TPC</Label>
                    <div className="view-text">{vessel.summer_tpc || "-"}</div>
                  </FormGroup>
                </div>
                <div className="d-flex flex-row col-md-12 p-0">
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">FWA</Label>
                    <div className="view-text">{vessel.fwa || "-"}</div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Suez Canal I.D. No.</Label>
                    <div className="view-text">
                      {vessel.suez_canal_id || "-"}
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-5 col-md-4">
                    <Label for="">Canal I.D. No.</Label>
                    <div className="view-text">{vessel.us_canal_id || "-"}</div>
                  </FormGroup>
                </div>

                <div className="fcb-title mt-4">
                  Owner / Charterer / Ship Management Company
                </div>

                <div className="col-md-12 p-0 d-flex flex-row mt-4 ocs-section">
                  <div className="col-md-4 d-flex flex-column">
                    <div className="d-flex flex-column ocs-column">
                      <FormGroup className="mb-5">
                        <Label for="" className="label-lg">
                          Registered Owner
                        </Label>
                        <div className="view-text">
                          {vessel.registered_owner || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Address</Label>
                        <div className="view-text">
                          {vessel.owner_address || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Telephone No.</Label>
                        <div className="view-text">
                          {vessel.owner_telephone_no || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Fax No.</Label>
                        <div className="view-text">
                          {vessel.owner_fax_no || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Email</Label>
                        <div className="view-text">
                          {vessel.owner_email || "-"}
                        </div>
                      </FormGroup>
                    </div>

                    {ownerPicArr.map((ro_pic, index) => {
                      return (
                        <div key={index} className="d-flex flex-column ocs-pic">
                          <FormGroup className="mb-5">
                            <Label for="">PIC</Label>
                            <div className="view-text">
                              {ro_pic.name || "-"}
                            </div>
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="">Telephone No.</Label>
                            <div className="view-text">
                              {ro_pic.telephone || "-"}
                            </div>
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="">Email</Label>
                            <div className="view-text">
                              {ro_pic.email || "-"}
                            </div>
                          </FormGroup>
                        </div>
                      );
                    })}
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <div className="d-flex flex-column ocs-column">
                      <FormGroup className="mb-5">
                        <Label for="" className="label-lg">
                          Charterer
                        </Label>
                        <div className="view-text">
                          {vessel.charterer || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Address</Label>
                        <div className="view-text">
                          {vessel.charterer_address || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Telephone No.</Label>
                        <div className="view-text">
                          {vessel.charterer_telephone_no || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Fax No.</Label>
                        <div className="view-text">
                          {vessel.charterer_fax_no || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Email</Label>
                        <div className="view-text">
                          {vessel.charterer_email || "-"}
                        </div>
                      </FormGroup>
                    </div>

                    {chartPIC.map((c_pic, index) => {
                      return (
                        <div key={index} className="d-flex flex-column ocs-pic">
                          <FormGroup className="mb-5">
                            <Label for="">PIC</Label>
                            <div className="view-text">{c_pic.name || "-"}</div>
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="">Telephone No.</Label>
                            <div className="view-text">
                              {c_pic.telephone || "-"}
                            </div>
                          </FormGroup>
                          <FormGroup className="mb-5">
                            <Label for="">Email</Label>
                            <div className="view-text">
                              {c_pic.email || "-"}
                            </div>
                          </FormGroup>
                        </div>
                      );
                    })}
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <div className="d-flex flex-column ocs-column">
                      <FormGroup className="mb-5">
                        <Label for="" className="label-lg">
                          Ship Management Company
                        </Label>
                        <div className="view-text">
                          {vessel.ship_management_company || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">IMO Company ID</Label>
                        <div className="view-text">
                          {vessel.imo_company_no || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Address</Label>
                        <div className="view-text">
                          {vessel.ship_management_address || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Telephone No.</Label>
                        <div className="view-text">
                          {vessel.ship_management_telephone_no || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Fax No.</Label>
                        <div className="view-text">
                          {vessel.ship_management_fax_no || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <Label for="">Email</Label>
                        <div className="view-text">
                          {vessel.ship_management_email || "-"}
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </div>

                <div className="fcb-title mt-4">
                  Freeboard / Draft / Displacement / Deadweight
                </div>

                <Table
                  className="custom-table"
                  bordered
                  style={{ marginTop: "20px" }}
                >
                  <thead>
                    <tr>
                      <th>Zone</th>
                      <th>Freeboard</th>
                      <th>Draft</th>
                      <th>Displacement</th>
                      <th>Deadweight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {freeboardDraft.map((fDraft, index) => {
                      return (
                        <tr key={index}>
                          <td>{fDraft.zone}</td>
                          <td>{fDraft.freeboard}</td>
                          <td>{fDraft.draft}</td>
                          <td>{fDraft.displacement}</td>
                          <td>{fDraft.deadweight}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <div className="fcb-title mt-4">Hold Capacity</div>
                <div className="col-md-12 d-flex flex-column holdCap">
                  <div className="col-md-12 d-flex flex-row mt-3 p-0">
                    <div className="col-md-4 d-flex flex-column">
                      <p className="title">Product</p>
                    </div>
                    <div className="col-md-2 d-flex flex-column">
                      <p className="title">Cargo Hold</p>
                    </div>
                    <div className="col-md-4 d-flex flex-column">
                      <p className="d-flex flex-row title">
                        Quantity(m<sup style={{ top: "0.80em" }}>3</sup>)
                      </p>
                    </div>
                    <div className="col-md-2 d-flex flex-column"></div>
                  </div>

                  {/* {holdCapRows.map((hcRow, index) => {
                  return (
                    <div
                      key={index}
                      className="col-md-12 d-flex flex-row mt-3 p-0"
                    >
                      <Button
                        className="btn-sm"
                        color="primary"
                        onClick={() => this.add_HCrow(index)}
                      >
                        Add Product
                      </Button>
                      <div className="col-md-4 d-flex flex-column">
                        <FormGroup className="mb-0">
                          <Input
                            type="select"
                            name="select"
                            id="exampleSelect"
                            className="form-control-sm"
                            value={hcRow.product}
                            onChange={e =>
                              this.update_HCqty(
                                e.target.value,
                                index,
                                "product"
                              )
                            }
                          >
                            <option>Product 1</option>
                            <option>Product 2</option>
                          </Input>
                        </FormGroup>
                      </div>

                      <div className="d-flex flex-column col-md-12 p-0">
                        {holdCapQty.map((hcQty, subIndex) => {
                          return (
                            <div
                              key={subIndex}
                              className="d-flex flex-row"
                              style={{ marginBottom: "0.5em" }}
                            >
                              <div className="col-md-2 d-flex flex-column">
                                <p className="fs13">CH #1</p>
                              </div>
                              <div className="col-md-3 d-flex flex-column">
                                <FormGroup className="mb-0">
                                  <Input
                                    className="form-control-sm"
                                    type="text"
                                    name="hCap_qty"
                                    id=""
                                    placeholder=""
                                    value={hcQty.qty}
                                    onChange={e =>
                                      this.update_HCqty(
                                        e.target.value,
                                        subIndex,
                                        "qty"
                                      )
                                    }
                                  />
                                </FormGroup>
                              </div>

                              <div
                                className="col-md-4 d-flex flex-column"
                                style={{ paddingTop: "0.25em" }}
                              >
                                {holdCapQty.length === 1 ? (
                                  <div
                                    className="tblActions tblAdd"
                                    onClick={() => this.add_HCqty(subIndex)}
                                  >
                                    <Icon name="plus" />
                                  </div>
                                ) : holdCapQty.length === subIndex + 1 ? (
                                  <div className="d-flex flex-row">
                                    <div
                                      style={{ marginRight: "0.5em" }}
                                      className="tblActions tblRemove"
                                      onClick={() => this.rmv_HCqty(subIndex)}
                                    >
                                      <Icon name="minus" />
                                    </div>
                                    <div
                                      className="tblActions tblAdd"
                                      onClick={() => this.add_HCqty(subIndex)}
                                    >
                                      <Icon name="plus" />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="tblActions tblRemove"
                                    onClick={() => this.rmv_HCqty(subIndex)}
                                  >
                                    <Icon name="minus" />
                                  </div>
                                )}
                              </div>

                              <div className="col-md-5 d-flex flex-column"></div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })} */}
                </div>

                <div className="col-md-12 d-flex flex-row mt-4">
                  <div className="col-md-8 fcb-title mt-4 p-0">Cargo Gear</div>
                  <div className="col-md-4 fcb-title mt-4 p-0">
                    Ship's Communication
                  </div>
                </div>

                <div className="col-md-12 d-flex flex-row mt-3">
                  <div className="col-md-8 p-0 d-flex flex-column">
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Deck Crane No.</Label>
                        <div className="view-text">
                          {vessel.deck_crane_no || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Slewing Angle</Label>
                        <div className="view-text">
                          {vessel.slewing_angle || "-"}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Type & Manufacturer</Label>
                        <div className="view-text">
                          {vessel.type_and_manufacturer || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Max. Outreach from Ship's Side</Label>
                        <div className="view-text">
                          {vessel.outreach_from_ships_side || "-"}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Hoisting Speed (Hook/Grab)</Label>
                        <div className="view-text">
                          {vessel.hoisting_speed || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Grab Bucket - Type / Number</Label>
                        <div className="view-text">
                          {vessel.grab_bucket_type || "-"}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Lowering Speed (at rated load)</Label>
                        <div className="view-text">
                          {vessel.lowering_speed || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Grab Cap. / Normal Out/ Input Source</Label>
                        <div className="view-text">
                          {vessel.grab_cap || "-"}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="p-0 d-flex flex-row">
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Winding Height</Label>
                        <div className="view-text">
                          {vessel.winding_height || "-"}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-0 col-md-6">
                        <Label>Lifting Capacity / Empty Wt.</Label>
                        <div className="view-text">
                          {vessel.lifting_capacity || "-"}
                        </div>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="col-md-4 d-flex flex-column">
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">INM FBB Tel.</Label>
                      <div className="view-text">
                        {vessel.inm_fbb_tel || "-"}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">Iridium Tel.</Label>
                      <div className="view-text">
                        {vessel.iridium_tel || "-"}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">INM Fax</Label>
                      <div className="view-text">{vessel.inm_fax || "-"}</div>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">INM C Telex</Label>
                      <div className="view-text">
                        {vessel.inm_c_telex || "-"}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label for="exampleFile">Email</Label>
                      <div className="view-text">
                        {vessel.ship_com_email || "-"}
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>

            <Col col={{ size: 12 }} className="d-flex flex-row p-0 mt-3">
              <div className="form-container-box bt-green col-md-12">
                <div className="col-md-12 d-flex flex-row auditHead">
                  {showAudit ? (
                    <Icon
                      className=""
                      name="angle-up"
                      onClick={this.handleAudit}
                    />
                  ) : (
                    <Icon
                      className=""
                      name="angle-down"
                      onClick={this.handleAudit}
                    />
                  )}

                  <p>Audit Trails</p>
                </div>

                <div>
                  {showAudit ? (
                    <Col md={{ size: 12 }} className="p-0">
                      create
                      <Table
                        className="custom-table"
                        bordered
                        style={{ marginTop: "20px" }}
                      >
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Details</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </Col>

            <div className="col-md-12 footer-actions d-flex flex-row justify-content-end">
              <Button
                data-cy="vessel_edit_btn"
                className="btn-sm"
                color="primary"
                onClick={() =>
                  this.props.history.push(
                    "/maintenance/vessel/edit/" + this.props.match.params.id
                  )
                }
              >
                EDIT
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
