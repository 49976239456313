import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../../../helpers/api.helper";
import constants from "../../../constants";
import moment from "moment";
import "./ArrivalTicker.scss";
import { ReactComponent as Departure } from "../svg/Departure.svg";
import classnames from "classnames";

class DepartureTicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DASHBOARD_TICKER: [],
      DEPARTURE_TICKER: [],
    };
  }

  componentDidMount = () => {
    this.loadTicker();
  };

  loadTicker = () => {
    api
      .get(this, {
        endpoint: "dashboard/ticker",
        state_key: "dashboardTicker",
      })
      .then((data) => {
        // console.log("TICKER", data);
        this.setState({
          DEPARTURE_TICKER: data.departure,
        });
      });
  };

  render() {
    return (
      <>
        <div className="col-md-12 p0 tickerCon">
          <div class="col-md-12 d-flex flex-row align-items-center">
            <div class="col-md-6 p0 d-flex flex-row">
              <Departure />{" "}
              <p className="table-title d-flex align-items-center col-md-10 p0">
                DEPARTURE
              </p>
            </div>
            <div class="col-md-6 p0 d-flex flex-row justify-content-end">
              <div className="d-flex flex-column align-items-end date-con">
                <span className="date-no">
                  {moment(new Date()).format(constants.__timeFormat)}
                </span>
                <span className="date-label">
                  {moment(new Date()).format(constants.__dateFormat) +
                    " " +
                    moment(new Date()).format(constants.__dayFormat)}
                </span>
              </div>
              <div className="d-flex flex-column align-items-center page-con">
                <span className="page-no">1</span>
                <span className="page-label">PAGE</span>
              </div>
            </div>
          </div>
          <table className="arrivalTable ticker-table">
            <thead>
              <tr>
                <th style={{ width: "12.5%" }}>Name of Ship</th>
                <th style={{ width: "12.5%" }} className="tar">
                  Voyage No.
                </th>
                <th style={{ width: "12.5%" }}>Ship Agent</th>
                <th style={{ width: "12.5%" }}>Port of Origin</th>
                <th style={{ width: "12.5%" }}>Destination</th>
                <th style={{ width: "12.5%" }}>
                  Total No.
                  <br />
                  of Crew
                </th>
                <th style={{ width: "15%" }}>
                  Estimated Date & Time of Departure
                </th>
                <th style={{ width: "12.5%" }} className="tar">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.DEPARTURE_TICKER.map((td, i) => (
                <tr key={i}>
                  <td>{td.vessel_name}</td>
                  <td className="tar yellowTD">{td.voyage_no}</td>
                  <td>
                    {td.selected_port_call_purposes.map((sp, i) => (
                      <div key={i}>
                        {sp.arrastre ? sp.arrastre : ""}
                        {sp.arrastre && sp.stevedoring ? " / " : ""}
                        {sp.stevedoring ? sp.stevedoring : ""}
                      </div>
                    ))}
                  </td>
                  <td className="tt-up">{td.port_of_origin}</td>
                  <td className="tt-up">{td.port_of_destination}</td>
                  <td>{td.total_number_of_pd_crew}</td>
                  <td>
                    {td.etd ? (
                      <p className="m0">
                        <span className="fw600">ETD: </span>
                        {moment(td.etd).format(constants.__dateTimeFormat)}
                      </p>
                    ) : (
                      ""
                    )}
                    {td.atd ? (
                      <p className="m0" style={{ paddingTop: "0.5em" }}>
                        <span className="fw600">ATD: </span>
                        {moment(td.atd).format(constants.__dateTimeFormat)}
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                  <td
                    className={
                      "tar " +
                      classnames({
                        orangeTD:
                          td.status === "Pending" ||
                          td.status === "In Transit" ||
                          td.status === "Announced",
                        greenTD:
                          td.status === "Arrived" ||
                          td.status === "Anchorage" ||
                          td.status === "Berthed" ||
                          td.status === "Cleared for Exit" ||
                          td.status === "Resource Plan Approved",
                        blueTD:
                          td.status === "Submitted" ||
                          td.status === "Entry Application Endorsed" ||
                          td.status === "Vessel Exit Application Endorsed" ||
                          td.status === "Resource Plan Submitted",
                        redTD:
                          td.status === "Rejected" ||
                          td.status === "Departed" ||
                          td.status === "Closed" ||
                          td.status === "Resource Plan Rejected",
                      })
                    }
                  >
                    {td.status + (td.atd ? +" " + td.atd : "")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default withRouter(DepartureTicker);
